import * as React from 'react';
import { SvgIcon } from '../../icon/icon.component';

type EditPatientPropsType = {
  width: number;
  height: number;
  fill: string;
  viewBox: string;
};

export const FeedbackIcon: React.FC<EditPatientPropsType> = ({
  width,
  height,
  fill,
  viewBox,
}): JSX.Element => {
  return (
    <SvgIcon
      width={width}
      height={height}
      originalWidth={width}
      originalHeight={height}
      fill={fill}
      viewBox={viewBox}
    >
      <g clipPath="url(#clip0_3820_106559)">
        <path
          d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z"
          fill="#E4E9ED"
        />
        <path
          d="M80.7275 48C79.9915 91.64 15.9995 91.632 15.2715 48C16.0075 4.35995 79.9995 4.36795 80.7275 48Z"
          fill="#FFC414"
        />
        <path
          d="M49.6473 49.8959H46.5993C46.2313 49.8959 45.9273 49.6079 45.9113 49.2399L45.3353 35.7919C45.2713 34.2079 46.5353 32.8879 48.1193 32.8879C49.7033 32.8879 50.9753 34.2079 50.9033 35.7919L50.3273 49.2399C50.3113 49.6079 50.0073 49.8959 49.6393 49.8959H49.6473Z"
          fill="white"
        />
        <path
          d="M51.5351 58.852C51.5351 56.8969 49.9502 55.312 47.9951 55.312C46.04 55.312 44.4551 56.8969 44.4551 58.852C44.4551 60.8071 46.04 62.392 47.9951 62.392C49.9502 62.392 51.5351 60.8071 51.5351 58.852Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3820_106559">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

import {
  CorrelatePairingCodeLoaderImpl,
  CorrelatePairingCodeServiceImpl,
  CorrelatePairingCodeTransformImpl,
} from './pairing-code-correlation.service';
import { correlatePairingCodeResponseType } from './pairing-code-correlation.types';
import { pairingCodeCorrelateMockResponse } from './pairing-code-correlation.mock';
import { Promise } from 'es6-promise';

export const mockLoader = (): Promise<correlatePairingCodeResponseType> =>
  Promise.resolve(pairingCodeCorrelateMockResponse);

export const CorrelatePairingCodeFactoryImp = ({ devMode }) => {
  const loader = devMode ? mockLoader : CorrelatePairingCodeLoaderImpl;
  return CorrelatePairingCodeServiceImpl(
    loader,
    CorrelatePairingCodeTransformImpl,
  );
};

import { Action } from '../../../../../app/store/app.types';

export enum LoginSystemErrorTypes {
  RETRY_LOGIN = 'RETRY_LOGIN',
}

export enum LogoutTypes {
  START_LOGOUT = 'START_LOGOUT',
}

export type LogoutAction = Action<LogoutTypes.START_LOGOUT>;

export type RetryLogin = Action<LoginSystemErrorTypes.RETRY_LOGIN>;

import React, { createContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { connect } from 'react-redux';
import {
  selectExpiration,
  selectExpires_in,
} from 'src/app/session/core/oidc/oidc.selectors';
import {
  selectIsGettingToken,
  selectIsSignedOut,
  selectIsSigningout,
} from 'src/app/session/core/session/session.selectors';
import { refreshOidcTokensStart } from 'src/app/session/core/oidc/oidc.actions';
import { createStructuredSelector } from 'reselect';
import {
  loginRedirect,
  signoutStart,
  validateSessionStart,
} from '../../core/session/session.actions';
import { showExpiringModal } from 'src/app/session/core/expiring-modal/expiring-modal.actions';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import { destroyModal } from 'src/shared/design-system/modal/store/modal/modal.actions';
import {
  FORMAT,
  LAST_ACTIVE,
  USER_ACTIVITY,
  USER_ACTIVITY_PROVIDER,
} from '../../constants';
import { testId } from '@roche/roche-common';
import {
  handlesActivity,
  handlesLastActive,
  handlesOnBeforeUnload,
  handlesReload,
  onClearAllLocalStorage,
} from './utils';
import { JELLO_LOADING_SIZES } from 'src/app/app.jello.constants';

import moment from 'moment';
import { clearWhatsNewViewed } from 'src/domains/announcements/whats-new/store/whats-new.actions';

export const UserActivityContext = createContext({});

/* istanbul ignore next */ export const UserActivityComponent = ({
  timeout,
  isGettingToken,
  isSigningout = false,
  expiration,
  onSignOut,
  onValidateSessionStart,
  onShowExpiringModal,
  onDestroyModal,
  onRefreshToken,
  children,
  isSignedOut,
  clearWhatsNewViewed,
}) => {
  const [_remaining, setRemaining] = useState(timeout);

  const {
    reset,
    start,
    pause,
    getRemainingTime,
    getLastActiveTime,
    getTabId,
    isLeader,
  } = useIdleTimer({
    timeout,
    crossTab: true,
    syncTimers: 500,
    leaderElection: true,
  });

  const setTimes = () => setRemaining(getRemainingTime());

  /* istanbul ignore next */ const onStayAction = () => {
    onDestroyModal();
    onValidateSessionStart();
    start();
  };

  /* istanbul ignore next */ const onSignOutAction = () => {
    onDestroyModal();
    clearWhatsNewViewed();
    onSignOut();
    onClearAllLocalStorage();
  };

  useEffect(() => {
    const cleanup = handlesOnBeforeUnload(getTabId());
    onDestroyModal();
    setTimes();
    handlesReload();
    handlesLastActive(onSignOutAction, getTabId(), isSignedOut);
    /* istanbul ignore next */
    const intervalId = setInterval(() => setTimes(), 1000);

    return () => {
      cleanup();
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem(
      LAST_ACTIVE,
      `${moment(getLastActiveTime()).format(FORMAT)}`,
    );
    handlesActivity({
      reset,
      pause,
      isGettingToken,
      expiration,
      _remaining,
      onSignOutAction,
      onShowExpiringModal,
      onRefreshToken,
      isLeader,
    });
  }, [_remaining]);

  return (
    <UserActivityContext.Provider
      value={{
        onStayAction,
        onSignOutAction,
        getLastActiveTime,
        getTabId,
        onRefreshToken,
      }}
      {...testId(USER_ACTIVITY, USER_ACTIVITY_PROVIDER)}
    >
      {isSigningout && (
        <jello-loading
          size={JELLO_LOADING_SIZES.XL}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'var(--jello-color-foreground-highlight-base)',
          }}
        />
      )}
      {!isSigningout && children}
    </UserActivityContext.Provider>
  );
};

export const UserActivity = connect(
  null,
  mapDispatchers({
    clearWhatsNewViewed,
    onSignOut: signoutStart,
    onDestroyModal: destroyModal,
    onRefreshToken: refreshOidcTokensStart,
    onValidateSessionStart: validateSessionStart,
    onShowExpiringModal: showExpiringModal,
    onRedirectToLogin: loginRedirect,
  }),
)(
  connect(
    createStructuredSelector({
      expiration: selectExpiration,
      isGettingToken: selectIsGettingToken,
      timeout: selectExpires_in,
      isSigningout: selectIsSigningout,
      isSignedOut: selectIsSignedOut,
    }),
  )(UserActivityComponent),
);

import React from 'react';
import { createSelector, createStructuredSelector } from 'reselect';

import { LocalizedText } from 'src/shared/design-system/localized-text/localized-text.component';
import {
  selectLastCollectedDate,
  selectLastDeliveryStatusRequestHasError,
  selectLastNumberOfStripsToDeliver,
  selectLastStatusNextDeliveryDate,
  selectLastTrafficLightStatus,
  selectNextDeliveryDate,
} from 'src/domains/strip-management/store/strip-delivery/strip-delivery.selectors';
import {
  formatStripDeliveryDateString,
  numberOfStripsToTubes,
} from 'src/domains/strip-management/store/strip-delivery/strip-delivery.utils';
import { selectPatientId } from 'src/domains/patient/store/patient/patient.selector';
import { selectEC6UserLanguage } from 'src/app/store/user/user.selectors';

const selectLastStatusLastCollectedDateString = createSelector(
  selectLastCollectedDate,
  (dateString) =>
    formatStripDeliveryDateString(
      dateString,
      <LocalizedText textKey="stripDelivery.empty.lastCollectedDate" />,
    ),
);
const selectLastStatusNextDeliveryDateString = createSelector(
  selectLastStatusNextDeliveryDate,
  (dateString) =>
    formatStripDeliveryDateString(
      dateString,
      <LocalizedText textKey="stripDelivery.empty.nextDeliveryDate" />,
    ),
);

const selectLastNumberOfTubesToDeliver = createSelector(
  selectLastNumberOfStripsToDeliver,
  numberOfStripsToTubes,
);

export const lastPatientStripStatusConnector = createStructuredSelector({
  numberOfTubesToDeliver: selectLastNumberOfTubesToDeliver,
  lastCollectedDate: selectLastStatusLastCollectedDateString,
  nextDeliveryDate: selectLastStatusNextDeliveryDateString,
  trafficLightStatus: selectLastTrafficLightStatus,
  patientId: selectPatientId,
  hasError: selectLastDeliveryStatusRequestHasError,
  lastCollectedDateIso: selectLastCollectedDate,
  nextDeliveryDateIso: selectNextDeliveryDate,
  locale: selectEC6UserLanguage,
});

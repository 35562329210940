import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import { getPatientStockRequest } from 'src/domains/strip-management/store/patient-stock/patient-stock.actions';

import { OrgStripManagement } from './org-strip-management.component';
import { orgStripManagementConnector } from './store/org-strip-management.selector';

const dispatchers = mapDispatchers({
  getPatientStockRequest: getPatientStockRequest.start,
});

export const addOrgStripManagement = compose(
  connect(orgStripManagementConnector, dispatchers),
);

export const OrgStripManagementWrapper = compose(
  withRouter,
  addOrgStripManagement,
)(OrgStripManagement);

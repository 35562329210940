import React from 'react';

import {
  BasalProfileChangeIcon,
  BasalRateChangeIcon,
  BasalToggleIcon,
  CarbohydratesToggleIcon,
  CircleMarkIcon,
  ConnectingLinesIcon,
  CrossMarkIcon,
  GridIcon,
  HypoLimitIcon,
  InsulinToggleIcon,
  MeanBloodGlucoseIcon,
  PumpPauseIcon,
  PumpRunIcon,
  PumpStopIcon,
  PumpTbrdecIcon,
  PumpTbrEndDecIcon,
  PumpTbrEndIncIcon,
  PumpTbrincIcon,
  PumpTimeSetBackIcon,
  RectangleMarkIcon,
} from 'src/shared/design-system/icons';

import { GRAPH_LEGEND_TYPES } from '../graph-legend.constant';

const graphs = [
  GRAPH_LEGEND_TYPES.TREND_DETAIL,
  GRAPH_LEGEND_TYPES.STANDARD_DAY_DETAIL,
  GRAPH_LEGEND_TYPES.STANDARD_WEEK_DETAIL,
];

export const BLOOD_GLUCOSE = 'graphDetails.legend.bloodGlucose';
export const BEFORE_MEAL_BG = 'graphs.detailGraph.bgBeforeMeal';
export const AFTER_MEAL_BG = 'graphs.detailGraph.bgAfterMeal';
export const MEAN_BLOOD_GLUCOSE = 'graphDetails.legend.meanBloodGlucose';
export const CARBOHYDRATES = 'graphDetails.legend.carbohydrates';
export const INSULIN = 'graphDetails.legend.insulinAll';
export const BASAL_RATE = 'graphDetails.legend.basalRate';
export const CONNECTING_LINES = 'graphDetails.legend.connectingLines';
export const GRID_LINES = 'graphs.detailGraph.gridLines';

export const legendLists = (theme) => [
  {
    textKey: 'graphDetails.statistics.bloodGlucose.bloodGlucose',
    noBorder: false,
    items: [
      {
        icon: <CrossMarkIcon />,
        label: BLOOD_GLUCOSE,
        graphs,
      },
      {
        icon: <RectangleMarkIcon fillColor={theme.colors.white} />,
        label: BEFORE_MEAL_BG,
        graphs,
      },
      {
        icon: <RectangleMarkIcon />,
        label: AFTER_MEAL_BG,
        graphs,
      },
      {
        icon: <MeanBloodGlucoseIcon />,
        label: MEAN_BLOOD_GLUCOSE,
        graphs,
      },
      {
        icon: <CarbohydratesToggleIcon height={12} />,
        label: CARBOHYDRATES,
        graphs,
      },
    ],
  },
  {
    textKey: 'graphDetails.legend.targetRangeSectionHeader',
    noBorder: false,
    items: [
      {
        icon: <CrossMarkIcon fillColor={theme.colors.red} />,
        label: 'graphDetails.legend.hypoglycaemia',
        graphs,
      },
      {
        icon: (
          <CircleMarkIcon
            strokeColor={theme.colors.red}
            fillColor={theme.colors.white}
          />
        ),
        label: 'graphDetails.legend.hypoSymptoms',
        graphs,
      },
      {
        icon: <HypoLimitIcon />,
        label: 'graphDetails.legend.veryLowLimit',
        graphs,
      },
      {
        icon: (
          <RectangleMarkIcon
            strokeColor={theme.colors.green}
            fillColor={theme.colors.white}
          />
        ),
        label: 'graphDetails.legend.targetRange',
        graphs,
      },
    ],
  },
  {
    textKey: 'graphDetails.legend.insulin',
    noBorder: false,
    items: [
      {
        icon: <InsulinToggleIcon />,
        label: INSULIN,
        graphs,
      },
      {
        icon: <PumpRunIcon width={12} isGraphIcon={false} />,
        label: 'graphDetails.legend.run',
        graphs: [GRAPH_LEGEND_TYPES.STANDARD_DAY_DETAIL],
      },
      {
        icon: <PumpPauseIcon width={12} isGraphIcon={false} />,
        label: 'graphDetails.legend.pause',
        graphs: [GRAPH_LEGEND_TYPES.STANDARD_DAY_DETAIL],
      },
      {
        icon: <PumpStopIcon width={12} isGraphIcon={false} />,
        label: 'graphDetails.legend.stop',
        graphs: [GRAPH_LEGEND_TYPES.STANDARD_DAY_DETAIL],
      },
    ],
  },
  {
    textKey: 'graphDetails.legend.basalRate',
    noBorder: false,
    items: [
      {
        icon: <BasalToggleIcon width={12} height={12} />,
        label: BASAL_RATE,
        graphs,
      },
      {
        icon: <BasalRateChangeIcon width={12} height={12} />,
        label: 'graphDetails.legend.basalRateChange',
        graphs: [
          GRAPH_LEGEND_TYPES.TREND_DETAIL,
          GRAPH_LEGEND_TYPES.STANDARD_WEEK_DETAIL,
        ],
      },
      {
        icon: <BasalProfileChangeIcon width={12} height={12} />,
        label: 'graphDetails.legend.basalRateSwitch',
        graphs: [
          GRAPH_LEGEND_TYPES.TREND_DETAIL,
          GRAPH_LEGEND_TYPES.STANDARD_WEEK_DETAIL,
        ],
      },
      {
        icon: <PumpTbrincIcon width={12} />,
        label: 'graphDetails.legend.tbrIncrease',
        graphs: [
          GRAPH_LEGEND_TYPES.TREND_DETAIL,
          GRAPH_LEGEND_TYPES.STANDARD_DAY_DETAIL,
        ],
      },
      {
        icon: <PumpTbrdecIcon width={12} />,
        label: 'graphDetails.legend.tbrDecrease',
        graphs: [
          GRAPH_LEGEND_TYPES.TREND_DETAIL,
          GRAPH_LEGEND_TYPES.STANDARD_DAY_DETAIL,
        ],
      },
      {
        icon: <PumpTbrEndIncIcon width={12} />,
        label: 'graphDetails.legend.tbrIncreaseEnd',
        graphs: [GRAPH_LEGEND_TYPES.TREND_DETAIL],
      },
      {
        icon: <PumpTbrEndDecIcon width={12} />,
        label: 'graphDetails.legend.tbrDecreaseEnd',
        graphs: [GRAPH_LEGEND_TYPES.TREND_DETAIL],
      },
      {
        icon: <PumpTimeSetBackIcon width={12} height={12} />,
        label: 'graphDetails.legend.timeSetBack',
        graphs: [GRAPH_LEGEND_TYPES.TREND_DETAIL],
      },
    ],
  },
  {
    textKey: 'graphDetails.legend.graphVisuals',
    noBorder: true,
    items: [
      {
        icon: <ConnectingLinesIcon />,
        label: CONNECTING_LINES,
        graphs,
      },
      {
        icon: <GridIcon />,
        label: GRID_LINES,
        graphs,
      },
    ],
  },
];

import React, { Fragment } from 'react';
import { isNil } from 'ramda';
import { Subject } from 'rxjs/Subject';
import { AutoSizer } from 'react-virtualized';

import { colors } from 'src/app/styles/colors';
import { Graph } from 'src/domains/diagnostics/components/graph-lib/graph.component';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import { withGraphLoader } from 'src/domains/diagnostics/utils/with-graph-loader';
import {
  DetailBasalEventTooltip,
  DetailBasalTooltip,
  DetailCarbohydratesToolTip,
  DetailGlucoseToolTip,
  DetailInsulinToolTip,
} from 'src/domains/diagnostics/components/tool-tip/detail-tool-tip/detail-tool-tip.component';
import { BasalAxis } from 'src/domains/diagnostics/components/graph/graph-axes/basal-axis.component';
import { BasalGraph } from 'src/domains/diagnostics/components/graph/basal-graph.component';
import { BasalRateChanges } from 'src/domains/diagnostics/components/graph/basal-rate-changes.component';
import { BasalRateProfileChanges } from 'src/domains/diagnostics/components/graph/basal-rate-profile-changes.component';
import { BloodGlucoseAxis } from 'src/domains/diagnostics/components/graph/graph-axes/blood-glucose-axis.component';
import { CarbohydratesAxis } from 'src/domains/diagnostics/components/graph/graph-axes/carbohydrates-axis.component';
import { CarbohydratesGraph } from 'src/domains/diagnostics/components/graph/carbohydrates-graph.component';
import { GRAPH_LEGEND_TYPES } from 'src/domains/diagnostics/components/graph-legend/graph-legend.constant';
import { GraphPlot } from 'src/domains/diagnostics/components/graph/graph-plot/graph-plot.component';
import { InsulinAxis } from 'src/domains/diagnostics/components/graph/graph-axes/insulin-axis.component';
import { InsulinGraph } from 'src/domains/diagnostics/components/graph/insulin-graph.component';
import { ResizeWrapper } from 'src/domains/diagnostics/resize-wrapper/resize-wrapper.component';
import { AppleEatenIcon, AppleIcon } from 'src/shared/design-system/icons';
import { GraphControls } from 'src/domains/diagnostics/components/graph-controls/graph-controls.component';
import { filterVerticalTicksForViewAndVisibilityTolerance } from 'src/domains/diagnostics/utils/graphs.util';
import {
  COLLAPSED_STD_GRAPH_HEIGHT,
  DATA_TYPE,
  LOGBOOK_TYPE_DETAILS,
  LOGBOOK_TYPE_DIARY,
  MIN_EXPANDED_STD_GRAPH_HEIGHT,
  PLOT_HEIGHT,
  PLOT_WIDTH,
  SUB_PLOT_HEIGHT,
  X_AXIS_HEIGHT,
  Y_AXIS_WIDTH,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';

import { DetailGraph } from './components/detail-graph/detail-graph.component';
import { WeekAxis } from './components/axes/week-axis.component';
import { StandardWeekPlotWrapperDiv } from './standard-week-detail.style';

import { filterMainPoints } from 'src/domains/diagnostics/scenes/graphs/template/utils/graphs-template.util';

import {
  createEventHandlerStream,
  getToolTipValueColor,
  getYAxisTickVisibilityTolerance,
  navigateToLogbook,
} from '../../graph-shared/graph.util';
import { graphHasMeasurement } from 'src/domains/diagnostics/scenes/graphs/graph-shared/measurements';
import { testId } from '@roche/roche-common';
import { ToolTip } from 'src/domains/diagnostics/components/tool-tip/tool-tip.component';
import { getCarbohydratesUnitsLabel } from 'src/domains/diagnostics/scenes/graphs/graph-statistics.util';

const StandardWeekPlotWrapperDivWithLoader = withGraphLoader(
  StandardWeekPlotWrapperDiv,
);

const clickStream$ = new Subject();
const stop$ = new Subject();

export class StandardWeekDetailComponent extends React.Component {
  state = {
    selectedDate: null,
  };

  componentDidMount() {
    createEventHandlerStream(clickStream$, stop$)(
      ([{ date: selectedDate }]) =>
        this.setState((state) => ({ ...state, selectedDate })),
      ([{ date, type }]) => {
        const logbookType =
          type === DATA_TYPE.GLUCOSE
            ? LOGBOOK_TYPE_DETAILS
            : LOGBOOK_TYPE_DIARY;

        navigateToLogbook(
          this.props.history,
          date,
          this.props.changeLogbookType,
          logbookType,
        );
      },
    );
  }

  render() {
    const {
      onLineClick,
      props: {
        basalLines,
        basalRateChanges,
        basalRateProfileChanges,
        basalTicks,
        bloodGlucoseUnit,
        carbohydratesTicks,
        carbohydratesLines,
        collapsed,
        flexibleHeight,
        graphDetails,
        graphYMax,
        hideToolTip,
        horizontalTicks,
        insulinPoints,
        insulinTicks,
        isLoading,
        lines,
        meanPoints,
        measurements,
        points,
        showGridLines,
        showToolTip,
        targetRange,
        theme,
        threshold,
        toolTip,
        verticalTicks,
        yDirection = -1,
        graphToggles,
        showChangeGraphToggle,
        t,
        timeFormat,
        graph,
        carbUnit,
      },
      state: { selectedDate },
    } = this;
    const carbLabel = getCarbohydratesUnitsLabel[carbUnit];
    const translatedHorizontalTicks = horizontalTicks.map((tick) => ({
      ...tick,
      label: t(tick.label),
    }));

    const yAxisTickVisibilityTolerance = getYAxisTickVisibilityTolerance({
      bloodGlucoseUnit,
    });

    return (
      <Fragment>
        <RenderIf
          validate={
            graphHasMeasurement(
              measurements,
              insulinPoints,
              basalLines,
              carbohydratesLines,
            ) && !isLoading
          }
        >
          <GraphControls
            showChangeGraphToggle={showChangeGraphToggle}
            graphStatistics={graphDetails}
            graphType={GRAPH_LEGEND_TYPES.STANDARD_WEEK_DETAIL}
            collapsed={collapsed}
            graph={graph}
          />
        </RenderIf>
        <ResizeWrapper
          minHeight={
            collapsed
              ? COLLAPSED_STD_GRAPH_HEIGHT
              : MIN_EXPANDED_STD_GRAPH_HEIGHT
          }
          render={(height) => {
            const filteredVerticalTicks =
              filterVerticalTicksForViewAndVisibilityTolerance(
                verticalTicks,
                flexibleHeight,
                threshold,
                targetRange,
                yAxisTickVisibilityTolerance,
                graphYMax,
              );

            return (
              <StandardWeekPlotWrapperDivWithLoader
                {...testId('graph-template', 'standard-week-detail')}
                collapsed={collapsed}
                hasError={
                  !graphHasMeasurement(
                    measurements,
                    insulinPoints,
                    basalLines,
                    carbohydratesLines,
                  ) && !isLoading
                }
                isLoading={isLoading}
                onClick={this.onGraphClick}
              >
                <AutoSizer>
                  {({ width }) => (
                    <RenderIf validate={width && height}>
                      <Graph
                        viewportRight={width}
                        viewportBottom={height}
                        height={height}
                        anchor="xMidYMid"
                      >
                        <GraphPlot
                          width={width}
                          height={height}
                          xAxisHeight={X_AXIS_HEIGHT}
                          yAxisWidth={Y_AXIS_WIDTH}
                          plotHeight={PLOT_HEIGHT}
                          plotWidth={PLOT_WIDTH}
                          subPlotHeight={SUB_PLOT_HEIGHT}
                          mainGraph={(dimensions) => (
                            <Fragment>
                              <DetailGraph
                                {...dimensions}
                                yDirection={yDirection}
                                targetRange={targetRange}
                                threshold={threshold.value}
                                horizontalTicks={translatedHorizontalTicks}
                                showGridLines={showGridLines}
                                lines={lines}
                                points={points}
                                meanPoints={filterMainPoints(meanPoints)}
                                carbohydratesLines={carbohydratesLines}
                                selectedDate={selectedDate}
                                onLineClick={onLineClick}
                                onPointMouseOver={showToolTip}
                                onPointMouseOut={hideToolTip}
                                graphYMax={graphYMax}
                                bloodGlucoseUnit={bloodGlucoseUnit}
                                collapsed={collapsed}
                              />
                              <RenderIf validate={graphToggles.showBasalRate}>
                                <BasalRateProfileChanges
                                  profileChanges={basalRateProfileChanges}
                                  {...{
                                    dimensions,
                                    selectedDate,
                                    showToolTip,
                                    hideToolTip,
                                    onLineClick,
                                  }}
                                />
                                <BasalRateChanges
                                  {...{
                                    basalRateChanges,
                                    dimensions,
                                    selectedDate,
                                    showToolTip,
                                    hideToolTip,
                                    onLineClick,
                                  }}
                                />
                              </RenderIf>
                            </Fragment>
                          )}
                          xAxisBottom={(dimensions) => (
                            <WeekAxis
                              {...dimensions}
                              {...{ collapsed, yDirection }}
                              ticks={translatedHorizontalTicks}
                            />
                          )}
                          yAxisLeft={(dimensions) => (
                            <BloodGlucoseAxis
                              {...dimensions}
                              ticks={filteredVerticalTicks}
                              unit={bloodGlucoseUnit}
                            />
                          )}
                          yAxisRightTop={(dimensions) => (
                            <RenderIf validate={graphToggles.showInsulin}>
                              <InsulinAxis
                                {...dimensions}
                                ticks={insulinTicks}
                              />
                            </RenderIf>
                          )}
                          yAxisRightBottom={(dimensions) => (
                            <Fragment>
                              <RenderIf validate={graphToggles.showBasalRate}>
                                <BasalAxis {...dimensions} ticks={basalTicks} />
                              </RenderIf>
                              <RenderIf
                                validate={
                                  !graphToggles.showBasalRate &&
                                  graphToggles.showCarbohydrates
                                }
                              >
                                <CarbohydratesAxis
                                  {...dimensions}
                                  ticks={carbohydratesTicks}
                                />
                              </RenderIf>
                            </Fragment>
                          )}
                          upperSubGraph={(dimensions) => (
                            <RenderIf validate={graphToggles.showInsulin}>
                              <InsulinGraph
                                {...dimensions}
                                insulinPoints={insulinPoints}
                                selectedDate={selectedDate}
                                onLineClick={onLineClick}
                                onPointMouseOver={showToolTip}
                                onPointMouseOut={hideToolTip}
                                collapsed={collapsed}
                              />
                            </RenderIf>
                          )}
                          lowerSubGraph={(dimensions) => (
                            <Fragment>
                              <RenderIf validate={graphToggles.showBasalRate}>
                                <BasalGraph
                                  {...dimensions}
                                  {...{
                                    basalLines,
                                    selectedDate,
                                    onLineClick,
                                  }}
                                  onMouseMove={showToolTip}
                                  onMouseOut={hideToolTip}
                                  collapsed={collapsed}
                                />
                              </RenderIf>
                              <RenderIf
                                validate={graphToggles.showCarbohydrates}
                              >
                                <CarbohydratesGraph
                                  {...dimensions}
                                  lines={carbohydratesLines}
                                  selectedDate={selectedDate}
                                  onLineClick={onLineClick}
                                  onPointMouseOver={showToolTip}
                                  onPointMouseOut={hideToolTip}
                                  collapsed={collapsed}
                                />
                              </RenderIf>
                            </Fragment>
                          )}
                        />
                      </Graph>
                    </RenderIf>
                  )}
                </AutoSizer>
              </StandardWeekPlotWrapperDivWithLoader>
            );
          }}
        />

        <RenderIf validate={toolTip.x && toolTip.y}>
          {renderToolTip(
            toolTip,
            threshold,
            targetRange,
            bloodGlucoseUnit,
            theme,
            t,
            timeFormat,
            carbLabel,
          )}
        </RenderIf>
      </Fragment>
    );
  }

  onLineClick = (data) => (e) => {
    e.stopPropagation();
    clickStream$.next(data);
  };

  onGraphClick = () => {
    if (!this.props.collapsed) {
      this.setState({ selectedDate: null });
    }
  };
}

const renderToolTip = (
  { x, y, data },
  { data: thresholdData },
  { data: targetRangeData },
  bloodGlucoseUnit,
  theme,
  t,
  timeFormat,
  carbLabel,
) => {
  const {
    date,
    endDate,
    basalCbrf,
    value,
    beforeMeal,
    afterMeal,
    type,
    bolusValue,
    bolusValueOriginal,
    bolusRemark,
    tbr,
    insulin1,
    insulin2,
    insulin3,
    manuallyEntered,
  } = data;

  const icon = beforeMeal ? (
    <AppleIcon height="17" />
  ) : afterMeal ? (
    <AppleEatenIcon height="17" />
  ) : null;

  const insulinValues = [
    {
      label: t('graphs.insulin.bolusIns1'),
      value: bolusValueOriginal || bolusValue,
    },
    { label: t('graphs.insulin.bolusIns1'), value: insulin1 },
    { label: t('graphs.insulin.bolusIns2'), value: insulin2 },
    { label: t('graphs.insulin.bolusIns3'), value: insulin3 },
  ].filter(({ value }) => !isNil(value));

  return (
    <ToolTip x={x} y={y}>
      <RenderIf validate={type === DATA_TYPE.INSULIN}>
        <DetailInsulinToolTip
          topBarColor={colors.blueMarine}
          values={insulinValues}
          date={date}
          remark={bolusRemark}
          timeFormat={timeFormat}
        />
      </RenderIf>
      <RenderIf validate={type === DATA_TYPE.CARBOHYDRATES}>
        <DetailCarbohydratesToolTip
          topBarColor={theme.colors.blueMarine}
          timeFormat={timeFormat}
          {...{ date, value, carbLabel }}
        />
      </RenderIf>
      <RenderIf
        validate={type === DATA_TYPE.GLUCOSE || type === DATA_TYPE.MEAN_GLUCOSE}
      >
        <DetailGlucoseToolTip
          topBarColor={getToolTipValueColor(
            value,
            thresholdData.value,
            targetRangeData,
          )}
          timeFormat={timeFormat}
          {...{ icon, date, type, value, bloodGlucoseUnit, manuallyEntered }}
        />
      </RenderIf>
      <RenderIf validate={type === DATA_TYPE.PROFILE_CHANGE}>
        <DetailBasalEventTooltip
          topBarColor={colors.blueMarine}
          type={'graphs.iconTitles.basalRateProfile'}
          date={date}
          timeFormat={timeFormat}
        />
      </RenderIf>
      <RenderIf validate={type === DATA_TYPE.BASAL_RATE_CHANGE}>
        <DetailBasalEventTooltip
          topBarColor={colors.blueMarine}
          type={'graphs.iconTitles.basalRateChange'}
          date={date}
          timeFormat={timeFormat}
        />
      </RenderIf>
      <RenderIf validate={!!basalCbrf && type === DATA_TYPE.BASAL}>
        <DetailBasalTooltip
          topBarColor={theme.colors.blueMarine}
          value={basalCbrf}
          timeFormat={timeFormat}
          {...{ date, endDate, tbr }}
        />
      </RenderIf>
    </ToolTip>
  );
};

import styled from 'styled-components';
import { Title } from 'src/shared/design-system/fonts/title/title.style';

export const BrowserDisclaimerTitle = styled(Title)`
  color: ${(props) => props.theme.colors.brandBlue};
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
  max-width: 398px;
`;

export const BrowserDisclaimerSubTitle = styled.p`
  font-size: 1rem;
  max-width: 400px;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  color: ${(props) => props.theme.colors.charcoal};
  margin: 0.5rem;
  line-height: 22px;
`;

import {
  createAuthHeader,
  putJSON,
} from 'src/shared/utils/service/service.utils';
import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const transformBody = (program) =>
  JSON.stringify({
    deliveryProgramId: program.deliveryProgramId,
    initialStock: parseInt(program.initialStock, 10),
    securityPercentage: parseInt(program.securityPercentage, 10),
    supplyModelName: program.supplyModelName,
    timeGap: parseInt(program.timeGap, 10),
  });

export const PostDeliveryConfigurationLoaderImpl: FixMe = (
  accessToken: string,
  gigyaToken: string,
  patientId: string,
  program: FixMe,
) =>
  putJSON(
    endpointWithParams(ENDPOINTS.saveDeliveryConfiguration, { patientId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
      body: transformBody(program),
    },
  );

export const PostDeliveryConfigurationTransformImpl = (data: FixMe): FixMe =>
  data.model;

export const PostDeliveryConfigurationServiceImpl: FixMe =
  (load: FixMe, transform) => (accessToken, gigyaToken, patientId, program) =>
    load(accessToken, gigyaToken, patientId, program).then(transform);

import { path } from 'ramda';

export const selectDashboard = path(['ui', 'patientDashboard']);
export const selectGraph = path(['ui', 'patientDashboard', 'graph']);
export const selectGraphType = path(['ui', 'patientDashboard', 'graphType']);
export const selectLogbookType = path([
  'ui',
  'patientDashboard',
  'logbookType',
]);

const isNotControlMeasurement = (bg) => !bg.control;
const isGlucoseMeasurement = (bg) =>
  bg.value != null && isNotControlMeasurement(bg);

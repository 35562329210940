import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { fetchDevicesStart } from 'src/domains/patient/scenes/list-devices/store/list-devices.action';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { fetchSettingsListStart } from 'src/domains/diagnostics/scenes/device-settings-jello/store/device-settings-list/device-settings-list.action';
import {
  fetchAllDeviceSettingsDataStart,
  fetchSettingsStart,
} from 'src/domains/diagnostics/scenes/device-settings-jello/store/device-settings/device-settings.action';

import { DeviceSettingsComponent } from './device-settings.component';
import { deviceSettingsConnector } from './device-settings.selector';
import {
  DeviceSettingsProps,
  DeviceSettingsState,
} from './device-settings.types';

const dispatchers = mapDispatchers({
  fetchDevices: fetchDevicesStart,
  fetchDeviceSettingsList: fetchSettingsListStart,
  fetchDeviceSettings: fetchSettingsStart,
  fetchAllData: fetchAllDeviceSettingsDataStart,
});

export const DeviceSettingsContainer = compose(
  withRouter,
  withTranslation,
  connect(deviceSettingsConnector, dispatchers),
  lifecycle<DeviceSettingsProps, DeviceSettingsState>({
    componentDidMount() {
      const {
        match: { params },
        fetchAllData,
      } = this.props;
      fetchAllData(parseInt(params.id, 10));
    },
  }),
)(DeviceSettingsComponent);

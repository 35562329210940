import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { addAlertAction } from 'src/shared/design-system/alerts/store/alert.actions';
import { AlertType } from 'src/shared/design-system/alerts/store/alert.types';
import { ALERT_POSITION } from 'src/shared/design-system/alerts/store/alert.constans';

import { createModal } from 'src/shared/design-system/modal/store/modal/modal.actions';
import { MODAL_TYPES } from 'src/shared/design-system/modal/store/modal/modal.constants';
import { getCurrentUser } from 'src/app/store/user/user.actions';

import {
  getUserUnits,
  saveUserUnits,
  USER_UNITS_GET_REQUEST,
  USER_UNITS_SAVE_REQUEST,
} from './user-units-selector.actions';

import { SaveErrorModal } from 'src/domains/patient/scenes/INS-request-modal/save-error-modal/save-error.modal';

export const getUserUnitsEpic = (getUserUnitsService) => (action$, state$) =>
  action$.ofType(USER_UNITS_GET_REQUEST.START).flatMap(() => {
    const openId = {
      accessToken: selectAccessToken(state$.getState()),
      apiKey: selectGigyaToken(state$.getState()),
    };
    return Observable.fromPromise(getUserUnitsService(openId))
      .switchMap((userUnits) => [getUserUnits.success(userUnits)])
      .pipe(
        catchError((err) =>
          Observable.concat(Observable.of(getUserUnits.error(err))),
        ),
      );
  });

export const saveUserUnitsEpic = (saveUserUnitsService) => (action$, state$) =>
  action$.ofType(USER_UNITS_SAVE_REQUEST.START).flatMap(({ payload }) => {
    const openId = {
      accessToken: selectAccessToken(state$.getState()),
      gigyaToken: selectGigyaToken(state$.getState()),
    };
    const { bgUnitMeasurement, carbUnitMeasurement } = payload;
    return Observable.fromPromise(
      saveUserUnitsService(openId, { bgUnitMeasurement, carbUnitMeasurement }),
    )
      .switchMap((userUnits) => [
        saveUserUnits.success(userUnits),
        getCurrentUser.start(),
        addAlertAction({
          type: AlertType.SUCCESS,
          text: { [AlertType.SUCCESS]: 'alert.settings.success.message' },
          position: ALERT_POSITION.BOTTOM_RIGHT,
        }),
      ])
      .pipe(
        catchError((err) =>
          Observable.concat(
            Observable.of(saveUserUnits.error(err)),
            Observable.of(
              createModal({
                key: MODAL_TYPES.CUSTOM,
                data: {
                  customComponent: SaveErrorModal,
                  title: 'errorSettingsModal.header',
                  content: 'errorSettingsModal.targetRange.content',
                  textPrimaryBtn: 'errorSettingsModal.okBtn',
                },
              }),
            ),
          ),
        ),
      );
  });

import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';

import { CheckPairingCodeResponse } from './pairing-code-availability.types';
import { ENDPOINTS } from 'src/app/navigation/services/service.constants';

export const CheckPairingCodeAvailabilityLoaderImpl = (
  accessToken: string,
  gigyaToken: string,
) =>
  getJSON(ENDPOINTS.pairingCodeAvailability, {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  });

export const CheckPairingCodeAvailabilityTransformImpl = ({
  status,
}: CheckPairingCodeResponse): number => status;

export const CheckPairingCodeAvailabilityServiceImpl =
  (load, transform) => (accessToken, gigyaToken) =>
    load(accessToken, gigyaToken).then(transform);

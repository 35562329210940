export const ALERT_JELLO_EVENTS = {
  LINK_ACTION: 'jello-alert-link-action',
  CLOSE_ACTION: 'jello-alert-close-action',
};

export const SHOW_WHATS_NEW_ALERT_KEY = 'showWhatsNewAlert';

export const WHATS_NEW_ALERT_JELLO_ID = 'whats-new-alert-jello-id';

export const WHATS_NEW_LOKALISE_KEYS = {
  TITLE: 'whatsNewAlert.title',
  LINK_TEXT: 'whatsNewAlert.linkText',
  CLOSE_BUTTON_ACCESSIBILITY_TEXT: 'whatsNewAlert.closeButtonAccessibilityText',
  CONTENT: 'whatsNewAlert.content',
};

export const WHATS_NEW_ALERT_EVENTS_KEYS = {
  INTERACTION: 'What´s New popup viewed',
};

export const WHATS_NEW_ALERT_SEGMENT_KEYS = {
  ACTION: 'Interaction with the popup',
};

export const WHATS_NEW_ALERT_SEGMENTS_VALUES = {
  LINK_CLICKED: 'Clicked on the link',
  CLOSED_DIRECTLY: 'Closed Directly',
};

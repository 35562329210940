/* tslint:disable */

import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';
import { DeviceSettingsListLoaderImplType } from './device-setttings-list.types';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const DeviceSettingsListLoaderImpl: DeviceSettingsListLoaderImplType = (
  { patientId, deviceSerialNumber, patientType },
  accessToken: string,
  gigyaToken,
) => {
  return getJSON(
    endpointWithParams(ENDPOINTS.deviceSettingsList, {
      deviceSerialNumber,
      patientId,
      patientType,
    }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );
};

export const DeviceSettingsListTransformImpl: any = (data) => data;

export const DeviceSettingsListServiceImpl: any =
  (load, transform) => (params, accessToken, gigyaToken) =>
    load(params, accessToken, gigyaToken).then(transform);

import styled from 'styled-components';

import { colors } from 'src/app/styles/colors';
import { convertPxToRem } from 'src/shared/utils/rem-calc';

import { weight } from '../weights/weight.style';

export const Title = styled.h6`
  font-size: ${convertPxToRem(20)};
  font-weight: ${weight};
  margin: 0;
`;

export const TitleName = styled(Title)`
  color: ${colors.brandBlue};
  font-weight: ${weight.semiBold};
  ${(props) => props.uppercase && 'text-transform: uppercase'};
`;

import {
  EnrollPatientToStudyStartAction,
  EnrollPatientToStudyActionType,
  EnrollPatientToStudySuccessAction,
  EnrollPatientToStudyErrorAction,
  ClearEnrollPatientToStudyAction,
  EnrollPatientToStudyStartPayload,
} from './enroll-patient-to-study.types';
import {
  createAction,
  createPayloadAction,
} from '../../../../../../../app/store/app.actions';

export const enrollPatientToStudyStart = (
  payload: EnrollPatientToStudyStartPayload,
): EnrollPatientToStudyStartAction =>
  createPayloadAction(
    EnrollPatientToStudyActionType.ENROLL_PATIENT_TO_STUDY_START,
    payload,
  );

export const enrollPatientToStudySuccess =
  (): EnrollPatientToStudySuccessAction =>
    createAction(
      EnrollPatientToStudyActionType.ENROLL_PATIENT_TO_STUDY_SUCCESS,
    );

export const enrollPatientToStudyError = (): EnrollPatientToStudyErrorAction =>
  createAction(EnrollPatientToStudyActionType.ENROLL_PATIENT_TO_STUDY_ERROR);

export const clearEnrollPatientToStudy = (): ClearEnrollPatientToStudyAction =>
  createAction(EnrollPatientToStudyActionType.CLEAR_ENROLL_PATIENT_TO_STUDY);

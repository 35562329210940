export const INTERVENTIONAL_CLINICAL_STUDY_PATH = 'interventionalClinicalStudy';
export const OBSERVATIONAL_CLINICAL_STUDY_PATH = 'observationalClinicalStudy';
export const UNBLIND_STUDY_MODAL_PATH = 'unblindStudyModal';
export const UNBLIND_STUDY_ERROR_MODAL_PATH = 'unblindStudyErrorModal';

export const INTERVENTIONAL_CLINICAL_STUDY_TAB =
  'interventional-clinical-study-tab';
export const OBSERVATIONAL_CLINICAL_STUDY_TAB =
  'observational-clinical-study-tab';

export const ENROLL_CLINICAL_STUDY = 'enroll-clinical-study';
export const ROLL_OFF_CLINICAL_STUDY = 'roll-off-clinical-study';

export const OBSERVATIONAL_STATES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const INTERVENTIONAL_PATH = {
  TAB_TITLE: `${INTERVENTIONAL_CLINICAL_STUDY_PATH}.title`,
  TEXT_CONTENT: `${INTERVENTIONAL_CLINICAL_STUDY_PATH}.content`,
  CHECKBOX_TEXT: `${INTERVENTIONAL_CLINICAL_STUDY_PATH}.checkboxText`,
  UNBLIND_BUTTON_TEXT: `${INTERVENTIONAL_CLINICAL_STUDY_PATH}.buttonText`,
  UNBLINDED_CONTENT: `${INTERVENTIONAL_CLINICAL_STUDY_PATH}.unblindedContent`,
  UNBLIND_ERROR_MODAL_BUTTON: `${UNBLIND_STUDY_ERROR_MODAL_PATH}.btnText`,
  UNBLIND_ERROR_MODAL_TITLE: `${UNBLIND_STUDY_ERROR_MODAL_PATH}.title`,
  UNBLIND_ERROR_MODAL_CONTENT: `${UNBLIND_STUDY_ERROR_MODAL_PATH}.content`,
  UNBLIND_MODAL_PRIMARY_BUTTON: `${UNBLIND_STUDY_MODAL_PATH}.primaryBtn`,
  UNBLIND_MODAL_SECONDARY_BUTTON: `${UNBLIND_STUDY_MODAL_PATH}.secondaryBtn`,
  UNBLIND_MODAL_HEADER_TITLE: `${UNBLIND_STUDY_MODAL_PATH}.headerTitle`,
  UNBLIND_MODAL_CONTENT_TITLE: `${UNBLIND_STUDY_MODAL_PATH}.contentTitle`,
  UNBLIND_MODAL_CONTENT_TEXT: `${UNBLIND_STUDY_MODAL_PATH}.contentText`,
};

export const OBSERVATIONAL_PATH = {
  TAB_TITLE: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.title`,
  ENROLL_SELECT_CLINICAL_STUDY: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enroll.selectClinicalStudy`,
  ENROLL_SELECT_LABEL: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enroll.selectLabel`,
  ENROLL_SELECT_PARTICIPANT_ID: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enroll.selectParticipantId`,
  ACCESIBILITY_TEXT_CLEAR_BUTTON: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enroll.clearButtonAccessibilityText`,
  ACCESIBILITY_TEXT_BUTTON: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enroll.searchButtonAccessibilityText`,
  SEARCHBOX_PLACEHOLDER: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enroll.searchboxPlaceholder`,
  ENROLL_CHECKBOX: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enroll.confirmCheckbox`,
  ENROLL_BUTTON: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enroll.enrollButton`,
  ROLLOFF_CONTENT_INFO: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.rollOff.contentInfo`,
  ROLLOFF_WITHDRAW_TEXT: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.rollOff.withdrawText`,
  ROLLOFF_CHECKBOX: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.rollOff.confirmCheckbox`,
  ROLLOFF_BUTTON: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.rollOff.rollOffButton`,
  CONFLICT_VIEW_CONTENT: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.conflictView.content`,
  PARTICIPANT_ID_NOT_VALID_FORMAT: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.participantIdError.notValidFormat`,
  PARTICIPANT_ID_NOT_FOUND: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.participantIdError.notFound`,
  PARTICIPANT_ID_NO_ITEMS: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.participantIdError.noItems`,
  PARTICIPANT_ID_DEFAULT_OPTION: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enroll.participantIdDefaultOption`,
  ENROLL_MODAL_PRIMARY_BUTTON: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enrollModal.enrollBtn`,
  ENROLL_MODAL_SECONDARY_BUTTON: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enrollModal.cancelBtn`,
  ENROLL_MODAL_HEADER_TITLE: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enrollModal.headerTitle`,
  ENROLL_MODAL_CONTENT_TITLE: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enrollModal.contentTitle`,
  ENROLL_MODAL_CONTENT_TEXT: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enrollModal.contentText`,
  ROLL_OFF_MODAL_PRIMARY_BUTTON: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.rollOffModal.withdrawBtn`,
  ROLL_OFF_MODAL_HEADER_TITLE: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.rollOffModal.headerTitle`,
  ROLL_OFF_MODAL_CONTENT_TITLE: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.rollOffModal.contentTitle`,
  ROLL_OFF_MODAL_CONTENT_TEXT: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.rollOffModal.contentText`,
  ROLL_OFF_ALERT_SUCCESS_TEXT: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.rollOffAlert.successText`,
  ROLL_OFF_ALERT_ERROR_TEXT: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.rollOffAlert.errorText`,
  ENROLL_ALERT_SUCCESS_TEXT: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enrollAlert.successText`,
  ENROLL_ALERT_ERROR_TEXT: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enrollAlert.errorText`,
  ENROLL_ALERT_ERROR_CONTENT: `${OBSERVATIONAL_CLINICAL_STUDY_PATH}.enrollAlert.errorContent`,
};

export const SELECT_OPTION_DEFAULT = {
  label: OBSERVATIONAL_PATH.PARTICIPANT_ID_DEFAULT_OPTION,
  value: '',
};

export const errorMessages = {
  notValidFormat: OBSERVATIONAL_PATH.PARTICIPANT_ID_NOT_VALID_FORMAT,
  notFound: OBSERVATIONAL_PATH.PARTICIPANT_ID_NOT_FOUND,
  noItems: OBSERVATIONAL_PATH.PARTICIPANT_ID_NO_ITEMS,
};

export const NUMBER_DASH_FORMAT_REGEX =
  /^\d{0,2}(-\d{0,3})?$|^\d{0,2}$|^\d{0,2}-?$/;

export const LINKED_ERROR_CODE = 404;
export const CONFLICT_ERROR_CODE = 409;
export const SUCCESS_CODE = 200;

export const ALERT_TEXT = {
  [OBSERVATIONAL_STATES.ACTIVE]: {
    SUCCESS: OBSERVATIONAL_PATH.ENROLL_ALERT_SUCCESS_TEXT,
    ERROR: OBSERVATIONAL_PATH.ENROLL_ALERT_ERROR_TEXT,
  },
  [OBSERVATIONAL_STATES.INACTIVE]: {
    SUCCESS: OBSERVATIONAL_PATH.ROLL_OFF_ALERT_SUCCESS_TEXT,
    ERROR: OBSERVATIONAL_PATH.ROLL_OFF_ALERT_ERROR_TEXT,
  },
};

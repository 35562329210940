import * as React from 'react';
import { RenderIf } from 'src/shared/utils/render-if';
import { includes, valuesIn } from 'ramda';

import styles from './styles.module.css';

import { testId } from '@roche/roche-common';

import { TopNavigationProps } from 'src/widgets/top-navigation-jello/top-navigation-jello.types';
import {
  JELLO_SEARCHBOX_SIZES,
  JELLO_TOOLBAR_ORIENTATIONS,
  JELLO_TOOLBAR_SIZES,
} from 'src/app/app.jello.constants';

import {
  JELLO_SEARCH_BOX_INPUT_EVENT,
  JELLO_SEARCH_BOX_LOKALISATION_KEYS,
  SCOPE_ID,
  TOOLBAR_ID,
  WAFFLE_ELEMENT_LIST,
} from './top-navigation-jello.constants';
import {
  determineSearchFunction,
  getSearchBoxPlaceHolder,
  isHomeCgmRoute,
  isHomeRoute,
  isUnavailableRouteForNewHeader,
  shouldTriggerSearch,
  validatePermissions,
} from './top-navigation-jello.utils';
import { AppLogoComponent } from './components/app-logo/app-logo.component';
import { DepCenterNameComponent } from './components/dep-center-name/dep-center-name.component';
import { NotificationsCenterComponent } from './components/notifications-center/notifications-center.component';
import { BackHomeButtonComponent } from './components/back-home-button/back-home-button.component';
import { UploadDataButtonComponent } from './components/upload-data-button/upload-data-button.component';
import { HelpCenterComponent } from './components/help-center/help-center.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { WaffleMenuComponent } from './components/waffle-menu/waffle-menu.component';
import { t } from 'i18next';
import { JelloSearchBox } from 'src/app/navigation/scenes/jello-search-box/jello-search-box.component';

import { Favicon } from 'src/app/favicon/favicon.container';
import { useHandleNotificationRedirect } from './hooks/useHandleNotificationRedirect';

export const TopNavigationJelloComponent = ({
  routes,
  location,
  profile,
  departmentName,
  centerName,
  professionalName,
  c2aIndividual,
  isHcpOrGp,
  hasMngHome,
  filterPatientsSet,
  search,
  goHome,
  goTo,
  defaultFilters,
  unitGlucoseForService,
  dashboardLocation,
  isFhir,
  searchAdmin,
  locale,
  createModal,
  hasNotificationCenterPermission,
  hasDeviceLinkPermission,
  updateSearchTerm,
  searchTerm,
  isAdmin,
  showTimeTrackerExportModal,
  exportPatientsSessionStart,
  permissions,
  clearCriteria,
  rebranding,
  updateSearchByTab,
  isPatientExportData,
  patientExportData,
  is12HourFormat,
  clearPatientExportData,
  destroyModal,
  userAnnouncements,
}: TopNavigationProps) => {
  useHandleNotificationRedirect({ goTo });

  const isAuthentication = includes(
    location.pathname,
    valuesIn(routes.authentication),
  );

  const hasUnavailableRouteForNewHeader = isUnavailableRouteForNewHeader(
    location.pathname,
  );

  // istanbul ignore next
  const onSearchHcp = (term: string) => {
    filterPatientsSet(defaultFilters);
    search(unitGlucoseForService, dashboardLocation, term, isFhir);
    updateSearchByTab(false);
    goHome();
  };

  // istanbul ignore next
  const onSearchAdmin = (term: string) => {
    searchAdmin({ fullName: term });
    goHome();
  };

  // istanbul ignore next
  const clearSearchInput = () => {
    clearCriteria();
    filterPatientsSet(defaultFilters);
    search(unitGlucoseForService, dashboardLocation, '', isFhir);
    updateSearchByTab(false);
  };

  // istanbul ignore next
  const onHandleSearch = (event) => {
    const value = event.target.value;

    if (event.type === JELLO_SEARCH_BOX_INPUT_EVENT) {
      updateSearchTerm(value);
    }

    if (shouldTriggerSearch(value, event.type)) {
      determineSearchFunction(
        isHcpOrGp,
        hasMngHome,
        onSearchHcp,
        onSearchAdmin,
      )(value);
    }
  };

  const searchBoxPlaceHolder = t(getSearchBoxPlaceHolder(hasMngHome));

  return (
    <>
      <Favicon />
      <RenderIf
        validate={
          !isAuthentication && !!profile && !hasUnavailableRouteForNewHeader
        }
      >
        <jello-toolbar
          orientation={JELLO_TOOLBAR_ORIENTATIONS.HORIZONTAL}
          size={JELLO_TOOLBAR_SIZES.S}
          {...testId(SCOPE_ID, TOOLBAR_ID)}
        >
          {/* First block  */}
          <div className={`${styles.wrapperBlocks} ${styles.start}`}>
            <AppLogoComponent
              routes={routes}
              clearSearchInput={clearSearchInput}
              rebranding={rebranding}
            />
            <RenderIf
              validate={validatePermissions(
                routes,
                profile,
                isHcpOrGp,
                hasMngHome,
              )}
            >
              <div className={styles.divider} />
              <DepCenterNameComponent
                departmentName={departmentName}
                centerName={centerName}
                c2aIndividual={c2aIndividual}
              />
            </RenderIf>
          </div>
          {/* Second block  */}
          <div className={`${styles.wrapperBlocks} ${styles.center}`}>
            <RenderIf
              validate={validatePermissions(routes, profile, true, hasMngHome)}
            >
              <JelloSearchBox
                id={SCOPE_ID}
                outlined={false}
                rounded={true}
                size={JELLO_SEARCHBOX_SIZES.S}
                placeholder={searchBoxPlaceHolder}
                ariaLabel={searchBoxPlaceHolder}
                clearButtonAccessibilityText={t(
                  JELLO_SEARCH_BOX_LOKALISATION_KEYS.SEARCH_BOX_CLEAR_BUTTON_ACCESSIBILITY_TEXT,
                )}
                searchButtonAccessibilityText={t(
                  JELLO_SEARCH_BOX_LOKALISATION_KEYS.SEARCH_BOX_SEARCH_BUTTON_ACCESSIBILITY_TEXT,
                )}
                searchCallback={onHandleSearch}
                value={searchTerm}
              />
            </RenderIf>
          </div>
          {/* Third block  */}
          <div className={`${styles.wrapperBlocks} ${styles.end}`}>
            <BackHomeButtonComponent
              clearSearchInput={clearSearchInput}
              routes={routes}
            />
            <RenderIf validate={isHcpOrGp || isAdmin}>
              <div className={styles.divider} />
              <div className={styles.dynamicItems}>
                <WaffleMenuComponent
                  showTimeTrackerExportModal={showTimeTrackerExportModal}
                  locale={locale}
                  exportPatientsSessionStart={exportPatientsSessionStart}
                  waffleElementList={WAFFLE_ELEMENT_LIST}
                  permissions={permissions}
                  routes={routes}
                  is12HourFormat={is12HourFormat}
                  isPatientExportData={isPatientExportData}
                  patientExportData={patientExportData}
                  clearPatientExportData={clearPatientExportData}
                  destroyModal={destroyModal}
                />
                <UploadDataButtonComponent
                  route={routes?.newDeviceLink.main}
                  createModal={createModal}
                  hasDeviceLinkPermission={hasDeviceLinkPermission}
                />
                <HelpCenterComponent
                  locale={locale}
                  createModal={createModal}
                  destroyModal={destroyModal}
                  rebranding={rebranding}
                  showWhatsNewMenu={!!userAnnouncements}
                />
                <RenderIf
                  validate={
                    hasNotificationCenterPermission &&
                    (isHomeRoute(routes) || isHomeCgmRoute(routes))
                  }
                >
                  <NotificationsCenterComponent />
                </RenderIf>
              </div>
            </RenderIf>
            <div className={styles.divider} />
            <UserAvatarComponent name={professionalName} routes={routes} />
          </div>
        </jello-toolbar>
        <div className={styles.bottomDivider} />
      </RenderIf>
    </>
  );
};

import { LOCATION_CHANGE } from 'react-router-redux';

import { SEARCH_PATIENTS_REQUEST } from './patient-search.constants';
import { PatientSearchActionType } from './patient-search.types';

import { STATE_ACTIONS } from '../../../../../app/store/state/state.constants';
import { DeviceAssignmentActionType } from '../../../store/device-assignment/device-assignment.types';

export const INITIAL_PATIENT_SEARCH_STATE = {
  data: [],
  error: '',
  isSearching: false,
  didSearch: false,
  searchBarOptions: {},
  fullName: '',
  patientID: '',
  resultsPage: 1,
};

export const patientSearchReducer = (
  state = INITIAL_PATIENT_SEARCH_STATE,
  action = {},
) => {
  switch (action.type) {
    case SEARCH_PATIENTS_REQUEST.SUCCESS:
      return {
        ...state,
        data: action.payload,
        didSearch: true,
        isSearching: false,
      };
    case SEARCH_PATIENTS_REQUEST.START:
      return {
        ...state,
        isSearching: true,
        fullName: action.payload.fullName,
      };
    case SEARCH_PATIENTS_REQUEST.ERROR:
      return {
        ...state,
        error: action.payload,
        didSearch: true,
        isSearching: false,
      };
    case STATE_ACTIONS.CLEAR_PATIENT_SEARCH_RESULTS:
      return INITIAL_PATIENT_SEARCH_STATE;
    case DeviceAssignmentActionType.CANCEL_ASSIGNMENT:
      return INITIAL_PATIENT_SEARCH_STATE;
    case PatientSearchActionType.SET_PATIENT_SEARCH_RESULTS_PAGE:
      return { ...state, resultsPage: action.payload.page };
    case LOCATION_CHANGE:
      if (action.payload.pathname.match(/home/)) {
        return INITIAL_PATIENT_SEARCH_STATE;
      } else {
        return state;
      }

    default:
      return state;
  }
};

import * as React from 'react';
import { withTheme } from 'styled-components';

import { ArrowIcon } from '../../../shared/design-system/icons';
import { ThemeInterface } from '../../../shared/theme';

import {
  DropdownSelectButton,
  DropdownSelectContainer,
  DropdownSelectDisplay,
  DropdownSelectDropdownContainer,
  DropdownSelectDropdownRow,
  DropdownSelectDropdownTitleRow,
  DropdownSelectInputContainer,
} from './dropdown-select.styles';

import {
  DropdownSelectProps,
  DropdownSelectPropsWithDefault,
  DropdownSelectState,
  SelectOption,
} from './dropdown-select.types';

import { RenderIf } from '../../../shared/utils/render-if';
import { isNotNil } from '../../../shared/utils/validation-helpers';

export class DropdownSelectComponent extends React.Component<
  DropdownSelectProps<SelectOption<string | number>>,
  DropdownSelectState<SelectOption<string | number>>
> {
  public wrapper: HTMLElement;
  private ref = React.createRef<HTMLElement>();

  constructor(props: DropdownSelectProps<SelectOption<string | number>>) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  public componentDidMount() {
    if (this.ref.current) {
      this.wrapper = this.ref.current;
    }

    this.checkSelectedOption();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public checkSelectedOption = () => {
    if (!this.props.selectedOption && this.props.options.length > 0) {
      this.handleOptionSelected(this.props.options[0]);
      return this.props.options[0];
    }
    if (this.props.selectedOption) {
      return this.props.selectedOption;
    }
    return false;
  };

  public handleClickOutside = (event) => {
    if (this.wrapper && !this.wrapper.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  public handleOptionSelected = (item: any) => () => {
    this.setState({
      isOpen: false,
    });

    if (this.props.onChange) {
      this.props.onChange(item);
    }
  };

  public toggleDropdown = (event) => {
    event.stopPropagation();
    this.setState({ isOpen: !this.state.isOpen });
  };

  public render() {
    const {
      fillColor,
      arrowIconHeight,
      disabled,
      width,
      borderRadius,
      borderColor,
      flex,
      optionFontSize,
      DisplayComponent,
      DisplayOptionComponent,
      showArrow,
    } = this.props as DropdownSelectPropsWithDefault;

    const isOpenClass = this.state.isOpen ? 'is-open' : '';
    const isDisabledClass = disabled ? 'is-disabled' : '';

    const selectedOption: any = this.checkSelectedOption();
    const initialValue = selectedOption.label
      ? selectedOption.label
      : this.props.placeholder;

    return (
      <DropdownSelectContainer
        width={width}
        flex={flex}
        onClick={this.toggleDropdown}
        ref={this.ref}
      >
        <DropdownSelectInputContainer
          className={`${isOpenClass}`}
          borderRadius={borderRadius}
          borderColor={borderColor}
        >
          <DropdownSelectDisplay
            className={isDisabledClass}
            borderRadius={borderRadius}
          >
            {DisplayComponent && selectedOption ? (
              <DisplayComponent values={selectedOption}>
                {initialValue}
              </DisplayComponent>
            ) : (
              initialValue
            )}
          </DropdownSelectDisplay>
          <RenderIf validate={showArrow}>
            <DropdownSelectButton
              className={`${isDisabledClass} ${isOpenClass}`}
              disabled={disabled}
              borderRadius={borderRadius}
            >
              <ArrowIcon height={arrowIconHeight} fillColor={fillColor} />
            </DropdownSelectButton>
          </RenderIf>
        </DropdownSelectInputContainer>
        <RenderIf validate={this.state.isOpen}>
          <DropdownSelectDropdownContainer
            borderRadius={borderRadius}
            borderColor={borderColor}
            hasTitle={isNotNil(this.props.titleOptions)}
          >
            <RenderIf validate={this.props.titleOptions}>
              <DropdownSelectDropdownTitleRow>
                {this.props.titleOptions}
              </DropdownSelectDropdownTitleRow>
            </RenderIf>

            {this.props.options.map((option, idx) => (
              <DropdownSelectDropdownRow
                key={idx}
                onClick={this.handleOptionSelected(option)}
                optionFontSize={optionFontSize}
                className={`dropdown-select-options ${
                  option.value === selectedOption.value ? ' is-selected' : ''
                }`}
              >
                {DisplayOptionComponent ? (
                  <DisplayOptionComponent values={option}>
                    {option.label}
                  </DisplayOptionComponent>
                ) : (
                  option.label
                )}
              </DropdownSelectDropdownRow>
            ))}
          </DropdownSelectDropdownContainer>
        </RenderIf>
      </DropdownSelectContainer>
    );
  }
}

export const DropdownSelect = withTheme<
  { theme?: ThemeInterface } & DropdownSelectProps<
    SelectOption<string | number>
  >,
  ThemeInterface
>(DropdownSelectComponent);

import styled from 'styled-components';
import { color, fontSize, space, textAlign, width } from 'styled-system';

export const TableCell = styled.td.attrs({
  colspan: (props) => props.colSpan,
})`
  ${width};
  ${color};
  ${space};
  ${fontSize};
  ${textAlign};
`;

import React from 'react';
import {
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
} from 'src/app/app.jello.constants';
import { DataSharingStatusBtn } from '../../data-sharing/data-sharing-status-btn/data-sharing-status-btn.container';
import styles from './styles.module.css';

const { M } = JELLO_TEXT_SIZES;
const { SEMIBOLD } = JELLO_TEXT_WEIGHTS;

export const PatientMainData = ({ surName, firstName }) => {
  const jelloTextProps = {
    class: styles.patientMainDataText,
    size: M,
    weight: SEMIBOLD,
  };

  const patientFullName = `${surName}, ${firstName}`;

  const dataSharingStatusBtnProps = {
    small: true,
  };

  return (
    <div className={styles.patientMainDataWrapper}>
      <jello-text {...jelloTextProps}>{patientFullName}</jello-text>

      <DataSharingStatusBtn {...dataSharingStatusBtnProps} />
    </div>
  );
};

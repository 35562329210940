import * as React from 'react';

import NotificationContainer from 'src/app/modules/notification-module';
import { NotificationModuleWrapper } from './notifications-center.style';

export const NotificationsCenterComponent = () => {
  return (
    <NotificationModuleWrapper>
      <NotificationContainer />
    </NotificationModuleWrapper>
  );
};

import * as React from 'react';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import { formatBGMeasurement } from 'src/domains/diagnostics/utils/measurements';
import { isNil, times } from 'ramda';
import {
  BloodGlucoseContentsDiv,
  BloodGlucoseContentsHyperDiv,
  BloodGlucoseContentsHypoDiv,
} from 'src/domains/diagnostics/widgets/logbook-diary/logbook-diary.style';
import { MeasurementCell } from 'src/domains/diagnostics/widgets/logbook-24hrs/logbook-24hrs.style';
import { isNotNil } from 'src/shared/utils/validation-helpers';
import {
  HI_OR_LO,
  HI_VALUE,
  LO_VALUE,
} from 'src/domains/diagnostics/store/constants';

export const MeasurementCellGroup = ({
  measurements24hours,
  measurementType,
  cellKey,
  measurementRowDifference,
  bloodGlucoseUnit,
}) => {
  return (
    <React.Fragment>
      {/* Render cells with values */}
      {measurements24hours.map((measurement, i) => {
        const isLastCell = measurements24hours.length - 1 === i;
        const doesNotContainsEmptyCell = measurementRowDifference <= 0;
        return (
          <MeasurementCell
            data-testid={`logbook-measurement-cell-${cellKey}-${i}-${measurement.dateStringISO}`}
            key={`bg-${cellKey}-${i}-${measurement.dateStringISO}`}
            isLastCell={isLastCell && doesNotContainsEmptyCell}
          >
            {generateCellContent(
              measurement,
              measurementType,
              bloodGlucoseUnit,
              measurement.rangeType,
            )}
          </MeasurementCell>
        );
      })}
      {/* Render empty cells */}
      <RenderIf validate={measurementRowDifference > 0}>
        {times(
          (x) => (
            <MeasurementCell
              key={`bg-empty-${cellKey}-${x}`}
              isLastCell={false}
            >
              {''}
            </MeasurementCell>
          ),
          // Exclude last empty cell
          measurementRowDifference > 0
            ? measurementRowDifference - 1
            : measurementRowDifference,
        )}
        {/* Add last empty cell with border */}
        <MeasurementCell
          key={`bg-empty-${cellKey}-${measurementRowDifference + 1}`}
          isLastCell={true}
        >
          {''}
        </MeasurementCell>
      </RenderIf>
    </React.Fragment>
  );
};

const generateCellContent = (
  measurement,
  measurementType,
  bloodGlucoseUnit,
  rangeType,
) => {
  let showValuesZero = '';
  if (measurementType === 'bloodGlucose') {
    return renderBloodGlucoseCellContentsDivType({
      ...measurement,
      bloodGlucoseUnit,
      rangeType,
    });
  } else {
    showValuesZero = measurement[measurementType];
  }
  return showValuesZero;
};

const renderBloodGlucoseCellContentsDivType = ({
  belowTargetRange,
  aboveTargetRange,
  hypoSymptoms,
  glucoseValue,
  bloodGlucoseUnit,
  rangeType,
}) => {
  if (!isNil(glucoseValue) || isNotNil(rangeType)) {
    if (aboveTargetRange || rangeType === HI_VALUE) {
      return (
        <BloodGlucoseContentsHyperDiv id={rangeType ? HI_OR_LO : ''}>
          {formatBGMeasurement(bloodGlucoseUnit)(glucoseValue ?? rangeType)}
        </BloodGlucoseContentsHyperDiv>
      );
    } else if (belowTargetRange || rangeType === LO_VALUE) {
      return (
        <BloodGlucoseContentsHypoDiv
          withInnerBorder={hypoSymptoms}
          id={rangeType ? HI_OR_LO : ''}
        >
          {formatBGMeasurement(bloodGlucoseUnit)(glucoseValue ?? rangeType)}
        </BloodGlucoseContentsHypoDiv>
      );
    }
  }

  return (
    <BloodGlucoseContentsDiv>
      {formatBGMeasurement(bloodGlucoseUnit)(glucoseValue)}
    </BloodGlucoseContentsDiv>
  );
};

import React from 'react';
import styles from './styles.module.css';
import { JelloOptionPicker } from 'src/shared/design-system/option-picker-jello/option-picker-jello';

import { t } from 'i18next';

import { TIME_INTERVAL } from 'src/domains/diagnostics/constants/diagnostics.constants';
import { JELLO_OPTION_PICKER_BACKGROUND } from 'src/app/app.jello.constants';

const intervals = [
  {
    label: 'bloodGlucoseOverview.intervals.quarterly',
    value: TIME_INTERVAL.QUARTERLY_INTERVALS,
  },
  {
    label: 'bloodGlucoseOverview.intervals.monthly',
    value: TIME_INTERVAL.MONTHLY_INTERVALS,
  },
  {
    label: 'bloodGlucoseOverview.intervals.weekly',
    value: TIME_INTERVAL.WEEKLY_INTERVALS,
  },
];

export const TimeIntervalsFilterComponent = ({
  onChangeInterval,
  updateTimeIntervalsFilter,
}) => {
  const id = 'time-intervals-filter';
  const backgroundColor = JELLO_OPTION_PICKER_BACKGROUND.BACKGROUND_01;
  const selectedIndexByDefault = '2';
  const accessibilityText = t(
    'bloodGlucoseOverview.intervals.accessibilityText',
  );
  const modelPath = 'ui.patientDashboard.bgOverview.timeInterval';

  const translatedIntervals = intervals.map(({ label, value }) => ({
    label: t(label),
    value,
  }));

  /* istanbul ignore next */
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target && e.target.value) {
      const value = e.target.value;
      onChangeInterval(value);
      updateTimeIntervalsFilter(modelPath, value);
    }
  };

  return (
    <div className={styles.timeIntervalsFilter}>
      <JelloOptionPicker
        id={id}
        backgroundColor={backgroundColor}
        options={translatedIntervals}
        accessibilityText={accessibilityText}
        selectedIndex={selectedIndexByDefault}
        onChange={onChange}
      />
    </div>
  );
};

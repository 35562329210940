import {
  CheckPairingCodeLoaderImpl,
  CheckPairingCodeServiceImpl,
  CheckPairingCodeTransformImpl,
} from './pairing-code-check.service';
import { checkPairingCodeResponseType } from './pairing-code-check.types';
import { pairingCodeCheckMockResponse } from './pairing-code-check.mock';
import { Promise } from 'es6-promise';

export const mockLoader = (): Promise<checkPairingCodeResponseType> =>
  Promise.resolve(pairingCodeCheckMockResponse);

export const CheckPairingCodeFactoryImp = ({ devMode }) => {
  const loader = devMode ? mockLoader : CheckPairingCodeLoaderImpl;
  return CheckPairingCodeServiceImpl(loader, CheckPairingCodeTransformImpl);
};

import styled from 'styled-components';

import { fontSize } from 'src/app/styles/font-sizes';
import { colors } from 'src/app/styles/colors';
import { Subheading } from 'src/shared/design-system/fonts/subheading/subheading.style';
import { Title } from 'src/shared/design-system/fonts/title/title.style';

type ManufacturerLeftItemLinkProps = {
  active: boolean;
};

export const ManufacturerLeftItemLink = styled.a<ManufacturerLeftItemLinkProps>`
  width: 100%;
  text-decoration: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: ${(props) =>
    props.active ? props.theme.colors.blue800 : props.theme.colors.brandBlue};
  &: visited {
    text-decoration: none;
    color: ${(props) =>
      props.active ? props.theme.colors.blue800 : props.theme.colors.brandBlue};
  }
`;

export const FlexWrapperDiv = styled.div`
  color: ${(props) => props.theme.colors.charcoal};
  display: flex;
`;

export const ManufacturerInfoTitle = styled(Title)`
  color: ${(props) => props.theme.colors.black};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeights.semiBold};
  font-size: ${fontSize.headline};
  line-height: 32px;
  margin-bottom: ${(props) => props.theme.spacing.three};
`;

export const ManufacturerInfoSubTitle = styled(Subheading)`
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
  font-size: ${fontSize.subheading};
  line-height: 24px;
  color: ${(props) => props.theme.colors.black};
`;
export const ManufacturerInfoIntenUseDescription = styled.div`
  font-size: 14px;
  .intended-subtitle {
    font-weight: 700;
    margin-top: 14px;
  }
  .intended-subtitle.product {
    font-weight: 400;
    font-size: 16px;
    b {
      font-weight: 600;
    }
  }
  p {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    margin: 0;
    line-height: 20px;
  }
  b {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    line-height: 24px;
    color: ${(props) => props.theme.colors.black};
  }
`;

export const ManufacturerInfoImportantInfoDescription = styled.div`
  font-size: 14px;
  .important-info-p {
    margin-top: 1.5rem;
  }
  p {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    line-height: 20px;
    margin: 0;
  }
  b {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    line-height: 24px;
    color: ${(props) => props.theme.colors.black};
  }
`;

export const TextBlock = styled.div`
  color: ${(props) => props.theme.colors.black};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${fontSize.p};
  line-height: 18px;
  padding-right: 2px;
`;

export const ManufacturerInformationText = styled.p`
  margin: 0;

  span {
    padding-left: 15px;
  }
`;

export const GeneralUseText = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const GeneralUseTextImpressum = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  p {
    margin: 0px;
  }
`;

export const GeneralUseTextBold = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin: 0px;
`;

// to preserve spacing and line breaks between trademarks
export const TrademarkSpan = styled.span`
  display: inline-block;
  white-space: pre;
`;
TrademarkSpan.displayName = 'TrademarkSpan';

export const BlockTitle = styled.div`
  padding-bottom: 30px;
`;

export const SingleBlockText = styled.div`
  padding-bottom: 15px;
`;

export const WarningCardContent = styled.div`
  padding-top: 4px;
  font-size: 14px;
`;
export const WarningCard = styled.div`
  background-color: ${colors.colorBrand05};
  border-radius: 0.5rem;
  margin-top: 15px;
  padding: 16px 16px 16px 24px;
  width: 100%;
  .warning-icon-disclaimers {
    color: ${colors.brandBlue};
  }
  p {
    margin: 4px 0 4px 0;
  }
  .warning-sub-text-top {
    margin-bottom: 0;
  }
  .warning-sub-text-bottom {
    margin-top: 0;
  }
`;
export const WarningCardContainer = styled.div`
  .warning-text-with-icon-container,
  .warning-text-with-icon-container-with-multiple-text {
    padding-left: 39px;
  }
  .warning-text-with-icon-container-with-multiple-text {
    padding-top: 0px;
  }
  .warning-box-title {
    text-transform: uppercase;
  }
`;
export const WarningIconContainer = styled.div`
  float: left;
  padding-right: 15px;
`;

export const WarningSecondaryContainer = styled.div`
  padding-top: 16px;
`;

export const WarningsMainContainer = styled.div`
  padding-top: 6px;
  padding-bottom: 9px;
`;

import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import * as React from 'react';
import { WhatsNewMenuItem } from './whats-new-help-menu.component';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import { openWhatsNewModal } from '../../../store/announcements.actions';
import { USER_ANNOUNCEMENT_TYPE } from 'src/domains/announcements/store/announcements.constants';

export const WhatsNewHelpMenu = compose<
  { handleClick: () => void },
  { children?: React.ReactNode }
>(
  connect(
    null,
    mapDispatchers({
      openWhatsNewModal,
    }),
  ),
  withHandlers({
    handleClick:
      ({ openWhatsNewModal }) =>
      () => {
        openWhatsNewModal({
          key: USER_ANNOUNCEMENT_TYPE.WHATS_NEW_NOTIFICATION,
        });
      },
  }),
)(WhatsNewMenuItem);

import { createSelector, createStructuredSelector } from 'reselect';
import { addIndex, flatten, groupBy, isNil, map, pipe, values } from 'ramda';
import { is12HourFormat } from 'src/domains/diagnostics/utils/time-format';

import { colors } from 'src/app/styles/colors';
import {
  addDays,
  convertGMTDateToFloatOld,
  hourStringToFloat,
  isEqual as luxonDatesEqual,
  toStartOfDay,
} from 'src/shared/utils/date';
import {
  selectBasalTicks,
  selectBasalYMax,
  selectGraphDetails,
  selectShowGridLines,
  selectTargetRangePadForBolus,
  selectThresholdPadForBolus,
  selectVerticalAxesCeilingPadForBolus,
  selectVerticalLabel,
  selectVerticalTicksPadForBolus,
} from 'src/domains/diagnostics/scenes/graphs/graph.selector';
import {
  filterDuplicateLines,
  isTbrDecrease,
  isTbrIncrease,
  sortAllInsulinBarsDescending,
  togglePointsFilter,
  transformBasal,
} from 'src/domains/diagnostics/scenes/graphs/graph-shared/graph.util';
import {
  CARBOHYDRATES_Y_MAX,
  GRAPH_Y_MAX_MG,
  GRAPH_Y_MAX_MMOL,
  GRAPH_Y_MIN,
  INSULIN_Y_MAX,
  TBR_TYPE,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { BLOOD_GLUCOSE_UNITS } from 'src/domains/patient-dashboards/bg/store/bg.constants';
import {
  selectBasalsInDateSliderRange,
  selectBloodGlucoseUnit,
  selectCarbohydratesMeasurementsInDateSliderRange,
  selectGlucoseMeasurementsIncludingNullValuesInDateSliderRange,
  selectGlucoseMeasurementsInDateSliderRange,
  selectGraph,
  selectGraphLoading,
  selectGraphStartTime,
  selectGraphThreshold,
  selectGraphToggles,
  selectGraphType,
  selectValidBolusesInDateSliderRange,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import { selectPatientStartDate } from 'src/domains/diagnostics/store/selectors/patient-date-range.selector';
import { selectTimeIntervals } from 'src/domains/diagnostics/store/selectors/strip-delivery.selectors';
import { getPumpIcon } from 'src/domains/diagnostics/widgets/logbook-diary/logbook-diary.util';
import { selectEC6TimeFormat } from 'src/app/store/user/user.selectors';
import { selectCarbUnitMeasurementForService } from 'src/domains/patient-dashboard/store/patient-date-range/patient-date-range.selector';

import {
  calculateInsulinDimensions,
  convertISOToGMT,
  crossoverDay,
  formatHorizontalTickLabel,
  generateLinesWithinGaps,
  getBackgroundPanels,
  getParsedIntervalIcon,
  getParsedIntervalLabel,
  getParsedIntervalTick,
  glucoseValueLinesConnector,
  groupByTimeInterval,
  groupDateObjectsBy24HourIntervals,
  includeInitialPanel,
  isStandardDayDetailGraph,
  meanBgPointsTransform,
  parseDailyGlucoseValues,
  parseTimeIntervalFloats,
  sortGlucoseValues,
  sortNormalizedXValuesIncludingInvisiblePoints,
} from './standard-day-detail.util';
import {
  HOURS_IN_DAY,
  PUMP_EVENT_DURATION_UNITS,
  PUMP_START_STOP_BASAL_REMARKS,
  pumpEventYValues,
  TEN_HOURS_NORMALIZED,
  TWO_HOURS_IN_MINUTES,
} from './standard-day-detail.constant';

import {
  selectCarbohydratesTicks,
  selectInsulinTicks,
} from '../trend/trend-detail/trend-detail.selector';
import {
  combineOverlappingInsulinOneBolusMeasurements,
  flagOverlappingBolusMeasurements,
} from '../../insulin/insulin.utilities';
import { adjustValueByDay } from 'src/domains/diagnostics/scenes/graphs/template/utils/graphs-template.util';

export const selectNumericalGraphStartTime = createSelector(
  selectGraphStartTime,
  selectGraph,
  selectGraphType,
  (graphStartTime, graph, graphType) =>
    isStandardDayDetailGraph(graph, graphType)
      ? hourStringToFloat(graphStartTime)
      : 0,
);

export const selectHourTimeHorizontalTicks = createSelector(
  [selectNumericalGraphStartTime, selectEC6TimeFormat],
  (numericalGraphStartTime, timeFormat) =>
    Array.from({ length: HOURS_IN_DAY + 1 })
      .map((hour, index) => {
        const hourIndex = index + numericalGraphStartTime;
        return {
          value: index / HOURS_IN_DAY,
          label: formatHorizontalTickLabel({
            hourIndex,
            HOURS_IN_DAY,
            is12hourFormat: is12HourFormat(timeFormat),
          }),
          isTickAtStartOfXAxis: index === 0,
          isTickAtEndOfXAxis: index / HOURS_IN_DAY === 1,
        };
      })
      .filter((hour, index) => index % 3 === 0),
);

export const selectBackgroundPanels = createSelector(
  selectNumericalGraphStartTime,
  selectTimeIntervals,
  (numericalGraphStartTime, timeIntervals) => {
    const backgroundPanels = parseTimeIntervalFloats(timeIntervals).map(
      (parsedInterval) =>
        getBackgroundPanels(parsedInterval, numericalGraphStartTime),
    );

    return includeInitialPanel(backgroundPanels);
  },
);

export const selectTimeIntervalHorizontalTicks = createSelector(
  selectNumericalGraphStartTime,
  selectTimeIntervals,
  (numericalGraphStartTime, timeIntervals) => {
    const parsedIntervals = parseTimeIntervalFloats(timeIntervals);

    const icons = parsedIntervals.map((parsedInterval) =>
      getParsedIntervalIcon(parsedInterval, numericalGraphStartTime),
    );

    const labels = parsedIntervals
      .map((parsedInterval) =>
        getParsedIntervalLabel(parsedInterval, numericalGraphStartTime),
      )
      .filter((intervalLabel) => !isNil(intervalLabel.value));

    const ticks = parsedIntervals.map((parsedInterval) =>
      getParsedIntervalTick(parsedInterval, numericalGraphStartTime),
    );

    return [...icons, ...labels, ...ticks];
  },
);

const normalizePoints = (points, _floor, ceiling) =>
  (points || []).map((point, _index) => {
    const { x, y } = point;

    return {
      ...point,
      x: x / HOURS_IN_DAY,
      y: y / ceiling,
    };
  });

export const convertMeasurementsToPoints = (
  measurements,
  thresholds = {},
  minimumXValue,
  bloodGlucoseUnit,
) =>
  measurements.map((measurement) => {
    let shape;
    const GRAPH_Y_MAX =
      bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L
        ? GRAPH_Y_MAX_MMOL
        : GRAPH_Y_MAX_MG;
    if (measurement.value > GRAPH_Y_MAX) {
      shape = 'triangle';
    } else if (
      shape !== 'triangle' &&
      (measurement.beforeMeal || measurement.afterMeal)
    ) {
      shape = 'square';
    } else {
      shape = 'x';
    }

    const x = convertGMTDateToFloatOld(measurement.date) - minimumXValue;

    const y = shape === 'triangle' ? GRAPH_Y_MAX : measurement.value;
    let strokeColor;

    if (measurement.value > thresholds.glucoseIdealIntervalMax) {
      strokeColor = colors.blueLight;
    } else if (measurement.value < thresholds.hypoglycemiaThreshold) {
      strokeColor = colors.red;
    } else {
      strokeColor = colors.black;
    }

    const fillColor =
      measurement.afterMeal || shape === 'triangle'
        ? strokeColor
        : colors.white;

    return {
      shape,
      x: adjustValueByDay(x),
      y,
      strokeColor,
      fillColor,
      data: measurement,
    };
  });

export const selectPoints = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectGraphThreshold,
  selectNumericalGraphStartTime,
  selectVerticalAxesCeilingPadForBolus,
  selectBloodGlucoseUnit,
  (measurements, thresholds, numericalStart, graphYMax, bloodGlucoseUnit) =>
    normalizePoints(
      convertMeasurementsToPoints(
        measurements,
        thresholds,
        numericalStart,
        bloodGlucoseUnit,
      ),
      GRAPH_Y_MIN,
      graphYMax,
    ),
);

export const selectFilteredPoints = createSelector(
  selectPoints,
  selectGraphToggles,
  togglePointsFilter,
);

const mapIndexed = addIndex(map);

const dailyGlucoseValuesToPoints = (minimumXValue) => (dailyGlucoseValues) =>
  dailyGlucoseValues
    .map(parseDailyGlucoseValues(minimumXValue))
    .sort(sortGlucoseValues)
    .map((point, lineIndex) => ({ ...point, lineIndex }));

const createGlucoseValueLines = (measurements, minimumXValue) =>
  pipe(
    groupDateObjectsBy24HourIntervals(minimumXValue),
    mapIndexed(dailyGlucoseValuesToPoints(minimumXValue)),
    glucoseValueLinesConnector(minimumXValue),
  )(measurements);

const normalizeLines = (lines, floor, ceiling) =>
  lines.map((line) =>
    line.map((datum, index) => {
      const { x, y, ...point } = datum;

      return {
        ...point,
        x: x / HOURS_IN_DAY,
        y: y / ceiling,
        data: datum,
      };
    }),
  );

export const selectConnectingLines = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectGraphThreshold,
  selectNumericalGraphStartTime,
  selectVerticalAxesCeilingPadForBolus,
  (measurements, thresholds, numericalStart, graphYMax) =>
    normalizeLines(
      createGlucoseValueLines(measurements, numericalStart),
      GRAPH_Y_MIN,
      graphYMax,
    ),
);

export const adjustNormalizedValuesBasedOnGraphStartTime =
  (numericalGraphStartTime) => (day) =>
    day.map(({ x, ...point }) => {
      const leavePointOffGraph = !!point.leaveOffGraph;
      const normalizedNumericalGraphStartTime =
        numericalGraphStartTime / HOURS_IN_DAY;
      const value = x - normalizedNumericalGraphStartTime;
      const adjustedValue =
        leavePointOffGraph || value >= 0 ? value : value + 1;

      return {
        ...point,
        originalX: x,
        x: adjustedValue,
      };
    });

/* For a day's line that wraps around the right boundary of the graph, we add
    extra points shifted by +/- a day so that the first and last point connect
    through the graph's edges if they are < 10 hours apart and chronologically
    in sequence
*/
const addInvisiblePointsForLinesThatWrapAround = (day) => {
  const notEnoughMeasurements = day.length <= 1;
  if (notEnoughMeasurements) {
    return day;
  }

  const [first] = day;
  const last = day[day.length - 1];

  const hasPointsOffofGraph =
    first.x < 0 || first.x > 1 || last.x < 0 || last.x < 0;
  const firstPointIsBeforeLastPoint = first.date < last.date;
  const firstXShiftedForwardOneDay = first.x + 1;

  if (
    hasPointsOffofGraph ||
    firstPointIsBeforeLastPoint ||
    firstXShiftedForwardOneDay - last.x > TEN_HOURS_NORMALIZED
  ) {
    return day;
  }

  return [
    {
      ...last,
      x: last.x - 1,
      date: first.date,
    },
    ...day,
    {
      ...first,
      x: first.x + 1,
      date: last.date,
    },
  ];
};

const selectFilteredConnectingLines = createSelector(
  selectConnectingLines,
  selectGraphToggles,
  selectNumericalGraphStartTime,
  (lines, toggles, numericalGraphStartTime) => {
    const newLines = lines
      .map(adjustNormalizedValuesBasedOnGraphStartTime(numericalGraphStartTime))
      .map((day) =>
        day.sort(
          sortNormalizedXValuesIncludingInvisiblePoints(
            numericalGraphStartTime,
          ),
        ),
      )
      .map(addInvisiblePointsForLinesThatWrapAround)
      .map(generateLinesWithinGaps(numericalGraphStartTime));

    return toggles.showBloodGlucoseLines ? newLines : [];
  },
);

export const convertMeasurementsToMeanPoints = (
  measurements,
  timeIntervals,
  minimumXValue,
) => {
  const bgPointsFiltered = measurements.filter(
    (glucoseValue) => glucoseValue.value,
  );

  const bgPoints = bgPointsFiltered
    ? bgPointsFiltered.map((gluVal) => ({
        x: convertGMTDateToFloatOld(gluVal.date),
        y: gluVal.value,
      }))
    : [];

  return meanBgPointsTransform(
    groupByTimeInterval(bgPoints, timeIntervals),
    minimumXValue,
  );
};

export const selectTimeIntervalMeanPoints = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectTimeIntervals,
  selectNumericalGraphStartTime,
  selectVerticalAxesCeilingPadForBolus,
  (measurements, timeIntervals, numericalStart, graphYMax) =>
    normalizePoints(
      convertMeasurementsToMeanPoints(
        measurements,
        timeIntervals,
        numericalStart,
      ),
      GRAPH_Y_MIN,
      graphYMax,
    ),
);

const selectFilteredMeanPoints = createSelector(
  selectTimeIntervalMeanPoints,
  selectGraphToggles,
  (meanPoints, toggles) => (toggles.showMeanBloodGlucose ? meanPoints : []),
);

const isValidStartStopPauseEvent = (basalRemark) =>
  basalRemark &&
  Object.values(PUMP_START_STOP_BASAL_REMARKS).includes(basalRemark);

const isStopEvent = (basalRemark) =>
  basalRemark === PUMP_START_STOP_BASAL_REMARKS.STOP;

const formatPumpStopDuration = (duration, timeUnit) => {
  const durationText = 'graphs.detailGraph.toolTip.pumpEvents.duration';
  const timeUnitText = `graphs.detailGraph.toolTip.pumpEvents.${timeUnit}`;
  const roundedDuration = Math.floor(duration);
  return [durationText, roundedDuration, timeUnitText];
};

const durationBetweenDates = (date1, date2) => date2.diff(date1);

const calculatePumpStopDuration = (stopEvent, nextEvent) => {
  const diff = durationBetweenDates(stopEvent.date, nextEvent.date);
  const diffInMinutes = diff.as('minutes');
  const lessThan2Hours = diffInMinutes < TWO_HOURS_IN_MINUTES;
  const duration = lessThan2Hours ? diffInMinutes : diff.as('hours');
  const timeUnit = lessThan2Hours
    ? PUMP_EVENT_DURATION_UNITS.MINS
    : PUMP_EVENT_DURATION_UNITS.HOURS;
  const lessThanAMinute = diffInMinutes < 1;

  return lessThanAMinute ? '' : formatPumpStopDuration(duration, timeUnit);
};

const convertBasalsToPumpEventPoints = (basals, numericalGraphStartTime) =>
  basals
    .filter(({ basalRemark }) => isValidStartStopPauseEvent(basalRemark))
    .map(({ date, basalRemark }, index, originalArray) => ({
      x: adjustValueByDay(
        convertGMTDateToFloatOld(date) - numericalGraphStartTime,
      ),
      y: pumpEventYValues[basalRemark],
      type: getPumpIcon({
        basalRemark,
      }),
      data: {
        date,
        value: basalRemark,
        duration:
          !isStopEvent(basalRemark) || !originalArray[index + 1]
            ? ''
            : calculatePumpStopDuration(
                originalArray[index],
                originalArray[index + 1],
              ),
      },
    }));

const normalizePumpEventPoints = (pumpEventPoints) =>
  pumpEventPoints.map(({ x, y, ...data }) => ({
    x: x / HOURS_IN_DAY,
    y,
    ...data,
  }));

export const selectPumpEventPoints = createSelector(
  selectBasalsInDateSliderRange,
  selectNumericalGraphStartTime,
  (basals, numericalGraphStartTime) =>
    normalizePumpEventPoints(
      convertBasalsToPumpEventPoints(basals, numericalGraphStartTime),
    ),
);

const normalizeCarbohydratesLines = (carbohydratesLines, graphYMax) =>
  carbohydratesLines.map(([a, b]) => [
    {
      ...a,
      x: a.x / HOURS_IN_DAY,
      y: a.y,
    },
    {
      ...b,
      x: b.x / HOURS_IN_DAY,
      y: b.y / CARBOHYDRATES_Y_MAX,
    },
  ]);

const convertCarbohydratesMeasurementsToLines = (
  carbohydrates,
  numericalGraphStartTime,
) =>
  carbohydrates.map(({ date, carbohydrates }) => {
    const x = adjustValueByDay(
      convertGMTDateToFloatOld(date) - numericalGraphStartTime,
    );
    return [
      { x, y: 0, date, value: carbohydrates },
      { x, y: carbohydrates, date, value: carbohydrates },
    ];
  });

export const selectCarbohydratesLines = createSelector(
  selectCarbohydratesMeasurementsInDateSliderRange,
  selectNumericalGraphStartTime,
  selectVerticalAxesCeilingPadForBolus,
  (carbohydrates, numericalGraphStartTime, graphYMax) =>
    normalizeCarbohydratesLines(
      convertCarbohydratesMeasurementsToLines(
        carbohydrates,
        numericalGraphStartTime,
      ),
      graphYMax,
    ),
);

const selectInsulinPoints = createSelector(
  selectGlucoseMeasurementsIncludingNullValuesInDateSliderRange,
  selectValidBolusesInDateSliderRange,
  selectNumericalGraphStartTime,
  selectPatientStartDate,
  (glucose, boluses, numericalGraphStartTime, startDate) => {
    const processedGlucose = combineOverlappingInsulinOneBolusMeasurements(
      luxonDatesEqual,
    )(glucose, boluses);
    const processedInsulin = flagOverlappingBolusMeasurements(luxonDatesEqual)(
      glucose,
      boluses,
    );

    return flatten([processedGlucose, processedInsulin])
      .map(convertISOToGMT)
      .reduce(
        calculateInsulinDimensions(
          numericalGraphStartTime,
          startDate,
          INSULIN_Y_MAX,
        ),
        [],
      )
      .reduce(crossoverDay, [])
      .sort(sortAllInsulinBarsDescending);
  },
);

export const normalizeBasalLine =
  (yMax) =>
  ([a, b]) =>
    [
      { ...a, x: a.x / HOURS_IN_DAY, y: a.y / yMax },
      { ...b, x: b.x / HOURS_IN_DAY, y: b.y / yMax },
    ];

const convertDateToXValue = (date, numericalGraphStartTime) =>
  adjustValueByDay(convertGMTDateToFloatOld(date) - numericalGraphStartTime);

const createHorizontalBasalLine = (
  xValues,
  y,
  { date, basalCbrf, endDate, tbr },
) => xValues.map((x) => ({ x, y, date, show: y > 0, basalCbrf, endDate, tbr }));

const createVerticalBasalLine = (
  yValues,
  x,
  { date, basalCbrf, endDate, tbr },
) => yValues.map((y) => ({ x, y, date, show: true, basalCbrf, endDate, tbr }));

const getBasalX2Value = (x1, numericalGraphStartTime, nextBasal) => {
  if (!nextBasal) {
    return HOURS_IN_DAY - numericalGraphStartTime;
  }

  const x2 = convertDateToXValue(nextBasal.date, numericalGraphStartTime);
  return x2 < x1 ? HOURS_IN_DAY : x2;
};

const getBasalY2Value = (x2, y1, firstBasal, nextBasal) => {
  const { basalCbrf } = nextBasal || firstBasal;
  return x2 === HOURS_IN_DAY && basalCbrf === 0 ? y1 : basalCbrf;
};

const convertBasalToLines =
  (numericalGraphStartTime) => (basalLines, basal, index, originalArray) => {
    const { date, basalCbrf } = basal;

    const firstBasal = originalArray[0];
    const nextBasal = originalArray[index + 1];

    const x1 = convertDateToXValue(date, numericalGraphStartTime);
    const y1 = basalCbrf;
    const x2 = getBasalX2Value(x1, numericalGraphStartTime, nextBasal);
    const y2 = getBasalY2Value(x2, y1, firstBasal, nextBasal);

    const horizontalLine = createHorizontalBasalLine([x1, x2], y1, basal);
    const verticalLine = createVerticalBasalLine(
      [y1, y2],
      x2,
      y1 > y2 ? basal : nextBasal || firstBasal,
    );

    return [...basalLines, horizontalLine, verticalLine];
  };

export const prependInitialLine = (lines) => {
  const [[firstPoint]] = lines;
  const [secondLastPoint] = lines[lines.length - 1];

  return [
    [0, firstPoint.x].map((x) => ({
      x,
      y: firstPoint.y || secondLastPoint.y,
      date: toStartOfDay(firstPoint.date),
      endDate: firstPoint.endDate,
      show: true,
      basalCbrf: firstPoint.basalCbrf,
      tbr: firstPoint.tbr,
    })),
    ...lines,
  ];
};

export const convertBasalsToLines = (numericalGraphStartTime) => (basals) =>
  basals.reduce(convertBasalToLines(numericalGraphStartTime), []);

export const sortBasalLines = (lines) =>
  [...lines].sort(([{ x: ax }], [{ x: bx }]) => ax - bx);

const dateToDay = ({ date }) => date.toFormat('yyyy LLL dd');

export const groupByDay = pipe(groupBy(dateToDay), values);

export const addEndDates = (measurements) =>
  measurements.reduce((acc, measurement, index, originalArray) => {
    const nextMeasurement = originalArray[index + 1];
    const { date } = measurement;

    const endDate = !nextMeasurement
      ? toStartOfDay(addDays(1)(date))
      : nextMeasurement.date;

    return [
      ...acc,
      {
        ...measurement,
        date,
        endDate,
      },
    ];
  }, []);

// generates an array of subarrays containing each day's measurement lines
export const selectBasalLines = createSelector(
  selectBasalsInDateSliderRange,
  selectBasalYMax,
  selectNumericalGraphStartTime,
  (basals, yMax, numericalGraphStartTime) =>
    groupByDay(addEndDates(basals))
      .map(map(transformBasal))
      .map(convertBasalsToLines(numericalGraphStartTime))
      .map(sortBasalLines)
      .map(prependInitialLine)
      .map(map(normalizeBasalLine(yMax)))
      .map(filterDuplicateLines),
);

const TBR_END = 'TBR End';
const ARBITRARY_TBR_Y_VALUE = 0.55;

export const normalizeTbrLine = ([a, b]) => [
  {
    ...a,
    x: a.x / HOURS_IN_DAY,
    y: a.y,
  },
  {
    ...b,
    x: b.x / HOURS_IN_DAY,
    y: b.y,
  },
];

export const createTbrLine =
  (numericalGraphStartTime) =>
  ({ date, type }) => {
    const x = convertDateToXValue(date, numericalGraphStartTime);
    const y = ARBITRARY_TBR_Y_VALUE;
    const show = true;
    return [
      { x, y: 0, date, type, show },
      { x, y, date, type, show },
    ];
  };

const isTbrEnd = (basalRemark) => basalRemark === TBR_END;

export const formatTbrEvent = ({ basalRemark, type, date }) => ({
  date,
  basalRemark,
  type,
});

export const addTbrType = (basal, index, originalArray) => {
  let type = TBR_TYPE.NONE; // this should not be the final value
  const previousBasal = originalArray[index - 1];
  const { basalRemark, ...data } = basal;

  if (isTbrEnd(basalRemark) && !!previousBasal) {
    if (isTbrIncrease(previousBasal)) {
      type = TBR_TYPE.END_INCREASE;
    } else if (isTbrDecrease(previousBasal)) {
      type = TBR_TYPE.END_DECREASE;
    } else {
      // eslint-disable-next-line no-empty
    }
  } else if (isTbrIncrease(basal)) {
    type = TBR_TYPE.INCREASE;
  } else if (isTbrDecrease(basal)) {
    type = TBR_TYPE.DECREASE;
  } else {
    // eslint-disable-next-line no-empty
  }

  return { ...data, basalRemark, type };
};

export const isTbrStart = ({ basalTbrinc, basalTbrdec }) =>
  !!basalTbrinc || !!basalTbrdec;

export const selectTbrLines = createSelector(
  selectBasalsInDateSliderRange,
  selectNumericalGraphStartTime,
  (basals, numericalGraphStartTime) =>
    basals
      .filter(isTbrStart)
      .map(addTbrType)
      .map(formatTbrEvent)
      .map(createTbrLine(numericalGraphStartTime))
      .map(normalizeTbrLine),
);

export const convertTbrLineToPoint = ([, { show, ...data }]) => ({ ...data });
export const selectTbrPoints = createSelector(selectTbrLines, (tbrLines) =>
  tbrLines.map(convertTbrLineToPoint),
);

export const standardDayDetailConnector = createStructuredSelector({
  bloodGlucoseUnit: selectBloodGlucoseUnit,
  backgroundPanels: selectBackgroundPanels,
  basalLines: selectBasalLines,
  basalTicks: selectBasalTicks,
  carbohydratesLines: selectCarbohydratesLines,
  carbohydratesTicks: selectCarbohydratesTicks,
  graphDetails: selectGraphDetails,
  graphToggles: selectGraphToggles,
  graphYMax: selectVerticalAxesCeilingPadForBolus,
  insulinPoints: selectInsulinPoints,
  insulinTicks: selectInsulinTicks,
  isLoading: selectGraphLoading,
  lines: selectFilteredConnectingLines,
  meanPoints: selectFilteredMeanPoints,
  measurements: selectGlucoseMeasurementsInDateSliderRange,
  points: selectFilteredPoints,
  pumpEventPoints: selectPumpEventPoints,
  showGridLines: selectShowGridLines,
  tbrLines: selectTbrLines,
  tbrPoints: selectTbrPoints,
  targetRange: selectTargetRangePadForBolus,
  threshold: selectThresholdPadForBolus,
  timeHorizontalTicks: selectHourTimeHorizontalTicks,
  timeIntervalHorizontalTicks: selectTimeIntervalHorizontalTicks,
  verticalLabel: selectVerticalLabel,
  verticalTicks: selectVerticalTicksPadForBolus,
  timeFormat: selectEC6TimeFormat,
  carbUnit: selectCarbUnitMeasurementForService,
});

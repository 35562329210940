export type OpenId = {
  accessToken: string;
  gigyaToken: string;
};

export enum MysugrStatusValues {
  INACTIVE = 'inactive',
  PENDING = 'pending',
  ACTIVE = 'active',
  NOT_INITIATED = 'not_initiated',
}

export type MysugrStatusLoaderImplType = (
  openId: OpenId,
  patientGigyaUid: string,
) => Promise<any>;

export type MysugrStatusTransformImplType = (
  data: MysugrStatusResponse,
) => MysugrStatusResponseTransformed;

export type MysugrStatusServiceImplType = (
  load: MysugrStatusLoaderImplType,
  transform: MysugrStatusTransformImplType,
) => (
  openId: OpenId,
  patientGigyaUid: string,
) => Promise<MysugrStatusResponseTransformed>;

export type MysugrStatusResponse = FixMe;
export type MysugrStatusResponseTransformed = FixMe;

/* tslint:disable */

import styled from 'styled-components';
import { TableRow } from 'src/domains/diagnostics/components/table-row/table-row.component';
import { colors } from '../../../../../app/styles/colors';

type TablePrimaryRow = {
  borderBottom?: boolean;
  backgroundColor?: string;
  paddingBottom?: string;
};

export const StyledTablePrimaryRow = styled(TableRow)`
  border-bottom: ${(props: TablePrimaryRow) =>
    props.borderBottom ? `1px solid ${colors.grayLight}` : ''};
  background: ${(props) => props.backgroundColor || ''};
`;

StyledTablePrimaryRow.displayName = 'TablePrimaryRowDiv';

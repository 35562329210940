import {
  EditPatientDemoProfileLoaderImpl,
  EditDemoPatientProfilesServiceImpl,
} from './edit-demo-patient-profiles.service';
import { mockEditDemoPatientProfile } from './edit-demo-patient-profiles.mock';

const mockLoader = () => Promise.resolve(mockEditDemoPatientProfile);

export const editDemoPatientProfilesFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : EditPatientDemoProfileLoaderImpl;
  return EditDemoPatientProfilesServiceImpl(loader);
};

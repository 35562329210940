import { isNil } from 'ramda';
import React from 'react';

import { PointSeries } from 'src/domains/diagnostics/components/graph-lib/graph-point-series.component';
import {
  DATA_TYPE,
  FADED_OPACITY,
  FULL_OPACITY,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';

import { ProfileChangeShape } from './point-shapes.component';
import { areDatesTheSameDay } from '../../scenes/graphs/graph-shared/graph-date';
import { getClickableCursorStyle } from '../../scenes/graphs/graph-shared/presentation.util';

export const BasalRateProfileChanges = ({
  profileChanges = [],
  dimensions,
  selectedDate,
  showToolTip,
  hideToolTip,
  onLineClick,
  collapsed,
}) => (
  <PointSeries
    points={profileChanges}
    {...dimensions}
    Shape={({ x, y, profile, date, changeType }) => (
      <ProfileChangeShape
        x={x}
        y={y}
        profile={profile}
        opacity={
          isNil(selectedDate) || areDatesTheSameDay(date, selectedDate)
            ? FULL_OPACITY
            : FADED_OPACITY
        }
        onClick={onLineClick({
          type: DATA_TYPE.PROFILE_CHANGE,
          date,
        })}
        onMouseMove={(event) =>
          showToolTip(
            event,
            { type: DATA_TYPE.PROFILE_CHANGE, date, changeType },
            180,
          )
        }
        onMouseOut={hideToolTip}
        cursor={getClickableCursorStyle(!collapsed)}
      />
    )}
  />
);

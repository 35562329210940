/* These values correspond to units specified by /clinical-data endpoint. */
export const BLOOD_GLUCOSE_UNITS = {
  MG_PER_DL: 'mg/dL',
  MMOL_PER_L: 'mmol/L',
} as const;

export const BG_UNIT_KEYS = {
  [BLOOD_GLUCOSE_UNITS.MG_PER_DL]: 'general.units.mgPerDL',
  [BLOOD_GLUCOSE_UNITS.MMOL_PER_L]: 'general.units.mmolPerL',
};

export const EMPTY_VALUE_PLACEHOLDER = '-';
export const NO_DATA_EMPTY_PLACEHOLDER = 'NO_DATA';
export const NOT_ENOUGH_DATA_PLACEHOLDER = 'NOT_ENOUGH_DATA';

export const MINUTES_IN_DAY = 1440;

export const BOLUS_TYPE = {
  STANDARD: 'Std',
  QUICK: 'Scr',
  EXTENDED: 'Ext',
  MULTIWAVE: 'Mul',
};

export const HYPO_RISK_MIN = 20; // only for mg/dl

export const HYPO_RISK_MAX = 600; // only for mg/dl

export const MIN_BG = 113; // only for mg/dl

export const INSULIN_TYPE = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
};

export const HI_VALUE = 'HI';
export const LO_VALUE = 'LO';
export const GLUCOSE_VALUE = 'GLUCOSE';
export const HI_OR_LO = 'HiOrLo';

export type HI_VALUE_TYPE = typeof HI_VALUE;
export type LO_VALUE_TYPE = typeof LO_VALUE;
export type GLUCOSE_VALUE_TYPE = typeof GLUCOSE_VALUE;
export type BloodGlucoseUnit =
  typeof BLOOD_GLUCOSE_UNITS[keyof typeof BLOOD_GLUCOSE_UNITS];

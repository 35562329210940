import { useEffect, useRef, useState } from 'react';

type CallBackType<T> = (updatedValue: T) => void;

type SetStateType<T> = T | ((prev: T) => T);

type ValidationFunc = (value: string) => { isValid: boolean };

type RetType = <T>(
  initialValue: T | (() => T),
) => [T, (newValue: SetStateType<T>, callback?: CallBackType<T>) => void];
// prettier-ignore
// istanbul ignore next
export const useCallbackState: RetType = <T,>(initialValue: T | (() => T)) => {
  const [state, _setState] = useState<T>(initialValue);
  const callbackQueue = useRef<CallBackType<T>[]>([]);
  useEffect(() => {
    callbackQueue.current.forEach((cb) => cb(state));
    callbackQueue.current = [];
  }, [state]);
  const setState = (newValue: SetStateType<T>, callback?: CallBackType<T>) => {
    _setState(newValue);
    if (callback && typeof callback === 'function') {
      callbackQueue.current.push(callback);
    }
  };
  return [state, setState];
};

// Is the mouse in the document?
// istanbul ignore next
export function useIsInDocument() {
  const [inDocument, setInDocument] = useState(true);
  useEffect(() => {
    document.addEventListener('mouseenter', () => setInDocument(true));
    document.addEventListener('mouseleave', () => setInDocument(false));
    return () => {
      document.removeEventListener('mouseenter', () => setInDocument(true));
      document.removeEventListener('mouseleave', () => setInDocument(false));
    };
  }, []);
  return inDocument;
}

// custom hook to use for attaching custom event listeners (not only jello web components)
export function useCustomEventListener(
  eventName: string | string[],
  handler: (event: Event) => void,
  options?: boolean | AddEventListenerOptions,
): any {
  // Create a ref that stores handler
  const savedHandler = useRef(handler);
  const element = useRef<HTMLElement | void>(undefined);
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(() => {
    // Define the listening target
    const targetElement: HTMLElement | Window = element?.current || window;
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }
    // Create event listener that calls handler function stored in ref
    const eventListener: typeof handler = (event) =>
      savedHandler.current(event);
    if (Array.isArray(eventName)) {
      eventName.forEach((event) => {
        targetElement.addEventListener(event, eventListener, options);
      });
    } else {
      targetElement.addEventListener(eventName, eventListener, options);
    }
    // Remove event listener on cleanup
    return () => {
      if (Array.isArray(eventName)) {
        eventName.forEach((event) => {
          targetElement.removeEventListener(event, eventListener);
        });
      } else {
        targetElement.removeEventListener(eventName, eventListener);
      }
    };
  }, [eventName, element, options]);
  return element;
}

// custom hook to use for attaching rules to a input ref (jello input)
export function useRules(
  inputRef: React.RefObject<any>,
  rules: ValidationFunc[],
) {
  useEffect(() => {
    const linkedInput: any = inputRef?.current;
    if (linkedInput) {
      // Add validation rules
      linkedInput.rules = rules;
    }
    return () => {
      if (linkedInput) {
        // Remove validation rules
        linkedInput.rules = undefined;
      }
    };
  }, [inputRef, rules]);
}

// custom hook to use for attaching custom event listeners and rules to a input ref (jello input)
export function useInputJelloEventListenerWithRules(
  eventName: string | string[],
  handler: (event: Event) => void,
  rules: ValidationFunc[],
  options?: boolean | AddEventListenerOptions,
): any {
  const inputRef = useCustomEventListener(eventName, handler, options);
  useRules(inputRef, rules);
  return inputRef;
}

// custom hook to use to detect reload action from browser
export function useDetectReloadActionFromBrowser() {
  const navigationType = (
    window.performance.getEntriesByType(
      'navigation',
    )[0] as PerformanceNavigationTiming
  ).type;
  if (navigationType === 'reload') return true;
  return false;
}

import React from 'react';

import { convertISOGMT } from 'src/shared/utils/date';
import { isWeekendDay } from 'src/domains/diagnostics/utils/time.util';

import { DayLinesList } from './logbook-day-cell.style';

import { EmptyRows } from 'src/domains/diagnostics/components/table/empty-rows/empty-rows.component';
import { LogbookCellBlock } from 'src/domains/diagnostics/components/table/logbook-cell-block/logbook-cell-block.component';
import { LogbookCellBlocksContainer } from 'src/domains/diagnostics/components/table/logbook-cell-blocks-container/logbook-cell-blocks-container.component';
import { LogbookTableCellDiv } from 'src/domains/diagnostics/components/table/logbook-table-cell/logbook-table-cell.component';

export const LogbookDayCell = ({ date, dayLines, numberOfRows, highlight }) => (
  <LogbookTableCellDiv
    key={`${dayLines.join(', ')}`}
    width="6.7rem"
    borderRight={true}
    highlight={highlight}
  >
    <LogbookCellBlocksContainer>
      <LogbookCellBlock align="left" flex={1}>
        <DayLinesList isWeekendDay={isWeekendDay(convertISOGMT(date))}>
          {dayLines.map((dayLine) => (
            <li key={dayLine}>{dayLine}</li>
          ))}
        </DayLinesList>
      </LogbookCellBlock>
    </LogbookCellBlocksContainer>
    <EmptyRows
      numberOfRows={numberOfRows - (dayLines.length - 1)}
      numberOfCells={1}
    />
  </LogbookTableCellDiv>
);

import { Observable } from 'rxjs';

import { FETCH_PATIENT_DATE_RANGE_REQUEST } from 'src/domains/patient-dashboard/store/patient-date-range/patient-date-range.constant';
import { setFirstLastMeasurementDates } from 'src/domains/patient-dashboard/store/patient-date-range/patient-date-range.action';
import {
  selectCarbUnitMeasurementForService,
  selectPatientEndDate,
  selectPatientStartDate,
  selectUnitMeasurementForService,
} from 'src/domains/patient-dashboard/store/patient-date-range/patient-date-range.selector';
import {
  selectBGOverviewEndDate,
  selectBGOverviewTimeInterval,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import { selectFhirPermission } from 'src/domains/permissions/store/permissions.selectors';
import { selectPatientFhirId } from 'src/domains/patient/store/patient/patient.selector';
import { calculateBloodGlucoseStartDate } from 'src/domains/diagnostics/scenes/blood-glucose-overview/store/blood-glucose-overview.utils';
import { NUMBER_BLOOD_GLUCOSE_OVERVIEW_COLUMNS } from 'src/domains/diagnostics/scenes/blood-glucose-overview/store/blood-glucose-overview.constants';
import { requestSequence } from 'src/app/store/request/request.epics';

import {
  FETCH_BG_OVERVIEW_CLINICAL_DATA_FROM_FHIR_REQUEST,
  FETCH_BG_OVERVIEW_CLINICAL_DATA_REQUEST,
  FETCH_BG_OVERVIEW_END_DATE_REQUEST,
  FETCH_CHECK_EXTERNAL_PATIENT_REQUEST,
  GET_CLINICAL_DATA_FROM_FHIR_REQUEST,
  GET_CLINICAL_DATA_REQUEST,
} from './bg.constants';
import {
  fetchBGOverviewClinicalData,
  fetchBGOverviewClinicalDataFromFhir,
  getClinicalData,
  getClinicalDataFromFhir,
  setClinicalData,
} from './bg.actions';
import { SessionActionType } from 'src/app/session/core/session/session.types';

export const getClinicalDataEpic = (clinicalDataService) =>
  requestSequence({
    service: clinicalDataService,
    actionTypes: GET_CLINICAL_DATA_REQUEST,
  });

export const getClinicalDataFromFhirEpic = (clinicalDataFromFhirService) =>
  requestSequence({
    service: clinicalDataFromFhirService,
    actionTypes: GET_CLINICAL_DATA_FROM_FHIR_REQUEST,
  });

export const fetchBGOverviewClinicalDataEpic = (clinicalDataService) =>
  requestSequence({
    service: clinicalDataService,
    actionTypes: FETCH_BG_OVERVIEW_CLINICAL_DATA_REQUEST,
  });

export const fetchBGOverviewClinicalDataFromFhirEpic = (
  clinicalDataFromFhirService,
) =>
  requestSequence({
    service: clinicalDataFromFhirService,
    actionTypes: FETCH_BG_OVERVIEW_CLINICAL_DATA_FROM_FHIR_REQUEST,
  });

export const fetchBGOverviewEndDateEpic = (fetchDateRangeService) =>
  requestSequence({
    service: fetchDateRangeService,
    actionTypes: FETCH_BG_OVERVIEW_END_DATE_REQUEST,
  });

export const setClinicalDataEpic = () => (action$, store) =>
  action$
    .ofType(
      GET_CLINICAL_DATA_REQUEST.SUCCESS,
      GET_CLINICAL_DATA_FROM_FHIR_REQUEST.SUCCESS,
      FETCH_BG_OVERVIEW_CLINICAL_DATA_REQUEST.SUCCESS,
      FETCH_BG_OVERVIEW_CLINICAL_DATA_FROM_FHIR_REQUEST.SUCCESS,
    )
    .flatMap((action) => {
      const { unit, measurements, insulin } = action.payload;
      return [setClinicalData(unit, measurements, insulin)];
    });

export const getClinicalDataOnDateRangeEpic = () => (action$, store) =>
  Observable.combineLatest(
    action$.ofType(FETCH_PATIENT_DATE_RANGE_REQUEST.SUCCESS),
    action$.ofType(SessionActionType.GET_CURRENT_USER_SUCCESS).take(1),
  ).mergeMap(([fetchDateRangeSuccess, fetchCurrentPatientSuccess]) => {
    const state = store.getState();
    const startDate = selectPatientStartDate(state);
    const endDate = selectPatientEndDate(state);
    const hasUserFhirPermission = selectFhirPermission(state);
    const patientFhirId = selectPatientFhirId(state);
    const bgUnit = selectUnitMeasurementForService(state);
    const carbUnit = selectCarbUnitMeasurementForService(state);
    const { patientId } = fetchDateRangeSuccess.payload;
    return [
      hasUserFhirPermission
        ? getClinicalDataFromFhir.start({
            patientFhirId,
            startDate,
            endDate,
            bgUnit,
            carbUnit,
          })
        : getClinicalData.start({
            patientId,
            startDate,
            endDate,
          }),
    ];
  });

export const getClinicalDataOnBGOverviewEndDateEpic = () => (action$, store) =>
  action$
    .ofType(FETCH_BG_OVERVIEW_END_DATE_REQUEST.SUCCESS)
    .flatMap((action) => {
      const state = store.getState();
      const { firstMeasurement, latestMeasurement, patientId } = action.payload;
      const endDate = selectBGOverviewEndDate(state);
      const interval = selectBGOverviewTimeInterval(state);
      const startDate = calculateBloodGlucoseStartDate(
        endDate,
        interval,
        NUMBER_BLOOD_GLUCOSE_OVERVIEW_COLUMNS,
      );
      const hasUserFhirPermission = selectFhirPermission(state);
      const patientFhirId = selectPatientFhirId(state);
      const bgUnit = selectUnitMeasurementForService(state);
      const carbUnit = selectCarbUnitMeasurementForService(state);
      return [
        hasUserFhirPermission
          ? fetchBGOverviewClinicalDataFromFhir.start({
              patientFhirId,
              startDate,
              endDate,
              bgUnit,
              carbUnit,
            })
          : fetchBGOverviewClinicalData.start({
              patientId,
              startDate: startDate.toString(),
              endDate: endDate.toString(),
            }),
        setFirstLastMeasurementDates(firstMeasurement, latestMeasurement),
      ];
    });

export const fetchCheckExternalPatientEpic = (externalPatientService) =>
  requestSequence({
    service: externalPatientService,
    actionTypes: FETCH_CHECK_EXTERNAL_PATIENT_REQUEST,
  });

import React from 'react';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { colors } from 'src/app/styles/colors';
import { Dropdown } from 'src/shared/design-system/dropdown/dropdown.component';
import { DateRangeIcon } from 'src/shared/design-system/icons';
import { TIME_INTERVAL } from 'src/domains/diagnostics/constants/diagnostics.constants';

import {
  IntervalsDropdownValueContainer,
  IntervalsDropdownValueWrapperDiv,
  IntervalsDropdownValueWrapperSpan,
} from './interval-dropdown.style';

const intervals = [
  {
    label: 'bloodGlucoseOverview.intervals.quarterly',
    value: TIME_INTERVAL.QUARTERLY_INTERVALS,
  },
  {
    label: 'bloodGlucoseOverview.intervals.monthly',
    value: TIME_INTERVAL.MONTHLY_INTERVALS,
  },
  {
    label: 'bloodGlucoseOverview.intervals.weekly',
    value: TIME_INTERVAL.WEEKLY_INTERVALS,
  },
];

const IntervalValue = ({ className, id, children }) => {
  return (
    <IntervalsDropdownValueContainer>
      <IntervalsDropdownValueWrapperDiv>
        <DateRangeIcon height={17} fillColor={colors.brandBlue} />
        <IntervalsDropdownValueWrapperSpan>
          {children}
        </IntervalsDropdownValueWrapperSpan>
      </IntervalsDropdownValueWrapperDiv>
    </IntervalsDropdownValueContainer>
  );
};

export const IntervalsDropdownComponent = ({ onChangeInterval, t }) => {
  const translatedIntervals = intervals.map(({ label, value }) => ({
    label: t(label),
    value,
  }));

  return (
    <Dropdown
      modelPath="ui.patientDashboard.bgOverview.timeInterval"
      onChange={onChangeInterval}
      options={translatedIntervals}
      searchable={false}
      selectHeight={'2.5rem'}
      valueComponent={IntervalValue}
    />
  );
};

export const IntervalsDropdown = withTranslation(IntervalsDropdownComponent);

import React from 'react';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { colors } from 'src/app/styles/colors';
import { spacing } from 'src/app/styles/spacing';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';

import { TableHeaderCellTitleWithTooltip } from './table-header-cell-title.component';

import { LogbookCellBlock } from 'src/domains/diagnostics/components/table/logbook-cell-block/logbook-cell-block.component';
import { LogbookCellBlocksContainer } from 'src/domains/diagnostics/components/table/logbook-cell-blocks-container/logbook-cell-blocks-container.component';
import { LogbookTableCell } from 'src/domains/diagnostics/components/table/logbook-table-cell/logbook-table-cell.component';
import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';
import { ParenthesesWrapper } from 'src/domains/diagnostics/components/parentheses/parentheses.component';

export const TableHeaderCellComponent = ({
  keyText,
  headerKey,
  colSpan,
  width,
  noFill,
  noTopBorder,
  noRightBorder,
  borderBottom,
  textTransform,
  fontWeight,
  color,
  height,
  verticalAlign,
  overflow,
  padding,
  textAlign,
  whiteSpace,
  t,
}) => (
  <LogbookTableCell
    key={`${headerKey}-${keyText}`}
    alternateFill={!noFill}
    borderRight={!noRightBorder ? { thick: true } : null}
    borderTop={!noTopBorder ? { thick: true } : null}
    borderBottom={borderBottom}
    p={1}
    colSpan={colSpan}
    width={width}
    color={color}
    textTransform={textTransform}
    fontWeight={fontWeight}
    height={height}
    verticalAlign={verticalAlign}
    textAlign={textAlign}
  >
    <LogbookCellBlocksContainer
      width={width}
      margin={`0 ${spacing.one} 0 ${spacing.one}`}
      id={headerKey}
      borderBottomString={`1px solid ${colors.blueMarine}`}
    >
      <LogbookCellBlock alternateFill={!noFill} height={height}>
        <TableHeaderCellTitleWithTooltip
          height={height}
          overflow={overflow}
          whiteSpace={whiteSpace}
          textAlign={textAlign}
          padding={padding}
        >
          <RenderIf validate={headerKey && headerKey === 'insulin'}>
            <span>
              <LocalizedText textKey={`${headerKey}`} />
              &nbsp;
              <ParenthesesWrapper text={t('graphs.logbookDiary.unitSymbol')} />
            </span>
          </RenderIf>
          <RenderIf validate={headerKey && headerKey !== 'insulin'}>
            <LocalizedText textKey={`${headerKey}`} />
          </RenderIf>
        </TableHeaderCellTitleWithTooltip>
      </LogbookCellBlock>
    </LogbookCellBlocksContainer>
  </LogbookTableCell>
);

export const TableHeaderCell = withTranslation(TableHeaderCellComponent);

import { combineReducers } from 'redux';
import { createForms } from 'react-redux-form';
import { routerReducer } from 'react-router-redux';
import {
  CIAM_SDK_NAMESPACE,
  IS_WHATS_NEW_VIEWED,
} from 'src/app/session/constants';
import { sessionReducer } from 'src/app/session/core/session/session.reducer';
import { navigationReducer } from 'src/app/navigation/store/navigation.reducers';
import { nextShipmentReducer } from 'src/domains/patient/scenes/next-shipment/store/next-shipment.reducer';
import { deliveryConfigurationReducer } from 'src/domains/patient/scenes/delivery-configuration/store/delivery-configuration.reducer';
import { patientSessionReducer } from 'src/app/navigation/store/patient-session/patient-session.reducer';
import { alertReducer } from 'src/shared/design-system/alerts/store/alert.reducer';

import { requestReducer } from 'src/app/store/request/request.reducers';
import { modalsReducer } from 'src/shared/design-system/modal/store/modal/modal.reducers';
import { prescriptionReducer } from 'src/domains/strip-management/store/prescription/shared/prescription.reducers';
import { patientStockReducer } from 'src/domains/strip-management/store/patient-stock/patient-stock.reducers';
import {
  patientPermissionsReducer,
  permissionsReducer,
} from 'src/domains/permissions/store/permissions.reducers';
import { targetRangesReducer } from 'src/widgets/target-ranges/store/target-ranges.reducer';

import { alertsReducer } from '../domains/strip-management/store/alerts/alerts.reducers';
import { patientDashboardReducer } from '../domains/patient-dashboards/bg/store/bg.reducers';
import { timePeriodsReducer } from '../domains/patient/scenes/time-periods/store/time-periods.reducer';
import { stripDeliveryReducer } from '../domains/strip-management/store/strip-delivery/strip-delivery.reducers';
import { patientSearchReducer } from '../domains/device-assignment/components/patient-search/store/patient-search.reducers';
import { patientReducer } from '../domains/patient/store/patient/patient.reducers';
import { hba1cCardReducer } from '../domains/diagnostics/widgets/hba1c-card/store/hba1c-card.reducer';
import { newEntryHba1cReducer } from '../domains/diagnostics/widgets/hba1c-modal/components/new-entry-hba1c/store/new-entry-hba1c.reducer';
import { listValuesHba1cReducer } from '../domains/diagnostics/widgets/hba1c-modal/components/list-values-hba1c/store/list-values-hba1c.reducer';
import { patientDateRangeReducer } from '../domains/diagnostics/core/patient-date-range/patient-date-range.reducers';
import { orgStockReducer } from '../domains/strip-management/scenes/org-stock/store/org-stock/org-stock.reducers';
import { deviceAssignmentReducer } from '../domains/device-assignment/store/device-assignment/device-assignment.reducers';
import { configurablePermissionsReducer } from '../domains/patient/store/configurables/configurables.reducers';
import { listDevicesReducer } from '../domains/patient/scenes/list-devices/store/list-devices.reducer';
import { departmentReducer } from '../domains/patient/store/department/department.reducers';
import { createPatientReducer } from '../domains/patient/scenes/create-patient/store/create-patient.reducer';
import { createProfessionalReducer } from 'src/domains/professional/scenes/create-professional/store/create-professional.reducer';
import { countriesReducer } from '../domains/patient/store/countries/countries.reducer';
import { languagesReducer } from '../domains/patient/store/languages/languages.reducer';
import { editPatientReducer } from '../domains/patient/scenes/edit-patient/store/edit-patient.reducer';
import { editProfileReducer } from 'src/domains/profile/scenes/edit-profile/store/edit-profile.reducer';
import { latestPatientsReducer } from '../domains/device-assignment/store/latest-patients/latest-patients.reducers';
import { currentFormsReducer } from '../domains/patient/store/current-forms/current-forms.reducer';
import { deviceSettingsListReducer } from '../domains/diagnostics/scenes/device-settings-jello/store/device-settings-list/device-settings-list.reducer';
import { deviceSettingsReducer } from '../domains/diagnostics/scenes/device-settings-jello/store/device-settings/device-settings.reducer';
import { mysugrReducer } from '../shared/design-system/forms/components/mysugr-status/store/mysugr.reducer';
import { hcpDashboardReducer } from '../domains/general/widgets/store/hcp-dashboard.reducers';
import { patientReportsReducer } from '../domains/patient-dashboard/store/patient-reports/patient-reports.reducer.ts';
import { continuousMonitoringReducer } from '../domains/patient-dashboards/cgm/store/cgm.reducers';
import { rpmSettingsReducer } from '../domains/rpm/rpm-settings/store/rpm-settings.reducer';
import { manifestModulesReducer } from './modules/store/manifest-modules/manifestModules.reducer';
import { UserUnitsReducer } from '../widgets/user-units-selector/store/user-units-selector.reducer';
import { INSIdentityReducer } from '../domains/patient/store/INS-patient/INS-Identity.reducer';
import { checkPairingCodeReducer } from '../domains/patient/scenes/create-patient/create-patient-with-platform/store/check-pairing-code/check-pairing-code.reducer';
import { createPatientWithFhirReducer } from '../domains/patient/scenes/create-patient/create-patient-with-platform/store/create-patient-with-fhir/create-patient-with-fhir.reducer';
import { editPatientWithFhirReducer } from '../domains/patient/scenes/create-patient/create-patient-with-platform/store/edit-patient-with-fhir/edit-patient-with-fhir.reducer';
import { correlatePairingCodeReducer } from '../domains/patient/scenes/create-patient/create-patient-with-platform/store/correlate-pairing-code/correlate-pairing-code.reducer';
import { pairingCodeIdentityStatusReducer } from 'src/domains/patient/scenes/create-patient/create-patient-with-platform/store/pairing-code-identity-status/pairing-code-identity-status.reducer';
import { checkDataSharingConsentReducer } from 'src/domains/patient-dashboards/bg/store/check-data-sharing-consent/check-data-sharing-consent.reducer';
import { invitePatientReducer } from '../domains/patient/scenes/create-patient/create-patient-with-platform/store/invite-patient/invite-patient.reducer';
import { configReducer } from '../domains/authentication/config';
import { changedPasswordReducer } from 'src/domains/profile/scenes/change-password/store/change-password.reducer';
import { connectSharingCodeReducer } from 'src/domains/patient/scenes/create-edit-form-jello/store/connect-sharing-code/connect-sharing-code.reducer';
import { checkDuplicatedEmailReducer } from 'src/domains/patient/scenes/create-edit-form-jello/store/check-duplicated-email/check-duplicated-email.reducer';
import {
  editPatientPlatformReducer,
  fetchEditPatientDataReducer,
} from 'src/domains/patient/scenes/create-edit-form-jello/store/edit-patient/edit-patient.reducer';
import { checkDuplicatedHealthcareIdReducer } from 'src/domains/patient/scenes/create-edit-form-jello/store/check-duplicated-healthcare-id/check-duplicated-healthcare-id.reducer';
import { sendInvitationReducer } from 'src/domains/patient/scenes/create-edit-form-jello/store/send-invitation/send-invitation.reducer';
import { createFhirPatientReducer } from 'src/domains/patient/scenes/create-edit-form-jello/store/create-patient/create-patient.reducer';
import { correlateSharingCodeReducer } from 'src/domains/patient/scenes/create-edit-form-jello/store/correlate-sharing-code/correlate-sharing-code.reducer';
import { unblindClinicalStudyPatientReducer } from 'src/domains/patient/scenes/clinical-studies/store/interventional-clinical-studies/unblind-clinical-study-patient.reducer';
import { getClinicalStudiesReducer } from 'src/domains/patient/scenes/clinical-studies/store/observational-clinical-studies/get-clinical-studies/get-clinical-studies.reducer';
import { getParticipantIdsReducer } from 'src/domains/patient/scenes/clinical-studies/store/observational-clinical-studies/get-participant-ids/get-participant-ids.reducer.ts';
import { getObservationalStudiesStatusReducer } from 'src/domains/patient/scenes/clinical-studies/store/observational-clinical-studies/get-observational-studies-status/get-observational-studies-status.reducer';
import { announcementsReducer } from 'src/domains/announcements/store/announcements.reducers';
import { enrollPatientToStudyReducer } from 'src/domains/patient/scenes/clinical-studies/store/observational-clinical-studies/enroll-patient-to-study/enroll-patient-to-study.reducer';
import { patientDemoProfileReducer } from 'src/domains/patient/scenes/demo-profile/store/demo-profile.reducer';
import { whatsNewReducer } from 'src/domains/announcements/whats-new/store/whats-new.reducers';

export const reducers = {
  router: routerReducer,
  request: requestReducer,
  navigation: navigationReducer,
  patient: patientReducer,
  patientReports: patientReportsReducer,
  patientSession: patientSessionReducer,
  alerts: alertsReducer,
  prescription: prescriptionReducer,
  permissions: permissionsReducer,
  patientPermissions: patientPermissionsReducer,
  configurablePermissions: configurablePermissionsReducer,
  listDevices: listDevicesReducer,
  deviceSettingsList: deviceSettingsListReducer,
  deviceSettings: deviceSettingsReducer,
  timePeriods: timePeriodsReducer,
  deliveryConfiguration: deliveryConfigurationReducer,
  targetRanges: targetRangesReducer,
  orgStock: orgStockReducer,
  deviceAssignment: deviceAssignmentReducer,
  modals: modalsReducer,
  department: departmentReducer,
  countries: countriesReducer,
  languages: languagesReducer,
  currentForms: currentFormsReducer,
  mysugr: mysugrReducer,
  lastHba1cValue: hba1cCardReducer,
  newEntryHba1c: newEntryHba1cReducer,
  listValuesHba1c: listValuesHba1cReducer,
  hcpDashboard: hcpDashboardReducer,
  continuousMonitoring: continuousMonitoringReducer,
  insIdentity: INSIdentityReducer,
  pairingCodePatient: checkPairingCodeReducer,
  sharingCodePatient: connectSharingCodeReducer,
  checkDuplicatedEmail: checkDuplicatedEmailReducer,
  editPatientData: fetchEditPatientDataReducer,
  editPatientPlatform: editPatientPlatformReducer,
  checkDuplicatedHealthcareId: checkDuplicatedHealthcareIdReducer,
  sendPatientInvitation: sendInvitationReducer,
  createFhirPatient: createFhirPatientReducer,
  correlateSharingCode: correlateSharingCodeReducer,
  userUnitsMeasurement: UserUnitsReducer,
  ...createForms({
    ui: combineReducers({
      patientDashboard: patientDashboardReducer,
      patientDateRange: patientDateRangeReducer,
    }),
    patientSearch: patientSearchReducer,
    latestPatients: latestPatientsReducer,
    deviceAssignmentPatientSearch: deviceAssignmentReducer,
    stripDelivery: stripDeliveryReducer,
    patientStock: patientStockReducer,
    createPatientWithFhir: createPatientWithFhirReducer,
    createPatient: createPatientReducer,
    createProfessional: createProfessionalReducer,
    editPatient: editPatientReducer,
    editPatientWithFhir: editPatientWithFhirReducer,
    correlatePairingCode: correlatePairingCodeReducer,
    invitePatient: invitePatientReducer,
    editProfile: editProfileReducer,
    nextShipment: nextShipmentReducer,
    hcpDashboard: hcpDashboardReducer,
    rpmSettings: rpmSettingsReducer,
    pairingCodeIdentityStatus: pairingCodeIdentityStatusReducer,
    checkDataSharingConsent: checkDataSharingConsentReducer,
    unblindClinicalStudyPatient: unblindClinicalStudyPatientReducer,
    clinicalStudiesIds: getClinicalStudiesReducer,
    participantClinicalStudiesIds: getParticipantIdsReducer,
    observationalClinicalStudiesStatus: getObservationalStudiesStatusReducer,
    enrollPatientToObservationalStudy: enrollPatientToStudyReducer,
    demoProfile: patientDemoProfileReducer,
  }),
  [CIAM_SDK_NAMESPACE]: combineReducers({
    config: configReducer,
    session: sessionReducer,
  }),
  isChangedPassword: changedPasswordReducer,
  uiAlerts: alertReducer,
  manifestModules: manifestModulesReducer,
  announcements: announcementsReducer,
  [IS_WHATS_NEW_VIEWED]: whatsNewReducer,
};

const asyncReducers = {};

export const injectReducer = (store, { key, reducer }) => {
  if (typeof asyncReducers[key] !== 'undefined') {
    return;
  }

  asyncReducers[key] = reducer;

  store.replaceReducer(
    combineReducers({
      ...reducers,
      ...asyncReducers,
    }),
  );
};

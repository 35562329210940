import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withScrollEvent } from 'src/domains/diagnostics/with-scroll-event/with-scroll-event';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';

import { Insulin } from './insulin.component';
import { insulinConnector } from './insulin.selector';

export const InsulinContainer = compose(
  withScrollEvent,
  connect(insulinConnector, null),
  withTranslation,
)(Insulin);

import {
  ListDemoProfileLoaderImpl,
  ListDemoProfilesServiceImpl,
} from './list-demo-profiles.service';
import { mockListDemoProfile } from './list-demo-profiles.mock';

const mockLoader = () => Promise.resolve(mockListDemoProfile);

export const ListDemoProfilesFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : ListDemoProfileLoaderImpl;
  return ListDemoProfilesServiceImpl(loader);
};

import {
  createAction,
  createPayloadAction,
} from '../../../../../../../app/store/app.actions';
import { GetClinicalStudiesTransformedResponseType } from '../../../../../services/patient/clinical-studies/observational-clinical-studies/get-clinical-studies/get-clinical-studies.types';
import {
  GetClinicalStudiesActionType,
  GetClinicalStudiesErrorAction,
  GetClinicalStudiesErrorType,
  GetClinicalStudiesStartAction,
  GetClinicalStudiesSuccessAction,
} from './get-clinical-studies.types';

export const getClinicalStudiesStart = (): GetClinicalStudiesStartAction =>
  createAction(GetClinicalStudiesActionType.GET_CLINICAL_STUDIES_START);

export const getClinicalStudiesSuccess = (
  payload: GetClinicalStudiesTransformedResponseType,
): GetClinicalStudiesSuccessAction =>
  createPayloadAction(
    GetClinicalStudiesActionType.GET_CLINICAL_STUDIES_SUCCESS,
    payload,
  );

export const getClinicalStudiesError = (
  payload: GetClinicalStudiesErrorType,
): GetClinicalStudiesErrorAction =>
  createPayloadAction(
    GetClinicalStudiesActionType.GET_CLINICAL_STUDIES_ERROR,
    payload,
  );

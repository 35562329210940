import styled, { StyledFunction } from 'styled-components';

import { fontSize } from 'src/app/styles/font-sizes';

const UnitValueSpan: StyledFunction<any> = styled.span;

export const UnitValue = UnitValueSpan`
    font-size: ${fontSize.caption};
    line-height: ${fontSize.caption}
`;

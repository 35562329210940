// tslint:disable
import styled from 'styled-components';
import {
  ModalBottom,
  ModalHeader,
} from 'src/shared/design-system/modal/modal.style';

export const StyledTime = styled.p`
  display: inline-block;
  font-size: 1.5em;
  color: #333333;
  margin: 0;
`;

StyledTime.displayName = 'StyledTime';

export const Title = styled.div`
  color: #003366;
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 1rem;
`;

interface IContent {
  direction?: string;
  justifyContent?: string;
  alignItems?: string;
  alignContent?: string;
}
export const Content = styled.div<IContent>`
  width: 100%;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: ${(props) => props.direction || 'row'};
  flex-wrap: nowrap;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-content: ${(props) => props.alignContent || 'stretch'};
  align-items: ${(props) => props.alignItems || 'flex-start'};
`;

interface IRow {
  order?: number;
  width?: string;
  flex?: string;
  marginRight?: string;
}
export const Row = styled.div<IRow>`
  width: ${(props) => props.width || '100%'};
  order: ${(props) => props.order || 0};
  flex: ${(props) => props.flex || 0} 1 auto;
  margin-right: ${(props) => props.marginRight || 0};
  align-self: auto;
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border: 1px solid #e8e8e8;
  margin-top: 1.4rem;
  margin-bottom: 0.8rem;
  border-bottom: none;
`;

export const ItemRadioButton = styled.div`
  margin-right: 1.55rem;
`;

export const SubTitle = styled.div`
  color: #003e7d;
  font-family: Nunito Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 1rem;
`;
export const SectionCheckBoxes = styled.div`
  margin-bottom: 1.5rem;
  line-height: 1.2;
`;
export const ItemCheckbox = styled.div`
  margin-bottom: 1rem;
`;

export const ModalBottomWithShadow = styled(ModalBottom)`
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 25px 0 rgba(6, 77, 50, 0.08);
`;

export const ModalHeaderTimeTracker = styled(ModalHeader)`
  font-size: 1rem;
  background-color: #004d99;
  text-transform: uppercase;
`;

export const StyledErrorMessage = styled.div`
  flex: 1 0;
  font-size: 0.9em;
  margin-left: auto;
  color: #e70a43;
`;

StyledErrorMessage.displayName = 'StyledErrorMessage';

import { pipe } from 'ramda';
import {
  createAuthHeader,
  postJSON,
  stringifyBody,
} from 'src/shared/utils/service/service.utils';

import {
  ENDPOINTS,
  endpointWithParams,
} from 'src/app/navigation/services/service.constants';

import {
  transformServerToClientDeliveryStatus,
  transformStatusToServerDeliveryStatus,
} from '../delivery-status.util';
import { withQueryPatientId } from '../query';

export const SetDeliveryStatusLoaderImpl = (
  { patientId, status, comment },
  accessToken,
  gigyaToken,
) =>
  postJSON(endpointWithParams(ENDPOINTS.deliveryStatus, { patientId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
    body: stringifyBody(
      transformStatusToServerDeliveryStatus({ status, comment }),
    ),
  });

export const SetDeliveryStatusTransformImpl =
  transformServerToClientDeliveryStatus;
export const SetDeliveryStatusServiceImpl =
  (post, transform) => (query, accessToken, gigyaToken) =>
    post(query, accessToken, gigyaToken).then(
      pipe(transform, withQueryPatientId(query)),
    );

import styled from 'styled-components';
import { borderRadius, space } from 'styled-system';

import { colors } from 'src/app/styles/colors';

export const Table = styled.table`
  width: ${(props) => props.width || '100%'};
  border-collapse: collapse;
  margin-bottom: 0.75rem;
  background-color: ${(props) =>
    props.clearFill ? colors.clear : colors.white};
  ${space};
  ${borderRadius};
`;

export const FixedTable = styled(Table)`
  table-layout: fixed;
`;

export const SeparateBordersTable = styled(FixedTable)`
  border-collapse: separate;
  border-spacing: ${(props) => (props.noBorderSpacing ? 0 : '0.75rem')};
`;

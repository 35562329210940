import { EventSystem } from '@roche/roche-common';
import React from 'react';
import { clearPatientState } from 'src/domains/patient/store/patient/patient.action';

export const useHandleNotificationRedirect = ({ goTo }) => {
  React.useEffect(() => {
    const nameEvent = 'NotificationRedirect';
    const eventSystem = new EventSystem();
    eventSystem.initializePubSub();
    eventSystem.subscribe(
      nameEvent,
      (name, data: { notificationAction: string }) => {
        clearPatientState();
        goTo(`/${data.notificationAction}`);
      },
    );
  }, []);
};

import {
  Action,
  PayloadAction,
} from '../../../../../../../app/store/app.types';
import { GetClinicalStudiesTransformedResponseType } from '../../../../../../../domains/patient/services/patient/clinical-studies/observational-clinical-studies/get-clinical-studies/get-clinical-studies.types';

export enum GetClinicalStudiesActionType {
  GET_CLINICAL_STUDIES_START = 'GET_CLINICAL_STUDIES_START',
  GET_CLINICAL_STUDIES_SUCCESS = 'GET_CLINICAL_STUDIES_SUCCESS',
  GET_CLINICAL_STUDIES_ERROR = 'GET_CLINICAL_STUDIES_ERROR',
}

export type GetClinicalStudiesErrorType = FixMe;

export type GetClinicalStudiesStartAction =
  Action<GetClinicalStudiesActionType.GET_CLINICAL_STUDIES_START>;

export type GetClinicalStudiesSuccessAction = PayloadAction<
  GetClinicalStudiesActionType.GET_CLINICAL_STUDIES_SUCCESS,
  GetClinicalStudiesTransformedResponseType
>;

export type GetClinicalStudiesErrorAction = PayloadAction<
  GetClinicalStudiesActionType.GET_CLINICAL_STUDIES_ERROR,
  GetClinicalStudiesErrorType
>;

export type GetClinicalStudiesActions =
  | GetClinicalStudiesStartAction
  | GetClinicalStudiesSuccessAction
  | GetClinicalStudiesErrorAction;

export type GetClinicalStudiesState = {
  isGettingClinicalStudies: boolean;
  isClinicalStudiesSuccessful: boolean;
  isClinicalStudiesError: boolean;
  observationalClinicalStudies: string[];
};

import {
  CheckPairingCodeAvailabilityLoaderImpl,
  CheckPairingCodeAvailabilityServiceImpl,
  CheckPairingCodeAvailabilityTransformImpl,
} from './pairing-code-availability.service';
import { CheckPairingCodeResponse } from './pairing-code-availability.types';
import { Promise } from 'es6-promise';

export const mockLoader = (): Promise<CheckPairingCodeResponse> =>
  Promise.resolve({ status: 200 });

export const CheckPairingCodeAvailabilityFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : CheckPairingCodeAvailabilityLoaderImpl;
  return CheckPairingCodeAvailabilityServiceImpl(
    loader,
    CheckPairingCodeAvailabilityTransformImpl,
  );
};

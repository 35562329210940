import React from 'react';

import { weight } from 'src/domains/diagnostics/components/fonts/weights/weight.style';
import { TableHeader } from 'src/domains/diagnostics/components/table-header/table-header.component';
import { LogbookTableRow } from 'src/domains/diagnostics/components/table/logbook-table-row/logbook-table-row.component';
import { TableHeaderCell } from 'src/domains/diagnostics/components/table/table-header-cell/table-header-cell.component';
import { colors } from 'src/app/styles/colors';
import { INSULIN_PUMP_TABLE_HEADER_KEYS } from 'src/domains/diagnostics/constants/insulin-pump.constants';

const headerWidth = '7rem';

const tableHeaders = [
  {
    key: INSULIN_PUMP_TABLE_HEADER_KEYS.DATE,
    width: headerWidth,
  },
  {
    key: INSULIN_PUMP_TABLE_HEADER_KEYS.TIME,
    width: headerWidth,
  },
  {
    key: INSULIN_PUMP_TABLE_HEADER_KEYS.U,
    width: headerWidth,
  },
  {
    key: INSULIN_PUMP_TABLE_HEADER_KEYS.TYPE,
    width: headerWidth,
  },
  {
    key: INSULIN_PUMP_TABLE_HEADER_KEYS.COMMENTS,
    width: '100%',
  },
];

export const InsulinPumpTableHeader = () => (
  <TableHeader bg={colors.clear}>
    <LogbookTableRow flex={1}>
      {tableHeaders.map((header, i) => {
        const borderBottom = { color: colors.blueMarine };

        return (
          <TableHeaderCell
            key={`logbook diary label header - ${header.key}-${i}`}
            keyText={i}
            headerKey={header.key}
            height="auto"
            width={header.width}
            colSpan={1}
            noFill
            noTopBorder
            noRightBorder
            borderBottom={borderBottom}
            color={colors.blueMarine}
            textTransform="uppercase"
            fontWeight={weight.bold}
            verticalAlign="top"
            overflow="visible"
            padding="0.75rem 0 0 0"
            margin-bottom="0.75rem"
            whiteSpace="normal"
            textAlign="left"
          />
        );
      })}
    </LogbookTableRow>
  </TableHeader>
);

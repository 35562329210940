import { connect } from 'react-redux';
import {
  selectUserAnnouncementNotification,
  selectUserAnnouncements,
} from '../store/announcements.selectors';
import { WhatsNewComponent } from './whats-new.component';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import {
  closeModal,
  markAnnouncementVisited,
} from '../store/announcements.actions';
import { setIsWhatsNewViewed } from './store/whats-new.actions';
import { selectEC6LastAccess } from 'src/app/store/user/user.selectors';
import { selectIsWhatsNewViewed } from './store/whats-new.selectors';

const mapStateToProps = (state) => ({
  userAnnouncementNotification: selectUserAnnouncementNotification(state),
  whatsNewConfig: selectUserAnnouncements(state),
  lastAccess: selectEC6LastAccess(state),
  isWhatsNewViewed: selectIsWhatsNewViewed(state),
});

const dispatchers = mapDispatchers({
  setIsWhatsNewViewed,
  closeModalAction: closeModal,
  markAnnouncementVisitedAction: markAnnouncementVisited.start,
});

export const WhatsNew = connect(
  mapStateToProps,
  dispatchers,
)(WhatsNewComponent);

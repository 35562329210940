import 'rxjs/add/operator/switchMap';
import { from } from 'rxjs/observable/from';
import { of } from 'rxjs/observable/of';
import { push } from 'react-router-redux';
import {
  createFhirPatientError,
  createFhirPatientWithPairingCodeSuccess,
} from './create-patient.actions';
import { Epic } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';
import {
  getTokens,
  dispatchCreatePatientWithoutPairingCodeActions,
  checkLastMeasurementDateFromBGandCGM,
} from '../create-patient/create-patient.utils';
import { State } from 'src/app/store/app.types';
import {
  CreateFhirPatientActions,
  CreateFhirPatientActionType,
  CreateFhirPatientStartAction,
  CreateFhirPatientWithPairingCodeSuccess,
  CreatePairingCodePatientAction,
  CreateWithoutPairingCodePatientAction,
} from './create-patient.actions.types';
import { triggerCreatePatientCompletedCountlyEvent } from '../../create-edit-countly-events/create-edit-countly-events';

export const createPatientWithPairingCodeSuccessEpic =
  () => (action$, store$) =>
    action$
      .ofType(CreateFhirPatientActionType.CREATE_PAIRING_CODE_PATIENT_SUCCESS)
      .switchMap(({ payload }: CreateFhirPatientWithPairingCodeSuccess) => {
        const { accessToken } = getTokens(store$);
        const { patientId, patientProfileUrl, createPatientCountlyEventData } =
          payload;

        return from(
          checkLastMeasurementDateFromBGandCGM(
            accessToken,
            patientId,
            store$,
            patientProfileUrl,
          ),
        ).pipe(
          switchMap(({ patientProfileUrl }) => [
            ...dispatchCreatePatientWithoutPairingCodeActions(payload),
            triggerCreatePatientCompletedCountlyEvent(
              createPatientCountlyEventData,
            ),
            push(patientProfileUrl),
          ]),
          catchError((err) => of(createFhirPatientError(err))),
        );
      });

export const createPatientWithPairingCodeEpic =
  (checkHealthcareIdService) => (action$, store$) =>
    action$
      .ofType(CreateFhirPatientActionType.CREATE_PAIRING_CODE_PATIENT_START)
      .switchMap(({ payload }: CreatePairingCodePatientAction) => {
        const { accessToken, gigyaToken } = getTokens(store$);
        return from(
          checkHealthcareIdService(payload.fhirData, accessToken, gigyaToken),
        ).pipe(
          switchMap(
            ({
              patientProfileUrl,
              patientId,
            }: {
              patientProfileUrl: string;
              patientId: string;
            }) => [
              createFhirPatientWithPairingCodeSuccess({
                ...payload,
                patientId,
                patientProfileUrl,
              }),
            ],
          ),
          catchError((err) => of(createFhirPatientError(err))),
        );
      });

export const createPatientWithoutPairingCodeEpic =
  (checkHealthcareIdService) => (action$, store$) =>
    action$
      .ofType(
        CreateFhirPatientActionType.CREATE_WITHOUT_PAIRING_CODE_PATIENT_START,
      )
      .switchMap(({ payload }: CreateWithoutPairingCodePatientAction) => {
        const { accessToken, gigyaToken } = getTokens(store$);
        const { isAdmin, fhirData, createPatientCountlyEventData } = payload;

        return from(
          checkHealthcareIdService(fhirData, accessToken, gigyaToken),
        ).pipe(
          switchMap(
            ({
              patientProfileUrl,
              patientId,
            }: {
              patientProfileUrl: string;
              patientId: string;
            }) => [
              ...dispatchCreatePatientWithoutPairingCodeActions(payload),
              triggerCreatePatientCompletedCountlyEvent(
                createPatientCountlyEventData,
              ),
              push(isAdmin ? `/patients/${patientId}` : patientProfileUrl),
            ],
          ),
          catchError((err) => of(createFhirPatientError(err))),
        );
      });

export const createFhirPatientEpic: () => Epic<
  CreateFhirPatientActions,
  State
> = () => (action$) =>
  action$
    .ofType(CreateFhirPatientActionType.CREATE_FHIR_PATIENT_START)
    .switchMap(({ payload }: CreateFhirPatientStartAction) => {
      if (!payload.isAdmin && payload.fhirData.pairingCode) {
        return of({
          type: CreateFhirPatientActionType.CREATE_PAIRING_CODE_PATIENT_START,
          payload,
        } as CreatePairingCodePatientAction);
      } else {
        return of({
          type: CreateFhirPatientActionType.CREATE_WITHOUT_PAIRING_CODE_PATIENT_START,
          payload,
        } as FixMe);
      }
    });

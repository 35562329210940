import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { createStyledComponent } from 'src/shared/utils/styles';
import { colors } from 'src/app/styles/colors';
import { spacing } from 'src/app/styles/spacing';
import { Div } from 'src/shared/design-system/div/div.component';
import { MediumTitle } from 'src/shared/design-system/top-patient-bar/typography';

const truncate = `
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const InfoText = createStyledComponent<'div', {}>('div')`
  font-size: 13px;
  margin-top: 12px;
  color: ${colors.charcoal};
  ${truncate};
  @media (max-width: 1118px) {
    font-size: 11px;
  }
`;
InfoText.displayName = 'PatientInfoText';

export const InfoTextDate = styled(InfoText)`
  text-transform: uppercase;
`;
InfoText.displayName = 'InfoTextDate';

export const PatientName = createStyledComponent<'div', {}>('div')`
  color: ${colors.brandBlue};
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 13px;
  max-width: 90%;
  ${truncate};
`;
PatientName.displayName = 'PatientInfoName';

export const PatientId = styled(InfoText)`
  margin-top: 0px;
  font-size: 15px;
  max-width: 90%;
  margin-bottom: 5px;
  ${truncate};
  @media (max-width: 1024px) {
    margin-right: 0.5rem;
  }
`;
PatientId.displayName = 'PatientInfoId';

export const Spacer = styled(Div)`
  margin: 0 1rem;
  height: 100%;
  width: 1px;
  min-width: 1px;
  background-color: ${colors.gray3};
`;
Spacer.displayName = 'PatientInfoSpacer';

export const SpacerShort = styled(Div)`
  margin: 0 ${spacing.three};
  height: 100%;
  width: 1px;
  min-width: 1px;
  background-color: ${colors.gray3};
`;
SpacerShort.displayName = 'PatientInfoSpacerShort';

export const SettingsLink = styled(NavLink)`
  position: absolute;
  top: 3px;
  right: -2rem;
`;
SettingsLink.displayName = 'PatientInfoSettingsLink';

export const Title = styled(MediumTitle)`
  vertical-align: top;
  width: 100%;
  ${truncate};
  @media (max-width: 1118) {
    font-size: 9px;
  }
`;
Title.displayName = 'PatientInfoTitleText';

export const RowPatientInfo = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
`;
RowPatientInfo.displayName = 'RowPatientInfo';

export const TimeTrackerWrapper = styled('span')`
  min-width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &: hover {
    cursor: pointer;
  }
`;

TimeTrackerWrapper.displayName = 'TimeTrackerWrapper';

export const BlockDescription = styled(Div)`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (min-width: 1118) {
    margin-left: -10px;
  }
`;

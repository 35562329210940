import { createStructuredSelector } from 'reselect';
import { selectEC6UserLanguage } from 'src/app/store/user/user.selectors';
import { path } from 'ramda';

export const selectNextShipment = path(['nextShipment']);
export const selectNextShipmentId = path(['nextShipment', 'id']);
export const selectResultDescription = path([
  'nextShipment',
  'resultDescription',
]);

export const nextShipmentConnector = createStructuredSelector({
  nextShipment: selectNextShipment,
  locale: selectEC6UserLanguage,
});

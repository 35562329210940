import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { selectPatientGigyaUid } from 'src/domains/patient/store/patient/patient.selector';

import {
  MysugrStatusLoaderImplType,
  MysugrStatusResponseTransformed,
  MysugrStatusValues,
} from 'src/app/services/mysugr-status/mysugr-status.types';
import { MysugrSendInviteLoaderImplType } from 'src/app/services/mysugr-send-invite/mysugr-send-invite.types';

import {
  mysugrInviteError,
  mysugrInviteReset,
  mysugrInviteSuccess,
  mysugrStatusSuccess,
} from './mysugr.actions';

import {
  MysugrInviteActions,
  MysugrInviteActionType,
  MysugrStatusActions,
  MysugrStatusActionType,
} from './mysugr.types';

export const mysugrStatusEpic =
  (
    mysugrStatusService: MysugrStatusLoaderImplType,
  ): Epic<MysugrStatusActions, any> =>
  (action$, store) =>
    action$
      .ofType(MysugrStatusActionType.MYSUGR_STATUS_START)
      .switchMap((action) =>
        Observable.fromPromise(
          mysugrStatusService(
            {
              accessToken: selectAccessToken(store.getState()),
              gigyaToken: selectGigyaToken(store.getState()),
            },
            selectPatientGigyaUid(store.getState()),
          ),
        )
          .map((data: MysugrStatusResponseTransformed) =>
            mysugrStatusSuccess(data),
          )
          // tslint:disable-next-line
          .pipe(
            catchError((err) =>
              Observable.of(mysugrStatusSuccess(MysugrStatusValues.INACTIVE)),
            ),
          ),
      );

export const mysugrSendInviteEpic =
  (
    sendInviteService: MysugrSendInviteLoaderImplType,
  ): Epic<MysugrInviteActions, any> =>
  (action$, store) =>
    action$
      .ofType(MysugrInviteActionType.MYSUGR_INVITE_START)
      .switchMap((action) =>
        Observable.fromPromise(
          sendInviteService(
            {
              accessToken: selectAccessToken(store.getState()),
              gigyaToken: selectGigyaToken(store.getState()),
            },
            selectPatientGigyaUid(store.getState()),
          ),
        )
          .map((data: string) => mysugrInviteSuccess(data))
          // tslint:disable-next-line
          .pipe(catchError((err) => Observable.of(mysugrInviteError(err)))),
      );

export const mysugrResetInviteEpic = (
  action$,
  store,
): Epic<MysugrInviteActions, MysugrInviteActions> =>
  action$
    .ofType(
      MysugrInviteActionType.MYSUGR_INVITE_SUCCESS,
      MysugrInviteActionType.MYSUGR_INVITE_ERROR,
    )
    .debounceTime(8000)
    .map((action) => mysugrInviteReset());

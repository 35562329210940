import {
  createAction,
  createPayloadAction,
} from '../../../../../../app/store/app.actions';
import {
  ClearCreateFhirPatientStateAction,
  CreateFhirPatientActionType,
  CreateFhirPatientErrorAction,
  CreateFhirPatientErrorPayload,
  CreateFhirPatientStartAction,
  CreateFhirPatientStartPayload,
  CreateFhirPatientSuccessAction,
  CreateFhirPatientWithPairingCodeSuccess,
  CreateFhirPatientWithPairingCodeSuccessPayload,
} from './create-patient.actions.types';

export const createFhirPatientStart = (
  payload: CreateFhirPatientStartPayload,
): CreateFhirPatientStartAction =>
  createPayloadAction(
    CreateFhirPatientActionType.CREATE_FHIR_PATIENT_START,
    payload,
  );

export const createFhirPatientSuccess = (): CreateFhirPatientSuccessAction =>
  createAction(CreateFhirPatientActionType.CREATE_FHIR_PATIENT_SUCCESS);

export const createFhirPatientError = (
  payload: CreateFhirPatientErrorPayload,
): CreateFhirPatientErrorAction =>
  createPayloadAction(
    CreateFhirPatientActionType.CREATE_FHIR_PATIENT_ERROR,
    payload,
  );

export const clearCreateFhirPatientStateAction =
  (): ClearCreateFhirPatientStateAction =>
    createAction(CreateFhirPatientActionType.CLEAR_CREATE_FHIR_PATIENT_STATE);

export const createFhirPatientWithPairingCodeSuccess = (
  payload: CreateFhirPatientWithPairingCodeSuccessPayload,
): CreateFhirPatientWithPairingCodeSuccess =>
  createPayloadAction(
    CreateFhirPatientActionType.CREATE_PAIRING_CODE_PATIENT_SUCCESS,
    payload,
  );

import * as React from 'react';
import { theme, ThemeInterface } from '../../../../shared/theme';
import { DropdownSelect } from '../dropdown-select.component';
import { withTheme } from 'styled-components';
import {
  DropdownBtn,
  DropdownButtonContainer,
} from './dropdown-buttons.styles';
import {
  LeftChevronIcon,
  RightChevronIcon,
} from '../../../../shared/design-system/icons';
import { SelectOption } from '../dropdown-select.types';
import {
  DropdownButtonProps,
  DropdownButtonState,
} from './dropdown-buttons.types';
import { isNotEmpty } from '../../../../shared/utils/validation-helpers';
import { isEmpty } from 'ramda';

export class DropdownButtonsComponent extends React.Component<
  DropdownButtonProps<SelectOption<string | number>>,
  DropdownButtonState<SelectOption<string | number>>
> {
  constructor(props: DropdownButtonProps<SelectOption<string | number>>) {
    super(props);
    const data = isNotEmpty(this.props.data) ? this.props.data[0] : {};
    const initValue: any = this.props.selectedOption || {};
    this.state = {
      selectedOption: initValue.value ? this.props.selectedOption : data,
    };
  }

  public isEndItem = (element) => {
    const data = this.props.data || [];
    if (data.length >= 1) {
      return data[data.length - 1].value === element.value;
    }
    return false;
  };

  public isFirstItem = (element) => {
    const data = this.props.data || [];
    if (data.length >= 1) {
      return data[0].value === element.value;
    }
    return false;
  };

  public isDataEmpty = () => {
    return isEmpty(this.props.data);
  };

  public checkLeftButtons = () => {
    if (this.props.invertButtons) {
      return this.isEndItem(this.state.selectedOption) || this.isDataEmpty();
    } else {
      return this.isFirstItem(this.state.selectedOption) || this.isDataEmpty();
    }
  };

  public checkRightButtons = () => {
    if (this.props.invertButtons) {
      return this.isFirstItem(this.state.selectedOption) || this.isDataEmpty();
    } else {
      return this.isEndItem(this.state.selectedOption) || this.isDataEmpty();
    }
  };

  public handleNextItem = () => {
    if (!this.isEndItem(this.state.selectedOption)) {
      const option: any = this.state.selectedOption;
      const index = this.props.data.findIndex((v) => v.value === option.value);
      const item: SelectOption<string | number> = this.props.data[index + 1];
      this.handleOnChange(item);
    }
  };

  public handlePrevItem = () => {
    if (!this.isFirstItem(this.state.selectedOption)) {
      const option: any = this.state.selectedOption;
      const index = this.props.data.findIndex((v) => v.value === option.value);
      const item: SelectOption<string | number> = this.props.data[index - 1];
      this.handleOnChange(item);
    }
  };

  public handleOnChange = (item: any) => {
    this.setState({ selectedOption: item });
    if (this.props.onChange) {
      this.props.onChange(item);
    }
  };

  public render() {
    const { data, titleOptions, placeholder, invertButtons } = this.props;
    return (
      <DropdownButtonContainer>
        <DropdownBtn
          className={`is-left ${this.checkLeftButtons() ? 'is-disabled' : ''}`}
          onClick={invertButtons ? this.handleNextItem : this.handlePrevItem}
        >
          <LeftChevronIcon
            width={30}
            height={40}
            strokeColor={
              this.checkLeftButtons()
                ? theme.colors.grayLight
                : theme.colors.brandBlue
            }
          />
        </DropdownBtn>
        <DropdownSelect
          width={'100%'}
          titleOptions={titleOptions}
          onChange={this.handleOnChange}
          placeholder={placeholder}
          selectedOption={this.state.selectedOption}
          options={data}
          borderRadius={false}
          borderColor={theme.colors.grayLighter}
          DisplayComponent={this.props.DisplayComponent}
          DisplayOptionComponent={this.props.DisplayOptionComponent}
          showArrow={false}
        />
        <DropdownBtn
          className={`is-right ${
            this.checkRightButtons() ? 'is-disabled' : ''
          }`}
          onClick={invertButtons ? this.handlePrevItem : this.handleNextItem}
        >
          <RightChevronIcon
            width={30}
            height={40}
            strokeColor={
              this.checkRightButtons()
                ? theme.colors.grayLight
                : theme.colors.brandBlue
            }
          />
        </DropdownBtn>
      </DropdownButtonContainer>
    );
  }
}

export const DropdownButtons = withTheme<
  { theme?: ThemeInterface } & DropdownButtonProps<
    SelectOption<string | number>
  >,
  ThemeInterface
>(DropdownButtonsComponent);

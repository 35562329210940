import * as React from 'react';
import { AnnouncementTextComponent } from '../../../components/announcement-text.component';
import { AnnouncementLinespaceComponent } from '../../../components/announcement-linespace.component';
import { AnnouncementMediaComponent } from '../../../components/announcement-media.component';
import { Page } from '../../../store/announcements.types';
import { RenderIf } from 'src/shared/utils/render-if';
import {
  BREAKLINE,
  CONTAINER,
  GIF,
  ID,
  IMAGE,
  PAGE_CONTENT,
  TEXT,
  VIDEO,
  WHATS_NEW_MEDIA_FALLBACK_TEXT,
} from '../../whats-new.constants';
import { testId } from '@roche/roche-common';

type WhatsNewContentProps = {
  content?: Page;
};

export const WhatsNewUiComposer: React.FunctionComponent<
  WhatsNewContentProps
> = ({ content }) => {
  const renderContent = (page: Page) => {
    return page?.pageContent?.map((item, index) => {
      switch (item.type) {
        case TEXT:
          return (
            <AnnouncementTextComponent
              key={index}
              value={item.value}
              style={item.style}
            />
          );
        case BREAKLINE:
          return <AnnouncementLinespaceComponent key={index} />;
        case IMAGE:
        case GIF:
        case VIDEO:
          return (
            <AnnouncementMediaComponent
              key={`${index}_${page.pageNo}`}
              index={index}
              mediaType={item.type}
              url={item.value}
              alt={WHATS_NEW_MEDIA_FALLBACK_TEXT}
            />
          );
        default:
          return null;
      }
    });
  };

  return (
    <React.Fragment>
      <RenderIf validate={content}>
        <div {...testId(`${ID}-${PAGE_CONTENT}`, CONTAINER)}>
          {renderContent(content as Page)}
        </div>
      </RenderIf>
    </React.Fragment>
  );
};

import React from 'react';
import { Bundle } from 'src/app/navigation/bundle';
import { getConfig } from '@roche/roche-common';

// START Temporarily added
const { REACT_APP_BGM_NEW_DASHBOARD_ENABLED } = getConfig();

function getQueryParams() {
  const params = new URLSearchParams(window.location.search);
  return params;
}

function hasNewBg() {
  const params = getQueryParams();
  return REACT_APP_BGM_NEW_DASHBOARD_ENABLED === 'true' && params.has('newBG');
}
// END Temporarily added

export const BgDashboardGpBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      let BgDashboardGpContainer;
      if (hasNewBg()) {
        ({ BgDashboardContainer: BgDashboardGpContainer } = await import(
          /* webpackChunkName: "bgDashboardNew" */ './bg-new/scenes/dashboard/bg-dashboard.container'
        ));
      } else {
        ({ BgDashboardGpContainer: BgDashboardGpContainer } = await import(
          /* webpackChunkName: "bgDashboardGp" */ './bg/scenes/dashboard/bg-dashboard.container'
        ));
      }
      return BgDashboardGpContainer;
    }}
    bundleDidLoad={(BgDashboardGpContainer) => (
      <BgDashboardGpContainer {...props} />
    )}
  />
);

export const BgDashboardBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      let BgDashboardContainer;

      if (hasNewBg()) {
        ({ BgDashboardContainer } = await import(
          /* webpackChunkName: "bgDashboardNew" */ './bg-new/scenes/dashboard/bg-dashboard.container'
        ));
      } else {
        ({ BgDashboardContainer } = await import(
          /* webpackChunkName: "bgDashboard" */ './bg/scenes/dashboard/bg-dashboard.container'
        ));
      }

      return BgDashboardContainer;
    }}
    bundleDidLoad={(BgDashboardContainer) => (
      <BgDashboardContainer {...props} />
    )}
  />
);

export const CgmDashboardBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { CGMDashboard } = await import(
        /* webpackChunkName: "cgmDashboard" */ './cgm/scenes/dashboard/cgm-dashboard.container'
      );
      return CGMDashboard;
    }}
    bundleDidLoad={(CGMDashboard) => <CGMDashboard {...props} />}
  />
);

import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';

import {
  NextShipmentFetchLoaderImplType,
  NextShipmentFetchServiceImplType,
  OpenId,
} from './next-shipment-fetch.types';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const NextShipmentFetchLoaderImpl: NextShipmentFetchLoaderImplType = (
  patientId: string,
  openId: OpenId,
) =>
  getJSON(
    endpointWithParams(ENDPOINTS.nextShipmentChangeFetch, { patientId }),
    {
      headers: {
        Authorization: createAuthHeader(openId.accessToken),
      },
    },
  );

export const NextShipmentFetchTransformImpl = (data: any) => {
  const resultDescription = data.resultDescription;
  const { id, shipDate, shipStatus, shipQtty } = data.model.shipment;
  return {
    id,
    shipDate,
    shipStatus,
    shipQtty,
    resultDescription,
  };
};

export const NextShipmentFetchServiceImpl: NextShipmentFetchServiceImplType =
  (load: NextShipmentFetchLoaderImplType, transform) => (query, token) =>
    load(query, token).then(transform);

import { BOLUS_TYPE } from 'src/domains/diagnostics/store/constants';
import { fixToDecimalPlace } from 'src/shared/utils/stat';

import {
  CombineOverlappingInsulinOneBolusMeasurementsFn,
  FlagOverlappingBolusMeasurementsFn,
} from './insulin.utilities.types';

/**
 * @description If there is a glucose measurement with insulin1 from the
 * glucoseMeasurements array and a bolus measurement from the newData.bolus
 * array with the exact same timestamp, then we combine the the glucose.insulin1
 * and insulin.bolusValue, because we treat all boluses as insulin1 type insulin.
 * The hover over should display the bolus and insulin1 values separately,
 * which is why we need to preserve the original.
 *
 * References: HCPWGRAPHS-1460 and HCPWGRAPHS-1480
 * Functions: bolusTypeToParseFunctionMap, getBolusType1Value
 */
export const combineOverlappingInsulinOneBolusMeasurements: CombineOverlappingInsulinOneBolusMeasurementsFn =
  (datesEqual) => (glucoseMeasurements, bolusMeasurements) =>
    glucoseMeasurements.map((glucose) => {
      const overlappingBolus = bolusMeasurements.find((insulin) =>
        datesEqual(insulin.date, glucose.date),
      );
      const { insulin1 } = glucose;

      return overlappingBolus !== undefined &&
        insulin1 !== null &&
        overlappingBolus.bolusType !== BOLUS_TYPE.EXTENDED
        ? {
            ...glucose,
            bolusValue: fixToDecimalPlace(
              insulin1 + overlappingBolus.bolusValue,
              1,
            ),
            bolusValueOriginal: overlappingBolus.bolusValue,
            combinedInsulin1: true,
          }
        : glucose;
    });

/**
 * @description If there is a bolus measurement from the newData.bolus
 * array that overlaps with a glucose measurement with a non-null insulin1
 * value with the exact same timestamp, then we want to flag the bolus
 * measurement, because the its value has already been combined with the
 * insulin1 value, we want to avoid duplicate plotting. Originally wanted
 * to set this to zero, but bolusValue is used to calculate multiwave bolus
 * measurement dimensions.
 *
 * So for STANDARD and QUICK bolus measurements, they won't be plotted.
 * For EXTENDED bolus, bolusValue isn't used for lineHeight, so nothing changes
 * (the rectangle is plotted).
 * For MULTIWAVE bolus, the opaque bar won't be plotted, just the rectangle.
 *
 * References: HCPWGRAPHS-1460 and HCPWGRAPHS-1480.
 * Functions: bolusTypeToParseFunctionMap, getBolusType1Value
 */
export const flagOverlappingBolusMeasurements: FlagOverlappingBolusMeasurementsFn =
  (datesEqual) => (glucoseMeasurements, bolusMeasurements) =>
    bolusMeasurements.map((insulin) => {
      const overlappingGlucose = glucoseMeasurements.find((glucose) =>
        datesEqual(glucose.date, insulin.date),
      );

      return overlappingGlucose !== undefined &&
        overlappingGlucose.insulin1 !== null &&
        insulin.bolusType !== BOLUS_TYPE.EXTENDED
        ? {
            ...insulin,
            overlappingBolusWithGlucose: true,
          }
        : insulin;
    });

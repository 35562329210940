import React from 'react';

import { colors } from 'src/app/styles/colors';

import { SvgIcon } from 'src/shared/design-system/icon/icon.component';

type Props = {
  height?: number,
  fillColor?: string,
};

export const DeviceDownloadDashboardIcon = ({
  height = 39,
  fillColor = colors.grayMedium,
}: Props) => {
  const originalWidth = 21;
  const originalHeight = 23;

  const aspectRatio = originalWidth / originalHeight;

  const filterId = 'shadowBlur';

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <defs>
        <filter id={filterId}>
          <feGaussianBlur stdDeviation="10 0" in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Icon/Meter-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group" transform="translate(2.000000, 3.000000)"></g>
        <rect
          id="Rectangle"
          stroke="#323B74"
          strokeWidth="1.4"
          fill="#FFFFFF"
          x="2.3"
          y="1.3"
          width="17.4"
          height="19.4"
          rx="2"
        ></rect>
        <g
          id="Rectangle-2"
          transform="translate(7.000000, 6.000000)"
          fill="#E7EEFA"
          stroke="#323B74"
          strokeWidth="1.4"
        >
          <rect
            id="Rectangle"
            x="-0.7"
            y="-0.7"
            width="9.4"
            height="5.4"
            rx="0.2"
          ></rect>
        </g>
        <circle
          id="Oval"
          stroke="#508EE5"
          fill="#FFFFFF"
          cx="8"
          cy="16"
          r="1.5"
        ></circle>
        <circle
          id="Oval"
          stroke="#508EE5"
          fill="#FFFFFF"
          cx="14"
          cy="16"
          r="1.5"
        ></circle>
      </g>
    </SvgIcon>
  );
};

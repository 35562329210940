import {
  createAuthHeader,
  getJSON,
} from '../../../../shared/utils/service/service.utils';
import { ENDPOINTS } from '../../../../app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';
import { generateAnnouncementUrl } from '../../whats-new/utils/whats-new.utility';
import { getConfig } from '@roche/roche-common';
import { IMAGE, VIDEO, GIF, RDCP } from '../../whats-new/whats-new.constants';
import {
  AnnouncementContent,
  ContentItem,
  Page,
  UserAnnouncements,
  UserAnnouncementsResponse,
  UserAnnouncementsTypes,
} from '../../store/announcements.types';
const { REACT_APP_PUBLIC_ASSETS_BASE_URL } = getConfig();

export const UserAnnouncementsLoaderImpl = (
  { type, countryCode },
  accessToken: string,
) => {
  return getJSON(
    endpointWithParams(ENDPOINTS.userAnnouncements, { type, countryCode }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );
};

const constructMediaURL = (data: UserAnnouncementsResponse) => {
  const content: AnnouncementContent = JSON.parse(data.content);
  const baseUrl = REACT_APP_PUBLIC_ASSETS_BASE_URL;
  const productName = RDCP;
  const announcementType = data?.type;
  const version = data?.releaseTag;
  const announcementUuid = data?.announcementUUID;

  (content?.pages || []).forEach((page: Page) => {
    (page.pageContent || []).map((item: ContentItem) => {
      if (
        (item.type === IMAGE || item.type === VIDEO || item.type === GIF) &&
        item.value
      ) {
        const fileName = item.value;
        const url = generateAnnouncementUrl(
          baseUrl,
          productName,
          announcementType,
          version,
          announcementUuid,
          fileName,
        );
        item.value = url;
      }
    });
  });
  return content;
};

export const UserAnnouncementsTransformImpl = (
  data: UserAnnouncementsResponse,
): UserAnnouncements | UserAnnouncementsResponse => {
  if (data.type === UserAnnouncementsTypes.WHATS_NEW) {
    return {
      ...data,
      content: constructMediaURL(data),
    };
  } else {
    return data;
  }
};

export const UserAnnouncementsServiceImpl =
  (load, transform) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);

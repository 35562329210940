import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';
import {
  ProfileType,
  ProfileTypesParams,
  ProfileTypesResponse,
} from './profile-types.types';
import { transformProfileTypes } from './profile-types.utils';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const ProfileTypesLoaderImpl = (
  { departmentId }: ProfileTypesParams,
  accessToken: string,
) => {
  return getJSON(endpointWithParams(ENDPOINTS.profiles, { departmentId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  });
};

export const ProfileTypesTransformImpl = ({
  model,
}: ProfileTypesResponse): ProfileType[] => transformProfileTypes(model);

export const ProfileTypesServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);

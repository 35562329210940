import styled from 'styled-components';

import { colors } from 'src/app/styles/colors';
import { spacing } from 'src/app/styles/spacing';
import { transitions } from 'src/app/styles/transitions';
import { Title } from 'src/shared/design-system/fonts/title/title.style';
import { weight } from 'src/shared/design-system/fonts/weights/weight.style';
import { combineRems, convertPxToRem } from 'src/shared/utils/rem-calc';

export const LoadingMessageContainerDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.flexibleHeight ? '100%' : 'auto')};
  justify-content: center;
  min-height: ${(props) =>
    `${
      props.minHeight === 'inherit'
        ? props.minHeight
        : convertPxToRem(props.minHeight)
    }`};
  transition: ${transitions.default};
  width: 100%;
`;

export const LoadingMessageHeadline = styled(Title)`
  color: ${colors.black};
  font-weight: ${weight.semiBold};
  margin: 0 auto ${combineRems(spacing.three, spacing.two)};
  min-height: 1em;
  white-space: pre-wrap;
`;

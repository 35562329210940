import { getObservationalStudiesStatusMockResponse } from './get-observational-studies-status.mock';
import {
  GetObservationalStudiesStatusLoaderImpl,
  GetObservationalStudiesStatusServiceImpl,
  GetObservationalStudiesStatusTransformImp,
} from './get-observational-studies-status.service';
import { GetObservationalStatusResponseType } from './get-observational-studies-status.types';

export const mockLoaderObservationalStauts =
  (): Promise<GetObservationalStatusResponseType> =>
    Promise.resolve(getObservationalStudiesStatusMockResponse);

export const GetObservationalStatusFactoryImp = ({ devMode }) => {
  const loader = devMode
    ? mockLoaderObservationalStauts
    : GetObservationalStudiesStatusLoaderImpl;
  return GetObservationalStudiesStatusServiceImpl(
    loader,
    GetObservationalStudiesStatusTransformImp,
  );
};

import { convertPxToRem } from '../../../shared/utils/rem-calc';
import * as React from 'react';
import styled, { StyledComponentClass } from 'styled-components';
import { ThemeInterface } from '../../../shared/theme';
import { fontSize } from '../../../app/styles/font-sizes';
import { breakpoints } from '../../../app/styles/breakpoints';

interface DropdownSelectContainerProps {
  width?: string;
  flex?: string;
  onClick?: (e: MouseEvent) => void;
}

const textDisabled = ({ theme }) => theme.colors.grayLight;
export const convertPxToRemWithTheme =
  (px: string) =>
  ({ theme }) =>
    convertPxToRem(px);

export const DropdownSelectContainer: StyledComponentClass<
  DropdownSelectContainerProps,
  ThemeInterface
> = styled<DropdownSelectContainerProps, 'div'>('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ width }) => (width ? width : '')};
  flex: ${({ flex }) => (flex ? flex : '')};
  cursor: pointer;
  background: ${(props) => props.theme.colors.white};
  @media (max-width: ${breakpoints.medium}) {
    width: 177px;
  }
`;
DropdownSelectContainer.displayName = 'DropdownSelectContainer';

type DropdownSelectInputContainerProps = {
  className?: string;
  borderColor?: string;
  borderRadius?: boolean;
};

export const DropdownSelectInputContainer: StyledComponentClass<
  DropdownSelectInputContainerProps,
  ThemeInterface
> = styled<DropdownSelectInputContainerProps, 'div'>('div')`
  display: flex;
  width: 100%;
  border-radius: ${(props) =>
    props.borderRadius ? convertPxToRemWithTheme('4px') : 0};
  border: 1px solid
    ${(props) =>
      props.borderColor ? props.borderColor : props.theme.colors.grayLighter};
  height: ${convertPxToRemWithTheme('40px')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.is-open {
    border-radius: ${(props) => (props.borderRadius ? '4px 4px 0 0' : 0)};
  }
`;
DropdownSelectInputContainer.displayName = 'DropdownSelectInputContainer';

type DropdownSelectButtonProps = {
  className?: string;
  disabled?: boolean;
  borderRadius?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const DropdownSelectButton: StyledComponentClass<
  DropdownSelectButtonProps,
  ThemeInterface
> = styled<DropdownSelectInputContainerProps, 'button'>('button')`
  width: 3rem;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.grayMedium};
  border: none;
  border-radius: ${(props) => (props.borderRadius ? '0 4px 4px 0' : 0)};
  margin: 0;
  outline: none;
  text-decoration: none;
  text-align: center;
  transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1);

  &.is-disabled {
    path {
      fill: ${textDisabled};
    }
  }

  &.is-open,
  &:not(.is-disabled):hover {
    cursor: pointer;
    background-color: #0066cc;
    color: ${(props) => props.theme.colors.red};
    border-radius: ${(props) => (props.borderRadius ? '0 4px 0 0' : 0)};
    path {
      fill: ${(props) => props.theme.colors.white};
    }

    path {
      transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
`;
DropdownSelectButton.displayName = 'DropdownSelectButton';

type DropdownSelectDisplayProps = {
  className?: string;
  borderRadius?: boolean;
};

export const DropdownSelectDisplay: StyledComponentClass<
  DropdownSelectDisplayProps,
  ThemeInterface
> = styled<DropdownSelectDisplayProps, 'span'>('span')`
  display: flex;
  height: 100%;
  flex: 1 1 auto;
  align-items: center;
  border: none;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) =>
    props.borderRadius ? convertPxToRemWithTheme('4px') : 0};
  padding: 0 20px;
  min-width: 0;
  font-size: ${({ theme }) => theme.fontSize.p};
  color: ${({ theme }) => theme.colors.charcoal};

  &.is-disabled {
    color: ${textDisabled};
  }

  @media (max-width: ${breakpoints.medium}) {
    font-size: ${fontSize.caption};
  }
`;
DropdownSelectDisplay.displayName = 'DropdownSelectDisplay';

type DropdownSelectDropdownContainerProps = {
  borderRadius?: boolean;
  borderColor?: string;
  hasTitle?: boolean;
};

export const DropdownSelectDropdownContainer: StyledComponentClass<
  DropdownSelectDropdownContainerProps,
  ThemeInterface
> = styled<DropdownSelectDropdownContainerProps, 'div'>('div')`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${convertPxToRemWithTheme('40px')};
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  // min-width: 330px;
  border: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : '#ccc')};
  border-top: none;
  max-height: ${(props) =>
    props.hasTitle
      ? convertPxToRemWithTheme('200px')
      : convertPxToRemWithTheme('140px')};
  overflow-y: scroll;
  z-index: 100;
  border-radius: ${(props) => (props.borderRadius ? '0 0 4px 4px' : 0)};
`;
DropdownSelectDropdownContainer.displayName = 'DropdownSelectDropdownContainer';

interface DropdownSelectDropdownRowProps {
  optionFontSize?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const DropdownSelectDropdownRow: StyledComponentClass<
  DropdownSelectDropdownRowProps,
  ThemeInterface
> = styled<DropdownSelectDropdownRowProps, 'div'>('div')`
  height: ${convertPxToRemWithTheme('48px')};
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  padding: ${convertPxToRemWithTheme('16px')} ${convertPxToRemWithTheme('20px')};
  transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1);
  font-size: ${({ optionFontSize, theme }) =>
    optionFontSize ? optionFontSize : theme.fontSize.p};
  color: ${({ theme }) => theme.colors.charcoal};

  &:hover {
    background-color: ${(props) => props.theme.colors.silver};
    cursor: pointer;
  }

  &.is-selected {
    background: ${(props) => props.theme.colors.silverLight};
    border-left: ${convertPxToRemWithTheme('3px')} solid
      ${(props) => props.theme.colors.brandBlue};
    padding: ${convertPxToRemWithTheme('16px')}
      ${convertPxToRemWithTheme('17px')};
  }

  @media (max-width: ${breakpoints.medium}) {
    font-size: ${fontSize.caption};
  }
`;
DropdownSelectDropdownRow.displayName = 'DropdownSelectDropdownRow';

export const DropdownSelectDropdownTitleRow: StyledComponentClass<
  DropdownSelectDropdownRowProps,
  ThemeInterface
> = styled<DropdownSelectDropdownRowProps, 'div'>('div')`
  height: ${convertPxToRemWithTheme('68px')};
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  line-height: ${convertPxToRemWithTheme('22px')};
  padding: 1rem 1.25rem;
  transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1);
  font-size: ${({ optionFontSize, theme }) =>
    optionFontSize ? optionFontSize : theme.fontSize.subheading};
  color: ${({ theme }) => theme.colors.charcoal};
`;
DropdownSelectDropdownTitleRow.displayName = 'DropdownSelectDropdownTitleRow';

import {
  getJSON,
  createAuthHeader,
} from 'src/shared/utils/service/service.utils';

import { transformServerAlertsToAlerts } from '../alerts.utils';

import {
  ENDPOINTS,
  endpointWithParams,
} from 'src/app/navigation/services/service.constants';

export const GetAlertsLoaderImpl = ({ patientId }, accessToken) =>
  getJSON(endpointWithParams(ENDPOINTS.alerts, { patientId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  });

export const GetAlertsTransformImpl = transformServerAlertsToAlerts;

export const GetAlertsServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);

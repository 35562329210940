import { getParticipantIdsMockresponse } from './get-participant-ids.mock';
import {
  GetParticipantIdsLoaderImpl,
  GetParticipantIdsServiceImpl,
  GetParticipantIdsTransformImp,
} from './get-participant-ids.service';
import { GetParticipantIdsResponseType } from './get-participant-ids.types';

export const mockLoaderParticipantIds =
  (): Promise<GetParticipantIdsResponseType> =>
    Promise.resolve(getParticipantIdsMockresponse);

export const GetParticipantIdsFactoryImp = ({ devMode }) => {
  const loader = devMode
    ? mockLoaderParticipantIds
    : GetParticipantIdsLoaderImpl;
  return GetParticipantIdsServiceImpl(loader, GetParticipantIdsTransformImp);
};

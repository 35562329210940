export const EVENTS_KEYS = {
  REPORTS_CLICKED: 'Reports clicked icon',
};

export const SEGMENT_KEYS = {
  SECTION: 'Section',
};

export const SEGMENT_VALUES = {
  BGM: 'BGM',
  CGM: 'CGM',
};

import styled from 'styled-components';

import { colors } from '../../colors';

const checkboxSize = 18;
const thickSize = checkboxSize - 6;

function tick(fillColor: string) {
  return `url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' fill='${fillColor}' x='0' y='0' width='${thickSize}px' height='${thickSize}px' viewBox='-10 -10 611.99 611.99' style='enable-background:new 0 0 611.99 611.99;' xml:space='preserve'%3E%3Cg%3E%3Cg id='_x39__34_'%3E%3Cg%3E%3Cpath d='M589.105,80.63c-30.513-31.125-79.965-31.125-110.478,0L202.422,362.344l-69.061-70.438 c-30.513-31.125-79.965-31.125-110.478,0c-30.513,31.125-30.513,81.572,0,112.678l124.29,126.776 c30.513,31.125,79.965,31.125,110.478,0l331.453-338.033C619.619,162.202,619.619,111.755,589.105,80.63z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`;
}

export const StyledCheckbox = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: ${checkboxSize}px;
  height: ${checkboxSize}px;
  margin-right: 8px;
  outline: none !important;
  background-clip: content-box;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  flex-shrink: 0;
  border-radius: 3px;
  border: 2px solid
    ${(props) => (props.disabled ? colors.disabledGray : colors.mainBlue)};
  background-color: ${(props) => {
    if (!props.checked) return '';

    if (!props.disabled) return colors.mainBlue;

    return colors.disabledGray;
  }};

  &::before {
    content: ${(props) => (props.checked ? tick(colors.bgBlue) : '')};
    position: relative;
    top: -2px;
    left: 1px;
  }

  &:focus {
    outline: none !important;
  }
`;

StyledCheckbox.displayName = 'StyledCheckbox';

export const StyledCheckboxLabel = styled.label`
  display: flex;
  align-items: flex-start;
  white-space: normal;
`;

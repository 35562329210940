import {
  getJSON,
  createAuthHeader,
} from 'src/shared/utils/service/service.utils';

import {
  ENDPOINTS,
  endpointWithParams,
} from 'src/app/navigation/services/service.constants';

export const GetPatientStockLoaderImpl = (
  { patientId, stripModelReference },
  accessToken,
) =>
  getJSON(
    endpointWithParams(ENDPOINTS.getPatientStock, {
      patientId,
      stripModelReference,
    }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );

export const GetPatientStockTransformImpl = (results) => ({
  stock: results.patientStock,
});

export const GetPatientStockServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);

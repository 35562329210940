import styled from 'styled-components';

import { colors } from 'src/app/styles/colors';
import { spacing } from 'src/app/styles/spacing';
import { Block } from 'src/shared/design-system/block/block.component';

export const Footer = styled.div`
  padding: ${spacing.three} ${spacing.four};
  background-color: ${colors.silverLight};
  border-top: 1px solid ${colors.grayLighter};
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
`;

export const ContentWrapper = styled(Block)`
  max-height: 38rem;
  overflow-y: auto;

  & ul {
    margin-bottom: 0;
  }

  & ${Block}:not(:first-child) {
    border-top: 1px solid ${colors.grayLighter};
  }
`;

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withScrollEvent } from 'src/domains/diagnostics/with-scroll-event/with-scroll-event';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { mapDispatchers } from 'src/domains/diagnostics/utils/map-dispatchers';
import { changeLogbookType } from 'src/domains/patient-dashboards/bg/store/bg.actions';
import { withToolTip } from 'src/shared/utils/with-tool-tip';

import { Metabolic } from './metabolic.component';
import { MetabolicConnector } from './metabolic.selector';

const dispatchers = mapDispatchers({ changeLogbookType });

export const MetabolicContainer = compose(
  withToolTip,
  withRouter,
  withScrollEvent,
  connect(MetabolicConnector, dispatchers),
  withTranslation,
)(Metabolic);

import {
  UserBloodGlucoseTransformedResponse,
  UserUnitsResponse,
} from './get-user-unit.types';

export const mockGetUserUnits: UserUnitsResponse = {
  resultDescription: 'getAllUnitMeasurementsOK.',
  model: [
    {
      id: 1,
      name: 'BLOOD_GLUCOSE',
      unitMeasurementModels: [
        {
          id: 1,
          code: '1',
          text: 'mg/dL',
          unit: 'MGDL',
          family: 'BLOOD_GLUCOSE',
        },
        {
          id: 2,
          code: '2',
          text: 'mmol/L',
          unit: 'MMOLL',
          family: 'BLOOD_GLUCOSE',
        },
      ],
    },
    {
      id: 2,
      name: 'BLOOD_PRESSURE',
      unitMeasurementModels: [
        {
          id: 3,
          code: '3',
          text: 'mmHg',
          unit: 'MMHG',
          family: 'BLOOD_PRESSURE',
        },
        {
          id: 4,
          code: '4',
          text: 'kPA',
          unit: 'KPA',
          family: 'BLOOD_PRESSURE',
        },
      ],
    },
    {
      id: 3,
      name: 'TEMPERATURE',
      unitMeasurementModels: [
        {
          id: 5,
          code: '5',
          text: '°C',
          unit: 'GRADES_CENTRIGRADES',
          family: 'TEMPERATURE',
        },
        {
          id: 6,
          code: '6',
          text: '°F',
          unit: 'GRADES_FARENHEIT',
          family: 'TEMPERATURE',
        },
      ],
    },
    {
      id: 4,
      name: 'WEIGHT',
      unitMeasurementModels: [
        {
          id: 7,
          code: '7',
          text: 'kg',
          unit: 'KG',
          family: 'WEIGHT',
        },
        {
          id: 8,
          code: '8',
          text: 'lbs',
          unit: 'POUNDS',
          family: 'WEIGHT',
        },
      ],
    },
    {
      id: 5,
      name: 'LENGTH',
      unitMeasurementModels: [
        {
          id: 9,
          code: '9',
          text: 'cm',
          unit: 'CENTIMETERS',
          family: 'LENGTH',
        },
        {
          id: 10,
          code: '10',
          text: 'ft',
          unit: 'FEET',
          family: 'LENGTH',
        },
      ],
    },
    {
      id: 6,
      name: 'CALORIES',
      unitMeasurementModels: [
        {
          id: 11,
          code: '11',
          text: 'cal',
          unit: 'CALORIES',
          family: 'CALORIES',
        },
        {
          id: 12,
          code: '12',
          text: 'kCal',
          unit: 'KILOCALORIES',
          family: 'CALORIES',
        },
      ],
    },
    {
      id: 7,
      name: 'CARBOHYDRATES',
      unitMeasurementModels: [
        {
          id: 13,
          code: '13',
          text: 'g',
          unit: 'GRAMS',
          family: 'CARBOHYDRATES',
        },
        {
          id: 14,
          code: '14',
          text: 'BE',
          unit: 'BE',
          family: 'CARBOHYDRATES',
        },
        {
          id: 16,
          code: '16',
          text: 'CC',
          unit: 'CC',
          family: 'CARBOHYDRATES',
        },
        {
          id: 20,
          code: '20',
          text: 'KE',
          unit: 'KE',
          family: 'CARBOHYDRATES',
        },
      ],
    },
    {
      id: 8,
      name: 'INSULINE_DOSE',
      unitMeasurementModels: [
        {
          id: 22,
          code: '22',
          text: 'IU',
          unit: 'IU',
          family: 'INSULINE_DOSE',
        },
        {
          id: 23,
          code: '23',
          text: 'IU/BE',
          unit: 'IUBE',
          family: 'INSULINE_DOSE',
        },
        {
          id: 24,
          code: '24',
          text: 'IU/CC',
          unit: 'IUCC',
          family: 'INSULINE_DOSE',
        },
        {
          id: 25,
          code: '25',
          text: 'IU/GR',
          unit: 'IUGR',
          family: 'INSULINE_DOSE',
        },
        {
          id: 26,
          code: '26',
          text: 'IU/KE',
          unit: 'IUKE',
          family: 'INSULINE_DOSE',
        },
        {
          id: 27,
          code: '27',
          text: 'RATIO',
          unit: 'RATIO',
          family: 'INSULINE_DOSE',
        },
      ],
    },
    {
      id: 9,
      name: 'PERCENTAGE',
      unitMeasurementModels: [
        {
          id: 28,
          code: '28',
          text: '%',
          unit: 'PERCENTAGE',
          family: 'PERCENTAGE',
        },
      ],
    },
    {
      id: 10,
      name: 'MICROALBUMINURIA_MG24H',
      unitMeasurementModels: [
        {
          id: 29,
          code: '29',
          text: 'mg/24H',
          unit: 'MICROALBUMINURIA_MG24H',
          family: 'MICROALBUMINURIA_MG24H',
        },
      ],
    },
    {
      id: 11,
      name: 'MICROALBUMINURIA_MGMMOL',
      unitMeasurementModels: [
        {
          id: 30,
          code: '30',
          text: 'mg/mmol',
          unit: 'MICROALBUMINURIA_MGMMOL',
          family: 'MICROALBUMINURIA_MGMMOL',
        },
      ],
    },
    {
      id: 12,
      name: 'ADO_DOSE',
      unitMeasurementModels: [
        {
          id: 31,
          code: '31',
          text: 'U',
          unit: 'ADO',
          family: 'ADO_DOSE',
        },
      ],
    },
    {
      id: 13,
      name: 'CHOLESTEROL',
      unitMeasurementModels: [
        {
          id: 32,
          code: '32',
          text: 'mg/dL',
          unit: 'CHOLESTEROL_MGDL',
          family: 'CHOLESTEROL',
        },
        {
          id: 33,
          code: '33',
          text: 'mmol/L',
          unit: 'CHOLESTEROL_MMOLL',
          family: 'CHOLESTEROL',
        },
      ],
    },
    {
      id: 264270,
      name: 'TIME_FORMAT',
      unitMeasurementModels: [
        {
          id: 264271,
          code: '264271',
          text: '24H',
          unit: 'TIME_FORMAT_24H',
          family: 'TIME_FORMAT',
        },
        {
          id: 264272,
          code: '264272',
          text: 'AM/PM',
          unit: 'TIME_FORMAT_AMPM',
          family: 'TIME_FORMAT',
        },
      ],
    },
  ],
};

export const mockBloodGlucoseUnitsTransformed: UserBloodGlucoseTransformedResponse =
  [
    {
      name: 'BLOOD_GLUCOSE',
      unitMeasurementModels: [
        {
          text: 'mg/dL',
          unit: 'MGDL',
          family: 'BLOOD_GLUCOSE',
        },
        {
          text: 'mmol/L',
          unit: 'MMOLL',
          family: 'BLOOD_GLUCOSE',
        },
      ],
    },
  ];

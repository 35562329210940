import {
  PairingCodeIdentityStatusActionType,
  PairingCodeIdentityStatusError,
  PairingCodeIdentityStatusErrorAction,
  PairingCodeIdentityStatusStartAction,
  PairingCodeIdentityStatusSuccessAction,
} from './pairing-code-identity-status.types';
import { createPayloadAction } from 'src/app/store/app.actions';
import { PairingCodeIdentityStatusTransformedResponseType } from 'src/domains/patient/services/pairing-code/pairing-code-identity-status/pairing-code-identity-status.types';

export const pairingCodeIdentityStatusStart = (
  payload: string,
): PairingCodeIdentityStatusStartAction => {
  return createPayloadAction(
    PairingCodeIdentityStatusActionType.PAIRING_CODE_IDENTITY_STATUS_START,
    payload,
  );
};

export const pairingCodeIdentityStatusSuccess = (
  payload: PairingCodeIdentityStatusTransformedResponseType,
): PairingCodeIdentityStatusSuccessAction => {
  return createPayloadAction(
    PairingCodeIdentityStatusActionType.PAIRING_CODE_IDENTITY_STATUS_SUCCESS,
    payload,
  );
};

export const pairingCodeIdentityStatusError = (
  payload: PairingCodeIdentityStatusError,
): PairingCodeIdentityStatusErrorAction => ({
  type: PairingCodeIdentityStatusActionType.PAIRING_CODE_IDENTITY_STATUS_ERROR,
  payload,
});

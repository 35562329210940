import {
  postJSON,
  createAuthHeader,
} from 'src/shared/utils/service/service.utils';
import { createService } from 'src/app/navigation/services/service';
import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
const logout = ({ token }) => {
  return postJSON(ENDPOINTS.logout, {
    headers: {
      Authorization: createAuthHeader(token),
    },
  });
};
export const logoutService = ({ devMode = false }: { devMode: boolean }) => {
  if (devMode) {
    return createService(
      () => Promise.resolve({} as FixMe),
      (res) => res,
    );
  }
  return createService(logout, (res) => res);
};

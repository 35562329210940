import { createStructuredSelector } from 'reselect';

import {
  selectBloodGlucoseUnit,
  selectGraphLoading,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';

import {
  selectBloodGlucoseDimensions,
  selectHorizontalLabel,
  selectHorizontalTicks,
  selectMetabolicVerticalTicks,
  selectStabilityLabels,
  selectThresholds,
  selectVerticalLabel,
  selectVerticalTicksDashboard,
} from './metabolic-axes.selector';
import {
  selectFilteredMetabolicGraphData,
  selectMeanBGSD,
  selectSD1,
} from './metabolic-data.selector';
import { selectGraphStatistics } from './metabolic-statistics.selector';

export const selectToggles = (state) => ({
  gridLines: state.forms.ui.patientDashboard.showGridLines.value,
});

export const MetabolicConnector = createStructuredSelector({
  bloodGlucoseUnit: selectBloodGlucoseUnit,
  verticalLabel: selectVerticalLabel,
  verticalTicks: selectMetabolicVerticalTicks,
  verticalTicksDashboard: selectVerticalTicksDashboard,
  horizontalLabel: selectHorizontalLabel,
  horizontalTicks: selectHorizontalTicks,
  stabilityLabels: selectStabilityLabels,
  thresholds: selectThresholds,
  graphDetails: selectGraphStatistics,
  graphData: selectFilteredMetabolicGraphData,
  meanBGSD: selectMeanBGSD,
  bloodGlucoseDimensions: selectBloodGlucoseDimensions,
  sd1: selectSD1,
  toggles: selectToggles,
  isLoading: selectGraphLoading,
});

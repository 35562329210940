// tslint:disable:cognitive-complexity
// tslint:disable:no-big-function
// @ts-nocheck
import { isNil } from 'ramda';
import * as React from 'react';
import { compose } from 'recompose';
import { Subject } from 'rxjs/Subject';
import { withTheme } from 'styled-components';
import { AutoSizer } from 'react-virtualized';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { colors } from 'src/app/styles/colors';
import { Graph } from 'src/domains/diagnostics/components/graph-lib/graph.component';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import { withGraphLoader } from 'src/domains/diagnostics/utils/with-graph-loader';
import {
  DetailBasalEventTooltip,
  DetailBasalTooltip,
  DetailCarbohydratesToolTip,
  DetailGlucoseToolTip,
  DetailInsulinToolTip,
  DetailPumpEventToolTip,
} from 'src/domains/diagnostics/components/tool-tip/detail-tool-tip/detail-tool-tip.component';
import { BasalAxis } from 'src/domains/diagnostics/components/graph/graph-axes/basal-axis.component';
import { BasalGraph } from 'src/domains/diagnostics/components/graph/basal-graph.component';
import { BloodGlucoseAxis } from 'src/domains/diagnostics/components/graph/graph-axes/blood-glucose-axis.component';
import { CarbohydratesAxis } from 'src/domains/diagnostics/components/graph/graph-axes/carbohydrates-axis.component';
import { CarbohydratesGraph } from 'src/domains/diagnostics/components/graph/carbohydrates-graph.component';
import { GRAPH_LEGEND_TYPES } from 'src/domains/diagnostics/components/graph-legend/graph-legend.constant';
import { GraphPlot } from 'src/domains/diagnostics/components/graph/graph-plot/graph-plot.component';
import { GraphStartTimeDropdown } from 'src/domains/diagnostics/components/graph-start-time-dropdown/graph-start-time-dropdown.component';
import { InsulinAxis } from 'src/domains/diagnostics/components/graph/graph-axes/insulin-axis.component';
import { InsulinGraph } from 'src/domains/diagnostics/components/graph/insulin-graph.component';
import { ResizeWrapper } from 'src/domains/diagnostics/resize-wrapper/resize-wrapper.component';
import { GraphControls } from 'src/domains/diagnostics/components/graph-controls/graph-controls.component';
import { AppleEatenIcon, AppleIcon } from 'src/shared/design-system/icons';
import { filterVerticalTicksForViewAndVisibilityTolerance } from 'src/domains/diagnostics/utils/graphs.util';
import {
  COLLAPSED_STD_GRAPH_HEIGHT,
  DATA_TYPE,
  LOGBOOK_TYPE_DETAILS,
  LOGBOOK_TYPE_DIARY,
  MIN_EXPANDED_STD_GRAPH_HEIGHT,
  PLOT_HEIGHT,
  PLOT_WIDTH,
  SUB_PLOT_HEIGHT,
  X_AXIS_HEIGHT,
  Y_AXIS_WIDTH,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { withToolTip } from 'src/shared/utils/with-tool-tip';
import { ToolTip } from 'src/domains/diagnostics/components/tool-tip/tool-tip.component';

import { HoursAxis } from './components/axes/hours-axis.component';
import { MealTimeAxis } from './components/axes/meal-time-axis.component';
import { DetailGraph } from './components/detail-graph/detail-graph.component';
import { StandardDayPlotWrapperDiv } from './standard-day-detail.style';

import { filterMainPoints } from 'src/domains/diagnostics/scenes/graphs/template/utils/graphs-template.util';

import {
  createEventHandlerStream,
  getToolTipValueColor,
  getYAxisTickVisibilityTolerance,
  navigateToLogbook,
} from '../../graph-shared/graph.util';
import { is12HourFormat } from 'src/domains/diagnostics/utils/time-format';
import { graphHasMeasurement } from 'src/domains/diagnostics/scenes/graphs/graph-shared/measurements';
import { testId } from '@roche/roche-common';
import { getCarbohydratesUnitsLabel } from 'src/domains/diagnostics/scenes/graphs/graph-statistics.util';

const StandardDayPlotWrapperDivWithLoader = withGraphLoader(
  StandardDayPlotWrapperDiv,
);

const clickStream$ = new Subject();
const stop$ = new Subject();

class StandardDayDetailComponent extends React.Component<any, any> {
  public state = {
    selectedDate: null,
  };

  public componentDidMount() {
    createEventHandlerStream(clickStream$, stop$)(
      ([{ date: selectedDate }]) =>
        this.setState((state) => ({ ...state, selectedDate })),
      ([{ date, type }]) => {
        const logbookType =
          type === DATA_TYPE.GLUCOSE
            ? LOGBOOK_TYPE_DETAILS
            : LOGBOOK_TYPE_DIARY;
        navigateToLogbook(
          this.props.history,
          date,
          this.props.changeLogbookType,
          logbookType,
        );
      },
    );
  }

  public componentWillUnmount() {
    stop$.next();
    stop$.complete();
  }

  public render() {
    const {
      bloodGlucoseUnit,
      collapsed,
      collapsedGP,
      measurements,
      points,
      meanPoints,
      pumpEventPoints,
      basalLines,
      carbohydratesLines,
      tbrLines,
      tbrPoints,
      backgroundPanels,
      lines,
      targetRange,
      threshold,
      verticalTicks,
      timeHorizontalTicks,
      timeIntervalHorizontalTicks,
      insulinTicks,
      insulinPoints,
      carbohydratesTicks,
      basalTicks,
      showGridLines,
      graphDetails,
      showDetails = true,
      showChangeGraphToggle,
      yDirection = -1,
      toolTip,
      showToolTip,
      hideToolTip,
      flexibleHeight,
      isLoading,
      graphYMax,
      graphToggles,
      t,
      timeFormat,
      graph,
      carbUnit,
    }: any = this.props;
    const carbLabel = getCarbohydratesUnitsLabel[carbUnit];
    const translatedPumpEventPoints = Array.isArray(pumpEventPoints)
      ? pumpEventPoints.map((item) => {
          const tDuration = item.data.duration ? item.data.duration : [];
          return {
            ...item,
            data: {
              ...item.data,
              duration:
                tDuration.length > 0
                  ? `${t(tDuration[0])} ${tDuration[1]} ${t(tDuration[2])}`
                  : '',
            },
          };
        })
      : [];
    const { selectedDate } = this.state;
    const timeIntervalTickLabelsTranslated = timeIntervalHorizontalTicks.map(
      (item) => {
        if (item.label) {
          return {
            ...item,
            label: t(item.label),
          };
        } else {
          return item;
        }
      },
    );
    const yAxisTickVisibilityTolerance = getYAxisTickVisibilityTolerance({
      bloodGlucoseUnit,
    });

    return (
      <React.Fragment>
        <RenderIf
          validate={
            graphHasMeasurement(
              measurements,
              insulinPoints,
              basalLines,
              carbohydratesLines,
            ) && !isLoading
          }
        >
          <GraphControls
            showChangeGraphToggle={showChangeGraphToggle}
            graphStatistics={graphDetails}
            graphType={GRAPH_LEGEND_TYPES.STANDARD_DAY_DETAIL}
            collapsed={collapsed}
            collapsedGP={collapsedGP}
            graph={graph}
          />
        </RenderIf>
        <ResizeWrapper
          minHeight={
            collapsed
              ? COLLAPSED_STD_GRAPH_HEIGHT
              : MIN_EXPANDED_STD_GRAPH_HEIGHT
          }
          render={(height) => {
            const filteredVerticalTicks =
              filterVerticalTicksForViewAndVisibilityTolerance(
                verticalTicks,
                flexibleHeight,
                threshold,
                targetRange,
                yAxisTickVisibilityTolerance,
                graphYMax,
              );

            return (
              <StandardDayPlotWrapperDivWithLoader
                {...testId('graph-template', 'standard-day-detail')}
                collapsed={collapsed}
                collapsedGP={collapsedGP}
                hasError={
                  !graphHasMeasurement(
                    measurements,
                    insulinPoints,
                    basalLines,
                    carbohydratesLines,
                  ) && !isLoading
                }
                isLoading={isLoading}
                onClick={this.onGraphClick}
              >
                <AutoSizer>
                  {({ width }) => (
                    <>
                      <RenderIf validate={width > 0 && height > 0}>
                        <Graph
                          viewportRight={width}
                          viewportBottom={height}
                          height={height}
                          anchor="xMidYMid"
                        >
                          <GraphPlot
                            width={width}
                            height={height}
                            xAxisHeight={X_AXIS_HEIGHT}
                            yAxisWidth={Y_AXIS_WIDTH}
                            plotHeight={PLOT_HEIGHT}
                            plotWidth={PLOT_WIDTH}
                            subPlotHeight={SUB_PLOT_HEIGHT}
                            mainGraph={(dimensions) => (
                              <DetailGraph
                                {...dimensions}
                                points={points}
                                meanPoints={filterMainPoints(meanPoints)}
                                pumpEventPoints={
                                  graphToggles.showBasalRate
                                    ? translatedPumpEventPoints
                                    : []
                                }
                                carbohydratesLines={carbohydratesLines}
                                lines={lines}
                                backgroundPanels={backgroundPanels}
                                yDirection={yDirection}
                                targetRange={targetRange}
                                threshold={threshold.value}
                                horizontalTicks={timeHorizontalTicks}
                                showGridLines={showGridLines}
                                onPointMouseOver={showToolTip}
                                onPointMouseOut={hideToolTip}
                                graphYMax={graphYMax}
                                selectedDate={selectedDate}
                                onLineClick={this.onLineClick}
                                tbrLines={
                                  graphToggles.showBasalRate ? tbrLines : []
                                }
                                tbrPoints={
                                  graphToggles.showBasalRate ? tbrPoints : []
                                }
                                collapsed={collapsed}
                                bloodGlucoseUnit={bloodGlucoseUnit}
                              />
                            )}
                            xAxisBottom={(dimensions) => (
                              <HoursAxis
                                {...dimensions}
                                {...{
                                  timeHorizontalTicks,
                                  collapsed,
                                  yDirection,
                                }}
                              />
                            )}
                            xAxisTop={(dimensions) => (
                              <MealTimeAxis
                                {...dimensions}
                                {...{
                                  ticks: timeIntervalTickLabelsTranslated,
                                  collapsed,
                                  yDirection,
                                }}
                              />
                            )}
                            yAxisLeft={(dimensions) => (
                              <BloodGlucoseAxis
                                {...dimensions}
                                ticks={filteredVerticalTicks}
                                unit={bloodGlucoseUnit}
                                collapsed={collapsed}
                                collapsedGP={collapsedGP}
                              />
                            )}
                            yAxisRightTop={(dimensions) => (
                              <RenderIf validate={graphToggles.showInsulin}>
                                <InsulinAxis
                                  {...dimensions}
                                  ticks={insulinTicks}
                                />
                              </RenderIf>
                            )}
                            yAxisRightBottom={(dimensions) => (
                              <React.Fragment>
                                <RenderIf validate={graphToggles.showBasalRate}>
                                  <BasalAxis
                                    {...dimensions}
                                    ticks={basalTicks}
                                  />
                                </RenderIf>
                                <RenderIf
                                  validate={
                                    !graphToggles.showBasalRate &&
                                    graphToggles.showCarbohydrates
                                  }
                                >
                                  <CarbohydratesAxis
                                    {...dimensions}
                                    ticks={carbohydratesTicks}
                                  />
                                </RenderIf>
                              </React.Fragment>
                            )}
                            upperSubGraph={(dimensions) => (
                              <RenderIf validate={graphToggles.showInsulin}>
                                <InsulinGraph
                                  {...dimensions}
                                  insulinPoints={insulinPoints}
                                  selectedDate={selectedDate}
                                  onLineClick={this.onLineClick}
                                  onPointMouseOver={showToolTip}
                                  onPointMouseOut={hideToolTip}
                                  collapsed={collapsed}
                                />
                              </RenderIf>
                            )}
                            lowerSubGraph={(dimensions) => {
                              return (
                                <React.Fragment>
                                  <RenderIf
                                    validate={graphToggles.showCarbohydrates}
                                  >
                                    <CarbohydratesGraph
                                      {...dimensions}
                                      lines={carbohydratesLines}
                                      selectedDate={selectedDate}
                                      onPointMouseOver={showToolTip}
                                      onPointMouseOut={hideToolTip}
                                      onLineClick={this.onLineClick}
                                      collapsed={collapsed}
                                    />
                                  </RenderIf>
                                  <RenderIf
                                    validate={graphToggles.showBasalRate}
                                  >
                                    <BasalGraph
                                      {...dimensions}
                                      basalLines={basalLines}
                                      selectedDate={selectedDate}
                                      tbrLines={tbrLines}
                                      tbrPoints={tbrPoints}
                                      onMouseMove={showToolTip}
                                      onMouseOut={hideToolTip}
                                      onLineClick={this.onLineClick}
                                      collapsed={collapsed}
                                    />
                                  </RenderIf>
                                </React.Fragment>
                              );
                            }}
                          />
                        </Graph>
                      </RenderIf>
                      <RenderIf validate={showDetails}>
                        <GraphStartTimeDropdown
                          is12hFormat={is12HourFormat(timeFormat)}
                        />
                      </RenderIf>
                    </>
                  )}
                </AutoSizer>
              </StandardDayPlotWrapperDivWithLoader>
            );
          }}
          resizeFunction={(clientHeight) => clientHeight}
        />
        <RenderIf validate={toolTip.x && toolTip.y}>
          {this.renderToolTip(
            toolTip,
            threshold,
            targetRange,
            bloodGlucoseUnit,
            t,
            timeFormat,
            carbLabel,
          )}
        </RenderIf>
      </React.Fragment>
    );
  }

  public onLineClick = (data) => (e) => {
    e.stopPropagation();
    clickStream$.next(data);
  };

  public onGraphClick = () => {
    if (!this.props.collapsed) {
      this.setState({ selectedDate: null });
    }
  };

  public renderToolTip = (
    { x, y, data },
    { data: thresholdData },
    { data: targetRangeData },
    bloodGlucoseUnit,
    t,
    timeFormat,
    carbLabel,
  ) => {
    const {
      date,
      endDate,
      type,
      value,
      beforeMeal,
      afterMeal,
      duration,
      bolusValue,
      bolusValueOriginal,
      bolusRemark,
      basalCbrf,
      insulin1,
      insulin2,
      insulin3,
      tbr,
      tbrLabel,
      manuallyEntered,
    } = data;

    const icon = beforeMeal ? (
      <AppleIcon height="17" />
    ) : afterMeal ? (
      <AppleEatenIcon height="17" />
    ) : null;

    const insulinValues = [
      {
        label: t('graphs.insulin.bolusIns1'),
        value: bolusValueOriginal || bolusValue,
      },
      { label: t('graphs.insulin.bolusIns1'), value: insulin1 },
      { label: t('graphs.insulin.bolusIns2'), value: insulin2 },
      { label: t('graphs.insulin.bolusIns3'), value: insulin3 },
    ].filter((item) => !isNil(item.value));

    return (
      <ToolTip x={x} y={y}>
        <RenderIf validate={type === DATA_TYPE.CARBOHYDRATES}>
          <DetailCarbohydratesToolTip
            topBarColor={this.props.theme.colors.blueMarine}
            timeFormat={timeFormat}
            {...{ date, value, carbLabel }}
          />
        </RenderIf>
        <RenderIf validate={type === DATA_TYPE.TBR_EVENT}>
          <DetailBasalEventTooltip
            topBarColor={colors.blueMarine}
            type={tbrLabel}
            date={date}
            timeFormat={timeFormat}
          />
        </RenderIf>
        <RenderIf validate={type === DATA_TYPE.PUMP_EVENT}>
          <DetailPumpEventToolTip
            topBarColor={this.props.theme.colors.blueMarine}
            timeFormat={timeFormat}
            {...{ date, value, duration }}
          />
        </RenderIf>
        <RenderIf
          validate={
            type === DATA_TYPE.GLUCOSE || type === DATA_TYPE.MEAN_GLUCOSE
          }
        >
          <DetailGlucoseToolTip
            icon={icon}
            topBarColor={getToolTipValueColor(
              value,
              thresholdData.value,
              targetRangeData,
            )}
            timeFormat={timeFormat}
            {...{ date, type, value, bloodGlucoseUnit, manuallyEntered }}
          />
        </RenderIf>
        <RenderIf validate={type === DATA_TYPE.INSULIN}>
          <DetailInsulinToolTip
            topBarColor={colors.blueMarine}
            values={insulinValues}
            date={date}
            remark={bolusRemark}
            timeFormat={timeFormat}
          />
        </RenderIf>
        <RenderIf validate={basalCbrf != null && type === DATA_TYPE.BASAL}>
          <DetailBasalTooltip
            topBarColor={colors.blueMarine}
            value={basalCbrf}
            timeFormat={timeFormat}
            {...{ date, endDate, tbr }}
          />
        </RenderIf>
      </ToolTip>
    );
  };
}

export const StandardDayDetail = compose(
  withTheme,
  withToolTip,
  withTranslation,
)(StandardDayDetailComponent);

import * as React from 'react';

import { SAVE_ALERTS } from 'src/domains/strip-management/store/alerts/alerts.constants';
import { ADD_ORG_STOCK } from 'src/domains/strip-management/scenes/org-stock/store/org-stock/org-stock.constants';
import {
  SUBMIT_LOST_STRIPS,
  SUBMIT_MANUAL_DELIVERY,
} from 'src/domains/strip-management/store/patient-stock/patient-stock.constants';
import { SAVE_PRESCRIPTION } from 'src/domains/strip-management/store/prescription/prescription.constants';
import {
  DELETE_CLINIC_GUIDE,
  SAVE_CLINIC_GUIDE,
} from 'src/domains/strip-management/store/prescription/clinic-guide/clinic-guide.constants';
import {
  GET_DELIVERY_STATUS,
  SAVE_DELIVERY,
  SAVE_DELIVERY_STATUS_COMMENT,
  SET_DELIVERY_STATUS,
} from 'src/domains/strip-management/store/strip-delivery/strip-delivery.constants';
import { LocalizedText } from 'src/shared/design-system/localized-text/localized-text.component';

export const REQUESTS_WITH_GLOBAL_LOADER = [
  SAVE_ALERTS,
  SAVE_PRESCRIPTION,
  SAVE_DELIVERY,
  GET_DELIVERY_STATUS,
  SET_DELIVERY_STATUS,
  SUBMIT_LOST_STRIPS,
  SUBMIT_MANUAL_DELIVERY,
  ADD_ORG_STOCK,
  SAVE_DELIVERY_STATUS_COMMENT,
  DELETE_CLINIC_GUIDE,
  SAVE_CLINIC_GUIDE,
];

export const REQUEST_LOADING_MESSAGES = {
  default: <LocalizedText textKey="requestsLoading.default" />,
  [SAVE_ALERTS]: <LocalizedText textKey="requestsLoading.saveAlerts" />,
  [SAVE_PRESCRIPTION]: (
    <LocalizedText textKey="requestsLoading.savePrescription" />
  ),
  [SAVE_DELIVERY]: <LocalizedText textKey="requestsLoading.saveDelivery" />,
  [GET_DELIVERY_STATUS]: (
    <LocalizedText textKey="requestsLoading.getDeliveryStatus" />
  ),
  [SET_DELIVERY_STATUS]: (
    <LocalizedText textKey="requestsLoading.setDeliveryStatus" />
  ),
  [SUBMIT_LOST_STRIPS]: (
    <LocalizedText textKey="requestsLoading.submitLostStrips" />
  ),
  [SUBMIT_MANUAL_DELIVERY]: (
    <LocalizedText textKey="requestsLoading.submitManualDelivery" />
  ),
  [ADD_ORG_STOCK]: <LocalizedText textKey="requestsLoading.addOrgStock" />,
  [SAVE_DELIVERY_STATUS_COMMENT]: (
    <LocalizedText textKey="requestsLoading.default" />
  ),
};

export const REQUEST_SUCCESS_MESSAGES = {
  default: <LocalizedText textKey="requestsLoading.complete" />,
  [DELETE_CLINIC_GUIDE]: (
    <LocalizedText textKey="prescription.customClinicGuides.modal.successfulMessage" />
  ),
};

export const REQUESTS_DESTROY_MODAL_ON_SUCCESS = [SAVE_DELIVERY_STATUS_COMMENT];

export const REQUESTS_REQUIRE_CONFIRMATION_ON_SUCCESS = [DELETE_CLINIC_GUIDE];

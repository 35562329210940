import { DiabetesType, DownloadNoticePeriod, Gender } from '../patient.types';

export const mockPatient = {
  clinicalRecordNumber: '1234',
  diabetesType: DiabetesType.DIABETES_TYPE1,
  diagnosticDate: '2018-04-17',
  downloadNoticePeriod: DownloadNoticePeriod.NO_WARNING,
  expectedDeliverDate: '2018-07-17',
  healthCareSystemId: '5678',
  nursingHome: true,
  pregnant: true,
  professionalList: [7116],
  timeZone: 'Europe/Madrid',
  user: {
    address: {
      address: 'street 1',
      city: 'Malaga',
      country: {
        id: 1,
      },
      postalCode: '2900',
      province: 'Malaga',
    },
    birthday: '1986-07-17',
    departmentProfileId: 7108,
    email: 'testCreateAPI1@mail.com',
    gender: Gender.FEMALE,
    hcpIsAccessible: true,
    languageId: 1,
    name: 'Juan',
    phone: '666666666',
    surname: 'Magan',
    surname2: 'Muñoz',
  },
};

export const mockCreatePatientResponse = {
  resultDescription: 'createPatientOK',
  model:
    'https://univ-uploader-dev.rochedc.accentureanalytics.com/eConecta/rest/api/professional/57789/patients/57805',
};

import * as React from 'react';

import { equals, isNil } from 'ramda';
import { DisclaimerBrowserModal } from 'src/widgets/modal/components/disclaimer-browser-modal/disclaimer-browser.modal';

import { AdditionalInfoContent } from 'src/domains/diagnostics/components/additional-info/additional-info.content';
import { MODAL_TYPES } from 'src/shared/design-system/modal/store/modal/modal.constants';
import { RenderIf } from 'src/shared/utils/render-if';
import { Portal } from 'src/shared/design-system/portal/portal.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import { selectModalProps } from 'src/shared/design-system/modal/store/modal/modal.selectors';
import { destroyModal } from 'src/shared/design-system/modal/store/modal/modal.actions';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';

import { ModalContentElement } from 'src/widgets/modal/components/strip-modal/strip-modal.content';

import { ModalCard, ModalOverlay, ModalWrapper } from './modal.style';

import { ExpiringModalComponent } from 'src/app/session/components/expiring-modal/expiring-modal.component';

import { JelloDialog } from 'src/shared/design-system/jello-dialog/jello-dialog.component';
import { LoadingModal } from './components/loading-modal/loading-modal.component';
import { DisclaimerModal } from './components/disclaimer-modal/disclaimer-modal.component';
import { DTCModal } from './components/dtc-modal/dtc-modal.container';
import { loginSystemErrorModal } from './components/login-system-error/login-system-error.container';
import { SupportModal } from './components/support-modal/support-modal.component';
import { ChangePasswordConfirmationModal } from 'src/widgets/modal/components/password-change-confirmation/change-password-confirmation.container';
import { ManufacturerInfoModal } from 'src/widgets/modal/components/manufacturer-info-modal/manufacturer-info-modal.component';

type ModalProps = {
  type: string;
  isOpen: boolean;
  data: any;
  destroyModal: () => void;
};
type ModalState = any;

const jelloDialogs = [
  MODAL_TYPES.ADDITIONAL_INFO,
  MODAL_TYPES.TTL_EXPIRING,
  MODAL_TYPES.STRIPS_MODAL,
  MODAL_TYPES.MANUFACTURER_INFO,
];

export class ModalClass extends React.Component<ModalProps, ModalState> {
  public closeAction = 'jello-dialog-close-action';

  public componentDidUpdate(prevProps) {
    if ({ ...prevProps.data }.id !== { ...this.props.data }.id) {
      this.setListeners();
    }
  }

  public componentWillUnmount() {
    this.removeListeners();
  }

  public render() {
    const { type, isOpen, data = {} } = this.props;
    const {
      id,
      headerTitle,
      showCloseButton,
      closeOnAction,
      primaryButtonText,
      secondaryButtonText,
      tertiaryButtonText,
      origin,
      primaryButtonAction,
      secondaryButtonAction,
      tertiaryButtonAction,
      className,
    } = data;

    return (
      <Portal rootId="modal-root">
        <RenderIf validate={isOpen && jelloDialogs.indexOf(type) < 0}>
          <ModalOverlay origin={origin}>
            <ModalWrapper>
              <ModalCard>
                {this.renderModalComponent(type, this.props)}
              </ModalCard>
            </ModalWrapper>
          </ModalOverlay>
        </RenderIf>
        <RenderIf validate={isOpen && jelloDialogs.indexOf(type) > -1}>
          <JelloDialog
            id={id}
            visible={isOpen}
            headerTitle={headerTitle}
            showCloseButton={showCloseButton}
            primaryButtonText={primaryButtonText}
            primaryButtonAction={primaryButtonAction}
            secondaryButtonText={secondaryButtonText}
            secondaryButtonAction={secondaryButtonAction}
            tertiaryButtonText={tertiaryButtonText}
            tertiaryButtonAction={tertiaryButtonAction}
            closeOnAction={closeOnAction}
            className={className}
          >
            {this.renderModalComponent(type, this.props) as JSX.Element}
          </JelloDialog>
        </RenderIf>
      </Portal>
    );
  }

  private getJelloModal = () =>
    document.getElementById({ ...this.props.data }.id);

  private setListeners = () => {
    const jelloModal = this.getJelloModal();
    if (jelloModal) {
      jelloModal.addEventListener(this.closeAction, () => this.hideModal());
    }
    window.addEventListener('popstate', () => this.hideModal());
  };

  private removeListeners = () => {
    const jelloModal = this.getJelloModal();
    if (jelloModal) {
      jelloModal.removeEventListener(this.closeAction, () => this.hideModal());
    }
    window.removeEventListener('popstate', () => this.hideModal());
  };

  private hideModal = () => {
    const { type, isOpen, destroyModal } = this.props;
    if (
      (isOpen && type === MODAL_TYPES.ADDITIONAL_INFO) ||
      (isOpen && type === MODAL_TYPES.STRIPS_MODAL)
    ) {
      destroyModal();
    }
  };

  private renderModalComponent = (type, props) => {
    const modalComponentMap = {
      [MODAL_TYPES.LOADING]: LoadingModal,
      [MODAL_TYPES.DISCLAIMER]: DisclaimerModal,
      [MODAL_TYPES.SUPPORT]: SupportModal,
      [MODAL_TYPES.DTC]: DTCModal,
      [MODAL_TYPES.MANUFACTURER_INFO]: ManufacturerInfoModal,
      [MODAL_TYPES.LOGIN_SYSTEM_ERROR]: loginSystemErrorModal,
      [MODAL_TYPES.CHANGE_PASSWORD_CONFIRMATION]:
        ChangePasswordConfirmationModal,
      [MODAL_TYPES.DISCLAIMER_BROWSER]: DisclaimerBrowserModal,
      [MODAL_TYPES.ADDITIONAL_INFO]: AdditionalInfoContent,
      [MODAL_TYPES.TTL_EXPIRING]: ExpiringModalComponent,
      [MODAL_TYPES.STRIPS_MODAL]: ModalContentElement,
    };

    if (equals(type, MODAL_TYPES.CUSTOM)) {
      const CustomComponent = props.data.customComponent;
      return !isNil(CustomComponent) ? <CustomComponent {...props} /> : null;
    }

    const ModalComponent = modalComponentMap[type];
    return !isNil(ModalComponent) ? <ModalComponent {...props} /> : null;
  };
}

export const Modal = compose(
  connect(selectModalProps, mapDispatchers({ destroyModal })),
)(ModalClass);

import * as React from 'react';

import { colors } from '../../../../app/styles/colors';

import { SvgIcon } from '../../icon/icon.component';

type DuringDaySunIconProps = {
  width?: number;
  height?: number;
  fillColor?: string;
};

export const DuringDaySunIcon = ({
  width = 16,
  height = 16,
  fillColor = colors.blueHypoglycemiaIcons,
}: DuringDaySunIconProps) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      originalWidth={width}
      originalHeight={height}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 1H8.5V3.5H7.5V1ZM10.804 4.454L12.572 2.686L13.279 3.393L11.511 5.161L10.804 4.454ZM12.5 7.5H15V8.5H12.5V7.5ZM10.838 11.51L11.545 10.804L13.313 12.571L12.606 13.278L10.838 11.51ZM7.5 12.5H8.5V15H7.5V12.5ZM2.722 12.61L4.49 10.84L5.197 11.547L3.429 13.315L2.722 12.61ZM1 7.5H3.5V8.5H1V7.5ZM2.688 3.43L3.395 2.724L5.163 4.49L4.456 5.197L2.688 3.43ZM8 6C9.1 6 10 6.9 10 8C10 9.1 9.1 10 8 10C6.9 10 6 9.1 6 8C6 6.9 6.9 6 8 6ZM8 5C6.3 5 5 6.3 5 8C5 9.7 6.3 11 8 11C9.7 11 11 9.7 11 8C11 6.3 9.7 5 8 5Z"
        fill={fillColor}
      />
    </SvgIcon>
  );
};

import {
  createAuthHeader,
  putJSON,
} from '../../../../../shared/utils/service/service.utils';
import { OpenId } from '../../../../../domains/patient/services/patient/list-devices/list-devices.types';
import { PatientSessionEndLoaderImplType } from './patient-session-end.types';
import { isEmpty } from 'ramda';

import { ENDPOINTS } from '../../service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const PatientSessionEndLoaderImpl: PatientSessionEndLoaderImplType = (
  { visitId, patientId, data },
  openId: OpenId,
) => {
  const headers = {
    Authorization: createAuthHeader(openId.accessToken),
    ApiKey: openId.gigyaToken,
    Accept: 'application/json',
  };
  const noteOptions = {
    headers: { ...headers },
    body: JSON.stringify(data ? data : {}),
  };
  const emptyOptions = {
    headers: { ...headers },
  };
  const options = !data || isEmpty(data) ? emptyOptions : noteOptions;

  return putJSON(
    endpointWithParams(ENDPOINTS.patientSessionEnd, { patientId, visitId }),
    options,
  );
};

export const PatientSessionEndTransformImpl: any = (data) => data;

export const PatientSessionEndServiceImpl: any =
  (load, transform) => (params, accessToken) =>
    load(params, accessToken).then(transform);

import * as React from 'react';
import { Page } from '../../../store/announcements.types';
import styles from './styles.module.css';
import { testId } from '@roche/roche-common';
import { ID, ITEM, MENU } from '../../whats-new.constants';
import {
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
} from 'src/app/app.jello.constants';
import { t } from 'i18next';

type WhatsNewMenuProps = {
  currentActive?: Page;
  setActiveHandler: (props: Page) => void;
  menuItems?: Page[];
};

export const WhatsNewMenu: React.FunctionComponent<WhatsNewMenuProps> = ({
  currentActive,
  setActiveHandler,
  menuItems = [],
}) => {
  return (
    <div className={styles.menuContainer} {...testId(ID, MENU)}>
      {menuItems.map((menu) => (
        <jello-text
          key={menu.pageNo}
          size={JELLO_TEXT_SIZES.M}
          weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
          class={
            menu.pageNo === currentActive?.pageNo
              ? `${styles.section} ${styles.sectionActive}`
              : styles.section
          }
          {...testId(`${ID}-${MENU}-${ITEM}`, '')}
          onClick={() => setActiveHandler(menu)}
        >
          {t(menu.pageHeader)}
        </jello-text>
      ))}
    </div>
  );
};

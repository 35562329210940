import { Action, PayloadAction } from 'src/app/store/app.types';
import { PatientFormState } from 'src/domains/patient/store/patient/patient.types';
import {
  CreatePatientErrorResponse,
  CreatePatientParams as CreatePatientServiceParams,
  CreatePatientTransformedResponse,
} from 'src/domains/patient/services/patient/create-patient/create-patient.types';

export enum CreatePatientActionType {
  CREATE_PATIENT_START = 'CREATE_PATIENT_START',
  CREATE_PATIENT_SUCCESS = 'CREATE_PATIENT_SUCCESS',
  CREATE_PATIENT_ERROR = 'CREATE_PATIENT_ERROR',
  GET_CREATED_PATIENT_START = 'GET_CREATED_PATIENT_START',
  GET_CREATED_PATIENT_SUCCESS = 'GET_CREATED_PATIENT_SUCCESS',
  GET_CREATED_PATIENT_ERROR = 'GET_CREATED_PATIENT_ERROR',
}

export type CreatedPatient = {
  id: number;
  firstName: string;
  surName: string;
  surName2: string;
};

export type CreatePatientParams = CreatePatientServiceParams;
export type CreatePatientSuccessPayload = CreatePatientTransformedResponse;
export type CreatePatientErrorPayload = CreatePatientErrorResponse;
export type GetCreatedPatientStartPayload = CreatePatientTransformedResponse;
export type GetCreatedPatientSuccessPayload = CreatedPatient;

export type CreatePatientStartAction = PayloadAction<
  CreatePatientActionType.CREATE_PATIENT_START,
  CreatePatientParams
>;
export type CreatePatientSuccessAction = PayloadAction<
  CreatePatientActionType.CREATE_PATIENT_SUCCESS,
  CreatePatientSuccessPayload
>;
export type CreatePatientErrorAction = PayloadAction<
  CreatePatientActionType.CREATE_PATIENT_ERROR,
  CreatePatientErrorPayload
>;

export type GetCreatedPatientStartAction = PayloadAction<
  CreatePatientActionType.GET_CREATED_PATIENT_START,
  GetCreatedPatientStartPayload
>;
export type GetCreatedPatientSuccessAction = PayloadAction<
  CreatePatientActionType.GET_CREATED_PATIENT_SUCCESS,
  GetCreatedPatientSuccessPayload
>;
export type GetCreatedPatientErrorAction =
  Action<CreatePatientActionType.GET_CREATED_PATIENT_ERROR>;

export type CreatePatientActions =
  | CreatePatientStartAction
  | CreatePatientSuccessAction
  | CreatePatientErrorAction
  | GetCreatedPatientStartAction
  | GetCreatedPatientSuccessAction
  | GetCreatedPatientErrorAction;

export type CreatePatientState = PatientFormState & {
  creationHasError: boolean;
  creationWasSuccessful: boolean;
  isCreatingPatient: boolean;
  newPatient?: CreatedPatient;
  grantedPermissions: string[];
};

import {
  createAuthHeader,
  postJSON,
  stringifyBody,
} from 'src/shared/utils/service/service.utils';
import { equals, reject } from 'ramda';
import {
  SaveTargetRangesLoaderImplType,
  SaveTargetRangesPayload,
  SaveTargetRangesServiceImplType,
} from './save-target-ranges.types';
import { transformTargetRanges } from './save-target-ranges.util';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';

export const SaveTargetRangesLoaderImpl: SaveTargetRangesLoaderImplType = (
  openId: {
    accessToken: string;
    gigyaToken: string;
  },
  range: SaveTargetRangesPayload,
  patientId: string,
) => {
  const headers = {
    Authorization: createAuthHeader(openId.accessToken),
    ApiKey: openId.gigyaToken,
    patientId,
  };
  return postJSON(ENDPOINTS.targetRanges, {
    headers: reject(equals(undefined))(headers),
    body: stringifyBody(transformTargetRanges(range)),
  });
};

export const SaveTargetRangesTransformImpl = (results) => results;

export const SaveTargetRangesServiceImpl: SaveTargetRangesServiceImplType =
  (load: SaveTargetRangesLoaderImplType, transform) =>
  (openId, range, patientId) =>
    load(openId, range, patientId).then(transform);

// tslint:disable
import { selectAccessToken } from '../../../../../app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from '../../../../../app/session/core/config/config.selectors';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { fetchLanguagesStart } from '../../../../patient/store/languages/languages.actions';
import { EditProfileServiceImplType } from '../../../services/profile/edit-profile.types';
import { EditEMRProfileServiceImplType } from '../../../services/profile-emr/edit-emr-profile.types';
import {
  EditEMRProfileStartAction,
  EditProfileStartAction,
} from './edit-profile.types';
import { EditProfileErrorModal } from '../components/edit-profile-error.modal';
import { EditProfileSuccessModal } from '../components/edit-profile-success.modal';

import { getCurrentUser } from '../../../../../app/store/user/user.actions';
import { fetchPermissions } from '../../../../permissions/store/permissions.actions';
import { createModal } from '../../../../../shared/design-system/modal/store/modal/modal.actions';
import { MODAL_TYPES } from '../../../../../shared/design-system/modal/store/modal/modal.constants';

import {
  editEMRProfileError,
  editEMRProfileSuccess,
  editProfileError,
  editProfileSuccess,
} from './edit-profile.actions';
import { EditProfileActionType } from './edit-profile.types';

export const editProfileEpic: (
  editProfileService: EditProfileServiceImplType,
) => Epic<FixMe, any> = (editProfileService) => (action$, store) =>
  action$
    .ofType(EditProfileActionType.EDIT_PROFILE_START)
    .switchMap(({ payload }: EditProfileStartAction) =>
      Observable.fromPromise(
        editProfileService(
          payload,
          selectAccessToken(store.getState()),
          selectGigyaToken(store.getState()),
        ),
      )
        .flatMap((data) => [
          editProfileSuccess(data),
          getCurrentUser.start(),
          fetchPermissions.start(),
          fetchLanguagesStart(),
          createModal({
            key: MODAL_TYPES.CUSTOM,
            data: {
              customComponent: EditProfileSuccessModal,
            },
          }),
        ])
        .pipe(
          catchError((err) =>
            Observable.concat(
              Observable.of(editProfileError(err)),
              Observable.of(
                createModal({
                  key: MODAL_TYPES.CUSTOM,
                  data: {
                    customComponent: EditProfileErrorModal,
                  },
                }),
              ),
            ),
          ),
        ),
    );

export const editEMRProfileEpic: (
  editProfileService: EditEMRProfileServiceImplType,
) => Epic<FixMe, any> = (editProfileService) => (action$, store) =>
  action$
    .ofType(EditProfileActionType.EDIT_EMR_PROFILE_START)
    .switchMap(({ payload }: EditEMRProfileStartAction) =>
      Observable.fromPromise(
        editProfileService(
          payload,
          selectAccessToken(store.getState()),
          selectGigyaToken(store.getState()),
        ),
      )
        .flatMap((data) => [
          editEMRProfileSuccess(data),
          getCurrentUser.start(),
          fetchPermissions.start(),
          createModal({
            key: MODAL_TYPES.CUSTOM,
            data: {
              customComponent: EditProfileSuccessModal,
            },
          }),
        ])
        .pipe(
          catchError((err) =>
            Observable.concat(
              Observable.of(editEMRProfileError(err)),
              Observable.of(
                createModal({
                  key: MODAL_TYPES.CUSTOM,
                  data: {
                    customComponent: EditProfileErrorModal,
                  },
                }),
              ),
            ),
          ),
        ),
    );

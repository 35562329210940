import { createSelector, createStructuredSelector } from 'reselect';

import { convertISOGMT, toFormat } from 'src/shared/utils/date';
import {
  selectAllGraphThresholds,
  selectBoluses,
  selectGlucoseMeasurementsIncludingNullValues,
  selectGraphLoading,
  selectTimeIntervals,
} from 'src/domains/diagnostics/scenes/graphs/logbook/logbook.core.selector';
import { selectBloodGlucoseUnit } from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import { selectEC6TimeFormat } from 'src/app/store/user/user.selectors';

import { selectHasData } from '../../../status-card/status-card.selector';
import { selectNumberBasals } from '../../../blood-glucose-general-stats/components/device-details/device-details.selector';
import {
  groupDayMeasurementsByMealTime,
  groupMeasurementsByDay,
} from '../../meal-time/grouping/meal-time.data-grouping';
import {
  adjustDayMeasurementsByFlags,
  groupMeasurementsAsMealTimeCellMatrices,
} from '../../meal-time/transformations/meal-time.data-transformation';
import { selectCarbUnitMeasurementForService } from 'src/domains/patient-dashboard/store/patient-date-range/patient-date-range.selector';

const toLogbookDateFormat = (dateString) => {
  const date = convertISOGMT(dateString);

  const weekDayName = toFormat('EEEE')(date);
  const monthName = toFormat('LLL')(date);

  const monthNameCapitalized =
    monthName.charAt(0).toUpperCase() + monthName.slice(1);

  return `${weekDayName}, ${toFormat('d')(
    date,
  )} ${monthNameCapitalized} ${toFormat('yyyy')(date)}`;
};

export const selectLogbookData = createSelector(
  selectGlucoseMeasurementsIncludingNullValues,
  selectBoluses,
  selectTimeIntervals,
  selectAllGraphThresholds,
  (measurements, bolusesWithoutJSDate, timeIntervals, allThresholds) => {
    const boluses = bolusesWithoutJSDate.map((bolus) => ({
      ...bolus,
      date: new Date(bolus.date.ts),
      value: Number(bolus.bolusValue.toFixed(2)),
    }));

    const measurementsGroupedByDay = groupMeasurementsByDay(
      measurements,
      boluses,
      allThresholds,
      timeIntervals,
    );
    const allDayMeasurementsGroupedByMealtime = Object.keys(
      measurementsGroupedByDay,
    ).map((date) => {
      const mealTimes = groupDayMeasurementsByMealTime(
        measurementsGroupedByDay[date],
        timeIntervals,
      );

      return {
        day: toLogbookDateFormat(date),
        date,
        mealTimes,
      };
    }, []);
    const adjustedMeasurementsBasedOnMealTimeFlags =
      allDayMeasurementsGroupedByMealtime.map(
        (dayMeasurementsGroupedByMealtime) =>
          adjustDayMeasurementsByFlags(dayMeasurementsGroupedByMealtime),
      );

    const measurementsGroupedAsMealTimeCellMatrices =
      groupMeasurementsAsMealTimeCellMatrices(
        adjustedMeasurementsBasedOnMealTimeFlags,
      );

    return measurementsGroupedAsMealTimeCellMatrices;
  },
);

export const logbookConnector = createStructuredSelector({
  bloodGlucoseUnit: selectBloodGlucoseUnit,
  logbookData: selectLogbookData,
  isLoading: selectGraphLoading,
  timeFormat: selectEC6TimeFormat,
  hasData: selectHasData,
  numbermeasurementsbasalData: selectNumberBasals,
  carbUnit: selectCarbUnitMeasurementForService,
});

import * as React from 'react';
import { SvgIcon } from '../../icon/icon.component';

type EditPatientPropsType = {
  width: number;
  height: number;
  fill: string;
  viewBox: string;
};

export const InviteIcon: React.FC<EditPatientPropsType> = ({
  width,
  height,
  fill,
  viewBox,
}): JSX.Element => {
  return (
    <SvgIcon
      width={width}
      height={height}
      originalWidth={width}
      originalHeight={height}
      fill={fill}
      viewBox={viewBox}
    >
      <g clipPath="url(#clip0_4013_153045)">
        <path
          d="M47.9079 78.4001C67.8816 78.4001 84.0736 62.2107 84.0736 42.2401C84.0736 22.2695 67.8816 6.08008 47.9079 6.08008C27.9341 6.08008 11.7422 22.2695 11.7422 42.2401C11.7422 62.2107 27.9341 78.4001 47.9079 78.4001Z"
          fill="#E4E9ED"
        />
        <path
          d="M76.5518 9.28027C79.1122 13.7603 83.4329 16.5603 89.0337 18.0003C90.714 18.4003 92.5543 18.2403 94.0745 17.3603C95.5148 16.4803 96.4749 14.4803 95.7548 12.9603C94.7947 10.8803 91.8342 10.8803 89.5938 11.2803C86.3133 11.9203 83.1928 13.1203 80.0723 14.4003C78.4721 15.0403 76.7918 15.6003 75.5916 16.8803C74.3914 18.1603 73.7513 20.0803 74.3114 21.6803C74.8715 23.2803 76.5518 24.3203 78.152 24.8803C79.7523 25.4403 81.5126 25.7603 83.1128 26.5603C84.7131 27.3603 85.9933 28.9603 85.7532 30.7203C85.5132 32.8803 83.0328 34.0803 80.8725 33.9203C78.7121 33.7603 76.7918 32.7203 74.7115 31.9203C68.7906 29.7603 61.5894 30.6403 56.3086 34.4003"
          stroke="#758FD8"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          opacity="0.2"
          d="M72.5517 7.51953L59.3496 9.51953L67.5909 18.3995L69.1111 14.7995L71.7516 15.4395L70.3913 11.7595L72.5517 7.51953Z"
          fill="#121212"
        />
        <path
          d="M76.3922 8.9602L71.1113 8.6402L74.2318 5.2002L76.3922 8.9602Z"
          fill="#00CE62"
        />
        <path
          d="M61.5098 4.96L72.4715 13.04L74.2318 5.2L75.752 0L61.5098 4.96Z"
          fill="#10F979"
        />
        <path
          d="M74.2318 5.19996L61.5098 4.95996L73.9117 6.63996L76.3921 8.95996L74.2318 5.19996Z"
          fill="black"
        />
        <path
          d="M85.8334 86.6404C87.5568 86.6404 88.9539 85.2435 88.9539 83.5204C88.9539 81.7973 87.5568 80.4004 85.8334 80.4004C84.11 80.4004 82.7129 81.7973 82.7129 83.5204C82.7129 85.2435 84.11 86.6404 85.8334 86.6404Z"
          fill="#B8D5FF"
        />
        <path
          d="M11.3414 19.3596C14.8766 19.3596 17.7424 16.4942 17.7424 12.9596C17.7424 9.42495 14.8766 6.55957 11.3414 6.55957C7.80626 6.55957 4.94043 9.42495 4.94043 12.9596C4.94043 16.4942 7.80626 19.3596 11.3414 19.3596Z"
          fill="#EAEAEA"
        />
        <path
          opacity="0.2"
          d="M20.3828 74.4003L8.46094 69.2803H43.2664L54.0681 74.4003H20.3828Z"
          fill="#000D35"
        />
        <path
          opacity="0.2"
          d="M15.7425 83.5205L24.6239 95.5205L11.1018 96.0005L12.7821 92.0005L10.2217 90.4805L14.1423 88.6405L15.7425 83.5205Z"
          fill="#121212"
        />
        <path
          d="M9.10149 82.4802C-3.54051 80.9602 0.140074 65.2802 1.82034 61.7602C3.5006 58.2402 7.82128 56.0802 11.1818 57.7602C13.4222 58.8802 14.9424 61.4403 17.2628 62.4003C20.3833 63.7603 24.0638 61.7603 25.7441 58.6403C27.4244 55.5203 27.2643 51.6803 26.3842 48.2403C25.7441 45.6803 24.6239 43.2003 22.7836 41.3603C20.9434 39.5203 18.2229 38.6403 15.9026 39.4403C13.5822 40.2403 11.7419 42.9603 12.302 45.6003C12.9421 48.4003 15.9826 50.0003 18.623 49.6003C21.2634 49.2003 23.5838 47.5203 25.5841 45.6003C27.5844 43.6803 29.4247 41.5203 31.8251 40.1603C35.8257 37.7603 41.1865 38.0803 44.9471 40.8803"
          stroke="#758FD8"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M10.7812 83.1204L15.5013 86.3204L15.1013 81.4404L10.7812 83.1204Z"
          fill="#00CE62"
        />
        <path
          d="M25.9041 89.5201L11.3418 89.1201L15.1024 81.4401L17.1827 76.0801L25.9041 89.5201Z"
          fill="#10F979"
        />
        <path
          d="M15.1019 81.4404L25.9036 89.5204L14.4618 82.8004L10.7812 83.1204L15.1019 81.4404Z"
          fill="black"
        />
        <path
          d="M20.3828 42.8799L47.9071 19.9199L75.4315 42.8799L60.9492 65.4399H35.3452L20.3828 42.8799Z"
          fill="#000D35"
        />
        <path
          d="M67.6708 70.6403H28.1445V18.3203H58.7894L67.6708 27.0403V70.6403Z"
          fill="white"
        />
        <path
          d="M35.8262 42.7998H60.07"
          stroke="#393A44"
          strokeWidth="0.952"
          strokeMiterlimit="10"
        />
        <path
          d="M35.8262 48.3203H60.07"
          stroke="#393A44"
          strokeWidth="0.952"
          strokeMiterlimit="10"
        />
        <path
          d="M58.7891 18.2402V26.9602H67.6705L58.7891 18.2402Z"
          fill="#B7BFC5"
        />
        <path
          d="M35.8262 37.2803H60.07"
          stroke="#393A44"
          strokeWidth="0.952"
          strokeMiterlimit="10"
        />
        <path
          opacity="0.2"
          d="M28.1445 70.6398H67.6708V41.7598L45.3472 57.1998L28.1445 69.0398V70.6398Z"
          fill="#000D35"
        />
        <path
          d="M50.0671 33.5201L47.9072 32.3201L45.8271 33.5201V28.0801H50.0671V33.5201Z"
          fill="#C41414"
        />
        <path
          d="M47.908 30.3206C50.0291 30.3206 51.7486 28.6014 51.7486 26.4806C51.7486 24.3599 50.0291 22.6406 47.908 22.6406C45.7869 22.6406 44.0674 24.3599 44.0674 26.4806C44.0674 28.6014 45.7869 30.3206 47.908 30.3206Z"
          fill="#FF7208"
        />
        <path
          d="M47.908 29.1208C49.3663 29.1208 50.5484 27.9389 50.5484 26.4808C50.5484 25.0228 49.3663 23.8408 47.908 23.8408C46.4497 23.8408 45.2676 25.0228 45.2676 26.4808C45.2676 27.9389 46.4497 29.1208 47.908 29.1208Z"
          fill="url(#paint0_linear_4013_153045)"
        />
        <path
          d="M20.3828 42.8799L75.4315 74.3999H20.3828V42.8799Z"
          fill="#0038FF"
        />
        <path
          opacity="0.2"
          d="M20.3828 74.4002L45.3467 57.2002L75.4315 74.4002H20.3828Z"
          fill="#000D35"
        />
        <path
          d="M75.4315 42.8799L20.3828 74.3999H75.4315V42.8799Z"
          fill="#227CFF"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4013_153045"
          x1="50.9908"
          y1="29.7747"
          x2="45.3829"
          y2="23.7498"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.14" stopColor="#EB3E36" stopOpacity="0" />
          <stop offset="0.28" stopColor="#E33C35" stopOpacity="0.06" />
          <stop offset="0.47" stopColor="#CE3932" stopOpacity="0.21" />
          <stop offset="0.68" stopColor="#AB332E" stopOpacity="0.48" />
          <stop offset="0.91" stopColor="#7B2C29" stopOpacity="0.84" />
          <stop offset="1" stopColor="#662927" />
        </linearGradient>
        <clipPath id="clip0_4013_153045">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export const mockQueryParams = {
  patientId: '313209',
  reportType: 'EMR',
  startDate: '2020-05-1996',
  endDate: '2020-05-1997',
};

export const mockResponseErr = {
  error: {
    code: 401,
    message: 'No access to the patient',
  },
};

export const mockParamsErr = {
  patientId: '313209',
  reportType: 'EMR',
  startDate: '2020-05-1996aadd',
  endDate: '2020-05-1997addd',
};

export const mockPatientReports = {
  mock: 'mock',
};

import { createRequestActionTypes } from '../../../app/store/request/request.actions';

export const FETCH_USER_ANNOUNCEMENTS = 'FETCH_USER_ANNOUNCEMENTS';
export const FETCH_USER_ANNOUNCEMENTS_REQUEST = createRequestActionTypes(
  FETCH_USER_ANNOUNCEMENTS,
);

export const MARK_ANNOUNCEMENT_VISITED = 'MARK_ANNOUNCEMENT_VISITED';
export const MARK_ANNOUNCEMENT_VISITED_REQUEST = createRequestActionTypes(
  MARK_ANNOUNCEMENT_VISITED,
);

export const USER_ANNOUNCEMENT_TYPE = {
  WHATS_NEW_NOTIFICATION: 'WHATS_NEW_NOTIFICATION',
};
export const OPEN_WHATS_NEW_MODAL = 'OPEN_WHATS_NEW_MODAL';
export const CLOSE_WHATS_NEW_MODAL = 'CLOSE_WHATS_NEW_MODAL';
export const HOME_ROUTE = '/home';

import { createRequestActions } from 'src/app/store/request/request.actions';

import {
  FETCH_TEMPORARY_REASONS_REQUEST,
  GET_CURRENT_PRESCRIPTION_REQUEST,
  PRESCRIPTION_ACTIONS,
  SAVE_PRESCRIPTION_REQUEST,
} from './prescription.constants';

export * from './clinic-guide/clinic-guide.actions'; // WIP-MOD Move clinical guidance to the index file
export * from './frequencies/frequencies.actions'; // WIP-MOD Move frequencies imports
export * from './therapy/therapy.actions'; // WIP-MOD Move therapy imports

export const resetPrescription = (id) => ({
  type: PRESCRIPTION_ACTIONS.RESET_PRESCRIPTION,
});

export const addPrescriptionEntry = (id) => ({
  type: PRESCRIPTION_ACTIONS.ADD_PRESCRIPTION_ENTRY,
  payload: id,
});

export const createPrescriptionEntry = () => ({
  type: PRESCRIPTION_ACTIONS.CREATE_PRESCRIPTION_ENTRY,
});

export const setPrescription = (prescription) => ({
  type: PRESCRIPTION_ACTIONS.SET_PRESCRIPTION,
  payload: prescription,
});

export const setActivePrescriptionForm = (formId) => ({
  type: PRESCRIPTION_ACTIONS.SET_ACTIVE_PRESCRIPTION_FORM,
  payload: formId,
});

// Quantities and periods directly extracted from clinic guide
export const updateQuantitiesAndPeriods = ({ formId, clinicGuideId }) => ({
  type: PRESCRIPTION_ACTIONS.UPDATE_QUANTITIES_AND_PERIODS,
  payload: { formId, clinicGuideId },
});

export const removeUnsavedPrescription = (formId) => ({
  type: PRESCRIPTION_ACTIONS.REMOVE_UNSAVED_PRESCRIPTION,
  payload: formId,
});

export const setClinicGuideFilter = ({ formId, filter }) => ({
  type: PRESCRIPTION_ACTIONS.SET_CLINIC_GUIDE_FILTER,
  payload: { formId, filter },
});

export const setGuideHistoryFilter = ({ filter }) => ({
  type: PRESCRIPTION_ACTIONS.SET_GUIDE_HISTORY_FILTER,
  payload: { filter },
});

export const setGuideHistoryPage = ({ page }) => ({
  type: PRESCRIPTION_ACTIONS.SET_GUIDE_HISTORY_PAGE,
  payload: { page },
});

export const getCurrentPrescriptionRequest = createRequestActions(
  GET_CURRENT_PRESCRIPTION_REQUEST,
);

export const savePrescriptionRequest = createRequestActions(
  SAVE_PRESCRIPTION_REQUEST,
);

export const fetchTemporaryReasonsRequest = createRequestActions(
  FETCH_TEMPORARY_REASONS_REQUEST,
);

export const initializePrescription = (fieldData) => ({
  type: PRESCRIPTION_ACTIONS.INITIALIZE_PRESCRIPTION,
  payload: fieldData,
});

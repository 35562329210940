import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { mapDispatchers } from 'src/domains/diagnostics/utils/map-dispatchers';
import { createModal } from 'src/domains/diagnostics/core/modal/modal.actions';
import { MODAL_TYPES } from 'src/domains/diagnostics/core/modal/modal.constants';

import { AdditionalInformationButton } from './additional-information.component';

const connector = createStructuredSelector({});

const dispatchers = mapDispatchers({
  createModal,
});

const handlers = withHandlers({
  onClickDisclaimer:
    ({ createModal }) =>
    (content = [], date) => {
      createModal({
        key: MODAL_TYPES.DISCLAIMER,
        data: {
          disclaimers: content,
          lastUpdateDate: date,
        },
      });
    },
});

export const AdditionalInformationButtonContainer = compose(
  connect(connector, dispatchers),
  handlers,
  withTranslation,
)(AdditionalInformationButton);

import { getConfig } from '@roche/roche-common';
import { CONTENTS, ID, PREFIX, TITLE } from './additional-info.constants';
import { TFunction } from 'i18next';
import { pathOr } from 'ramda';
import { Section } from './additional-info.content';

export const getProps = (
  t: TFunction,
  info: string,
  profile: string,
  active?: string,
  condition?: { [key: string]: () => boolean },
) => {
  return {
    id: ID,
    headerTitle: t(`${PREFIX}${info}.${TITLE}`),
    showCloseButton: true,
    closeOnAction: null,
    primaryButtonText: null,
    secondaryButtonText: null,
    tertiaryButtonText: null,
    info,
    active,
    condition,
    ...pathOr(pathOr(null, [info], CONTENTS), [info, profile], CONTENTS),
  };
};

export const additionalInfoActivated = () =>
  `${getConfig().REACT_APP_ADDITIONAL_INFO}` === 'true';

export const filterSections = (
  sections: Section[],
  condition?: { [key: string]: () => boolean },
) => {
  return sections.filter((section) =>
    section.conditionalSection && condition && condition[section.info]
      ? condition[section.info]()
      : true,
  );
};

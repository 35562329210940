import { testId } from '@roche/roche-common';
import React from 'react';
import {
  JELLO_TOOLBAR_ORIENTATIONS,
  JELLO_TOOLBAR_SIZES,
} from 'src/app/app.jello.constants';
import { ADMINISTRATIVE } from 'src/app/store/user/user.constants.js';
import { RenderIf } from 'src/shared/utils/render-if';
import { PatientActionsComponent } from '../patient-actions-jello/patient-actions-jello.component';
import styles from './styles.module.css';
import { PatientMainData } from './components/patient-main-data';
import { PatientSecondaryData } from './components/patient-secondary-data';

const ToolBarDivider = () => <div className={styles.toolbarDivider} />;

export const PatientDataBarComponent = ({
  patient,
  profile,
  isPlatformUser,
  visitModuleActiveSection,
  setVisitModuleActiveSection,
}) => {
  const {
    surName,
    firstName,
    healthCareSystemId,
    diabetesTypeEC6,
    dateOfBirth,
    diagnosticDate,
    therapyType,
  } = patient;

  const jelloToolbarProps = {
    orientation: JELLO_TOOLBAR_ORIENTATIONS.HORIZONTAL,
    size: JELLO_TOOLBAR_SIZES.S,
    style: {
      borderBottom: '1px solid var(--jello-color-background-alternative)',
      marginBottom: 0,
    },
  };

  const patientMainDataProps = {
    surName,
    firstName,
  };

  const patientActionsComponentProps = {
    visitModuleActiveSection,
    setVisitModuleActiveSection,
  };

  const patientSecondaryDataProps = {
    dateOfBirth,
    diabetesTypeEC6,
    diagnosticDate,
    healthCareSystemId,
    isPlatformUser,
    therapyType,
  };

  return (
    <jello-toolbar
      {...jelloToolbarProps}
      {...testId('jello-toolbar', 'patient-databar')}
    >
      <div className={styles.toolbarPatientInfoWrapper}>
        <PatientMainData {...patientMainDataProps} />
        <ToolBarDivider />
        <PatientSecondaryData {...patientSecondaryDataProps} />
      </div>
      <jello-toolbar-spacer />
      <RenderIf validate={profile !== ADMINISTRATIVE}>
        <PatientActionsComponent {...patientActionsComponentProps} />
      </RenderIf>
    </jello-toolbar>
  );
};

import * as React from 'react';

import { RenderIf } from '../../utils/render-if';

import { CardBase } from './card-base/card-base.component';
import { CardContent } from './card-base/card-base.style';
import { CardHeader } from './card-header/card-header.component';

export const Card = ({
  cardStyles = ['default'],
  customHeaderComponent,
  title,
  expandable = false,
  info = {},
  expanded = false,
  openModal = false,
  expandClickHandler = () => null,
  openModalClickHandler = () => null,
  onInfoClickCallback,
  children,
  iconSize,
  link,
  ...props
}: any) => (
  <CardBase cardStyles={cardStyles} {...props}>
    <RenderIf validate={title || customHeaderComponent}>
      <CardHeader
        cardStyles={cardStyles}
        title={title}
        expandable={expandable}
        info={info}
        openModal={openModal}
        expandClickHandler={expandClickHandler}
        openModalClickHandler={openModalClickHandler}
        customHeaderComponent={customHeaderComponent}
        onInfoClickCallback={onInfoClickCallback}
        expanded={expanded}
        link={link}
        size={iconSize}
        {...props}
      />
    </RenderIf>
    <CardContent cardStyles={cardStyles} {...props}>
      {children}
    </CardContent>
  </CardBase>
);

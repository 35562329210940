import {
  GRAPH_LOGBOOK,
  GRAPH_LOGBOOK_DIARY,
  GRAPH_LOGBOOK_STATS,
  GRAPHS,
  LOGBOOK_TYPE_DIARY,
  LOGBOOK_TYPE_STATS,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { LOGBOOK_HAS_DROPDOWN } from 'src/domains/diagnostics/constants/logbook.constants';

import { historyRoutePush } from './graph-wrapper.util';

const logbookDropDownOptions = [
  {
    label: GRAPH_LOGBOOK,
    value: GRAPH_LOGBOOK,
  },
  {
    label: LOGBOOK_TYPE_DIARY,
    value: GRAPH_LOGBOOK_DIARY,
  },
  {
    label: LOGBOOK_TYPE_STATS,
    value: GRAPH_LOGBOOK_STATS,
  },
];

export const tabs = (changeGraph) => [
  {
    path: GRAPHS.TREND,
    link: GRAPHS.TREND,
    activeTitle: GRAPHS.TREND,
    name: 'graphs.trendTitle',
    onClick: () => changeGraph(GRAPHS.TREND),
    hasDropDown: false,
    modelPath: 'ui.patientDashboard.graphType',
  },
  {
    path: GRAPHS.STANDARD_DAY,
    link: GRAPHS.STANDARD_DAY,
    activeTitle: GRAPHS.STANDARD_DAY,
    name: 'graphs.standardDayTitle',
    onClick: () => changeGraph(GRAPHS.STANDARD_DAY),
    hasDropDown: false,
    modelPath: 'ui.patientDashboard.graphType',
  },
  {
    path: GRAPHS.STANDARD_WEEK,
    link: GRAPHS.STANDARD_WEEK,
    activeTitle: GRAPHS.STANDARD_WEEK,
    name: 'graphs.standardWeekTitle',
    onClick: () => changeGraph(GRAPHS.STANDARD_WEEK),
    hasDropDown: false,
    modelPath: 'ui.patientDashboard.graphType',
  },
  {
    path: GRAPHS.LOGBOOK,
    link: GRAPHS.LOGBOOK,
    activeTitle: GRAPHS.LOGBOOK,
    name: 'graphs.logbookTitle',
    onClick: (v, h) => {
      historyRoutePush(v, h); // this will be bypassed if hasDropDown is false
      changeGraph(GRAPHS.LOGBOOK);
    },
    hasDropDown: LOGBOOK_HAS_DROPDOWN,
    // No effect when hasDropDown is false
    dropDownOptions: logbookDropDownOptions,
    // Used with react-redux-forms to connect a control to the store
    // No effect if hasDropDown is false
    modelPath: 'ui.patientDashboard.logbookType',
  },
  {
    path: GRAPHS.METABOLIC_RATE,
    link: GRAPHS.METABOLIC_RATE,
    activeTitle: GRAPHS.METABOLIC_RATE,
    name: 'graphs.metabolicTitle',
    onClick: () => changeGraph(GRAPHS.METABOLIC_RATE),
    hasDropDown: false,
    modelPath: 'ui.patientDashboard.graphType',
  },
  {
    path: GRAPHS.INSULIN,
    link: GRAPHS.INSULIN,
    activeTitle: GRAPHS.INSULIN,
    name: 'graphs.insulin.title',
    onClick: () => changeGraph(GRAPHS.INSULIN),
    hasDropDown: false,
  },
  {
    path: GRAPHS.INSULIN_PUMP,
    link: GRAPHS.INSULIN_PUMP,
    activeTitle: GRAPHS.INSULIN_PUMP,
    name: 'graphs.insulinPumpTitle',
    onClick: () => changeGraph(GRAPHS.INSULIN_PUMP),
    hasDropDown: false,
  },
  {
    path: GRAPHS.DEVICE_SETTINGS,
    link: GRAPHS.DEVICE_SETTINGS,
    activeTitle: GRAPHS.DEVICE_SETTINGS,
    name: 'graphs.deviceSettings.deviceSettings',
    onClick: () => changeGraph(GRAPHS.DEVICE_SETTINGS),
    hasDropDown: false,
  },
];

export const gpTabs = (changeGraph) => [
  {
    path: GRAPHS.TREND,
    link: GRAPHS.TREND,
    activeTitle: GRAPHS.TREND,
    name: 'graphs.trendTitle',
    onClick: () => changeGraph(GRAPHS.TREND),
    hasDropDown: false,
    modelPath: 'ui.patientDashboard.graphType',
  },
  {
    path: GRAPHS.STANDARD_DAY,
    link: GRAPHS.STANDARD_DAY,
    activeTitle: GRAPHS.STANDARD_DAY,
    name: 'graphs.standardDayTitle',
    onClick: () => changeGraph(GRAPHS.STANDARD_DAY),
    hasDropDown: false,
    modelPath: 'ui.patientDashboard.graphType',
  },
  {
    path: GRAPHS.LOGBOOK,
    link: GRAPHS.LOGBOOK,
    activeTitle: GRAPHS.LOGBOOK,
    name: 'graphs.logbookTitle',
    onClick: (v, h) => {
      historyRoutePush(v, h); // this will be bypassed if hasDropDown is false
      changeGraph(GRAPHS.LOGBOOK);
    },
    hasDropDown: LOGBOOK_HAS_DROPDOWN,
    // No effect when hasDropDown is false
    dropDownOptions: logbookDropDownOptions,
    // Used with react-redux-forms to connect a control to the store
    // No effect if hasDropDown is false
    modelPath: 'ui.patientDashboard.logbookType',
  },
];

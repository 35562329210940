import { ProfileTypes } from 'src/domains/patient/store/department/department.types';
import { STATE_ACTIONS } from 'src/app/store/state/state.constants';
import { PERMISSIONS } from 'src/domains/permissions/store/permissions.constants';

import {
  CreatePatientActions,
  CreatePatientActionType,
  CreatePatientState,
} from './create-patient.types';

export const initialCreatePatientState: CreatePatientState = {
  profileType: ProfileTypes.basic,
  patientInfo: { allowPatientAccess: true },
  healthInfo: {
    pregnant: undefined,
    gender: undefined,
  },
  creationHasError: false,
  creationWasSuccessful: false,
  isCreatingPatient: false,
  grantedPermissions: [],
};

export const createPatientReducer = (
  state = initialCreatePatientState,
  action: CreatePatientActions,
): CreatePatientState => {
  switch (action.type) {
    case CreatePatientActionType.CREATE_PATIENT_START:
      return {
        ...state,
        creationHasError: false,
        creationWasSuccessful: false,
        isCreatingPatient: true,
      };
    case CreatePatientActionType.CREATE_PATIENT_SUCCESS:
      return {
        ...state,
        creationHasError: false,
        creationWasSuccessful: true,
        isCreatingPatient: false,
      };
    case CreatePatientActionType.CREATE_PATIENT_ERROR:
      return {
        ...state,
        creationHasError: true,
        creationWasSuccessful: false,
        isCreatingPatient: false,
      };
    case CreatePatientActionType.GET_CREATED_PATIENT_SUCCESS:
      return {
        ...state,
        newPatient: action.payload,
      };
    case STATE_ACTIONS.CLEAR_CREATE_PATIENT:
      const resetState = initialCreatePatientState;
      resetState.grantedPermissions = [];
      return {
        ...resetState,
      };
    case STATE_ACTIONS.ADD_OPEN_CONNECTIVITY:
      initialCreatePatientState.grantedPermissions = [
        PERMISSIONS.OPEN_CONNECTIVITY_DATA_DOWN,
        ...initialCreatePatientState.grantedPermissions,
      ];
      return initialCreatePatientState;
    default:
      return state;
  }
};

import {
  createAction,
  createPayloadAction,
} from '../../../../../../../app/store/app.actions';
import {
  GetParticipantIdsErrorPayload,
  GetParticipantIdsParams,
  GetParticipantIdsTransformedResponseType,
} from '../../../../../services/patient/clinical-studies/observational-clinical-studies/get-participant-ids/get-participant-ids.types';
import {
  ClearParticipantIdsAction,
  GetParticipantIdsActionType,
  GetParticipantIdsErrorAction,
  GetParticipantIdsStartAction,
  GetParticipantIdsSuccessAction,
} from './get-participant-ids.types';

export const getParticipantIdsStart = (
  payload: GetParticipantIdsParams,
): GetParticipantIdsStartAction =>
  createPayloadAction(
    GetParticipantIdsActionType.GET_PARTICIPANT_IDS_START,
    payload,
  );

export const getParticipantIdsSuccess = (
  payload: GetParticipantIdsTransformedResponseType,
): GetParticipantIdsSuccessAction =>
  createPayloadAction(
    GetParticipantIdsActionType.GET_PARTICIPANT_IDS_SUCCESS,
    payload,
  );

export const getParticipantIdsError = (
  payload: GetParticipantIdsErrorPayload,
): GetParticipantIdsErrorAction =>
  createPayloadAction(
    GetParticipantIdsActionType.GET_PARTICIPANT_IDS_ERROR,
    payload,
  );

export const clearGetParticipantIds = (): ClearParticipantIdsAction =>
  createAction(GetParticipantIdsActionType.CLEAR_GET_PARTICIPANT_IDS);

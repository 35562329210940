import styled from 'styled-components';

export const Container = styled.div`
  .note {
    display: block;
  }
  .card {
    margin-top: 10px;
  }
  .sections {
    border-right: 1px solid #bfbfbf;
    padding-right: 10px;
    .section {
      clear: both;
      color: #0166cc;
      cursor: pointer;
      display: block;
      margin-top: 15px;
      margin-bottom: 15px;
      &.active {
        color: #003366;
      }
      &:hover {
        color: #003366;
      }
    }
  }
  .content {
    p:first-child {
      margin-top: 0px;
    }
  }
`;
export const Content = styled.div``;
export const CardContent = styled.div``;
export const Card = styled.div`
  background-color: #e0ecf9;
  border-radius: 0.5rem;
  margin-top: 15px;
  padding: 16px 16px 16px 24px;
  width: 100%;
  .card-text-no-margin {
    margin: 0;
  }
  .card-first-text-no-top-margin {
    margin-top: 0;
  }
`;
export const CardContainer = styled.div``;
export const IconContainer = styled.div`
  float: left;
  padding-right: 15px;
`;
export const SectionsContainer = styled.div`
  display: none;
  height: 600px;
  overflow: auto;
  overflow-x: hidden;
  padding: 0 20px 0 10px;
  &.active {
    display: inline-block;
  }
  & table {
    border-collapse: collapse;
  }
  & tr {
    &:not(:last-of-type) {
      border-bottom: 0.5px solid #bfbfbf;
    }
  }
  & th {
    font-weight: 600;
  }
  & th,
  td {
    text-align: left;
    padding: 8px 16px;
    vertical-align: top;
    font-size: 12px;
    line-height: 16px;
  }
  .icon {
    color: #0166cc;
  }
`;

export const AdditionalInfoWrapper = styled.div`
  cursor: pointer;
`;

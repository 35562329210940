import { Action, PayloadAction } from 'src/app/store/app.types';
import { CreatePatientWithFhirParams as CreatePatientWithFhirServiceParams } from 'src/domains/patient/services/patient/create-patient-with-fhir/create-patient-with-fhir.types';

export enum CreatePatientWithFhirActionType {
  CREATE_PATIENT_WITH_FHIR_START = 'CREATE_PATIENT_WITH_FHIR_START',
  CREATE_PATIENT_WITH_FHIR_SUCCESS = 'CREATE_PATIENT_WITH_FHIR_SUCCESS',
  CREATE_PATIENT_WITH_FHIR_ERROR = 'CREATE_PATIENT_WITH_FHIR_ERROR',
  CLEAR_CREATE_PATIENT_WITH_FHIR_STATE = 'CLEAR_CREATE_PATIENT_WITH_FHIR_STATE',
}

export type CreatePatientWithFhirParams = CreatePatientWithFhirServiceParams;

export type CreatePatientWithFhirStartAction = PayloadAction<
  CreatePatientWithFhirActionType.CREATE_PATIENT_WITH_FHIR_START,
  CreatePatientWithFhirParams
>;
export type CreatePatientWithFhirSuccessAction =
  Action<CreatePatientWithFhirActionType.CREATE_PATIENT_WITH_FHIR_SUCCESS>;

export type CreatePatientWithFhirErrorAction =
  Action<CreatePatientWithFhirActionType.CREATE_PATIENT_WITH_FHIR_ERROR>;

export type ClearCreatePatientWithFhirStateAction =
  Action<CreatePatientWithFhirActionType.CLEAR_CREATE_PATIENT_WITH_FHIR_STATE>;

export type CreatePatientWithFhirActions =
  | CreatePatientWithFhirStartAction
  | CreatePatientWithFhirSuccessAction
  | CreatePatientWithFhirErrorAction
  | ClearCreatePatientWithFhirStateAction;

export type CreatePatientWithFhirState = {
  creationHasError: boolean;
  creationWasSuccessful: boolean;
  isCreatingPatient: boolean;
};

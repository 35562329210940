import * as React from 'react';

import { colors } from '../../../../app/styles/colors';

import { SvgIcon } from '../../icon/icon.component';

type AtNightMoonIconProps = {
  width?: number;
  height?: number;
  fillColor?: string;
};

export const AtNightMoonIcon = ({
  width = 16,
  height = 16,
  fillColor = colors.blueHypoglycemiaIcons,
}: AtNightMoonIconProps) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      originalWidth={width}
      originalHeight={height}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.2 2.3C6.2 6.7 8.9 11 13.3 12.1H13.5C12.4 13.3 10.8 13.9 9.2 13.9H9C5.6 13.8 3 11 3.2 7.7C3.2 5.3 4.8 3.1 7.2 2.3ZM8 1C4.1 1.6 1.5 5.3 2.1 9.1C2.7 12.4 5.5 14.9 8.9 15H9.2C11.5 15 13.6 13.9 15 12C15.2 11.8 15.1 11.4 14.9 11.3C14.8 11.2 14.7 11.2 14.6 11.2C10.7 10.9 7.9 7.4 8.2 3.6C8.3 3 8.4 2.4 8.6 1.8C8.7 1.5 8.6 1.2 8.3 1.1C8.1 1 8.1 1 8 1Z"
        fill={fillColor}
      />
    </SvgIcon>
  );
};

import { groupWith, isEmpty, isNil, sort, values } from 'ramda';
import { convertTimeToFormat } from 'src/domains/diagnostics/utils/time-format';

import {
  convertGMTDateToFloatOld,
  convertISOGMT,
  hourStringToFloat,
  toFormat,
} from '../../../../../../shared/utils/date';
import {
  AppleEatenIcon,
  AppleIcon,
  NightIcon,
  OvernightIcon,
} from 'src/shared/design-system/icons';
import {
  FADED_OPACITY,
  FULL_OPACITY,
  GRAPH_STANDARD_DAY,
  GRAPH_TYPE_DETAILS,
  GROUPED_TIME_INTERVALS,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { colors } from 'src/app/styles/colors';
import {
  getBolusType1Value,
  getBolusType2Value,
  getBolusType3Value,
} from 'src/domains/diagnostics/widgets/logbook-diary/logbook-diary.util';
import {
  BOLUS_TYPE,
  INSULIN_TYPE,
} from 'src/domains/diagnostics/store/constants';
import { hourToMs } from 'src/shared/utils/date';
import {
  barsWithInvalidLineHeight,
  getDimensionsMapper,
  sortInsulinBarsDescending,
} from 'src/domains/diagnostics/scenes/graphs/graph-shared/graph.util';

import { HOURS_IN_DAY } from './standard-day-detail.constant';

import { MINIMUM_MEASUREMENTS_TO_CALCULATE_STATISTICS } from '../../../blood-glucose-overview/store/blood-glucose-overview.constants';
import {
  bolusTypeToParseFunctionMap,
  parseStandardOrQuickBolus,
} from 'src/domains/diagnostics/scenes/graphs/bolus/bolus.util';
import { areDatesTheSameDay } from 'src/domains/diagnostics/scenes/graphs/graph-shared/graph-date';
import {
  adjustValueByDay,
  hourGapNotGreaterThanXHours,
} from 'src/domains/diagnostics/scenes/graphs/template/utils/graphs-template.util';

export const formatHours = (value) =>
  value === HOURS_IN_DAY
    ? '00:00'
    : value < 10
    ? `0${value}:00`
    : `${value}:00`;

const shouldAddInvisiblePoint = (
  minimumXValue,
  pointA,
  pointB,
  adjustmentModifier, // -1 or 1
) =>
  Math.abs(
    adjustValueByDay(pointA.x - minimumXValue) -
      (pointB.x - HOURS_IN_DAY * adjustmentModifier - minimumXValue),
  ) < 10;

export const glucoseValueLinesConnector =
  (minimumXValue) => (glucoseValueLineData) =>
    glucoseValueLineData.map((line, index, array) => {
      const previous = array[index - 1];
      let current = [...line];
      const next = array[index + 1];

      if (previous) {
        const firstPointOfCurrent = current[0];
        const lastPointOfPrevious = previous[previous.length - 1];
        // get dates to check for 10 hour gap.
        const aDate = lastPointOfPrevious.date;
        const bDate = firstPointOfCurrent.date;

        if (
          hourGapNotGreaterThanXHours(aDate, bDate, 10) &&
          shouldAddInvisiblePoint(
            minimumXValue,
            firstPointOfCurrent,
            lastPointOfPrevious,
            1,
          )
        ) {
          // Add lastPointPrevious to the beginning of current.
          current = [
            {
              ...lastPointOfPrevious,
              x: lastPointOfPrevious.x - HOURS_IN_DAY,
              lineIndex: firstPointOfCurrent.lineIndex,
              date: firstPointOfCurrent.date,
              leaveOffGraph: true,
            },
            ...current,
          ];
        }
      }

      if (next) {
        const lastPointOfCurrent = current[current.length - 1];
        const firstPointOfNext = next[0];
        // get dates to check for 10 hour gap.
        const aDate = lastPointOfCurrent.date;
        const bDate = firstPointOfNext.date;

        if (
          hourGapNotGreaterThanXHours(aDate, bDate, 10) &&
          shouldAddInvisiblePoint(
            minimumXValue,
            lastPointOfCurrent,
            firstPointOfNext,
            -1,
          )
        ) {
          // Add firstPointOfNext to the end of current
          current = [
            ...current,
            {
              ...firstPointOfNext,
              x: firstPointOfNext.x + HOURS_IN_DAY,
              lineIndex: lastPointOfCurrent.lineIndex,
              date: lastPointOfCurrent.date,
              leaveOffGraph: true,
            },
          ];
        }
      }

      return current;
    });

export const groupDateObjectsBy24HourIntervals =
  (_minimumXValue) => (dateObjects) => {
    const chronologicalData = sort(
      (a, b) => a.date.valueOf() - b.date.valueOf(),
      dateObjects,
    );

    return values(
      chronologicalData.reduce((datesGroupedBy24H, val) => {
        // non-mutating; returns a newly-constructed DateTime
        const dayStart = val.date.set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        const key = toFormat('yyyy-MM-dd-HH')(dayStart);

        const entries = datesGroupedBy24H[key] || [];

        return {
          ...datesGroupedBy24H,
          [key]: [...entries, val],
        };
      }, {}),
    );
  };

export const connectFirstAndLastBG = (bgPoints = []) => {
  if (isEmpty(bgPoints)) {
    return;
  }
  const sortedBgPoints = bgPoints.sort((a, b) => a.x - b.x);
  const first = sortedBgPoints[0];
  const last = sortedBgPoints[sortedBgPoints.length - 1];
  const startingPoint = { ...last, x: last.x - HOURS_IN_DAY };
  const endingPoint = { ...first, x: first.x + HOURS_IN_DAY };
  return [startingPoint, ...bgPoints, endingPoint];
};

export const calculateMidPoint = (startTime, endTime) => {
  // if the "end time" is in the next day from the "start time", do a special midpoint calculation for the "mid time"
  if (endTime < startTime) {
    return ((startTime + (endTime + HOURS_IN_DAY)) / 2) % HOURS_IN_DAY;
  }
  // if the "start" and "end time" are in the same day, do a standard midpoint calculation
  return (startTime + endTime) / 2;
};

export const parseTimeIntervalToFloat = (timeInterval) => {
  const startTimeFloat = hourStringToFloat(timeInterval.startTime);
  const endTimeFloat = hourStringToFloat(timeInterval.endTime);
  const midTimeFloat = calculateMidPoint(startTimeFloat, endTimeFloat);

  return {
    description: timeInterval.description,
    startTime: startTimeFloat,
    midTime: midTimeFloat,
    endTime: endTimeFloat,
  };
};

export const parseTimeIntervalFloats = (timeIntervals) =>
  timeIntervals.map(parseTimeIntervalToFloat);

export const findTimeIntervalForBGPoint = (timeIntervals, bgPoint) =>
  timeIntervals.find((timeInterval) => {
    // if the "end time" is in the next day from the "start time", check from "start" to midnight and from midnight to "end"
    if (timeInterval.endTime < timeInterval.startTime) {
      return (
        (bgPoint.x >= timeInterval.startTime && bgPoint.x <= HOURS_IN_DAY) ||
        (bgPoint.x >= 0 && bgPoint.x <= timeInterval.endTime)
      );
    }
    // if the "start" and "end time" are in the same day, just check between the "start" and "end time"
    return (
      bgPoint.x >= timeInterval.startTime && bgPoint.x <= timeInterval.endTime
    );
  }) || {};

export const groupByTimeInterval = (bgPoints, timeIntervals) => {
  if (isEmpty(timeIntervals)) {
    return {};
  }

  const timeIntervalAsFloats = parseTimeIntervalFloats(timeIntervals);

  return bgPoints.reduce((bgPointsByIntervalMidtime, bgPoint) => {
    const { midTime } = findTimeIntervalForBGPoint(
      timeIntervalAsFloats,
      bgPoint,
    );
    const midTimeString = isNil(midTime) ? '' : midTime.toString();
    const bgPointsInInterval = bgPointsByIntervalMidtime[midTimeString] || [];

    return {
      ...bgPointsByIntervalMidtime,
      ...(midTimeString && {
        [midTimeString]: [...bgPointsInInterval, bgPoint],
      }),
    };
  }, {});
};

export const meanBgPointsTransform = (
  bgPointsByTimeInterval,
  minimumXValue,
) => {
  const times = Object.keys(bgPointsByTimeInterval);

  const transformedPoints = times.map((time) =>
    bgPointsByTimeInterval[time].reduce((acc, val, i, intervalArr) => {
      const x = parseFloat(time) - minimumXValue;
      let meanValue = val.y / intervalArr.length;
      let glucoseValue = val.glucoseValue / intervalArr.length;

      if (i !== 0) {
        meanValue += acc.y;
        glucoseValue += acc.glucoseValue;
      }

      return {
        x: adjustValueByDay(x),
        y: meanValue,
        data: {
          value: parseFloat(meanValue.toFixed(2)),
        },
        notEnoughData:
          bgPointsByTimeInterval[time].length <
          MINIMUM_MEASUREMENTS_TO_CALCULATE_STATISTICS,
        glucoseValue: parseFloat(glucoseValue.toFixed(2)),
      };
    }, {}),
  );

  return connectFirstAndLastBG(transformedPoints);
};

export const getIntervalLabel = (description) => {
  const label = description.replace('BEFORE_', '').replace('AFTER_', '');

  switch (label) {
    case 'BREAKFAST':
      return 'general.mealBlocks.breakfast';
    case 'LUNCH':
      return 'general.mealBlocks.lunch';
    case 'DINNER':
      return 'general.mealBlocks.dinner';
    case 'BEDTIME':
      return 'general.mealBlocks.bedTime';
    case 'NIGHT':
      return 'general.mealBlocks.night';
    default:
      return '';
  }
};

export const getParsedIntervalIcon = (parsedInterval, minimumXValue) => {
  let IconComponent;
  let iconWidthScale;

  const { description } = parsedInterval;
  const value = parsedInterval.midTime - minimumXValue;

  if (
    description === 'BEFORE_BREAKFAST' ||
    description === 'BEFORE_LUNCH' ||
    description === 'BEFORE_DINNER'
  ) {
    IconComponent = AppleIcon;
    iconWidthScale = 0.04;
  } else if (
    description === 'AFTER_BREAKFAST' ||
    description === 'AFTER_LUNCH' ||
    description === 'AFTER_DINNER'
  ) {
    IconComponent = AppleEatenIcon;
    iconWidthScale = 0.023;
  } else if (description === 'NIGHT') {
    IconComponent = NightIcon;
    iconWidthScale = 0.035;
  } else if (description === 'BEDTIME') {
    IconComponent = OvernightIcon;
    iconWidthScale = 0.033;
  } else {
    // eslint-disable-next-line no-empty
  }

  return {
    value: adjustValueByDay(value) / HOURS_IN_DAY,
    IconComponent,
    iconWidthScale,
    icon: true,
  };
};

export const getParsedIntervalLabel = (parsedInterval, minimumXValue) => {
  const { midTime, endTime, description } = parsedInterval;

  let value = null;

  if (description === 'NIGHT' || description === 'BEDTIME') {
    value = midTime - minimumXValue;
  } else if (
    description === 'BEFORE_BREAKFAST' ||
    description === 'BEFORE_LUNCH' ||
    description === 'BEFORE_DINNER'
  ) {
    value = endTime - minimumXValue;
  } else {
    // eslint-disable-next-line no-empty
  }

  return {
    value: value ? adjustValueByDay(value) / HOURS_IN_DAY : null,
    label: getIntervalLabel(parsedInterval.description),
  };
};

export const getParsedIntervalTick = (parsedInterval, minimumXValue) => {
  const { endTime, description } = parsedInterval;

  const value = endTime - minimumXValue;
  let type = null;

  if (
    description === 'BEFORE_BREAKFAST' ||
    description === 'BEFORE_LUNCH' ||
    description === 'BEFORE_DINNER'
  ) {
    type = 'short';
  } else {
    type = 'long';
  }

  return {
    value: adjustValueByDay(value) / HOURS_IN_DAY,
    type,
    tickLine: true,
  };
};

export const getBackgroundPanels = (
  parsedInterval,
  numericalGraphStartTime,
) => {
  const { startTime, endTime, description } = parsedInterval;
  const fillColor =
    description.includes(GROUPED_TIME_INTERVALS.BREAKFAST) ||
    description.includes(GROUPED_TIME_INTERVALS.DINNER)
      ? colors.white
      : colors.silverLight;

  const x1 = startTime - numericalGraphStartTime;
  const x2 = endTime - numericalGraphStartTime;

  return {
    x1: adjustValueByDay(x1) / HOURS_IN_DAY,
    x2: adjustValueByDay(x2) / HOURS_IN_DAY,
    fillColor,
  };
};

export const includeInitialPanel = (backgroundPanels) => {
  let initialPanel = null;

  const panels = backgroundPanels.map(({ x1, x2, fillColor }) => {
    // if the end time is in the next day from the start time,
    // set a new initial panel to append, then modify the end time of the current panel
    if (x2 < x1) {
      initialPanel = { x1: 0, x2, fillColor };
      x2 = 1;
    }

    return {
      x1,
      x2,
      fillColor,
    };
  });

  return initialPanel ? panels.concat(initialPanel) : panels;
};

export const sortGlucoseValues = (a, b) => a.x - b.x;

const dateToXValue = (minimumXValue) => (date) =>
  convertGMTDateToFloatOld(date) - minimumXValue;

// This sorts by x, except in the case of invisible points (marked by leaveOffGraph flag) that have been added to connect a day to the next or previous day.
export const sortNormalizedXValuesIncludingInvisiblePoints =
  (minimumXValue) => (a, b) => {
    if ((!a.leaveOffGraph && !b.leaveOffGraph) || a.date === b.date) {
      return a.x - b.x;
    }

    const [newAX, newBX] = [a, b].map(
      ({ data: { date }, leaveOffGraph, x }) => {
        if (leaveOffGraph) {
          const xValueToCompare =
            dateToXValue(minimumXValue)(date) / HOURS_IN_DAY;

          return xValueToCompare < 0 ? xValueToCompare + 1 : xValueToCompare;
        }

        return x;
      },
    );

    return newAX - newBX;
  };

// Used by selectFilteredLines selectors in standard-day-detail as the map callback
// Groups the data to create LineSeries, points should not be more than x hours apart
export const generateLinesWithinGaps = (_minimumXValue) => (line) =>
  groupWith(
    ({ data: { date: aDate } }, { data: { date: bDate } }) =>
      bDate >= aDate && hourGapNotGreaterThanXHours(aDate, bDate, 10),
    line,
  );

export const parseDailyGlucoseValues =
  (_minimumXValue) => (glucoseValue, index) => {
    const x = dateToXValue(0)(glucoseValue.date);
    return {
      x: adjustValueByDay(x),
      y: glucoseValue.value,
      date: glucoseValue.date,
      lineIndex: index,
    };
  };

export const convertISOToGMT = (measurement) => ({
  ...measurement,
  date: convertISOGMT(measurement.date),
});

const applyHourOffset = (hourOffset) => (dimensions) => ({
  ...dimensions,
  x:
    dimensions.x > hourOffset
      ? dimensions.x - hourOffset
      : dimensions.x - hourOffset + 1,
});
export const calculateInsulinDimensions =
  (numericalGraphStartTime, startDate, graphYMax) => (acc, measurement) => {
    const { bolusRemark, bolusType } = measurement;
    const { ONE, TWO, THREE } = INSULIN_TYPE;
    const { STANDARD } = BOLUS_TYPE;
    const { blackGraphInsulin, blueGraphInsulin, redGraphInsulin } = colors;

    const totalTime = hourToMs(24);
    const hourOffset = hourToMs(numericalGraphStartTime) / totalTime;
    const isGlucoseMeasurement = bolusType === undefined;
    const getBolusType = !isGlucoseMeasurement ? bolusType : STANDARD;
    const getDimensions = bolusTypeToParseFunctionMap[getBolusType];

    const insulinDimensions = [
      {
        color: redGraphInsulin,
        insulinType: ONE,
        bolusValue: getBolusType1Value(measurement),
        getInsulinDimensionsFn: getDimensions,
        bolusRemark,
      },
      {
        color: blueGraphInsulin,
        insulinType: TWO,
        bolusValue: getBolusType2Value(measurement),
        getInsulinDimensionsFn: parseStandardOrQuickBolus,
      },
      {
        color: blackGraphInsulin,
        insulinType: THREE,
        bolusValue: getBolusType3Value(measurement),
        getInsulinDimensionsFn: parseStandardOrQuickBolus,
      },
    ].map(getDimensionsMapper(totalTime, startDate, graphYMax, measurement));

    const dimensionMeasurement = insulinDimensions
      .map(applyHourOffset(hourOffset))
      .sort(sortInsulinBarsDescending)
      .filter(barsWithInvalidLineHeight);

    return values.length === 0 ? acc : acc.concat(dimensionMeasurement);
  };

export const crossoverDay = (acc, dimensions) => {
  const { rectWidth, x } = dimensions;

  if (!rectWidth || rectWidth + x <= 1) {
    return [...acc, dimensions];
  }

  const initialRectWidth = 1 - x;

  const initialRectangle = {
    ...dimensions,
    rectWidth: initialRectWidth,
  };

  const crossoverRectangle = {
    ...dimensions,
    x: 0,
    rectWidth: rectWidth - initialRectWidth,
    lineHeight: null,
  };

  return [...acc, initialRectangle, crossoverRectangle];
};

export const formatHorizontalTickLabel = ({
  hourIndex,
  HOURS_IN_DAY,
  is12hourFormat,
}) => {
  const moreThatDayHours = isMoreThanHoursInDay(hourIndex, HOURS_IN_DAY);
  const resultHours = moreThatDayHours ? hourIndex - HOURS_IN_DAY : hourIndex;
  const formattedHours = formatHours(resultHours);

  return is12hourFormat
    ? convertTimeToFormat(formattedHours, true)
    : formattedHours;
};

const isMoreThanHoursInDay = (hourIndex, HOURS_IN_DAY) =>
  hourIndex > HOURS_IN_DAY;

export const isStandardDayDetailGraph = (graph, graphType) =>
  graph === GRAPH_STANDARD_DAY && graphType === GRAPH_TYPE_DETAILS;
export const getOpacity = (selectedDate, measurementDate) =>
  isNil(selectedDate) || areDatesTheSameDay(selectedDate, measurementDate)
    ? FULL_OPACITY
    : FADED_OPACITY;

import {
  getJSON,
  createAuthHeader,
} from 'src/shared/utils/service/service.utils';

import { createService } from '../../../../app/navigation/services/service';

import { CountryData, CountryOptions, CountryService } from './country.types';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';

const countryTransform = (countryData) => countryData.model;

/**
 * @param {string} token
 * @returns {Promise<CountryData>}
 *
 * Note: the token is without bearer attached to it because EC6 directly expect it without
 */
const countryRequest = (openId: CountryOptions): Promise<CountryData> => {
  return getJSON(ENDPOINTS.countries, {
    headers: {
      Authorization: createAuthHeader(openId.accessToken),
    },
  });
};

export const countryService = ({ devMode = false }): CountryService => {
  if (devMode) {
    return createService(() => Promise.resolve({} as any), countryTransform);
  }
  return createService(countryRequest, countryTransform);
};

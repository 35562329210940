import {
  allPass,
  any,
  assoc,
  assocPath,
  dissoc,
  either,
  equals,
  find,
  isNil,
  map,
  mergeRight,
  not,
  pathOr,
  pick,
  pipe,
  prop,
  propEq,
  reduce,
  reject,
} from 'ramda';
import { hasValue, isNotNil } from 'src/shared/utils/validation-helpers';

import {
  FLATTENED_UNSAVED_PRESCRIPTION_IDS,
  PATIENT_PRESCRIPTION_TYPES,
  PRESCRIPTION_COUNTLY_KEYS,
  UNSAVED_PRESCRIPTION_IDS,
} from './prescription.constants';

import { countlyEventTrigger } from '../../../../app/navigation/countly';

const moment = require('moment');

const isNilOrTemporaryPrescriptionExpired = (element) =>
  isNil(element) ||
  (element.endDate &&
    moment(element.endDate).isBefore(moment().subtract(1, 'days')))
    ? true
    : false;
// flattenPrescriptions receives the prescriptions object which is the following structure:
// {
//    permanent: Prescription,
//    temporary: Prescription,
// }
// and flattens it into an array of prescription objects.
//
// Since, if the patient does not have a current prescription, the value of
// permanent will be null, ramda reject + isNil is used to remove the null
// entry from the array (if there is one) so that the array can be used
// gracefully in other files.
export const flattenPrescriptionsExpired = (prescriptions) =>
  !isNil(prescriptions)
    ? reject(isNilOrTemporaryPrescriptionExpired, [
        prescriptions.permanent,
        prescriptions.temporary,
      ])
    : [];

export const flattenPrescriptions = (prescriptions) =>
  !isNil(prescriptions)
    ? reject(isNil, [prescriptions.permanent, prescriptions.temporary])
    : [];

const hasFormId = (formId) => allPass([hasValue, propEq('id', formId)]);

export const isNewPermanentForm = hasFormId(UNSAVED_PRESCRIPTION_IDS.PERMANENT);

export const onlyContainsNewPermanentForm = allPass([
  pipe(prop(PATIENT_PRESCRIPTION_TYPES.TEMPORARY), isNil),
  pipe(prop(PATIENT_PRESCRIPTION_TYPES.PERMANENT), isNewPermanentForm),
]);

export const findClinicGuideWithPrescriptionId = (prescriptionId) =>
  pipe(
    flattenPrescriptions,
    find(propEq('id', prescriptionId)),
    pathOr(null, ['clinicGuide']),
  );

// Use if you are setting options of more than one field at once
// Expects object in newFormOptions
export const setAllFieldsOptions = (formId, newFormOptions) => (state) => {
  const activeForms = {
    ...state.activeForms,
    [formId]: {
      ...state.activeForms[formId],
      ...newFormOptions,
    },
  };
  return {
    ...state,
    activeForms,
  };
};

// Use if setting options/value for just one field
export const setFieldOptions = (field, newFieldOptions, formId) => (state) => {
  const activeForm = {
    ...state.activeForms[formId],
    [field]: newFieldOptions,
  };
  const activeForms = {
    ...state.activeForms,
    [formId]: activeForm,
  };
  return {
    ...state,
    activeForms,
  };
};

const isTemporaryPrescription = (state, id) =>
  propEq('id', id)(state.temporary);

// Maps options of a certain field over all temporary active forms
export const setFieldOptionsForTemporaryForm =
  (field, newFieldOptions) => (state) => {
    const activeForms = map(
      (form) =>
        isTemporaryPrescription(state, form.id)
          ? { ...form, [field]: newFieldOptions }
          : form,
      state.activeForms,
    );
    return {
      ...state,
      activeForms,
    };
  };

export const setPrescriptionByType = (prescription) =>
  assoc(prescription.prescriptionType, prescription);

const isOutdatedAfterSave = (previousId) => propEq('id', previousId);

export const removeActiveForm = (formId) => (state) => {
  const activeForms = reject(isOutdatedAfterSave(formId), state.activeForms);
  return { ...state, activeForms };
};

export const removePrescription = (formId) => (state) => {
  const prescriptions = reject(hasFormId(formId))({
    permanent: state.permanent,
    temporary: state.temporary,
  });
  return {
    ...state,
    permanent: prescriptions.permanent,
    temporary: prescriptions.temporary,
  };
};

export const flattenPrescriptionDateRange = (prescription) => {
  const startDate = pathOr(null, ['dateRange', 'startDate'], prescription);
  const endDate = pathOr(null, ['dateRange', 'endDate'], prescription);
  return pipe(
    assoc('startDate', startDate),
    assoc('endDate', endDate),
    dissoc('dateRange'),
  )(prescription);
};

// Compare a given form ID against placeholder form IDs
export const isUnsavedPrescription = (prescription) =>
  any(equals(prop('id', prescription)))(FLATTENED_UNSAVED_PRESCRIPTION_IDS);

// If ID is not found in placeholder set, it is a valid database ID
export const hasSavedPrescriptionId = pipe(isUnsavedPrescription, not);

// Determine if a prescription form was fetched from the database
export const isSavedPrescription = allPass([isNotNil, hasSavedPrescriptionId]);

export const isTypeTemporary = equals(PATIENT_PRESCRIPTION_TYPES.TEMPORARY);
export const isTypePermanent = equals(PATIENT_PRESCRIPTION_TYPES.PERMANENT);
export const getOptionsArrayFromActiveForm = (optionsName) =>
  pipe(pick([optionsName]), pathOr([], [optionsName]));

// Below: returns object of the shape
// { [form id key]: { option: optionId } }
// for relevant option (ie - therapy, clinic guide, etc)
// Will have an entry per form found in prescriptions array
export const prescriptionsToKeyedOptionObject = (optionName) =>
  pipe(
    map((prescription) => ({ [prescription.id]: prescription[optionName] })),
    reduce(mergeRight, {}),
  );

export const addStripModelNameToPrescription =
  (stripModels) => (prescription) => {
    const stripModelReference = prop('stripModel', prescription);
    const stripModelName = pipe(
      find(propEq('stripModelReference', stripModelReference)),
      prop('stripModelName'),
    )(stripModels);
    return hasValue(stripModelName)
      ? assocPath(['stripModelName'], stripModelName, prescription)
      : prescription;
  };

export const shouldSetPrescriptionFormActive = (payload) => (state) => {
  const activePrescriptionId = pathOr(null, [
    'prescription',
    'activePrescription',
  ])(state);
  return either(
    isUnsavedPrescription,
    propEq('prescriptionType', activePrescriptionId),
  )(payload);
};

export function sendGuidelinesCountlyEvent(actionPayload, state) {
  const {
    therapy,
    clinicGuide,
    stripModel,
    quantity,
    period,
    prescriptionType,
    frequency,
    reason,
  } = actionPayload.prescription;

  const { therapies, clinicGuides, stripModels, temporary, activeForms } =
    state;

  const countlyKeys = { ...PRESCRIPTION_COUNTLY_KEYS };

  if (prescriptionType === 'permanent') {
    const patientTherapy = find(propEq('id', therapy), therapies).name;
    const clinicalGuide = find(propEq('id', clinicGuide), clinicGuides).name;
    const stripsModelSelected = find(propEq('stripModelReference', stripModel))(
      stripModels,
    );
    const deliverFrequency = `${frequency.duration} ${frequency.unit}`;
    const stripsModelTransformed = `${stripsModelSelected.stripModelName} (${stripsModelSelected.stripModelPackageUnits})`;

    countlyEventTrigger(countlyKeys.PRIMARY_TITLE, {
      [countlyKeys.PATIENT_THERAPY]: patientTherapy,
      [countlyKeys.PATIENT_CLINICAL_GUIDE]: clinicalGuide,
      [countlyKeys.NUMBER_OF_TESTS]: quantity,
      [countlyKeys.TEST_FREQUENCY]: period,
      [countlyKeys.MODEL]: stripsModelTransformed,
      [countlyKeys.DELIVERY_FREQUENCY]: deliverFrequency,
    });
  }

  if (prescriptionType === 'temporary') {
    const stateReasons = activeForms[temporary.id].reasons;
    const selectedReason = find(propEq('id', reason), stateReasons).description;

    countlyEventTrigger(countlyKeys.TEMPORARY_TITLE, {
      [countlyKeys.REASON]: selectedReason,
      [countlyKeys.NUMBER_OF_TESTS]: quantity,
      [countlyKeys.TEST_FREQUENCY]: period,
    });
  }
}

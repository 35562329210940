import {
  createAuthHeader,
  postJSON,
} from 'src/shared/utils/service/service.utils';

import {
  InvitePatientLoaderImplType,
  InvitePatientResponseType,
  InvitePatientServiceImplType,
  InvitePatientTransformImplType,
} from './invite-patient.types';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';

export const InvitePatientLoaderImpl: InvitePatientLoaderImplType = (
  accessToken: string,
  gigyaToken: string,
  patientID: string,
  email: string,
) =>
  postJSON(ENDPOINTS.rdacInvitePatient, {
    headers: {
      Authorization: createAuthHeader(accessToken),
      patientID,
      email,
    },
  });

export const InvitePatientTransformImpl: InvitePatientTransformImplType = (
  response: InvitePatientResponseType,
) => {
  const { email } = response;
  return {
    email,
  };
};

export const InvitePatientServiceImpl: InvitePatientServiceImplType =
  (load, transform) => (accessToken, gigyaToken, patientID, email) => {
    return load(accessToken, gigyaToken, patientID, email).then(transform);
  };

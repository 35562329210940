import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';

import { transformClinicGuides } from './get-clinic-guides.util';

import {
  ENDPOINTS,
  endpointWithParams,
} from 'src/app/navigation/services/service.constants';

export const GetClinicGuidesLoaderImpl = ({ therapyId }, accessToken) => {
  const url = therapyId
    ? endpointWithParams(ENDPOINTS.getClinicGuidesWithTherapy, { therapyId })
    : ENDPOINTS.getClinicGuides;

  const options = {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  };

  return getJSON(url, options);
};

export const GetClinicGuidesTransformImpl = (data) =>
  transformClinicGuides(data);

export const GetClinicGuidesServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);

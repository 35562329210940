import * as React from 'react';
import { connect } from 'react-redux';
import { selectAccessToken } from '../../session/core/oidc/oidc.selectors';
import { selectGigyaToken } from '../../session/core/config/config.selectors';

import { getConfig } from '@roche/roche-common';

import {
  selectEC6TimeZone,
  selectEC6UserLanguage,
} from '../../store/user/user.selectors';

const ModuleAnchorConfigComponent = (props: StateProps) => {
  const children = props.children;
  return (
    <>
      {React.cloneElement(children, {
        config: {
          accessToken: props.accessToken,
          apiKey: props.apiKey,
          hcpLanguage: props.hcpLanguage,
          hcpTimezone: props.hcpTimezone,
          clientId: props.clientId,
          clientSecret: props.clientSecret,
          apiUrl: props.apiUrl,
        },
      })}
    </>
  );
};

interface StateProps {
  accessToken: string;
  apiKey: string;
  hcpLanguage: string;
  hcpTimezone: string;
  clientId: string;
  clientSecret: string;
  apiUrl: string;
  children: any;
}
const mapStateToProps = (state): StateProps => ({
  ...state,
  accessToken: selectAccessToken(state),
  apiKey: selectGigyaToken(state),
  hcpLanguage: selectEC6UserLanguage(state),
  hcpTimezone: selectEC6TimeZone(state),
  clientId: getConfig().REACT_APP_CLIENT_ID,
  apiUrl: getConfig().REACT_APP_API_MULE,
  clientSecret: getConfig().REACT_APP_CLIENT_SECRET,
});

export const ModuleAnchorConfig = connect(mapStateToProps)(
  ModuleAnchorConfigComponent,
);

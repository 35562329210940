import {
  IS_WHATS_NEW_VIEWED,
  CLEAR_IS_WHATS_NEW_VIEWED,
} from './whats-new.constants';

export const setIsWhatsNewViewed = () => ({
  type: IS_WHATS_NEW_VIEWED,
});

export const clearWhatsNewViewed = () => ({
  type: CLEAR_IS_WHATS_NEW_VIEWED,
});

import React from 'react';

import { colors } from 'src/app/styles/colors';

import { SvgIcon } from 'src/shared/design-system/icon/icon.component';

export const BasalToggleIcon = ({
  height = 13,
  fillColor = colors.basalIconBlue,
  minX = 0,
  minY = 0,
}: Props) => {
  const originalWidth = 12;
  const originalHeight = 12;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      title="Basal"
      width={height * aspectRatio}
      height={height}
      minX={minX}
      minY={minY}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g fill={fillColor} fillRule="nonzero">
        <rect x="0" y="4" width="6" height="2" />
        <rect x="4" y="10" width="5" height="2" />
        <rect x="4" y="4" width="2" height="8" />
        <rect x="10" y="0" width="2" height="8" />
        <rect x="7" y="0" width="2" height="12" />
        <rect x="7" y="0" width="4" height="2" />
      </g>
    </SvgIcon>
  );
};

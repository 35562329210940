import { addDomainNamespace } from 'src/domains/strip-management/domain-namespace';

import { createRequestActionTypes } from '../../../../app/store/request/request.actions';

export const periodTranslationKeyBase = 'prescription.period.';

const DOMAIN_NAMESPACE = 'PATIENT_PRESCRIPTION';
const addNamespacing = (actionName) =>
  addDomainNamespace(actionName, DOMAIN_NAMESPACE);

export const PRESCRIPTION_ACTIONS = {
  GET_PRESCRIPTION: addNamespacing('GET_PRESCRIPTION'),
  SET_PRESCRIPTION: addNamespacing('SET_PRESCRIPTION'),
  SET_ACTIVE_PRESCRIPTION_FORM: addNamespacing('SET_ACTIVE_PRESCRIPTION_FORM'),
  ADD_PRESCRIPTION_ENTRY: addNamespacing('ADD_PRESCRIPTION_ENTRY'),
  CREATE_PRESCRIPTION_ENTRY: addNamespacing('CREATE_PRESCRIPTION_ENTRY'),
  UPDATE_QUANTITIES_AND_PERIODS: addNamespacing(
    'UPDATE_QUANTITIES_AND_PERIODS',
  ),
  RESET_PRESCRIPTION: addNamespacing('RESET_PRESCRIPTION'),
  INITIALIZE_PRESCRIPTION: addNamespacing('INITIALIZE_PRESCRIPTION'),
  SET_PRESCRIPTION_CLINIC_GUIDE_TYPE: addNamespacing(
    'SET_PRESCRIPTION_CLINIC_GUIDE_TYPE',
  ),
  REMOVE_UNSAVED_PRESCRIPTION: addNamespacing('REMOVE_UNSAVED_PRESCRIPTION'),
  SHOW_CUSTOM_CLINIC_GUIDES_FORM: addNamespacing(
    'SHOW_CUSTOM_CLINIC_GUIDES_FORM',
  ),
  HIDE_CUSTOM_CLINIC_GUIDES_FORM: addNamespacing(
    'HIDE_CUSTOM_CLINIC_GUIDES_FORM',
  ),
  SET_CLINIC_GUIDE_FILTER: addNamespacing('SET_CLINIC_GUIDE_FILTER'),
  SET_GUIDE_HISTORY_FILTER: addNamespacing('SET_GUIDE_HISTORY_FILTER'),
  SET_GUIDE_HISTORY_PAGE: addNamespacing('SET_GUIDE_HISTORY_PAGE'),
};

export const PATIENT_PRESCRIPTION_TYPES = {
  TEMPORARY: 'temporary',
  PERMANENT: 'permanent',
};

export const UNSAVED_PRESCRIPTION_IDS = {
  TEMPORARY: 'new-unsaved-temporary-prescription',
  PERMANENT: 'new-unsaved-permanent-prescription',
};

export const FLATTENED_UNSAVED_PRESCRIPTION_IDS = [
  UNSAVED_PRESCRIPTION_IDS.PERMANENT,
  UNSAVED_PRESCRIPTION_IDS.TEMPORARY,
];

export const GET_CURRENT_PRESCRIPTION = addNamespacing(
  'GET_CURRENT_PRESCRIPTION',
);
export const GET_CURRENT_PRESCRIPTION_REQUEST = createRequestActionTypes(
  GET_CURRENT_PRESCRIPTION,
);

export const SAVE_PRESCRIPTION = addNamespacing('SAVE_PRESCRIPTION');
export const SAVE_PRESCRIPTION_REQUEST =
  createRequestActionTypes(SAVE_PRESCRIPTION);

export const FETCH_TEMPORARY_REASONS = addNamespacing(
  'FETCH_TEMPORARY_REASONS',
);
export const FETCH_TEMPORARY_REASONS_REQUEST = createRequestActionTypes(
  FETCH_TEMPORARY_REASONS,
);

// User modifiable fields to reset on SET_ACTIVE_PRESCRIPTION_FORM
const USER_EDITABLE_FIELDS = [
  'clinicGuide',
  'frequency',
  'period',
  'quantity',
  'stripModel',
  'therapy',
];

export const MAX_PRESCRIPTIONS = 2;

export const GUIDE_HISTORY_FILTERS = {
  ALL: 'ALL',
  PERSONAL: 'PERSONAL',
};

export const GUIDES_PER_HISTORY_PAGE = 5;

export const PRESCRIPTION_COUNTLY_KEYS = {
  PRIMARY_TITLE: 'Primary Guideline Edited',
  PATIENT_THERAPY: 'Patient Therapy',
  PATIENT_CLINICAL_GUIDE: 'Clinical Guide',
  NUMBER_OF_TESTS: 'Number of Tests',
  TEST_FREQUENCY: 'Test Frequency',
  MODEL: 'Model',
  DELIVERY_FREQUENCY: 'Delivery frequency',
  TEMPORARY_TITLE: 'Temporary Guideline Added',
  REASON: 'Reason',
};

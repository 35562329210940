import React, { Component } from 'react';
import { Subject } from 'rxjs/Subject';
import { isEmpty } from 'ramda';
import { AutoSizer } from 'react-virtualized';

import { Graph } from 'src/domains/diagnostics/components/graph-lib/graph.component';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import { withGraphLoader } from 'src/domains/diagnostics/utils/with-graph-loader';
import { ToolTip } from 'src/domains/diagnostics/components/tool-tip/tool-tip.component';
import { GraphControls } from 'src/domains/diagnostics/components/graph-controls/graph-controls.component';
import { ResizeWrapper } from 'src/domains/diagnostics/resize-wrapper/resize-wrapper.component';

import { MetabolicWrapperDiv } from './metabolic.style';
import { AxesPlot } from './components/metabolic-axes.component';
import { MetabolicDataPoints } from './components/metabolic-data-points.component';
import { MetabolicEllipses } from './components/metabolic-ellipses.component';
import { MetabolicPlot } from './components/metabolic-plot.component';
import { MetabolicTooltip } from './components/metabolic-tooltip.component';
import { MetabolicStabilityLabels } from './components/metabolic-stability-labels.component';
import {
  COLLAPSED_STD_GRAPH_HEIGHT,
  GRAPH_TYPE_METABOLIC,
  LOGBOOK_TYPE_STATS,
  MIN_EXPANDED_STD_GRAPH_HEIGHT,
} from '../../graph.constants';
import { navigateToLogbook } from '../../graph-shared/graph.util';
import { testId } from '@roche/roche-common';

const clickStream$ = new Subject();
const stop$ = new Subject();

const MetabolicWrapperDivWithLoader = withGraphLoader(MetabolicWrapperDiv);

export class Metabolic extends Component {
  state = {
    selectedPoint: null,
  };

  componentDidMount() {
    clickStream$
      .bufferWhen(() => clickStream$.debounceTime(250))
      .takeUntil(stop$)
      .subscribe((values) => {
        if (values.length === 1) {
          this.setState({ selectedPoint: values[0].index });
        } else {
          const { history, changeLogbookType } = this.props;
          navigateToLogbook(
            history,
            values[0].date,
            changeLogbookType,
            LOGBOOK_TYPE_STATS,
          );
        }
      });
  }

  componentWillUnmount() {
    stop$.next();
    stop$.complete();
  }

  render() {
    const {
      collapsed,
      bloodGlucoseUnit,
      graphData,
      graphDetails,
      thresholds,
      verticalTicks,
      verticalTicksDashboard,
      verticalLabel,
      horizontalTicks,
      horizontalLabel,
      toggles,
      meanBGSD,
      bloodGlucoseDimensions,
      sd1,
      toolTip,
      showToolTip,
      hideToolTip,
      stabilityLabels,
      showDetails = true,
      isLoading,
      t,
      graph,
    } = this.props;

    const { selectedPoint } = this.state;

    const stabilityLabelKeyArray = Object.keys(stabilityLabels);
    const translatedStabilityLabels = stabilityLabelKeyArray.reduce(
      (acc, curr) => {
        const label = {
          ...acc,
          [curr]: t(stabilityLabels[curr]),
        };
        return label;
      },
      {},
    );
    const translatedVerticalLabel = verticalLabel.map((eachLabel) => ({
      ...eachLabel,
      label: `${eachLabel.label.map((item) => t(item)).join('')}`,
    }));

    const translatedHorizontalLabel = horizontalLabel.map((eachLabel) => ({
      ...eachLabel,
      label: `${eachLabel.label.map((item) => t(item)).join('')}`,
    }));

    return (
      <React.Fragment>
        <RenderIf validate={!isEmpty(graphData) && !isLoading}>
          <GraphControls
            graphStatistics={graphDetails}
            graphType={GRAPH_TYPE_METABOLIC}
            graph={graph}
          />
        </RenderIf>
        <ResizeWrapper
          minHeight={
            collapsed
              ? COLLAPSED_STD_GRAPH_HEIGHT
              : MIN_EXPANDED_STD_GRAPH_HEIGHT
          }
          render={(height) => {
            return (
              <MetabolicWrapperDivWithLoader
                {...testId('graph-template', 'metabolic-rate')}
                collapsed={collapsed}
                hasError={isEmpty(graphData) && !isLoading}
                isLoading={isLoading}
                showDetails={showDetails}
                onClick={this.onGraphClick}
              >
                <AutoSizer>
                  {({ width }) => {
                    const innerPlotWidth = width * 0.95;
                    const innerPlotX = width * 0.04;
                    return (
                      <Graph
                        viewportRight={width}
                        viewportBottom={height}
                        height={height}
                        anchor="xMidYMid"
                      >
                        <MetabolicStabilityLabels
                          x={innerPlotX}
                          y={height * 0.1}
                          width={innerPlotWidth - innerPlotX}
                          height={height * 0.8}
                          padding={height * 0.02}
                          labels={translatedStabilityLabels}
                          bloodGlucoseDimensions={bloodGlucoseDimensions}
                        />
                        <MetabolicPlot
                          x={innerPlotX}
                          y={height * 0.1}
                          width={innerPlotWidth - innerPlotX}
                          height={height * 0.8}
                          padding={height * 0.02}
                          thresholds={thresholds}
                          toggles={toggles}
                          bloodGlucoseDimensions={bloodGlucoseDimensions}
                        />
                        <AxesPlot
                          x={0}
                          y={0}
                          axisX={innerPlotX}
                          verticalTicks={verticalTicks}
                          verticalTicksDashboard={verticalTicksDashboard}
                          verticalLabel={translatedVerticalLabel}
                          horizontalTicks={horizontalTicks}
                          horizontalLabel={translatedHorizontalLabel}
                          width={width}
                          innerPlotWidth={innerPlotWidth}
                          height={height}
                          showDetails={showDetails}
                          bloodGlucoseDimensions={bloodGlucoseDimensions}
                        />
                        <RenderIf validate={graphData && graphData.length}>
                          <MetabolicEllipses
                            x={innerPlotX}
                            y={height * 0.1}
                            width={innerPlotWidth - innerPlotX}
                            height={height * 0.8}
                            padding={height * 0.02}
                            meanBGSD={meanBGSD}
                            sd1={sd1}
                          />
                          <MetabolicDataPoints
                            x={innerPlotX}
                            y={height * 0.1}
                            width={innerPlotWidth - innerPlotX}
                            height={height * 0.8}
                            padding={height * 0.02}
                            graphData={graphData}
                            onPointMouseEnter={showToolTip}
                            onPointMouseLeave={hideToolTip}
                            meanBGSD={meanBGSD}
                            selectedPoint={selectedPoint}
                            onPointClick={this.onDataPointClick}
                            isMaximized={showDetails}
                          />
                        </RenderIf>
                      </Graph>
                    );
                  }}
                </AutoSizer>
              </MetabolicWrapperDivWithLoader>
            );
          }}
        />
        <RenderIf validate={toolTip && toolTip.x && toolTip.y}>
          {this.renderToolTip(toolTip, bloodGlucoseUnit)}
        </RenderIf>
      </React.Fragment>
    );
  }

  onGraphClick = (e) => this.setState({ selectedPoint: null });

  onDataPointClick = (point) => (e) => {
    e.stopPropagation();
    clickStream$.next(point);
  };

  renderToolTip = ({ x, y, data }, bloodGlucoseUnit) => {
    const { meanRounded, stdRounded, dateLabel, manuallyEntered } = data;

    return (
      <ToolTip x={x} y={y}>
        <MetabolicTooltip
          date={dateLabel}
          stdDev={stdRounded}
          mean={meanRounded}
          bloodGlucoseUnit={bloodGlucoseUnit}
          manuallyEntered={manuallyEntered}
        />
      </ToolTip>
    );
  };
}

import { createAction, createPayloadAction } from 'src/app/store/app.actions';
import {
  FetchDevicesErrorAction,
  FetchDevicesStartAction,
  FetchDevicesSuccessAction,
  ListDevicesActionType,
  RemoveDevicePayload,
  RemoveDeviceStartAction,
} from './list-devices.types';

export const fetchDevicesStart = (patientId): FetchDevicesStartAction =>
  createPayloadAction(ListDevicesActionType.FETCH_DEVICES_START, patientId);

export const fetchDevicesSuccess = (devices): FetchDevicesSuccessAction =>
  createPayloadAction(ListDevicesActionType.FETCH_DEVICES_SUCCESS, devices);

export const fetchDevicesError = (): FetchDevicesErrorAction =>
  createAction(ListDevicesActionType.FETCH_DEVICES_ERROR);

export const removeDeviceStart = (
  payload: RemoveDevicePayload,
): RemoveDeviceStartAction =>
  createPayloadAction(ListDevicesActionType.REMOVE_DEVICE_START, payload);

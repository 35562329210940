import {
  createAction,
  createPayloadAction,
} from '../../../../app/store/app.actions';

import {
  ConfigurablePermissionsActions,
  FetchConfigurablesErrorAction,
  FetchConfigurablesResetAction,
  FetchConfigurablesStartAction,
  FetchConfigurablesSuccessAction,
} from './configurables.types';

export const fetchConfigurablesStart = (): FetchConfigurablesStartAction =>
  createAction(ConfigurablePermissionsActions.FETCH_CONFIGURABLES_START);

export const fetchConfigurablesSuccess = (
  payload: string[],
): FetchConfigurablesSuccessAction =>
  createPayloadAction(
    ConfigurablePermissionsActions.FETCH_CONFIGURABLES_SUCCESS,
    payload,
  );

export const fetchConfigurablesError = (): FetchConfigurablesErrorAction =>
  createAction(ConfigurablePermissionsActions.FETCH_CONFIGURABLES_ERROR);

export const fetchConfigurablesReset = (): FetchConfigurablesResetAction =>
  createAction(ConfigurablePermissionsActions.FETCH_CONFIGURABLES_RESET);

import { STATE_ACTIONS } from 'src/app/store/state/state.constants';
import { SAVE_ALERTS_REQUEST } from 'src/domains/strip-management/store/alerts/alerts.constants';
import { SAVE_PRESCRIPTION_REQUEST } from 'src/domains/strip-management/store/prescription/prescription.constants';

import {
  GET_DELIVERY_HISTORY_REQUEST,
  GET_DELIVERY_REQUEST,
  GET_DELIVERY_STATUS_REQUEST,
  GET_LAST_DELIVERY_STATUS_REQUEST,
  GET_THRESHOLDS_REQUEST,
  GET_TIME_INTERVALS_REQUEST,
  SAVE_DELIVERY_REQUEST,
  SAVE_DELIVERY_STATUS_COMMENT_REQUEST,
  SET_DELIVERY_STATUS_REQUEST,
  TRAFFIC_LIGHT_STATES,
} from './strip-delivery.constants';

export const INITIAL_FORCE_STATUS_STATE = {
  status: null,
  comment: '',
};

export const INITIAL_CONDITIONS_STATE = {
  actualHypers: { pass: null, amount: null, threshold: null },
  hypers: { pass: null, amount: null, threshold: null },
  hypos: { pass: null, amount: null, threshold: null },
  warnings: { pass: null, amount: null, threshold: null },
  consumption: {
    pass: null,
    amount: null,
    threshold: null,
    percentConsumed: null,
  },
};

export const INITIAL_STRIP_DELIVERY_STATUS_STATE = {
  trafficLightStatus: TRAFFIC_LIGHT_STATES.DISABLED,
  trafficLightStatusId: null,
  trafficLightStatusDateCalculated: '',
  trafficLightStatusForced: null,
  trafficLightStatusConditions: INITIAL_CONDITIONS_STATE,
  trafficLightStatusComment: null,
  trafficLightStatusCommentState: null,
  numberOfStripsToDeliver: null,
  lastTrafficLightStatus: null,
  lastTrafficLightStatusConditions: INITIAL_CONDITIONS_STATE,
  lastTrafficLightStatusComment: null,
  lastTrafficLightStatusCommentState: null,
  lastStatusLastCollectedDate: null,
  lastNumberOfStripsToDeliver: null,
  lastTrafficLightStatusDateCalculated: null,
  lastDeliveryTrafficLightStatus: null,
};

export const INITIAL_STRIP_DELIVERY_STATE = {
  stripDeliveryInfo: {
    id: null,
    lastCollectedDate: '',
    prescriptionId: null,
    ...INITIAL_STRIP_DELIVERY_STATUS_STATE,
  },
  thresholds: {},
  timeIntervals: [],
  forceTrafficStatus: INITIAL_FORCE_STATUS_STATE,
  deliveryHistory: {
    list: [],
    error: null,
  },
};

const saveAlertsRequestSuccess = (state) => ({
  ...state,
  stripDeliveryInfo: INITIAL_STRIP_DELIVERY_STATE.stripDeliveryInfo,
});

const savePrescriptionRequestSuccess = saveAlertsRequestSuccess;

const clearStripDelivery = () => INITIAL_STRIP_DELIVERY_STATE;

const getDeliveryStatusRequestSuccess = (state, action) => {
  const {
    trafficLightStatus,
    trafficLightStatusId,
    trafficLightStatusDateCalculated,
    trafficLightStatusForced,
    trafficLightStatusConditions,
    trafficLightStatusComment,
    trafficLightStatusCommentState,
    numberOfStripsToDeliver,
  } = action.payload;
  return {
    ...state,
    forceTrafficStatus: INITIAL_FORCE_STATUS_STATE,
    stripDeliveryInfo: {
      ...state.stripDeliveryInfo,
      trafficLightStatus,
      trafficLightStatusId,
      trafficLightStatusDateCalculated,
      trafficLightStatusForced,
      trafficLightStatusConditions,
      trafficLightStatusComment,
      trafficLightStatusCommentState,
      numberOfStripsToDeliver,
    },
  };
};

const getDeliveryRequestSuccess = (state, action) => ({
  ...state,
  stripDeliveryInfo: {
    ...state.stripDeliveryInfo,
    id: action.payload.id,
    lastCollectedDate: action.payload.lastCollectedDate,
    prescriptionId: action.payload.prescriptionId,
    lastDeliveryTrafficLightStatus:
      action.payload.lastDeliveryTrafficLightStatus,
  },
});

const getLastDeliveryStatusRequestSuccess = (state, action) => {
  const {
    lastTrafficLightStatus,
    lastTrafficLightStatusConditions,
    lastTrafficLightStatusComment,
    lastTrafficLightStatusCommentState,
    lastNumberOfStripsToDeliver,
    lastTrafficLightStatusDateCalculated,
  } = action.payload;

  return {
    ...state,
    stripDeliveryInfo: {
      ...state.stripDeliveryInfo,
      lastTrafficLightStatus,
      lastTrafficLightStatusConditions,
      lastTrafficLightStatusComment,
      lastTrafficLightStatusCommentState,
      lastNumberOfStripsToDeliver,
      lastTrafficLightStatusDateCalculated,
    },
  };
};

const getTimeIntervalsRequestSuccess = (state, action) => ({
  ...state,
  timeIntervals: action.payload,
});

const getThresholdsRequestSuccess = (state, action) => ({
  ...state,
  thresholds: action.payload,
});

const saveDeliveryRequestSuccess = (state, action) => {
  const { lastCollectedDate } = action.payload;
  const stripDeliveryInfo = {
    ...state.stripDeliveryInfo,
    ...INITIAL_STRIP_DELIVERY_STATUS_STATE,
    lastCollectedDate,
  };
  return {
    ...state,
    stripDeliveryInfo,
  };
};

const setDeliveryStatusRequestSuccess = (state, action) => ({
  ...state,
  forceTrafficStatus: INITIAL_FORCE_STATUS_STATE,
  stripDeliveryInfo: {
    ...state.stripDeliveryInfo,
    ...action.payload,
  },
});

const saveDeliveryStatusCommentRequestSuccess = (state, action) => ({
  ...state,
  stripDeliveryInfo: {
    ...state.stripDeliveryInfo,
    trafficLightStatusId: action.payload.trafficLightStatusId,
  },
});

const getDeliveryHistoryRequestSuccess = (state, action) => ({
  ...state,
  deliveryHistory: {
    list: action.payload,
    error: null,
  },
});

const getDeliveryHistoryRequestError = (state, action) => ({
  ...state,
  deliveryHistory: {
    list: [],
    error: action.payload,
  },
});

const stripDeliveryReducerActions = {
  [SAVE_ALERTS_REQUEST.SUCCESS]: saveAlertsRequestSuccess,

  [SAVE_PRESCRIPTION_REQUEST.SUCCESS]: savePrescriptionRequestSuccess,

  [STATE_ACTIONS.CLEAR_STRIP_DELIVERY]: clearStripDelivery,

  [GET_DELIVERY_STATUS_REQUEST.SUCCESS]: getDeliveryStatusRequestSuccess,

  [GET_DELIVERY_REQUEST.SUCCESS]: getDeliveryRequestSuccess,

  [GET_LAST_DELIVERY_STATUS_REQUEST.SUCCESS]:
    getLastDeliveryStatusRequestSuccess,

  [GET_TIME_INTERVALS_REQUEST.SUCCESS]: getTimeIntervalsRequestSuccess,

  [GET_THRESHOLDS_REQUEST.SUCCESS]: getThresholdsRequestSuccess,

  [SAVE_DELIVERY_REQUEST.SUCCESS]: saveDeliveryRequestSuccess,

  [SET_DELIVERY_STATUS_REQUEST.SUCCESS]: setDeliveryStatusRequestSuccess,

  [SAVE_DELIVERY_STATUS_COMMENT_REQUEST.SUCCESS]:
    saveDeliveryStatusCommentRequestSuccess,

  [GET_DELIVERY_HISTORY_REQUEST.SUCCESS]: getDeliveryHistoryRequestSuccess,

  [GET_DELIVERY_HISTORY_REQUEST.ERROR]: getDeliveryHistoryRequestError,
};

// Usage in the reducer function
export const stripDeliveryReducer = (
  state = INITIAL_STRIP_DELIVERY_STATE,
  action = {},
) => {
  const handler = stripDeliveryReducerActions[action.type];
  return handler ? handler(state, action) : state;
};

import {
  GetINSIdentityLoaderImpl,
  GetINSIdentityServiceImpl,
  GetINSIdentityTransformImpl,
} from './get-INS-identity.service';
// @ts-ignore
import { mockGetINSIdentity } from './get-INS-identity.mock';
import { INSIdentityResponseType } from './get-INS-identity.types';

const mockLoader = (): Promise<INSIdentityResponseType> =>
  new Promise((resolve, reject) =>
    setTimeout(() => reject(new Error('Connection Failed')), 60000),
  );

export const GetINSIdentityFetchFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : GetINSIdentityLoaderImpl;
  return GetINSIdentityServiceImpl(loader, GetINSIdentityTransformImpl);
};

import { filter, includes, map, not, pipe, replace } from 'ramda';

export const transformConfigurableForEC6 = (configurable: string): string =>
  replace('ROLE_', '', configurable);

export const transformConfigurableFromEC6 = (configurable: string): string =>
  `${configurable}`;

export const getRevokedConfigurables = (
  list: string[],
  toExclude: string[],
): string[] => {
  const filterFn = (element) => not(includes(element, toExclude));
  return pipe(filter(filterFn), map(transformConfigurableForEC6))(list);
};

export const getGrantedConfigurables = (
  list: string[],
  toExclude: string[],
): string[] => {
  const exclude = toExclude.map(transformConfigurableFromEC6);
  const filterFn = (element) => not(includes(element, exclude));
  return filter(filterFn, list);
};

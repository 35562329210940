import React from 'react';

import { getTrafficLightColorFromStatus } from 'src/domains/diagnostics/core/strip-delivery/strip-delivery.utils';
import { Badge } from 'src/shared/design-system/badge/badge.component';

export const TrafficLightCircle = ({
  disabled,
  emptyInnerCircle,
  icon,
  size,
  status,
}) => (
  <Badge
    bgColor={getTrafficLightColorFromStatus(status)}
    disabled={disabled}
    emptyInnerCircle={emptyInnerCircle}
    icon={icon}
    size={size}
  />
);
TrafficLightCircle.defaultProps = {
  disabled: false,
};

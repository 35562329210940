import * as React from 'react';
import {
  JELLO_HEADINGS_LEVELS,
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
} from 'src/app/app.jello.constants';
import styles from './styles.module.css';
import { getLabel } from '../../../shared/utils/i18n/with-translation';
import { Style } from '../store/announcements.types';
import { t } from 'i18next';
import {
  BOLD,
  CALLOUT,
  BULLET,
  CONTAINER,
  CUSTOM,
  ITALIC,
  SUB_HEADER,
  UNORDERED_LIST,
  WHAT_NEW_TEXT,
} from '../whats-new/whats-new.constants';
import { testId } from '@roche/roche-common';

const getTextProps = (styleArray: Style[]) => {
  const textProps = {
    size: JELLO_TEXT_SIZES.M,
    level: '',
    weight: JELLO_TEXT_WEIGHTS.NORMAL,
    fontStyle: '',
  };
  if (styleArray.includes(SUB_HEADER)) {
    textProps.size = JELLO_TEXT_SIZES.M;
    textProps.level = JELLO_HEADINGS_LEVELS.H2;
    textProps.weight = JELLO_TEXT_WEIGHTS.BOLD;
  }
  if (styleArray.includes(ITALIC)) {
    textProps.weight = JELLO_TEXT_WEIGHTS.NORMAL;
    textProps.fontStyle = ITALIC;
  }
  if (styleArray.includes(BOLD)) {
    textProps.weight = JELLO_TEXT_WEIGHTS.BOLD;
  }

  return textProps;
};

const renderJelloText = (value: string, textProps) => {
  return (
    <jello-text
      size={textProps.size}
      level={textProps.level}
      weight={textProps.weight}
      style={{ fontStyle: textProps.fontStyle }}
    >
      {t(value)}
    </jello-text>
  );
};
const renderCustomText = (
  value: string | string[],
  textProps,
  isCustom: boolean,
  isBullet: boolean,
) => {
  const contentList = Array.isArray(value) ? value : [];

  if (isCustom) {
    return isBullet ? (
      <ul
        {...testId(WHAT_NEW_TEXT, UNORDERED_LIST)}
        className={styles.styledUL}
      >
        {contentList.map((text, textIndex) => {
          return (
            <li {...testId(WHAT_NEW_TEXT, `${textIndex}`)} key={textIndex}>
              <jello-text>{getLabel(t(text as string))}</jello-text>
            </li>
          );
        })}
      </ul>
    ) : (
      <jello-text {...testId(WHAT_NEW_TEXT, `${value}`)}>
        {getLabel(t(value))}
      </jello-text>
    );
  }

  if (!isCustom && isBullet) {
    return (
      <ul
        {...testId(WHAT_NEW_TEXT, UNORDERED_LIST)}
        className={styles.styledUL}
      >
        {contentList.map((text, textIndex) => {
          return (
            <li {...testId(WHAT_NEW_TEXT, `${textIndex}`)} key={textIndex}>
              {renderJelloText(text, textProps)}
            </li>
          );
        })}
      </ul>
    );
  } else {
    return <>{renderJelloText(value as string, textProps)}</>;
  }
};

interface MyTextProps {
  value: string | string[];
  style: Style[];
}

export const AnnouncementTextComponent: React.FunctionComponent<
  MyTextProps
> = ({ style, value }) => {
  const isCustom = style?.includes(CUSTOM);
  const isBullet = style?.includes(BULLET);
  const isCallout = style?.includes(CALLOUT);

  const textProps = getTextProps(style);

  return (
    <React.Fragment>
      <div
        {...testId(WHAT_NEW_TEXT, CONTAINER)}
        className={isCallout ? styles.callout : ''}
      >
        {renderCustomText(value, textProps, isCustom, isBullet)}
      </div>
    </React.Fragment>
  );
};

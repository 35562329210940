import { Action, PayloadAction } from 'src/app/store/app.types';
import {
  UnblindBodyData,
  UnblindPatientErrorResponse,
} from '../../../../services/patient/clinical-studies/interventional-clinical-studies/unblind-clinical-study-patient/unblind-clinical-study-patient.types';

export enum UnblindPatientActionType {
  UNBLIND_PATIENT_START = 'UNBLIND_PATIENT_START',
  UNBLIND_PATIENT_SUCCESS = 'UNBLIND_PATIENT_SUCCESS',
  UNBLIND_PATIENT_ERROR = 'UNBLIND_PATIENT_ERROR',
  CLEAR_UNBLIND_PATIENT_STATE = 'CLEAR_UNBLIND_PATIENT_STATE',
}

export type UnblindPatientStartAction = PayloadAction<
  UnblindPatientActionType.UNBLIND_PATIENT_START,
  string
>;

export type UnblindPatientSuccessAction = PayloadAction<
  UnblindPatientActionType.UNBLIND_PATIENT_SUCCESS,
  UnblindBodyData
>;

export type UnblindPatientErrorAction = PayloadAction<
  UnblindPatientActionType.UNBLIND_PATIENT_ERROR,
  UnblindPatientErrorResponse
>;

export type clearUnblindClinicalStudyPatientAction =
  Action<UnblindPatientActionType.CLEAR_UNBLIND_PATIENT_STATE>;

export type UnblindPatientActions =
  | UnblindPatientStartAction
  | UnblindPatientSuccessAction
  | UnblindPatientErrorAction
  | clearUnblindClinicalStudyPatientAction;

export type UnblindPatientState = {
  isUnblindingPatient: boolean;
  unblindPatientSuccessful: boolean;
  unblindPatientError: boolean;
};

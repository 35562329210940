import { connect } from 'react-redux';
import { selectManifestModuleName } from '../store/manifest-modules/manifestModules.selectors';
import { compose } from 'recompose';

import {
  selectPatientId,
  selectPatientFhirId,
} from '../../../domains/patient/store/patient/patient.selector';
import { dateRangeConnector } from '../../../domains/patient-dashboard/store/patient-date-range/patient-date-range.selector';

import { MODULE_NAME } from './reports.constants';
import { ReportsComponent } from './reports.component';
import {
  selectHasRebranding,
  selectJelloPermission,
} from '../../../domains/permissions/store/permissions.selectors';
import { selectCurrentDashboardLocation } from 'src/domains/general/widgets/store/hcp-dashboard.selectors';
import {
  selectEC6TimeFormat,
  selectEC6UserUnitMeasurement,
  selectIfusLang,
} from 'src/app/store/user/user.selectors';
import {
  selectCGMEndDate,
  selectCGMStartDate,
} from 'src/domains/patient-dashboards/cgm/store/selectors/cgm.selectors';

export const ReportsContainer = compose(
  connect((state) => ({
    patientId: selectPatientFhirId(state),
    legacyPatientId: selectPatientId(state),
    startDate: dateRangeConnector(state).startDate,
    endDate: dateRangeConnector(state).endDate,
    moduleName: selectManifestModuleName(MODULE_NAME)(state),
    jelloDs: selectJelloPermission(state),
    rebranding: selectHasRebranding(state),
    tabName: selectCurrentDashboardLocation(state),
    locale: selectIfusLang(state),
    timeFormat: selectEC6TimeFormat(state),
    unitMeasurement: selectEC6UserUnitMeasurement(state),
    cgmStartDate: selectCGMStartDate(state),
    cgmEndDate: selectCGMEndDate(state),
  })),
)(ReportsComponent);

import React, { Fragment } from 'react';

import { HorizontalAxis } from 'src/domains/diagnostics/components/graph-lib/graph-horizontal-axis.component';
import { colors } from 'src/app/styles/colors';
import { strokeWidth } from 'src/app/styles/stroke-width';
import { weight } from 'src/domains/diagnostics/components/fonts/weights/weight.style';

import { AXIS_FONT_SIZE, DAY_RANGE_AXIS } from './axis.constants';

const DayTick = ({
  tick,
  totalTicks,
  height,
  width,
  yDirection = -1,
  collapsed,
}) => {
  const rectangleHeight = height * DAY_RANGE_AXIS.DAY_RECT_HEIGHT;

  return (
    <React.Fragment>
      <rect
        x={
          tick.value +
          ((DAY_RANGE_AXIS.DAY_RECT_MARGIN / totalTicks) * width) / 2
        }
        y={yDirection * rectangleHeight}
        rx={DAY_RANGE_AXIS.RECT_RADIUS}
        ry={DAY_RANGE_AXIS.RECT_RADIUS}
        width={(DAY_RANGE_AXIS.DAY_RECT_WIDTH / totalTicks) * width}
        height={rectangleHeight}
        fill={tick.isWeekend ? colors.blueMarineAlpha15 : colors.clear}
      />
      <line
        x1={tick.value}
        y1={height * yDirection}
        x2={tick.value}
        y2={yDirection * height * DAY_RANGE_AXIS.TICK}
        strokeWidth={strokeWidth.one}
        stroke={colors.grayLight}
      />
      <text
        textAnchor="middle"
        x={tick.value + ((1 / totalTicks) * width) / 2}
        y={yDirection * height * DAY_RANGE_AXIS.DAY_TICK_OFFSET}
        fontSize={collapsed ? AXIS_FONT_SIZE - 2 : AXIS_FONT_SIZE}
      >
        {tick.label}
      </text>
    </React.Fragment>
  );
};

const MonthTick = ({
  tick,
  totalTicks,
  height,
  width,
  collapsed,
  x,
  yDirection = -1,
}) => (
  <React.Fragment>
    <rect
      x={tick.value - 2}
      y={(height / 2.2) * yDirection}
      width={2}
      height={height}
      fill="white"
    />
    <text
      textAnchor="start"
      x={tick.value}
      fontSize={collapsed ? AXIS_FONT_SIZE - 2 : AXIS_FONT_SIZE}
      fontWeight={weight.semiBold}
    >
      {tick.label}
    </text>
  </React.Fragment>
);

export const DayRangeAxis = ({
  x = 0,
  width,
  height,
  dayTicks,
  monthYearTicks,
  collapsed,
  yDirection = -1,
}) => (
  <Fragment>
    <line
      x1={x}
      y1={(yDirection * height) / 2.2}
      x2={x + width}
      y2={(yDirection * height) / 2.2}
      strokeWidth={strokeWidth.one}
      stroke={colors.grayLight}
    />
    <HorizontalAxis
      x={x}
      y={height / 2}
      width={width}
      height={height}
      Tick={(tick) => (
        <DayTick
          tick={tick}
          height={height}
          width={width}
          totalTicks={dayTicks.length}
          key={tick.value}
          collapsed={collapsed}
        />
      )}
      ticks={dayTicks}
      axisColor={colors.grayLight}
    />
    <HorizontalAxis
      x={x}
      y={AXIS_FONT_SIZE / 2}
      width={width}
      Tick={(tick) => (
        <MonthTick
          tick={tick}
          height={height}
          width={width}
          totalTicks={dayTicks.length}
          key={tick.value}
          collapsed={collapsed}
          x={x}
          yDirection={yDirection}
        />
      )}
      ticks={monthYearTicks}
      axisColor={colors.grayLight}
    />
  </Fragment>
);

import {
  Hba1cListState,
  ListHba1cActionActions,
  ListHba1cActionType,
} from './list-values-hba1c.types';

export const LIST_HBA1C_INITIAL_STATE: Hba1cListState = {
  fhirId: null,
  isFetchingHba1cList: false,
  hasData: false,
  isFetchingHba1cListError: false,
  hba1cInfo: null,
};

export const listValuesHba1cReducer = (
  state = LIST_HBA1C_INITIAL_STATE,
  action: ListHba1cActionActions,
): Hba1cListState => {
  switch (action.type) {
    case ListHba1cActionType.FETCH_HBA1C_LIST_START:
      return {
        ...LIST_HBA1C_INITIAL_STATE,
        isFetchingHba1cList: true,
        hasData: false,
      };
    case ListHba1cActionType.FETCH_HBA1C_LIST_SUCCESS:
      return {
        ...state,
        hba1cInfo: action.payload,
        isFetchingHba1cList: false,
        hasData: true,
      };
    case ListHba1cActionType.FETCH_HBA1C_LIST_ERROR:
      return {
        ...state,
        isFetchingHba1cListError: true,
      };
    default:
      return state;
  }
};

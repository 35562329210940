import React from 'react';
import { Switch } from 'react-router-dom';
import { RouterOutlet } from 'src/app/navigation/components/router-outlet';

import { ProtectedRoute } from 'src/shared/design-system/protected-route/protected-route.container';

import { ProfileBundle } from './bundles';

export const profileLinks = {
  changePassword: '/profile/change-password',
  changeSecurityQuestion: '/profile/change-security-question',
  editProfile: '/profile/edit',
};

export const ProfileRoutes = ({ path, ...props }) => (
  <RouterOutlet key={path} path={path}>
    <Switch>
      <ProtectedRoute path={path} component={ProfileBundle} {...props} />
    </Switch>
  </RouterOutlet>
);

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchPatientRequest } from 'src/domains/patient/store/patient/patient.action';
import {
  selectPatient,
  selectPatientFhirId,
  selectPatientId,
  selectPatientProfileType,
} from 'src/domains/patient/store/patient/patient.selector';
import {
  selectPatientPermissions,
  selectPatternsPermission,
  selectPermissions,
  selectPlatformPermission,
} from 'src/domains/permissions/store/permissions.selectors';
import { selectActiveDevices } from 'src/widgets/top-patient-bar/patient-info/patient-info.container';
import {
  hideTimePeriod,
  selectCGMSummary,
  selectHasCGMMeasurements,
  selectTotalMeasurements,
} from 'src/domains/patient-dashboards/cgm/store/selectors/cgm.selectors';
import { selectPatientHasMeasurementData } from 'src/domains/patient-dashboards/bg/store/bg.selectors';
import { selectUserProfile } from 'src/app/store/user/user.selectors';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import { getCGMSummaryRequest } from 'src/domains/patient-dashboards/cgm/store/cgm.actions';
import { JelloWrapperComponent } from './jello-wrapper.component';

const mapStateToProps = (state) => ({
  patient: selectPatient(state),
  patientId: selectPatientId(state),
  permissions: selectPermissions(state),
  patientPermissions: selectPatientPermissions(state),
  patientProfileType: selectPatientProfileType(state),
  activeDevices: selectActiveDevices(state),
  cgmSummary: selectCGMSummary(state),
  cgmHasMeasurements: selectHasCGMMeasurements(state),
  cgmTotalMeasurements: selectTotalMeasurements(state),
  bgmHasMeasurements: selectPatientHasMeasurementData(state),
  patternsPermissions: selectPatternsPermission(state),
  profile: selectUserProfile(state),
  fhirId: selectPatientFhirId(state),
  isPlatformUser: selectPlatformPermission(state),
  hideTimePeriod: hideTimePeriod(state),
});

const mapDispatchToProps = mapDispatchers({
  fetchPatient: fetchPatientRequest.start,
  getCGMSummary: getCGMSummaryRequest.start,
});

export const JelloWrapperContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JelloWrapperComponent),
);

import {
  drop,
  isEmpty,
  join,
  lensIndex,
  map,
  mergeAll,
  over,
  pickAll,
  pipe,
  prepend,
  split,
  toLower,
  toUpper,
} from 'ramda';

import { Redirect } from 'react-router-dom';

import { pass } from 'src/shared/utils/validation-helpers';

export const verifyPermission = (permissionsList) => (permission) =>
  !!permissionsList[transformPermission(permission)];

export const hasPermissions = ({ toValidate, current }) => {
  if (isEmpty(current)) {
    return null;
  }
  return toValidate.every(verifyPermission(current));
};

export const getPermissions = ({ current, permissions }) => {
  const matchedPermissions = pickAll(
    Object.keys(transformPermissions(permissions)),
    current,
  );
  return map((val) => pass(val), matchedPermissions);
};

const splitUnderScore = split('_');
const toTitleCase = map(pipe(toLower, over(lensIndex(0), toUpper), join('')));
const dropRole = drop(1);

export const toKeys = (key) => ({ [key]: true });
export const transformPermission = pipe(
  splitUnderScore,
  dropRole,
  toTitleCase,
  prepend('has'),
  join(''),
);
export const transformPermissions = (permissions) =>
  mergeAll(permissions.map(transformPermission).map(toKeys));

export const errorMessageRedirect = (route) => {
  // eslint-disable-next-line react/react-in-jsx-scope
  return <Redirect to={route} />;
};

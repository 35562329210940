import React from 'react';
import { filter, fromPairs, map, pipe, toPairs } from 'ramda';

import {
  AfterExerciseIcon,
  AppleEatenFullColorIcon,
  AppleFullColorIcon,
  BedIcon,
  BeforeExerciseIcon,
  BicycleIcon,
  CustomIcon,
  FastingIcon,
  HandPointIcon,
  HyperWarningIcon,
  HypoIcon,
  HypoSymptomsIcon,
  HypoWarningIcon,
  IllnessIcon,
  MealIcon,
  PillsIcon,
  PremenstrualIcon,
  RunningIcon,
  SnackIcon,
  StressIcon,
  UserDefinedAsteriskIcon,
} from 'src/shared/design-system/icons';

const bloodGlucoseIcons = {
  manuallyEntered: {
    iconComponent: <HandPointIcon height={15} width={20} />,
  },
  beforeMeal: {
    iconComponent: <AppleFullColorIcon width={12} height={14} />,
  },
  afterMeal: {
    iconComponent: <AppleEatenFullColorIcon width={8.5} height={15.5} />,
  },
  hypo: {
    iconComponent: <HypoIcon height={15} />,
  },
  hypoSymptoms: {
    iconComponent: <HypoSymptomsIcon height={15} />,
  },
  snack: {
    iconComponent: <SnackIcon height={15} />,
    indexNumber: 3,
  },
  bedTime: {
    iconComponent: <BedIcon height={15} />,
    indexNumber: 4,
  },
  fasting: {
    iconComponent: <FastingIcon height={15} />,
    indexNumber: 20,
  },
  beforeExercise: {
    iconComponent: <BeforeExerciseIcon height={15} />,
    indexNumber: 23,
  },
  afterExercise: {
    iconComponent: <AfterExerciseIcon height={15} />,
    indexNumber: 24,
  },
  stress: {
    iconComponent: <StressIcon height={15} />,
    indexNumber: 29,
  },
  illness: {
    iconComponent: <IllnessIcon height={15} />,
    indexNumber: 31,
  },
  oralMedication: {
    iconComponent: <PillsIcon height={15} />,
    indexNumber: 35,
  },
  userDefinedAsterisk: {
    iconComponent: <UserDefinedAsteriskIcon height={15} />,
    indexNumber: 36,
  },
  hypoWarning: {
    iconComponent: <HypoWarningIcon height={15} />,
    indexNumber: 71,
  },
  hyperWarning: {
    iconComponent: <HyperWarningIcon height={15} />,
    indexNumber: 72,
  },
  premenstrual: {
    iconComponent: <PremenstrualIcon height={15} />,
    indexNumber: 73,
  },
  otherMealTime: {
    iconComponent: <MealIcon height={15} />,
    indexNumber: 74,
  },
  exercise1: {
    iconComponent: <RunningIcon height={15} />,
    indexNumber: 75,
  },
  exercise2: {
    iconComponent: <BicycleIcon height={15} />,
    indexNumber: 76,
  },
  custom1: {
    iconComponent: <CustomIcon height={15} />,
    indexNumber: 81,
  },
  custom2: {
    iconComponent: <CustomIcon height={15} />,
    indexNumber: 82,
  },
  custom3: {
    iconComponent: <CustomIcon height={15} />,
    indexNumber: 83,
  },
};

export const bloodGlucoseIconsByName = pipe(
  toPairs,
  map(([name, { iconComponent }]) => [name, iconComponent]),
  fromPairs,
)(bloodGlucoseIcons);

export const bloodGlucoseIconNamesByIndex = pipe(
  toPairs,
  filter((p) => p[1].indexNumber),
  map(([name, { indexNumber }]) => [indexNumber, name]),
  fromPairs,
)(bloodGlucoseIcons);

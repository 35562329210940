import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import * as React from 'react';
import { selectIsSessionValid } from 'src/app/session/core/session/session.selectors';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FALLBACK_LANGUAGE } from './i18.constants';

export { translate } from './translate';

export const DEFAULT_NAMESPACE_I18N = 'translations';

const translations = ({ isValidSession }) => {
  React.useEffect(() => {
    if (
      isValidSession ||
      window.location.pathname === '/reset-password-professional'
    ) {
      import(
        /* webpackChunkName: "translations" */ '@roche/rdcs-rdcp-hcp-client-translations'
      ).then((l) => {
        i18n
          .use(LanguageDetector)
          .use(initReactI18next)
          .init({
            defaultNS: DEFAULT_NAMESPACE_I18N,
            detection: {
              order: ['localstorage'],
            },
            fallbackLng: l.FALLBACK_LOCALE,
            interpolation: {
              escapeValue: false,
              prefix: '[[',
              suffix: ']]',
            },
            ns: ['translations'],
            resources: l.locales,
          });
      });
    }
  }, [isValidSession]);
  return null;
};

export const TranlsationsComponent = compose(
  connect(
    createStructuredSelector({
      isValidSession: selectIsSessionValid,
    }),
  ),
)(translations);

export default i18n;

import React from 'react';

import { SvgIcon } from 'src/shared/design-system/icon/icon.component';

type Props = {
  height?: number,
  fillColor?: string,
  strokeColor?: string,
  dotColor?: string,
};

export const V3CalendarIcon = ({
  fillColor = '#E7EEFA',
  dotColor = '#508EE5',
  height = 24,
  strokeColor = '#003E7D',
}: Props) => {
  const originalWidth = 25;
  const originalHeight = 24;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      height={height}
      fillColor={fillColor}
      dotColor={dotColor}
      strokeColor={strokeColor}
      originalHeight={originalHeight}
      originalWidth={originalWidth}
      width={height * aspectRatio}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="SELECTED-Nav-/-TIME-RANGE"
          transform="translate(-1046.000000, -84.000000)"
        >
          <g id="Calendar" transform="translate(1036.000000, 76.000000)">
            <g id="Group-4">
              <g
                id="Icon/Primary/Calendar"
                transform="translate(4.000000, 5.000000)"
              >
                <g
                  id="Icon/menu"
                  transform="translate(10.418605, 10.046512)"
                ></g>
                <g id="Group" transform="translate(6.697674, 4.093023)">
                  <path
                    d="M2.23255814,3.34883721 L16.744186,3.34883721 C17.9771939,3.34883721 18.9767442,4.34838754 18.9767442,5.58139535 L18.9767442,7.81395349 L18.9767442,7.81395349 L0,7.81395349 L0,5.58139535 C-1.50999907e-16,4.34838754 0.999550326,3.34883721 2.23255814,3.34883721 Z"
                    id="Rectangle"
                    stroke={strokeColor}
                    strokeWidth="1.19069767"
                    fill={fillColor}
                    fillRule="nonzero"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M0,7.81395349 L18.9767442,7.81395349 L18.9767442,19.3488372 C18.9767442,20.581845 17.9771939,21.5813953 16.744186,21.5813953 L2.23255814,21.5813953 C0.999550326,21.5813953 1.50999907e-16,20.581845 0,19.3488372 L0,7.81395349 L0,7.81395349 Z"
                    id="Rectangle"
                    stroke={strokeColor}
                    strokeWidth="1.19069767"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    strokeLinejoin="round"
                  ></path>
                  <g
                    id="menuAsset-6"
                    transform="translate(3.720930, 10.790698)"
                    fill={dotColor}
                    fillRule="nonzero"
                  >
                    <circle
                      id="Oval-2"
                      cx="1.41395349"
                      cy="1.41395349"
                      r="1.14604651"
                    ></circle>
                    <circle
                      id="Oval-Copy-2"
                      cx="1.41395349"
                      cy="6.12465116"
                      r="1.14604651"
                    ></circle>
                    <circle
                      id="Oval-Copy-3"
                      cx="5.75255814"
                      cy="1.41395349"
                      r="1.14604651"
                    ></circle>
                    <circle
                      id="Oval-Copy-5"
                      cx="5.75255814"
                      cy="6.12465116"
                      r="1.14604651"
                    ></circle>
                    <circle
                      id="Oval-Copy-4"
                      cx="10.0911628"
                      cy="1.41395349"
                      r="1.14604651"
                    ></circle>
                    <circle
                      id="Oval-Copy-6"
                      cx="10.0911628"
                      cy="6.12465116"
                      r="1.14604651"
                    ></circle>
                  </g>
                  <line
                    x1="5.02325581"
                    y1="0.186046512"
                    x2="5.02325581"
                    y2="5.39534884"
                    id="Line-8"
                    stroke="#003E7D"
                    strokeWidth="1.19069767"
                    fill={strokeColor}
                    fillRule="nonzero"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="14.3255814"
                    y1="0.186046512"
                    x2="14.3255814"
                    y2="5.39534884"
                    id="Line-8"
                    stroke={strokeColor}
                    strokeWidth="1.19069767"
                    fill="#E7EEFA"
                    fillRule="nonzero"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

import React from 'react';

import { getMealIcon } from 'src/domains/diagnostics/meal-times/meal-times.util';
import { MealTime } from 'src/domains/diagnostics/meal-times/meal-times.style';
import { colors } from 'src/app/styles/colors';

import { LogbookCellBlock } from 'src/domains/diagnostics/components/table/logbook-cell-block/logbook-cell-block.component';
import { LogbookCellBlocksContainer } from 'src/domains/diagnostics/components/table/logbook-cell-blocks-container/logbook-cell-blocks-container.component';

const borderBottom = {
  color: colors.blueMarine,
};

const iconStyles = {
  withBorder: true,
  height: 28,
  iconColor: colors.white,
  borderFillColor: colors.blueMarine,
  borderColor: 'none',
};

export const MealTimeIcon = ({ keyText, mealTime, alignItems }) => (
  <LogbookCellBlocksContainer borderBottom={borderBottom} m={'0 0.375rem'}>
    <LogbookCellBlock flex={1}>
      <MealTime key={keyText} alignItems={alignItems} p={0}>
        {getMealIcon(mealTime, iconStyles)}
      </MealTime>
    </LogbookCellBlock>
  </LogbookCellBlocksContainer>
);

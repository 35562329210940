import { getConfig } from '@roche/roche-common';
import { qualtricsScriptContent } from './qualtrics-script';

const {
  REACT_APP_DATA_DOG_APPLICATION_ID,
  REACT_APP_DATA_DOG_CLIENT_TOKEN,
  REACT_APP_DATA_DOG_SITE,
  REACT_APP_DATA_DOG_SERVICE,
  REACT_APP_DATA_DOG_ENV,
  REACT_APP_DATA_DOG_SAMPLERATE,
  REACT_APP_DATA_DOG_TRACKINTERACTIONS,
  REACT_APP_DATA_DOG_REPLAY_SAMPLE_RATE,
  REACT_APP_DATA_DOG_TRACK_SESSION_SUBDOMAINS,
  REACT_APP_DATA_DOG_ALLOW_TRACKING_ORIGINS,
  REACT_APP_VERSION,
  REACT_APP_CONSENTS_DOMAIN_ID,
  REACT_APP_QUALTRICS_SURVEY_ID,
  REACT_APP_QUALTRICS_SURVEY_URL,
} = getConfig();

const authSuccessPath = new RegExp('auth/success[?]code=[^&]*');
const redactedPath = 'auth/success?code=REDACTED';

export const appendOneTrustScript = ({ onLoad, onError }): void => {
  if (!REACT_APP_CONSENTS_DOMAIN_ID) {
    return;
  }

  if (!document.getElementById('ot-autoblock-snippet')) {
    const otAutoBlock = document.createElement('script');
    otAutoBlock.setAttribute(
      'src',
      `https://cdn.cookielaw.org/consent/${REACT_APP_CONSENTS_DOMAIN_ID}/OtAutoBlock.js`,
    );
    otAutoBlock.setAttribute('id', 'ot-autoblock-snippet');
    otAutoBlock.type = 'text/javascript';
    document.head.appendChild(otAutoBlock);
  }

  if (!document.getElementById('ot-sdk-snippet')) {
    const otStub = document.createElement('script');
    otStub.setAttribute(
      'src',
      'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    );
    otStub.setAttribute('charset', 'UTF-8');
    otStub.setAttribute('data-document-language', 'true');
    otStub.setAttribute('data-domain-script', REACT_APP_CONSENTS_DOMAIN_ID);
    otStub.setAttribute('id', 'ot-sdk-snippet');
    otStub.type = 'text/javascript';
    otStub.onload = onLoad;
    otStub.onerror = onError;
    document.head.appendChild(otStub);
  }

  if (!document.getElementById('ot-wrapper')) {
    const otInit = document.createElement('script');
    otInit.setAttribute('id', 'ot-wrapper');
    otInit.type = 'text/javascript';
    otInit.textContent = 'function OptanonWrapper(){ }';
  }
};

function handleLoadError(children: any) {
  document.head.removeChild(children);
}

function handleDataDogLoad() {
  const { DD_RUM }: any = window;
  if (DD_RUM) {
    DD_RUM.init({
      applicationId: REACT_APP_DATA_DOG_APPLICATION_ID,
      clientToken: REACT_APP_DATA_DOG_CLIENT_TOKEN,
      site: REACT_APP_DATA_DOG_SITE,
      service: REACT_APP_DATA_DOG_SERVICE,
      env: REACT_APP_DATA_DOG_ENV,
      version: REACT_APP_VERSION,
      sampleRate: +REACT_APP_DATA_DOG_SAMPLERATE,
      allowedTracingOrigins: REACT_APP_DATA_DOG_ALLOW_TRACKING_ORIGINS
        ? [REACT_APP_DATA_DOG_ALLOW_TRACKING_ORIGINS]
        : [],
      replaySampleRate: +REACT_APP_DATA_DOG_REPLAY_SAMPLE_RATE,
      trackSessionAcrossSubdomains:
        REACT_APP_DATA_DOG_TRACK_SESSION_SUBDOMAINS === 'true',
      trackInteractions: REACT_APP_DATA_DOG_TRACKINTERACTIONS === 'true',
      silentMultipleInit: true,
      beforeSend: (event) => {
        // Remove Access Code from the event's URLs
        event.view.url = event.view.url.replace(authSuccessPath, redactedPath);
        event.view.referrer = event.view.referrer.replace(
          authSuccessPath,
          redactedPath,
        );
        if (event.resource && event.resource.url) {
          event.resource.url = event.resource.url.replace(
            authSuccessPath,
            redactedPath,
          );
        }
        if (event.error && event.error.resource && event.error.resource.url) {
          event.error.resource.url = event.error.resource.url.replace(
            authSuccessPath,
            redactedPath,
          );
        }
      },
    });
  }
}

export const appendDataDogScript = (): void => {
  if (
    !REACT_APP_DATA_DOG_SITE &&
    !REACT_APP_DATA_DOG_APPLICATION_ID &&
    !REACT_APP_DATA_DOG_CLIENT_TOKEN
  ) {
    return;
  }

  if (!document.getElementById('dd-snippet')) {
    const dd = document.createElement('script');
    dd.setAttribute('defer', 'defer');
    dd.setAttribute(
      'src',
      `https://www.datadoghq-browser-agent.com/datadog-rum-v4.js`,
    );
    dd.setAttribute('id', 'dd-snippet');
    dd.type = 'text/javascript';
    dd.async = true;
    dd.addEventListener('load', () => handleDataDogLoad(), false);
    dd.addEventListener('error', () => handleLoadError(dd), false);
    document.head.appendChild(dd);
  } else {
    // runs if script is already loaded DOM
    handleDataDogLoad();
  }
};

export const appendQualtricsScript = (): void => {
  if (!REACT_APP_QUALTRICS_SURVEY_ID && !REACT_APP_QUALTRICS_SURVEY_URL) {
    return;
  }

  if (!document.getElementById('qualtrics-snippet')) {
    const qualtricsScript = document.createElement('script');
    qualtricsScript.setAttribute('id', 'qualtrics-snippet');
    qualtricsScript.type = 'text/javascript';

    qualtricsScript.text = qualtricsScriptContent;

    qualtricsScript.setAttribute('defer', 'defer');
    qualtricsScript.async = true;

    document.head.appendChild(qualtricsScript);
  }

  const qualtricsDiv = document.createElement('div');
  qualtricsDiv.setAttribute('id', REACT_APP_QUALTRICS_SURVEY_ID);
  qualtricsDiv.innerHTML = `<!--DO NOT REMOVE-CONTENTS PLACED HERE-->`;
  document.body.append(qualtricsDiv);
};

import {
  EnrollPatientToStudyActionType,
  EnrollPatientToStudyActions,
  EnrollPatientToStudyState,
} from './enroll-patient-to-study.types';

export const enrollPatientToStudyInitialState: EnrollPatientToStudyState = {
  isEnrollingPatientToStudy: false,
  enrollPatientToStudySuccessful: false,
  enrollPatientToStudyError: false,
};

export const enrollPatientToStudyReducer = (
  state = enrollPatientToStudyInitialState,
  action: EnrollPatientToStudyActions,
): EnrollPatientToStudyState => {
  switch (action.type) {
    case EnrollPatientToStudyActionType.ENROLL_PATIENT_TO_STUDY_START:
      return {
        ...state,
        isEnrollingPatientToStudy: true,
        enrollPatientToStudySuccessful: false,
        enrollPatientToStudyError: false,
      };

    case EnrollPatientToStudyActionType.ENROLL_PATIENT_TO_STUDY_SUCCESS:
      return {
        isEnrollingPatientToStudy: false,
        enrollPatientToStudySuccessful: true,
        enrollPatientToStudyError: false,
      };

    case EnrollPatientToStudyActionType.ENROLL_PATIENT_TO_STUDY_ERROR:
      return {
        isEnrollingPatientToStudy: false,
        enrollPatientToStudySuccessful: false,
        enrollPatientToStudyError: true,
      };

    case EnrollPatientToStudyActionType.CLEAR_ENROLL_PATIENT_TO_STUDY:
      return enrollPatientToStudyInitialState;

    default:
      return state;
  }
};

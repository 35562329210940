export const TEST_ID_DEMO_PROFILE = {
  BASE: 'jello-patient-form',
  CHIP: 'chips',
  SEARCH_BOX: 'search-box',
  BOX: 'box',
  TEXT: 'text',
  CANCEL: 'Cancel',
  SAVE: 'Save',
};
export const DEMO_PATIENT_PROFILE = 'patientDemoProfile';
export const DEMO_PROFILE_PLACEHOLDER = `${DEMO_PATIENT_PROFILE}.searchbox.placeholder`;
export const DEMO_PROFILES = `${DEMO_PATIENT_PROFILE}.searchbox.label`;
export const DEMO_PROFILE_TITLE = `${DEMO_PATIENT_PROFILE}.title`;
export const DEMO_PROFILE_CONTENT = `${DEMO_PATIENT_PROFILE}.infoMessage`;
export const DEMO_PROFILE_WARNING_TITLE = `${DEMO_PATIENT_PROFILE}.warning.title`;
export const DEMO_PROFILE_WARNING_CONTENT = `${DEMO_PATIENT_PROFILE}.warning.content`;
export const SAVE_BUTTON_TEXT = 'createPatientWithPlatform.saveButton';
export const CANCEL_BUTTON_TEXT = 'createPatientWithPlatform.cancelButton';
export const EDIT_DEMO_PATIENT_PROFILES_SUCCESS =
  'editPatient.alertEditSuccess.title';
export const EDIT_DEMO_PATIENT_PROFILES_ERROR =
  'editPatientWithFhir.errorModal.edition.title';
export const DEMO_PROFILE = 'demoProfile';

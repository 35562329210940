import * as React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from 'src/shared/design-system/protected-route/protected-route.container';
import {
  PERMISSIONS,
  PATIENT_PERMISSIONS,
} from 'src/domains/permissions/store/permissions.constants';
import { AugmentedAdvancedIndicatorsBundleContainer } from 'src/domains/indicators/bundles';

export const indicatorsLinks = {
  main: '/patients/:id/advanced-indicators',
};

export const IndicatorsRoutes = () => {
  const isNotBlindedPatient = (patientPermissions) => {
    return patientPermissions?.includes(PATIENT_PERMISSIONS.BLINDED_STUDY)
      ? false
      : true;
  };

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={indicatorsLinks.main}
        hasPermissions={[
          PERMISSIONS.ADVANCED_INDICATORS,
          PERMISSIONS.TIME_BLOCKS_MANAGEMENT,
          PERMISSIONS.TREATMENT_LIST,
          PERMISSIONS.USER_SHOW_GRAPHIC_CONFIG,
          PERMISSIONS.PATIENT_DEVICES,
        ]}
        accessConditions={[
          ({ patientPermissions }) => isNotBlindedPatient(patientPermissions),
        ]}
        validationRedirectPath={'/patients/:id'}
        component={AugmentedAdvancedIndicatorsBundleContainer}
      />
    </Switch>
  );
};

import {
  createAction,
  createPayloadAction,
} from '../../../../../../app/store/app.actions';

import {
  ClearsharingCodeCorrelateAction,
  CorrelateSharingCodeActionType,
  CorrelateSharingCodeErrorAction,
  CorrelateSharingCodePayload,
  CorrelateSharingCodeResponse,
  CorrelateSharingCodeStartAction,
  CorrelateSharingCodeSuccessAction,
} from './correlate-sharing-code.actions.types';

export const correlateSharingCodeStart = (
  payload: CorrelateSharingCodePayload,
): CorrelateSharingCodeStartAction =>
  createPayloadAction(
    CorrelateSharingCodeActionType.CORRELATE_SHARING_CODE_START,
    payload,
  );

export const correlateSharingCodeSuccess = (
  payload: CorrelateSharingCodeResponse,
): CorrelateSharingCodeSuccessAction =>
  createPayloadAction(
    CorrelateSharingCodeActionType.CORRELATE_SHARING_CODE_SUCCESS,
    payload,
  );

export const correlateSharingCodeError = (
  payload: CorrelateSharingCodeResponse,
): CorrelateSharingCodeErrorAction => ({
  type: CorrelateSharingCodeActionType.CORRELATE_SHARING_CODE_ERROR,
  payload,
});

export const clearsharingCodeCorrelateAction =
  (): ClearsharingCodeCorrelateAction =>
    createAction(
      CorrelateSharingCodeActionType.CLEAR_CORRELATE_SHARING_CODE_DATA,
    );

import React from 'react';

import { JelloIcon } from 'src/shared/design-system/jello-icon/jello-icon.component';

import { CardMinimizerButton } from './card-minimizer.style';
import { JELLO_ICON_NAMES } from 'src/app/app.jello.constants';

type Props = {
  link: string,
};

export const CardMinimizer = ({ link }: Props) => (
  <CardMinimizerButton to={link}>
    <JelloIcon iconName={JELLO_ICON_NAMES.MINIMIZE} size={'XS'} />
  </CardMinimizerButton>
);

export const mockCurrentUser = {
  resultDescription: 'getUserInfoOK',
  model: {
    statusLogin: 'SUCCESS',
    data: {
      id: 305456,
      name: 'Gon',
      surname: 'm v',
      surname2: null,
      address: {
        id: 305457,
        address: '',
        postalCode: '',
        city: '',
        province: '',
        country: {
          id: 1,
          name: 'Spain',
          isoCode: 'es',
          labelProperty: 'configuration.countries.es',
          labelText: null,
          language: {
            id: 1,
            languageId: '1',
            labelProperty: 'enum.Spanish',
            labelText: null,
            isoCode: 'es',
            languageCountryIsoCode: 'ES',
            locale: 'es-ES',
            active: true,
            key: '1',
            value: null,
          },
          timeZone: 'Europe/Madrid',
          profilesList: null,
          currency: 'EURO',
          prefix: '+34',
          timeFormat: 'AM/PM',
          key: '1',
          value: null,
        },
      },
      fullname: 'Gon m v ',
      inactivate: false,
      gender: 'UNSPECIFIED',
      phone: null,
      birthday: '1989-03-02T00:00:00+0000',
      email: 'profesional.g.maduenodev@yopmail.com',
      loginId: 305458,
      language: 'English',
      languageId: 3,
      languageIsoCode: 'en',
      languageCountryIsoCode: 'GB',
      locale: 'en-GB',
      role: 'PROFESSIONAL',
      departmentProfileId: 305453,
      profile: 'HCP_MASTER',
      countryId: 1,
      inactivateReason: null,
      inactivateComment: null,
      departmentId: 305451,
      centerId: 305443,
      areaId: 39,
      locked: false,
      lastAccess: '2021-04-12T13:58:20+0000',
      passwordReset: true,
      viewNotifications: false,
      countryIsoCode: 'es',
      departmentName: 'DepartamentoGonzalo',
      centerName: 'CentroGonzalo',
      centerLegalName: 'CentroGonzalo',
      accessEnum: null,
      profileId: 71,
      gigyaUid: 'bfdb63c22b954b08a6403a75f1298ad6',
      activeInGigya: null,
      hcpIsAccessible: true,
      unitMeasurement: 'mg/dL',
      fhirId: null,
      centerTimezone: 'Europe/Madrid',
      noPhone: false,
      fakeMail: false,
      headerWelcome: 'Gonzalo m v',
      languageIsoCodeComplete: 'en_GB',
    },
  },
};

import React, { Fragment } from 'react';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { VerticalAxis } from 'src/domains/diagnostics/components/graph-lib/graph-vertical-axis.component';
import { colors } from 'src/app/styles/colors';
import { strokeWidth } from 'src/app/styles/stroke-width';

import { AXIS_FONT_SIZE, TEXT_DELTA_Y, X_AXIS_RIGHT } from './axis.constants';

export const CarbohydratesAxisComponent = ({ y, width, height, ticks, t }) => (
  <Fragment>
    <VerticalAxis
      x={0}
      y={y}
      height={height}
      Tick={(tick) => (
        <Fragment key={tick.value}>
          <line
            x1={0}
            x2={width * X_AXIS_RIGHT.TICK_END}
            y1={tick.value}
            y2={tick.value}
            strokeWidth={strokeWidth.one}
            stroke={tick.color ? tick.color : colors.silverDark}
          />
          <text
            x={width * X_AXIS_RIGHT.TEXT_OFFSET}
            y={tick.value}
            textAnchor="start"
            alignmentBaseline="text-after-edge"
            fill={tick.color ? tick.color : colors.black}
            fontSize={AXIS_FONT_SIZE}
            dy={TEXT_DELTA_Y}
          >
            {tick.label}
          </text>
        </Fragment>
      )}
      ticks={ticks}
    />
    <text
      x={width * (1 - X_AXIS_RIGHT.TEXT_OFFSET)}
      y={(height - height * X_AXIS_RIGHT.TEXT_OFFSET) * -1}
      textAnchor="middle"
      fontSize={AXIS_FONT_SIZE}
      transform={`rotate(90, ${width * (1 - X_AXIS_RIGHT.TEXT_OFFSET)}, ${
        (height - height * X_AXIS_RIGHT.TEXT_OFFSET) * -1
      })`}
    >
      {`${t('graphs.detailGraph.carbohydratesInG')}`}
    </text>
  </Fragment>
);

export const CarbohydratesAxis = withTranslation(CarbohydratesAxisComponent);

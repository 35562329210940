export const TABS_ID = 'edit-patient-tabs';
export const TABS_IDS = {
  profile: 'editPatient.profile',
  blocks: 'editPatient.timeBlocks',
  ranges: 'editPatient.glucoseTargetRanges',
  devices: 'editPatient.devices',
  deactivate: 'editPatient.deactivatePatient',
  clinicalStudy: 'editPatient.clinicalStudy',
  demoProfile: 'editPatient.demoProfile',
};

export const EDIT_PATIENT = {
  PROFILE_AND_SHARING_STATUS: TABS_IDS.profile,
  TIME_BLOCKS: TABS_IDS.blocks,
  GLUCOSE_TARGET_RANGES: TABS_IDS.ranges,
  DEVICES: TABS_IDS.devices,
  DEACTIVATE_PATIENT: TABS_IDS.deactivate,
  CLINICAL_STUDY: TABS_IDS.clinicalStudy,
  DEMO_PROFILE: TABS_IDS.demoProfile,
};

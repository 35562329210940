import { DateTime } from 'luxon';
import { compose, uniq } from 'ramda';

import { TARGET_RANGES_GET_REQUEST } from 'src/widgets/target-ranges/store/target-ranges.actions';

import {
  CGM_CLEAR_DATES,
  CGM_SET_DATES,
  CGM_SET_SELECTED_DATE,
  GET_CGM_CLINCAL_DATA,
  GET_CGM_SUMMARY,
} from './cgm.actions';

import {
  convertJSDateGMT,
  convertStringToJSDate,
  isDateStringValid,
  subtractDays,
  toEndOfDay,
  toISO,
  toStartOfDay,
} from 'src/shared/utils/date';

export type CGMSummary = {
  startDate: string | null;
  endDate: string | null;
  firstMeasurementDate: string | null;
  lastMeasurementDate: string | null;
  range?: any;
};

export type CGMMeasurementPerDay = {
  intervalFirstMeasurementDay: string;
  intervalLastMeasurementDay: string;
  intervalTotalMeasurementDay: number;
  interval: number;
};

export type CGMClinicalRawDatum = {
  glucose: number | string;
  date: string | null;
  rangeType?: string;
};

export type CGMClinicalData = {
  rawData?: CGMClinicalRawDatum[];
  totalMeasurements: number;
  glucoseSummation: number;
  intervals: CGMInterval;
  days: {
    [key: string]: CGMDataDay[];
  };
  daysStats: {
    [key: string]: CGMStatDataDay;
  };
};

export type CGMInterval = {
  interval: number;
  unit: string;
  intervalTotalMeasurements: number;
  intervalFirstMeasurement: string;
  intervalLastMeasurement: string;
  intervalDays: CGMIntervalDay;
}[];

export type CGMIntervalDay = { [key: string]: CGMMeasurementPerDay };

export type CGMDataDay = {
  hour: string;
  totalvalues: number;
  values: CGMMeasurement[];
};

export type CGMStatDataDay = {
  summationDayValues: number;
  totalDayMeasurement: number;
};

export type CGMMeasurement = {
  glucoseValue: number;
  timestamp: string;
  date?: DateTime;
  rangeType: string;
};

type CGMState = {
  summary: CGMSummary;
  measurements: CGMClinicalData;
  pristine: boolean;
  errors: string[];
  cgmClinicalDataFinish: boolean;
  selectedDate?: string;
};

export const INITIAL_STATE: CGMState = {
  summary: {
    startDate: '',
    endDate: '',
    firstMeasurementDate: '',
    lastMeasurementDate: '',
    range: 14,
  },
  measurements: {
    rawData: [],
    intervals: [],
    totalMeasurements: 0,
    glucoseSummation: 0,
    days: {},
    daysStats: {},
  },
  pristine: true,
  cgmClinicalDataFinish: false,
  errors: [],
};

const returnNullIfInvalidDateString = (dateString) =>
  isDateStringValid(dateString) ? convertStringToJSDate(dateString) : null;

const validateAndTransformDates = (dates) =>
  dates.map(returnNullIfInvalidDateString);

const initialStateReducer = () => {
  return INITIAL_STATE;
};

const getCgmSummaryStartReducer = initialStateReducer;

const getCgmSummarySuccessReducer = (state, action) => {
  const THIRTEEN_DAYS = 13;

  const { firstMeasurementDate, lastMeasurementDate } = action.payload;

  const [lastMeasurement] = validateAndTransformDates([lastMeasurementDate]);

  const startDate = compose(
    toISO,
    toStartOfDay,
    subtractDays(THIRTEEN_DAYS),
    convertJSDateGMT,
  )(lastMeasurement || new Date());

  const endDate = compose(
    toISO,
    toEndOfDay,
    convertJSDateGMT,
  )(lastMeasurement || new Date());

  return {
    ...state,
    summary: {
      startDate,
      endDate,
      firstMeasurementDate,
      lastMeasurementDate,
      range: null,
    },
    pristine: false,
  };
};

const cgmSetDatesReducer = (state, action) => {
  const { summary } = state;
  const startDateParsed = action.payload.startDate.toISOString();
  const endDateParsed = action.payload.endDate.toISOString();
  const range = action.payload.range;

  return {
    ...state,
    summary: {
      ...summary,
      startDate: startDateParsed,
      endDate: endDateParsed,
      range,
    },
    cgmClinicalDataFinish: false,
  };
};

const cgmClearDatesReducer = initialStateReducer;

const getCgmClinicalDataStartReducer = (state) => {
  return {
    ...state,
    measurements: INITIAL_STATE.measurements,
  };
};

const cgmSetSelectedDateReducer = (state, action) => {
  return {
    ...state,
    selectedDate: action.payload.selectedDate,
  };
};

const getCgmClinicalDataSuccessReducer = (state, action) => {
  const {
    days,
    daysStats,
    glucoseSummation,
    intervals,
    rawData,
    totalMeasurements,
  } = action.payload;

  return {
    ...state,
    pristine: false,
    measurements: {
      days,
      daysStats,
      glucoseSummation,
      intervals,
      rawData,
      totalMeasurements,
    },
    cgmClinicalDataFinish: true,
  };
};

const generalErrorReducer = (state, action) => {
  const errors = uniq([action.type, ...state.errors]);

  return {
    ...INITIAL_STATE,
    errors,
  };
};

const getCgmSummaryErrorReducer = generalErrorReducer;
const targetRangesSetRequestErrorReducer = generalErrorReducer;

export const continuousMonitoringReducer = (
  state = INITIAL_STATE,
  action,
): CGMState => {
  switch (action.type) {
    case GET_CGM_SUMMARY.START:
      return getCgmSummaryStartReducer();

    case GET_CGM_SUMMARY.SUCCESS:
      return getCgmSummarySuccessReducer(state, action);

    case CGM_SET_DATES:
      return cgmSetDatesReducer(state, action);

    case CGM_CLEAR_DATES:
      return cgmClearDatesReducer();

    case GET_CGM_CLINCAL_DATA.START:
      return getCgmClinicalDataStartReducer(state);

    case CGM_SET_SELECTED_DATE:
      return cgmSetSelectedDateReducer(state, action);

    case GET_CGM_CLINCAL_DATA.SUCCESS:
      return getCgmClinicalDataSuccessReducer(state, action);

    case GET_CGM_SUMMARY.ERROR:
      return getCgmSummaryErrorReducer(state, action);

    case TARGET_RANGES_GET_REQUEST.ERROR:
      return targetRangesSetRequestErrorReducer(state, action);

    default:
      return state;
  }
};

import { testId } from '@roche/roche-common';
import * as React from 'react';
import { HELP, ID } from '../../whats-new.constants';
export const WhatsNewMenuItem = ({ children, handleClick }) => {
  return (
    <div onClick={handleClick} {...testId(ID, HELP)}>
      {children}
    </div>
  );
};

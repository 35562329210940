import {
  saveUserUnitLoaderImpl,
  saveUserUnitServiceImpl,
  saveUserUnitTransformImpl,
} from './save-user-units.service';
import { saveUserUnitResponseMock } from './save-user-units.mock';
import { SaveUserUnitsResponse } from './save-user-units.types';

const mockPost = (): Promise<SaveUserUnitsResponse> =>
  Promise.resolve(saveUserUnitResponseMock);

export const SaveUserUnitsFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockPost : saveUserUnitLoaderImpl;
  return saveUserUnitServiceImpl(loader, saveUserUnitTransformImpl);
};

import { filter, map, pipe } from 'ramda';
import {
  BOLUS_TYPE_ICONS,
  EXTENDED_MULTIWAVE_DEFAULT_BOLUS,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { BOLUS_TYPE } from 'src/domains/diagnostics/store/constants';
import { getMsFromTimeString } from '../../../time';

export const toBolusShape = ({
  date,
  value,
  remark,
  registerType,
  bolusType,
}) => ({
  date,
  bolusValue: value,
  bolusRemark: remark,
  bolusRegisterType: registerType,
  bolusType,
});

export const createBolusObject = (registerTypeString) =>
  pipe(filter(keepBolusOfRegisterType(registerTypeString)), map(toBolusShape));

const keepBolusOfRegisterType =
  (registerTypeString) =>
  ({ registerType }) =>
    registerType === registerTypeString;
export const getBolusTypeIcon = (measurement) => {
  const measurementBolusType =
    measurement.bolusType && measurement.bolusType.toLowerCase();

  const bolusTypeIcons = {
    std: BOLUS_TYPE_ICONS.STANDARD,
    scr: BOLUS_TYPE_ICONS.QUICK,
    ext: BOLUS_TYPE_ICONS.EXTENDED,
    mul: BOLUS_TYPE_ICONS.MULTIWAVE,
  };

  return bolusTypeIcons[measurementBolusType] || BOLUS_TYPE_ICONS.EMPTY;
};
export const parseStandardOrQuickBolus = (
  measurement,
  totalTime,
  startDate,
  insulinYMax,
) => {
  const { overlappingBolusWithGlucose } = measurement;
  const start = startDate.startOf('day');
  const x = ((measurement.date - start) % totalTime) / totalTime;
  // bolus that overlaps with glucose exact timestamp not visible HCPWGRAPHS-1460, HCPWGRAPHS-1480
  const lineHeight = overlappingBolusWithGlucose
    ? 0
    : measurement.bolusValue / insulinYMax;

  return { x, lineHeight };
};
const extensionPeriodInTimeString = (bolusRemark) =>
  bolusRemark
    ? bolusRemark.match(/[0-9]{1,2}:[0-9]{2,}/g)[0]
    : EXTENDED_MULTIWAVE_DEFAULT_BOLUS;
const parseExtendedBolus = (measurement, totalTime, startDate, insulinYMax) => {
  const start = startDate.startOf('day');
  const x = ((measurement.date - start) % totalTime) / totalTime;
  const rectWidth =
    getMsFromTimeString(extensionPeriodInTimeString(measurement.bolusRemark)) /
    totalTime;
  const rectHeight = measurement.bolusValue / insulinYMax;

  return { x, rectWidth, rectHeight };
};
const parseMultiwaveBolus = (
  measurement,
  totalTime,
  startDate,
  insulinYMax,
) => {
  const { overlappingBolusWithGlucose } = measurement;
  const bolusRemark = measurement.bolusRemark
    ? measurement.bolusRemark
    : EXTENDED_MULTIWAVE_DEFAULT_BOLUS;
  const extensionValue = parseFloat(bolusRemark.split('/')[0]);

  const start = startDate.startOf('day');
  const x = ((measurement.date - start) % totalTime) / totalTime;
  const rectWidth =
    getMsFromTimeString(extensionPeriodInTimeString(bolusRemark)) / totalTime;
  // bolus that overlaps with glucose exact timestamp not visible HCPWGRAPHS-1460, HCPWGRAPHS-1480
  const lineHeight = overlappingBolusWithGlucose
    ? 0
    : measurement.bolusValue / insulinYMax;
  const rectHeight = (measurement.bolusValue - extensionValue) / insulinYMax;

  return { x, lineHeight, rectWidth, rectHeight };
};
export const bolusTypeToParseFunctionMap = {
  [BOLUS_TYPE.QUICK]: parseStandardOrQuickBolus,
  [BOLUS_TYPE.STANDARD]: parseStandardOrQuickBolus,
  [BOLUS_TYPE.EXTENDED]: parseExtendedBolus,
  [BOLUS_TYPE.MULTIWAVE]: parseMultiwaveBolus,
};

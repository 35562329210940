import React from 'react';
import { Bundle } from 'src/app/navigation/bundle';

export const TherapyBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { TherapyContainer } = await import(
        /* webpackChunkName: "therapy" */ './therapy.container'
      );
      return TherapyContainer;
    }}
    bundleDidLoad={(TherapyContainer) => <TherapyContainer {...props} />}
  />
);

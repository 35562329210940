export const mockProfessional = {
  firstName: 'Name',
  surname: 'Surname',
  language: 1,
  email: 'email@email.es',
  hcpId: '1234',
  departmentProfileId: NaN,
  countryId: 2,
  openConnectivity: 'false',
  dateOfBirthDay: '01',
  dateOfBirthMonth: '12',
  dateOfBirthYear: '2020',
  phone: '609876564',
};

export const mockProfileTypes = [
  {
    id: 9,
    departmentProfileId: NaN,
    department: {
      id: 1,
      name: 'Hospital Endocrinologia',
      sapCodePayer: 'sapcodepy_dp1',
      sapCodeClient: '',
    },
    profile: {
      id: 71,
      name: '',
      role: 'PROFESSIONAL',
      label: 'HCP Master',
      code: 'HCP_MASTER',
      mandatory: true,
      departmentProfileId: 1,
    },
  },
];

export const mockProfessionalBaseObj = {
  name: 'Name',
  surname: 'Surname',
  languageId: 1,
  email: 'email@email.es',
  professionalNumber: '1234',
  departmentProfileId: NaN,
  countryId: 2,
  grantRevokeFunctDTO: {
    grantFunctionalities: [],
    revokeFunctionalities: ['OPEN_CONNECTIVITY_DATA_DOWN'],
  },
  birthday: '2020-12-01',
  phone: '609876564',
};

export const mockCreateProfessionalResponse = {
  resultDescription: 'createdProfessionalOK',
  model: 'professionalId:294395',
};

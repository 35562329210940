import { createStructuredSelector } from 'reselect';
import { selectIsSignedOut } from 'src/app/session/core/session/session.selectors';
import { selectIsOidcSessionValid } from 'src/app/session/core/oidc/oidc.selectors';
import { getCombinedRoutes } from 'src/app/navigation/store/navigation.selectors';

export const protectedRouteConnector = createStructuredSelector({
  isAuthenticated: selectIsOidcSessionValid,
  routes: getCombinedRoutes,
  hasLoggedOut: selectIsSignedOut,
});

import { connect } from 'react-redux';
import { compose } from 'recompose';

import { IconsViewComponent } from './icons-view.component';
import { selectHasRebranding } from 'src/domains/permissions/store/permissions.selectors';

export const IconsViewContainer = compose(
  connect((state) => ({
    rebranding: selectHasRebranding(state),
  })),
)(IconsViewComponent);

import { createAction, createPayloadAction } from 'src/app/store/app.actions';

import {
  ComeFromLoginAction,
  LoginRedirectAction,
  LoginSuccessAction,
  SessionActionType,
  SessionValidationErrorPayload,
  SetSuccessRedirectAction,
  SignoutAfterRefreshStartAction,
  SignoutErrorAction,
  SignoutStartAction,
  SignoutSuccessAction,
  ValidateSessionErrorAction,
  ValidateSessionStartAction,
  ValidateSessionSuccessAction,
} from './session.types';

export const comeFromLogin = (): ComeFromLoginAction =>
  createAction(SessionActionType.COME_FROM_LOGIN);

export const loginSuccess = (payload: string): LoginSuccessAction =>
  createPayloadAction(SessionActionType.LOGIN_SUCCESS, payload);

export const loginRedirect = (): LoginRedirectAction =>
  createAction(SessionActionType.LOGIN_REDIRECT);

export const validateSessionStart = (): ValidateSessionStartAction =>
  createAction(SessionActionType.VALIDATE_SESSION_START);

export const validateSessionError = (
  payload: SessionValidationErrorPayload,
): ValidateSessionErrorAction =>
  createPayloadAction(SessionActionType.VALIDATE_SESSION_ERROR, payload);

export const validateSessionSuccess = (
  payload: any,
): ValidateSessionSuccessAction =>
  createPayloadAction(SessionActionType.VALIDATE_SESSION_SUCCESS, payload);

export const signoutStart = (): SignoutStartAction =>
  createAction(SessionActionType.SIGN_OUT_START);

export const signoutAfterRefreshStart = (): SignoutAfterRefreshStartAction =>
  createAction(SessionActionType.SIGN_OUT_AFTER_REFRESH_START);

export const signoutSuccess = (): SignoutSuccessAction =>
  createAction(SessionActionType.SIGN_OUT_SUCCESS);

export const signoutError = (): SignoutErrorAction =>
  createAction(SessionActionType.SIGN_OUT_ERROR);

export const setSuccessRedirect = (payload: string): SetSuccessRedirectAction =>
  createPayloadAction(SessionActionType.SET_SUCCESS_REDIRECT, payload);

import styled from 'styled-components';

import { breakpoints } from 'src/app/styles/breakpoints';
import { transitionEasing, transitionSpeed } from 'src/app/styles/transitions';
import { borderRadius } from 'src/app/styles/border-radius';
import { colors } from 'src/app/styles/colors';
import { boxShadows } from 'src/app/styles/box-shadow';

import { cardPadding, mapCardStyle } from '../card.style';

const cardBaseStyles = {
  default: {
    borderRadius: borderRadius.six,
    padding: cardPadding.default,
    paddingMediumBreakpoint: cardPadding.medium,
    paddingLargeBreakpoint: cardPadding.large,
    bgColor: colors.white,
    borderColor: colors.silverMedium,
    boxShadow: boxShadows.two,
    headerColor: colors.white,
    height: '100%',
    width: '100%',
    minHeight: '100%',
  },
  primary: {
    bgColor: colors.white,
    borderColor: colors.silverMedium,
    boxShadow: boxShadows.two,
    headerColor: colors.white,
  },
  secondary: {
    bgColor: colors.silverLight,
    borderColor: colors.silverDark,
    boxShadow: 'none',
    headerColor: colors.silverLight,
  },
  blue: {
    bgColor: colors.lavender,
    borderColor: colors.quartzBlue,
    boxShadow: 'none',
    headerColor: colors.white,
  },
  noPadding: {
    padding: cardPadding.none,
    paddingMediumBreakpoint: cardPadding.none,
    paddingLargeBreakpoint: cardPadding.none,
  },
  topHalf: {
    borderRadius: `${borderRadius.six} ${borderRadius.six} 0 0`,
    borderBottom: '0',
  },
  bottomHalf: {
    borderTop: '0',
    borderRadius: `0 0 ${borderRadius.six} ${borderRadius.six}`,
  },
  hoverHeader: {
    padding: cardPadding.none,
    paddingMediumBreakpoint: cardPadding.none,
    paddingLargeBreakpoint: cardPadding.none,
    boxShadow: '0',
    borderRadius: '0',
    border: '0',
  },
  bgGraph: {
    minHeight: '345px',
  },
  hypoglycemiaSmallCard: {
    maxHeight: '17.8rem',
  },
  hypoglycemiaLargeCard: {
    minHeight: '293px',
    maxHeight: '293px',
  },
  statisticsCardHighR: {
    minHeight: '214px',
  },
  statisticsCardSmallR: {
    minHeight: '206px',
  },
  largeGlucoseDistribution: {
    minHeight: '515px',
    maxHeight: '515px',
  },
  shortGlucoseDistribution: {
    maxHeight: '17.8rem',
  },
  patientDashboard: {
    minHeight: '17.8rem',
    flexDirection: 'column',
  },
  patientDashboardHba1cCard: {
    minHeight: '123px',
    height: '123px',
    flexDirection: 'column',
    marginBottom: '1rem',
  },
  patientDashboardHba1cCardWidthJello: {
    minHeight: '123px',
    height: '123px',
    flexDirection: 'column',
    marginBottom: '1rem',
  },
  graphBgGPGraph: {
    paddingMediumBreakpoint: cardPadding.default,
    paddingLargeBreakpoint: cardPadding.default,
    minHeight: '470px',
  },
  marginBottomCard: {
    marginBottom: '1rem',
  },
  unsetAGPHeight: {
    height: 'unset',
  },
  cgmDashboard: {
    height: '100%',
    minHeight: 'auto',
  },
  dailyGlucoseProfile: {
    minHeight: '300px',
    height: '300px',
  },
  dailyGlucoseProfileIE: {
    minHeight: '300px',
    height: '300px',
  },
  flexible: {
    display: 'flex',
  },
  flat: {
    boxShadow: 0,
  },
};
const mapCardBaseStyle = mapCardStyle(cardBaseStyles);

const cardContentStyles = {
  default: {
    color: colors.charcoal,
  },
  patientDashboard: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    innerDivWidth: '100%',
  },
  cgmDashboard: {
    padding: '1rem',
  },
  patientDashboardHba1cCard: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    innerDivWidth: '100%',
  },
  patientDashboardHba1cCardWidthJello: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    innerDivWidth: '93%',
  },
  centerItemsCard: {
    height: '100%',
    alignItems: 'center',
  },
  dailyTimeInRangesCard: {
    minHeight: '192px',
  },
};

const mapCardContentStyle = mapCardStyle(cardContentStyles);

export const CardSection = styled.section`
  background: ${mapCardBaseStyle('bgColor')};
  border: 1px solid ${mapCardBaseStyle('borderColor')};
  box-shadow: ${mapCardBaseStyle('boxShadow')};
  border-radius: ${mapCardBaseStyle('borderRadius')};
  padding: ${mapCardBaseStyle('padding')};
  min-height: ${mapCardBaseStyle('minHeight')};
  max-height: ${mapCardBaseStyle('maxHeight')};
  height: ${mapCardBaseStyle('height')};
  width: ${mapCardBaseStyle('width')};
  display: ${mapCardBaseStyle('display')};
  flex-direction: ${mapCardBaseStyle('flexDirection')};
  margin-top: ${mapCardBaseStyle('marginTop')};
  margin-bottom: ${mapCardBaseStyle('marginBottom')};
  position: relative;
  transition: padding ${transitionSpeed.default} ${transitionEasing.default};

  @media (min-width: ${breakpoints.medium}) {
    padding: ${mapCardBaseStyle('paddingMediumBreakpoint')};
  }
  @media (min-width: ${breakpoints.large}) {
    padding: ${mapCardBaseStyle('paddingLargeBreakpoint')};
  }
`;
CardSection.defaultProps = {
  cardStyles: ['default'],
};
CardSection.displayName = 'CardSection';

export const CardContent = styled.div`
  color: ${colors.charcoal};
  flex: ${mapCardContentStyle('flex')};
  flex-grow: ${mapCardContentStyle('flexGrow')};
  flex-direction: ${mapCardContentStyle('flexDirection')};
  display: ${mapCardContentStyle('display')};
  height: ${mapCardContentStyle('height')};
  min-height: ${mapCardContentStyle('minHeight')};
  margin-bottom: ${mapCardContentStyle('marginBottom')};
  padding: ${mapCardContentStyle('padding')};
  aspect-ratio: ${mapCardContentStyle('aspectRatio')};
  justify-content: ${mapCardContentStyle('justifyContent')};
  align-items: ${mapCardContentStyle('alignItems')};
  & > div {
    width: ${mapCardContentStyle('innerDivWidth')};
  }
`;
CardContent.defaultProps = {
  cardStyles: ['default'],
};
CardContent.displayName = 'Content';

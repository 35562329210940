import React from 'react';
import { Bundle } from 'src/app/navigation';

export const DeviceAssignmentBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { DeviceAssignmentContainer } = await import(
        /* webpackChunkName: "deviceAssignment" */ './scenes/device-assignment'
      );
      return DeviceAssignmentContainer;
    }}
    bundleDidLoad={(DeviceAssignmentContainer) => (
      <DeviceAssignmentContainer {...props} />
    )}
  />
);

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { TabGeneralComponent } from './tab-general.component';
import { deviceSettingsGeneralConnector } from './tab-general.selector';
import { withTranslation } from 'src/shared/utils/i18n/with-translation';

export const TabGeneralContainer = compose<any, any>(
  withTranslation,
  connect(deviceSettingsGeneralConnector, null),
)(TabGeneralComponent);

import { FormControlOption } from './types';
import { find, map, pipe, prop, propEq, sortBy } from 'ramda';
import { Country } from 'src/domains/patient/store/countries/countries.types';
import { ActiveSystemLanguage } from 'src/app/services/languages/active-system-languages/active-system-languages.types';

import { translate } from 'src/shared/utils/i18n';

export const convertEC6CountriesToDropdownOptions = (
  countries: Country[],
): FormControlOption[] =>
  pipe(
    map(({ id, isoCode }) => ({
      label: translate(`countries.${isoCode}`),
      value: id,
    })),
    sortBy(prop('label')),
  )(countries);

export const convertProfessionaTypesToDropdownOptions = (
  selectDepartmentTypes: any,
): FormControlOption[] =>
  pipe(
    map(({ departmentProfileId, name }) => ({
      label: name,
      value: departmentProfileId,
    })),
    sortBy(prop('label')),
  )(selectDepartmentTypes);

export const convertActiveLanguagesToDropdownOptions = (
  languages: ActiveSystemLanguage[],
): FormControlOption[] =>
  pipe(
    map(({ id, locale }) => ({
      label: translate(`languages.${locale}`),
      value: id,
    })),
    sortBy(prop('label')),
  )(languages);

export const findCountryByIsoCode =
  (isoCode: string) =>
  (countries: Country[]): Country =>
    find(propEq('isoCode', isoCode))(countries);

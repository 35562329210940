import React from 'react';
import { isNil } from 'ramda';
import {
  convertTimeToFormat,
  is12HourFormat,
} from 'src/domains/diagnostics/utils/time-format';

import {
  AppleEatenIcon,
  AppleIcon,
  CircleMarkIcon,
  HandPointIcon,
} from 'src/shared/design-system/icons';
import { colors } from 'src/app/styles/colors';
import { BLOOD_GLUCOSE_UNITS } from 'src/domains/patient-dashboards/bg/store/bg.constants';
import { EMPTY_VALUE_PLACEHOLDER } from 'src/domains/diagnostics/store/constants';
import { formatBGMeasurement } from 'src/domains/diagnostics/utils/measurements';
import { RenderIf } from 'src/shared/utils/render-if';
import {
  LOGBOOK_STATUS_COLOR,
  LOGBOOK_UNITS_UNIT,
} from 'src/domains/diagnostics/constants/logbook.constants';
import { LocalizedText } from 'src/shared/design-system/localized-text/localized-text.component';

import {
  Column,
  ContainerDiv,
  GlucoseIconWrapper,
  GrayMediumLabel,
  GraySmallLabel,
  Header,
  InnerRow,
  LargeLabel,
  LightGraySmallLabel,
  ManualLabelWrapper,
  MeasurementValue,
  MediumLabel,
  Row,
  SmallerLabel,
} from './logbook-hint.style';

const mealIconSetting = {
  height: 14,
  width: 14,
  borderFillColor: 'transparent',
  withBorder: true,
};

const getStatusIconSetting = (statusColor) => {
  const colorMap = {
    [LOGBOOK_STATUS_COLOR.TRANSPARENT]: colors.clear,
    [LOGBOOK_STATUS_COLOR.GREEN]: colors.trafficGreen,
    [LOGBOOK_STATUS_COLOR.BLUE]: colors.blueMarine,
    [LOGBOOK_STATUS_COLOR.RED]: colors.red,
  };

  const color = colorMap[statusColor];

  return {
    height: 7,
    width: 7,
    fillColor: color,
    strokeColor: color,
  };
};

export const unitToString = ({ unit, value }) => {
  if (unit === LOGBOOK_UNITS_UNIT[BLOOD_GLUCOSE_UNITS.MG_PER_DL]) {
    return formatBGMeasurement(BLOOD_GLUCOSE_UNITS.MG_PER_DL)(value);
  }
  if (unit === LOGBOOK_UNITS_UNIT[BLOOD_GLUCOSE_UNITS.MMOL_PER_L]) {
    return formatBGMeasurement(BLOOD_GLUCOSE_UNITS.MMOL_PER_L)(value);
  }
  return `${value} `;
};

const HintRow = ({
  glucose,
  bolus,
  carbohydrates,
  time,
  afterMeal,
  beforeMeal,
  statusColor,
  timeFormat,
}) => (
  <Row>
    <InnerRow pb={2}>
      <Column>
        <SmallerLabel>
          {convertTimeToFormat(time, is12HourFormat(timeFormat))}
        </SmallerLabel>
      </Column>
      <Column>
        <RenderIf validate={beforeMeal}>
          <AppleIcon {...mealIconSetting} />
        </RenderIf>
        <RenderIf validate={afterMeal}>
          <AppleEatenIcon {...mealIconSetting} />
        </RenderIf>
      </Column>
    </InnerRow>
    <RenderIf validate={glucose.manuallyEntered}>
      <ManualLabelWrapper manuallyEntered={glucose.manuallyEntered}>
        <LocalizedText textKey={'graphs.detailGraph.toolTip.manuallyEntered'} />
      </ManualLabelWrapper>
    </RenderIf>
    <InnerRow>
      <Column>
        <GlucoseIconWrapper>
          <RenderIf validate={glucose.manuallyEntered}>
            <HandPointIcon />
          </RenderIf>
          <CircleMarkIcon
            {...getStatusIconSetting(
              !isNil(glucose.value)
                ? statusColor
                : LOGBOOK_STATUS_COLOR.TRANSPARENT,
            )}
          />
        </GlucoseIconWrapper>
        <MediumLabel>
          {!isNil(glucose.value)
            ? unitToString(glucose)
            : `${EMPTY_VALUE_PLACEHOLDER} `}
          <LocalizedText textKey={glucose.unit} />
        </MediumLabel>
      </Column>
      <Column>
        <RenderIf validate={!isNil(carbohydrates.value)}>
          <GraySmallLabel>
            <MeasurementValue>{unitToString(carbohydrates)}</MeasurementValue>
            <LocalizedText textKey={carbohydrates.unit} />
          </GraySmallLabel>
        </RenderIf>
        <RenderIf validate={!isNil(carbohydrates.value) && !isNil(bolus.value)}>
          <LightGraySmallLabel pl={2} pr={2}>
            |
          </LightGraySmallLabel>
        </RenderIf>
        <RenderIf validate={!isNil(bolus.value)}>
          <GraySmallLabel>
            {unitToString(bolus)}
            <LocalizedText textKey={bolus.unit} />
          </GraySmallLabel>
        </RenderIf>
      </Column>
    </InnerRow>
  </Row>
);

export const renderHintsDataRows = (measurements, timeFormat) =>
  measurements.map((measurement, index) => (
    <HintRow
      key={`mealtime measurement ${index}`}
      timeFormat={timeFormat}
      {...measurement}
    />
  ));

export const LogbookHint = ({
  mealTimeName,
  date,
  measurements,
  timeFormat,
}) => (
  <ContainerDiv>
    <Header>
      <Column>
        <LargeLabel>
          <LocalizedText textKey={mealTimeName} />
        </LargeLabel>
      </Column>
      <Column>
        <GrayMediumLabel>{date}</GrayMediumLabel>
      </Column>
    </Header>
    {renderHintsDataRows(measurements, timeFormat)}
  </ContainerDiv>
);

import { createAction, createPayloadAction } from 'src/app/store/app.actions';

import {
  DeliveryConfigurationActionType,
  FetchDeliveryConfigurationErrorAction,
  FetchDeliveryConfigurationStartAction,
  FetchDeliveryConfigurationStartPayloadType,
  FetchDeliveryConfigurationSuccessAction,
  getPatientProgramSuccessAction,
} from './delivery-configuration.types';
import { Program } from './../delivery-configuration.types';

export const fetchDeliveryConfigurationStart = (
  patientId: FetchDeliveryConfigurationStartPayloadType,
): FetchDeliveryConfigurationStartAction =>
  createPayloadAction(
    DeliveryConfigurationActionType.FETCH_DELIVERY_CONFIGURATION_START,
    patientId,
  );

export const fetchDeliveryConfigurationSuccess = (
  params: Program[],
): FetchDeliveryConfigurationSuccessAction =>
  createPayloadAction(
    DeliveryConfigurationActionType.FETCH_DELIVERY_CONFIGURATION_SUCCESS,
    params,
  );

export const fetchDeliveryConfigurationError =
  (): FetchDeliveryConfigurationErrorAction =>
    createAction(
      DeliveryConfigurationActionType.FETCH_DELIVERY_CONFIGURATION_ERROR,
    );

export const getPatientProgramSuccess = (
  params: Program,
): getPatientProgramSuccessAction =>
  createPayloadAction(
    DeliveryConfigurationActionType.GET_PATIENT_PROGRAM_SUCCESS,
    params,
  );

export const saveDeliveryConfigurationStart = (
  patientId: string,
  program: Program[],
): FixMe =>
  createPayloadAction(
    DeliveryConfigurationActionType.SAVE_DELIVERY_CONFIGURATION_START,
    {
      patientId,
      program,
    },
  );

export const saveDeliveryConfigurationSuccess = (program: Program[]): FixMe =>
  createPayloadAction(
    DeliveryConfigurationActionType.SAVE_DELIVERY_CONFIGURATION_SUCCESS,
    program,
  );

export const saveDeliveryConfigurationError = (): FixMe =>
  createAction(
    DeliveryConfigurationActionType.SAVE_DELIVERY_CONFIGURATION_ERROR,
  );

import * as React from 'react';

import { withTranslation } from '../../../utils/i18n/with-translation';
import { SvgIcon } from '../../icon/icon.component';
import { colors } from '../../../../app/styles/colors';

const originalWidth = 18;
const originalHeight = 18;
// tslint:disable-next-line: no-identical-expressions
const aspectRatio = originalWidth / originalWidth;

export const CirclePlayComponent = ({
  height = originalHeight,
  fillColor = colors.white,
}) => (
  <SvgIcon
    width={height * aspectRatio}
    height={height}
    originalWidth={originalWidth}
    originalHeight={originalHeight}
  >
    <g
      id="Device-Settings"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      transform="translate(1, 1)"
    >
      <circle
        id="Oval"
        stroke={fillColor}
        strokeWidth="1"
        cx="8"
        cy="8"
        r="8"
      />
      <polygon
        id="Triangle"
        fill={fillColor}
        fillRule="evenodd"
        transform="translate(9.000000, 8.000000) rotate(-270.000000) translate(-9.000000, -8.000000) "
        points="9 5 13 11 5 11"
      />
    </g>
  </SvgIcon>
);

export const CirclePlayIcon = withTranslation(CirclePlayComponent);

import React from 'react';

import { LOGBOOK_UNITS_UNIT } from 'src/domains/diagnostics/constants/logbook.constants';
import { colors } from 'src/app/styles/colors';

import { LogbookCellBlock } from 'src/domains/diagnostics/components/table/logbook-cell-block/logbook-cell-block.component';
import { LogbookCellBlocksContainer } from 'src/domains/diagnostics/components/table/logbook-cell-blocks-container/logbook-cell-blocks-container.component';
import { LogbookTableCell } from 'src/domains/diagnostics/components/table/logbook-table-cell/logbook-table-cell.component';
import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';

const BloodGlucoseCarbohydratesAndBolusCells = ({
  children,
  keyText,
  bloodGlucoseUnit,
  width,
  carbLabel,
}) => [
  <LogbookTableCell key={`${keyText}-cell`} p={1} width={width}>
    <LogbookCellBlocksContainer>
      {[bloodGlucoseUnit, 'CARBOHYDRATES', 'BOLUS'].map((unit) => (
        <LogbookCellBlock
          key={`${keyText}-cell-${unit}-before`}
          flex="1"
          color={colors.blueMarine}
        >
          <LocalizedText
            textKey={
              unit === 'CARBOHYDRATES' ? carbLabel : LOGBOOK_UNITS_UNIT[unit]
            }
          />
        </LogbookCellBlock>
      ))}
      {children}
    </LogbookCellBlocksContainer>
  </LogbookTableCell>,
];

const NightBedtimeHeader = ({ bloodGlucoseUnit, keyText, carbLabel }) => [
  <BloodGlucoseCarbohydratesAndBolusCells
    key={`${keyText}-nightbedtime`}
    keyText={keyText}
    bloodGlucoseUnit={bloodGlucoseUnit}
    width={'10.5rem'}
    carbLabel={carbLabel}
  />,
];
const BreakfastLunchDinnerHeader = ({
  bloodGlucoseUnit,
  keyText,
  carbLabel,
}) => [
  <BloodGlucoseCarbohydratesAndBolusCells
    key={`${keyText}-bld`}
    bloodGlucoseUnit={bloodGlucoseUnit}
    width={'17.45rem'}
    carbLabel={carbLabel}
  >
    {[bloodGlucoseUnit, 'BOLUS'].map((unit) => (
      <LogbookCellBlock
        key={`${keyText}-cell-${unit}-after`}
        flex="1"
        color={colors.blueMarine}
      >
        <LocalizedText
          textKey={
            unit === 'CARBOHYDRATES' ? carbLabel : LOGBOOK_UNITS_UNIT[unit]
          }
        />
      </LogbookCellBlock>
    ))}
  </BloodGlucoseCarbohydratesAndBolusCells>,
];

export const LogbookUnitsHeader = ({ bloodGlucoseUnit, carbLabel }) => [
  <NightBedtimeHeader
    key="nightHeader"
    keyText="nightHeader"
    bloodGlucoseUnit={bloodGlucoseUnit}
    carbLabel={carbLabel}
  />,
  <BreakfastLunchDinnerHeader
    key="breakfastHeader"
    keyText="breakfastHeader"
    bloodGlucoseUnit={bloodGlucoseUnit}
    carbLabel={carbLabel}
  />,
  <BreakfastLunchDinnerHeader
    key="lunchHeader"
    keyText="lunchHeader"
    bloodGlucoseUnit={bloodGlucoseUnit}
    carbLabel={carbLabel}
  />,
  <BreakfastLunchDinnerHeader
    key="dinnerHeader"
    keyText="dinnerHeader"
    bloodGlucoseUnit={bloodGlucoseUnit}
    carbLabel={carbLabel}
  />,
  <NightBedtimeHeader
    key="bedtimeHeader"
    keyText="bedtimeHeader"
    bloodGlucoseUnit={bloodGlucoseUnit}
    carbLabel={carbLabel}
  />,
  <LogbookTableCell key="detailsHeader" p={1} />,
];

import {
  createAuthHeader,
  postJSON,
} from '../../../../shared/utils/service/service.utils';
import { ENDPOINTS } from '../../../../app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const MarkAnnouncementVisitedLoaderImpl = (
  { id },
  accessToken: string,
) => {
  return postJSON(
    endpointWithParams(ENDPOINTS.markAnnouncementVisited, { id }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );
};

export const MarkAnnouncementVisitedTransformImpl = (data) => data;

export const MarkAnnouncementVisitedServiceImpl =
  (load, transform) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);

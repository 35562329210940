import React from 'react';

import { colors } from 'src/app/styles/colors';

import { SvgIcon } from 'src/shared/design-system/icon/icon.component';

export const CarbohydratesToggleIcon = ({
  height = 16,
  fillColor = colors.carb,
  minX = 0,
  minY = 0,
}: Props) => {
  const originalWidth = 13;
  const originalHeight = 16;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      title="Basal"
      width={height * aspectRatio}
      height={height}
      minX={minX}
      minY={minY}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g
        fill={fillColor}
        transform={`translate(${(height * aspectRatio) / 2 - 2}, 0)`}
      >
        <polygon points="0 0 4 0 4 4 0 4" />
        <polygon points="0 6 4 6 4 10 0 10" />
        <polygon points="0 12 4 12 4 16 0 16" />
      </g>
    </SvgIcon>
  );
};

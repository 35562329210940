import { GetParticipantIdsResponseType } from './get-participant-ids.types';

export const getParticipantIdsMockresponse: GetParticipantIdsResponseType = {
  subjectIds: [
    {
      siteId: '10',
      participantId: '102',
    },
  ],
};

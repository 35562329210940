/* tslint:disable */

import { createSelector, createStructuredSelector, Selector } from 'reselect';
import { isEmpty, pathOr } from 'ramda';

import { selectIs12HourFormat } from 'src/app/store/user/user.selectors';

import { splitArrayToObjectsByType } from './tab-reminders.utils';

import { DeviceSetting } from '../../device-settings.types';
import {
  selectLatestDeviceSetting,
  selectPreviousDeviceSetting,
} from '../../device-settings.selector';
import { selectMeterSettings } from '../tab-general/tab-general.selector';

const getDataByObjectType = (query, data) => {
  const resPath = pathOr({}, query, data);
  if (!isEmpty(resPath)) {
    return splitArrayToObjectsByType(resPath);
  }
  return {};
};

export const selectPumpReminder: Selector<any, any> = createSelector(
  [selectLatestDeviceSetting],
  (latestSetting: DeviceSetting) =>
    getDataByObjectType(
      ['settings', 'Reminders', 'PumpReminders'],
      latestSetting,
    ),
);
export const selectPreviousPumpReminder: Selector<any, any> = createSelector(
  [selectPreviousDeviceSetting],
  (previousSetting: DeviceSetting) =>
    getDataByObjectType(
      ['settings', 'Reminders', 'PumpReminders'],
      previousSetting,
    ),
);

export const selectAlarmClockReminder: Selector<any, any> = createSelector(
  [selectLatestDeviceSetting],
  (latestSetting: DeviceSetting) =>
    getDataByObjectType(
      ['settings', 'Reminders', 'AlarmClockReminders'],
      latestSetting,
    ),
);
export const selectPreviousAlarmClockReminder: Selector<any, any> =
  createSelector(
    [selectPreviousDeviceSetting],
    (previousSetting: DeviceSetting) =>
      getDataByObjectType(
        ['settings', 'Reminders', 'AlarmClockReminders'],
        previousSetting,
      ),
  );
export const selectAppointmentReminder: Selector<any, any> = createSelector(
  [selectLatestDeviceSetting],
  (latestSetting: DeviceSetting) => {
    const appointmentReminders = pathOr(
      {},
      ['settings', 'Reminders', 'AppointmentReminders'],
      latestSetting,
    );
    return !isEmpty(appointmentReminders) ? appointmentReminders : [];
  },
);
export const selectPreviousAppointmentReminder: Selector<any, any> =
  createSelector(
    [selectPreviousDeviceSetting],
    (latestSetting: DeviceSetting) => {
      const appointmentReminders = pathOr(
        {},
        ['settings', 'Reminders', 'AppointmentReminders'],
        latestSetting,
      );
      return !isEmpty(appointmentReminders) ? appointmentReminders : [];
    },
  );

export const selectBGTestReminder: Selector<any, any> = createSelector(
  [selectLatestDeviceSetting],
  (latestSetting: DeviceSetting) => {
    const bgTestReminder = pathOr(
      {},
      ['settings', 'Reminders', 'BgTestReminder'],
      latestSetting,
    );
    return bgTestReminder;
  },
);

export const selectPreviousBGTestReminder: Selector<any, any> = createSelector(
  [selectPreviousDeviceSetting],
  (latestSetting: DeviceSetting) => {
    const bgTestReminder = pathOr(
      {},
      ['settings', 'Reminders', 'BgTestReminder'],
      latestSetting,
    );
    return bgTestReminder;
  },
);
export const deviceSettingsReminderConnector = () =>
  createStructuredSelector({
    pumpReminder: selectPumpReminder,
    previousPumpReminder: selectPreviousPumpReminder,
    alarmClockReminder: selectAlarmClockReminder,
    previousAlarmClockReminder: selectPreviousAlarmClockReminder,
    appointmentReminder: selectAppointmentReminder,
    previousAppointmentReminder: selectPreviousAppointmentReminder,
    bgReminder: selectBGTestReminder,
    previousBgReminder: selectPreviousBGTestReminder,
    meterSetting: selectMeterSettings,
    is12HourFormat: selectIs12HourFormat,
  });

import { GetClinicalDataFromFhirLoaderImpl } from './clinical-data-from-fhir.service';

import {
  ClinicalDataServiceImpl,
  ClinicalDataTransformImpl,
} from '../clinical-data/clinical-data.service';

import { mockClinicalDataFromFhir } from './clinical-data-from-fhir.mock';
import { ClinicalDataFromFhirReponseType } from './clinical-data-from-fhir.types';
import { Promise } from 'es6-promise';

const mockLoader = (): Promise<ClinicalDataFromFhirReponseType> =>
  new Promise((resolve, reject) =>
    setTimeout(() => resolve(mockClinicalDataFromFhir), 3000),
  );

export const ClinicalDataFromFhirFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : GetClinicalDataFromFhirLoaderImpl;
  return ClinicalDataServiceImpl(loader, ClinicalDataTransformImpl);
};

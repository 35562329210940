import * as React from 'react';

import {
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
} from 'src/app/app.jello.constants';
import styles from './styles.module.css';

import { t } from 'i18next';

type iconListItem = {
  icon: React.ReactNode | React.ReactElement;
  label: string;
};

export type GenerateIconsSectionProps = {
  sectionTitle: string;
  iconListItems: iconListItem[];
};

const iconListMap = ({ icon, label }: iconListItem, index: React.Key) => (
  <li key={index} className={styles.iconListItem}>
    {icon}
    <jello-text
      className={styles.iconListItem__text}
      size={JELLO_TEXT_SIZES.XS}
      weight={JELLO_TEXT_WEIGHTS.REGULAR}
    >{`${t(label)}`}</jello-text>
  </li>
);

export const GenerateIconsSection: React.FunctionComponent<
  GenerateIconsSectionProps
> = (props) => {
  const { sectionTitle, iconListItems } = props;

  return (
    <section className={styles.iconsSection}>
      <header>
        <jello-text
          size={JELLO_TEXT_SIZES.M}
          weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
        >{`${t(sectionTitle)}`}</jello-text>
      </header>
      <ul className={styles.iconsList}>{iconListItems.map(iconListMap)}</ul>
    </section>
  );
};

import {
  AddOrgStockLoaderImpl,
  AddOrgStockServiceImpl,
  AddOrgStockTransform,
} from './add-org-stock.service';
import { mockAddOrgStockResponse } from './add-org-stock.mock';

const mockLoader = () => Promise.resolve(mockAddOrgStockResponse);

export const AddOrgStockFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : AddOrgStockLoaderImpl;
  return AddOrgStockServiceImpl(loader, AddOrgStockTransform);
};

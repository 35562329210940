import { createAction, createPayloadAction } from 'src/app/store/app.actions';

import {
  FetchHba1cCardErrorAction,
  FetchHba1cCardStartAction,
  FetchHba1cCardStartPayloadType,
  FetchHba1cCardSuccessAction,
  Hba1cCardActionType,
} from './hba1c-card.types';

export const fetchHba1cCardStart = (
  fhirId: FetchHba1cCardStartPayloadType,
): FetchHba1cCardStartAction =>
  createPayloadAction(Hba1cCardActionType.FETCH_HBA1C_CARD_START, fhirId);

export const fetchHba1cCardSuccess = (
  params: any,
): FetchHba1cCardSuccessAction =>
  createPayloadAction(Hba1cCardActionType.FETCH_HBA1C_CARD_SUCCESS, params);

export const fetchHba1cCardError = (): FetchHba1cCardErrorAction =>
  createAction(Hba1cCardActionType.FETCH_HBA1C_CARD_ERROR);

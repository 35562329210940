import styled, { css } from 'styled-components';
import fonts from '../../fonts';
import { colors } from '../../colors';

const styledInputBase = css`
  font-size: 14px;
  font-family: ${fonts.type.mainFont};
  color: ${colors.black};
  border-radius: 3px;
  border: 1px solid ${colors.disabledGray};
`;

export const StyledTextInput = styled.textarea<{
  width?: string;
  height?: string;
}>`
  ${styledInputBase}
  height: ${(props) => props.height || '80px'};
  width: ${(props) => props.width || '80%'};
  padding: 9px;
  margin: 0 0 10px;
  resize: none;
  ::placeholder {
    color: ${colors.gray};
    font-style: italic;
  }
`;

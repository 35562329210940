import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';
import { ENDPOINTS } from 'src/app/navigation/services/service.constants';

export const ListDemoProfileLoaderImpl = (query: any, accessToken: string) => {
  return getJSON(ENDPOINTS.getDemoProfiles, {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  });
};

export const ListDemoProfilesServiceImpl =
  (load) => (query: any, accessToken: string, gigyaToken: string) => {
    return load(query, accessToken, gigyaToken).then((data) => data);
  };

import {
  createAuthHeader,
  postJSON,
  stringifyBody,
} from 'src/shared/utils/service/service.utils';

import {
  CreatePatientWithFhirLoaderImplType,
  CreatePatientWithFhirParams,
  CreatePatientWithFhirResponse,
  CreatePatientWithFhirServiceImplType,
  CreatePatientWithFhirTransformedResponse,
} from './create-patient-with-fhir.types';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';

export const CreatePatientWithFhirLoaderImpl: CreatePatientWithFhirLoaderImplType =
  (
    { patient, dataSharing, pairingCode }: CreatePatientWithFhirParams,
    accessToken: string,
  ) =>
    postJSON(ENDPOINTS.createPatientWithFhir, {
      headers: {
        Authorization: createAuthHeader(accessToken),
        DataSharing: dataSharing,
        ...(pairingCode && { PairingCode: pairingCode }),
      },
      body: stringifyBody(patient),
    });

export const CreatePatientWithFhirTransformImpl = ({
  model,
}: CreatePatientWithFhirResponse): CreatePatientWithFhirTransformedResponse => {
  const patientId = model.substring(model.lastIndexOf('/') + 1);
  const patientProfileUrl = `/patients/bg/${patientId}`;
  return { patientProfileUrl, patientId };
};

export const CreatePatientWithFhirServiceImpl: CreatePatientWithFhirServiceImplType =
  (load, transform) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);

import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';

import {
  FetchConfigurablesLoaderType,
  FetchConfigurablesServiceType,
} from './configurables.types';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';

export const FetchConfigurablesLoaderImpl: FetchConfigurablesLoaderType = (
  openId: any,
) => {
  return getJSON(ENDPOINTS.configurables, {
    headers: {
      Authorization: createAuthHeader(openId.accessToken),
    },
  });
};

export const FetchConfigurablesServiceImpl: FetchConfigurablesServiceType =
  (load, transform) => (openId) =>
    load(openId).then(transform);

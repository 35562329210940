import { createRequestActions } from 'src/app/store/request/request.actions';

import {
  FETCH_PATIENT_DATE_RANGE_REQUEST,
  PATIENT_DATE_ACTIONS,
} from './patient-date-range.constant';

export const fetchPatientDateRangeRequest = createRequestActions(
  FETCH_PATIENT_DATE_RANGE_REQUEST,
);

export const clearPatientDateRange = () => ({
  type: PATIENT_DATE_ACTIONS.CLEAR,
});

export const onPatientDatesRangeChange = (
  patientId,
  startDate,
  endDate,
  range,
  hasUserFhirPermission,
  patientFhirId,
) => ({
  type: PATIENT_DATE_ACTIONS.SET_DATES,
  payload: {
    patientId,
    startDate,
    endDate,
    range,
    hasUserFhirPermission,
    patientFhirId,
  },
});

export const setFirstLastMeasurementDates = (
  firstMeasurementDate,
  latestMeasurementDate,
) => ({
  type: PATIENT_DATE_ACTIONS.SET_FIRST_LAST_DATES,
  payload: { firstMeasurementDate, latestMeasurementDate },
});

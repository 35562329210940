import { Observable } from 'rxjs';
import { Epic } from 'redux-observable';
import { filter, includes, not } from 'ramda';
import { selectAccessToken } from '../../../../../../app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from '../../../../../../app/session/core/config/config.selectors';
import { fetchPatientRequest } from '../../../../store/patient/patient.action';
import { State } from '../../../../../../app/store/app.types';
import {
  EditPatientPlatformActionType,
  EditPatientPlatformStartAction,
  FetchEditPatientDataActions,
  FetchEditPatientDataActionType,
  FetchEditPatientDataStartAction,
} from './edit-patient.actions.types';
import { FetchConfigurablesLoaderType } from '../../../../services/configurables/configurables.types';
import { GetPatientInfoServiceImplType } from '../../../../services/patient/patient-info/patient-info.types';
import { catchError } from 'rxjs/operators';
import { addAlertAction } from '../../../../../../shared/design-system/alerts/store/alert.actions';
import { AlertType } from '../../../../../../shared/design-system/alerts/store/alert.types';
import { ALERT_POSITION } from '../../../../../../shared/design-system/alerts/store/alert.constans';
import {
  editPatientPlatformErrorAction,
  editPatientPlatformSuccessAction,
  fetchEditPatientDataErrorAction,
  fetchEditPatientDataStartAction,
  fetchEditPatientDataSuccessAction,
} from './edit-patient.actions';
import {
  EditPatientWithFhirServiceImplType,
  TransformedEditPatientWithFhirSuccessResponse,
} from '../../../../services/patient/edit-patient-with-fhir/edit-patient-with-fhir.types';
import { triggerEditPatientCompletedCountlyEvent } from '../../create-edit-countly-events/create-edit-countly-events';

export const transformConfigurableFromEC6 = (configurable: string): string =>
  `${configurable}`;

export const getGrantedConfigurables = (
  list: string[],
  toExclude: string[],
): string[] => {
  const exclude = toExclude.map(transformConfigurableFromEC6);
  const filterFn = (element) => not(includes(element, exclude));
  return filter(filterFn, list);
};

export const fetchEditPatientDataEpic: (
  getPatientInfoService: GetPatientInfoServiceImplType,
  fetchConfigurablesService: FetchConfigurablesLoaderType,
) => Epic<FetchEditPatientDataActions, State> =
  (getPatientInfoService, fetchConfigurablesService) => (action$, store) =>
    action$
      .ofType(FetchEditPatientDataActionType.FETCH_EDIT_PATIENT_DATA_START)
      .switchMap(({ payload }: FetchEditPatientDataStartAction) => {
        const openId = {
          accessToken: selectAccessToken(store.getState()),
          gigyaToken: selectGigyaToken(store.getState()),
        };
        const editablePatientInfo = Observable.fromPromise(
          getPatientInfoService(payload, openId),
        );
        const configurablePermissions = Observable.fromPromise(
          fetchConfigurablesService(openId),
        );
        return Observable.forkJoin(editablePatientInfo, configurablePermissions)
          .switchMap((data) => {
            const [EC6patient, configurables] = data;
            const grantedPermissions = getGrantedConfigurables(
              configurables,
              (EC6patient as FixMe).revokedPermissions,
            );
            const editableEC6patient = {
              ...EC6patient,
              grantedPermissions,
            };
            return [fetchEditPatientDataSuccessAction(editableEC6patient)];
          })
          .pipe(
            catchError((err) =>
              Observable.of(fetchEditPatientDataErrorAction(err)),
            ),
          );
      });

export const editPatientPlatformEpic: (
  editPatientWithFhirService: EditPatientWithFhirServiceImplType,
) => Epic<FixMe, State> = (editPatientWithFhirService) => (action$, store$) =>
  action$
    .ofType(EditPatientPlatformActionType.EDIT_PATIENT_PLATFORM_START)
    .flatMap(({ payload }: EditPatientPlatformStartAction) => {
      const accessToken = selectAccessToken(store$.getState());
      const gigyaToken = selectGigyaToken(store$.getState());
      const { patientId, editPatientCountlyEventData } = payload;

      return Observable.fromPromise(
        editPatientWithFhirService(payload, accessToken, gigyaToken),
      )
        .switchMap((data: TransformedEditPatientWithFhirSuccessResponse) => {
          return [
            editPatientPlatformSuccessAction(data.status),
            addAlertAction({
              type: AlertType.SUCCESS,
              text: {
                [AlertType.SUCCESS]: 'editPatient.alertEditSuccess.title',
              },
              position: ALERT_POSITION.BOTTOM_RIGHT,
            }),
            fetchEditPatientDataStartAction({ patientId }),
            fetchPatientRequest.start({ patientId }),
            triggerEditPatientCompletedCountlyEvent(
              editPatientCountlyEventData,
            ),
          ];
        })
        .pipe(
          catchError(() => Observable.of(editPatientPlatformErrorAction())),
        );
    });

import { DateTime } from 'luxon';
import { concat, join, pathOr } from 'ramda';

import { convertBooleanToString } from 'src/domains/patient/booleans';

import { convertEC6GenderToPatientGender } from '../patient.util';

export const convertProfessionalListToString = (
  professionals: number[],
): string => (professionals ? join(',')(professionals) : '');

const dummyEmailRegex = /noemail[\-0-9a-zA-Z]+@accu-chek.com/g;

export const isDummyEmail = (email: string) => email.match(dummyEmailRegex);

export const transformEC6Patient = (EC6PatientData) => {
  const hasPatientPortalAccess = EC6PatientData.user.hcpIsAccessible;

  // Do not display internal placeholder emails used in EC6 for non-portal users
  // Ex. noemailMABccJIVZSZBMHO@accu-chek.com
  const patientEmail = pathOr('', ['user', 'email'], EC6PatientData);
  const patientPublicEmail = isDummyEmail(patientEmail) ? '' : patientEmail;
  const functionalities =
    EC6PatientData.grantRevokeFunctDTO.revokeFunctionalities;
  const revokedPermissions = functionalities || [];

  return {
    healthInfo: {
      diabetesType: EC6PatientData.diabetesType,
      therapyType: EC6PatientData.therapyType,
      associatedProfessional: convertProfessionalListToString(
        EC6PatientData.professionalList,
      ),
      professionalList: EC6PatientData.professionalList,
      dateOfDiagnosis: EC6PatientData.diagnosticDate,
      pregnant: convertBooleanToString(EC6PatientData.pregnant),
      expectedDeliverDate: EC6PatientData.expectedDeliverDate,
      dateOfDiagnosisMonth:
        EC6PatientData.diagnosticDate &&
        DateTime.fromISO(EC6PatientData.diagnosticDate).month,
      dateOfDiagnosisDay:
        EC6PatientData.diagnosticDate &&
        DateTime.fromISO(EC6PatientData.diagnosticDate).day,
      dateOfDiagnosisYear:
        EC6PatientData.diagnosticDate &&
        DateTime.fromISO(EC6PatientData.diagnosticDate).year,
      gestationalDueDateMonth:
        EC6PatientData.expectedDeliverDate &&
        DateTime.fromISO(EC6PatientData.expectedDeliverDate).month,
      gestationalDueDateDay:
        EC6PatientData.expectedDeliverDate &&
        DateTime.fromISO(EC6PatientData.expectedDeliverDate).day,
      gestationalDueDateYear:
        EC6PatientData.expectedDeliverDate &&
        DateTime.fromISO(EC6PatientData.expectedDeliverDate).year,
    },
    patientInfo: {
      firstName: EC6PatientData.user.name,
      lastName: concat(
        EC6PatientData.user.surname,
        EC6PatientData.user.surname2 ? ` ${EC6PatientData.user.surname2}` : '',
      ),
      surname: EC6PatientData.user.surname,
      surname2: EC6PatientData.user.surname2,
      departmentProfileId: EC6PatientData.user.departmentProfileId,
      healthCareId: EC6PatientData.healthCareSystemId,
      email: patientPublicEmail,
      language: EC6PatientData.user.languageId,
      allowPatientAccess: hasPatientPortalAccess,
      phoneNumber: EC6PatientData.user.phone,
      street: EC6PatientData.user.address.address,
      city: EC6PatientData.user.address.city,
      province: EC6PatientData.user.address.province,
      postalCode: EC6PatientData.user.address.postalCode,
      country: EC6PatientData.user.address.country.id,
      dateOfBirthMonth:
        EC6PatientData.user.birthday &&
        DateTime.fromISO(EC6PatientData.user.birthday).month,
      dateOfBirthDay:
        EC6PatientData.user.birthday &&
        DateTime.fromISO(EC6PatientData.user.birthday).day,
      dateOfBirthYear:
        EC6PatientData.user.birthday &&
        DateTime.fromISO(EC6PatientData.user.birthday).year,
      gender: convertEC6GenderToPatientGender(EC6PatientData.user.gender),
      unknownBirthdate: EC6PatientData.user.unknownBirthdate,
      birthNames: pathOr(
        null,
        ['user', 'userAdditionalInfo', 'birthNames'],
        EC6PatientData,
      ),
      usedName: pathOr(
        null,
        ['user', 'userAdditionalInfo', 'usedName'],
        EC6PatientData,
      ),
      usedSurnames: pathOr(
        null,
        ['user', 'userAdditionalInfo', 'usedSurnames'],
        EC6PatientData,
      ),
      birthPlaceCode: pathOr(
        99999,
        ['user', 'userAdditionalInfo', 'birthPlaceCode'],
        EC6PatientData,
      ),
      proofOfIdentity: pathOr(
        null,
        ['patientInsStatus', 'proofOfIdentity'],
        EC6PatientData,
      ),
      insCode: pathOr(null, ['patientInsStatus', 'insNumber'], EC6PatientData),
    },
    revokedPermissions,
  };
};

import { JELLO_ICON_NAMES } from 'src/app/app.jello.constants';
import { BGM, CGM } from 'src/app/app.constants';

export const SIDEBAR_MENU = 'sidebarMenu';
export const TOGGLER = 'toggler';
export const MENU_OPTION = 'option';
export const STRIP_MANAGEMENT = 'stripManagement';
export const HOME_DELIVERY_PATH_KEY = 'home';
export const PICK_UP_PATH_KEY = 'pickUp';
export const ADMIN_PATH_KEY = 'admin';

export const PARSED_LOCATION = {
  bgm: BGM,
  cgm: CGM,
};

export const getItems = (routes) => {
  const {
    dashboard,
    patient,
    stripManagement,
    indicators,
    adminStrip,
    diagnostics,
  } = routes;

  return {
    bgm: {
      iconName: JELLO_ICON_NAMES.ACCU_CHEK_GUIDE,
      label: 'sidebarmenu.bgm',
      activePaths: [
        dashboard.bgId,
        diagnostics.insulin,
        diagnostics.graph,
        diagnostics.trend,
        diagnostics.logbook,
        diagnostics.logbookStats,
        diagnostics.logbookDiary,
        diagnostics.metabolicRate,
        diagnostics.standardWeek,
        diagnostics.standardDay,
        diagnostics.bloodGlucoseOverview,
        diagnostics.bloodGlucoseGeneralStats,
        diagnostics.bloodGlucoseGeneralStatsAll,
        diagnostics.bloodGlucoseGeneralStatsById,
        diagnostics.insulinPump,
        diagnostics.deviceSettings,
      ],
      path: dashboard.bgId,
    },
    cgm: {
      iconName: JELLO_ICON_NAMES.CGM_PATCH,
      label: 'sidebarmenu.cgm',
      activePaths: [dashboard.cgmId],
      path: dashboard.cgmId,
    },
    patterns: {
      iconName: JELLO_ICON_NAMES.TREND,
      label: 'sidebarmenu.patterns',
      activePaths: [indicators.main],
      path: indicators.main,
    },
    stripManagement: {
      iconName: JELLO_ICON_NAMES.ACTIVE_TEST_STRIPS,
      label: 'sidebarmenu.stripManagement',
      activePaths: [
        patient.delivery,
        stripManagement.stripInfoByPatient,
        stripManagement.prescriptionInfoByPatient,
        stripManagement.alerts,
        adminStrip.stripInfoByPatient,
      ],
      path: {
        [HOME_DELIVERY_PATH_KEY]: patient.delivery,
        [PICK_UP_PATH_KEY]: stripManagement.stripInfoByPatient,
        [ADMIN_PATH_KEY]: adminStrip.stripInfoByPatient,
      },
    },
    patientDevices: {
      iconName: JELLO_ICON_NAMES.GLUCOSE_DEVICES,
      label: 'sidebarmenu.patientDevices',
      activePaths: [patient.deviceSettings],
      path: patient.deviceSettings,
    },
    editPatient: {
      iconName: JELLO_ICON_NAMES.EDIT_PATIENT,
      label: 'sidebarmenu.editPatient',
      activePaths: [patient.editPatient],
      path: patient.editPatient,
    },
  };
};

import { requestSequence } from '../../../../../app/store/request/request.epics';
import {
  EDIT_DEMO_PATIENT_PROFILES_REQUEST,
  FETCH_ASSIGNED_DEMO_PATIENT_PROFILES_REQUEST,
  FETCH_DEMO_PROFILES_REQUEST,
} from '../constants/demo-profile.constants';

export const fetchDemoProfilesEpic = (listDemoProfileService) =>
  requestSequence({
    service: listDemoProfileService,
    actionTypes: FETCH_DEMO_PROFILES_REQUEST,
  });

export const editDemoPatientProfilesEpic = (editDemoPatientProfileService) =>
  requestSequence({
    service: editDemoPatientProfileService,
    actionTypes: EDIT_DEMO_PATIENT_PROFILES_REQUEST,
  });

export const fetchAssignedDemoPatientProfilesEpic = (
  fetchAssignedDemoPatientProfileService,
) =>
  requestSequence({
    service: fetchAssignedDemoPatientProfileService,
    actionTypes: FETCH_ASSIGNED_DEMO_PATIENT_PROFILES_REQUEST,
  });

import { Action, PayloadAction } from '../../../../app/store/app.types';

export type FetchConfigurablesStartAction =
  Action<ConfigurablePermissionsActions.FETCH_CONFIGURABLES_START>;

export type FetchConfigurablesSuccessAction = PayloadAction<
  ConfigurablePermissionsActions.FETCH_CONFIGURABLES_SUCCESS,
  string[]
>;

export type FetchConfigurablesErrorAction =
  Action<ConfigurablePermissionsActions.FETCH_CONFIGURABLES_ERROR>;

export type FetchConfigurablesResetAction =
  Action<ConfigurablePermissionsActions.FETCH_CONFIGURABLES_RESET>;

export type ConfigurablePermissionsActionsType =
  | FetchConfigurablesStartAction
  | FetchConfigurablesSuccessAction
  | FetchConfigurablesErrorAction
  | FetchConfigurablesResetAction;

export type ConfigurablePermissionsState = {
  isLoading: boolean;
  configurables: string[];
  error: boolean;
};

export enum ConfigurablePermissionsActions {
  FETCH_CONFIGURABLES_START = 'FETCH_CONFIGURABLES_START',
  FETCH_CONFIGURABLES_ERROR = 'FETCH_CONFIGURABLES_ERROR',
  FETCH_CONFIGURABLES_SUCCESS = 'FETCH_CONFIGURABLES_SUCCESS',
  FETCH_CONFIGURABLES_RESET = 'FETCH_CONFIGURABLES_RESET',
}

import { createStyledComponent } from 'src/shared/utils/styles';
import { breakpoints } from 'src/app/styles/breakpoints';
import { colors } from 'src/app/styles/colors';
import styled from 'styled-components';
import * as C from '../../calendar.constants';

export const CalendarContainer = createStyledComponent<'div', {}>('div')`
  display: table;
  height: 100%;
  text-align: -webkit-center;
  max-width: 450px;
  min-width: 230px;
  z-index: 253;
  .DateInput_fang {
    display: none;
  }
  .CalendarMonth_caption {
    color: #0D79D6;
    font-size: 15px;
  }
  .DayPicker_weekHeaders {
    color: #9B9B9B;
  }
  .DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: 30%;
    &:first-of-type {
      left: -17px;
    }
    &:last-of-type {
      right: -17px;
    }
  }
  .DateRangePickerInput__disabled {
    background: #FFFFFF;
    color: #9B9B9B;
  }
  .CalendarMonth_table {
    tr {
      border-top: 2.5px solid #FFFFFF;
      border-bottom: 2.5px solid #FFFFFF;
      td {
        border: none;
        &.CalendarDay__selected_span,
        &.CalendarDay__selected_start,
        &.CalendarDay__selected_end {
          background: #0D79D6;
          color: #FFFFFF;
        }
        border: none !important;
      }
    }
  }
  .large {
    .DateInput {
      max-width: 90px;
    }
  }
}
`;
CalendarContainer.displayName = 'CalendarContainer';

export const ButtonContainer = createStyledComponent<'div', {}>('div')`
  display: table-cell;
  vertical-align: middle;
  &.${C.PREV},
  &.${C.NEXT} {
    width: 36px;
  }
  &.${C.PREV} {
    padding: 0 10px 0 0;
    @media (max-width: ${breakpoints.changeMedium}) {
      padding: 0 8px 0 0;
    }
  }
  &.${C.NEXT} {
    padding: 0 0 0 10px;
    @media (max-width: ${breakpoints.changeMedium}) {
      padding: 0 0 0 8px;
    }
  }
`;
ButtonContainer.displayName = 'ButtonContainer';

export const CalendarIconContainer = createStyledComponent<
  'div',
  { disabled? }
>('div')`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: table-cell;
  vertical-align: middle;
  padding-left: 5px;
`;
CalendarIconContainer.displayName = 'CalendarIconContainer';

export const DatesContainer = createStyledComponent<'div', {}>('div')`
  border: 1px solid ${colors.gray3};
`;
DatesContainer.displayName = 'DatesContainer';

export const DatesWrapper = createStyledComponent<'div', {}>('div')`
  display: table;
  margin: auto;
  width: 100%
`;
DatesWrapper.displayName = 'DatesWrapper';

export const IconContainer = createStyledComponent<'div', {}>('div')`
`;
IconContainer.displayName = 'IconContainer';

export const PickerContainer = createStyledComponent<'div', {}>('div')`
.DateInput_input {
  font-size: 0.875rem;
  font-weight: 600;
  border-bottom: none;
  }
`;
PickerContainer.displayName = 'PickerContainer';

export const ArrowIconContainer = createStyledComponent<'div', {}>('div')`
  display: inline-block;
  height: 17px;
  margin-left: 5px;
  margin-right: 5px;
  svg {
    path {
      fill: ${colors.blueLight};
    }
  }
  text-align: center;
  vertical-align: middle;
`;
ArrowIconContainer.displayName = 'ArrowIconContainer';

export const DoneButtonWrapper = createStyledComponent<'div', {}>('div')`
  border-top: 1px solid ${colors.grayLight};
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: right;
`;
DoneButtonWrapper.displayName = 'DoneButtonWrapper';

export const DoneButton = styled.button`
  background-color: #ffffff;
  border: 1px solid #0d79d6;
  border-radius: 1rem;
  color: #0d79d6;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 2rem;
  width: 6.25rem;
`;
DoneButton.displayName = 'DoneButton';

export const NavButtonContainer = createStyledComponent<'div', {}>('div')`
  border-radius: 50%;
  display: block;
  width: 34px;
  height: 34px;
  background: white;
  border: 1px solid ${colors.gray3};
  text-align: center;
  > span {
    margin-top: 9px;
  }
  svg path {
    fill: #0D79D6;
  }
  &.navBtn${C.PREV} {
    > span {
      transform: rotate(90deg) translate(3px,2px) !important;
    }
  }
  &.navBtn${C.NEXT} {
    > span {
      transform: rotate(268deg) translate(-3px,2px) !important;
    }
  }
`;
NavButtonContainer.displayName = 'NavButtonContainer';

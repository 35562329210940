import React from 'react';

import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';
import { BG_UNIT_KEYS } from 'src/domains/diagnostics/store/constants';
import { formatBGMeasurement } from 'src/domains/diagnostics/utils/measurements';

import {
  List,
  ListElement,
  ListElementSectionHeader,
  ListsContainer,
  ListSection,
} from '../graph-statistics.style';

const formatBGWithZeroCheck = (value, bloodGlucoseUnit) =>
  isNaN(value) || value === 0
    ? ' - '
    : formatBGMeasurement(bloodGlucoseUnit)(value) + ' ';

export const MetabolicStatisticsBloodGlucose = ({
  bloodGlucoseUnit,
  mean,
  meanBeforeMeal,
  meanAfterMeal,
  stdDev,
  stdDevMeanRatio,
}) => (
  <ListSection borderRight>
    <ListElementSectionHeader noIcon>
      <LocalizedText textKey="graphDetails.statistics.bloodGlucose.bloodGlucose" />
    </ListElementSectionHeader>
    <ListsContainer noIcon>
      <List flex={'none'}>
        <ListElement>
          <LocalizedText textKey="graphDetails.statistics.bloodGlucose.meanBloodGlucose" />
        </ListElement>
        <ListElement>
          <LocalizedText textKey="graphDetails.statistics.bloodGlucose.meanBloodGlucoseBeforeMeal" />
        </ListElement>
        <ListElement>
          <LocalizedText textKey="graphDetails.statistics.bloodGlucose.meanBloodGlucoseAfterMeal" />
        </ListElement>
        <ListElement>
          <LocalizedText textKey="graphDetails.statistics.bloodGlucose.standardDeviationSD" />
        </ListElement>
        <ListElement>
          <LocalizedText textKey="graphDetails.statistics.bloodGlucose.stdDevMeanRatio" />
        </ListElement>
      </List>
      <List>
        <ListElement bold>
          {formatBGWithZeroCheck(mean, bloodGlucoseUnit)}
          <LocalizedText textKey={BG_UNIT_KEYS[bloodGlucoseUnit]} />
        </ListElement>
        <ListElement bold>
          {formatBGWithZeroCheck(meanBeforeMeal, bloodGlucoseUnit)}
          <LocalizedText textKey={BG_UNIT_KEYS[bloodGlucoseUnit]} />
        </ListElement>
        <ListElement bold>
          {formatBGWithZeroCheck(meanAfterMeal, bloodGlucoseUnit)}
          <LocalizedText textKey={BG_UNIT_KEYS[bloodGlucoseUnit]} />
        </ListElement>
        <ListElement bold>
          {`${formatBGMeasurement(bloodGlucoseUnit)(stdDev)} `}
          <LocalizedText textKey={BG_UNIT_KEYS[bloodGlucoseUnit]} />
        </ListElement>
        <ListElement bold>
          {isNaN(stdDevMeanRatio) ? ' - ' : stdDevMeanRatio + ' '}%
        </ListElement>
      </List>
      <List flex={8} />
    </ListsContainer>
  </ListSection>
);

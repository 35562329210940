import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getConfig } from '@roche/roche-common';
import { selectManifestModuleNameListKeys } from 'src/app/modules/store/manifest-modules/manifestModules.selectors';
import { GeneralInformationViewComponent } from './general-info-view.component';
import { selectHasRebranding } from 'src/domains/permissions/store/permissions.selectors';

const rdcpManufactureDate = getConfig().REACT_APP_RDCP_MANUFACTURE_DATE
  ? getConfig().REACT_APP_RDCP_MANUFACTURE_DATE
  : '';

export const GeneralInfoViewContainer = compose(
  connect((state) => ({
    moduleDataList: selectManifestModuleNameListKeys(state),
    rdcpManufactureDate,
    rebranding: selectHasRebranding(state),
  })),
)(GeneralInformationViewComponent);

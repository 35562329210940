import styled from 'styled-components';
import { space } from 'styled-system';

import { colors } from 'src/app/styles/colors';
import { fontSize } from 'src/app/styles/font-sizes';
import { spacing } from 'src/app/styles/spacing';
import { zIndexes } from 'src/app/styles/z-index';

export const Header = styled.div`
  padding: ${spacing.two};
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.grayLighter};
  background-color: ${colors.white};
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
`;

export const Row = styled.div`
  padding: ${spacing.two};
  background-color: ${colors.white};
  width: 100%;
`;
export const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 12.5rem;
  position: relative;
  z-index: ${zIndexes.overlay};

  & ${Row}:nth-child(odd) {
    background-color: ${colors.silverLight};
  }
`;

export const InnerRow = styled.div`
  display: flex;
  justify-content: space-between;
  ${space};
`;

export const GlucoseIconWrapper = styled.div`
  white-space: nowrap;
  margin-right: 0.25rem;
  display: flex;
  align-items: center;
  > * {
    padding-left: ${spacing.one};
`;

export const ManualLabelWrapper = styled.div`
  color: ${({
    manuallyEntered,
    theme: {
      colors: { green, red },
    },
  }) => (manuallyEntered ? green : red)};
  font-size: ${({ theme: { fontSize } }) => fontSize.graphLabel};
  line-height: ${({ theme: { fontSize } }) => fontSize.graphLabel};
  letter-spacing: ${({ theme: { letterSpacing } }) => letterSpacing.small};
  text-transform: uppercase;
  margin-bottom: 0.3125rem;
`;

export const MeasurementValue = styled.span`
  margin-left: 0.25rem;
`;

const Label = styled.span`
  ${space};
`;

export const SmallerLabel = styled(Label)`
  font-size: ${fontSize.graphLabel};
`;

export const SmallLabel = styled(Label)`
  font-size: ${fontSize.caption};
`;

export const MediumLabel = styled(Label)`
  font-size: ${fontSize.p};
`;

export const LargeLabel = styled(Label)`
  font-size: ${fontSize.subheading};
`;

export const GrayMediumLabel = styled(MediumLabel)`
  color: ${colors.charcoal};
`;

export const GraySmallLabel = styled(SmallLabel)`
  color: ${colors.charcoal};
`;

export const LightGraySmallLabel = styled(SmallLabel)`
  color: ${colors.silverLight};
`;

import {
  GetRpmSettingsLoaderImpl,
  GetRpmSettingsServiceImpl,
  GetRpmSettingsTransformImpl,
  UpdateRpmSettingsLoaderImpl,
  UpdateRpmSettingsServiceImpl,
  UpdateRpmSettingsTransformImpl,
} from './rpm-settings.service';

import { mockRpmSettings } from './rpm-settings.mock';

const mockLoader = () => Promise.resolve(mockRpmSettings);

export const GetRpmSettingsFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : GetRpmSettingsLoaderImpl;
  return GetRpmSettingsServiceImpl(loader, GetRpmSettingsTransformImpl);
};

export const UpdateRpmSettingsFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : UpdateRpmSettingsLoaderImpl;
  return UpdateRpmSettingsServiceImpl(loader, UpdateRpmSettingsTransformImpl);
};

import { selectAccessToken } from '../../../../app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from '../../../../app/session/core/config/config.selectors';
import { find, path, propEq, propOr } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { selectCountries } from '../../../patient/store/countries/countries.selectors';
import { selectConfigurablePermissions } from '../../../patient/store/configurables/configurables.selectors';
import {
  selectDepartmentTypes,
  selectDepartmentTypesProfessional,
} from '../../../patient/store/department/department.selectors';
import { selectLanguages } from '../../../patient/store/languages/languages.selectors';

import {
  selectEC6DepartmentId,
  selectEC6UserCountryId,
  selectEC6UserId,
} from '../../../../app/store/user/user.selectors';

import {
  convertActiveLanguagesToDropdownOptions,
  convertEC6CountriesToDropdownOptions,
  convertProfessionaTypesToDropdownOptions,
} from '../../../../widgets/forms/store/utils';

const selectCreateProfessionalState = path(['createProfessional']);

const selectIsCreatingProfessional = createSelector(
  selectCreateProfessionalState,
  path(['isCreatingProfessional']),
);

const selectCreationWasSuccessful = createSelector(
  selectCreateProfessionalState,
  path(['creationWasSuccessful']),
);

const selectCreationHasError = createSelector(
  selectCreateProfessionalState,
  path(['creationHasError']),
);

export const selectNewProfessional = createSelector(
  selectCreateProfessionalState,
  (state) => path(['newProfessional'])(state),
);

export const selectCountryDropdownOptions = createSelector(
  selectCountries,
  (countries) => convertEC6CountriesToDropdownOptions(countries),
);

export const selectLanguageDropdownOptions = createSelector(
  selectLanguages,
  (languages) => convertActiveLanguagesToDropdownOptions(languages),
);

export const selectProfessionalTypesDropdownOptions = createSelector(
  selectDepartmentTypesProfessional,
  (types) => convertProfessionaTypesToDropdownOptions(types),
);

export const selectDefaultProfessionalDepartmentId = createSelector(
  selectDepartmentTypesProfessional,
  (types) => {
    const def = find(propEq('name', 'ADMINISTRATIVE'))(types);
    return propOr(null, 'departmentProfileId')(def);
  },
);

export const createProfessionalConnector = (state) => {
  return createStructuredSelector({
    accessToken: selectAccessToken,
    apiKey: selectGigyaToken,
    countries: selectCountryDropdownOptions,
    departmentId: selectEC6DepartmentId,
    languages: selectLanguageDropdownOptions,
    professionals: selectProfessionalTypesDropdownOptions,
    professionalsDefaultId: selectDefaultProfessionalDepartmentId,
    professionalId: selectEC6UserId,
    creationWasSuccessful: selectCreationWasSuccessful,
    creationHasError: selectCreationHasError,
    isCreatingProfessional: selectIsCreatingProfessional,
    newProfessional: selectNewProfessional,
    departmentProfileTypes: selectDepartmentTypes,
    configurablePermissions: selectConfigurablePermissions,
    countryId: selectEC6UserCountryId,
    departmentTypes: selectDepartmentTypesProfessional,
  });
};

enum HIandLOAlertTypesEnum {
  BG = 'BG',
  CGM = 'CGM',
}

export const HIandLOAlertTypes = {
  BG: HIandLOAlertTypesEnum.BG,
  CGM: HIandLOAlertTypesEnum.CGM,
};

export type HiAndLoValuesAlertProps = {
  hiAndLoValues?: {
    numberOfHiValues: number;
    numberOfLoValues: number;
  };
  type: HIandLOAlertTypesEnum;
  ref?: {};
};

import styled from 'styled-components';

import { GridItem } from 'src/shared/design-system/grid-layout/index';

const mediumBreakpoint = '1550px';

export const BasalBolusGrid = styled(GridItem)`
  @media (max-width: ${mediumBreakpoint}) {
    grid-column: span 12;
    margin-bottom: -1rem;

    table {
      margin-bottom: 1.5rem;
    }
  }
`;

import { includes, isNil } from 'ramda';
import { compose } from 'recompose';

import {
  average,
  fixToDecimalPlace,
  standardDeviation,
} from 'src/shared/utils/stat';
import {
  diffDays,
  isAfter,
  isBefore,
  plusMonths,
  plusQuarters,
  plusWeeks,
  toDayOfYearFormat,
  toEndOfISOWeek,
  toEndOfMonth,
  toEndOfQuarter,
  toFormat,
  toStartOfQuarter,
} from 'src/shared/utils/date';
import { isDatetimeWithinInterval } from 'src/domains/diagnostics/time';
import {
  groupByDay,
  groupByMonthFilled,
  groupByQuarterFilled,
  groupByWeekFilled,
  isAfterInterval,
  isBeforeInterval,
  isEqualInterval,
  subIntervals,
  toInterval,
} from 'src/domains/diagnostics/utils/time.util';
import { calculateIntervalAverageTestsPerDay } from 'src/domains/diagnostics/utils/measurements';
import {
  PAGER_TYPE,
  TIME_INTERVAL,
} from 'src/domains/diagnostics/constants/diagnostics.constants';
import {
  calculateLowBloodGlucoseIndex,
  roundToNDecimalPlaces,
} from 'src/domains/diagnostics/scenes/graphs/graph-statistics.util';
import { BLOOD_GLUCOSE_UNITS } from 'src/domains/diagnostics/store/constants';

import {
  HYPO_RISK_THRESHOLDS,
  MINIMUM_DAYS_FOR_INTERVAL,
  MINIMUM_LBGI_CALCULATED_VALUE,
  MINIMUM_MEASUREMENTS_PER_DAY,
  MINIMUM_MEASUREMENTS_TO_CALCULATE_STATISTICS,
  MINIMUM_TESTS_FOR_INTERVAL,
  NO_MEASUREMENTS_ENOUGH_LABEL,
  NO_MEASUREMENTS_INTERVAL_LABEL,
  NUMBER_BLOOD_GLUCOSE_OVERVIEW_COLUMNS,
  TRAFFIC_LIGHT_COLORS,
  TRAFFIC_LIGHT_LABELS,
  VARIABILITY_THRESHOLD,
} from './blood-glucose-overview.constants';

const toTrafficLightInsufficientLabel = (numberOfMeasurements) =>
  numberOfMeasurements === 0
    ? NO_MEASUREMENTS_INTERVAL_LABEL
    : TRAFFIC_LIGHT_LABELS.INSUFFICIENT_INFO;

const isSufficientInformation = (numberOfMeasurements) =>
  numberOfMeasurements >= MINIMUM_TESTS_FOR_INTERVAL;

export const isLbgiDataValid = (
  { totalSecondHalf, totalFirstHalf },
  numberOfDaysInDateSliderRange,
) =>
  totalSecondHalf / numberOfDaysInDateSliderRange >=
    MINIMUM_LBGI_CALCULATED_VALUE &&
  totalFirstHalf / numberOfDaysInDateSliderRange >=
    MINIMUM_LBGI_CALCULATED_VALUE;

export const isDateRangeLessThanEqualsToThreeWeeksAndContainsThreeDaysWithSixMeasurements =
  (groupByDayMeasurements, numberOfDaysInDateSliderRange) => {
    if (numberOfDaysInDateSliderRange > MINIMUM_DAYS_FOR_INTERVAL) {
      return false;
    }
    const groupByDayMeasurementsLength = groupByDayMeasurements.length;
    const isThereAnyDayWithAtLeastSixMeasurements = groupByDayMeasurements.some(
      ({ group }) => group.length >= MINIMUM_MEASUREMENTS_PER_DAY,
    );
    if (!isThereAnyDayWithAtLeastSixMeasurements) {
      return false;
    }
    let areThereThreeConsecutiveDaysWithAtLeastSixMeasurements = false;
    for (let i = 0; i < groupByDayMeasurementsLength - 2; i++) {
      if (
        isMinMeasurementsCompletedForThreeConsecutiveDays(
          groupByDayMeasurements[i].group.length,
          groupByDayMeasurements[i + 1].group.length,
          groupByDayMeasurements[i + 2].group.length,
        ) &&
        differenceBetweenDaysIsLessThanOneDay(
          groupByDayMeasurements[i].interval.start,
          groupByDayMeasurements[i + 1].interval.start,
          groupByDayMeasurements[i + 2].interval.start,
        )
      ) {
        areThereThreeConsecutiveDaysWithAtLeastSixMeasurements = true;
      }
    }
    return areThereThreeConsecutiveDaysWithAtLeastSixMeasurements;
  };
const isMinMeasurementsCompletedForThreeConsecutiveDays = (
  firstDayMeasurements,
  secondDayMeasurements,
  thirdDayMeasurements,
) =>
  firstDayMeasurements >= MINIMUM_MEASUREMENTS_PER_DAY &&
  secondDayMeasurements >= MINIMUM_MEASUREMENTS_PER_DAY &&
  thirdDayMeasurements >= MINIMUM_MEASUREMENTS_PER_DAY;

const differenceBetweenDaysIsLessThanOneDay = (
  firstDay,
  secondDay,
  thirdDay,
) => {
  const { days: daysBetweenSecondAndFirstDay } = diffDays(
    secondDay,
    firstDay,
  ).toObject();
  const { days: daysBetweenThirdAndSecondDay } = diffDays(
    thirdDay,
    secondDay,
  ).toObject();
  return (
    Math.ceil(daysBetweenSecondAndFirstDay) === 1 &&
    Math.ceil(daysBetweenThirdAndSecondDay) === 1
  );
};

const isEvenlyDistributed = (
  groupByDayMeasurements,
  intervals,
  numberOfDaysInDateSliderRange,
) => {
  const evenlyDistributedNumbers = groupByDayMeasurements
    .map((groupByDayMeasurements) =>
      groupByDayMeasurements.group.reduce(
        (acc, measurement) => {
          isDatetimeWithinInterval(
            measurement.date,
            intervals.firstHalf.startTime,
            intervals.firstHalf.endTime,
          )
            ? acc.firstHalf++
            : acc.secondHalf++;

          return acc;
        },
        {
          firstHalf: 0,
          secondHalf: 0,
        },
      ),
    )
    .reduce(
      (acc, halfDaysCounts) => {
        acc.total++;
        acc.totalFirstHalf += halfDaysCounts.firstHalf;
        acc.totalSecondHalf += halfDaysCounts.secondHalf;
        if (halfDaysCounts.firstHalf > 0 && halfDaysCounts.secondHalf > 0) {
          acc.passed++;
        }
        return acc;
      },
      {
        total: 0,
        passed: 0,
        totalFirstHalf: 0,
        totalSecondHalf: 0,
      },
    );
  return isLbgiDataValid(
    evenlyDistributedNumbers,
    numberOfDaysInDateSliderRange,
  );
};

const dropdownBGOverviewIntervalsConverter = (dropdownBGOverviewIntervals) => {
  switch (dropdownBGOverviewIntervals) {
    case TIME_INTERVAL.WEEKLY_INTERVALS:
      return 7;
    case TIME_INTERVAL.MONTHLY_INTERVALS:
      return 30;
    case TIME_INTERVAL.QUARTERLY_INTERVALS:
      return 90;
    default:
      return dropdownBGOverviewIntervals;
  }
};

export const hasReliableInfo = (
  measurements,
  testsPerDay,
  dayInHalfTimeIntervals,
  numberOfDaysInDateSliderRange,
  dropdownBGOverviewIntervals,
) => {
  const selectedTimeRange = dropdownBGOverviewIntervals
    ? dropdownBGOverviewIntervalsConverter(dropdownBGOverviewIntervals)
    : numberOfDaysInDateSliderRange;
  const sufficientInformation = isSufficientInformation(measurements.length);
  const groupMeasurementsByDay = groupByDay(measurements);
  const threeConsecutiveDaysWithMin6Measurements =
    isDateRangeLessThanEqualsToThreeWeeksAndContainsThreeDaysWithSixMeasurements(
      groupMeasurementsByDay,
      selectedTimeRange,
    );
  const evenlyDistributed = isEvenlyDistributed(
    groupMeasurementsByDay,
    dayInHalfTimeIntervals,
    selectedTimeRange,
  );
  return (
    (sufficientInformation && evenlyDistributed) ||
    threeConsecutiveDaysWithMin6Measurements
  );
};

export const determineHasReliableInfo = (
  groupedMeasurements,
  dayInHalfIntervals,
  numberOfDaysInDateSliderRange,
  dropdownBGOverviewIntervals,
) =>
  groupedMeasurements.map((intervalGroup) => ({
    ...intervalGroup,
    conditionsMet: hasReliableInfo(
      intervalGroup.measurements,
      intervalGroup.testsPerDay,
      dayInHalfIntervals,
      numberOfDaysInDateSliderRange,
      dropdownBGOverviewIntervals,
    ),
  }));

export const formatWeeklyInterval = (interval) => {
  const getMonthShortForm = (date) => date.toLocaleString({ month: 'short' });
  const startMonth = getMonthShortForm(interval.start);
  const startDay = toFormat('dd')(interval.start);
  const endMonth = getMonthShortForm(interval.end);
  const endDay = toFormat('dd')(interval.end);
  const endYear = toFormat('yyyy')(interval.end);
  const startYear = toFormat('yyyy')(interval.start);
  const yearLabel =
    endYear === startYear ? endYear.toUpperCase() : `${startYear} - ${endYear}`;
  return {
    top: `${startMonth} ${startDay} - ${endMonth} ${endDay}`.toUpperCase(),
    bottom: yearLabel.toUpperCase(),
  };
};
export const formatMonthlyInterval = (interval) => ({
  top: toFormat('MMM / yyyy')(interval.start).toUpperCase(),
  bottom: null,
});

export const formatQuarterlyInterval = (interval) => {
  const date = interval.start;
  const quarterStart = toStartOfQuarter(date);
  const year = toFormat('yyyy')(quarterStart);
  const quarter = `${date.quarter} / ${year}`;
  return {
    top: `Q${quarter}`.toUpperCase(),
    bottom: null,
  };
};

const createFormatGroupByInterval =
  (intervalFormatter) => (groupedMeasurements) =>
    groupedMeasurements.map((groupedMeasurement) => ({
      ...groupedMeasurement,
      interval: intervalFormatter(groupedMeasurement.interval),
    }));

const formatGroupByWeekInterval =
  createFormatGroupByInterval(formatWeeklyInterval);
const formatGroupByMonthInterval = createFormatGroupByInterval(
  formatMonthlyInterval,
);
const formatGroupByQuarterInterval = createFormatGroupByInterval(
  formatQuarterlyInterval,
);

const toMeasurementsGroups = (groupedMeasurement) =>
  groupedMeasurement
    .map(({ interval, group }) => ({
      interval,
      measurements: group,
    }))
    .slice(0, 6) // might slice off the 7th interval which is partial and we don't display
    .reverse();

const groupByTimeIntervalTransformers = {
  [TIME_INTERVAL.QUARTERLY_INTERVALS]: compose(
    toMeasurementsGroups,
    groupByQuarterFilled,
  ),
  [TIME_INTERVAL.MONTHLY_INTERVALS]: compose(
    toMeasurementsGroups,
    groupByMonthFilled,
  ),
  [TIME_INTERVAL.WEEKLY_INTERVALS]: compose(
    toMeasurementsGroups,
    groupByWeekFilled,
  ),
};

export const groupByTimeInterval = (
  timeInterval = TIME_INTERVAL.WEEKLY_INTERVALS,
  clinicalData = [],
  endDate,
  numberOfColumns,
  firstMeasurementDate,
  lastMeasurementDate,
) => {
  let result = [];
  if (clinicalData.length > 0) {
    result = groupByTimeIntervalTransformers[timeInterval](
      clinicalData,
      endDate,
      numberOfColumns,
      firstMeasurementDate,
      lastMeasurementDate,
    );
  }
  return result;
};
const toLabelIntervalTransformers = {
  [TIME_INTERVAL.QUARTERLY_INTERVALS]: formatGroupByQuarterInterval,
  [TIME_INTERVAL.MONTHLY_INTERVALS]: formatGroupByMonthInterval,
  [TIME_INTERVAL.WEEKLY_INTERVALS]: formatGroupByWeekInterval,
};
export const toLabelInterval = (groupedMeasurements, interval) =>
  toLabelIntervalTransformers[interval](groupedMeasurements);

export const calculateIntervalEmptyDays = (groupedMeasurements) =>
  groupedMeasurements.map((intervalGroup) => {
    const {
      interval: { start, end },
      measurements,
    } = intervalGroup;
    const daysWithMeasurement = measurements.reduce((acc, { date }) => {
      const day = toDayOfYearFormat(date);
      if (!includes(day, acc)) {
        acc.push(day);
      }
      return acc;
    }, []);

    const numberIntervalOfDays = Math.round(diffDays(end, start).values.days);
    const numberOfMissingDays =
      numberIntervalOfDays - daysWithMeasurement.length;
    return {
      ...intervalGroup,
      numberOfMissingDays,
    };
  });

export const calculateBloodGlucoseStartDate = (
  endDate,
  timeInterval,
  numberOfColumns,
) => subIntervals(endDate, timeInterval, numberOfColumns - 1).start;

export const calculateMeanBloodGlucose = (groupedMeasurements) =>
  groupedMeasurements.map(({ interval, measurements, conditionsMet }) => {
    const measurementValues = measurements.map(
      (measurement) => measurement.value,
    );

    const valuesForAverage = measurements
      .filter((measurement) => measurement.value)
      .map((filteredMeasurement) => filteredMeasurement.value);

    const mean = fixToDecimalPlace(average(valuesForAverage), 1);

    return {
      interval,
      value: mean,
      numberOfMeasurements: measurementValues.length,
      conditionsMet,
    };
  });

export const calculateForVariability = (
  groupedMeasurementIntervals,
  bloodGlucoseUnit,
) =>
  groupedMeasurementIntervals.map(
    ({ interval, measurements, conditionsMet }) => {
      const measurementValues = measurements.map(
        (measurement) => measurement.value,
      );
      const mean =
        bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L
          ? fixToDecimalPlace(average(measurementValues), 1)
          : average(measurementValues).toFixed();
      const standardDev =
        bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L
          ? fixToDecimalPlace(standardDeviation(measurementValues), 1)
          : standardDeviation(measurementValues).toFixed();
      return {
        interval,
        numberOfMeasurements: measurementValues.length,
        value: fixToDecimalPlace((standardDev / mean) * 100, 1),
        conditionsMet,
      };
    },
  );

export const createCalculateForHypoglycaemia =
  (hypoglycaemiaThreshold) => (groupedMeasurementIntervals) =>
    groupedMeasurementIntervals.map(
      ({ interval, measurements, conditionsMet }) => {
        const hypoglycaemiaCount = measurements.reduce(
          (count, { value }) =>
            value < hypoglycaemiaThreshold ? count + 1 : count,
          0,
        );

        return {
          interval,
          numberOfMeasurements: measurements.length,
          value: hypoglycaemiaCount,
          conditionsMet,
        };
      },
    );

export const calculateVariabilityStatus = (value, numberOfMeasurements) => {
  if (numberOfMeasurements === 0) {
    return {
      color: TRAFFIC_LIGHT_COLORS.GRAY,
      label: TRAFFIC_LIGHT_LABELS.NO_INFO,
    };
  } else if (
    isNil(value) ||
    isNaN(value) ||
    numberOfMeasurements < MINIMUM_MEASUREMENTS_TO_CALCULATE_STATISTICS
  ) {
    return {
      color: TRAFFIC_LIGHT_COLORS.GRAY,
      label: TRAFFIC_LIGHT_LABELS.INSUFFICIENT_INFO,
    };
  } else if (value <= VARIABILITY_THRESHOLD) {
    return {
      color: TRAFFIC_LIGHT_COLORS.GREEN,
      label: TRAFFIC_LIGHT_LABELS.STABLE,
    };
  } else {
    return {
      color: TRAFFIC_LIGHT_COLORS.RED,
      label: TRAFFIC_LIGHT_LABELS.UNSTABLE,
    };
  }
};

export const toVariabilityStatus = (groupedValues) =>
  groupedValues.map(({ numberOfMeasurements, value }) =>
    calculateVariabilityStatus(value, numberOfMeasurements),
  );

export const calculateAvgTestsPerDay = (groupedMeasurements) =>
  groupedMeasurements.map((intervalGroup) => {
    const {
      interval: { start, end },
      measurements,
    } = intervalGroup;

    const testsPerDay = calculateIntervalAverageTestsPerDay(
      measurements,
      start,
      end,
    );

    return {
      ...intervalGroup,
      testsPerDay,
    };
  });

export const formatTestsPerDayAvg = (groups) =>
  groups.map((group) =>
    group.testsPerDay === 0
      ? NO_MEASUREMENTS_INTERVAL_LABEL
      : Number(group.testsPerDay).toFixed(1),
  );

export const isHypoRed = (value, hypoglycemiaThreshold) =>
  value > 0 && value < hypoglycemiaThreshold;

export const isHypoYellow = (
  value,
  hypoglycemiaThreshold,
  glucoseIdealIntervalMin,
) => value >= hypoglycemiaThreshold && value < glucoseIdealIntervalMin;

export const isInRange = (
  value,
  glucoseIdealIntervalMin,
  glucoseIdealIntervalMax,
) => value >= glucoseIdealIntervalMin && value <= glucoseIdealIntervalMax;

export const isHyperYellow = (value, glucoseIdealIntervalMax, upperThreshold) =>
  value > glucoseIdealIntervalMax && value <= upperThreshold;

export const isHyperRed = (value, upperThreshold) => value > upperThreshold;

export const calculateMeanColor = (
  value,
  {
    hypoglycemiaThreshold,
    glucoseIdealIntervalMin,
    glucoseIdealIntervalMax,
    upperHyperThreshold,
  },
  numberOfMeasurements,
) => {
  if (
    typeof numberOfMeasurements !== 'undefined' &&
    numberOfMeasurements === 0
  ) {
    return TRAFFIC_LIGHT_COLORS.GRAY;
  }
  if (
    isHypoRed(value, hypoglycemiaThreshold) ||
    isHyperRed(value, upperHyperThreshold)
  ) {
    return TRAFFIC_LIGHT_COLORS.RED;
  }
  if (
    isHypoYellow(value, hypoglycemiaThreshold, glucoseIdealIntervalMin) ||
    isHyperYellow(value, glucoseIdealIntervalMax, upperHyperThreshold)
  ) {
    return TRAFFIC_LIGHT_COLORS.YELLOW;
  }
  if (isInRange(value, glucoseIdealIntervalMin, glucoseIdealIntervalMax)) {
    return TRAFFIC_LIGHT_COLORS.GREEN;
  }
  return TRAFFIC_LIGHT_COLORS.GRAY;
};

export const calculateMeanLabel = (
  value,
  { glucoseIdealIntervalMin, glucoseIdealIntervalMax },
) => {
  if (value > 0 && value < glucoseIdealIntervalMin) {
    return TRAFFIC_LIGHT_LABELS.HYPO;
  }
  if (value > glucoseIdealIntervalMax) {
    return TRAFFIC_LIGHT_LABELS.HYPER;
  }
  if (value >= glucoseIdealIntervalMin && value <= glucoseIdealIntervalMax) {
    return TRAFFIC_LIGHT_LABELS.IN_RANGE;
  }
};

export const toMeanBloodGlucoseStatus = (meanGlucoseValues, thresholds) =>
  meanGlucoseValues.map(({ value, numberOfMeasurements, conditionsMet }) => ({
    color:
      numberOfMeasurements === 0 ||
      numberOfMeasurements < MINIMUM_MEASUREMENTS_TO_CALCULATE_STATISTICS
        ? TRAFFIC_LIGHT_COLORS.GRAY
        : calculateMeanColor(value, thresholds, numberOfMeasurements),
    label:
      numberOfMeasurements === 0 ||
      numberOfMeasurements < MINIMUM_MEASUREMENTS_TO_CALCULATE_STATISTICS
        ? toTrafficLightInsufficientLabel(numberOfMeasurements)
        : calculateMeanLabel(value, thresholds),
  }));

export const toMeanBloodGlucoseValue = (meanGlucoseValues) =>
  meanGlucoseValues.map(({ value, numberOfMeasurements }) =>
    numberOfMeasurements === 0
      ? NO_MEASUREMENTS_INTERVAL_LABEL
      : numberOfMeasurements < MINIMUM_MEASUREMENTS_TO_CALCULATE_STATISTICS
      ? NO_MEASUREMENTS_ENOUGH_LABEL
      : value,
  );

export const toHypoglycaemiaCount = (groupedValues) =>
  groupedValues.map(({ value, numberOfMeasurements }) =>
    numberOfMeasurements === 0
      ? NO_MEASUREMENTS_INTERVAL_LABEL
      : value.toString(),
  );

export const toTimeIntervals = (groupedValues) =>
  groupedValues.map(({ interval, numberOfMissingDays }) => ({
    label: interval,
    info: numberOfMissingDays
      ? [
          `${numberOfMissingDays}`,
          'dashboard.statusCard.monthLabels.daysMissing',
        ]
      : null,
  }));

export const calculateEndDateAfterPagination = (
  pagerType,
  pagerEndDate,
  interval,
  earlieastMeasurementDate,
  lastestMeasurementDate,
) => {
  const addIntervalTransformers = {
    [TIME_INTERVAL.WEEKLY_INTERVALS]: plusWeeks,
    [TIME_INTERVAL.MONTHLY_INTERVALS]: plusMonths,
    [TIME_INTERVAL.QUARTERLY_INTERVALS]: plusQuarters,
  };

  const addIntervalAmountByPager = {
    [PAGER_TYPE.NEXT]: 1,
    [PAGER_TYPE.SUPER_NEXT]: NUMBER_BLOOD_GLUCOSE_OVERVIEW_COLUMNS,
    [PAGER_TYPE.PREV]: -1,
    [PAGER_TYPE.SUPER_PREV]: -NUMBER_BLOOD_GLUCOSE_OVERVIEW_COLUMNS,
  };

  const endOfInterval = {
    [TIME_INTERVAL.WEEKLY_INTERVALS]: toEndOfISOWeek,
    [TIME_INTERVAL.MONTHLY_INTERVALS]: toEndOfMonth,
    [TIME_INTERVAL.QUARTERLY_INTERVALS]: toEndOfQuarter,
  };

  const newPagerEndDate = addIntervalTransformers[interval](
    pagerEndDate,
    addIntervalAmountByPager[pagerType],
  );

  const endDateOffsetFromFirstDate = addIntervalTransformers[interval](
    earlieastMeasurementDate,
    NUMBER_BLOOD_GLUCOSE_OVERVIEW_COLUMNS - 1,
  );

  const isForwardMoving =
    pagerType === PAGER_TYPE.NEXT || pagerType === PAGER_TYPE.SUPER_NEXT;

  const isBackWardMoving =
    pagerType === PAGER_TYPE.PREV || pagerType === PAGER_TYPE.SUPER_PREV;

  if (isForwardMoving && isAfter(newPagerEndDate, lastestMeasurementDate)) {
    return lastestMeasurementDate.toJSDate();
  }
  if (
    isBackWardMoving &&
    isBefore(newPagerEndDate, endDateOffsetFromFirstDate)
  ) {
    return endDateOffsetFromFirstDate.toJSDate();
  }

  return endOfInterval[interval](newPagerEndDate).toJSDate();
};

export const isLastInterval = (interval, endDate, lastMeasurementDate) => {
  const endInterval = toInterval(endDate, interval);
  const lastInterval = toInterval(lastMeasurementDate, interval);
  return (
    isEqualInterval(endInterval, lastInterval) ||
    isAfterInterval(endInterval, lastInterval)
  );
};

export const isFirstInterval = (interval, firstDate, firstMeasurementDate) => {
  const startInterval = toInterval(firstDate, interval);
  const firstInterval = toInterval(firstMeasurementDate, interval);

  const isEqual = isEqualInterval(startInterval, firstInterval);
  const isBefore = isBeforeInterval(startInterval, firstInterval);

  return isEqual || isBefore;
};

export const calculateHypoRisk = (measurements, bloodGlucoseUnit) => {
  const lbgi = calculateLowBloodGlucoseIndex(measurements, bloodGlucoseUnit);
  return {
    lbgi: roundToNDecimalPlaces(lbgi, 1),
    numberOfMeasurements: measurements.length,
  };
};

export const calculateHypoRiskForIntervals = (intervals, bloodGlucoseUnit) =>
  intervals.map((interval) => ({
    ...calculateHypoRisk(interval.measurements, bloodGlucoseUnit),
    conditionsMet: interval.conditionsMet,
  }));

export const isHypoRiskLow = (lbgi) =>
  lbgi <= HYPO_RISK_THRESHOLDS.MEDIUM && lbgi >= 0;
export const isHypoRiskMedium = (lbgi) =>
  lbgi <= HYPO_RISK_THRESHOLDS.HIGH && lbgi > HYPO_RISK_THRESHOLDS.MEDIUM;
export const isHypoRiskHigh = (lbgi) => lbgi > HYPO_RISK_THRESHOLDS.HIGH;

export const calculateHypoRiskColor = (
  lbgi,
  numberOfMeasurements,
  conditionsMet,
) => {
  if (
    numberOfMeasurements === 0 ||
    isNil(lbgi) ||
    (typeof conditionsMet !== 'undefined' && !conditionsMet)
  ) {
    return TRAFFIC_LIGHT_COLORS.GRAY;
  }
  if (isHypoRiskLow(lbgi)) {
    return TRAFFIC_LIGHT_COLORS.GREEN;
  } else if (isHypoRiskMedium(lbgi)) {
    return TRAFFIC_LIGHT_COLORS.YELLOW;
  } else if (isHypoRiskHigh(lbgi)) {
    return TRAFFIC_LIGHT_COLORS.RED;
  } else {
    return TRAFFIC_LIGHT_COLORS.GRAY;
  }
};

export const calculateHypoRiskLabel = (lbgi) => {
  if (isHypoRiskLow(lbgi)) {
    return TRAFFIC_LIGHT_LABELS.LOW;
  } else if (isHypoRiskMedium(lbgi)) {
    return TRAFFIC_LIGHT_LABELS.MEDIUM;
  } else if (isHypoRiskHigh(lbgi)) {
    return TRAFFIC_LIGHT_LABELS.HIGH;
  } else {
    return TRAFFIC_LIGHT_LABELS.NO_INFO;
  }
};

export const toHypoRiskStatus = (hypoRisks) =>
  hypoRisks.map(({ lbgi, numberOfMeasurements, conditionsMet }) => ({
    color: calculateHypoRiskColor(lbgi, numberOfMeasurements, conditionsMet),
    label: conditionsMet
      ? calculateHypoRiskLabel(lbgi)
      : toTrafficLightInsufficientLabel(numberOfMeasurements),
  }));

import * as React from 'react';
import styles from 'src/widgets/top-navigation-jello/styles.module.css';

import { BackHomeButtonProps } from 'src/widgets/top-navigation-jello/top-navigation-jello.types';

import {
  BACK_HOME_BUTTON_ICON_NAME,
  BACK_HOME_BUTTON_ID,
} from 'src/widgets/top-navigation-jello/top-navigation-jello.constants';
import { JelloIconButton } from 'src/shared/design-system/jello-icon-button/jello-icon-button.component';
import { JelloTooltip } from 'src/shared/design-system/jello-tooltip/jello-tooltip.component';
import { Link } from 'src/shared/design-system/link/link.component';
import {
  JELLO_ICON_BUTTON_VARIANTS,
  JELLO_ICON_NAMES,
  JELLO_ICON_SIZES,
  JELLO_TOOLTIP_POSITIONS,
} from 'src/app/app.jello.constants';
import { t } from 'i18next';

export const BackHomeButtonComponent = ({
  routes,
  clearSearchInput,
}: BackHomeButtonProps) => {
  return (
    <JelloTooltip
      id={BACK_HOME_BUTTON_ID}
      label={t('toolbar.home')}
      position={JELLO_TOOLTIP_POSITIONS.BOTTOM}
    >
      <Link to={routes.general.home} onClick={clearSearchInput}>
        <JelloIconButton
          size={JELLO_ICON_SIZES.L}
          iconName={JELLO_ICON_NAMES[BACK_HOME_BUTTON_ICON_NAME]}
          variant={JELLO_ICON_BUTTON_VARIANTS.TERTIARY}
          customClass={styles.displayFlex}
        />
      </Link>
    </JelloTooltip>
  );
};

import { convertPxToRem } from 'src/domains/diagnostics/utils/rem-calc';

export const GRAPH_TREND = 'trend';
export const GRAPH_STANDARD_DAY = 'standard-day';
export const GRAPH_STANDARD_WEEK = 'standard-week';
export const GRAPH_METABOLIC_RATE = 'metabolic-rate';
export const GRAPH_INSULIN = 'insulin';
export const GRAPH_INSULIN_PUMP = 'insulin-pump';
export const GRAPH_LOGBOOK = 'logbook';
export const GRAPH_BLOOD_GLUCOSE_GENERAL_STATS = 'blood-glucose-general-stats';
export const GRAPH_TYPE_DETAILS = 'details';

export const GRAPHS = {
  TREND: GRAPH_TREND,
  STANDARD_DAY: GRAPH_STANDARD_DAY,
  STANDARD_WEEK: GRAPH_STANDARD_WEEK,
  METABOLIC_RATE: GRAPH_METABOLIC_RATE,
  INSULIN: GRAPH_INSULIN,
  INSULIN_PUMP: GRAPH_INSULIN_PUMP,
  LOGBOOK: GRAPH_LOGBOOK,
  BLOOD_GLUCOSE_GENERAL_STATS: GRAPH_BLOOD_GLUCOSE_GENERAL_STATS,
};

export const COLLAPSED_STD_GRAPH_HEIGHT = 288;
export const COLLAPSED_STD_GRAPH_HEIGHT_IN_REM = convertPxToRem(
  COLLAPSED_STD_GRAPH_HEIGHT,
);
export const COLLAPSED_LOGBOOK24H_MIN_HEIGHT = '15rem';

export const BASAL_MAX_VALUES = [0.4, 1, 2, 5];

export const BASAL_RATE_PLUS_BOLUS = 'BASAL_RATE_PLUS_BOLUS';

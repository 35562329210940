export const transformCurrentUser = (CurrentUserData) => ({
  areaId: CurrentUserData.areaId,
  birthday: CurrentUserData.birthday,
  centerId: CurrentUserData.centerId,
  centerName: CurrentUserData.centerName,
  countryId: CurrentUserData.countryId,
  countryIsoCode: CurrentUserData.countryIsoCode,
  departmentId: CurrentUserData.departmentId,
  departmentName: CurrentUserData.departmentName,
  email: CurrentUserData.email,
  fullname: CurrentUserData.fullname,
  gender: CurrentUserData.gender,
  id: CurrentUserData.id,
  inactive: CurrentUserData.inactivate,
  language: CurrentUserData.language,
  languageCountryIsoCode: CurrentUserData.languageCountryIsoCode,
  languageId: CurrentUserData.languageId,
  centerLanguageId: CurrentUserData.address.country.language.id,
  languageIsoCode: CurrentUserData.languageIsoCode,
  languageIsoCodeComplete: CurrentUserData.languageIsoCodeComplete,
  lastAccess: CurrentUserData.lastAccess,
  name: CurrentUserData.name,
  phone: CurrentUserData.phone,
  profile: CurrentUserData.profile,
  profileId: CurrentUserData.profileId,
  surname: CurrentUserData.surname,
  surname2: CurrentUserData.surname2,
  unitMeasurement: CurrentUserData.unitMeasurement,
  carbUnitMeasurement: CurrentUserData.carbUnitMeasurement,
  timeZone: CurrentUserData.address.country.timeZone,
  timeFormat: CurrentUserData.address.country.timeFormat,
  gigyaUid: CurrentUserData.gigyaUid,
});

import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ProtectedRoute } from 'src/shared/design-system/protected-route/protected-route.container';
import {
  PERMISSIONS,
  PATIENT_PERMISSIONS,
} from 'src/domains/permissions/store/permissions.constants';
import { connectWithPlatformPermission } from './utils/utils';
import { LoadingRing } from 'src/shared/design-system/loading-ring/loading-ring.component';
import { Column } from 'src/shared/design-system/column/column.component';

import {
  CreatePatientBundle,
  DeactivatePatientBundle,
  DeliveryConfigurationBundle,
  DeviceSettingsJelloBundle,
  EditPatientBundle,
  EditPatientBundleJello,
  EditPatientWithPlatformBundle,
  GraphicSettingsBundle,
  HomeDeliveryJelloBundle,
  ListDevicesBundle,
  NextShipmentBundle,
  TimePeriodsBundle,
} from './bundles';

export const patientLinks = {
  createPatient: '/patient/create',
  deactivatePatient: '/patients/:id/deactivate',
  delivery: '/patients/bg/:id/delivery',
  deliveryConfiguration: '/patients/bg/:id/delivery-configuration',
  deviceSettings: '/patients/bg/:id/device-settings',
  editPatient: '/patients/:id/edit',
  editTimePeriods: '/patients/:id/time-periods',
  graphSettings: '/patients/:id/graph-settings',
  listDevices: '/patients/:id/devices',
  nextShipment: '/patients/bg/:id/next-shipment',
  patientBGDashboard: '/patients/bg/:id',
  patientById: '/patients/:id',
  patients: '/patients',
  patientCGMDashboard: '/patients/cgm/:id',
};

const loadingRing = (
  <Column align="center" height="100vh" justifyContent="center">
    <LoadingRing infinite />
  </Column>
);

export const PatientRoutes = () => {
  const isNotBlindedPatient = (patientPermissions) => {
    return patientPermissions?.includes(PATIENT_PERMISSIONS.BLINDED_STUDY)
      ? false
      : true;
  };

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={patientLinks.editPatient}
        component={connectWithPlatformPermission((props) => {
          if (props.isFetchingPermissions) {
            return loadingRing;
          }

          return <EditPatientBundleJello />;
        })}
      />

      <ProtectedRoute
        exact
        path={patientLinks.deactivatePatient}
        component={DeactivatePatientBundle}
        hasPermissions={[PERMISSIONS.PATIENT_MANAGEMENT_DELETE]}
      />
      <ProtectedRoute
        exact
        path={patientLinks.createPatient}
        component={CreatePatientBundle}
        hasPermissions={[PERMISSIONS.REGISTER_PATIENT]}
      />
      <ProtectedRoute
        exact
        path={patientLinks.editTimePeriods}
        component={TimePeriodsBundle}
        hasPermissions={[PERMISSIONS.TIME_BLOCKS_MANAGEMENT]}
      />
      <ProtectedRoute
        exact
        path={patientLinks.listDevices}
        component={ListDevicesBundle}
        hasPermissions={[PERMISSIONS.PATIENT_DEVICES]}
      />
      <ProtectedRoute
        exact
        path={patientLinks.deviceSettings}
        hasPermissions={[PERMISSIONS.PATIENT_DEVICES]}
        accessConditions={[
          ({ patientPermissions }) => isNotBlindedPatient(patientPermissions),
        ]}
        validationRedirectPath={'/patients/:id'}
        component={DeviceSettingsJelloBundle}
      />
      <ProtectedRoute
        exact
        path={patientLinks.graphSettings}
        component={GraphicSettingsBundle}
        hasPermissions={[PERMISSIONS.RELEVANT_CLINICAL_DATA]}
      />
      <ProtectedRoute
        exact
        path={patientLinks.delivery}
        hasPermissions={[
          PERMISSIONS.NEXT_SHIPMENT,
          PERMISSIONS.PATIENT_DELIVERY_PROGRAM_CONFIG,
        ]}
        accessConditions={[
          ({ patientPermissions }) => isNotBlindedPatient(patientPermissions),
        ]}
        validationRedirectPath={'/patients/:id'}
        component={HomeDeliveryJelloBundle}
      />
      <ProtectedRoute
        exact
        path={patientLinks.deliveryConfiguration}
        component={DeliveryConfigurationBundle}
        hasPermissions={[PERMISSIONS.PATIENT_DELIVERY_PROGRAM_CONFIG]}
      />
      <ProtectedRoute
        exact
        path={patientLinks.nextShipment}
        component={NextShipmentBundle}
        hasPermissions={[PERMISSIONS.NEXT_SHIPMENT]}
      />
    </Switch>
  );
};

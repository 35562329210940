import { STATE_ACTIONS } from '../../../../../app/store/state/state.constants';

import {
  CreateProfessionalActions,
  CreateProfessionalActionType,
  CreateProfessionalState,
} from './create-professional.types';

const initialCreateProfessionalState: CreateProfessionalState = {
  firstName: '',
  surname: '',
  birthday: '',
  dateOfBirthMonth: '',
  dateOfBirthDay: '',
  dateOfBirthYear: '',
  phone: '',
  language: '',
  email: '',
  professional: null,
  hcpId: '',
  openConnectivity: null,
  creationHasError: false,
  creationWasSuccessful: false,
  isCreatingProfessional: false,
};

export const createProfessionalReducer = (
  state = initialCreateProfessionalState,
  action: CreateProfessionalActions,
): CreateProfessionalState => {
  switch (action.type) {
    case CreateProfessionalActionType.CREATE_PROFESSIONAL_START:
      return {
        ...state,
        creationHasError: false,
        creationWasSuccessful: false,
        isCreatingProfessional: true,
      };
    case CreateProfessionalActionType.CREATE_PROFESSIONAL_SUCCESS:
      return {
        ...state,
        creationHasError: false,
        creationWasSuccessful: true,
        isCreatingProfessional: false,
      };
    case CreateProfessionalActionType.CREATE_PROFESSIONAL_ERROR:
      return {
        ...state,
        creationHasError: true,
        creationWasSuccessful: false,
        isCreatingProfessional: false,
      };
    case STATE_ACTIONS.CLEAR_CREATE_PROFESSIONAL:
      const { language } = state;
      return {
        ...initialCreateProfessionalState,
        language,
      };
    default:
      return state;
  }
};

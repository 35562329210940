import React from 'react';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';

import { SvgIcon } from 'src/shared/design-system/icon/icon.component';

const SnackIconComponent = ({ height, width, t }) => {
  const originalWidth = 16;
  const originalHeight = 15;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  return (
    <SvgIcon
      title={t('graphs.iconTitles.snack')}
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g
        id="_icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="NEW-ICONS"
          transform="translate(-1221.000000, -526.000000)"
          fillRule="nonzero"
        >
          <g id="Group-17" transform="translate(1221.000000, 526.000000)">
            <path
              d="M13.4333942,0.101052632 C14.766971,-0.559703947 15.9177427,2.07055921 14.9240166,6.50194079 C14.374473,8.9525 12.439668,10.9333224 10.4521826,12.4104605 C8.46419917,13.8875987 4.98609129,11.9180921 6.97357676,9.94858553 C8.96106224,7.97907895 10.9490456,6.50194079 11.9427718,3.54769737 C12.5907054,1.62151316 12.4396349,0.593421053 13.4333942,0.101052632"
              id="Shape"
              fill="#FFE8B6"
            />
            <path
              d="M14.9240166,3.05532895 C14.9240166,4.53246711 14.4271535,7.48671053 12.9365311,9.45621711 C11.4459087,11.4257237 9.45842324,11.9180921 10.9490456,9.94858553 C12.439668,7.97907895 13.4333942,6.50194079 13.9302573,4.53243421 C14.4271203,2.56292763 14.9240166,1.08582237 14.9240166,3.05532895"
              id="Shape"
              fill="#FFD983"
            />
            <path
              d="M10.4521577,12.9527564 C10.3050622,12.661268 11.0319917,14.8400509 8.96103734,15.9075262 C7.97572614,16.4151742 3.99232365,15.4151331 6.97356846,15.4151331 C8.4646888,15.4151331 8.96103734,14.430388 7.9673029,13.445643 C7.8226971,13.301893 7.72481328,13.1487268 7.65825726,12.9965887 C7.45153527,12.8257416 7.22497925,12.6469999 6.97356846,12.4604045 C5.84966805,11.624837 4.07236515,10.3791545 2.0053527,9.01377618 C0.51473029,8.02903112 0.0178838174,7.53663803 0.0178838174,7.04424493 C0.0178838174,5.56710678 4.48970954,5.56710678 6.97356846,6.55185184 C9.45842324,7.53659691 11.9427801,9.99848013 11.9427801,9.99848013 L13.930249,11.9679703 C12.439668,13.9379538 10.4521577,12.9527564 10.4521577,12.9527564"
              id="Shape"
              fill="#E8B331"
              transform="translate(6.974066, 10.958409) scale(-1, 1) rotate(-180.000000) translate(-6.974066, -10.958409) "
            />
            <path
              d="M10.4521577,5.07467504 C10.4521577,5.07467504 11.3425311,7.40312899 8.96103734,8.52179675 C6.95315353,9.46468326 4.98605809,9.01418984 3.4959751,8.02940366 C2.0053527,7.04465859 2.50224066,6.05991353 2.0053527,5.56702701 C1.50846473,5.07463392 2.50224066,4.58228195 3.4959751,5.56702701 C4.48970954,6.55230662 7.62746888,7.97722438 8.96103734,7.53705169 C10.4521577,7.04461747 9.9553112,6.05987241 10.4521577,5.07467504"
              id="Shape"
              fill="#FFE8B6"
              transform="translate(6.244160, 7.019369) scale(-1, 1) rotate(-180.000000) translate(-6.244160, -7.019369) "
            />
            <path
              d="M15.5243817,12.9030921 C15.5243817,12.9030921 13.433527,16.3497368 12.4398008,16.3497368 L10.4523154,16.3497368 C9.45858921,16.3497368 10.4523154,15.8573684 10.9491784,15.365 C11.4460415,14.8726316 13.433527,15.365 13.433527,12.4107566 C13.433527,10.9335855 15.5243817,12.9030921 15.5243817,12.9030921"
              id="Shape"
              fill="#FFCC4D"
            />
            <path
              d="M12.4396349,8.47144737 C13.9302573,8.47144737 16.4146058,9.94907895 15.9177427,12.9033553 C15.4208797,15.8576316 13.4333942,16.35 12.4396349,16.35 L11.4459087,16.35 C10.4521826,16.35 10.9490456,15.8576316 11.4459087,15.3652632 C11.9427718,14.8728947 13.4333942,15.3652632 13.4333942,12.4110197 C13.4333942,10.9338816 11.4459087,8.964375 10.4521826,8.964375 C10.4521494,8.96434211 11.4459087,8.47144737 12.4396349,8.47144737"
              id="Shape"
              fill="#FFE8B6"
            />
            <path
              d="M7.96756846,9.45644737 C9.4586888,9.45644737 10.4524481,9.94881579 9.4586888,10.9335855 C8.67263071,11.7120395 6.47694606,13.3954605 4.48946058,13.8878289 C2.5019751,14.3801974 0.514489627,14.3801974 2.00511203,13.3954605 C3.49573444,12.4107237 6.85653112,9.45644737 7.96756846,9.45644737"
              id="Shape"
              fill="#FFD983"
            />
            <path
              d="M1.0113527,14.7086513 C1.0113527,15.0365789 1.0113527,15.365 0.514489627,15.365 C0.017626556,15.365 0.017626556,14.7086513 0.017626556,14.7086513 C0.017626556,14.7086513 0.017626556,14.3802303 0.514489627,14.3802303 C1.0113527,14.3802303 1.0113527,14.3802303 1.0113527,14.7086513 Z"
              id="Shape"
              fill="#4A4A4A"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const SnackIcon = withTranslation(SnackIconComponent);

import { createRequestActions } from 'src/app/store/request/request.actions';

import {
  FETCH_PATIENT_PERMISSIONS,
  FETCH_PERMISSIONS,
} from './permissions.constants';

export const fetchPermissions = createRequestActions(FETCH_PERMISSIONS);
export const fetchPatientPermissions = createRequestActions(
  FETCH_PATIENT_PERMISSIONS,
);

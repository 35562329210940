// tslint:disable
import {
  createAuthHeader,
  postJSON,
  stringifyBody,
} from '../../../../../shared/utils/service/service.utils';
import { PatientSessionStartLoaderImplType } from './patient-session-start.types';
import { ENDPOINTS } from '../../service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const PatientSessionStartLoaderImpl: PatientSessionStartLoaderImplType =
  ({ patientId, hcpTimezone }, openId) =>
    postJSON(endpointWithParams(ENDPOINTS.patientSessionStart, { patientId }), {
      headers: {
        Authorization: createAuthHeader(openId.accessToken),
        Accept: 'application/json',
      },
      body: stringifyBody({
        patientId,
        hcpTimezone,
      }),
    });

export const PatientSessionStartTransformImpl: any = (data) => data;

export const PatientSessionStartServiceImpl: any =
  (load, transform) => (params, openId) =>
    load(params, openId).then(transform);

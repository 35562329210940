import React from 'react';
import { withTheme } from 'styled-components';

import { HorizontalAxis } from 'src/domains/diagnostics/components/graph-lib/graph-horizontal-axis.component';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';

const getModifiers = (collapsed) => ({
  TEXT_POSITION_MODIFIER: collapsed ? 0.35 : 0.55,
  ICON_POSITION_MODIFIER: collapsed ? 0.45 : 0.65,
  LONG_TICK_HEIGHT_MODIFIER: collapsed ? 0.35 : 0.65,
  SHORT_TICK_HEIGHT_MODIFIER: 0.8,
});

export const MealTimeAxisComponent = ({
  x = 0,
  width,
  height,
  theme,
  ticks,
  collapsed,
}) => {
  const {
    TEXT_POSITION_MODIFIER,
    ICON_POSITION_MODIFIER,
    LONG_TICK_HEIGHT_MODIFIER,
    SHORT_TICK_HEIGHT_MODIFIER,
  } = getModifiers(collapsed);

  return (
    <HorizontalAxis
      x={x}
      y={0}
      width={width}
      height={height}
      Tick={(tick, i) => {
        const {
          value,
          icon,
          IconComponent = () => undefined,
          iconWidthScale,
          label,
          type,
          tickLine,
        } = tick;

        const iconWidth = (iconWidthScale * width) / 4;

        return (
          <React.Fragment key={`${value}-${i}`}>
            <RenderIf validate={icon}>
              <g
                key={`${value}`}
                transform={`translate(${value - iconWidth / 2}, ${
                  height * ICON_POSITION_MODIFIER
                })`}
              >
                <IconComponent width={iconWidth} />
              </g>
            </RenderIf>

            <RenderIf validate={label}>
              <text
                textAnchor="middle"
                x={value}
                y={height * TEXT_POSITION_MODIFIER}
                fontSize={theme.fontSize.label}
              >
                {label}
              </text>
            </RenderIf>

            <RenderIf validate={tickLine && type === 'short'}>
              <line
                x1={tick.value}
                y1={height * SHORT_TICK_HEIGHT_MODIFIER}
                x2={tick.value}
                y2={height}
                strokeWidth={1}
                stroke={theme.colors.grayLight}
              />
            </RenderIf>

            <RenderIf validate={tickLine && type === 'long'}>
              <line
                x1={tick.value}
                y1={height * LONG_TICK_HEIGHT_MODIFIER}
                x2={tick.value}
                y2={height}
                strokeWidth={1}
                stroke={theme.colors.grayLight}
              />
            </RenderIf>
          </React.Fragment>
        );
      }}
      ticks={ticks}
      axisColor={'gray'}
    />
  );
};
export const MealTimeAxis = withTheme(MealTimeAxisComponent);

import {
  createAuthHeader,
  postJSON,
} from 'src/shared/utils/service/service.utils';
import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { EditDemoPatientProfile } from '../../store/demo-profile.types';

export const EditPatientDemoProfileLoaderImpl = (
  query,
  accessToken: string,
) => {
  return postJSON(ENDPOINTS.editDemoProfiles, {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
    body: query,
  });
};

export const EditDemoPatientProfilesServiceImpl =
  (load) =>
  (query: EditDemoPatientProfile, accessToken: string, gigyaToken: string) => {
    return load(query, accessToken, gigyaToken).then((data) => data);
  };

import { Epic } from 'redux-observable';
import { State } from '../../../../../../../app/store/app.types';

import { EnrollPatientToStudyServiceImplType } from '../../../../../services/patient/clinical-studies/observational-clinical-studies/enroll-patient-to-study/enroll-patient-to-study.types';
import {
  EnrollPatientToStudyActionType,
  EnrollPatientToStudyStartAction,
} from './enroll-patient-to-study.types';
import { selectAccessToken } from '../../../../../../../app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from '../../../../../../../app/session/core/config/config.selectors';
import { Observable } from 'rxjs';
import {
  enrollPatientToStudyError,
  enrollPatientToStudySuccess,
} from './enroll-patient-to-study.actions';
import { catchError } from 'rxjs/operators';
import { addAlertAction } from '../../../../../../../shared/design-system/alerts/store/alert.actions';
import { AlertType } from '../../../../../../../shared/design-system/alerts/store/alert.types';
import { ALERT_POSITION } from '../../../../../../../shared/design-system/alerts/store/alert.constans';
import {
  ALERT_TEXT,
  OBSERVATIONAL_PATH,
} from '../../../clinical-studies.constants';
import { getObservationalStudiesStatusStart } from '../get-observational-studies-status/get-observational-studies-status.actions';

export const enrollPatientToStudyEpic: (
  enrollPatientToStudyService: ReturnType<EnrollPatientToStudyServiceImplType>,
) => Epic<FixMe, State> = (enrollPatientToStudyService) => (action$, store$) =>
  action$
    .ofType<EnrollPatientToStudyStartAction>(
      EnrollPatientToStudyActionType.ENROLL_PATIENT_TO_STUDY_START,
    )
    .flatMap(({ payload }: EnrollPatientToStudyStartAction) => {
      const accessToken = selectAccessToken(store$.getState());
      const apiKey = selectGigyaToken(store$.getState());
      const fhirId = payload.fhirId;

      return Observable.fromPromise(
        enrollPatientToStudyService(accessToken, apiKey, fhirId, {
          studyId: payload.studyId,
          siteId: payload.siteId,
          participantId: payload.participantId,
          status: payload.status,
        }),
      )
        .switchMap(() => {
          return [
            enrollPatientToStudySuccess(),
            getObservationalStudiesStatusStart(fhirId),
            addAlertAction({
              type: AlertType.SUCCESS,
              text: {
                [AlertType.SUCCESS]: ALERT_TEXT[payload.status].SUCCESS,
              },
              position: ALERT_POSITION.TOP_RIGHT,
            }),
          ];
        })
        .pipe(
          catchError(() =>
            Observable.concat(
              Observable.of(enrollPatientToStudyError()),
              Observable.of(
                addAlertAction({
                  type: AlertType.ERROR,
                  text: {
                    [AlertType.ERROR]: ALERT_TEXT[payload.status].ERROR,
                  },
                  position: ALERT_POSITION.TOP_RIGHT,
                  content: {
                    [AlertType.ERROR]:
                      OBSERVATIONAL_PATH.ENROLL_ALERT_ERROR_CONTENT,
                  },
                }),
              ),
            ),
          ),
        );
    });

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { NextShipmentErrorModal } from 'src/domains/patient/scenes/next-shipment/components/next-shipment-error-modal/next-shipment-error.modal';
import { NextShipmentSuccessModal } from 'src/domains/patient/scenes/next-shipment/components/next-shipment-success-modal/next-shipment-success.modal';

import { createModal } from 'src/shared/design-system/modal/store/modal/modal.actions';
import { MODAL_TYPES } from 'src/shared/design-system/modal/store/modal/modal.constants';
import { selectPatientId } from 'src/domains/patient/store/patient/patient.selector';

import { selectNextShipmentId } from './next-shipment.selectors';
import {
  changeDateNextShipment,
  changeStatusNextShipment,
  fetchNextShipment,
  NEXT_SHIPMENT_CHANGE_DATE_REQUEST,
  NEXT_SHIPMENT_CHANGE_STATUS_REQUEST,
  NEXT_SHIPMENT_FETCH_REQUEST,
} from './next-shipment.actions';

export const fetchNextShipmentEpic =
  (fetchNextShipmentService) => (action$, state$) =>
    action$
      .ofType(
        NEXT_SHIPMENT_FETCH_REQUEST.START,
        NEXT_SHIPMENT_CHANGE_STATUS_REQUEST.SUCCESS,
      )
      .flatMap((data) => {
        const openId = {
          accessToken: selectAccessToken(state$.getState()),
          gigyaToken: selectGigyaToken(state$.getState()),
        };
        return Observable.fromPromise(
          fetchNextShipmentService(data.payload, openId),
        )
          .switchMap((shipment) => [fetchNextShipment.success(shipment)])
          .pipe(catchError((data) => [fetchNextShipment.error(data)]));
      });

export const changeStatusNextShipmentEpic =
  (changeStatusNextShipmentService) => (action$, state$) =>
    action$
      .ofType(NEXT_SHIPMENT_CHANGE_STATUS_REQUEST.START)
      .flatMap((data) => {
        const status = data.payload;
        const patientId = selectPatientId(state$.getState());
        const id = selectNextShipmentId(state$.getState());
        const openId = {
          accessToken: selectAccessToken(state$.getState()),
          gigyaToken: selectGigyaToken(state$.getState()),
        };
        return Observable.fromPromise(
          changeStatusNextShipmentService(id, status, openId),
        )
          .switchMap((data) => [
            createModal({
              key: MODAL_TYPES.CUSTOM,
              data: {
                customComponent: NextShipmentSuccessModal,
              },
            }),
            changeStatusNextShipment.success(patientId),
          ])
          .pipe(
            catchError(() =>
              Observable.concat(
                Observable.of(changeStatusNextShipment.error),
                Observable.of(
                  createModal({
                    key: MODAL_TYPES.CUSTOM,
                    data: {
                      customComponent: NextShipmentErrorModal,
                    },
                  }),
                ),
              ),
            ),
          );
      });

export const changeDateNextShipmentEpic =
  (changeDateNextShipmentService) => (action$, state$) =>
    action$.ofType(NEXT_SHIPMENT_CHANGE_DATE_REQUEST.START).flatMap((data) => {
      const moment = require('moment');
      const { newDate, shipStatus } = data.payload;
      const dateFormat = 'YYYY-MM-DDTHH:mm:ss+0000';
      const date = moment(newDate, 'DD/MM/YYYY').format(dateFormat);
      const patientId = selectPatientId(state$.getState());
      const openId = {
        accessToken: selectAccessToken(state$.getState()),
        gigyaToken: selectGigyaToken(state$.getState()),
      };
      return Observable.fromPromise(
        changeDateNextShipmentService(patientId, date, shipStatus, openId),
      )
        .switchMap((data) => [
          createModal({
            key: MODAL_TYPES.CUSTOM,
            data: {
              customComponent: NextShipmentSuccessModal,
            },
          }),
          fetchNextShipment.start(patientId),
        ])
        .pipe(
          catchError(() =>
            Observable.concat(
              Observable.of(changeDateNextShipment.error),
              Observable.of(
                createModal({
                  key: MODAL_TYPES.CUSTOM,
                  data: {
                    customComponent: NextShipmentErrorModal,
                  },
                }),
              ),
            ),
          ),
        );
    });

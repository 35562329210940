import { Action, PayloadAction } from '../../../../app/store/app.types';
import { INSUser } from '../../services/INS-identity/get-INS-identity/get-INS-identity.types';

export enum INSIdentityActionType {
  INS_IDENTITY_GET = 'INS_IDENTITY_GET',
  INS_IDENTITY_GET_START = 'INS_IDENTITY_GET_START',
  INS_IDENTITY_GET_SUCCESS = 'INS_IDENTITY_GET_SUCCESS',
  INS_IDENTITY_GET_ERROR = 'INS_IDENTITY_GET_ERROR',
  INS_IDENTITY_CLEAR = 'INS_IDENTITY_CLEAR',
}

export type GetINSIdentityAction =
  Action<INSIdentityActionType.INS_IDENTITY_GET_START>;

export type GetINSIdentitySuccessAction = PayloadAction<
  INSIdentityActionType.INS_IDENTITY_GET_SUCCESS,
  INSIdentity
>;

export type GetINSIdentityErrorAction =
  Action<INSIdentityActionType.INS_IDENTITY_GET_ERROR>;

export type INSIdentityClearAction =
  Action<INSIdentityActionType.INS_IDENTITY_CLEAR>;

export type INSIdentity = {
  code: string;
  user: INSUser;
};

export type INSIdentityStateType = {
  isFetchingINSIdentity: boolean;
  isFetchingINSIdentityError: boolean;
  userHasCancelledRequest: boolean;
  successINSIdentity: boolean;
  patientIdentity?: INSIdentity;
};

export type INSIdentityActions =
  | GetINSIdentityAction
  | GetINSIdentitySuccessAction
  | GetINSIdentityErrorAction
  | INSIdentityClearAction;

import { setIn } from 'src/shared/utils/ramda/ramda.utils';

import {
  CurrentFormsActionType,
  CurrentFormsState,
  SetCurrentFormsReducerActions,
} from './current-forms.types';

export const initialCurrentFormsState: CurrentFormsState = {
  editPatient: {
    patientInfo: {
      email: '',
    },
  },
};

export const currentFormsReducer = (
  state = initialCurrentFormsState,
  action: SetCurrentFormsReducerActions,
): CurrentFormsState => {
  // tslint:disable
  switch (action.type) {
    // tslint:enable
    case CurrentFormsActionType.SET_CURRENT_FORMS_PROP:
      return setIn(action.payload.path, action.payload.value, state);
    default:
      return state;
  }
};

import { compose, lifecycle, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createModal } from 'src/domains/diagnostics/core/modal/modal.actions';
import { fetchPatientRequest } from 'src/domains/diagnostics/core/patient/patient.action';
import {
  getThresholdsRequest,
  getTimeIntervalsRequest,
} from 'src/domains/diagnostics/core/strip-delivery/strip-delivery.actions';
import { MODAL_TYPES } from 'src/domains/diagnostics/core/modal/modal.constants';
import { convertISO, convertJSDateGMT } from 'src/shared/utils/date';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import {
  fetchBGOverviewClinicalData,
  fetchBGOverviewClinicalDataFromFhir,
  fetchBGOverviewEndDate,
  onBloodGlucoseOverviewEndDateChange,
} from 'src/domains/patient-dashboards/bg/store/bg.actions';
import { withScrollWindowToTop } from 'src/shared/utils/window';

import { BloodGlucoseOverview } from './blood-glucose-overview.component';
import { NUMBER_BLOOD_GLUCOSE_OVERVIEW_COLUMNS } from './store/blood-glucose-overview.constants';
import {
  calculateBloodGlucoseStartDate,
  calculateEndDateAfterPagination,
} from './store/blood-glucose-overview.utils';
import { bloodGlucoseOverviewConnector } from './store/blood-glucose-overview.selector';

export const disclaimers = [
  {
    titleKey: 'modals.disclaimer.testFrequency.title',
    descKey: 'modals.disclaimer.testFrequency.desc',
  },
  {
    titleKey: 'modals.disclaimer.hypoRisk.title',
    descKey: 'modals.disclaimer.hypoRisk.desc',
    bullets: [
      {
        headerKey: 'modals.disclaimer.hypoRisk.greenInfo',
        descKey: null,
      },
      {
        headerKey: 'modals.disclaimer.hypoRisk.orangeInfo',
        descKey: null,
      },
      {
        headerKey: 'modals.disclaimer.hypoRisk.redInfo',
        descKey: null,
      },
    ],
  },
  {
    titleKey: 'modals.disclaimer.variability.title',
    descKey: 'modals.disclaimer.variability.desc',
    footerDecKey: 'modals.disclaimer.variability.footerDesc',
    bullets: [
      {
        headerKey: 'modals.disclaimer.variability.greenInfo',
        descKey: null,
      },
      {
        headerKey: 'modals.disclaimer.variability.redInfo',
        descKey: null,
      },
    ],
  },
  {
    titleKey: 'modals.disclaimer.meanBloodGlucose.title',
    descKey: 'modals.disclaimer.meanBloodGlucose.desc',
  },
];
const getBloodGlucoseMeasurementsByProps = (props) => {
  const {
    match: { params },
    endDate,
    interval,
    onFetchBGOverviewClinicalData,
    onFetchBGOverviewClinicalDataFromFhir,
    hasUserFhirPermission,
    patientFhirId,
    bgUnit,
    carbUnit,
  } = props;

  const patientId = params.id;

  const startDate = calculateBloodGlucoseStartDate(
    endDate,
    interval,
    NUMBER_BLOOD_GLUCOSE_OVERVIEW_COLUMNS,
  );
  hasUserFhirPermission
    ? onFetchBGOverviewClinicalDataFromFhir({
        patientFhirId,
        startDate,
        endDate,
        bgUnit,
        carbUnit,
      })
    : onFetchBGOverviewClinicalData({
        patientId,
        startDate: startDate.toString(),
        endDate: endDate.toString(),
      });
};
const addHandlers = withHandlers({
  onChangeInterval: (props) => (interval) => {
    const { onBloodGlucoseOverviewEndDateChange, lastMeasurementDate } = props;
    const endDate = isNaN(lastMeasurementDate)
      ? convertISO('1970-01-01T23:59:59')
      : lastMeasurementDate;

    getBloodGlucoseMeasurementsByProps({ ...props, interval, endDate });
    onBloodGlucoseOverviewEndDateChange(endDate.toJSDate());
  },
  onClickPager: (props) => (pagerType) => {
    const {
      onBloodGlucoseOverviewEndDateChange,
      endDate,
      interval,
      firstMeasurementDate,
      lastMeasurementDate,
    } = props;
    const newEndDate = calculateEndDateAfterPagination(
      pagerType,
      endDate,
      interval,
      firstMeasurementDate,
      lastMeasurementDate,
    );

    getBloodGlucoseMeasurementsByProps({
      ...props,
      endDate: convertJSDateGMT(newEndDate),
    });
    onBloodGlucoseOverviewEndDateChange(newEndDate);
  },
  onClickDisclaimer: (props) => () => {
    const { createModal } = props;
    createModal({
      key: MODAL_TYPES.DISCLAIMER,
      data: {
        disclaimers,
        lastUpdateDate: '2018-02-16',
      },
    });
  },
});

const dispatchers = mapDispatchers({
  onFetchPatient: fetchPatientRequest.start,
  onFetchBGOverviewEndDate: fetchBGOverviewEndDate.start,
  onFetchBGOverviewClinicalData: fetchBGOverviewClinicalData.start,
  onFetchBGOverviewClinicalDataFromFhir:
    fetchBGOverviewClinicalDataFromFhir.start,
  getThresholds: getThresholdsRequest.start,
  onBloodGlucoseOverviewEndDateChange,
  getTimeIntervals: getTimeIntervalsRequest.start,
  createModal: createModal,
});

const addLifeCycles = lifecycle({
  componentDidMount() {
    const {
      onFetchPatient,
      onFetchBGOverviewEndDate,
      getThresholds,
      match: { params },
      getTimeIntervals,
      hasUserFhirPermission,
      patientFhirId,
    } = this.props;
    const patientId = params.id;
    onFetchPatient({ patientId });
    onFetchBGOverviewEndDate({
      patientId,
      hasUserFhirPermission,
      patientFhirId,
    });
    getThresholds({ patientId });
    getTimeIntervals({ patientId });
  },
});

export const BloodGlucoseOverviewContainer = compose(
  withRouter,
  connect(bloodGlucoseOverviewConnector, dispatchers),
  addHandlers,
  addLifeCycles,
  withScrollWindowToTop,
)(BloodGlucoseOverview);

import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';

import {
  GetDeliveryConfigurationLoaderImplType,
  GetDeliveryConfigurationResponseType,
  GetDeliveryConfigurationServiceImplType,
  GetDeliveryConfigurationTransformImplType,
  GetPatientDeliveryConfigurationLoaderImplType,
  GetPatientDeliveryConfigurationResponseType,
  GetPatientDeliveryConfigurationServiceImplType,
  GetPatientDeliveryConfigurationTransformImplType,
} from './delivery-configuration.types';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const GetDeliveryConfigurationLoaderImpl: GetDeliveryConfigurationLoaderImplType =
  (accessToken: string) =>
    getJSON(ENDPOINTS.getDeliveryConfiguration, {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    });

export const GetDeliveryConfigurationTransformImpl: GetDeliveryConfigurationTransformImplType =
  (data: GetDeliveryConfigurationResponseType) =>
    data.model.map(
      ({
        name,
        deliveryConfiguration: {
          supplyModelName,
          initialStock,
          securityPercentage,
          timeGap,
          deliveryProgramId,
          packagingUnits,
        },
      }) => ({
        deliveryProgram: name,
        supplyModelName,
        initialStock,
        securityPercentage,
        timeGap,
        deliveryProgramId,
        packagingUnits,
      }),
    );

export const GetDeliveryConfigurationServiceImpl: GetDeliveryConfigurationServiceImplType =

    (
      load: GetDeliveryConfigurationLoaderImplType,
      transform: GetDeliveryConfigurationTransformImplType,
    ) =>
    (accessToken, gigyaToken) =>
      load(accessToken, gigyaToken).then(transform);

export const GetPatientDeliveryConfigurationLoaderImpl: GetPatientDeliveryConfigurationLoaderImplType =
  (accessToken: string, gigyaToken: string, patientId: string) =>
    getJSON(
      endpointWithParams(ENDPOINTS.getPatientDeliveryConfiguration, {
        patientId,
      }),
      {
        headers: {
          Authorization: createAuthHeader(accessToken),
        },
      },
    );

export const GetPatientDeliveryConfigurationServiceImpl: GetPatientDeliveryConfigurationServiceImplType =

    (
      load: GetPatientDeliveryConfigurationLoaderImplType,
      transform: GetPatientDeliveryConfigurationTransformImplType,
    ) =>
    (accessToken, gigyaToken, patientId) =>
      load(accessToken, gigyaToken, patientId).then(transform);

export const GetPatientDeliveryConfigurationTransformImpl: GetPatientDeliveryConfigurationTransformImplType =
  (data: GetPatientDeliveryConfigurationResponseType) => ({
    deliveryProgram: data.model.supplyModelName,
    supplyModelName: data.model.supplyModelName,
    initialStock: data.model.initialStock,
    securityPercentage: data.model.securityPercentage,
    timeGap: data.model.timeGap,
    deliveryProgramId: data.model.deliveryProgramId,
    packagingUnits: data.model.packagingUnits,
  });

import React, { FunctionComponent, useEffect } from 'react';

import { WhatsNewContent } from '../whats-new-content/whats-new-content.component';
import { WhatsNewMenu } from '../whats-new-menu/whats-new-menu.component';
import { testId } from '@roche/roche-common';
import { Page, UserAnnouncements } from '../../../store/announcements.types';
import {
  JELLO_BUTTON_SIZES,
  JELLO_BUTTON_VARIANTS,
  JELLO_ICON_NAMES,
} from 'src/app/app.jello.constants';
import styles from '../../styles.module.css';
import { useCustomEventListener } from 'src/shared/utils/hooks';
import {
  WHATS_NEW_CHECKBOX,
  WHATS_NEW_TITLE,
  WHATS_NEW_CLOSE_MODAL_BUTTON_TEXT,
} from '../../whats-new.constants';
import { RenderIf } from 'src/shared/utils/render-if';
import {
  CHECKBOX,
  CONTAINER,
  ID,
  MODAL,
  OK_BTN,
} from 'src/domains/announcements/whats-new/whats-new.constants';
import { t } from 'i18next';
import { WHATS_NEW_LOKALISE_KEYS } from 'src/widgets/whats-new-alert-jello/whats-new-alert-jello.constants';
import { countlyEventTrigger } from 'src/app/navigation/countly';
import {
  EVENTS_KEYS,
  SEGMENT_KEYS,
  SEGMENT_VALUES,
} from '../../../countly.constants';

export interface WhatsNewModalProps {
  showModal: boolean;
  whatsNewConfig: UserAnnouncements;
  isFooterAvailable: boolean;
  hideWhatsNewModal: (isGotItClicked: boolean, isChecked: boolean) => void;
}

export const WhatsNewModal: FunctionComponent<WhatsNewModalProps> = ({
  showModal,
  whatsNewConfig,
  isFooterAvailable,
  hideWhatsNewModal,
}) => {
  // Sort the menu items based on page number.
  const sortedPages = React.useMemo(() => {
    return whatsNewConfig?.content?.pages?.sort(
      (a, b) => parseInt(a.pageNo, 10) - parseInt(b.pageNo, 10),
    );
  }, [whatsNewConfig.announcementUUID]);

  const [activeMenu, setActiveMenu] = React.useState<Page>(sortedPages?.[0]);
  const [isChecked, setIsChecked] = React.useState(false);

  useEffect(() => {
    if (isFooterAvailable) {
      setTimeout(() => {
        if (window.Countly) {
          countlyEventTrigger(EVENTS_KEYS.ANNOUNCEMENT_DISPLAYED, {
            [SEGMENT_KEYS.TYPE]: SEGMENT_VALUES.WHATS_NEW,
          });
        } else {
          console.error('Failed to fire countly event.');
        }
      }, 1000);
    }
  }, [isFooterAvailable]);

  useEffect(() => {
    if (isChecked) {
      countlyEventTrigger(EVENTS_KEYS.ANNOUNCEMENT_CHECKED, {
        [SEGMENT_KEYS.TYPE]: SEGMENT_VALUES.WHATS_NEW,
      });
    }
  }, [isChecked]);

  // Method to set the value from WhatsNewMenu
  const setActiveMenuOption = (value: Page) => {
    setActiveMenu(value);
    countlyEventTrigger(EVENTS_KEYS.ANNOUNCEMENT_CLICKED_SECTION, {
      [SEGMENT_KEYS.WHATS_NEW]: value.pageHeader,
    });
  };

  // Event to check checkbox is clicked
  const checkBoxRef = useCustomEventListener(
    'jello-input-checkbox-change',
    () => {
      setIsChecked(!isChecked);
    },
  );

  // Event of modal closed using close button
  const whatsNewModalRef = useCustomEventListener('jello-modal-hide', () => {
    hideWhatsNewModal(false, false);
  });

  return (
    <jello-modal
      class={styles.modal}
      visible={showModal || undefined}
      ref={whatsNewModalRef}
      {...testId(ID, MODAL)}
      close-button-accessibility-text={t(
        WHATS_NEW_LOKALISE_KEYS.CLOSE_BUTTON_ACCESSIBILITY_TEXT,
      )}
      header-title={t(`${WHATS_NEW_TITLE}`)}
      persistent
      header-icon-name={JELLO_ICON_NAMES.DEPRECATED_STAR}
    >
      <div className={styles.modalWrapper}>
        <div
          {...testId(ID, CONTAINER)}
          className={
            isFooterAvailable ? styles.footerWrapper : styles.bodyWrapper
          }
        >
          {
            <React.Fragment>
              <jello-grid center="">
                <jello-row>
                  <jello-col xs-3 sm-3 md-3 l-3 xl-3 class={styles.sections}>
                    <WhatsNewMenu
                      setActiveHandler={setActiveMenuOption}
                      currentActive={activeMenu}
                      menuItems={sortedPages}
                    />
                  </jello-col>
                  <jello-col xs-9 sm-9 md-9 l-9 xl-9>
                    {showModal ? <WhatsNewContent page={activeMenu} /> : null}
                  </jello-col>
                </jello-row>
              </jello-grid>
            </React.Fragment>
          }
        </div>
        <RenderIf validate={isFooterAvailable}>
          <div className={styles.bottomBoxWrapper}>
            <jello-box block="" class={styles.bottomBox}>
              <div>
                <jello-input-checkbox
                  ref={checkBoxRef}
                  label={t(WHATS_NEW_CHECKBOX)}
                  checked={isChecked || undefined}
                  {...testId(ID, CHECKBOX)}
                />

                <jello-button
                  size={JELLO_BUTTON_SIZES.L}
                  variant={JELLO_BUTTON_VARIANTS.PRIMARY}
                  onClick={() => {
                    hideWhatsNewModal(true, isChecked);
                  }}
                  {...testId(ID, OK_BTN)}
                >
                  {t(WHATS_NEW_CLOSE_MODAL_BUTTON_TEXT)}
                </jello-button>
              </div>
            </jello-box>
          </div>
        </RenderIf>
      </div>
    </jello-modal>
  );
};

import * as React from 'react';
import { withTranslation } from '../../../utils/i18n/with-translation';

export const PdfDownloadIconComponent = ({
  onClick,
  borderColor,
  iconColor,
  color,
  viewBox,
}: any) => (
  <svg
    width="31px"
    height="31px"
    viewBox={viewBox ? viewBox : '0 0 32 32'}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{ overflow: 'inherit' }}
    onClick={onClick}
  >
    <g
      id="Patient-Settings-/-Edit-flow"
      stroke="none"
      strokeWidth="1"
      fill={color ? color : 'none'}
      fillRule="evenodd"
    >
      <g id="SELECTED-Nav-/-Patient-Info">
        <g id="Icon/Export-PDF">
          <circle
            id="Oval"
            stroke={borderColor}
            strokeWidth="2"
            fill="#FFFFFF"
            cx="16"
            cy="16"
            r="16"
          />
          <path
            fill={iconColor}
            d="M19.7142582,7.61908259 L19.7142582,12.6428815 C19.7142582,12.7427874 19.7860576,12.8288591 19.879972,12.8514121 L19.9285421,12.8571653 L24.952341,12.8571653 L24.952341,24.1666635 C24.952341,24.880114 24.4052992,25.4628283 23.706706,25.5193161 L23.5952,25.5238045 L12.5952262,25.5238045 C11.8817757,25.5238045 11.2990614,24.9767628 11.2425736,24.2781695 L11.2380852,24.1666635 L11.2380852,20.476198 L12.3809424,20.476198 L12.3809424,24.1666635 C12.3809424,24.2693646 12.4503459,24.3534397 12.5456293,24.3753653 L12.5952262,24.3809474 L23.5952,24.3809474 C23.6979011,24.3809474 23.7819762,24.3115439 23.8039017,24.2162604 L23.8094838,24.1666635 L23.8087619,14 L19.9285421,14.0000225 C19.2181895,14.0000225 18.6326871,13.4500376 18.5759127,12.753946 L18.5714011,12.6428815 L18.5706667,8.76190476 L12.5952262,8.76193973 C12.4925251,8.76193973 12.40845,8.83134324 12.3865245,8.92662669 L12.3809424,8.97622357 L12.3809424,16.8571553 L11.2380852,16.8571553 L11.2380852,8.97622357 C11.2380852,8.26277308 11.785127,7.68005878 12.4837203,7.62357098 L12.5952262,7.61908259 L19.7142582,7.61908259 Z M16.8970145,15.1146018 L20.0267561,18.2738205 C20.2428143,18.4931716 20.2428143,18.8434747 20.0267561,19.0595329 L16.8937215,22.2154905 C16.5630803,22.5461317 15.996719,22.3137046 15.996719,21.8455253 L15.996719,19.7142932 L8.14285589,19.7142932 C7.88677753,19.7142932 7.67207639,19.5280452 7.62752648,19.2843232 L7.61904762,19.1904849 L7.61904762,18.1428684 C7.61904762,17.88679 7.80529561,17.6720889 8.04901758,17.627539 L8.14285589,17.6190601 L15.99998,17.6190601 L15.99998,15.484535 C15.99998,15.0163877 16.5663413,14.7839606 16.8970145,15.1146018 Z M21.1520401,7.4286064 C21.319669,7.4286064 21.4809958,7.48098722 21.6142497,7.57736795 L21.7085864,7.65777252 L24.9136511,10.8660982 C25.0315079,10.9839551 25.1074601,11.1353275 25.1331267,11.296775 L25.1428172,11.4193835 L25.1428172,11.6190726 L20.952351,11.6190726 L20.952351,7.4286064 L21.1520401,7.4286064 Z"
            id="Combined-Shape"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const PdfDownloadIcon = withTranslation(PdfDownloadIconComponent);

import {
  PatientReportsLoaderImpl,
  PatientReportsServiceImpl,
  PatientReportsTransformImpl,
} from './patient-reports.service';
import { mockPatientReports } from './patient-reports.mock';

const mockLoader = () => Promise.resolve(mockPatientReports);
// Promise.reject({error: "error"})

export const PatientReportsFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : PatientReportsLoaderImpl;
  return PatientReportsServiceImpl(loader, PatientReportsTransformImpl);
};

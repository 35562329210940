export enum DiabetesType {
  DIABETES_TYPE1 = 'DIABETES_TYPE1',
  DIABETES_TYPE2 = 'DIABETES_TYPE2',
  DIABETES_GESTATIONAL = 'DIABETES_GESTATIONAL',
  DIABETES_MODY = 'DIABETES_MODY',
  DIABETES_LADA = 'DIABETES_LADA',
  DIABETES_PREDIABETES = 'DIABETES_PREDIABETES',
  DIABETES_UNKNOWN = 'DIABETES_UNKNOWN',
  PENDING_TO_IDENTIFY = 'PENDING_TO_IDENTIFY',
  DIABETES_TYPE2_MDI = 'DIABETES_TYPE2_MDI',
  DIABETES_TYPES_PENDING = 'PENDING_TO_IDENTIFY',
  UNKNOWN = 'UNKNOWN', // NOT FROM BACKEND
  DIABETES_TYPE_MODY = 'DIABETES_TYPE_MODY', // NOT FROM BACKEND
  DIABETES_TYPE_LADA = 'DIABETES_TYPE_LADA', // NOT FROM BACKEND
}

export enum TherapyType {
  MDI = 'MDI',
  CSII = 'CSII',
  BASAL = 'BASAL',
  OAD = 'OAD',
  NII = 'NII',
  BOT = 'BOT',
  FRC = 'FRC',
  AID = 'AID',
  LIFESTYLE = 'LIFESTYLE',
}

export enum DownloadNoticePeriod {
  NO_WARNING = 'NO_WARNING',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNSPECIFIED = 'UNSPECIFIED',
  SELECTOPTION = '',
}

export type EC6Patient = {
  clinicalRecordNumber?: string;
  diabetesType: DiabetesType;
  therapyType: TherapyType;
  diagnosticDate: string;
  downloadNoticePeriod?: DownloadNoticePeriod;
  expectedDeliverDate: string;
  healthCareSystemId: string;
  nursingHome?: boolean;
  pregnant?: boolean;
  professionalList: number[];
  timeZone?: string;
  user: {
    address: {
      address: string;
      city: string;
      country: {
        id: number;
      };
      postalCode: string;
      province: string;
    };
    birthday: string;
    unknownBirthdate?: boolean;
    departmentProfileId: number;
    email: string;
    gender: Gender | null;
    gigyaUid?: string;
    hcpIsAccessible: boolean;
    languageId: number;
    name: string;
    phone: string;
    surname: string;
    surname2: string | null;
    userAdditionalInfo?: {
      birthNames: string;
      usedName: string;
      usedSurnames: string;
      birthPlaceCode: string | number;
    };
  };
  patientInsStatus?: {
    proofOfIdentity: string;
    insCode: string | number;
  };
  grantRevokeFunctDTO: {
    grantFunctionalities: string[];
    revokeFunctionalities: string[];
  };
};

export type EC6PatientWithFhir = {
  clinicalRecordNumber?: string;
  diabetesType?: DiabetesType;
  therapyType: TherapyType;
  diagnosticDate?: string;
  downloadNoticePeriod?: string | DownloadNoticePeriod;
  expectedDeliverDate?: string;
  healthCareSystemId?: string;
  nursingHome?: boolean;
  pregnant?: boolean;
  professionalList: number[];
  timeZone?: string;
  user: {
    address?: {
      address: string;
      city: string;
      country: {
        id: number | string;
      };
      postalCode: string;
      province: string;
    };
    birthday: string;
    unknownBirthdate?: boolean;
    departmentProfileId: number;
    email: string;
    gender?: Gender | null;
    gigyaUid?: string;
    hcpIsAccessible: boolean;
    languageId?: number;
    name: string;
    phone: string;
    surname: string;
    surname2?: string;
    userAdditionalInfo?: {
      birthNames: string;
      usedName: string;
      usedSurnames: string;
      birthPlaceCode: string | number;
    };
  };
  patientInsStatus?: {
    proofOfIdentity: string;
    insStatus: string | number;
    validatedStatusDate: string;
  };
  grantRevokeFunctDTO: {
    grantFunctionalities: string[];
    revokeFunctionalities: string[];
  };
};

export type PatientType = {
  id: number;
  user: {
    id: number;
    name: string;
    surname: string;
    surname2: string;
    address: Ec6Address;
    fullname: string;
    inactivate: boolean;
    gender: string;
    phone: string;
    birthday: string;
    email: string;
    loginId: number;
    language: string;
    languageId: number;
    languageIsoCode: string;
    languageCountryIsoCode: string;
    role: string;
    departmentProfileId: number;
    profile: string;
    countryId: number;
    inactivateReason: string;
    inactivateComment: string;
    departmentId: number;
    centerId: number;
    areaId: number;
    locked: boolean;
    lastAccess: string;
    passwordReset: boolean;
    viewNotifications: boolean;
    countryIsoCode: string;
    departmentName: string;
    centerName: string;
    accessEnum: string;
    profileId: number;
    noPhone: boolean;
    headerWelcome: string;
    languageIsoCodeComplete: string;
    fhirId: string;
  };
  clinicalRecordNumber: string;
  healthCareSystemId: string;
  deviceType: string;
  diagnosticDate: string;
  deviceTypeLabel: string;
  timeZone: string;
  pregnant?: boolean;
  nursingHome: boolean;
  professionalList: Ec6Professional[];
  relevantClinicalData: any;
  units: any;
  expectedDeliverDate: string;
  downloadNoticePeriod: string;
  diabetesType: string;
  therapyType: string;
  lastGlucoseDate: string;
  patientDevices: {
    id: number;
    associationName: string;
    associationStatus: string;
    clientInstanceId: any;
    clientClassId: any;
    associationId: string;
    fromDate: string;
    toDate: string;
    lastDownloadDate: string;
    active: boolean;
    professionalInfo: any;
    device: Device;
  }[];
  treatmentName: string;
  multilingualDeviceTypeLabel: string;
  deviceTypeIcon: string;
  isEMRCompleted?: boolean;
};

export interface Device {
  id: number;
  serialNumber: string;
  deviceModel: {
    id: number;
    name: string;
    description: string;
    brandName: string;
    brand: {
      id: number;
      name: string;
      description: string;
    };
    resourceType: string;
    presentationFormat: string;
    reference: string;
    formatLabel: any;
    packagingUnits: string;
    countriesList: any;
    countriesStList: any;
    materialType: {
      id: number;
      name: string;
      label: string;
      description: string;
      characteristicModel: string;
      characteristicModelLabel: string;
      modelsList: string;
      labelText: string;
      unitMeasurement: string;
      value: string;
      key: string;
    };
    brandId: number;
    modelCountryName: string;
    countriesSelList: any;
    countrieSelUnique: any;
    countriesSelStringList: any;
    createdProfile: string;
    imageBase64: string;
    imageContentType: string;
    imageFileName: string;
    modelCountryId: any;
    myCatalog: any;
    addAndRemoveModel: any;
  };
  deviceTypeIcon: string;
}

interface Ec6CountryLanguage {
  id: number;
  languageId: string;
  labelProperty: string;
  labelText: string;
  key: string;
  value: string;
}

interface Ec6Country {
  id: number;
  name: string;
  isoCode: string;
  labelProperty: string;
  labelText: string;
  language: Ec6CountryLanguage;
  timeZone: string;
  currency: string;
  prefix: string;
  key: string;
  value: string;
  profilesList?: any;
}

interface Ec6Address {
  id: number;
  address: string;
  postalCode: string;
  city: string;
  province: string;
  country: Ec6Country;
}

interface Ec6Professional {
  id: number;
}

import { createSelector } from 'reselect';

import { State } from 'src/app/store/app.types';
import { getIn } from 'src/shared/utils/ramda/ramda.utils';

export const selectLanguagesState = (state: State) =>
  getIn(['languages'], state);

export const selectLanguages = createSelector(
  [selectLanguagesState],
  (languagesState) => getIn(['activeLanguages'], languagesState),
);

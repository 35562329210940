import { createSelector } from 'reselect';

import {
  BG_UNIT_KEYS,
  BLOOD_GLUCOSE_UNITS,
} from 'src/domains/diagnostics/store/constants';
import {
  selectBloodGlucoseUnit,
  selectGraphThreshold,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';

import { createThresholdTicks, shouldTickShow } from './metabolic.util';
import {
  GRAPH_LABEL_VALUE,
  METABOLIC_GRAPH_X_MAX_MG,
  METABOLIC_GRAPH_X_MAX_MMOL,
  METABOLIC_GRAPH_Y_MAX_MG,
  METABOLIC_GRAPH_Y_MAX_MMOL,
  METABOLIC_HORIZONTAL_LINE_MG,
  METABOLIC_HORIZONTAL_LINE_MMOL,
  METABOLIC_VERTICAL_LINE_MG,
  METABOLIC_VERTICAL_LINE_MMOL,
  Y_TICK_INCREMENTS_MG,
  Y_TICK_INCREMENTS_MMOL,
} from './metabolic.constant';

import { getYAxisTickVisibilityTolerance } from '../../graph-shared/graph.util';

export const selectThresholds = createSelector(
  selectGraphThreshold,
  (threshold) => ({
    glucoseIdealIntervalMax: threshold.glucoseIdealIntervalMax,
    glucoseIdealIntervalMin: threshold.glucoseIdealIntervalMin,
    hypoglycemiaThreshold: threshold.hypoglycemiaThreshold,
  }),
);

export const selectStabilityLabels = (state) => ({
  stableHigh: 'graphs.metabolicGraph.labels.stableHigh',
  stableLow: 'graphs.metabolicGraph.labels.stableLow',
  unstableHigh: 'graphs.metabolicGraph.labels.unstableHigh',
  unstableLow: 'graphs.metabolicGraph.labels.unstableLow',
});

export const selectVerticalLabel = createSelector(
  selectBloodGlucoseUnit,
  (unit) => [
    {
      value: GRAPH_LABEL_VALUE,
      label: [
        'graphs.axisLabels.meanBloodGlucose',
        ' (',
        BG_UNIT_KEYS[unit],
        ') ',
      ],
    },
  ],
);

export const selectHorizontalLabel = createSelector(
  selectBloodGlucoseUnit,
  (unit) => [
    {
      value: GRAPH_LABEL_VALUE,
      label: [
        'graphs.axisLabels.standardDeviation',
        ' (',
        BG_UNIT_KEYS[unit],
        ') ',
      ],
    },
  ],
);

const selectYAxisTickVisibilityTolerance = createSelector(
  selectBloodGlucoseUnit,
  (bloodGlucoseUnit) =>
    getYAxisTickVisibilityTolerance({
      bloodGlucoseUnit,
    }),
);

export const selectBloodGlucoseDimensions = createSelector(
  selectBloodGlucoseUnit,
  (unit) =>
    unit === BLOOD_GLUCOSE_UNITS.MG_PER_DL
      ? {
          metabolicGraphMaxX: METABOLIC_GRAPH_X_MAX_MG,
          metabolicGraphMaxY: METABOLIC_GRAPH_Y_MAX_MG,
          metabolicYIncrements: Y_TICK_INCREMENTS_MG,
          metabolicHorizontalLine: METABOLIC_HORIZONTAL_LINE_MG,
          metabolicVerticalLine: METABOLIC_VERTICAL_LINE_MG,
        }
      : {
          metabolicGraphMaxX: METABOLIC_GRAPH_X_MAX_MMOL,
          metabolicGraphMaxY: METABOLIC_GRAPH_Y_MAX_MMOL,
          metabolicYIncrements: Y_TICK_INCREMENTS_MMOL,
          metabolicHorizontalLine: METABOLIC_HORIZONTAL_LINE_MMOL,
          metabolicVerticalLine: METABOLIC_VERTICAL_LINE_MMOL,
        },
);

export const selectMetabolicVerticalTicks = createSelector(
  selectThresholds,
  selectYAxisTickVisibilityTolerance,
  selectBloodGlucoseDimensions,
  (
    thresholds,
    yAxisTickVisibilityTolerance,
    { metabolicGraphMaxY, metabolicYIncrements },
  ) => {
    const ticks = [
      ...createThresholdTicks(
        thresholds,
        yAxisTickVisibilityTolerance,
        metabolicGraphMaxY,
      ),
    ];
    let tickValue = 0;

    while (tickValue <= metabolicGraphMaxY) {
      if (shouldTickShow(tickValue, thresholds, yAxisTickVisibilityTolerance)) {
        ticks.push({
          value: tickValue / metabolicGraphMaxY,
          label: tickValue,
        });
      }
      tickValue += metabolicYIncrements;
    }

    return ticks;
  },
);

export const selectVerticalTicksDashboard = createSelector(
  selectThresholds,
  selectYAxisTickVisibilityTolerance,
  selectBloodGlucoseDimensions,
  (thresholds, yAxisTickVisibilityTolerance, { metabolicGraphMaxY }) => [
    ...createThresholdTicks(
      thresholds,
      yAxisTickVisibilityTolerance,
      metabolicGraphMaxY,
    ),
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: formatMeasurement(metabolicGraphMaxY),
    },
  ],
);

const formatMeasurement = (n) => String(n);

export const selectHorizontalTicks = createSelector(
  selectBloodGlucoseDimensions,
  ({ metabolicGraphMaxX, metabolicYIncrements }) =>
    [0, 1, 2, 3]
      .map((x) => x * metabolicYIncrements)
      .map((x) => ({
        value: x / metabolicGraphMaxX,
        label: formatMeasurement(x),
      })),
);

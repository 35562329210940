import {
  Action,
  PayloadAction,
} from '../../../../../../../app/store/app.types';
import {
  GetParticipantIdsErrorPayload,
  GetParticipantIdsParams,
  GetParticipantIdsTransformedResponseType,
} from '../../../../../services/patient/clinical-studies/observational-clinical-studies/get-participant-ids/get-participant-ids.types';

export enum GetParticipantIdsActionType {
  GET_PARTICIPANT_IDS_START = 'GET_PARTICIPANT_IDS_START',
  GET_PARTICIPANT_IDS_SUCCESS = 'GET_PARTICIPANT_IDS_SUCCESS',
  GET_PARTICIPANT_IDS_ERROR = 'GET_PARTICIPANT_IDS_ERROR',
  CLEAR_GET_PARTICIPANT_IDS = 'CLEAR_GET_PARTICIPANT_IDS',
}

export type GetParticipantIdsStartAction = PayloadAction<
  GetParticipantIdsActionType.GET_PARTICIPANT_IDS_START,
  GetParticipantIdsParams
>;

export type GetParticipantIdsSuccessAction = PayloadAction<
  GetParticipantIdsActionType.GET_PARTICIPANT_IDS_SUCCESS,
  GetParticipantIdsTransformedResponseType
>;

export type GetParticipantIdsErrorAction = PayloadAction<
  GetParticipantIdsActionType.GET_PARTICIPANT_IDS_ERROR,
  GetParticipantIdsErrorPayload
>;

export type ClearParticipantIdsAction =
  Action<GetParticipantIdsActionType.CLEAR_GET_PARTICIPANT_IDS>;

export type GetParticipantIdsActions =
  | GetParticipantIdsStartAction
  | GetParticipantIdsSuccessAction
  | GetParticipantIdsErrorAction
  | ClearParticipantIdsAction;

export type GetParticipantIdsState = {
  isGettingParticipantIds: boolean;
  getParticipantIdsSuccessful: boolean;
  getParticipantIdsError: boolean;
  observationalParticipantIds: {}[] | [];
};

import * as React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ThemeProvider } from 'styled-components';
import 'normalize.css';
import 'react-virtualized/styles.css';
import 'react-vis/dist/style.css';
import 'react-select/dist/react-select.css';
import 'core-js/features/object/keys';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/from';
import 'core-js/features/array/includes';
import 'core-js/features/string/ends-with';
import 'core-js/features/string/starts-with';
import 'core-js/features/string/includes';
import 'core-js/features/string/repeat';
import 'core-js/features/math';
import 'core-js/features/number';
import 'intl';
import 'core-js/features/set';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import 'src/app/app.style';

import { theme } from 'src/shared/theme';
import { TranlsationsComponent } from 'src/shared/utils/i18n';
import { configureStore } from 'src/app/store';

import { AppContainer } from './app.container';

import 'src/shared/utils/i18n';

export const { persistor, store, history } = configureStore({});

export const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <React.Fragment>
        <TranlsationsComponent />
        <React.Fragment>
          <ThemeProvider theme={theme}>
            <ConnectedRouter history={history}>
              <AppContainer store={store} />
            </ConnectedRouter>
          </ThemeProvider>
          <div id="tool-tip-root" />
        </React.Fragment>
      </React.Fragment>
    </PersistGate>
  </Provider>
);

import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'src/shared/design-system/protected-route/protected-route.container';
import { PERMISSIONS } from 'src/domains/permissions/store/permissions.constants';

import { RpmSettingsBundle } from './bundles';

export const rpmLinks = {
  rpmSettings: '/rpm/settings',
};

export const RpmRoutes = () => (
  <Switch>
    <ProtectedRoute
      exact
      path={rpmLinks.rpmSettings}
      component={RpmSettingsBundle}
      hasPermissions={[PERMISSIONS.RPM_SETTINGS_MANAGEMENT]}
    />
  </Switch>
);

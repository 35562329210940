import React from 'react';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';

import { Tooltip } from './insufficient-data-tooltip.style';

import { InfoTooltip } from '../info-tooltip/info-tooltip.component';

const TOOLTIP_WIDTH = 200;
const TOOLTIP_X_OFFSET = 10;

export const InsufficientDataTooltipComponent = ({ t, noTop = false }) => (
  <Tooltip noTop={noTop}>
    <InfoTooltip
      info={t('bloodGlucoseOverview.insufficientData')}
      toolTipXOffset={TOOLTIP_X_OFFSET}
      toolTipWidth={TOOLTIP_WIDTH}
    />
  </Tooltip>
);

export const InsufficientDataTooltip = withTranslation(
  InsufficientDataTooltipComponent,
);

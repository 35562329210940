export type DemoPatientProfileState = {
  isFetchingDemoProfiles: boolean;
  demoPatientProfiles: string[];
  isFetchingDemoProfilesError: boolean;
  isEditingDemoPatientProfiles: boolean;
  isEditingDemoPatientProfilesError: boolean;
  editedDemoPatientProfiles: EditDemoPatientProfileResponse | null;
  isFetchingAssignedDemoPatientProfiles: boolean;
  assignedDemoPatientProfiles: string[];
  isFetchingAssignedDemoPatientProfilesError: boolean;
};

export type AssignedProfiles = {
  demoProfile: string;
  ingestionOperationId: string | null;
};

export type EditDemoPatientProfile = {
  patientRecordId: string;
  demoProfiles: string[];
};

export type EditDemoPatientProfileResponse = {
  patientRecordId: string;
  assignedProfiles: AssignedProfiles[];
};

export const CLEAR_EDIT_DEMO_PATIENT_PROFILE =
  'CLEAR_EDIT_DEMO_PATIENT_PROFILE';

export const BLOOD_GLUCOSE_UNITS_FAMILY = 'BLOOD_GLUCOSE';
export const CARBOHYDRATES_UNITS_FAMILY = 'CARBOHYDRATES';
export const GLUCOSE_UNIT_RADIO_GROUP = 'glucoseUnitRadioGroup';
export const CARBOHYDRATES_UNIT_RADIO_GROUP = 'carbohydratesUnitRadioGroup';
export const SETTINGS_UNITS_BLOOD_GLUCOSE = 'setting-units-blood-glucose';
export const JELLO_INPUT_RADIO_GROUP = 'jello-input-radio-group';
export const SAVE_BUTTON = 'save-bt';
export const CANCEL_BUTTON = 'cancel-btn';
export const EXCHANGE_VALUE = 'EXCHANGE';
export const JELLO_SELECT = 'jello-select';
export const ROUTE_LEAVING_GUARD = 'route-leaving-guard';
export const CHANGE_NAVIGATION_ROUTE = 'change-navigation-route';
export const USER_UNIT_SELECTOR = 'user-unit-selector';
export const JELLO_SELECT_EVENT_CHANGE = 'jello-select-change';
export const HEADING_LABEL = 'heading-label';
export const DESCRIPTION_LABEL = 'description-label';
export const GLUCOSE_UNIT = 'glucose_unit';
export const CARBOS_UNIT = 'carbohydrates_unit';

export const GRAMS_TEXT = 'Grams (g)';

export const GRAMS = 'GRAMS';
export const G10 = 'G10';
export const G12 = 'G12';
export const G15 = 'G15';
export const G20 = 'G20';
export const CARBOHYDRATES_UNITS = {
  GRAMS,
  G10,
  G12,
  G15,
  G20,
};

export const COUNTLY_EVENTS_KEYS = {
  INTERACTION: 'Unit of measurement',
};

export const COUNTLY_SEGMENT_KEYS = {
  BG_UNITS: 'BG Units',
  CARB_UNITS: 'Carb Units',
};

export const COUNTLY_SEGMENT_VALUES = {
  MGDL: 'MGDL',
  MMOLL: 'MMOLL',
  GRAMS,
  G10,
  G12,
  G15,
  G20,
};

import { getClinicalStudiesMockResponse } from './get-clinical-studies.mock';
import {
  GetClinicalStudiesLoaderImpl,
  GetClinicalStudiesServiceImpl,
  GetClinicalStudiesTransformImpl,
} from './get-clinical-studies.service';
import { GetClinicalStudiesResponseType } from './get-clinical-studies.types';

export const mockLoaderClinicalStudies =
  (): Promise<GetClinicalStudiesResponseType> =>
    Promise.resolve(getClinicalStudiesMockResponse);

export const GetClinicalStudiesFactoryImp = ({ devMode }) => {
  const loader = devMode
    ? mockLoaderClinicalStudies
    : GetClinicalStudiesLoaderImpl;
  return GetClinicalStudiesServiceImpl(loader, GetClinicalStudiesTransformImpl);
};

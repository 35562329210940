import { combineEpics, Epic } from 'redux-observable';

import { State } from 'src/app/store/app.types';
import { requestSequence } from 'src/app/store/request/request.epics';
import { selectPatientFhirId } from 'src/domains/patient/store/patient/patient.selector';
import { selectEC6UserUnitMeasurement } from 'src/app/store/user/user.selectors';
import { CGMSummaryFactoryImpl } from 'src/domains/patient-dashboards/cgm/services/cgm/cgm-summary/cgm-summary.factory';
import { CGMClinicalDataFactoryImpl } from 'src/domains/patient-dashboards/cgm/services/cgm/cgm-clinical-data/cgm-clinical-data.factory';

import {
  CGM_SET_DATES,
  GET_CGM_CLINCAL_DATA,
  GET_CGM_SUMMARY,
  getCGMClinicalDataRequest,
} from './cgm.actions';

export const getCGMSummaryEpic = requestSequence({
  actionTypes: GET_CGM_SUMMARY,
  service: CGMSummaryFactoryImpl({ devMode: false }),
  useFhirRole: true,
});

export const getCGMClinicalDataEpic = requestSequence({
  actionTypes: GET_CGM_CLINCAL_DATA,
  service: CGMClinicalDataFactoryImpl({ devMode: false }),
  useFhirRole: true,
});

export const cgmClinicalDataTrigger = (
  action$,
  store,
): Epic<{ type: string }, State> => {
  return action$
    .ofType(CGM_SET_DATES)
    .map(({ payload: { endDate, startDate, range } }) => {
      return getCGMClinicalDataRequest.start({
        fhirId: selectPatientFhirId(store.getState()),
        endDate: endDate.parseZone().utc(true).toISOString(),
        startDate: startDate.parseZone().utc(true).toISOString(),
        unit: selectEC6UserUnitMeasurement(store.getState()),
        range,
      });
    })
    .debounceTime(150);
};

export const continuousMonitoringEpics = combineEpics(
  getCGMSummaryEpic,
  getCGMClinicalDataEpic,
  cgmClinicalDataTrigger,
);

import React from 'react';
import styled from 'styled-components';

// @ts-ignore
import BGImage from './bg.jpg';
import { BlockNavigation } from '../../shared/design-system/block/block.component';
import { TopNavigationJello } from '../../widgets/top-navigation-jello/top-navigation-jello.container';

import { CountlyLoader } from './countly-loader';

const AppWrapper = styled.div`
  background-position: center center;
  background-size: cover;
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
AppWrapper.displayName = 'AppWrapper';

type NavWrapperPropsTypes = {
  gigyaUID: string;
  permissions: any;
  children: React.ReactNode;
};

export const NavigatorWrapper: React.FunctionComponent<
  NavWrapperPropsTypes
> = ({ gigyaUID, permissions, children }) => {
  return (
    <>
      <AppWrapper>
        {<TopNavigationJello />}
        <BlockNavigation ml={0} mr={0}>
          {children}
        </BlockNavigation>
      </AppWrapper>
      <CountlyLoader permissions={permissions} gigyaUID={gigyaUID} />
    </>
  );
};

export enum UserAnnouncementsTypes {
  WHATS_NEW = 'WHATS_NEW',
}

export type Style =
  | 'header'
  | 'sub_header'
  | 'italic'
  | 'bold'
  | 'bullet'
  | 'custom'
  | 'callout';

export interface TextContent {
  type: 'text';
  value: string | string[];
  style: Style[];
}

export type MediaType = 'image' | 'video' | 'gif';
export interface MediaContent {
  type: MediaType;
  value: string;
}

export interface BreaklineContent {
  type: 'breakline';
}

export type ContentItem = TextContent | MediaContent | BreaklineContent;

export interface Page {
  pageNo: string;
  pageHeader: string;
  pageContent: ContentItem[];
}

export interface AnnouncementContent {
  announcementHeader: string;
  pages: Page[];
}

export type UserAnnouncementsResponse = {
  id: number;
  announcementUUID: string;
  releaseTag: string;
  metadata: string;
  content: string;
  type: UserAnnouncementsTypes;
  isVisited: boolean;
};

export type UserAnnouncements = {
  id: number;
  announcementUUID: string;
  releaseTag: string;
  metadata: string;
  content: AnnouncementContent;
  type: UserAnnouncementsTypes;
  isVisited: boolean;
};

export type MarkAnnouncementVisited = {
  status: string;
};

export type UserAnnouncementNotificationState = {
  key: string;
};

export type AnnouncementsState = {
  userAnnouncements: UserAnnouncements | null;
  isFetchingAnnouncements: boolean;
  markVisitedResponse: MarkAnnouncementVisited | null;
  isMarkingAnnouncementVisited: boolean;
  userAnnouncementNotification: UserAnnouncementNotificationState | null;
};

import React from 'react';
import { RenderIf } from 'src/shared/utils/render-if';
import { PeriodSelectors } from 'src/widgets/top-patient-bar/period-selectors-block-jello/period-selectors-block.component';
import { TimeIntervalsFilter } from '../components/time-intervals-filter/time-intervals-filter.container';
import { DashboardTitleComponent } from 'src/domains/patient-dashboards/patient-dashboard-jello/components/dashboard-title-jello/dashboard-title.component';
import styles from './styles.module.css';
import { VisitModuleSidebarAnchor } from '../../../app/modules/visit-module/sidebar/visit-module-sidebar-anchor';
import { ConditionalRouteForVisitModuleStickyPatientSummaryBar } from '../../../app/modules/visit-module/routes';
import {
  isRouteDisplayingTimeIntervalsFilter,
  isRouteNotDisplayingPeriodSelectors,
} from './utils';

const TimePeriodConditional = () => (
  <>
    <RenderIf validate={isRouteNotDisplayingPeriodSelectors(location.href)}>
      <jello-col xs-8 styles={{ float: 'right' }}>
        <div className={styles.periodSelectorWrapper}>
          <PeriodSelectors />
        </div>
      </jello-col>
    </RenderIf>
    <RenderIf validate={isRouteDisplayingTimeIntervalsFilter(location.href)}>
      <jello-col xs-8 class={styles.title}>
        <TimeIntervalsFilter />
      </jello-col>
    </RenderIf>
  </>
);

const VisitModuleSideBarContent = ({ visitModuleActiveSection }) => (
  <ConditionalRouteForVisitModuleStickyPatientSummaryBar
    component={
      <VisitModuleSidebarAnchor activeSection={visitModuleActiveSection} />
    }
  />
);

export const ContainerWithGrid = ({
  location,
  patientPermissions,
  profile,
  patientProfileType,
  children,
  visitModuleActiveSection,
}) => {
  const dashboardTitleProps = {
    location,
    patientPermissions,
    profile,
    patientProfileType,
  };

  const visitModuleProps = { visitModuleActiveSection };

  return (
    <>
      <VisitModuleSideBarContent {...visitModuleProps} />

      <div className={styles.scroll}>
        <jello-grid class={styles.height}>
          <jello-row class={styles.positionRelative}>
            <jello-col xs-auto class={styles.paddingCol}>
              <jello-grid class={styles.height}>
                <jello-row class={styles.paddingRow}>
                  <jello-col xs-4 class={styles.title}>
                    <DashboardTitleComponent {...dashboardTitleProps} />
                  </jello-col>
                  <TimePeriodConditional />
                </jello-row>
                <jello-row class={styles.height}>
                  <jello-col xs-auto>{children}</jello-col>
                </jello-row>
              </jello-grid>
            </jello-col>
          </jello-row>
        </jello-grid>
      </div>
    </>
  );
};

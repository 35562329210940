import * as React from 'react';
import { SvgIcon } from '../../icon/icon.component';

type PairingCodePropsType = {
  width: number;
  height: number;
};

export const ErrorCrossIcon: React.FC<PairingCodePropsType> = ({
  width,
  height,
}): JSX.Element => {
  return (
    <SvgIcon
      width={width}
      height={height}
      originalWidth={width}
      originalHeight={height}
      viewBox="0 0 96 96"
      fill="none"
    >
      <path
        d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z"
        fill="#E4E9ED"
      />
      <path
        d="M80.7275 48.0004C79.9915 91.6404 15.9995 91.6324 15.2715 48.0004C16.0075 4.36044 79.9995 4.36843 80.7275 48.0004Z"
        fill="#E60039"
      />
      <path
        d="M58.5431 37.4395L37.4551 58.5595"
        stroke="white"
        strokeWidth="4.36"
        strokeMiterlimit="10"
      />
      <path
        d="M58.5431 58.5595L37.4551 37.4395"
        stroke="white"
        strokeWidth="4.36"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
};

import * as React from 'react';
import { useEffect, useState } from 'react';
import { TimeTrackerWrapper } from 'src/widgets/top-patient-bar/patient-info/patient-info.style';
import { Chip } from 'src/widgets/top-patient-bar/patient-info/components/chip/chip.component';
import { RenderIf } from 'src/shared/utils/render-if';
import { t } from 'i18next';
import { colors } from 'src/app/styles/colors';
import { FetchStatus } from 'src/domains/patient/scenes/list-devices/store/list-devices.types';
import { TimeTrackerModal } from 'src/widgets/top-patient-bar/patient-info/components/time-tracker-modal/time-tracker-modal.component';
import { PatientInfo } from 'src/widgets/top-patient-bar/patient-info/patient-info.types';
import { TimeTracker } from 'src/widgets/top-patient-bar/patient-info/components/time-tracker/time-tracker.component';
import { ModalCreationType } from 'src/widgets/top-patient-bar/patient-info/components/time-tracker-modal/time-tracker-modal.types';
import styles from './styles.module.css';

export interface VisitTimeTrackingComponentProps {
  isRPMEnrolled: boolean;
  isTimeTrackerEnrolled: boolean;
  isPatientSessionRunning: boolean;
  resetPatientSession: () => void;
  resetSessionEndStatus: () => void;
  patientSessionTime: number;
  patient: PatientInfo;
  sessionEndStatus: FetchStatus;
  endSessionStart: (payload) => any;
  patientId: number;
  visitId: number;
  resetIsRunning: () => any;
}

const countCurrentSessionTime = (patientSessionTime: number): number => {
  const now = Math.round(new Date().getTime() / 1000);
  return now - patientSessionTime;
};
export const VisitTimeTrackingComponent = (
  props: VisitTimeTrackingComponentProps,
): JSX.Element => {
  const [currentSessionTime, setCurrentSessionTime] = useState<number>(
    countCurrentSessionTime(props.patientSessionTime || 0),
  );

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  let interval: NodeJS.Timer | undefined;

  useEffect(() => {
    if (props.isPatientSessionRunning) {
      interval = setInterval(
        () =>
          setCurrentSessionTime(
            (prevCurrentSessionTime) => prevCurrentSessionTime + 1,
          ),
        1000,
      );
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!(props.isRPMEnrolled && props.isTimeTrackerEnrolled)) {
      clearInterval(interval);
    }
  }, [props.isRPMEnrolled, props.isTimeTrackerEnrolled]);

  useEffect(() => {
    if (props.isPatientSessionRunning && props.visitId) {
      sessionStorage.setItem('visitId', props.visitId.toString());
    }
  }, [props.isPatientSessionRunning, props.visitId]);

  useEffect(() => {
    if (props.sessionEndStatus === FetchStatus.SUCCESS) {
      props.resetIsRunning();
      setModalIsOpen(false);
    }
  }, [props.sessionEndStatus]);

  const onTimeTrackerClick = () => {
    // prevent passing error message
    props.resetSessionEndStatus(); // sessionEndStatus = NONE

    if (props.isPatientSessionRunning) {
      setModalIsOpen(true);
    } else {
      props.resetPatientSession();
    }
  };
  return (
    <RenderIf validate={props.isRPMEnrolled && props.isTimeTrackerEnrolled}>
      <section
        className={`${styles.buttonsSection} ${styles.timeTracker}`}
        data-testid="patient-actions-buttons-section"
      >
        <TimeTrackerModal
          modalCreationType={ModalCreationType.BY_COMPONENT}
          isOpen={modalIsOpen}
          endSessionStart={props.endSessionStart}
          setIsOpen={setModalIsOpen}
          patientId={props.patientId}
          t={t}
          data={{
            patientSessionTime: currentSessionTime,
            patient: props.patient,
            showErrorMessage: props.sessionEndStatus === FetchStatus.FAILURE,
          }}
        />
        <RenderIf validate={props.isPatientSessionRunning}>
          <TimeTrackerWrapper data-testid={'visit-timer'}>
            <Chip backgroundColor={'#A254A8'} onClick={onTimeTrackerClick}>
              <span data-testid="time-tracker-click-area">
                <TimeTracker startTime={currentSessionTime} />
              </span>
            </Chip>
          </TimeTrackerWrapper>
        </RenderIf>
        <RenderIf validate={!props.isPatientSessionRunning}>
          <Chip
            backgroundColor={colors.brandBlue}
            onClick={onTimeTrackerClick}
            data-testid={'start-visit-button'}
          >
            {t('timetracker.startVisit')}
          </Chip>
        </RenderIf>
      </section>
    </RenderIf>
  );
};

import { ProfileTypes } from 'src/domains/patient/store/department/department.types';
import { STATE_ACTIONS } from 'src/app/store/state/state.constants';
import { PatientGender } from 'src/domains/patient/store/patient/patient.types';

import {
  EditPatientActions,
  EditPatientActionType,
  EditPatientState,
} from './edit-patient.types';

export const initialEditPatientState: EditPatientState = {
  isEditingPatient: false,
  editWasSuccessful: null,
  isFetchingPatient: false,
  fetchingPatientHasError: false,
  fetchingPatientWasSuccessful: false,
  patientFormData: {
    grantedPermissions: [],
    profileType: ProfileTypes.none,
    patientInfo: {
      allowPatientAccess: true,
      gender: PatientGender.UNSPECIFIED,
    },
    healthInfo: {
      pregnant: undefined,
    },
  },
  patientFormDataBeforeEditing: null,
};

const handleEditPatientStart = (state: EditPatientState) => ({
  ...state,
  isEditingPatient: true,
});

const handleEditEMRPatientStart = handleEditPatientStart;

const handleEditPatientSuccess = (state: EditPatientState) => ({
  ...state,
  isEditingPatient: false,
  editWasSuccessful: true,
});

const handleEditEMRPatientSuccess = handleEditPatientSuccess;

const handleEditPatientStatusModalConfirmed = (state: EditPatientState) => ({
  ...state,
  editWasSuccessful: null,
});

const handleEditPatientError = (state: EditPatientState) => ({
  ...state,
  isEditingPatient: false,
  editWasSuccessful: false,
});

const handleEditEMRPatientError = handleEditPatientError;

const handleFetchPatientForEditStart = (state: EditPatientState) => ({
  ...state,
  isFetchingPatient: true,
});

const handleFetchPatientForEditSuccess = (
  state: EditPatientState,
  action: EditPatientActions,
) => ({
  ...state,
  isFetchingPatient: false,
  fetchingPatientWasSuccessful: true,
  patientFormDataBeforeEditing: action.payload,
});

const handleFetchPatientForEditError = (state: EditPatientState) => ({
  ...state,
  isFetchingPatient: false,
  fetchingPatientHasError: true,
});

const handleNoAssociatedProfessionals = (state: EditPatientState) => ({
  ...state,
  patientFormData: {
    ...state.patientFormData,
    healthInfo: {
      ...state.patientFormData.healthInfo,
      associatedProfessional: undefined,
    },
  },
});

const handleClearEditPatient = () => initialEditPatientState;

const actionHandlers = {
  [EditPatientActionType.EDIT_PATIENT_START]: handleEditPatientStart,
  [EditPatientActionType.EDIT_EMR_PATIENT_START]: handleEditEMRPatientStart,
  [EditPatientActionType.EDIT_PATIENT_SUCCESS]: handleEditPatientSuccess,
  [EditPatientActionType.EDIT_EMR_PATIENT_SUCCESS]: handleEditEMRPatientSuccess,
  [EditPatientActionType.EDIT_PATIENT_STATUS_MODAL_CONFIRMED]:
    handleEditPatientStatusModalConfirmed,
  [EditPatientActionType.EDIT_PATIENT_ERROR]: handleEditPatientError,
  [EditPatientActionType.EDIT_EMR_PATIENT_ERROR]: handleEditEMRPatientError,
  [EditPatientActionType.FETCH_PATIENT_FOR_EDIT_START]:
    handleFetchPatientForEditStart,
  [EditPatientActionType.FETCH_PATIENT_FOR_EDIT_SUCCESS]:
    handleFetchPatientForEditSuccess,
  [EditPatientActionType.FETCH_PATIENT_FOR_EDIT_ERROR]:
    handleFetchPatientForEditError,
  [EditPatientActionType.NO_ASSOCIATED_PROFESSIONALS]:
    handleNoAssociatedProfessionals,
  [STATE_ACTIONS.CLEAR_EDIT_PATIENT]: handleClearEditPatient,
};

export const editPatientReducer = (
  state = initialEditPatientState,
  action: EditPatientActions,
): EditPatientState => {
  const handler = actionHandlers[action.type];
  if (handler) {
    return handler(state, action);
  }

  return state;
};

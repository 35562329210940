import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { State } from 'src/app/store/app.types';
import {
  fetchLanguagesError,
  fetchLanguagesSuccess,
} from 'src/domains/patient/store/languages/languages.actions';
import { ActiveSystemLanguagesService } from 'src/app/services/languages/active-system-languages/active-system-languages.types';

import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';

import { LanguagesActions, LanguagesActionType } from './languages.types';

export const fetchLanguagesEpic =
  (service: ActiveSystemLanguagesService): Epic<LanguagesActions, State> =>
  (action$, store$) =>
    action$
      .ofType(LanguagesActionType.FETCH_LANGUAGES_START)
      .debounceTime(1000)
      .switchMap(() => {
        const openId = {
          accessToken: selectAccessToken(store$.getState()),
          gigyaToken: selectGigyaToken(store$.getState()),
        };
        return Observable.fromPromise(service(openId))
          .map((data) => fetchLanguagesSuccess(data))
          .pipe(catchError((err) => Observable.of(fetchLanguagesError(err))));
      });

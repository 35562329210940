// tslint:disable
import styled from 'styled-components';
import {
  ModalBody,
  ModalCard,
  ModalHeader,
} from 'src/shared/design-system/modal/modal.style';

import { breakpoints } from 'src/app/styles/breakpoints';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
`;

export const ModalCardReportTimeTracker = styled(ModalCard)`
  width: 525px;
`;
export const Title = styled.div`
  color: #0066cc;
  font-family: Nunito Medium;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 20px;
`;

export const ModalBodyExportTT = styled(ModalBody)`
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  padding-top: 32px;
  padding-left: 62px;
  padding-right: 62px;
  padding-bottom: 24px;
`;

export const ModalHeaderTT = styled(ModalHeader)`
  font-size: 1rem;
  background-color: #004d99;
  font-family: Nunito Medium;
`;
export const Description = styled.div`
  color: #4a4a4a;
  font-family: Nunito Regular;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-bottom: 40px;
`;

export const CalendarBox = styled.div`
  height: 41px;
  width: 254px;
  // border: 1px solid #c3d0e8;
  margin-bottom: 40px;
  border-radius: 2px;
  .calendarReportTT {
    padding: 0;
    width: 100%;
  }
  .DateRangePickerInput {
    width: 100%;
  }

  .DateRangePicker_picker {
    @media (max-width: ${breakpoints.changeMedium}) {
      left: -25vw !important;
      top: -5vh !important;
    }
  }
`;

import {
  equals,
  flatten,
  isNil,
  map,
  not,
  pathOr,
  pathSatisfies,
  pipe,
} from 'ramda';

import { getPatientStockRequest } from 'src/domains/strip-management/store/patient-stock/patient-stock.actions';
import { PATIENT_ACTIONS } from 'src/domains/patient/store/patient/patient.constant';
import {
  PATIENT_PRESCRIPTION_TYPES,
  UNSAVED_PRESCRIPTION_IDS,
} from 'src/domains/strip-management/store/prescription/prescription.constants';
import { requestSequence } from 'src/app/store/request/request.epics';
import { hasValue } from 'src/shared/utils/validation-helpers';

import {
  FETCH_TEMPORARY_REASONS_REQUEST,
  GET_CURRENT_PRESCRIPTION_REQUEST,
  PRESCRIPTION_ACTIONS,
  SAVE_PRESCRIPTION_REQUEST,
} from './prescription.constants';
import { GET_CLINIC_GUIDES_REQUEST } from './clinic-guide/clinic-guide.constants';
import {
  INITIAL_PRESCRIPTION_FORM_STATE,
  INITIAL_TEMPORARY_PRESCRIPTION_FORM_STATE,
} from './shared/prescription.reducers';
import {
  addPrescriptionEntry,
  fetchTemporaryReasonsRequest,
  getClinicGuidesRequest,
  getCurrentPrescriptionRequest,
  getFrequenciesRequest,
  getTherapiesRequest,
  resetPrescription,
  setActivePrescriptionForm,
  updateQuantitiesAndPeriods,
} from './prescription.actions';
import {
  findClinicGuideWithPrescriptionId,
  flattenPrescriptionDateRange,
  flattenPrescriptions,
  shouldSetPrescriptionFormActive,
} from './prescription.utils';

export * from './clinic-guide/clinic-guide.epics'; // WIP-MOD Move clinical guidance imports
export * from './frequencies/frequencies.epics'; // WIP-MOD Move frequencies imports
export * from './therapy/therapy.epics'; // WIP-MOD Move therapy imports

export const constructUnsavedPermanent = () => ({
  ...INITIAL_PRESCRIPTION_FORM_STATE,
  id: UNSAVED_PRESCRIPTION_IDS.PERMANENT,
  prescriptionType: PATIENT_PRESCRIPTION_TYPES.PERMANENT,
});

export const constructUnsavedTemporary = () => ({
  ...INITIAL_TEMPORARY_PRESCRIPTION_FORM_STATE,
  id: UNSAVED_PRESCRIPTION_IDS.TEMPORARY,
  prescriptionType: PATIENT_PRESCRIPTION_TYPES.TEMPORARY,
});

export const constructUnsavedPrescription = (prescriptionType) =>
  equals(prescriptionType, PATIENT_PRESCRIPTION_TYPES.PERMANENT)
    ? constructUnsavedPermanent()
    : constructUnsavedTemporary();

export const combineWithDefaultFormState = (prescription) => ({
  ...INITIAL_PRESCRIPTION_FORM_STATE,
  ...prescription,
});

export const prescriptionTypeFromAction = ({ type }) =>
  equals(GET_CURRENT_PRESCRIPTION_REQUEST.ERROR, type)
    ? PATIENT_PRESCRIPTION_TYPES.PERMANENT
    : PATIENT_PRESCRIPTION_TYPES.TEMPORARY;

export const getCurrentPrescriptionEpic = (getPrescriptionService) =>
  requestSequence({
    service: getPrescriptionService,
    actionTypes: GET_CURRENT_PRESCRIPTION_REQUEST,
  });

export const isStripModelStockSet = (stripModelReference) =>
  pathSatisfies(pipe(isNil, not), ['patientStock', stripModelReference]);

export const conditionalAction = ({ condition, action }) =>
  condition ? [action] : [];

export const setCurrentPrescriptionsEpic = () => (action$, state) =>
  action$
    .ofType(GET_CURRENT_PRESCRIPTION_REQUEST.SUCCESS)
    .flatMap(({ payload }) => {
      const createPrescriptionActions = (prescription) => {
        const { stripModel, patientId } = prescription;
        const getPatientStock = conditionalAction({
          condition: !isStripModelStockSet(stripModel)(state.getState()),
          action: getPatientStockRequest.start({
            patientId,
            stripModelReference: stripModel,
          }),
        });
        return [addPrescriptionEntry(prescription), ...getPatientStock];
      };
      return pipe(
        flattenPrescriptions,
        map(pipe(combineWithDefaultFormState, createPrescriptionActions)),
        flatten,
      )(payload);
    });

export const onPrescriptionSaveFetchLatestEpic = () => (action$, state) =>
  action$.ofType(SAVE_PRESCRIPTION_REQUEST.SUCCESS).flatMap(({ payload }) => {
    const patientId = pathOr(null, ['patientId'], payload);
    return [
      resetPrescription(),
      ...conditionalAction({
        condition: hasValue(patientId),
        action: getCurrentPrescriptionRequest.start({ patientId }),
      }),
    ];
  });

export const addPrescriptionEntryEpic = () => (action$, state) =>
  action$
    .ofType(PRESCRIPTION_ACTIONS.ADD_PRESCRIPTION_ENTRY)
    .filter(({ payload }) =>
      shouldSetPrescriptionFormActive(payload)(state.getState()),
    )
    .map(({ payload: { id } }) => setActivePrescriptionForm(id));

export const onClinicGuidesUpdateEpic = () => (action$, state) =>
  action$.ofType(GET_CLINIC_GUIDES_REQUEST.SUCCESS).flatMap((action) => {
    const { id } = action.payload;
    const { permanent, temporary } = state.getState().prescription;
    const clinicGuide = findClinicGuideWithPrescriptionId(id)({
      permanent,
      temporary,
    });
    return conditionalAction({
      condition: hasValue(clinicGuide),
      action: updateQuantitiesAndPeriods({
        formId: id,
        clinicGuideId: clinicGuide,
      }),
    });
  });

export const fetchTemporaryPrescriptionReasonsEpic = (reasonsService) =>
  requestSequence({
    service: reasonsService,
    actionTypes: FETCH_TEMPORARY_REASONS_REQUEST,
  });

export const flattenSavedPrescriptionDateRange = (query) => ({
  ...query,
  prescription: flattenPrescriptionDateRange(query.prescription),
});

export const addSavedPrescriptionIds = (returnedPrescription, action) => ({
  patientId: action.payload.patientId,
  previousPrescriptionId: action.payload.prescriptionId,
  prescription: returnedPrescription,
});

export const savePrescriptionEpic = (savePrescriptionService) =>
  requestSequence({
    service: savePrescriptionService,
    actionTypes: SAVE_PRESCRIPTION_REQUEST,
    queryTransform: flattenSavedPrescriptionDateRange,
    responseTransform: addSavedPrescriptionIds,
    options: { delay: true },
  });

export const onPatientChangeResetPrescriptionEpic = () => (action$) =>
  action$
    .ofType(PATIENT_ACTIONS.SET_NEW_PATIENT)
    .flatMap((action) => [resetPrescription()]);

export const initializePrescriptionEpic = () => (action$, state) =>
  action$
    .ofType(PRESCRIPTION_ACTIONS.INITIALIZE_PRESCRIPTION)
    .flatMap((action) => {
      const { therapyId, formId, isTemporaryPrescription } = action.payload;
      const fetchTemporaryReasons = conditionalAction({
        condition: isTemporaryPrescription,
        action: fetchTemporaryReasonsRequest.start(),
      });
      return [
        getTherapiesRequest.start(),
        getFrequenciesRequest.start(),
        getClinicGuidesRequest.start({ id: formId, therapyId }),
        ...fetchTemporaryReasons,
      ];
    });

import { createAction, createPayloadAction } from 'src/app/store/app.actions';
import {
  FetchPatientTimeBlocksErrorAction,
  FetchPatientTimeBlocksStartAction,
  FetchPatientTimeBlocksStartPayloadType,
  FetchPatientTimeBlocksSuccessAction,
  MoveTimeBlocksUIAction,
  PatientTimeBlocksActionType,
  PatientTimeBlocksStatusResetAction,
} from './time-periods.types';
import { TimeInterval } from './../time-periods.types';

export const fetchPatientTimeBlocksStart = (
  patientId: FetchPatientTimeBlocksStartPayloadType,
): FetchPatientTimeBlocksStartAction =>
  createPayloadAction(
    PatientTimeBlocksActionType.FETCH_PATIENT_TIMEBLOCKS_START,
    patientId,
  );

export const fetchPatientTimeBlocksSuccess = (
  params: TimeInterval[],
): FetchPatientTimeBlocksSuccessAction =>
  createPayloadAction(
    PatientTimeBlocksActionType.FETCH_PATIENT_TIMEBLOCKS_SUCCESS,
    params,
  );

export const fetchPatientTimeBlocksError =
  (): FetchPatientTimeBlocksErrorAction =>
    createAction(PatientTimeBlocksActionType.FETCH_PATIENT_TIMEBLOCKS_ERROR);

export const moveTimePeriodsUI = (
  params: TimeInterval[],
): MoveTimeBlocksUIAction =>
  createPayloadAction(PatientTimeBlocksActionType.MOVE_TIME_BLOCKS_UI, params);

export const savePatientTimeBlocksStart = (
  patientId: string,
  blocks: TimeInterval[],
): FixMe =>
  createPayloadAction(
    PatientTimeBlocksActionType.SAVE_PATIENT_TIMEBLOCKS_START,
    {
      patientId,
      blocks,
    },
  );

export const savePatientTimeBlocksSuccess = (blocks: TimeInterval[]): FixMe =>
  createPayloadAction(
    PatientTimeBlocksActionType.SAVE_PATIENT_TIMEBLOCKS_SUCCESS,
    blocks,
  );

export const patientTimeBlocksStatusReset =
  (): PatientTimeBlocksStatusResetAction =>
    createAction(PatientTimeBlocksActionType.PATIENT_TIMEBLOCKS_STATUS_RESET);

export const savePatientTimeBlocksError = (): FixMe =>
  createAction(PatientTimeBlocksActionType.SAVE_PATIENT_TIMEBLOCKS_ERROR);

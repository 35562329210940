import {
  Action,
  PayloadAction,
} from '../../../../../../../app/store/app.types';
import {
  GetObservationalStatusErrorPayload,
  GetObservationalStatusParams,
  GetObservationalStatusSuccessPayload,
  GetObservationalStatusTransformedResponseType,
} from '../../../../../services/patient/clinical-studies/observational-clinical-studies/get-observational-studies-status/get-observational-studies-status.types';

export enum GetObservationalStatusActionType {
  GET_OBSERVATIONAL_STATUS_START = 'GET_OBSERVATIONAL_STATUS_START',
  GET_OBSERVATIONAL_STATUS_SUCCESS = 'GET_OBSERVATIONAL_STATUS_SUCCESS',
  GET_OBSERVATIONAL_STATUS_ERROR = 'GET_OBSERVATIONAL_STATUS_ERROR',
  CLEAR_OBSERVATIONAL_STATUS = 'CLEAR_OBSERVATIONAL_STATUS',
}

export type GetObservationalStatusError =
  GetObservationalStatusTransformedResponseType;

export type GetObservationalStatusStartAction = PayloadAction<
  GetObservationalStatusActionType.GET_OBSERVATIONAL_STATUS_START,
  GetObservationalStatusParams
>;

export type GetObservationalStatusSuccessAction = PayloadAction<
  GetObservationalStatusActionType.GET_OBSERVATIONAL_STATUS_SUCCESS,
  GetObservationalStatusSuccessPayload
>;

export type GetObservationalStatusErrorAction = PayloadAction<
  GetObservationalStatusActionType.GET_OBSERVATIONAL_STATUS_ERROR,
  GetObservationalStatusErrorPayload
>;

export type ClearObservationalStatusAction =
  Action<GetObservationalStatusActionType.CLEAR_OBSERVATIONAL_STATUS>;

export type GetObservationalStatusActions =
  | GetObservationalStatusStartAction
  | GetObservationalStatusSuccessAction
  | GetObservationalStatusErrorAction
  | ClearObservationalStatusAction;

export type GetObservationalStatusState = {
  isGettingObservationalStatus: boolean;
  getObservationalStatusSuccessful: boolean;
  getObservationalStatusError: boolean;
  status: GetObservationalStatusError;
  studyId: string;
  subjectId: string;
};

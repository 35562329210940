import styled from 'styled-components';
import { space } from 'styled-system';
import { path, pathOr } from 'ramda';

import { borderRadius } from 'src/app/styles/border-radius';
import { boxShadow } from 'src/app/styles/box-shadow';
import { colors } from 'src/app/styles/colors';
import { fontSize } from 'src/app/styles/font-sizes';
import { spacing } from 'src/app/styles/spacing';
import { transitions } from 'src/app/styles/transitions';
import { weight } from 'src/shared/design-system/fonts/weights/weight.style';
import { combineRems, convertPxToRem } from 'src/shared/utils/rem-calc';

import { ButtonSizes } from './button.types';

const {
  brandBlue,
  brandBlueDark,
  grayLight,
  silver,
  transparent,
  white,
  quartzBlue,
  blueBackgroundButton,
  grayTextButton,
  blueMarineBorder,
} = colors;

// Set button theming
// Multiple themes ill be mapped to one styled component below
const buttonMap = {
  info: {
    always: {
      padding: `${combineRems(spacing.one, spacing.two)} ${spacing.two}`,
    },
    default: {
      bg: white,
      border: white,
      boxShadow: boxShadow({ color: colors.black, depth: 'one' }),
      text: brandBlue,
    },
    disabled: {
      bg: white,
      border: white,
      boxShadow: boxShadow({ color: colors.black, depth: 'two' }),
      text: grayLight,
    },
    hover: {
      bg: white,
      border: white,
      boxShadow: boxShadow({ color: colors.black, depth: 'two' }),
      text: brandBlueDark,
    },
  },
  outlinedLight: {
    always: {
      padding: `${spacing.two} ${spacing.four}`,
    },
    default: {
      bg: white,
      border: quartzBlue,
      boxShadow: 'none',
      text: brandBlue,
    },
    disabled: {
      bg: white,
      border: silver,
      boxShadow: 'none',
      text: grayLight,
    },
    hover: {
      bg: white,
      border: brandBlue,
      boxShadow: 'none',
      text: brandBlue,
    },
  },
  primary: {
    always: {
      fontWeight: weight.bold,
      padding: `${combineRems(spacing.one, spacing.two)} ${spacing.four}`,
    },
    default: {
      bg: brandBlue,
      border: brandBlue,
      boxShadow: 'none',
      text: white,
    },
    disabled: {
      bg: silver,
      border: silver,
      boxShadow: boxShadow({ color: colors.black, depth: 'two' }),
      text: grayLight,
    },
    hover: {
      bg: brandBlue,
      border: brandBlue,
      boxShadow: boxShadow({ color: colors.black, depth: 'two' }),
      text: white,
    },
  },
  secondary: {
    always: {
      padding: `${combineRems(spacing.one, spacing.two)} ${spacing.four}`,
    },
    default: {
      bg: transparent,
      border: brandBlue,
      boxShadow: 'none',
      text: brandBlue,
    },
    disabled: {
      bg: transparent,
      border: silver,
      boxShadow: boxShadow({ olor: colors.black, depth: 'two' }),
      text: grayLight,
    },
    hover: {
      bg: transparent,
      border: brandBlueDark,
      boxShadow: boxShadow({ color: colors.black, depth: 'two' }),
      text: brandBlueDark,
    },
  },
  square: {
    always: {
      padding: '6px',
    },
    default: {
      bg: transparent,
      border: brandBlueDark,
      boxShadow: 'none',
      text: brandBlueDark,
      borderRadius: '1.65px',
    },
    active: {
      bg: blueBackgroundButton,
      border: brandBlueDark,
      boxShadow: 'none',
      text: brandBlueDark,
      borderRadius: '1.65px',
    },
    inactive: {
      bg: transparent,
      border: blueMarineBorder,
      boxShadow: 'none',
      text: grayTextButton,
    },
    disabled: {
      bg: transparent,
      border: silver,
      boxShadow: 'none',
      text: grayLight,
    },
    hover: {
      bg: transparent,
      border: brandBlueDark,
      boxShadow: boxShadow({ color: colors.black, depth: 'two' }),
      text: brandBlueDark,
    },
  },
};

export const buttonSizeMap = {
  [ButtonSizes.SMALL]: convertPxToRem(112),
  [ButtonSizes.MEDIUM]: convertPxToRem(156),
  [ButtonSizes.LARGE]: convertPxToRem(200),
  [ButtonSizes.AUTO]: 'auto',
};

export const BaseButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: ${convertPxToRem(2)} solid currentColor;
  border-radius: ${(props) =>
    pathOr(
      borderRadius.hundred,
      [props.buttonStyle, 'default', 'borderRadius'],
      buttonMap,
    )};
  cursor: ${(props) =>
    props.loading || props.disabled ? 'not-allowed' : 'pointer'};
  display: inline-block;
  font-size: ${fontSize.p};
  font-weight: ${(props) =>
    path([props.buttonStyle, 'always', 'fontWeight'], buttonMap) ||
    weight.semiBold};
  line-height: 0.85em;
  max-width: 100%;
  min-width: ${(props) => buttonSizeMap[props.buttonSize]};
  padding: ${(props) =>
    path([props.buttonStyle, 'always', 'padding'], buttonMap)};
  pointer-events: ${(props) =>
    props.loading || props.disabled ? 'none' : 'auto'};
  transition: ${transitions.default};
  ${space};
  &:focus {
    outline: none;
  }
`;

// Create dynamic button styling based on props
export const BrandButton = styled(BaseButton)`
  background-color: ${(props) =>
    path([props.buttonStyle, 'default', 'bg'], buttonMap)};
  border-color: ${(props) =>
    path([props.buttonStyle, 'default', 'border'], buttonMap)};
  color: ${(props) => path([props.buttonStyle, 'default', 'text'], buttonMap)};
  font-size: ${(props) => props.fontSize};
  ${(props) => props.uppercase && 'text-transform: uppercase'};
  font-weight: ${(props) => weight[props.fontWeight]};
  min-width: ${(props) => props.minWidth};
  max-height: ${(props) => props.maxHeight};
  box-shadow: ${(props) =>
    path([props.buttonStyle, 'default', 'boxShadow'], buttonMap)};

  path {
    fill: ${(props) => path([props.buttonStyle, 'default', 'text'], buttonMap)};
  }
  circle {
    fill: ${(props) => path([props.buttonStyle, 'default', 'text'], buttonMap)};
    & + path {
      fill: ${(props) => path([props.buttonStyle, 'default', 'bg'], buttonMap)};
    }
  }

  &:hover,
  &:focus {
    background-color: ${(props) =>
      path([props.buttonStyle, 'hover', 'bg'], buttonMap)};
    border-color: ${(props) =>
      path([props.buttonStyle, 'hover', 'border'], buttonMap)};
    box-shadow: ${(props) =>
      path([props.buttonStyle, 'hover', 'boxShadow'], buttonMap)};
    color: ${(props) => path([props.buttonStyle, 'hover', 'text'], buttonMap)};

    path {
      fill: ${(props) => path([props.buttonStyle, 'hover', 'text'], buttonMap)};
    }
    circle {
      fill: ${(props) => path([props.buttonStyle, 'hover', 'text'], buttonMap)};
      & + path {
        fill: ${(props) => path([props.buttonStyle, 'hover', 'bg'], buttonMap)};
      }
    }
  }

  &:active {
    background-color: ${(props) =>
      path([props.buttonStyle, 'default', 'bg'], buttonMap)};
    border-color: ${(props) =>
      path([props.buttonStyle, 'default', 'border'], buttonMap)};
    box-shadow: ${(props) =>
      path([props.buttonStyle, 'hover', 'boxShadow'], buttonMap)};
    color: ${(props) =>
      path([props.buttonStyle, 'default', 'text'], buttonMap)};

    path {
      fill: ${(props) =>
        path([props.buttonStyle, 'default', 'text'], buttonMap)};
    }
    circle {
      fill: ${(props) =>
        path([props.buttonStyle, 'default', 'text'], buttonMap)};
      & + path {
        fill: ${(props) =>
          path([props.buttonStyle, 'default', 'bg'], buttonMap)};
      }
    }
  }

  &[disabled] {
    background-color: ${(props) =>
      path([props.buttonStyle, 'disabled', 'bg'], buttonMap)};
    border-color: ${(props) =>
      path([props.buttonStyle, 'disabled', 'border'], buttonMap)};
    color: ${(props) =>
      path([props.buttonStyle, 'disabled', 'text'], buttonMap)};

    path {
      fill: ${(props) =>
        path([props.buttonStyle, 'disabled', 'text'], buttonMap)};
    }
    circle {
      fill: ${(props) =>
        path([props.buttonStyle, 'disabled', 'text'], buttonMap)};
      & + path {
        fill: ${(props) =>
          path([props.buttonStyle, 'disabled', 'bg'], buttonMap)};
      }
    }
  }
`;

BrandButton.defaultProps = {
  buttonStyle: 'primary',
};

// Create dynamic square button styling based on props
export const SquareButton = styled(BaseButton)`
  border: 1px solid;
  border-radius: 2px;
  background-color: ${(props) =>
    props.selected
      ? path([props.buttonStyle, 'active', 'bg'], buttonMap)
      : path([props.buttonStyle, 'inactive', 'bg'], buttonMap)};
  border-color: ${(props) =>
    props.selected
      ? path([props.buttonStyle, 'active', 'border'], buttonMap)
      : path([props.buttonStyle, 'inactive', 'border'], buttonMap)};
  color: ${(props) =>
    props.selected
      ? path([props.buttonStyle, 'active', 'text'], buttonMap)
      : path([props.buttonStyle, 'inactive', 'text'], buttonMap)};
  font-size: ${(props) => props.fontSize};
  ${(props) => props.uppercase && 'text-transform: uppercase'};
  font-weight: ${(props) => weight[props.fontWeight]};
  min-width: ${(props) => props.minWidth}px;
  max-height: ${(props) => props.maxHeight};
  box-shadow: ${(props) =>
    path([props.buttonStyle, 'default', 'boxShadow'], buttonMap)};
  height: ${(props) => (props.height ? `${props.height}px` : 'auto')};

  path {
    fill: ${(props) => path([props.buttonStyle, 'default', 'text'], buttonMap)};
  }
  circle {
    fill: ${(props) => path([props.buttonStyle, 'default', 'text'], buttonMap)};
    & + path {
      fill: ${(props) => path([props.buttonStyle, 'default', 'bg'], buttonMap)};
    }
  }

  &:hover,
  &:focus {
    background-color: ${(props) =>
      path([props.buttonStyle, 'hover', 'bg'], buttonMap)};
    border-color: ${(props) =>
      path([props.buttonStyle, 'hover', 'border'], buttonMap)};
    box-shadow: ${(props) =>
      path([props.buttonStyle, 'hover', 'boxShadow'], buttonMap)};
    color: ${(props) => path([props.buttonStyle, 'hover', 'text'], buttonMap)};

    path {
      fill: ${(props) => path([props.buttonStyle, 'hover', 'text'], buttonMap)};
    }
    circle {
      fill: ${(props) => path([props.buttonStyle, 'hover', 'text'], buttonMap)};
      & + path {
        fill: ${(props) => path([props.buttonStyle, 'hover', 'bg'], buttonMap)};
      }
    }
  }

  &:active {
    background-color: ${(props) =>
      path([props.buttonStyle, 'default', 'bg'], buttonMap)};
    border-color: ${(props) =>
      path([props.buttonStyle, 'default', 'border'], buttonMap)};
    box-shadow: ${(props) =>
      path([props.buttonStyle, 'hover', 'boxShadow'], buttonMap)};
    color: ${(props) =>
      path([props.buttonStyle, 'default', 'text'], buttonMap)};

    path {
      fill: ${(props) =>
        path([props.buttonStyle, 'default', 'text'], buttonMap)};
    }
    circle {
      fill: ${(props) =>
        path([props.buttonStyle, 'default', 'text'], buttonMap)};
      & + path {
        fill: ${(props) =>
          path([props.buttonStyle, 'default', 'bg'], buttonMap)};
      }
    }
  }

  &[disabled] {
    background-color: ${(props) =>
      path([props.buttonStyle, 'disabled', 'bg'], buttonMap)};
    border-color: ${(props) =>
      path([props.buttonStyle, 'disabled', 'border'], buttonMap)};
    color: ${(props) =>
      path([props.buttonStyle, 'disabled', 'text'], buttonMap)};

    path {
      fill: ${(props) =>
        path([props.buttonStyle, 'disabled', 'text'], buttonMap)};
    }
    circle {
      fill: ${(props) =>
        path([props.buttonStyle, 'disabled', 'text'], buttonMap)};
      & + path {
        fill: ${(props) =>
          path([props.buttonStyle, 'disabled', 'bg'], buttonMap)};
      }
    }
  }
`;

SquareButton.defaultProps = {
  buttonStyle: 'selected',
};

export const ButtonTextSpan = styled.span``;

export const ButtonIconSpan = styled.span`
  svg {
    height: ${fontSize.subheading};
  }
  & + ${ButtonTextSpan} {
    padding-left: ${spacing.two};
  }
`;

export const ButtonTextSpanInverted = styled.span`
  & + ${ButtonIconSpan} {
    padding-left: ${spacing.two};
  }
`;

export const ButtonContentSpan = styled.span`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const ButtonLoadingIconSpan = styled.span`
  padding-left: ${spacing.two};
`;

import {
  convertCurrentDateInMs,
  convertJSDateGMT,
  toStartOfDay,
} from 'src/shared/utils/date';
import { convertDateStringToMs } from 'src/domains/diagnostics/time';
import { isNumberBetween } from 'src/shared/utils/validation-helpers';
import { withoutTargetRange } from 'src/domains/diagnostics/scenes/graphs/logbook/logbook.core.utils';
import { isCrossoverBlock } from '../../graph/logbook.graph-utils';
import {
  DAY_IN_MS,
  DAY_START_MS,
} from 'src/domains/diagnostics/constants/logbook.constants';

const toLogbookBGFormat = (
  {
    carbohydrates,
    date,
    value: glucose,
    afterMeal,
    beforeMeal,
    hypoSymptoms,
    insulin1,
    insulin2,
    insulin3,
    manuallyEntered,
    rangeType,
  },
  allThresholds,
  timeIntervals,
) => {
  const aboveTargetRange = withoutTargetRange(
    'ABOVE',
    glucose,
    date,
    allThresholds,
    timeIntervals,
    beforeMeal,
    afterMeal,
  );
  const belowTargetRange = withoutTargetRange(
    'BELOW',
    glucose,
    date,
    allThresholds,
    timeIntervals,
    beforeMeal,
    afterMeal,
  );

  return {
    aboveTargetRange,
    belowTargetRange,
    hypoSymptoms,
    afterMeal,
    beforeMeal,
    date,
    glucose,
    bolus: null,
    carbohydrates,
    insulin1,
    insulin2,
    insulin3,
    manuallyEntered,
    rangeType,
  };
};

const isolateInsulinObjects = (measurement) => {
  const glucose =
    measurement.glucose || measurement.carbohydrates || measurement.rangeType
      ? { ...measurement }
      : null;
  const insulin1 = measurement.insulin1
    ? {
        ...measurement,
        glucose: null,
        carbohydrates: null,
        insulin: { value: measurement.insulin1 },
      }
    : null;
  const insulin2 = measurement.insulin2
    ? {
        ...measurement,
        glucose: null,
        carbohydrates: null,
        insulin: { value: measurement.insulin2 },
      }
    : null;
  const insulin3 = measurement.insulin3
    ? {
        ...measurement,
        glucose: null,
        carbohydrates: null,
        insulin: { value: measurement.insulin3 },
      }
    : null;
  const bolus = measurement.bolus
    ? {
        ...measurement,
        glucose: null,
        carbohydrates: null,
        insulin: { value: measurement.bolus.value },
      }
    : null;

  const bolusAndInsulinNotNull = [bolus, insulin1, insulin2, insulin3].filter(
    (x) => x,
  );

  if (!glucose) {
    return bolusAndInsulinNotNull;
  } else {
    const insulin = bolusAndInsulinNotNull[0] || { insulin: null };
    const remainder = bolusAndInsulinNotNull.slice(1);
    glucose.insulin = insulin.insulin;
    return [glucose, ...remainder];
  }
};

const groupByDay = (measurements) =>
  measurements.reduce((measurementsGroupedByDay, measurement) => {
    const date = toStartOfDay(convertJSDateGMT(measurement.date));

    const currentMeasurements = measurementsGroupedByDay[date] || [];

    return {
      ...measurementsGroupedByDay,
      [date]: [...currentMeasurements, measurement],
    };
  }, {});

export const groupMeasurementsByDay = (
  measurements,
  boluses,
  allThresholds,
  timeIntervals,
) => {
  const logbookGlucoseMeasurements = measurements.map((measurement) =>
    toLogbookBGFormat(measurement, allThresholds, timeIntervals),
  );

  const groupedBgMeasurements = groupByDay(logbookGlucoseMeasurements);

  const groupedBoluses = groupByDay(boluses);

  const allTimeObjects = [...logbookGlucoseMeasurements, ...boluses].sort(
    (a, b) => (a.date.getTime() < b.date.getTime() ? -1 : 1),
  );

  groupByDay(allTimeObjects);

  const allDates = [...new Set([...Object.keys(groupByDay(allTimeObjects))])];

  return allDates.reduce((groupedBgAndBolusMeasurements, date) => {
    const bgMeasurements = groupedBgMeasurements[date] || [];
    const boluses = groupedBoluses[date] || [];

    const orphanedBoluses = boluses
      .filter(
        (bolus) =>
          !bgMeasurements.find(
            (bg) => bg.date.getTime() === bolus.date.getTime(),
          ),
      )
      .map((bolus) => ({
        glucose: null,
        carbohydrates: null,
        insulin: { value: bolus.value },
        bolus,
        date: bolus.date,
      }));

    const bolusesWithSameDateAtBgMeasurements = [];
    const mergedBgMeasurementsAndBoluses = bgMeasurements.map((bg) => {
      const bolusesWithSameDate = boluses.find(
        (bolus) =>
          bolus.date.getTime() === bg.date.getTime() &&
          !bolusesWithSameDateAtBgMeasurements.includes(bolus.value),
      );
      if (bolusesWithSameDate && bolusesWithSameDate.value) {
        bolusesWithSameDateAtBgMeasurements.push(bolusesWithSameDate.value);
      }
      const mergedBgMeasurementsAndBolus = {
        ...bg,
        bolus: bolusesWithSameDate,
      };

      return mergedBgMeasurementsAndBolus;
    });

    const sequencedMeasurements = mergedBgMeasurementsAndBoluses // [{}, {} ]
      .map(isolateInsulinObjects) //[[{},{}], [{},{}]]
      .reduce((a, b) => a.concat(b), []); //[{insulin: },{}, {}]

    return {
      ...groupedBgAndBolusMeasurements,
      [date]: [...sequencedMeasurements, ...orphanedBoluses].sort((a, b) => {
        const aTime = a.date.getTime();
        const bTime = b.date.getTime();
        return aTime < bTime ? -1 : bTime < aTime ? 1 : 0;
      }),
    };
  }, {});
};

export const groupDayMeasurementsByMealTime = (
  measurementsGroupedByDay,
  timeIntervals,
) =>
  measurementsGroupedByDay.reduce(
    (measurementsGroupedByMealtime, measurement, index) => {
      let accumulator = {};
      if (index > 0) {
        accumulator = measurementsGroupedByMealtime;
      } else {
        timeIntervals.forEach(({ description: mealTime }) => {
          accumulator[mealTime] = [];
        });
      }
      // JS Date
      const { date } = measurement;
      const msCurrentDate = convertCurrentDateInMs(date);
      const mealtime =
        timeIntervals.find((timeInterval) => {
          const { startTime, endTime } = timeInterval;
          const startTimeMs = convertDateStringToMs(startTime);
          const endTimeMs = convertDateStringToMs(endTime);
          if (isCrossoverBlock(startTimeMs, endTimeMs)) {
            return (
              isNumberBetween(startTimeMs, DAY_IN_MS - 1, msCurrentDate) ||
              isNumberBetween(DAY_START_MS, endTimeMs, msCurrentDate)
            );
          }
          return isNumberBetween(startTimeMs, endTimeMs, msCurrentDate);
        }) || //defaulting to nightblock if time doesnt fall in any timeblocks
        timeIntervals.filter((timeblock) => timeblock.description === 'NIGHT');
      if (!mealtime) {
        return accumulator;
      }
      const { description: mealtimeName } = mealtime;
      return {
        ...accumulator,
        [mealtimeName]: [...accumulator[mealtimeName], measurement],
      };
    },
    {},
  );

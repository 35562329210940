import { NORMALIZATION } from 'src/shared/utils/stat';

export const GRAPH_LABEL_VALUE = 0.5;
export const METABOLIC_GRAPH_X_MAX_MG = 150;
export const METABOLIC_GRAPH_Y_MAX_MG = 400;
export const STD_DEV_POPULATION = NORMALIZATION.UNCORRECTED;
export const Y_TICK_INCREMENTS_MG = 50;
export const METABOLIC_HORIZONTAL_LINE_MG = 150;
export const METABOLIC_VERTICAL_LINE_MG = 50;

export const METABOLIC_GRAPH_X_MAX_MMOL = 15;
export const METABOLIC_GRAPH_Y_MAX_MMOL = 25;
export const Y_TICK_INCREMENTS_MMOL = 5;
export const METABOLIC_HORIZONTAL_LINE_MMOL = 10;
export const METABOLIC_VERTICAL_LINE_MMOL = 5;

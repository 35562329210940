import { last, map, pipe, sort, values } from 'ramda';
import { convertISOGMT, convertMillisGMT } from 'src/shared/utils/date';
import { LOGBOOK_STATUS_COLOR } from 'src/domains/diagnostics/constants/logbook.constants';
import { HI_VALUE, LO_VALUE } from 'src/domains/diagnostics/store/constants';

export const getNumberOfRows = pipe(
  values,
  map((value) => value.length),
  sort((a, b) => a - b),
  last,
);

export const getMaxMealTimeRows = (mealTimes) => {
  if (!Object.keys(mealTimes).length) {
    return 0;
  }
  const combinedMealTimes = {
    NIGHT: mealTimes.NIGHT,
    BREAKFAST: [...mealTimes.BEFORE_BREAKFAST, ...mealTimes.AFTER_BREAKFAST],
    LUNCH: [...mealTimes.BEFORE_LUNCH, ...mealTimes.AFTER_LUNCH],
    DINNER: [...mealTimes.BEFORE_DINNER, ...mealTimes.AFTER_DINNER],
    BEDTIME: mealTimes.BEDTIME,
  };
  return getNumberOfRows(combinedMealTimes);
};

export const getSelectedRowId = (match, logbookData) => {
  const urlDate = parseInt(match.params.selectedDate, 10);

  const index = logbookData.findIndex((datum) => {
    const { date } = datum;
    return convertISOGMT(date).day === convertMillisGMT(urlDate).day;
  });

  return index;
};

export const toMeasurementIndicatorColor = (measurement) => {
  const { aboveTargetRange, belowTargetRange, hypoSymptoms, rangeType } =
    measurement;
  if (aboveTargetRange || rangeType === HI_VALUE) {
    return LOGBOOK_STATUS_COLOR.BLUE;
  }
  if (belowTargetRange || hypoSymptoms || rangeType === LO_VALUE) {
    return LOGBOOK_STATUS_COLOR.RED;
  }
  return LOGBOOK_STATUS_COLOR.GREEN;
};

export const isCrossoverBlock = (startTimeMs, endTimeMs) =>
  endTimeMs < startTimeMs;

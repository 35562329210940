import React from 'react';
import { Route } from 'react-router-dom';

import { LoginBundle, LoginSuccessBundle, RedirectBundle } from './bundles';

import { generalLinks } from '../general/routes';
/* istanbul ignore next */
export const authenticationLinks = {
  login: '/auth/login',
  redirect: '/auth/redirect',
  loginProfessional: '/oidbc/login/professional',
  success: '/auth/success',
  resetPasswordPatient: '/reset-password-patient',
  root: '/',
};
/* istanbul ignore next */
export const AuthenticationRoutes = () => (
  <React.Fragment>
    <Route
      key="success"
      path={authenticationLinks.success}
      component={LoginSuccessBundle}
    />
    <Route
      key="redirect"
      path={authenticationLinks.redirect}
      component={RedirectBundle}
    />
    <Route
      key="login"
      path="/(|auth/login)"
      component={({ location }) => (
        <LoginBundle homeRoute={generalLinks.home} location={location} />
      )}
    />
  </React.Fragment>
);

import { DateTime } from 'luxon';
import { countlyEventTrigger } from 'src/app/navigation/countly';
import {
  BG_STATISTICS_DEVICES_KEYS,
  BG_STATISTICS_DEVICES_SEGMENT_KEYS,
  BG_STATISTICS_DEVICES_SEGMENT_VALUES,
} from 'src/domains/diagnostics/widgets/blood-glucose-general-stats/components/device-details/device.countly.constans';

export const getDataLastSync = (dateLastSync, is12hourTimeFormat) =>
  is12hourTimeFormat
    ? DateTime.fromISO(dateLastSync).toFormat('dd LLL yyyy ') +
      DateTime.fromISO(dateLastSync)
        .toFormat('hh:mm a', { locale: 'us' })
        .toUpperCase()
    : DateTime.fromISO(dateLastSync).toFormat('dd LLL yyyy HH:mm');

export const deviceTypeCountly = (name, path) =>
  path === 'all'
    ? countlyEventTrigger(
        BG_STATISTICS_DEVICES_KEYS.BG_STATISTICS_DEVICE_SELECTED,
        {
          [BG_STATISTICS_DEVICES_SEGMENT_KEYS.DEVICE_SELECTED]:
            BG_STATISTICS_DEVICES_SEGMENT_VALUES.ALL,
        },
      )
    : countlyEventTrigger(
        BG_STATISTICS_DEVICES_KEYS.BG_STATISTICS_DEVICE_SELECTED,
        {
          [BG_STATISTICS_DEVICES_SEGMENT_KEYS.DEVICE_SELECTED]:
            name || BG_STATISTICS_DEVICES_SEGMENT_VALUES.NO_NAME_DEVICE,
        },
      );

import { isEmpty, map, mapObjIndexed, mean, pipe, pluck, values } from 'ramda';

import { convertDateToWeeklyFloat } from '../../../../time';
import {
  convertISOGMT,
  subtractDays,
} from '../../../../../../shared/utils/date';
import {
  BOLUS_TYPE,
  INSULIN_TYPE,
} from 'src/domains/diagnostics/store/constants';
import { colors } from 'src/app/styles/colors';
import { addDays, dayToMs } from 'src/shared/utils/date';
import {
  getBolusType1Value,
  getBolusType2Value,
  getBolusType3Value,
} from 'src/domains/diagnostics/widgets/logbook-diary/logbook-diary.util';

import {
  barsWithInvalidLineHeight,
  getDimensionsMapper,
  sortInsulinBarsDescending,
} from '../../graph-shared/graph.util';
import { MINIMUM_MEASUREMENTS_TO_CALCULATE_STATISTICS } from '../../../blood-glucose-overview/store/blood-glucose-overview.constants';
import {
  bolusTypeToParseFunctionMap,
  parseStandardOrQuickBolus,
} from 'src/domains/diagnostics/scenes/graphs/bolus/bolus.util';

const dayIntervals = [
  { startTime: 0, endTime: 1, midTime: 0.5 },
  { startTime: 1, endTime: 2, midTime: 1.5 },
  { startTime: 2, endTime: 3, midTime: 2.5 },
  { startTime: 3, endTime: 4, midTime: 3.5 },
  { startTime: 4, endTime: 5, midTime: 4.5 },
  { startTime: 5, endTime: 6, midTime: 5.5 },
  { startTime: 6, endTime: 7, midTime: 6.5 },
];

const appendDateAsFloatToMeasurement = (measurement) => ({
  ...measurement,
  weeklyFloat: convertDateToWeeklyFloat(measurement.date),
});

const groupMeasurementsByInterval = (intervals) => (measurements) => {
  const filteredMeasurements = measurements.filter(
    (glucoseValue) => glucoseValue.value,
  );
  return filteredMeasurements
    ? filteredMeasurements.reduce(
        (measurementsGroupedByInterval, measurement) => {
          const intervalKey = intervals
            .find(
              (interval) =>
                interval.startTime <= measurement.weeklyFloat &&
                interval.endTime > measurement.weeklyFloat,
            )
            .midTime.toString();

          const entries = measurementsGroupedByInterval[intervalKey] || [];

          return {
            ...measurementsGroupedByInterval,
            [intervalKey]: [...entries, measurement],
          };
        },
        {},
      )
    : [];
};

const calculateMeanValue = pipe(pluck('value'), mean);

const calculateMeanAndFormMeanBgObjects = (measurements, interval) => {
  const filteredMeasurements = measurements.filter(
    (glucoseValue) => glucoseValue.value,
  );

  const meanValue = filteredMeasurements
    ? calculateMeanValue(filteredMeasurements)
    : calculateMeanValue([]);

  const notEnoughData =
    filteredMeasurements.length < MINIMUM_MEASUREMENTS_TO_CALCULATE_STATISTICS;

  return {
    data: { value: parseFloat(meanValue.toFixed(2)) },
    x: parseFloat(interval),
    y: meanValue,
    notEnoughData,
  };
};

const connectFirstAndLastBG = (bgPoints = []) => {
  if (isEmpty(bgPoints)) {
    return;
  }

  const bgPointsFiltered = bgPoints.filter((bg) => bg.data.value);

  if (!bgPointsFiltered.length) {
    return;
  }

  const sortedBgPoints = bgPointsFiltered.sort((a, b) => a.x - b.x);
  const first = sortedBgPoints[0];
  const last = sortedBgPoints[sortedBgPoints.length - 1];
  const startingPoint = { ...last, x: last.x - 7 };
  const endingPoint = { ...first, x: first.x + 7 };

  return [startingPoint, ...bgPointsFiltered, endingPoint];
};

export const createMeanBgPoints = pipe(
  map(appendDateAsFloatToMeasurement),
  groupMeasurementsByInterval(dayIntervals),
  mapObjIndexed(calculateMeanAndFormMeanBgObjects),
  values,
  connectFirstAndLastBG,
);

export const convertISOToGMT = (measurement) => ({
  ...measurement,
  date: convertISOGMT(measurement.date),
});

export const calculateInsulinDimensions =
  (startDate, graphYMax) => (acc, measurement) => {
    const { bolusRemark, bolusType } = measurement;
    const { STANDARD } = BOLUS_TYPE;
    const { ONE, TWO, THREE } = INSULIN_TYPE;
    const { blackGraphInsulin, blueGraphInsulin, redGraphInsulin } = colors;

    const start = subtractDays(startDate.weekday - 1)(startDate);
    const totalTime = dayToMs(7);
    const isGlucoseMeasurement = bolusType === undefined;
    const getBolusType = !isGlucoseMeasurement ? bolusType : STANDARD;
    const getDimensions = bolusTypeToParseFunctionMap[getBolusType];

    const insulinDimensions = [
      {
        color: redGraphInsulin,
        insulinType: ONE,
        bolusValue: getBolusType1Value(measurement),
        getInsulinDimensionsFn: getDimensions,
        bolusRemark,
      },
      {
        color: blueGraphInsulin,
        insulinType: TWO,
        bolusValue: getBolusType2Value(measurement),
        getInsulinDimensionsFn: parseStandardOrQuickBolus,
      },
      {
        color: blackGraphInsulin,
        insulinType: THREE,
        bolusValue: getBolusType3Value(measurement),
        getInsulinDimensionsFn: parseStandardOrQuickBolus,
      },
    ].map(getDimensionsMapper(totalTime, start, graphYMax, measurement));

    const dimensionsMeasurement = insulinDimensions
      .sort(sortInsulinBarsDescending)
      .filter(barsWithInvalidLineHeight);

    return values.length === 0 ? acc : acc.concat(dimensionsMeasurement);
  };

// Wraps the bolus extensions carried over to the next week
export const crossoverWeek = (acc, dimensions) => {
  const { rectWidth, x, date } = dimensions;

  if (!rectWidth || rectWidth + x <= 1) {
    return [...acc, dimensions];
  }

  const initialRectWidth = 1 - x;

  const initialRectangle = {
    ...dimensions,
    rectWidth: initialRectWidth,
    splitCrossoverWeek: true,
  };

  const crossoverRectangle = {
    ...dimensions,
    x: 0,
    rectWidth: rectWidth - initialRectWidth,
    crossoverDate: addDays(1)(date),
  };

  return [...acc, initialRectangle, crossoverRectangle];
};

// Breaks down bolus extension carried over to the next day
export const crossoverDay = (acc, dimensions) => {
  const {
    date,
    rectWidth: rectWidthOrig,
    x,
    splitCrossoverWeek: alreadySplit,
  } = dimensions;

  const day = 1 / 7;
  const canCrossover = rectWidthOrig !== undefined;
  const rectWidth = canCrossover ? rectWidthOrig : 0;
  const isCrossover = Math.floor(x / day) !== Math.floor((x + rectWidth) / day);

  if (!isCrossover || alreadySplit) {
    return [...acc, dimensions];
  }

  const currentDayEnd = Math.ceil(x / day) / 7;

  const initialRectangle = {
    ...dimensions,
    rectWidth: currentDayEnd - x,
  };

  const crossoverRectangle = {
    ...dimensions,
    x: currentDayEnd,
    rectWidth: rectWidth - initialRectangle.rectWidth,
    crossoverDate: addDays(1)(date),
  };

  return [...acc, initialRectangle, crossoverRectangle];
};

import * as React from 'react';
import {
  UserAnnouncementNotificationState,
  UserAnnouncements,
} from '../store/announcements.types';
import {
  HOME_ROUTE,
  USER_ANNOUNCEMENT_TYPE,
} from '../store/announcements.constants';
import { WhatsNewModal } from './components/whats-new-modal/whats-new-modal.component';
import { RenderIf } from 'src/shared/utils/render-if';
import {
  EVENTS_KEYS,
  SEGMENT_KEYS,
  SEGMENT_VALUES,
} from '../countly.constants';
import { countlyEventTrigger } from 'src/app/navigation/countly';

export interface WhatsNewComponentProps {
  whatsNewConfig: UserAnnouncements | null;
  lastAccess: string;
  userAnnouncementNotification: UserAnnouncementNotificationState;
  isWhatsNewViewed: boolean;
  setIsWhatsNewViewed: () => void;
  closeModalAction: () => void;
  markAnnouncementVisitedAction: (payload: { id: string }) => void;
}

export const WhatsNewComponent: React.FunctionComponent<
  WhatsNewComponentProps
> = ({
  whatsNewConfig,
  lastAccess,
  userAnnouncementNotification,
  isWhatsNewViewed,
  setIsWhatsNewViewed,
  closeModalAction,
  markAnnouncementVisitedAction,
}) => {
  const [showWhatsNewModal, setShowWhatsNewModal] = React.useState(false);

  React.useEffect(() => {
    /**
     * lastAccess : Check to identify if this is the first login of the user.
     * showWhatsNew : Checks if the user is on Home page and has not marked the announcement as visited.
     * isHelpCenter : Check if the user is coming from help center.
     */
    const isNotVisited = !whatsNewConfig?.isVisited;
    const isHomePage = window.location.pathname === HOME_ROUTE;
    const isHelpCenter =
      userAnnouncementNotification?.key ===
      USER_ANNOUNCEMENT_TYPE.WHATS_NEW_NOTIFICATION;
    const showWhatsNew = isNotVisited && isHomePage && !isWhatsNewViewed;

    const showModal = !!lastAccess && (showWhatsNew || isHelpCenter);
    setShowWhatsNewModal(showModal);
  }, [
    userAnnouncementNotification?.key,
    whatsNewConfig?.isVisited,
    window.location.pathname,
  ]);

  // Hide scroll bar of parent body on modal open
  React.useEffect(() => {
    document.body.style.overflow = showWhatsNewModal ? 'hidden' : 'auto';
  }, [showWhatsNewModal]);

  const hideWhatsNewModal = (isGotItClicked: boolean, isChecked: boolean) => {
    if (isGotItClicked && isChecked) {
      markAnnouncementVisitedAction({
        id: `${whatsNewConfig?.id}`,
      });
    }
    if (
      userAnnouncementNotification?.key !==
      USER_ANNOUNCEMENT_TYPE.WHATS_NEW_NOTIFICATION
    ) {
      countlyEventTrigger(EVENTS_KEYS.ANNOUNCEMENT_CLOSED, {
        [SEGMENT_KEYS.BUTTON]: isGotItClicked
          ? SEGMENT_VALUES.BUTTON_GOT_IT
          : SEGMENT_VALUES.BUTTON_CLOSE,
      });
    }
    setShowWhatsNewModal(false);
    setIsWhatsNewViewed();
    closeModalAction();
  };

  return (
    <RenderIf validate={showWhatsNewModal}>
      <WhatsNewModal
        showModal={showWhatsNewModal}
        whatsNewConfig={whatsNewConfig as UserAnnouncements}
        isFooterAvailable={
          userAnnouncementNotification?.key !==
          USER_ANNOUNCEMENT_TYPE.WHATS_NEW_NOTIFICATION
        }
        hideWhatsNewModal={hideWhatsNewModal}
      />
    </RenderIf>
  );
};

import { push } from 'react-router-redux';
import { signoutStart } from 'src/app/session/core/session/session.actions';
import { getCombinedRoutes } from 'src/app/navigation/store/navigation.selectors';

import { GET_CURRENT_USER } from 'src/app/store/user/user.constants';
import {
  createModal,
  removeModalByKey,
} from 'src/shared/design-system/modal/store/modal/modal.actions';
import { MODAL_TYPES } from 'src/shared/design-system/modal/store/modal/modal.constants';

import { LoginSystemErrorTypes } from './login-system-error.types';
import { START_LOGOUT } from './login-system-error.constant';

import { SessionActionType } from 'src/app/session/core/session/session.types';
import { selectIsChangedPassword } from 'src/domains/profile/scenes/change-password/store/change-password.selector';
import { changedPasswordStateReset } from 'src/domains/profile/scenes/change-password/store/change-password.action';

export const logoutEpic = () => (actions$, store$) =>
  actions$.ofType(START_LOGOUT).switchMap(() => {
    return [signoutStart()];
  });

/* istanbul ignore next*/
export const reloadAfterLogoutEpic = () => (actions$, store$) =>
  actions$.ofType(SessionActionType.SIGN_OUT_SUCCESS).switchMap(() => {
    window.location.reload();
    return [];
  });

export const oidcLoginErrorEpic = () => (actions$, store$) => {
  return actions$.ofType(GET_CURRENT_USER.ERROR).switchMap(() => {
    /* istanbul ignore next*/ if (selectIsChangedPassword(store$.getState())) {
      return [signoutStart(), changedPasswordStateReset()];
    } else {
      return [
        createModal({
          key: MODAL_TYPES.LOGIN_SYSTEM_ERROR,
        }),
      ];
    }
  });
};

/* istanbul ignore next*/
export const retryLoginEpic = () => (actions$, store$) =>
  actions$.ofType(LoginSystemErrorTypes.RETRY_LOGIN).switchMap(() => {
    const routes: any = getCombinedRoutes(store$.getState());
    return [
      removeModalByKey({
        key: MODAL_TYPES.LOGIN_SYSTEM_ERROR,
      }),
      push(routes.authentication.login),
      signoutStart(),
    ];
  });

import { createPayloadAction } from '../../../store/app.actions';
import { CalendarInfo } from '../../../../widgets/top-navigation-jello/components/time-tracker-export-modal/time-tracker-export-modal.types';
import { PayloadAction } from '../../../store/app.types';

import {
  CLEAR_PATIENT_EXPORT_DATA,
  EXPORT_PATIENT_SESSION_REQUEST,
  PATIENT_ACTIONS,
  SAVE_PATIENT_SESSION_REQUEST,
  START_PATIENT_SESSION_REQUEST,
} from './patient-session.constants';

export type SavePatientSessionStartActionProps = {
  patientId: string;
  shouldClearSession?: boolean;
};

export type ExportPatientsSessionStartActionProps = {
  t: (param: string) => string;
} & CalendarInfo;

export type ExportStartAction = PayloadAction<
  string,
  ExportPatientsSessionStartActionProps
>;

export const startPatientSessionAction = (patientId, sessionTime) => ({
  payload: {
    patientSessionId: patientId,
    sessionTime,
  },
  type: PATIENT_ACTIONS.START_PATIENT_SESSION,
});

export const resetPatientSessionAction = () => ({
  type: PATIENT_ACTIONS.RESET_PATIENT_SESSION,
});

export const resetSessionStatusAction = () => ({
  type: PATIENT_ACTIONS.CLEAR_SESSION_STATUS,
});

export const resetIsRunningAction = () => ({
  type: PATIENT_ACTIONS.RESET_IS_RUNNING,
});

export const clearPatientExportData = () => ({
  type: CLEAR_PATIENT_EXPORT_DATA,
});

export const savePatientSessionStartAction = (params) =>
  createPayloadAction(SAVE_PATIENT_SESSION_REQUEST.START, params);

export const savePatientSessionSuccessAction = (params) =>
  createPayloadAction(SAVE_PATIENT_SESSION_REQUEST.SUCCESS, params);

export const savePatientSessionErrorAction = (params) =>
  createPayloadAction(SAVE_PATIENT_SESSION_REQUEST.ERROR, params);

// session start

export const startPatientSessionStartAction = (params) =>
  createPayloadAction(START_PATIENT_SESSION_REQUEST.START, params);

export const startPatientSessionSuccessAction = (params) =>
  createPayloadAction(START_PATIENT_SESSION_REQUEST.SUCCESS, params);

export const startPatientSessionErrorAction = (params) =>
  createPayloadAction(START_PATIENT_SESSION_REQUEST.ERROR, params);

// export
export const exportPatientsSessionStartAction = (
  params: ExportPatientsSessionStartActionProps,
): ExportStartAction =>
  createPayloadAction(EXPORT_PATIENT_SESSION_REQUEST.START, params);

export const exportPatientsSessionSuccessAction = (params) =>
  createPayloadAction(EXPORT_PATIENT_SESSION_REQUEST.SUCCESS, params);

export const exportPatientsSessionErrorAction = (params) =>
  createPayloadAction(EXPORT_PATIENT_SESSION_REQUEST.ERROR, params);

import { addDomainNamespace } from 'src/domains/strip-management/domain-namespace';

import { createRequestActionTypes } from '../../../../../app/store/request/request.actions';

export const frequencyTranslationKeyBase = 'prescription.frequency.';

const DOMAIN_NAMESPACE = 'PATIENT_PRESCRIPTION';
const addNamespacing = (actionName) =>
  addDomainNamespace(actionName, DOMAIN_NAMESPACE);

export const GET_FREQUENCIES = addNamespacing('GET_FREQUENCIES');
export const GET_FREQUENCIES_REQUEST =
  createRequestActionTypes(GET_FREQUENCIES);

import {
  BLOOD_GLUCOSE_UNITS,
  MIN_BG,
} from '../../../../domains/diagnostics/store/constants';
import { calculateBloodGlucoseIndex } from '../../../../domains/diagnostics/utils/graph-statistics.util';
import { average } from '../../../../shared/utils/stat';
import { convertBGMeasurement } from '../../../../domains/diagnostics/utils/measurements';
import { CARBOHYDRATES_UNITS } from '../../../../widgets/user-units-selector/user-units-selector.constants';

export const roundToNDecimalPlaces = (number, n) => {
  const factor = Math.pow(10, n);
  return Math.round(number * factor) / factor;
};

export const calculateLowBloodGlucoseIndex = (measurements, bloodGlucoseUnit) =>
  average(
    measurements.map((measurement) => {
      const measurementValue = convertBGMeasurement(
        measurement.value,
        bloodGlucoseUnit,
        BLOOD_GLUCOSE_UNITS.MG_PER_DL,
      );
      return measurementValue <= MIN_BG
        ? calculateBloodGlucoseIndex(measurementValue).bgIndex
        : 0;
    }),
  );

export const calculateHighBloodGlucoseIndex = (
  measurements,
  bloodGlucoseUnit,
) =>
  average(
    measurements.map((measurement) => {
      const measurementValue = convertBGMeasurement(
        measurement.value,
        bloodGlucoseUnit,
        BLOOD_GLUCOSE_UNITS.MG_PER_DL,
      );
      return measurementValue > MIN_BG
        ? calculateBloodGlucoseIndex(measurementValue).bgIndex
        : 0;
    }),
  );

const GRAPHS_LABEL_G = 'graphs.logbook.g';
const GRAPHS_LABEL_EX = 'graphs.exchangedLabel';

const { GRAMS, G10, G12, G15, G20 } = CARBOHYDRATES_UNITS;

export const getCarbohydratesUnitsLabel = {
  [GRAMS]: GRAPHS_LABEL_G,
  [G10]: GRAPHS_LABEL_EX,
  [G12]: GRAPHS_LABEL_EX,
  [G15]: GRAPHS_LABEL_EX,
  [G20]: GRAPHS_LABEL_EX,
};

const ZERO_DECIMALS = 0;
const ONE_DECIMAL = 1;

export const carbDecimalsAccordingToCarbUnit = {
  [GRAMS]: ZERO_DECIMALS,
  [G10]: ONE_DECIMAL,
  [G12]: ONE_DECIMAL,
  [G15]: ONE_DECIMAL,
  [G20]: ONE_DECIMAL,
};

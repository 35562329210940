import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { State } from '../../../../../../../app/store/app.types';
import { GetObservationalStatusLoaderImplType } from '../../../../../services/patient/clinical-studies/observational-clinical-studies/get-observational-studies-status/get-observational-studies-status.types';
import {
  GetObservationalStatusActionType,
  GetObservationalStatusActions,
  GetObservationalStatusStartAction,
} from './get-observational-studies-status.types';
import { selectAccessToken } from '../../../../../../../app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from '../../../../../../../app/session/core/config/config.selectors';
import {
  getObservationalStudiesStatusError,
  getObservationalStudiesStatusSuccess,
} from './get-observational-studies-status.actions';

export const getObservationalStudiesStatusEpic: (
  getObservationalStatusService: GetObservationalStatusLoaderImplType,
) => Epic<GetObservationalStatusActions, State> =
  (getObservationalStatusService) => (action$, store$) =>
    action$
      .ofType(GetObservationalStatusActionType.GET_OBSERVATIONAL_STATUS_START)
      .flatMap(({ payload }: GetObservationalStatusStartAction) => {
        const accessToken = selectAccessToken(store$.getState());
        const apiKey = selectGigyaToken(store$.getState());

        return Observable.fromPromise(
          getObservationalStatusService(accessToken, apiKey, payload),
        )
          .switchMap((response) => {
            return [getObservationalStudiesStatusSuccess(response)];
          })
          .pipe(
            catchError((response) =>
              Observable.of(getObservationalStudiesStatusError(response)),
            ),
          );
      });

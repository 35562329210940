import { REQUEST } from './request.constants';
import { PayloadAction } from '../app.types';

type RequestActionTypes = {
  START: string;
  SUCCESS: string;
  ERROR: string;
  BASE: string;
};

export const createRequestActionTypes = (
  baseAction: string,
): RequestActionTypes => ({
  START: `${baseAction}_START`,
  SUCCESS: `${baseAction}_SUCCESS`,
  ERROR: `${baseAction}_ERROR`,
  BASE: baseAction,
});

export const createRequestActions = (
  requestActionTypes: RequestActionTypes,
) => ({
  start: (
    payload = {},
  ): PayloadAction<string, FixMe, { activity: string; base: string }> => ({
    type: requestActionTypes.START,
    payload,
    meta: { activity: REQUEST.START, base: requestActionTypes.BASE },
  }),
  success: (
    payload: FixMe,
  ): PayloadAction<string, FixMe, { activity: string; base: string }> => ({
    type: requestActionTypes.SUCCESS,
    payload,
    meta: { activity: REQUEST.SUCCESS, base: requestActionTypes.BASE },
  }),
  error: (
    error: FixMe,
  ): PayloadAction<string, FixMe, { activity: string; base: string }> => ({
    type: requestActionTypes.ERROR,
    payload: error,
    meta: {
      activity: REQUEST.ERROR,
      base: requestActionTypes.BASE,
    },
  }),
});

import React from 'react';
import { countlyEventTrigger } from 'src/app/navigation/countly';
import { BG_GRAPH_SEGMENT_VALUES } from 'src/domains/patient-dashboards/components/tabs-header/tabs-header.countly.constans';
import {
  BG_GRAPH_LEGEND_SEGMENT_VALUES,
  BG_GRAPH_LEGEND_STATISTIC_KEYS,
  BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS,
} from 'src/domains/diagnostics/components/graph-controls/graph-controls.countly.constans';

import { GraphLegend } from 'src/domains/diagnostics/components/graph-legend/graph-legend.component';
import { GraphStatistics } from 'src/domains/diagnostics/components/graph-statistics/graph-statistics.component';
import { MenuIcon } from 'src/shared/design-system/icons';
import { GRAPHS } from 'src/domains/diagnostics/scenes/graphs/graph.constants';

const keyTextTabs = {
  legend: 'legend',
  statistics: 'statistics',
};
export const getGraphDetailTabs = (
  graphType,
  graphStatistics,
  showChangeGraphToggle,
  graph,
) => {
  const tabs = [
    {
      label: <MenuIcon />,
      keyText: keyTextTabs.legend,
      content: <GraphLegend {...{ graphType, showChangeGraphToggle, graph }} />,
    },
  ];

  if (
    [
      GRAPHS.LOGBOOK,
      GRAPHS.DEVICE_SETTINGS,
      GRAPHS.LOGBOOK_STATS,
      GRAPHS.INSULIN_PUMP,
      GRAPHS.LOGBOOK_24HR,
    ].includes(graphType)
  ) {
    return tabs;
  }

  return [
    ...tabs,
    {
      label: '%',
      keyText: keyTextTabs.statistics,
      content: (
        <GraphStatistics
          graph={graph}
          graphType={graphType}
          graphDetails={graphStatistics}
        />
      ),
    },
  ];
};

export const graphTypeSelected = (graph) => {
  const graphTypeToSegmentValueMap = {
    [GRAPHS.TREND]: BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_TREND,
    [GRAPHS.STANDARD_DAY]: BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_STANDARD_DAY,
    [GRAPHS.STANDARD_WEEK]:
      BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_STANDARD_WEEK,
    [GRAPHS.LOGBOOK]: BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_LOGBOOK,
    [GRAPHS.LOGBOOK_DIARY]:
      BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_LOGBOOK_DIARY,
    [GRAPHS.LOGBOOK_STATS]:
      BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_LOGBOOK_STATS,
    [GRAPHS.LOGBOOK_24HR]: BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_LOGBOOK_24H,
    [GRAPHS.METABOLIC_RATE]:
      BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_METABOLIC_RATE,
    [GRAPHS.INSULIN]: BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_INSULIN,
    [GRAPHS.INSULIN_PUMP]: BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_INSULIN_PUMP,
    [GRAPHS.BLOOD_GLUCOSE_GENERAL_STATS]:
      BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_BLOOD_GLUCOSE_GENERAL_STATS,
    [GRAPHS.DEVICE_SETTINGS]:
      BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_DEVICE_SETTINGS,
  };
  return (
    graphTypeToSegmentValueMap[graph] ||
    BG_GRAPH_SEGMENT_VALUES.NO_NAME_GRAPH_SELECTED
  );
};

export const legendStatisticsTabOpenedCountly = (
  tab,
  showChangeGraphToggle,
  graph,
) => {
  switch (tab.keyText) {
    case keyTextTabs.legend:
      return countlyEventTrigger(
        BG_GRAPH_LEGEND_STATISTIC_KEYS.BG_GRAPH_LEGEND_OPENED,
        {
          [BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS.BG_GRAPH_TYPE]:
            graphTypeSelected(graph),
          [BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS.BG_LOCATION]:
            showChangeGraphToggle
              ? BG_GRAPH_LEGEND_SEGMENT_VALUES.GRAPH_PAGE
              : BG_GRAPH_LEGEND_SEGMENT_VALUES.BG_DASHBOARD,
        },
      );
    case keyTextTabs.statistics:
      return countlyEventTrigger(
        BG_GRAPH_LEGEND_STATISTIC_KEYS.BG_GRAPH_STATISTICS_OPENED,
        {
          [BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS.BG_GRAPH_TYPE]:
            graphTypeSelected(graph),
          [BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS.BG_LOCATION]:
            showChangeGraphToggle
              ? BG_GRAPH_LEGEND_SEGMENT_VALUES.GRAPH_PAGE
              : BG_GRAPH_LEGEND_SEGMENT_VALUES.BG_DASHBOARD,
        },
      );
    default:
      return;
  }
};

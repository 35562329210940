import { Action, PayloadAction } from '../../../../../../app/store/app.types';
import {
  CheckHCIDParams,
  CheckHCIDTransformedResponseType,
} from '../../../../services/check-hcid/check-hcid.types';

export enum CheckDuplicatedHealthcareIdActionType {
  CHECK_DUPLICATED_HEALTHCARE_ID_START = 'CHECK_DUPLICATED_HEALTHCARE_ID_START',
  CHECK_DUPLICATED_HEALTHCARE_ID_SUCCESS = 'CHECK_DUPLICATED_HEALTHCARE_ID_SUCCESS',
  CHECK_DUPLICATED_HEALTHCARE_ID_ERROR = 'CHECK_DUPLICATED_HEALTHCARE_ID_ERROR',
  RESET_DUPLICATED_HEALTHCARE_ID = 'RESET_DUPLICATED_HEALTHCARE_ID',
}

export type CheckDuplicatedHealthcareIdStartPayload = CheckHCIDParams;

export type CheckDuplicatedHealthcareIdSuccessPayload =
  CheckHCIDTransformedResponseType;

export type CheckDuplicatedHealthcareIdErrorPayload = { message: string };

export type CheckDuplicatedHealthcareIdStartAction = PayloadAction<
  CheckDuplicatedHealthcareIdActionType.CHECK_DUPLICATED_HEALTHCARE_ID_START,
  CheckDuplicatedHealthcareIdStartPayload
>;

export type CheckDuplicatedHealthcareIdSuccessAction = PayloadAction<
  CheckDuplicatedHealthcareIdActionType.CHECK_DUPLICATED_HEALTHCARE_ID_SUCCESS,
  CheckDuplicatedHealthcareIdSuccessPayload
>;

export type CheckDuplicatedHealthcareIdErrorAction = PayloadAction<
  CheckDuplicatedHealthcareIdActionType.CHECK_DUPLICATED_HEALTHCARE_ID_ERROR,
  CheckDuplicatedHealthcareIdErrorPayload
>;

export type ResetDuplicatedHealthcareIdAction =
  Action<CheckDuplicatedHealthcareIdActionType.RESET_DUPLICATED_HEALTHCARE_ID>;

export type CheckDuplicatedHealthcareIdActions =
  | CheckDuplicatedHealthcareIdStartAction
  | CheckDuplicatedHealthcareIdSuccessAction
  | CheckDuplicatedHealthcareIdErrorAction
  | ResetDuplicatedHealthcareIdAction;

export type CheckDuplicatedHealthcareIdState = {
  isCheckingDuplicatedHealthcareId: boolean;
  checkDuplicatedHealthcareIdWasSuccessful: boolean;
  checkDuplicatedHealthcareIdHasError: boolean;
  isHealthcareIdDuplicated: boolean | null;
};

import { connect } from 'react-redux';
import { QualtricsDataInjectorComponent } from './qualtrics-data-injector.component';
import {
  selectEC6UserEmail,
  selectEC6UserLanguage,
  selectHomeCountry,
} from '../../store/user/user.selectors';

const mapStateToProps = (state) => ({
  userEmail: selectEC6UserEmail(state),
  countryCode: selectHomeCountry(state),
  locale: selectEC6UserLanguage(state),
});

export const QualtricsDataInjectorContainer = connect(mapStateToProps)(
  QualtricsDataInjectorComponent,
);

import {
  createAction,
  createPayloadAction,
} from '../../../../../../../app/store/app.actions';
import {
  GetObservationalStatusParams,
  GetObservationalStatusSuccessPayload,
} from '../../../../../services/patient/clinical-studies/observational-clinical-studies/get-observational-studies-status/get-observational-studies-status.types';
import {
  ClearObservationalStatusAction,
  GetObservationalStatusActionType,
  GetObservationalStatusError,
  GetObservationalStatusErrorAction,
  GetObservationalStatusStartAction,
  GetObservationalStatusSuccessAction,
} from './get-observational-studies-status.types';

export const getObservationalStudiesStatusStart = (
  payload: GetObservationalStatusParams,
): GetObservationalStatusStartAction =>
  createPayloadAction(
    GetObservationalStatusActionType.GET_OBSERVATIONAL_STATUS_START,
    payload,
  );

export const getObservationalStudiesStatusSuccess = (
  payload: GetObservationalStatusSuccessPayload,
): GetObservationalStatusSuccessAction =>
  createPayloadAction(
    GetObservationalStatusActionType.GET_OBSERVATIONAL_STATUS_SUCCESS,
    payload,
  );

export const getObservationalStudiesStatusError = (
  payload: GetObservationalStatusError,
): GetObservationalStatusErrorAction =>
  createPayloadAction(
    GetObservationalStatusActionType.GET_OBSERVATIONAL_STATUS_ERROR,
    payload,
  );

export const clearGetObservationalStudiesStatus =
  (): ClearObservationalStatusAction =>
    createAction(GetObservationalStatusActionType.CLEAR_OBSERVATIONAL_STATUS);

import * as React from 'react';
import { ModuleAnchorConfig } from '../moduleAnchorConfig/ModuleAnchorConfig';
import { ModuleAnchorComponent } from '@roche/roche-common';
import { ModuleLoader } from '../visit-module/retrocompatibility/load-modules';

import { ANCHOR_NAME } from './reports.constants';
import { CGM } from 'src/app/app.constants';

interface StateProps {
  patientId: number;
  legacyPatientId: string;
  startDate: string;
  endDate: string;
  moduleName: string;
  jelloDs: boolean;
  rebranding: boolean;
  tabName: string;
  locale: string;
  timeFormat: string;
  unitMeasurement: string;
  cgmStartDate: string;
  cgmEndDate: string;
}

export class ReportsComponent extends React.Component<StateProps> {
  public componentDidMount(): void {
    ModuleLoader.emitLoadModuleEvent(this.props.moduleName);
  }

  public render() {
    const {
      patientId,
      legacyPatientId,
      startDate,
      endDate,
      moduleName,
      jelloDs,
      rebranding,
      tabName,
      locale,
      timeFormat,
      unitMeasurement,
      cgmStartDate,
      cgmEndDate,
    } = this.props;

    const isCGMTabName = tabName === CGM;
    const effectiveStartDate = isCGMTabName ? cgmStartDate : startDate;
    const effectiveEndDate = isCGMTabName ? cgmEndDate : endDate;

    return (
      <ModuleAnchorConfig>
        <ModuleAnchorComponent
          moduleName={moduleName}
          anchorName={ANCHOR_NAME}
          inputAttributes={{
            patientId,
            legacyPatientId,
            startDate: effectiveStartDate,
            endDate: effectiveEndDate,
            jelloDs,
            rebranding,
            tabName,
            locale,
            timeFormat,
            unitMeasurement,
          }}
          document={document}
        />
      </ModuleAnchorConfig>
    );
  }
}

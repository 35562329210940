export const EVENTS_KEYS = {
  ANNOUNCEMENT_DISPLAYED: 'Announcement displayed',
  ANNOUNCEMENT_CHECKED: 'Announcement checked',
  ANNOUNCEMENT_CLOSED: 'Announcement closed',
  ANNOUNCEMENT_CLICKED_SECTION: 'Announcement clicked section',
};

export const SEGMENT_KEYS = {
  TYPE: 'Type',
  BUTTON: 'Button',
  WHATS_NEW: `What’s New`,
};

export const SEGMENT_VALUES = {
  WHATS_NEW: `What’s new`,
  BUTTON_CLOSE: 'Close',
  BUTTON_GOT_IT: 'Got it',
};

// tslint:disable
import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';

import { CaretButton } from 'src/shared/design-system/caret-button/caret-button.component';
import { CARET_DIRECTION } from 'src/shared/design-system/caret-button/caret-button.constant';
import { LocalizedText } from 'src/shared/design-system/localized-text/localized-text.component';
import { Popover } from 'src/shared/design-system/popover/popover.component';
import {
  PopoverList,
  PopoverListHeader,
  PopoverListItemLink,
} from 'src/shared/design-system/popover/popover.style';
import { POPOVER_LINK_ACTIVE_CLASS } from 'src/shared/design-system/popover/popover.constants';
import { WithPermissions } from 'src/shared/utils/with-permissions/with-permissions.container';
import { PERMISSIONS } from 'src/domains/permissions/store/permissions.constants';
import { selectPatientId } from 'src/domains/patient/store/patient/patient.selector';
import { getCombinedRoutes } from 'src/app/navigation/store/navigation.selectors';

import {
  MenuContainer,
  MenuContent,
  MenuToggler,
} from './patient-dropdown.style';
import { testId } from '@roche/roche-common';

type PatientDropdownState = {
  showPopover: boolean;
  shift: boolean;
  pristine: boolean;
};

type PatientDropdownProps = {
  routes: FixMe;
  patientId: number;
};

export class PatientDropdownComponent extends React.Component<
  PatientDropdownProps,
  PatientDropdownState
> {
  state = {
    showPopover: false,
    shift: false,
    pristine: true,
  };

  private wrapperRef: HTMLElement;
  private ref = React.createRef<HTMLElement>();

  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    if (this.ref.current) {
      this.wrapperRef = this.ref.current;
    }

    document.addEventListener('mousedown', this.clickOutside);
    if (!this.state.pristine) return;
    const shift = this.wrapperRef && this.wrapperRef.offsetLeft <= 214;
    this.setState({
      shift,
      pristine: false,
    });
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickOutside);
  }

  public render() {
    const { routes, patientId } = this.props;
    return (
      <MenuContainer shift={this.state.shift} ref={this.ref}>
        <MenuToggler
          onClick={this.toggle}
          {...testId('patientDropdown', 'button')}
        >
          <CaretButton direction={CARET_DIRECTION.DOWN} />
        </MenuToggler>
        <Popover pushLeft={0} show={this.state.showPopover}>
          <MenuContent>
            <PopoverListHeader>
              <LocalizedText textKey="userDropdown.managePatientProfile" />
            </PopoverListHeader>
            <PopoverList>
              <WithPermissions hasPermissions={[PERMISSIONS.EDIT_PATIENT]}>
                <PopoverListItemLink
                  exact
                  activeClassName={POPOVER_LINK_ACTIVE_CLASS}
                  to={routes.patient.editPatient.replace(':id', patientId)}
                >
                  <LocalizedText textKey="dashboard.patientCardPopover.editPatient" />
                </PopoverListItemLink>
              </WithPermissions>
              <WithPermissions hasPermissions={[PERMISSIONS.EDIT_PATIENT_EMR]}>
                <PopoverListItemLink
                  exact
                  activeClassName={POPOVER_LINK_ACTIVE_CLASS}
                  to={routes.patient.editPatient.replace(':id', patientId)}
                >
                  <LocalizedText textKey="dashboard.patientCardPopover.editPatient" />
                </PopoverListItemLink>
              </WithPermissions>
              <WithPermissions
                hasPermissions={[PERMISSIONS.TIME_BLOCKS_MANAGEMENT]}
              >
                <PopoverListItemLink
                  exact
                  activeClassName={POPOVER_LINK_ACTIVE_CLASS}
                  to={routes.patient.editTimePeriods.replace(':id', patientId)}
                >
                  <LocalizedText textKey="dashboard.patientCardPopover.timePeriods" />
                </PopoverListItemLink>
              </WithPermissions>
              <WithPermissions
                hasPermissions={[PERMISSIONS.RELEVANT_CLINICAL_DATA]}
              >
                <PopoverListItemLink
                  exact
                  activeClassName={POPOVER_LINK_ACTIVE_CLASS}
                  to={routes.patient.graphSettings.replace(':id', patientId)}
                >
                  <LocalizedText textKey="dashboard.patientCardPopover.graphicSettings" />
                </PopoverListItemLink>
              </WithPermissions>
              <WithPermissions hasPermissions={[PERMISSIONS.PATIENT_DEVICES]}>
                <PopoverListItemLink
                  exact
                  activeClassName={POPOVER_LINK_ACTIVE_CLASS}
                  to={routes.patient.listDevices.replace(':id', patientId)}
                >
                  <LocalizedText textKey="dashboard.patientCardPopover.listDevices" />
                </PopoverListItemLink>
              </WithPermissions>
              <WithPermissions
                hasPermissions={[PERMISSIONS.PATIENT_MANAGEMENT_DELETE]}
              >
                <PopoverListItemLink
                  {...testId('patientDropdown', 'deactivatePatient')}
                  exact
                  activeClassName={POPOVER_LINK_ACTIVE_CLASS}
                  to={routes.patient.deactivatePatient.replace(
                    ':id',
                    patientId,
                  )}
                >
                  <LocalizedText textKey="dashboard.patientCardPopover.deactivatePatient" />
                </PopoverListItemLink>
              </WithPermissions>
            </PopoverList>
          </MenuContent>
        </Popover>
      </MenuContainer>
    );
  }

  private clickOutside = (e) => {
    const { showPopover } = this.state;
    if (showPopover && this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.setState({ showPopover: false });
    }
  };

  private toggle = (e) => {
    this.setState({ showPopover: !this.state.showPopover });
  };
}

export const PatientDropdown = compose<PatientDropdownProps, {}>(
  withRouter,
  connect(
    createStructuredSelector({
      routes: getCombinedRoutes,
      patientId: selectPatientId,
    }),
  ),
)(PatientDropdownComponent);

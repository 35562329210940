/* tslint:disable */
import { createAction, createPayloadAction } from 'src/app/store/app.actions';
import {
  FETCH_DEVICE_SETTINGS_REQUEST,
  fetchAllDevicesActionType,
} from './device-settings.constant';
import {
  FetchDeviceSettingsStartAction,
  FetchDeviceSettingsSuccessAction,
} from './device-settings.types';

export const fetchSettingsStart = (params): FetchDeviceSettingsStartAction =>
  createPayloadAction(FETCH_DEVICE_SETTINGS_REQUEST.START, params);

export const fetchSettingsSuccess = (
  params,
): FetchDeviceSettingsSuccessAction =>
  createPayloadAction(FETCH_DEVICE_SETTINGS_REQUEST.SUCCESS, params);

export const fetchSettingsError = () =>
  createAction(FETCH_DEVICE_SETTINGS_REQUEST.ERROR);

export const fetchAllDeviceSettingsDataBase = () =>
  createAction(fetchAllDevicesActionType.FETCH_BASE);

export const fetchAllDeviceSettingsDataStart = (
  params,
): FetchDeviceSettingsStartAction =>
  createPayloadAction(fetchAllDevicesActionType.FETCH_START, params);

export const fetchAllDeviceSettingsDataSuccess = () =>
  createAction(fetchAllDevicesActionType.FETCH_SUCCESS);

export const fetchAllDeviceSettingsDataError = () =>
  createAction(fetchAllDevicesActionType.FETCH_ERROR);

import {
  getJSON,
  createAuthHeader,
} from 'src/shared/utils/service/service.utils';

import {
  GetTimeBlocksLoaderImplType,
  GetTimeBlocksResponseType,
  GetTimeBlocksServiceImplType,
  GetTimeBlocksTransformedReponseType,
} from './time-blocks.types';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const GetTimeBlocksLoaderImpl: GetTimeBlocksLoaderImplType = (
  accessToken: string,
  gigyaToken: string,
  patientId: string,
) =>
  getJSON(endpointWithParams(ENDPOINTS.timeBlocks, { patientId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  });

export const GetTimeBlocksTransformImpl = (
  data: GetTimeBlocksResponseType,
): GetTimeBlocksTransformedReponseType => data.model.timeIntervals;

export const GetTimeBlocksServiceImpl: GetTimeBlocksServiceImplType =
  (load: GetTimeBlocksLoaderImplType, transform) =>
  (accessToken, gigyaToken, patientId) =>
    load(accessToken, gigyaToken, patientId).then(transform);

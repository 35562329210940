import * as React from 'react';

export const Consent = ({ rebranding }) => {
  const logoRoche = require('../../assets/images/roche-logo2.png');
  const logoAcc = require('../../assets/images/accu_chek_care_plattform.png');

  const altRoche = 'RocheDiabetes Care Platform';
  const altAcc = 'Accu-Chek Care';

  const logo = rebranding ? logoAcc : logoRoche;
  const altText = rebranding ? altAcc : altRoche;

  return (
    <div
      style={{
        position: 'relative',
        textAlign: 'right',
        margin: '40px 40px',
      }}
    >
      <img src={logo} alt={altText} />
    </div>
  );
};

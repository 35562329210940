import { path } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';

export const selectPatternsAndIndicators = path(['patternsAndIndicators']);
export const selectCheckedWhetherPatientHasConfiguration = createSelector(
  selectPatternsAndIndicators,
  path(['configuration', 'checkedWhetherPatientHasConfiguration']),
);
export const selectPatternsConfigurationFirstTime = createSelector(
  selectPatternsAndIndicators,
  path(['configuration', 'firstTimeUser']),
);
export const selectPatientHasPatternsConfiguration = createSelector(
  selectCheckedWhetherPatientHasConfiguration,
  selectPatternsConfigurationFirstTime,
  (checkPatientHasConfiguration, isFirstTime) =>
    checkPatientHasConfiguration ? !isFirstTime : false,
);
export const selectCalculationPeriod = createSelector(
  selectPatternsAndIndicators,
  path(['piProfileSetup', 'calculationPeriod']),
);
export const piConnector = createStructuredSelector({
  calculationPeriod: selectCalculationPeriod,
  hasPatientPatternsConfiguration: selectPatientHasPatternsConfiguration,
});

import styled, { css } from 'styled-components';
import fonts from '../../fonts';

interface TextStyledProps {
  sizeProp?: string;
  colorProp?: string;
  weight?: string;
}

const sizeStyle = css<Pick<TextStyledProps, 'sizeProp'>>`
  font-size: ${(props: Pick<TextStyledProps, 'sizeProp'>) => {
    if (props.sizeProp !== undefined && props.sizeProp in fonts.text.size) {
      return fonts.text.size[props.sizeProp];
    }
    return props.sizeProp;
  }};
`;

const colorStyle = css<TextStyledProps>`
  color: ${(props: any) => props.colorProp};
`;

const weightStyle = css<Pick<TextStyledProps, 'weight'>>`
  font-weight: ${(props: Pick<TextStyledProps, 'weight'>) => {
    if (props.weight !== undefined && props.weight in fonts.weight) {
      return fonts.weight[props.weight];
    }
    return props.weight;
  }};
`;

export const StyledText = styled.span<any>`
  font-family: ${fonts.type.mainFont};
  ${(props) => props.sizeProp && sizeStyle}
  ${(props) => props.colorProp && colorStyle}
  ${(props) => props.weight && weightStyle}
`;

import { countlyEventTrigger } from '../../../../../app/navigation/countly';
import {
  CREATE_PATIENT_SEGMENT_KEYS,
  CREATE_PATIENT_EVENTS_KEYS,
  BASIC,
  HOME_DELIVERY,
  NA,
  PICK_UP_CENTER,
  HOME_DELIVERY_CONFIG,
  CONNECT_DATA_SHARING_SEGMENT_KEYS,
  ATTEMPTS_LABELS,
  CONNECT_DATA_SHARING_EVENTS_KEYS,
  PATIENT_CREATION,
  EDIT_PATIENT_EVENTS_KEYS,
  EDIT_PATIENT_SEGMENT_KEYS,
} from '../create-edit-form-jello.constants';

const parseUndefinedToNA = (value: string) =>
  value === undefined || value === null ? NA : value;

const parseTrueFalse = (value: string) =>
  value === '' || value === undefined || value === null ? false : true;

const parseLanguages = (
  id: number,
  langs: { value: string; label: string }[],
) => {
  const value = langs.filter((lang) => lang.value === id.toString());
  return id !== 1 ? value[0].label : NA;
};

const parseProfileType = (type: string) =>
  type === HOME_DELIVERY_CONFIG ? HOME_DELIVERY : PICK_UP_CENTER;

export const triggerCreatePatientInitiatedCountlyEvent = (access) =>
  countlyEventTrigger(CREATE_PATIENT_EVENTS_KEYS.CREATE_PATIENT_INITIATED, {
    [CREATE_PATIENT_SEGMENT_KEYS.ACCESS]: access,
  });

export const triggerCreatePatientStep1CountlyEvent = (dataSharing) =>
  countlyEventTrigger(
    CREATE_PATIENT_EVENTS_KEYS.CREATE_PATIENT_STEP_1_COMPLETED,
    {
      [CREATE_PATIENT_SEGMENT_KEYS.INVITE]: dataSharing,
    },
  );

export const triggerCreatePatientCompletedCountlyEvent = ({
  patient,
  dataSharing,
  isStripChecked,
  profileType,
  hasOpenConnectivity,
  isRPMProgramEnrolled,
  languages,
}) =>
  countlyEventTrigger(CREATE_PATIENT_EVENTS_KEYS.CREATE_PATIENT_COMPLETED, {
    [CREATE_PATIENT_SEGMENT_KEYS.INVITE]: dataSharing,
    [CREATE_PATIENT_SEGMENT_KEYS.EMAIL]: parseTrueFalse(patient.user.email),
    [CREATE_PATIENT_SEGMENT_KEYS.HEALTHCARE_ID]: parseTrueFalse(
      patient.healthCareSystemId,
    ),
    [CREATE_PATIENT_SEGMENT_KEYS.DIABETES_TYPE]: parseUndefinedToNA(
      patient.diabetesType,
    ),
    [CREATE_PATIENT_SEGMENT_KEYS.THERAPY]: parseUndefinedToNA(
      patient.therapyType,
    ),
    [CREATE_PATIENT_SEGMENT_KEYS.DIAGNOSIS_DATE]: parseTrueFalse(
      patient.diagnosticDate,
    ),
    [CREATE_PATIENT_SEGMENT_KEYS.LANGUAGE]: parseLanguages(
      patient.user.languageId,
      languages,
    ),
    [CREATE_PATIENT_SEGMENT_KEYS.SEX_ASSIGN_TO_BIRTH]: parseUndefinedToNA(
      patient.user.gender,
    ),
    [CREATE_PATIENT_SEGMENT_KEYS.PREGNANCY]: patient.pregnant,
    [CREATE_PATIENT_SEGMENT_KEYS.PHONE]: parseTrueFalse(patient.user.phone),
    [CREATE_PATIENT_SEGMENT_KEYS.RPM_PROGRAM]: isRPMProgramEnrolled,
    [CREATE_PATIENT_SEGMENT_KEYS.OPEN_CONNECTIVITY]: hasOpenConnectivity,
    [CREATE_PATIENT_SEGMENT_KEYS.PROFILE_TYPE]: isStripChecked
      ? parseProfileType(profileType)
      : BASIC,
  });

export const triggerOpenedEditPatientCountlyEvent = (access: string) => {
  countlyEventTrigger(EDIT_PATIENT_EVENTS_KEYS.PATIENT_SETTINGS_OPENED, {
    [EDIT_PATIENT_SEGMENT_KEYS.ACCESS]: access,
  });
};

export const triggerEditPatientCompletedCountlyEvent = ({
  patient,
  isStripChecked,
  profileType,
  hasOpenConnectivity,
  isRPMProgramEnrolled,
  languages,
}) =>
  countlyEventTrigger(EDIT_PATIENT_EVENTS_KEYS.PATIENT_SETTINGS_SAVED, {
    [EDIT_PATIENT_SEGMENT_KEYS.EMAIL]: parseTrueFalse(patient.user.email),
    [EDIT_PATIENT_SEGMENT_KEYS.HEALTHCARE_ID]: parseTrueFalse(
      patient.healthCareSystemId,
    ),
    [EDIT_PATIENT_SEGMENT_KEYS.DIABETES_TYPE]: parseUndefinedToNA(
      patient.diabetesType,
    ),
    [EDIT_PATIENT_SEGMENT_KEYS.THERAPY]: parseUndefinedToNA(
      patient.therapyType,
    ),
    [EDIT_PATIENT_SEGMENT_KEYS.DIAGNOSIS_DATE]: parseTrueFalse(
      patient.diagnosticDate,
    ),
    [EDIT_PATIENT_SEGMENT_KEYS.LANGUAGE]: parseLanguages(
      patient.user.languageId,
      languages,
    ),
    [EDIT_PATIENT_SEGMENT_KEYS.SEX_ASSIGN_TO_BIRTH]: parseUndefinedToNA(
      patient.user.gender,
    ),
    [EDIT_PATIENT_SEGMENT_KEYS.PREGNANCY]: patient.pregnant,
    [EDIT_PATIENT_SEGMENT_KEYS.PHONE]: parseTrueFalse(patient.user.phone),
    [EDIT_PATIENT_SEGMENT_KEYS.RPM_PROGRAM]: isRPMProgramEnrolled,
    [EDIT_PATIENT_SEGMENT_KEYS.OPEN_CONNECTIVITY]: hasOpenConnectivity,
    [EDIT_PATIENT_SEGMENT_KEYS.PROFILE_TYPE]: isStripChecked
      ? parseProfileType(profileType)
      : BASIC,
  });

const getAttemptLabels = (attempts: number) => {
  if (attempts === ATTEMPTS_LABELS.ONE) return ATTEMPTS_LABELS.FIRST;
  else if (attempts === ATTEMPTS_LABELS.TWO) return ATTEMPTS_LABELS.SECOND;
  else return ATTEMPTS_LABELS.THIRD;
};

export const triggerValidateAttemptCountlyEvent = (
  key: string,
  form: string,
  attempt: number,
) =>
  countlyEventTrigger(key, {
    [CONNECT_DATA_SHARING_SEGMENT_KEYS.FORM]: form,
    [CONNECT_DATA_SHARING_SEGMENT_KEYS.ATTEMPTS]: getAttemptLabels(attempt),
  });

export const triggerGetFormOriginCountlyEvent = (key: string, form: string) =>
  countlyEventTrigger(key, {
    [CONNECT_DATA_SHARING_SEGMENT_KEYS.FORM]: form,
  });

export const triggerErrorDuplicatedConnectionCountlyEvent = () =>
  countlyEventTrigger(
    CONNECT_DATA_SHARING_EVENTS_KEYS.ERROR_DUPLICATED_CONNECTION,
    {},
  );

export const triggerErrorConflictDetectedCountlyEvent = () =>
  countlyEventTrigger(CONNECT_DATA_SHARING_EVENTS_KEYS.ERROR_CONFLICT, {});

export const triggerConnectionCompletedWithPairingCodeCountlyEvent = (
  pairingCode: string,
) => {
  if (pairingCode) {
    triggerGetFormOriginCountlyEvent(
      CONNECT_DATA_SHARING_EVENTS_KEYS.PATIENT_CONNECTION_COMPLETED,
      PATIENT_CREATION,
    );
  }
};

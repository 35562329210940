import { createSelector } from 'reselect';
import {
  selectBloodGlucoseUnit,
  selectFormattedGlobalDatesAverageTestsPerDay,
  selectGlucoseMeasurementsInDateSliderRange,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import { roundBGMeasurement } from 'src/domains/diagnostics/utils/measurements';
import { getFormattedStandardDeviation } from 'src/domains/diagnostics/utils/stat.util';
import { average } from '../../../../../shared/utils/stat';
import { map } from 'ramda';

const getMeasurementValues = (measurements) =>
  map((measurement) => measurement.value, measurements);
export const selectGraphDetailBloodGlucose = createSelector(
  selectBloodGlucoseUnit,
  selectGlucoseMeasurementsInDateSliderRange,
  selectFormattedGlobalDatesAverageTestsPerDay,
  (bloodGlucoseUnit, measurements, testsPerDay) => {
    const bloodGlucoseValues = getMeasurementValues(measurements);
    const bloodGlucoseStandardDeviation = getFormattedStandardDeviation(
      bloodGlucoseValues,
      roundBGMeasurement(bloodGlucoseUnit),
    );
    const bloodGlucoseMean = roundBGMeasurement(bloodGlucoseUnit)(
      average(bloodGlucoseValues),
    );
    return {
      bloodGlucoseMean,
      bloodGlucoseStandardDeviation,
      bloodGlucoseUnit,
      testsPerDay,
    };
  },
);

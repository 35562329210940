import { PayloadAction } from 'src/app/store/app.types';
import { DeactivatePatientMotives } from '../deactivate-patient.types';

export enum DeactivatePatientActionType {
  DEACTIVATE_PATIENT_START = 'DEACTIVATE_PATIENT_START',
}

export type DeactivatePatientStartAction = PayloadAction<
  DeactivatePatientActionType.DEACTIVATE_PATIENT_START,
  DeactivatePatientParamsPayloadType
>;

export type DeactivatePatientParamsPayloadType = {
  patientId: string;
  comment: string;
  motive: DeactivatePatientMotives;
};

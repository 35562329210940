import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'react-router-redux';
import {
  selectPatientFhirId,
  selectPatientId,
} from '../../domains/patient/store/patient/patient.selector';
import { AuthenticationRoutes } from '../../domains/authentication/routes';
import { DashboardRoutes } from '../../domains/patient-dashboards/routes';
import { DeviceAssignmentRoutes } from '../../domains/device-assignment/routes';
import { DiagnosticsRoutes } from '../../domains/diagnostics/routes';
import { GeneralRoutes } from '../../domains/general/routes';
import { IndicatorsRoutes } from '../../domains/indicators/routes';
import { PatientRoutes } from '../../domains/patient/routes';
import { NewDeviceLinkRoutes } from '../modules/wdtc-module/routes';
import { TherapyRoutes } from '../modules/therapy/routes';
import { ProfessionalRoutes } from '../../domains/professional/routes';
import { ProfileRoutes } from '../../domains/profile/routes';
import { StripManagementRoutes } from '../../domains/strip-management/routes';
import { RpmRoutes } from '../../domains/rpm/routes';
import { mapDispatchers } from '../../shared/utils/map-dispatchers';
import {
  resetPatientSessionAction,
  savePatientSessionStartAction,
  SavePatientSessionStartActionProps,
  startPatientSessionAction,
  startPatientSessionStartAction,
} from './store/patient-session/patient-session.actions';
import { createStructuredSelector } from 'reselect';
import {
  selectIsPatientSessionRunning,
  selectPatientSessionId,
} from './store/patient-session/patient-session.selectors';
import {
  selectIsRPMEnrolled,
  selectIsTimeTrackerEnrolled,
  selectPermissions,
} from '../../domains/permissions/store/permissions.selectors';
import { handlePatientSession, RouteHistory } from './patient-session.utils';
import { NavigatorWrapper } from './navigation-wrapper';
import { getCombinedRoutes } from './store/navigation.selectors';
import { selectEC6TimeZone } from '../store/user/user.selectors';
import { selectUID } from '../session/core/config/config.selectors';

import { LocalizedText } from '../../shared/design-system/localized-text/localized-text.component';
import {
  InternetExplorerNoSupportContainer,
  InternetExplorerNoSupportTitle,
} from './navigation.style';

import { ModuleLoaderComponent } from '../modules/module-loader';

export type NavigationComponentProps = {
  routeHistory: RouteHistory;
  resetPatientSession: () => any;
  startPatientSession: (patientId: number, sessionTime: number) => any;
  savePatientSessionStart: (props: SavePatientSessionStartActionProps) => any;
  startPatientSessionRequest: (props: any) => any;
  resetSessionStatus: () => any;
  t: (props) => any;
  patientSessionId: number | null;
  isRPMEnrolled: boolean;
  isTimeTrackerEnrolled: boolean;
  patientId: number;
  patientFhirId: string;
  hcpTimezone: string;
  isPatientSessionRunning: boolean;
  location: any;
  permissions: any;
  routes: any;
  goTo: (path: string) => void;
  gigyaUID: string;
  isBrowserInternetExplorer: boolean;
};

export class NavigationComponent extends React.Component<
  NavigationComponentProps,
  {}
> {
  public componentDidUpdate() {
    const currentRoute = this.props.location.pathname;
    handlePatientSession({
      ...this.props,
      routeHistory: { currentRoute },
    });
  }

  public render() {
    const { gigyaUID, permissions, goTo, isBrowserInternetExplorer } =
      this.props;

    if (isBrowserInternetExplorer) {
      return (
        <InternetExplorerNoSupportContainer>
          <InternetExplorerNoSupportTitle>
            <LocalizedText textKey={`internet-explorer-nosupport.title`} />
          </InternetExplorerNoSupportTitle>
          <LocalizedText textKey={`internet-explorer-nosupport.description`} />
        </InternetExplorerNoSupportContainer>
      );
    }

    return (
      <NavigatorWrapper gigyaUID={gigyaUID} permissions={permissions}>
        <Route
          key="trailing-slash"
          path="______/:url*/"
          exact
          strict
          render={(props) => (
            <Redirect to={`${props.location.pathname.slice(0, -1)}`} />
          )}
        />
        <ModuleLoaderComponent />
        <AuthenticationRoutes key="authentication-routes" />
        <GeneralRoutes key="general-routes" />
        <DashboardRoutes key="dashboard-routes" />
        <PatientRoutes key="patient-routes" />
        <StripManagementRoutes key="strip-management-routes" />
        <DiagnosticsRoutes
          key="diagnostics-routes"
          patientId={this.props.patientId}
          goTo={goTo}
        />
        <IndicatorsRoutes key="indicators-routes" />
        <ProfileRoutes key="profile" path="/profile" />
        <ProfessionalRoutes key="professional-routes" />
        <DeviceAssignmentRoutes key="device-assignment" />
        <RpmRoutes key="rpm-routes" />
        <TherapyRoutes key="therapy-routes" />
        <NewDeviceLinkRoutes key="new-device-link-routes" />
      </NavigatorWrapper>
    );
  }
}

const structuredSelector = createStructuredSelector({
  patientId: selectPatientId,
  patientFhirId: selectPatientFhirId,
  patientSessionId: selectPatientSessionId,
  gigyaUID: selectUID,
  isRPMEnrolled: selectIsRPMEnrolled,
  isTimeTrackerEnrolled: selectIsTimeTrackerEnrolled,
  isPatientSessionRunning: selectIsPatientSessionRunning,
  hcpTimezone: selectEC6TimeZone,
  permissions: selectPermissions,
  routes: getCombinedRoutes,
});

export const Navigation = compose(
  connect(
    structuredSelector,
    mapDispatchers({
      resetPatientSession: resetPatientSessionAction,
      startPatientSession: startPatientSessionAction,
      savePatientSessionStart: savePatientSessionStartAction,
      startPatientSessionRequest: startPatientSessionStartAction,
      goTo: (path) => push(path),
    }),
  ),
)(NavigationComponent);

import * as React from 'react';
import {
  JELLO_BUTTON_SIZES,
  JELLO_BUTTON_VARIANTS,
  JELLO_ICON_NAMES,
  JELLO_TOOLTIP_POSITIONS,
} from 'src/app/app.jello.constants';
import {
  UPLOAD_DATA_ICON_NAME,
  UPLOAD_DATA_TOOLTIP_ID,
} from '../../top-navigation-jello.constants';
import { t } from 'i18next';
import { NavLink } from 'react-router-dom';
import { JelloIconButton } from 'src/shared/design-system/jello-icon-button/jello-icon-button.component';
import { JelloTooltip } from 'src/shared/design-system/jello-tooltip/jello-tooltip.component';
import { UploadDataButtonComponentProps } from '../../top-navigation-jello.types';
import { MODAL_TYPES } from 'src/shared/design-system/modal/store/modal/modal.constants';

export const UploadDataButtonComponent: React.FunctionComponent<
  UploadDataButtonComponentProps
> = (props) => {
  const { route, createModal, hasDeviceLinkPermission } = props;

  const renderButton = () => (
    <JelloIconButton
      size={JELLO_BUTTON_SIZES.L}
      iconName={JELLO_ICON_NAMES[UPLOAD_DATA_ICON_NAME]}
      variant={JELLO_BUTTON_VARIANTS.TERTIARY}
      onClick={
        hasDeviceLinkPermission
          ? undefined
          : () => createModal({ key: MODAL_TYPES.DTC })
      }
    />
  );

  const WrappedButton = hasDeviceLinkPermission ? (
    <NavLink to={route}>{renderButton()}</NavLink>
  ) : (
    renderButton()
  );

  return (
    <JelloTooltip
      id={UPLOAD_DATA_TOOLTIP_ID}
      label={t('menuPopover.uploadData')}
      position={JELLO_TOOLTIP_POSITIONS.BOTTOM}
    >
      {WrappedButton}
    </JelloTooltip>
  );
};

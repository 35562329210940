import {
  createAuthHeader,
  endpointTransformer,
  getJSON,
} from 'src/shared/utils/service/service.utils';

import { CheckEmailParams, CheckEmailResponse } from './check-email.types';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const CheckEmailLoaderImpl = (
  { email, hasUserPlatformPermission }: CheckEmailParams,
  accessToken: string,
  gigyaToken: string,
) =>
  getJSON(
    endpointTransformer(endpointWithParams(ENDPOINTS.checkEmail, { email }), [
      '/ciam/api/v1/access/patient/checkEmail',
      '/ciam/api/v2/access/patients/check-email',
      null,
      null,
      hasUserPlatformPermission,
    ]),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );

export const CheckEmailTransformImpl = ({
  model,
}: CheckEmailResponse): boolean => model;

export const CheckEmailServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);

import { map } from 'ramda';

import { translate } from '../../../../shared/utils/i18n';

export const transformTherapies = (TherapyData) => {
  const mapper = ({ label, name, ...data }) => ({
    ...data,
    name: label ? translate(`prescription.therapy.${label}`) : name,
  });
  return map(mapper, TherapyData);
};

import {
  FetchStatus,
  ListDevicesActions,
  ListDevicesActionType,
  ListDevicesState,
} from './list-devices.types';

export const initialListDevicesState: ListDevicesState = {
  isFetchingDevices: false,
  isFetchingDevicesError: false,
  devices: [],
  status: FetchStatus.NONE,
};

export const listDevicesReducer = (
  state = initialListDevicesState,
  action: ListDevicesActions,
): ListDevicesState => {
  switch (action.type) {
    case ListDevicesActionType.FETCH_DEVICES_START:
      return {
        ...initialListDevicesState,
        isFetchingDevices: true,
        status: FetchStatus.LOADING,
      };
    case ListDevicesActionType.FETCH_DEVICES_SUCCESS:
      return {
        ...initialListDevicesState,
        devices: action.payload,
        status: FetchStatus.SUCCESS,
      };
    case ListDevicesActionType.FETCH_DEVICES_ERROR:
      return {
        ...initialListDevicesState,
        isFetchingDevicesError: true,
        status: FetchStatus.FAILURE,
      };
    default:
      return state;
  }
};

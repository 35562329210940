import {
  createAuthHeader,
  postJSON,
} from 'src/shared/utils/service/service.utils';

import {
  transformJSONToPrescription,
  transformPrescriptionToJSONWithSanitization,
} from '../prescription.util';

import {
  ENDPOINTS,
  endpointWithParams,
} from 'src/app/navigation/services/service.constants';

export const SavePrescriptionLoaderImpl = (
  { patientId, prescription },
  accessToken,
) =>
  postJSON(endpointWithParams(ENDPOINTS.savePrescription, { patientId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
    body: transformPrescriptionToJSONWithSanitization(prescription),
  });

export const SavePrescriptionTransformImpl = transformJSONToPrescription;

export const SavePrescriptionServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);

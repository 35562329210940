import * as React from 'react';
import { testId } from '@roche/roche-common';
import {
  setINSSuccessElements,
  setPrimaryBtnText,
} from './ins-request.modal.utils';
import { RenderIf } from '../../../../shared/utils/render-if';
import { LoadingRing } from '../../../../shared/design-system/loading-ring/loading-ring.component';
import { ModalContentContainer } from './ins-request.modal.styles';

import {
  INS_MODAL_ID,
  insModalBtns,
  insModalHeader,
  LOADER_SIZE,
  ONE_MATCH_FOUND_CODE,
  SUBTITLE_MARGIN_TOP,
  TITLE_MARGIN_TOP,
} from './ins-request.modal.constants';
import { INSIdentityTransformedResponseType } from '../../services/INS-identity/get-INS-identity/get-INS-identity.types';
import { TFunction } from 'i18next';

type INSModalPropsType = {
  insIdentity: INSIdentityTransformedResponseType;
  isFetchingINS: boolean;
  successINS: boolean;
  hasErrorINS: boolean;
  data: DataPropType;
  t: TFunction;
};

type DataPropType = {
  title: string;
  subtitle: string;
  color: string;
  icon: string;
  primaryBtnTxt: string;
};

export const INSModalComponent: React.FunctionComponent<INSModalPropsType> = ({
  insIdentity,
  isFetchingINS,
  successINS,
  hasErrorINS,
  data,
  t,
}) => {
  const INSSuccessObject: any =
    successINS && setINSSuccessElements(insIdentity.code);
  const oneMatchFound = successINS && insIdentity.code === ONE_MATCH_FOUND_CODE;
  const showSecondButton = !isFetchingINS && oneMatchFound && !hasErrorINS;
  const { title, subtitle, color, icon, primaryBtnTxt } = data;
  return (
    <jello-dialog
      id={INS_MODAL_ID}
      header-title={t(insModalHeader)}
      visible=""
      primary-button-text={
        primaryBtnTxt ? t(primaryBtnTxt) : t(setPrimaryBtnText(oneMatchFound))
      }
      secondary-button-text={
        showSecondButton ? t(`${insModalBtns}.cancel`) : null
      }
      {...testId('jello-ins-dialog', 'modal')}
    >
      <ModalContentContainer>
        <RenderIf validate={isFetchingINS}>
          <LoadingRing size={LOADER_SIZE} />
        </RenderIf>
        <RenderIf validate={!isFetchingINS}>
          <jello-icon
            size="M"
            icon-name={icon ? icon : INSSuccessObject.icon}
            style={{ color: color ? color : INSSuccessObject.color }}
          />
        </RenderIf>
        <jello-heading
          size="L"
          weight="semibold"
          style={{ marginTop: TITLE_MARGIN_TOP }}
        >
          {title ? t(title) : t(INSSuccessObject.title)}
        </jello-heading>
        <jello-text
          size="M"
          weight="regular"
          style={{ marginTop: SUBTITLE_MARGIN_TOP, textAlign: 'center' }}
        >
          {subtitle ? t(subtitle) : t(INSSuccessObject.subtitle)}
        </jello-text>
      </ModalContentContainer>
    </jello-dialog>
  );
};

import React, { Fragment, useState } from 'react';
import styles from './styles.module.css';
import { MediaType } from '../store/announcements.types';
import {
  CONTAINER,
  FALLBACK_TEXT,
  GIF,
  IMAGE,
  MP4,
  VIDEO,
  WHAT_NEW_MEDIA,
} from '../whats-new/whats-new.constants';
import { testId } from '@roche/roche-common';
import {
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
} from 'src/app/app.jello.constants';
import { t } from 'i18next';
import { RenderIf, RenderIfElse } from 'src/shared/utils/render-if';

interface MediaContentProps {
  mediaType: MediaType;
  url: string;
  index: number;
  alt: string;
}

export const AnnouncementMediaComponent: React.FC<MediaContentProps> = ({
  mediaType,
  url,
  index,
  alt,
}) => {
  const [isErrorMediaLoading, setErrorMediaLoading] = useState(false);

  const fallbackContent = () => (
    <div
      id={`${WHAT_NEW_MEDIA}_${FALLBACK_TEXT}`}
      {...testId(WHAT_NEW_MEDIA, FALLBACK_TEXT)}
      className={styles.fallbackBlock}
    >
      <img
        src="e43bc1c0-05e0-4045-9cfc-1bca6486ba4b.jpg"
        alt=""
        className={styles.fallbackImage}
      />
      <jello-text size={JELLO_TEXT_SIZES.S} weight={JELLO_TEXT_WEIGHTS.NORMAL}>
        {t(alt)}
      </jello-text>
    </div>
  );

  const handleMediaError = () => {
    setErrorMediaLoading(true);
  };

  const renderMedia = () => (
    <Fragment>
      <RenderIfElse validate={isErrorMediaLoading}>
        <Fragment>{fallbackContent()}</Fragment>
        <Fragment>
          {renderImageOrGif()}
          {renderVideo()}
        </Fragment>
      </RenderIfElse>
    </Fragment>
  );

  const renderImageOrGif = () => {
    return (
      <RenderIf validate={mediaType === IMAGE || mediaType === GIF}>
        <img
          id={`${WHAT_NEW_MEDIA}_${FALLBACK_TEXT}-${index}`}
          {...testId(WHAT_NEW_MEDIA, IMAGE)}
          src={url}
          alt={`${t(alt)}`}
          className={styles.mediaBlock}
          onError={handleMediaError}
        />
      </RenderIf>
    );
  };

  const renderVideo = () => {
    return (
      <RenderIf validate={mediaType === VIDEO}>
        <video
          id={`${WHAT_NEW_MEDIA}_${FALLBACK_TEXT}-${index}`}
          {...testId(WHAT_NEW_MEDIA, VIDEO)}
          controls
          className={styles.mediaBlock}
          onError={handleMediaError}
        >
          <source src={url} type={`${VIDEO}/${MP4}`} />
        </video>
      </RenderIf>
    );
  };

  return (
    <div
      {...testId(WHAT_NEW_MEDIA, CONTAINER)}
      className={styles.mediaContainer}
    >
      {renderMedia()}
    </div>
  );
};

import styled, { createGlobalStyle } from 'styled-components';

import { borderRadius } from 'src/app/styles/border-radius';
import { colors } from 'src/app/styles/colors';
import { spacing } from 'src/app/styles/spacing';
import { P } from 'src/shared/design-system/fonts/body/body.style';
import { Title } from 'src/shared/design-system/fonts/title/title.style';
import { weight } from 'src/shared/design-system/fonts/weights/weight.style';
import { HrReset } from 'src/app/styles/resets';
import { combineRems, convertPxToRem } from 'src/shared/utils/rem-calc';

export const ContainerDiv = styled.div`
  max-width: 45rem;
  padding: ${combineRems(spacing.three, spacing.four)}
    ${combineRems(spacing.three, spacing.four)} ${spacing.four};
  display: table;
  align-items: center;
  padding-bottom: 0;
`;
ContainerDiv.displayName = 'ContainerDiv';

export const DTCHeadline = styled(Title)`
  color: ${colors.charcoal};
  font-weight: ${weight.semiBold};
  margin: 0 auto ${spacing.three};
`;
DTCHeadline.displayName = 'DTCHeadline';

export const DTCSubheadline = styled(P)`
  color: ${colors.grayDark};
  margin: 0 auto ${spacing.three};
`;
DTCSubheadline.displayName = 'DTCSubheadline';

export const DTCSubheadlineBottom = styled(DTCSubheadline)`
  margin: 0;
  margin-top: ${spacing.three};
`;
DTCSubheadlineBottom.displayName = 'DTCSubheadlineBottom';

export const DTCCaptionline = styled(P)`
  color: ${colors.grayDark};
  margin: 0 auto ${spacing.three};
  font-size: 12px;
`;
DTCCaptionline.displayName = 'DTCCaptionline';

export const DTCHr = styled(HrReset)`
  border-bottom: 1px solid ${colors.grayLight};
  margin: 0 auto ${convertPxToRem(25)};
`;
DTCHr.displayName = 'DTCHr';

export const DTCIconPanel = styled.div`
  min-width: ${convertPxToRem(470)};
  background-color: ${colors.blueMarineAlpha5};
  border: 1px solid ${colors.quartzBlue};
  border-radius: ${borderRadius.three};
  padding: ${spacing.three} ${spacing.four};
`;
DTCIconPanel.displayName = 'DTCIconPanel';

export const DTCHelpAnchor = styled.span`
  text-transform: lowercase;
`;
DTCHelpAnchor.displayName = 'DTCHelpAnchor';

export const HelpAnchorContainer = styled.div`
  margin-top: ${convertPxToRem(25)};
`;
HelpAnchorContainer.displayName = 'HelpAnchorContainer';

// tslint:disable:no-unused-expression
export const GlobalDtcStyle = createGlobalStyle`
  .modal__close {
    display: none;
  }
  .modal__content {
    max-width: 34rem;
  }
`; // tslint:enable:no-unused-expression

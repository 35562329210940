import * as React from 'react';
import { Reducer } from 'redux';
import { Epic } from 'redux-observable';

import { store } from '../app';
import { injectEpic } from '../epic';
import { injectReducer } from '../reducer';

type ReducerEntry<ReducerState, ReducerKey = any /*extends keyof State*/> = {
  readonly key: ReducerKey;
  readonly reducer: Reducer<ReducerState>;
};

type BundleData<Component, ReducerKey = any /*extends keyof State*/> = {
  readonly component: Component;
  readonly reducerEntry?: ReducerEntry</*State[ReducerKey]*/ any, ReducerKey>;
  readonly rootEpic?: Epic<any, any>;
};

type BundleProps<Component, ReducerKey = any /*extends keyof State*/> = {
  readonly bundleWillLoad: () => Promise<BundleData<Component, ReducerKey>>;
  readonly bundleDidLoad: (comp: Component) => React.ReactNode;
};

type BundleState<Component> = {
  readonly component?: Component;
  readonly isMounted?: boolean;
};

export class ModuleBundleComponent<
  T,
  ReducerKey = any /*extends keyof State*/,
> extends React.Component<BundleProps<T, ReducerKey>, BundleState<T>> {
  private _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { isMounted: false };
  }

  public componentDidMount() {
    this._isMounted = true;

    this.props
      .bundleWillLoad()
      .then(({ component, reducerEntry, rootEpic }) => {
        if (reducerEntry) {
          injectReducer(store, reducerEntry);
        }

        if (rootEpic) {
          injectEpic(rootEpic);
        }

        if (this._isMounted) {
          this.setState({ component });
        }
      });
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public render() {
    return this.state.component
      ? this.props.bundleDidLoad(this.state.component)
      : null;
  }
}

import {
  JELLO_CHIP_COLOR,
  JELLO_ICON_NAMES,
} from 'src/app/app.jello.constants';
import { ProfileTypes } from 'src/domains/patient/store/department/department.types';

export const SIDEBAR_BGM_TITLE = 'titleOfContent.bgmTitle';
export const SIDEBAR_STRIP_TITLE = 'sidebarmenu.stripManagement';
export const SIDEBAR_PATIENT_DEVICES = 'sidebarmenu.patientDevices';
export const EDIT_PATIENT = 'sidebarmenu.editPatient';
export const PATTERNS_TITLE = 'titleOfContent.patternsTitle';

export const getTitles = (routes) => [
  {
    id: 'bgm-heading',
    title: SIDEBAR_BGM_TITLE,
    path: routes.dashboard.bgId,
  },
  {
    id: 'strip-admin',
    title: SIDEBAR_STRIP_TITLE,
    path: routes.adminStrip.stripInfoByPatient,
  },
  {
    id: 'strip-information',
    title: SIDEBAR_STRIP_TITLE,
    path: routes.stripManagement.stripInfoByPatient,
  },
  {
    id: 'strip-prescription',
    title: SIDEBAR_STRIP_TITLE,
    path: routes.stripManagement.prescriptionInfoByPatient,
  },
  {
    id: 'strip-prescription',
    title: SIDEBAR_STRIP_TITLE,
    path: routes.stripManagement.customClinicGuides,
  },
  {
    id: 'strip-store',
    title: SIDEBAR_STRIP_TITLE,
    path: routes.stripManagement.alerts,
  },
  {
    id: 'strip-store',
    title: SIDEBAR_STRIP_TITLE,
    path: routes.patient.delivery,
  },
  {
    id: 'device-settings',
    title: SIDEBAR_PATIENT_DEVICES,
    path: routes.patient.deviceSettings,
  },
  {
    id: 'edit-patient',
    title: EDIT_PATIENT,
    path: routes.patient.editPatient,
  },
  {
    id: 'patterns-heading',
    title: PATTERNS_TITLE,
    path: routes.indicators.main,
  },
];

export const PATIENT_DASBOARD_TITLE_CHIP = {
  chipColor: JELLO_CHIP_COLOR.ACCENT_06,
  [ProfileTypes.pickup]: {
    text: 'hcpDashboard.stripMng.pickupCenter',
    icon: JELLO_ICON_NAMES.BUILDING,
  },
  [ProfileTypes.homeDelivery]: {
    text: 'createPatientWithPlatform.accountConfig.options.stripMng.homeDelivery',
    icon: JELLO_ICON_NAMES.HOME,
  },
};

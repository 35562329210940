import * as React from 'react';
import { XIcon } from 'src/shared/design-system/icons';
import { Block } from 'src/shared/design-system/block/block.component';
import { Button } from 'src/shared/design-system/button/button.component';
import { LocalizedText } from 'src/shared/design-system/localized-text/localized-text.component';
import { SuccessBadge } from 'src/shared/design-system/badge/success-badge/success-badge.component';
import { ButtonReset } from 'src/app/styles/resets';
import { colors } from 'src/app/styles/colors';
import { translate } from 'src/shared/utils/i18n';
import { CommonHeader, ModalBody } from 'src/widgets/modal/modal.style';

export const NextShipmentSuccessModal = ({ destroyModal }) => (
  <ModalBody>
    <CommonHeader>
      <strong>
        <LocalizedText fontSize={4} textKey="nextShipment.title" />
      </strong>
      <ButtonReset onClick={() => destroyModal()}>
        <XIcon height={14} fillColor={colors.white} />
      </ButtonReset>
    </CommonHeader>
    <Block py={2} px={6}>
      <Block p={4}>
        <Block pb={2} mb={3} display="flex" justifyContent="center">
          <SuccessBadge size={75} fillColor={colors.brandBlue} />
        </Block>
        <Block pb={3} mb={4} display="flex" justifyContent="center">
          <strong>
            <LocalizedText textKey="patient.deliveryConfiguration.successModal.description" />
          </strong>
        </Block>
        <Block display="flex" justifyContent="center">
          <Button
            autoFocus
            label={translate(
              'patient.deliveryConfiguration.successModal.confirm',
            )}
            onClick={() => destroyModal()}
          />
        </Block>
      </Block>
    </Block>
  </ModalBody>
);

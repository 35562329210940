import {
  FetchAssignedDemoPatientProfilesLoaderImpl,
  FetchAssignedDemoPatientProfilesServiceImpl,
} from './list-assigned-demo-patient-profiles.service';
import { mockAssignedDemoPatientProfile } from './list-assigned-demo-patient-profiles.mock';

const mockLoader = () => Promise.resolve(mockAssignedDemoPatientProfile);

export const fetchAssignedDemoPatientProfilesFactoryImpl = ({ devMode }) => {
  const loader = devMode
    ? mockLoader
    : FetchAssignedDemoPatientProfilesLoaderImpl;
  return FetchAssignedDemoPatientProfilesServiceImpl(loader);
};

import * as React from 'react';

import { GenerateIconsSection } from '../icon-components.component';
import { JELLO_ICON_NAMES } from 'src/app/app.jello.constants';
import { getJelloIcon } from '../../icons-view-templates';
import { GENERAL_ICONS } from '../../../../manufacturer-info-modal.icons.keys';

const sectionTitle = 'manufacturerInfo.generalIcons.generalIconsTitle';

export const iconListItems = [
  {
    label: GENERAL_ICONS.HOME_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.HOME),
  },
  {
    label: GENERAL_ICONS.PATTERNS_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.TREND),
  },
  {
    label: GENERAL_ICONS.CREATE_PATIENT_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.ADD_PATIENT),
  },
  {
    label: GENERAL_ICONS.TIME_TRACKER_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.TIMER),
  },
  {
    label: GENERAL_ICONS.GENERAL_FUNCTIONS_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.SWITCHER),
  },
  {
    label: GENERAL_ICONS.STRIP_MANAGEMENT_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.ACTIVE_TEST_STRIPS),
  },
  {
    label: GENERAL_ICONS.ADD_HCP_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.ADD_HCP_PROFILE),
  },
  {
    label: GENERAL_ICONS.WARNING_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.WARNING_ALT),
  },
  {
    label: GENERAL_ICONS.DEVICE_LINK_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.CLOUD_UPLOAD),
  },
  {
    label: GENERAL_ICONS.GLUCOSE_DEVICES_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.GLUCOSE_DEVICES),
  },
  {
    label: GENERAL_ICONS.HCP_PROFILE_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.HCP),
  },
  {
    label: GENERAL_ICONS.EXPAND_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.MAXIMIZE),
  },
  {
    label: GENERAL_ICONS.HELP_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.HELP),
  },
  {
    label: GENERAL_ICONS.PRINT_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.PRINTER),
  },
  {
    label: GENERAL_ICONS.STOCK_MANAGEMENT_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.INVENTORY_MANAGEMENT),
  },
  {
    label: GENERAL_ICONS.COLLAPSE_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.MINIMIZE),
  },
  {
    label: GENERAL_ICONS.ADDITIONAL_INFO_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.INFORMATION),
  },
  {
    label: GENERAL_ICONS.REPORT_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.DOCUMENT_EXPORT),
  },
  {
    label: GENERAL_ICONS.FILTERS_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.FILTER),
  },
  {
    label: GENERAL_ICONS.CLOSE_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.CLOSE),
  },
  {
    label: GENERAL_ICONS.NOTIFICATION_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.NOTIFICATION),
  },
  {
    label: GENERAL_ICONS.NOTES_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.NOTE),
  },
  {
    label: GENERAL_ICONS.RPM_SETTINGS_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.FORUM),
  },
  {
    label: GENERAL_ICONS.BACK_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.ARROW_LEFT),
  },
  {
    label: GENERAL_ICONS.SEARCH_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.SEARCH),
  },
  {
    label: GENERAL_ICONS.VIDEO_CONSULTATION_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.VIDEO),
  },
  {
    label: GENERAL_ICONS.GETTING_STARTED_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.QUICK_MANUAL),
  },
  {
    label: GENERAL_ICONS.SHOW_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.CHEVRON_DOWN),
  },
  {
    label: GENERAL_ICONS.BGM_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.ACCU_CHEK_GUIDE),
  },
  {
    label: GENERAL_ICONS.CALENDAR_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.CALENDAR),
  },
  {
    label: GENERAL_ICONS.PRODUCT_INFO_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.TAGS),
  },
  {
    label: GENERAL_ICONS.HIDE_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.CHEVRON_UP),
  },
  {
    label: GENERAL_ICONS.CGM_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.CGM_PATCH),
  },
  {
    label: GENERAL_ICONS.SETTINGS_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.SETTINGS),
  },
  {
    label: GENERAL_ICONS.PASSWORD_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.LOCKED),
  },
  {
    label: GENERAL_ICONS.EXPAND_SIDEBAR_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.PAGE_LAST),
  },
  {
    label: GENERAL_ICONS.PUMP_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.ACCU_CHEK_INSIGHT_PUMP),
  },
  {
    label: GENERAL_ICONS.WHATS_NEW_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.DEPRECATED_STAR),
  },
  {
    label: GENERAL_ICONS.LAUNCH_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.LAUNCH),
  },
  {
    label: GENERAL_ICONS.COLLAPSE_SIDEBAR_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.PAGE_FIRST),
  },
  {
    label: GENERAL_ICONS.EDIT_PATIENT_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.EDIT_PATIENT),
  },
  {
    label: GENERAL_ICONS.SIGN_OUT_ICON_TEXT_KEY,
    icon: getJelloIcon(JELLO_ICON_NAMES.POWER),
  },
];

export const GeneralIconsComponent = () => (
  <GenerateIconsSection
    iconListItems={iconListItems}
    sectionTitle={sectionTitle}
  />
);

/* tslint:disable */
/* eslint:disable */
import {
  DeviceSettingsListLoaderImpl,
  DeviceSettingsListServiceImpl,
  DeviceSettingsListTransformImpl,
} from './device-settings-list.service';
import { mockDeviceListSettings } from './device-settings-list.mock';

const mockLoader = () => Promise.resolve(mockDeviceListSettings);

export const DeviceSettingsListFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : DeviceSettingsListLoaderImpl;
  return DeviceSettingsListServiceImpl(loader, DeviceSettingsListTransformImpl);
};

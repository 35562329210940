import { FormControlOption } from 'src/domains/patient/components/forms/forms.types';
import { DeactivatePatientMotives } from './deactivate-patient.types';

export const DEACTIVATE_REASON_OPTIONS: FormControlOption[] = [
  {
    label: 'deactivatePatient.form.select.o',
    value: DeactivatePatientMotives.OTHERS,
  },
  {
    label: 'deactivatePatient.form.select.cop',
    value: DeactivatePatientMotives.CHANGE_OF_PROFESSIONAL,
  },
  {
    label: 'deactivatePatient.form.select.snn',
    value: DeactivatePatientMotives.SERVICE_NOT_NEEDED,
  },
];

export const DEACTIVATE_INITIAL_STATE = {
  motive: DeactivatePatientMotives.OTHERS,
};

export const DEACTIVATE_MAX_LENGTH = 250;

export const DEACTIVATE_COUNTLY_KEYS = {
  EVENT_TITLE: 'Patient deactivated',
  SEG_REASON: 'Reason',
  VALUE_OTHER: 'Other',
  VALUE_CHANGE: 'Change professional',
  VALUE_NOT_NEEDED: 'Service not needed',
  SEG_COMMENTS: 'Comments',
  VALUE_TRUE: 'True',
  VALUE_FALSE: 'False',
};

import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils'; //eslint-disable-line
import { removePermissionsEMR } from './permissions.utils';

import {
  ENDPOINTS,
  endpointWithParams,
} from 'src/app/navigation/services/service.constants';

import { randomValue } from 'src/shared/utils/stat.js';

const TARGET_ID_TYPES = {
  hcp: 'HCP',
  user: 'USER',
  fhir: 'FHIR',
  gigya: 'GIGYA',
  association: 'ASSOCIATION',
  hcid: 'HCID',
};

export const PermissionsServiceLoaderImpl = (_params, accessToken) =>
  getJSON(
    endpointWithParams(ENDPOINTS.permissions, { random: randomValue(1) }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );

export const PatientPermissionsServiceLoaderImpl = (
  gigyaUid,
  accessToken,
  gigyaToken,
  fhirId,
  hasRolePlatform,
) => {
  const headers = {
    Authorization: createAuthHeader(accessToken),
    ...(hasRolePlatform && {
      targetIdType: TARGET_ID_TYPES.fhir,
      targetId: fhirId,
    }),
  };

  if (hasRolePlatform && fhirId) {
    return getJSON(ENDPOINTS.permissionsWithPlatform, { headers });
  }

  return getJSON(
    endpointWithParams(ENDPOINTS.permissionsPatients, {
      gigyaUid,
      random: randomValue(1),
    }),
    { headers },
  );
};

export const transformPermissions = (p) => removePermissionsEMR(p);

export const PermissionsService =
  (load, transform) =>
  (query, accessToken, gigyaToken, fhirId, hasRolePlatform) =>
    load(query, accessToken, gigyaToken, fhirId, hasRolePlatform).then(
      transform,
    );

import { createPayloadAction } from '../../../store/app.actions';

import {
  ManifestModulesActionType,
  ManifestModulesPayload,
  SaveManifestModules,
} from './manifestModules.types';

export const saveManifestModules = (
  payload: ManifestModulesPayload,
): SaveManifestModules =>
  createPayloadAction(ManifestModulesActionType.SAVE_MANIFEST_MODULES, payload);

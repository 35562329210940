import { createSelector } from 'reselect';
import { path } from 'ramda';

export const selectAnnouncements = path(['announcements']);

export const selectUserAnnouncements = createSelector(
  selectAnnouncements,
  path(['userAnnouncements']),
);

export const selectIsFetchingAnnouncements = createSelector(
  selectAnnouncements,
  path(['isFetchingAnnouncements']),
);

export const selectMarkVisitedResponse = createSelector(
  selectAnnouncements,
  path(['markVisitedResponse']),
);

export const selectIsMarkingAnnouncementVisited = createSelector(
  selectAnnouncements,
  path(['isMarkingAnnouncementVisited']),
);
export const selectUserAnnouncementNotification = createSelector(
  selectAnnouncements,
  path(['userAnnouncementNotification']),
);

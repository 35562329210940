export const colors = {
  actualHyper: '#E26500',
  backGroundBlueLight: 'var(--jello-color-background-alternative)',
  basalBlue: '#6CC3EA',
  basalIconBlue: '#058CCF',
  black: 'var(--jello-color-foreground-base)',
  blackGraphInsulin: '#3A3535',
  blue: '#0D79D6',
  blue3: '#e7eefa',
  blue600: 'var(--jello-color-foreground-highlight-base)',
  blue800: 'var(--jello-color-interactive-active)',
  blueBackgroundButton: '#E6EEFB',
  blueBorderCircleDashboard: '#0064D3',
  blueBorderDashboard: '#C3D0E8',
  blueBorderInactiveButton: '#c3d0e8',
  blueFaded: '#bed5ed',
  blueFadedLight: '#f9fbfd',
  blueGraphInsulin: '#3488D6',
  blueHypoglycemiaIcons: 'var(--jello-color-interactive-active)',
  blueLight: '#65B7E6',
  blueMarine: '#5D8FDF',
  blueMarine5: '#3D70C4',
  blueMarineAlpha: '#f7f9fd',
  blueMarineAlpha15: 'rgba(93, 143, 223, 0.15)',
  blueMarineAlpha30: 'rgba(93, 143, 223, 0.3)',
  blueMarineAlpha5: 'rgba(93, 143, 223, 0.05)',
  blueMarineBorder: '#D3E0F6',
  blueVeryLight: '#80e7ff',
  borderInputTimeRange: '#BABABA',
  brandBlue: 'var(--jello-color-foreground-highlight-base)',
  brandBlueDark: '#003E7D',
  carb: '#E59037',
  charcoal: '#4A4A4A',
  clear: 'rgba(0, 0, 0, 0)',
  colorBrand05: 'var(--jello-color-background-alternative)',
  cyan: '#2FCAEA',
  darkBlueMarine: '#06C',
  darkestGray: '#3A3A3A',
  darkOrange: '#C07250',
  darkRedLegendGraph: '#A71B28',
  fadedDarkGray: '#aaaaaa',
  gray: '#A1A1A1',
  gray250: '#bfbfbf',
  gray3: '#d9dde7',
  gray33: '#8d8da5',
  grayDark: '#656565',
  grayDarkTextDashboard: '#343434',
  grayHypoglycemiaData: '#333',
  grayLight: '#D8D8D8',
  grayLighter: '#eaeaea',
  grayMedium: '#9B9B9B',
  grayNoData: 'var(--jello-color-foreground-interactive-disabled)',
  grayTextButton: '#6f728b',
  green: '#4FA952',
  greenLegendGraph: '#5DBC68',
  grey900: 'var(--jello-color-foreground-accent-base)',
  greyDateThumbnails: '#aeb0c8',
  greyGlucosePoints: '#737373',
  greyMediumTimeRange: '#737373',
  heroGradient: 'linear-gradient(93deg, #13b1e5, #a91ae7)',
  hyper: '#F2AA13',
  hypo: '#9E1C0F',
  iconColor: '#508EE5',
  inTarget: '#72941F',
  intenseBlue: '#0091ea',
  intenseLila: '#311b92',
  intenseOrange: '#FFAB00',
  jelloAccentBase02: 'var(--jello-color-accent-base-02)',
  jelloColorFeedback0: 'var(--jello-color-feedback-success-intense)',
  jelloColorFeedback01: 'var(--jello-color-feedback-success-intense)',
  jelloColorFeedback07: 'var(--jello-color-feedback-danger-intense)',
  jelloColorForegroundSecondary: 'var(--jello-color-foreground-secondary)',
  lavender: '#f7f9fd',
  lighterTurqoise: '#58BDB7',
  lightGreen: '#F2FCE9',
  lightGreen2: '#D8F1BC',
  lightOrange: '#FFBA7E',
  lightTurqoise: '#E0F0F0',
  orangeCircle: '#FFBA7E',
  orangeCircleBorder: 'rgba(255, 186, 126, 0.5)',
  orangeLegendGrahp: '#FDB913',
  orangeLight: '#F4D399',
  paleBlue: '#e1f5fe',
  paleLila: '#ded1f6',
  paleOrange: '#FFECB3',
  purpleBolusCal: '#7C095E',
  quartzBlue: '#d3e0f6',
  quartzBlue2: '#B5D6F2',
  red: '#CF021B',
  redGraphInsulin: '#FF395F',
  redLegend: '#E60039',
  redLegendGraph: '#ED1C24',
  redLight: '#F34C4C',
  redLighter: '#FF9CA8',
  redPastel: '#E1AFB5',
  rose500: 'var(--jello-color-accent-base-04)',
  silver: '#EEEEEE',
  silverDark: '#e6e6e9',
  silverLight: '#F7F7F7',
  silverMedium: '#EAEAEA',
  tbrBlue: '#188CE1',
  trafficGreen: '#7ED321',
  trafficOrange: '#F5A623',
  trafficOrangeCircleBorder: 'rgba(255, 186, 126, 0.5)',
  trafficRed: '#CF021B',
  trafficRed2: '#F0B3BA',
  trafficRedLightest: '#FAF2F3',
  trafficRedText: '#A40115',
  transparentBlack: 'rgba(0, 0, 0, .6)',
  transparentCyan: 'rgba(47, 202, 234, 0.7)',
  transparentGrayDark: 'rgba(101, 101, 101, .5)',
  transparentGrayLight: 'rgba(238, 238, 238, .5)',
  transparentGrayMedium: 'rgba(198, 198, 198, 0.5)',
  transparentGreen: 'rgba(185, 231, 153, 0.4)',
  turqoise: '#0DA69E',
  warning: '#D92D1A',
  white: 'var(--jello-color-foreground-inverse-base)',
  yellowLegendGraph: '#FFF200',
};

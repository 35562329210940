import {
  GetParticipantIdsActionType,
  GetParticipantIdsState,
  GetParticipantIdsActions,
} from './get-participant-ids.types';

export const getParticipantIdsInitialState: GetParticipantIdsState = {
  isGettingParticipantIds: false,
  getParticipantIdsSuccessful: false,
  getParticipantIdsError: false,
  observationalParticipantIds: [],
};

export const getParticipantIdsReducer = (
  state = getParticipantIdsInitialState,
  action: GetParticipantIdsActions,
): GetParticipantIdsState => {
  switch (action.type) {
    case GetParticipantIdsActionType.GET_PARTICIPANT_IDS_START:
      return {
        ...state,
        isGettingParticipantIds: true,
        getParticipantIdsSuccessful: false,
        getParticipantIdsError: false,
        observationalParticipantIds: [],
      };
    case GetParticipantIdsActionType.GET_PARTICIPANT_IDS_SUCCESS:
      return {
        isGettingParticipantIds: false,
        getParticipantIdsSuccessful: true,
        getParticipantIdsError: false,
        observationalParticipantIds: action.payload,
      };
    case GetParticipantIdsActionType.GET_PARTICIPANT_IDS_ERROR:
      return {
        isGettingParticipantIds: false,
        getParticipantIdsSuccessful: false,
        getParticipantIdsError: true,
        observationalParticipantIds: [],
      };

    case GetParticipantIdsActionType.CLEAR_GET_PARTICIPANT_IDS:
      return getParticipantIdsInitialState;

    default:
      return state;
  }
};

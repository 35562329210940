// @ts-nocheck
import { includes, isEmpty, keys, map, path, pipe, pluck } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';

import { selectOngoingRequests } from 'src/app/store/request/request.selectors';
import { pickBasalYMax } from 'src/domains/diagnostics/scenes/graphs/graph.selector';
import {
  addTbrType,
  createTbrLine,
  formatTbrEvent,
  isTbrStart,
  normalizeTbrLine,
} from 'src/domains/diagnostics/scenes/graphs/template/standard-day-detail/standard-day-detail.selector';
import { selectAllBasals } from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import { FETCH_PATIENT_REQUEST } from 'src/domains/patient/store/patient/patient.constant';
import {
  extractIsoDate,
  hourStringToFloat,
  isBetween,
} from 'src/shared/utils/date';
import { TARGET_RANGES_GET_REQUEST } from 'src/widgets/target-ranges/store/target-ranges.actions';
import { GET_CGM_CLINCAL_DATA, GET_CGM_SUMMARY } from './../cgm.actions';

import {
  filterDuplicateLines,
  transformBasal,
} from 'src/domains/diagnostics/scenes/graphs/graph-shared/graph.util';

import { HI_VALUE, LO_VALUE } from 'src/domains/diagnostics/store/constants';
import { NOT_CONNECTED } from '../../../../patient-data-bar/data-sharing/constants';
import { CGM_VIEW_TYPE } from './cgm.selectors.constants';
import { selectLogbookStatsData } from '../../../../diagnostics/widgets/logbook-stats/logbook-stats.selector';

const REQUIREMENTS = [
  GET_CGM_SUMMARY.BASE,
  TARGET_RANGES_GET_REQUEST.BASE,
  FETCH_PATIENT_REQUEST.BASE,
];

export const selectCGM = path(['continuousMonitoring']);

export const selectCGMErrors = createSelector(selectCGM, path(['errors']));

export const selectCGMPristine = createSelector(selectCGM, path(['pristine']));

export const selectCGMClinicalDataFinish = createSelector(
  selectCGM,
  path(['cgmClinicalDataFinish']),
);

export const selectCGMSummary = createSelector(selectCGM, path(['summary']));

export const selectCGMMeasurements = createSelector(
  selectCGM,
  path(['measurements']),
);

export const selectCGMRawData = createSelector(
  selectCGMMeasurements,
  path(['rawData']),
);

export const selectCGMRawDataWithoutHiAndLoValues = createSelector(
  selectCGMRawData,
  (rawData) => rawData?.filter(({ glucose }) => glucose !== null),
);

export const selectCGMRangeTypeInRangeDate = createSelector(
  selectCGMRawData,
  (rawData) => rawData?.map(({ rangeType }) => rangeType),
);

export const selectIsLoadingCGMSummary = createSelector(
  selectOngoingRequests,
  includes(GET_CGM_SUMMARY.BASE),
);

export const selectIsLoadingCGMClinicalData = createSelector(
  selectOngoingRequests,
  includes(GET_CGM_CLINCAL_DATA.BASE),
);

export const selectCGMFirstMeasurement = createSelector(
  selectCGMSummary,
  path(['firstMeasurementDate']),
);

export const selectCGMLastMeasurement = createSelector(
  selectCGMSummary,
  path(['lastMeasurementDate']),
);

export const selectCGMStartDate = createSelector(
  selectCGMSummary,
  path(['startDate']),
);

export const selectCGMRange = createSelector(selectCGMSummary, path(['range']));

export const selectCGMEndDate = createSelector(
  selectCGMSummary,
  path(['endDate']),
);

export const selectIsSummaryEmpty = createSelector(
  selectCGMFirstMeasurement,
  (date) => date === null,
);

export const selectIsLoadingCGMRequirements = createSelector(
  selectOngoingRequests,
  selectCGMErrors,
  selectCGMFirstMeasurement,
  (reqs, errs, first) => {
    const ongoing = (reqs as string[]).some((val) =>
      includes(val, REQUIREMENTS),
    );
    const noerrs = isEmpty(errs);
    const nofirst = first === '';
    return ongoing || (noerrs && nofirst);
  },
);

export const selectHasCGMMeasurements = createSelector(
  selectIsLoadingCGMSummary,
  selectCGMFirstMeasurement,
  (loading, first) => !loading && first,
);

export const selectTotalMeasurements = createSelector<{}, {}, number>(
  selectCGMMeasurements,
  path(['totalMeasurements']),
);

export const selectTotalMeasurementsWithoutHiAndLoValues = createSelector(
  selectCGMRangeTypeInRangeDate,

  (rangeTypes) => {
    const HI_LO_ARRAY = [HI_VALUE, LO_VALUE];
    const filterNonHiAndLoValues = (rangeType) =>
      !HI_LO_ARRAY.includes(rangeType);
    const nonHiAndLoValues = rangeTypes?.filter(filterNonHiAndLoValues);
    return nonHiAndLoValues?.length;
  },
);

// TODO: test
export const selectGlucoseSummation = createSelector<{}, {}, number>(
  selectCGMMeasurements,
  path(['glucoseSummation']),
);

export const selectDataDays = createSelector(
  selectCGMMeasurements,
  path(['days']),
);

export const selectDataStatsDays = createSelector(
  selectCGMMeasurements,
  path(['daysStats']),
);

// TODO: test
export const selectIntervals = createSelector(
  selectCGMMeasurements,
  path(['intervals']),
);

export const selectDataDaysList = createSelector(selectDataDays, (days) =>
  keys(days),
);

export const selectSingleDataDay = (day: string) =>
  createSelector(selectDataDays, (days) => days[day]);

export const selectCGMSelectedDate = createSelector(
  selectCGM,
  path(['selectedDate']),
);

export const isDateInDateRange = (date, selected) => {
  return isBetween(
    date,
    selected.clone().startOf('day'),
    selected.clone().endOf('day'),
  );
};

export const filterByDateRange = (objects, selected) =>
  objects.filter(({ date }) => isDateInDateRange(date, selected));

export const selectBasalsInRange = createSelector(
  selectAllBasals,
  selectCGMSelectedDate,
  (basals, selectedDate) => {
    const moment = require('moment');
    return filterByDateRange(basals, moment(`${selectedDate}`));
  },
);

export const selectMaxBasalMeasurement = createSelector(
  selectBasalsInRange,
  pipe(pluck('basalCbrf'), (basalsValues) => Math.max(...basalsValues)),
);

export const selectBasalYMax = createSelector(
  selectMaxBasalMeasurement,
  pickBasalYMax,
);

export const selectNumericalSelectedTime = createSelector(
  selectCGMSelectedDate,
  (graphStartTime) =>
    hourStringToFloat(graphStartTime || new Date().toISOString()),
);

export const selectTbrLines = createSelector(
  selectBasalsInRange,
  selectNumericalSelectedTime,
  (basals, numericalGraphStartTime) =>
    basals
      .filter(isTbrStart)
      .map(addTbrType)
      .map(formatTbrEvent)
      .map(createTbrLine(numericalGraphStartTime))
      .map(normalizeTbrLine),
);

export const selectGlucose = path([
  'ui',
  'patientDashboard',
  'glucoseMeasurements',
]);

export const cgmDateConnector = createStructuredSelector({
  range: selectCGMRange,
  startDate: selectCGMStartDate,
  endDate: selectCGMEndDate,
  firstMeasurementDate: selectCGMFirstMeasurement,
  lastMeasurementDate: selectCGMLastMeasurement,
});

export const selectCgmViewType = createSelector(
  selectHasCGMMeasurements,
  (hasCGMMeasurements) => {
    return hasCGMMeasurements
      ? CGM_VIEW_TYPE.DATA_VIEW
      : CGM_VIEW_TYPE.DATA_SHARING_VIEW;
  },
);

export const hideTimePeriod = createSelector(
  selectCgmViewType,
  (cgmViewType) => cgmViewType === CGM_VIEW_TYPE.DATA_SHARING_VIEW,
);

export const dataToCardSelector = createSelector(
  selectLogbookStatsData,
  selectCGMSelectedDate,
  (dataToCard, dailyProfileSelectedDay) =>
    dataToCard.find((element) => {
      const utcDate = element.date.toISOString();
      const date = extractIsoDate(utcDate);

      return date === dailyProfileSelectedDay;
    }),
);

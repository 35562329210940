import * as React from 'react';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { colors } from 'src/app/styles/colors';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';

import { SvgIcon } from 'src/shared/design-system/icon/icon.component';

const AdditionalInformationIconComponent = ({
  height = 18,
  width = 18,
  iconColor = colors.grayDark,
  borderFillColor = colors.white,
  borderColor = colors.grayDark,
  withBorder = false,
  t,
  title = 'graphs.iconTitles.additionalInformation',
}) => {
  const originalWidth = 18;
  const originalHeight = 18;
  const minX = withBorder ? 0 : -1.5;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  const r = withBorder ? 8 : 8.344;

  return (
    <SvgIcon
      title={t(title)}
      width={calculatedWidth}
      minX={minX}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g fillRule="evenodd">
        <RenderIf validate={withBorder}>
          <circle
            stroke={borderColor}
            fill={borderFillColor}
            cx="9.313"
            cy="8.857"
            r={r}
          />
        </RenderIf>
        <g transform="translate(8.513, 5.057)" fill={iconColor}>
          <rect
            x="-4.5519144e-14"
            y="1.13242749e-14"
            width="1.6"
            height="1.6"
          />
          <rect x="-4.5519144e-14" y="3" width="1.6" height="4.8" rx="0.8" />
        </g>
      </g>
    </SvgIcon>
  );
};

export const AdditionalInformationIcon = withTranslation(
  AdditionalInformationIconComponent,
);

import React from 'react';
import { Bundle } from 'src/app/navigation/bundle';

export const RpmSettingsBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { RpmSettings } = await import(
        /* webpackChunkName: "rpmSettings" */ 'src/domains/rpm/scenes/rpm-settings-wrapper/rpm-settings-wrapper.container'
      );
      return RpmSettings;
    }}
    bundleDidLoad={(RpmSettings) => <RpmSettings {...props} />}
  />
);

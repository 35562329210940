import * as React from 'react';

import { ToolTip } from 'src/shared/design-system/tool-tip/tool-tip.component';
import { renderHintsDataRows } from 'src/domains/diagnostics/widgets/logbook/logbook-hint/logbook-hint.component';
import {
  Column,
  ContainerDiv,
  GrayMediumLabel,
  Header,
  LargeLabel,
} from 'src/domains/diagnostics/widgets/logbook/logbook-hint/logbook-hint.style';

export const HourToolTip = ({ x, y, hour, day, measurements, timeFormat }) => (
  <ToolTip x={x} y={y}>
    <ContainerDiv>
      <Header>
        <Column>
          <LargeLabel>{hour}</LargeLabel>
        </Column>
        <Column>
          <GrayMediumLabel>{day}</GrayMediumLabel>
        </Column>
      </Header>
      {renderHintsDataRows(measurements, timeFormat)}
    </ContainerDiv>
  </ToolTip>
);

import * as React from 'react';

import { XIcon } from 'src/shared/design-system/icons/index';
import { Block } from 'src/shared/design-system/block/block.component';
import { Button } from 'src/shared/design-system/button/button.component';
import { LocalizedText } from 'src/shared/design-system/localized-text/localized-text.component';
import { ButtonReset } from 'src/app/styles/resets';
import { colors } from 'src/app/styles/colors';
import { translate } from 'src/shared/utils/i18n';

import {
  BrowserDisclaimerSubTitle,
  BrowserDisclaimerTitle,
} from './disclaimer-browser-modal.style';

import { CommonHeader, ModalBody } from '../../modal.style';

export const DisclaimerBrowserModal = ({ destroyModal }) => (
  <ModalBody>
    <CommonHeader>
      <LocalizedText fontSize={4} textKey="modals.browserDisclaimer.tittle" />
      <ButtonReset
        onClick={() => {
          destroyModal();
        }}
      >
        <XIcon height={14} fillColor={colors.white} />
      </ButtonReset>
    </CommonHeader>
    <Block>
      <Block py={4} px={5}>
        <Block pb={1} mb={3} display="flex" justifyContent="center">
          <BrowserDisclaimerTitle>
            <LocalizedText
              fontSize={4}
              textKey="modals.browserDisclaimer.subTittle"
            />
          </BrowserDisclaimerTitle>
        </Block>
        <Block pb={1} mb={4} textAlign="center">
          <BrowserDisclaimerSubTitle>
            <LocalizedText
              fontSize={3}
              textKey="modals.browserDisclaimer.description1"
            />
          </BrowserDisclaimerSubTitle>
          <BrowserDisclaimerSubTitle>
            <LocalizedText
              fontSize={3}
              textKey="modals.browserDisclaimer.description2"
            />
          </BrowserDisclaimerSubTitle>
        </Block>
        <Block display="flex" justifyContent="center">
          <Button
            autoFocus
            label={translate('modals.browserDisclaimer.confirm')}
            onClick={destroyModal}
          />
        </Block>
      </Block>
    </Block>
  </ModalBody>
);

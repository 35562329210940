export const transformClinicalStudiesResponse = (response) => {
  const studyIds = response.observationalStudies.map((study) => study.studyId);
  const filteredStudyIds = studyIds.filter(
    (item, index) => studyIds.indexOf(item) === index,
  );
  return filteredStudyIds.map((study) => ({
    label: study,
    value: study,
  }));
};

export const transformParticipantIdsResponse = (response) => {
  const participantIds = response.subjectIds.map(
    ({ participantId, siteId }) => ({
      label: siteId + '-' + participantId,
      value: siteId + '-' + participantId,
    }),
  );
  participantIds.sort((a, b) => {
    const numberA = parseInt(a.value.split('-')[1], 10);
    const numberB = parseInt(b.value.split('-')[1], 10);

    return numberA - numberB;
  });

  return participantIds;
};

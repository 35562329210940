import { compose, lifecycle } from 'recompose';
import { equals, not, pipe } from 'ramda';
import { connect } from 'react-redux';

import { hasValue } from 'src/shared/utils/validation-helpers';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import {
  connectToPermissions,
  selectJelloPermission,
} from 'src/domains/permissions/store/permissions.selectors';
import { PERMISSIONS } from 'src/domains/permissions/store/permissions.constants';
import { verifyPermission } from 'src/domains/permissions/store/permissions.utils';
import { connectToProfile } from 'src/app/store/user/user.selectors';

import {
  fetchCheckExternalPatient,
  setIsExternalPatient,
} from './bg/store/bg.actions';
import { patientExternalConnector } from './bg/store/bg.selectors';

export const hasPatientDiagnostics = (permissionList) =>
  verifyPermission(permissionList)(PERMISSIONS.PATIENT_DIAGNOSTICS);
export const hasPatientDiagnosticsPermissionChanged = (
  currentPermissions,
  nextPermissions,
) =>
  hasValue(currentPermissions)
    ? pipe(
        hasPatientDiagnostics,
        equals(hasPatientDiagnostics(currentPermissions)),
        not,
      )(nextPermissions)
    : hasValue(nextPermissions);

export const addDiagnosticsPermissionLifecycle = (Component) =>
  lifecycle({
    shouldComponentUpdate({ currentPermissions: nextPermissions }) {
      return hasPatientDiagnosticsPermissionChanged(
        this.props.currentPermissions,
        nextPermissions,
      );
    },
  })(Component);

export const addDiagnosticsPermissionAndCheckExternalIdLifecycle = (
  Component,
) =>
  lifecycle({
    componentDidMount() {
      const {
        setIsExternalPatient,
        fetchCheckExternalPatient,
        location,
        match: { params },
      } = this.props;

      const patientId = params.id;
      const urlSearchParams = new URLSearchParams(location.search);
      const type = urlSearchParams.get('type');
      setIsExternalPatient(false);
      if (type === 'external' && patientId) {
        fetchCheckExternalPatient({ externalPatientId: patientId });
      }
    },
    shouldComponentUpdate({ currentPermissions: nextPermissions }) {
      return hasPatientDiagnosticsPermissionChanged(
        this.props.currentPermissions,
        nextPermissions,
      );
    },
  })(Component);

export const connectWithDiagnosticsPermissionsAndCheckExternalIdLifecycle =
  compose(
    connectToPermissions,
    connectToProfile,
    connect(
      (state) => ({
        patientExternalConnector,
      }),
      mapDispatchers({
        setIsExternalPatient,
        fetchCheckExternalPatient: fetchCheckExternalPatient.start,
      }),
    ),
    addDiagnosticsPermissionAndCheckExternalIdLifecycle,
  );

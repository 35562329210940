import { animations } from './animations';
import { letterSpacing } from './typography';

import { boxShadow, boxShadows } from '../../app/styles/box-shadow';
import {
  BASE_FONT_SIZE,
  fontSize,
  fontSizesMap,
} from '../../app/styles/font-sizes';
import { fontWeights } from '../../app/styles/font-weights/font-weights';
import { borderRadiusMap } from '../../app/styles/border-radius';
import { breakpointsMap } from '../../app/styles/breakpoints';
import { spacing, spacingMap } from '../../app/styles/spacing';
import { colors } from '../../app/styles/colors';
import { complexStyles } from '../../app/styles/complex-styles/complex-styles';
import { strokeWidth } from '../../app/styles/stroke-width';
import {
  transitionEasing,
  transitions,
  transitionSpeed,
} from '../../app/styles/transitions';
import { zIndexes, zIndexScale } from '../../app/styles/z-index';

export const theme = {
  BASE_FONT_SIZE,
  animations,
  boxShadow,
  boxShadows,
  borderRadius: borderRadiusMap,
  breakpoints: breakpointsMap,
  colors,
  fontSize,
  fontSizes: fontSizesMap,
  fontWeights,
  letterSpacing,
  spacing,
  space: spacingMap,
  strokeWidth,
  textStyles: complexStyles.textStyles,
  transitions,
  transitionEasing,
  transitionSpeed,
  zIndexScale,
  zIndexes,
};

export type ThemeInterface = typeof theme;

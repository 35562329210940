import {
  createAuthHeader,
  postJSON,
  stringifyBody,
} from 'src/shared/utils/service/service.utils';
import { ENDPOINTS } from 'src/app/navigation/services/service.constants';

import {
  CreateProfessionalParams,
  CreateProfessionalResponse,
  EC6Model,
} from './create-professional.types';

export const CreateProfessionalLoaderImpl = (
  { professionalId, professional }: CreateProfessionalParams,
  accessToken: string,
) =>
  postJSON(ENDPOINTS.practitioners, {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
    body: stringifyBody(professional),
  });

export const CreateProfessionalTransformImpl = ({
  model,
}: CreateProfessionalResponse): EC6Model => ({
  professionalId: Number(model.split(':')[1]),
});

export const CreateProfessionalServiceImpl =
  (load, transform) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { selectManifestModuleName } from '../store/manifest-modules/manifestModules.selectors';

import { NotificationComponent } from './notification.component';

const MODULE_NAME = 'rdcp-hcp-nc-client-module';
export const NotificationContainer = compose(
  connect((state) => ({
    moduleName: selectManifestModuleName(MODULE_NAME)(state),
  })),
)(NotificationComponent);

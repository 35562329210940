import React, { useState } from 'react';
import { t } from 'i18next';
import {
  selectPatientHasData,
  selectPatientIsEMRCompleted,
  selectPatientReportStatus,
} from './documents-export-section.selector';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { selectIsHCPOrGPOrAdminUserProfile } from 'src/app/store/user/user.selectors';
import {
  selectPatientFhirId,
  selectPatientInfo,
} from 'src/domains/patient/store/patient/patient.selector';
import {
  selectIsEMR,
  selectIsPatientEMR,
} from 'src/domains/permissions/store/permissions.selectors';
import { routes } from 'src/domains/routes';
import styles from './styles.module.css';
import {
  REPORTS_DIALOG_ID,
  SHOW_REPORTS_MODAL_EVENT,
} from 'src/app/modules/reports-module/reports.constants';
import { countlyEventTrigger } from 'src/app/navigation/countly';
import {
  EVENTS_KEYS,
  SEGMENT_KEYS,
  SEGMENT_VALUES,
} from './countly-documents-export-events.constants';
import {
  selectPatientEndDate,
  selectPatientStartDate,
} from 'src/domains/diagnostics/store/selectors/patient-date-range.selector';
import { savePatientReportsStart } from 'src/domains/patient-dashboard/store/patient-reports/patient-reports.action';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import { DateTime } from 'luxon';
import { isHomeRoute } from 'src/shared/utils/path-matchers';
import { ReportsContainer } from 'src/app/modules/reports-module/reports.container';
import { RenderIf } from 'src/shared/utils/render-if';
import { PrintAndDownloadButton } from './print-and-download/print-and-download-button';
import { ExportEMRSection } from './print-and-download/export-emr-section';
import { PATIENT_ACTIONS_BUTTONS_SECTION_TEST_ID } from './patient.constants';

const mapStateToProps = (state, props) => ({
  isHCPOrGPOrAdminUserProfile: selectIsHCPOrGPOrAdminUserProfile(state),
  isHcpEMR: selectIsEMR(state),
  isPatientEMR: selectIsPatientEMR(state),
  patientHasData: selectPatientHasData(state),
  patientFhirId: selectPatientFhirId(state),
  isPatientEMRCompleted: selectPatientIsEMRCompleted(state),
  patientReportStatus: selectPatientReportStatus(state),
  patientStartDate: selectPatientStartDate(state),
  patientEndDate: selectPatientEndDate(state),
  patient: selectPatientInfo(state),
  fhirId: selectPatientFhirId(state),
});

const mapDispatchToProps = mapDispatchers({
  onSavePatientReports: savePatientReportsStart,
});

export const DocumentsExportSectionComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const [isExportToEMROpen, setIsExportToEMROpen] = useState(false);

  const onClickPrintAndDownloadButton = () => {
    const dialog = document.querySelector(`#${REPORTS_DIALOG_ID}`);
    dialog
      ? dialog.dispatchEvent(new CustomEvent(SHOW_REPORTS_MODAL_EVENT))
      : console.error('Reports dialog not found');

    const section = isCGMDashboardTab ? SEGMENT_VALUES.CGM : SEGMENT_VALUES.BGM;
    countlyEventTrigger(EVENTS_KEYS.REPORTS_CLICKED, {
      [SEGMENT_KEYS.SECTION]: section,
    });
  };

  const onClickExportToEMR = () => {
    callSavePatientReports();
    setIsExportToEMROpen(true);
  };

  const callSavePatientReports = () => {
    const { onSavePatientReports, patientStartDate, patientEndDate, fhirId } =
      props;
    const patientStartDateIso = toIso(patientStartDate);
    const patientEndDateIso = toIso(patientEndDate);
    onSavePatientReports({
      patientId: fhirId,
      reportType: 'EMR',
      startDate: patientStartDateIso,
      endDate: patientEndDateIso,
    });
  };

  const toIso = (stringDate: DateTime) =>
    stringDate.toUTC().startOf('day').toISODate();

  const isBGDashboardTab = !!matchPath(location.pathname, {
    path: routes.patient.patientBGDashboard,
    exact: true,
    strict: false,
  });

  const isCGMDashboardTab = !!matchPath(location.pathname, {
    path: routes.patient.patientCGMDashboard,
    exact: true,
    strict: false,
  });

  const canDisplayPrintAndDownload =
    props.isHCPOrGPOrAdminUserProfile && !isHomeRoute();

  const printAndDownloadDisableProp =
    isBGDashboardTab || isCGMDashboardTab ? {} : { disabled: '' };

  const canDisplayExportEMR = props.isHcpEMR && props.isPatientEMR;

  const isExportEMREnabled =
    Boolean(props.patientHasData) &&
    Boolean(props.patientFhirId) &&
    Boolean(props.isPatientEMRCompleted)
      ? {}
      : { disabled: '' };

  const patientDetails = {
    patient: props.patient,
    patientStartDate: props.patientStartDate,
    patientEndDate: props.patientEndDate,
    patientReportStatus: props.patientReportStatus,
  };
  return (
    <RenderIf validate={canDisplayPrintAndDownload || canDisplayExportEMR}>
      <ReportsContainer />
      <section
        className={styles.buttonsSection}
        data-testid={PATIENT_ACTIONS_BUTTONS_SECTION_TEST_ID}
      >
        <RenderIf validate={canDisplayPrintAndDownload}>
          <PrintAndDownloadButton
            onClickPrintAndDownloadButton={onClickPrintAndDownloadButton}
            disableProps={printAndDownloadDisableProp}
            tooltipText={t('patientBar.printAndDownloadTooltipText')}
          />
        </RenderIf>

        <RenderIf validate={canDisplayExportEMR}>
          <ExportEMRSection
            onClickExportToEMR={onClickExportToEMR}
            isExportEMREnabled={isExportEMREnabled}
            isModalOpen={isExportToEMROpen}
            setModalOpen={setIsExportToEMROpen}
            patientDetails={patientDetails}
            onRetry={callSavePatientReports}
            tooltipText={t('patientBar.exportToEMRTooltipText')}
          />
        </RenderIf>
      </section>
    </RenderIf>
  );
});

import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';

import { trafficLightColorToStatus } from '../../delivery-status/delivery-status.util';

import {
  ENDPOINTS,
  endpointWithParams,
} from 'src/app/navigation/services/service.constants';

export const GetDeliveryLoaderImpl = ({ patientId }, accessToken) =>
  getJSON(endpointWithParams(ENDPOINTS.delivery, { patientId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  });

export const GetDeliveryTransformImpl = ({
  id,
  lastCollectedDate,
  prescriptionId,
  stripModelId,
  stripsDelivered,
  trafficLightStatus,
}) => ({
  id,
  lastCollectedDate,
  prescriptionId,
  stripModelId,
  stripsDelivered,
  lastDeliveryTrafficLightStatus:
    trafficLightColorToStatus[trafficLightStatus.status],
});

export const GetDeliveryServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);

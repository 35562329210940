import {
  MarkAnnouncementVisitedLoaderImpl,
  MarkAnnouncementVisitedTransformImpl,
  MarkAnnouncementVisitedServiceImpl,
} from './mark-announcement-visited.service';
import { mockMarkAnnouncementVisited } from './mark-announcement-visited.mock';

const mockLoader = () => Promise.resolve(mockMarkAnnouncementVisited);

export const MarkAnnouncementVisitedFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : MarkAnnouncementVisitedLoaderImpl;
  return MarkAnnouncementVisitedServiceImpl(
    loader,
    MarkAnnouncementVisitedTransformImpl,
  );
};

import { equals, path } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import {
  selectCarbsUserUnitMeasurement,
  selectEC6UserUnitMeasurement,
} from '../../../../app/store/user/user.selectors';
import {
  selectDataSharingConsentResponse,
  selectDataSharingHasBeenCalled,
} from '../../../../domains/patient-dashboards/bg/store/check-data-sharing-consent/check-data-sharing-consent.selector';
import { CARBOHYDRATES_UNITS } from '../../../../widgets/user-units-selector/user-units-selector.constants';
import {
  selectFhirPermission,
  selectPlatformPermission,
} from '../../../permissions/store/permissions.selectors';

const { GRAMS, G10, G12, G15, G20 } = CARBOHYDRATES_UNITS;

export const UNIT_MMOLL = 'MMOLL';
export const UNIT_MGDL = 'MGDL';

export const selectPatientStartDate = path([
  'ui',
  'patientDateRange',
  'startDate',
]);

export const selectPatientRange = path(['ui', 'patientDateRange', 'range']);

export const selectPatientEndDate = path(['ui', 'patientDateRange', 'endDate']);

export const selectPatientFirstMeasurementDate = path([
  'ui',
  'patientDateRange',
  'firstMeasurementDate',
]);

export const selectPatientLastMeasurementDate = path([
  'ui',
  'patientDateRange',
  'lastMeasurementDate',
]);

export const selectUnitMeasurementForService = createSelector(
  selectEC6UserUnitMeasurement,
  (bgUnit) => (equals(bgUnit, 'mmol/L') ? UNIT_MMOLL : UNIT_MGDL),
);

export const selectCarbUnitMeasurementForService = createSelector(
  selectCarbsUserUnitMeasurement,
  (carbUnit) => CARBOHYDRATES_UNITS[carbUnit] || GRAMS,
);

export const dateRangeConnector = createStructuredSelector({
  range: selectPatientRange,
  startDate: selectPatientStartDate,
  endDate: selectPatientEndDate,
  firstMeasurementDate: selectPatientFirstMeasurementDate,
  lastMeasurementDate: selectPatientLastMeasurementDate,
  dataSharingResponse: selectDataSharingConsentResponse,
  dataSharingCalled: selectDataSharingHasBeenCalled,
  hasUserFhirPermission: selectFhirPermission,
  hasUserPlatformPermission: selectPlatformPermission,
});

// @ts-nocheck
import * as React from 'react';

import { Button } from 'src/domains/diagnostics/components/button/button.component';
import { AdditionalInformationIcon } from 'src/shared/design-system/icons';
import { colors } from 'src/app/styles/colors';
import { fontSize } from 'src/app/styles/font-sizes';
import { BREAK_POINT_ADDITIONAL_INFORMATION } from 'src/app/styles/breakpoints';

export class AdditionalInformationButton extends React.Component<any, any> {
  public state = {
    isLowResolution: window.outerWidth <= BREAK_POINT_ADDITIONAL_INFORMATION,
  };
  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }
  public render() {
    const { isLowResolution } = this.state;
    const { onClickDisclaimer, content, updated, t } = this.props;

    return (
      <Button
        label={isLowResolution ? '' : t('general.disclaimers')}
        fontSize={fontSize.subheading}
        buttonStyle="infoHeader"
        onClick={() => onClickDisclaimer(content, updated)}
        icon={
          <AdditionalInformationIcon
            height="17"
            withBorder
            iconColor={colors.white}
            borderFillColor={colors.blueMarine}
            borderColor={colors.clear}
          />
        }
      />
    );
  }
  public handleWindowResize = () => {
    this.setState({
      isLowResolution: window.outerWidth <= BREAK_POINT_ADDITIONAL_INFORMATION,
    });
  };
}

import { PairingCodeIdentityStatusResponseType } from './pairing-code-identity-status.types';
import { pairingCodeIdentityStatusMockResponse } from './pairing-code-identity-status.mock';
import {
  PairinCodeIdentityStatusServiceImpl,
  PairingCodeIdentityStatusLoaderImpl,
  PairingCodeIdentityStatusTransformImpl,
} from './pairing-code-identity-status.service';

export const mockLoader = (): Promise<PairingCodeIdentityStatusResponseType> =>
  Promise.resolve(pairingCodeIdentityStatusMockResponse);

export const pairingCodeIdentityStatusFactoryImp = ({ devMode }) => {
  const loader = devMode ? mockLoader : PairingCodeIdentityStatusLoaderImpl;
  return PairinCodeIdentityStatusServiceImpl(
    loader,
    PairingCodeIdentityStatusTransformImpl,
  );
};

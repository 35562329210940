import {
  UserAnnouncementsLoaderImpl,
  UserAnnouncementsTransformImpl,
  UserAnnouncementsServiceImpl,
} from './user-announcements.service';
import { mockUserAnnouncements } from './user-announcements.mock';

const mockLoader = () => Promise.resolve(mockUserAnnouncements);

export const UserAnnouncementsFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : UserAnnouncementsLoaderImpl;
  return UserAnnouncementsServiceImpl(loader, UserAnnouncementsTransformImpl);
};

import { createSelector } from 'reselect';

import { average } from 'src/shared/utils/stat';
import {
  calculateHighBloodGlucoseIndex,
  calculateLowBloodGlucoseIndex,
  getFormattedBloodGlucoseIndex,
} from 'src/domains/diagnostics/utils/graph-statistics.util';
import { getFormattedStandardDeviation } from 'src/domains/diagnostics/utils/stat.util';
import {
  getBeforeMealMean,
  validateMeanBGMeasurements,
} from 'src/domains/diagnostics/utils/graphs.util';
import { diffInDaysInclusiveOfEnds } from 'src/domains/diagnostics/utils/time.util';
import {
  selectGlucoseMeasurementsByDay,
  selectGlucoseMeasurementsInDateSliderRange,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import {
  selectPatientEndDate,
  selectPatientStartDate,
} from 'src/domains/diagnostics/store/selectors/patient-date-range.selector';
import { selectTimeIntervals } from 'src/domains/diagnostics/store/selectors/strip-delivery.selectors';

import { selectBloodGlucoseUnit } from './diagnostics.selector';

export const selectLowestBG = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  (measurements) =>
    Math.min(...measurements.map((measurement) => measurement.value)),
);

export const selectHighestBG = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  (measurements) =>
    Math.max(...measurements.map((measurement) => measurement.value)),
);

export const selectBGIndexes = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectBloodGlucoseUnit,
  (measurements, bloodGlucoseUnit) => ({
    lbgi: getFormattedBloodGlucoseIndex(
      calculateLowBloodGlucoseIndex,
      bloodGlucoseUnit,
      measurements,
    ),
    hbgi: getFormattedBloodGlucoseIndex(
      calculateHighBloodGlucoseIndex,
      bloodGlucoseUnit,
      measurements,
    ),
  }),
);

export const selectNumberOfTests = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  (measurements) => measurements.length,
);

export const selectNumberOfDaysInDateRange = createSelector(
  selectPatientStartDate,
  selectPatientEndDate,
  diffInDaysInclusiveOfEnds,
);

export const selectNumberOfDaysWithMeasurement = createSelector(
  selectGlucoseMeasurementsByDay,
  (glucoseMeasurementsGroupedByDay) => glucoseMeasurementsGroupedByDay.length,
);

export const selectTestsPerDay = createSelector(
  selectNumberOfTests,
  selectNumberOfDaysInDateRange,
  (numberOfTests, numberOfDays) => (numberOfTests / numberOfDays).toFixed(1),
);

export const selectTestsPerMeasuredDay = createSelector(
  selectNumberOfTests,
  selectNumberOfDaysWithMeasurement,
  (numberOfTests, numberOfDaysWithMeasurement) =>
    (numberOfTests / numberOfDaysWithMeasurement).toFixed(1),
);

export const selectMeanBG = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  (measurements) =>
    average(measurements.map((measurement) => measurement.value)),
);

export const selectStandardDeviation = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  (measurements) =>
    getFormattedStandardDeviation(
      measurements.map((measurement) => measurement.value),
    ),
);

export const selectMeanBGBeforeMeal = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  (measurements) =>
    validateMeanBGMeasurements(
      measurements
        .filter((measurement) => measurement.beforeMeal)
        .map((measurement) => measurement.value),
    ),
);

export const selectMeanBGAfterMeal = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  (measurements) =>
    validateMeanBGMeasurements(
      measurements
        .filter((measurement) => measurement.afterMeal)
        .map((measurement) => measurement.value),
    ),
);

export const selectMeanBGBeforeBreakfast = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectTimeIntervals,
  (measurements, timeIntervals) =>
    getBeforeMealMean(measurements, timeIntervals, 'BREAKFAST', 'LUNCH'),
);

export const selectMeanBGBeforeLunch = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectTimeIntervals,
  (measurements, timeIntervals) =>
    getBeforeMealMean(measurements, timeIntervals, 'LUNCH', 'DINNER'),
);

export const selectMeanBGBeforeDinner = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectTimeIntervals,
  (measurements, timeIntervals) =>
    getBeforeMealMean(measurements, timeIntervals, 'DINNER', 'BEDTIME'),
);

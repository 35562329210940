import {
  DEVICE_SETTINGS_TYPE_BASAL_BOLUS,
  DEVICE_SETTINGS_TYPE_GENERAL,
  DEVICE_SETTINGS_TYPE_REMINDERS,
  GRAPH_TYPE_DETAILS,
  GRAPH_TYPE_TREND,
  LOGBOOK_TYPE_24HOUR,
  LOGBOOK_TYPE_DETAILS,
  LOGBOOK_TYPE_DIARY,
  LOGBOOK_TYPE_STATS,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { t } from 'i18next';
import { getJelloIcon } from 'src/widgets/modal/components/manufacturer-info-modal/components/icons-view/icons-view-templates';
import { JELLO_ICON_NAMES } from 'src/app/app.jello.constants';

export const prepareListOfDeviceSettingsTabs = () => [
  { type: DEVICE_SETTINGS_TYPE_BASAL_BOLUS, text: 'Basal / Bolus' },
  { type: DEVICE_SETTINGS_TYPE_GENERAL, text: 'General' },
  { type: DEVICE_SETTINGS_TYPE_REMINDERS, text: 'Reminders' },
];

export const prepareListOfLogbookTabs = (graphType) => [
  {
    type: LOGBOOK_TYPE_24HOUR,
    icon: getJelloIcon(JELLO_ICON_NAMES.LOGBOOK),
    tooltipText: t('graphs.logbook.tooltip.24hours'),
  },
  {
    type: LOGBOOK_TYPE_DETAILS,
    icon: getJelloIcon(JELLO_ICON_NAMES.EVENT),
    tooltipText: t('graphs.logbook.tooltip.logbook'),
  },
  {
    type: LOGBOOK_TYPE_DIARY,
    icon: getJelloIcon(JELLO_ICON_NAMES.LIST_BULLET),
    tooltipText: t('graphs.logbook.tooltip.diary'),
  },
  {
    type: LOGBOOK_TYPE_STATS,
    icon: getJelloIcon(JELLO_ICON_NAMES.PERCENTAGE),
    tooltipText: t('graphs.logbook.tooltip.dailyStatistics'),
  },
];

export const prepareListOfGraphTabs = (graphType) => [
  {
    type: GRAPH_TYPE_DETAILS,
    icon: getJelloIcon(JELLO_ICON_NAMES.DEPRECATED_CHART_SCATTER),
    tooltipText: t('graphs.iconTitles.lineGraph'),
  },
  {
    type: GRAPH_TYPE_TREND,
    icon: getJelloIcon(JELLO_ICON_NAMES.CHART_BOXPLOT),
    tooltipText: t('graphs.iconTitles.boxGraph'),
  },
];

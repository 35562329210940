// tslint:disable
import { AddAlertActionProps } from './alert.types';

export const ALERT_ACTIONS = {
  ADD_ALERT: 'ADD_ALERT',
  REMOVE_ALERT: 'REMOVE_ALERT',
};

export const addAlertAction = ({
  id,
  type,
  text,
  position,
  content,
}: AddAlertActionProps) => ({
  type: ALERT_ACTIONS.ADD_ALERT,
  payload: {
    type,
    id,
    text,
    position,
    content,
  },
});

export const removeAlertAction = () => ({
  type: ALERT_ACTIONS.REMOVE_ALERT,
});

import * as React from 'react';

type InstructionsForUseProps = {
  width?: number;
  height?: number;
};

export const InstructionsForUseIcon = ({
  width = 32,
  height = 32,
}: InstructionsForUseProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
  >
    <g>
      <path
        d="M24.7637 14.962H20.4429V15.7366H24.7637L21.7632 15.7366V19.8965H20.4429V20.6711H26.0439V19.8965H24.7637L24.7637 15.7366V14.962Z"
        fill="currentColor"
      />
      <path
        d="M24.7779 12.5673C24.7779 13.419 24.0874 14.1095 23.2357 14.1095C22.384 14.1095 21.6936 13.419 21.6936 12.5673C21.6936 11.7156 22.384 11.0252 23.2357 11.0252C24.0874 11.0252 24.7779 11.7156 24.7779 12.5673Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.59652 7.41277C4.56825 7.74451 7.85307 8.07332 8.95531 7.33335C8.98317 7.31464 9.01201 7.2975 9.04251 7.28351C12.6448 5.63169 14.1135 5.56257 15.7982 6.24817C15.9282 6.30106 16.0735 6.30077 16.2034 6.24773C17.5878 5.68254 19.7658 5.31948 22.9975 7.30496C25.5711 8.88613 27.7394 8.56868 29.0383 7.94405C29.4335 7.75399 30 8.02607 30 8.46463V24.9903C30 25.183 29.8903 25.3596 29.7126 25.4344C28.042 26.1377 24.9495 26.7276 23.0033 25.3057C20.0546 23.1514 17.439 23.6531 16.2599 24.1754C16.0957 24.2481 15.9077 24.2419 15.745 24.1661C14.5404 23.6048 11.7046 23.2505 8.9958 25.3057C7.06754 26.7687 3.6229 26.3897 2.19183 25.4455C2.06591 25.3624 2 25.2181 2 25.0672V7.91167C2 7.5972 2.2864 7.36059 2.59652 7.41277ZM4 24.0672V9.62706C4.66444 9.70087 5.36828 9.75665 6.05027 9.77428C6.71362 9.79144 7.40685 9.77409 8.04707 9.68586C8.60754 9.60862 9.33493 9.45174 9.96892 9.05909C11.6649 8.2867 12.6909 7.9675 13.4057 7.87151C14.0163 7.78951 14.4458 7.86232 15 8.08279V21.8654C13.052 21.5117 10.3394 21.7758 7.78693 23.7124C7.31743 24.0686 6.48603 24.303 5.45404 24.2789C4.88575 24.2656 4.38622 24.1772 4 24.0672ZM17 21.8695C18.8506 21.5128 21.4523 21.6957 24.1832 23.6908C24.6456 24.0287 25.3839 24.2155 26.3673 24.1692C26.9335 24.1426 27.4989 24.0414 28 23.9072V10.3487C26.2979 10.6386 24.1971 10.3892 21.9506 9.00905C20.5556 8.15202 19.5054 7.87207 18.7534 7.81389C18.0313 7.75802 17.4668 7.89731 17 8.08299V21.8695Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

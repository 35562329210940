import { createRequestActionTypes } from '../../../../../app/store/request/request.actions';

export const FETCH_DEMO_PROFILES = 'FETCH_DEMO_PROFILES';
export const FETCH_DEMO_PROFILES_REQUEST =
  createRequestActionTypes(FETCH_DEMO_PROFILES);

export const EDIT_DEMO_PROFILES = 'EDIT_DEMO_PROFILES';
export const EDIT_DEMO_PATIENT_PROFILES_REQUEST =
  createRequestActionTypes(EDIT_DEMO_PROFILES);

export const FETCH_ASSIGNED_DEMO_PROFILES = 'FETCH_ASSIGNED_DEMO_PROFILES';
export const FETCH_ASSIGNED_DEMO_PATIENT_PROFILES_REQUEST =
  createRequestActionTypes(FETCH_ASSIGNED_DEMO_PROFILES);

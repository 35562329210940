import styled from 'styled-components';
import { top } from 'styled-system';

import { spacing } from 'src/app/styles/spacing';

export const GraphSettingsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.isLogbook ? 'row-reverse' : 'row')};
  margin-left: ${spacing.four};
  align-items: center;
  width: '100%';
  position: absolute;
  z-index: ${(props) => (props.isLogbook ? 10000 : 'unset')};
  ${top};
`;
GraphSettingsWrapper.displayName = 'GraphSettingsWrapper';

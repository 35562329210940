import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const ThresholdLoaderImpl = (
  { patientId }: any,
  accessToken: string,
) => {
  return getJSON(endpointWithParams(ENDPOINTS.thresholds, { patientId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  });
};

export const ThresholdTransformImpl = (results) => {
  const model = results.model;
  return {
    actualHyper: {
      postIdealInterval: model.postHyper,
      noctIdealInterval: model.noctHyper,
      preIdealInterval: model.preHyper,
    },
    afterEating: {
      actualHyper: model.postHyper,
      hyper: model.postHigh,
      hypo: model.postHipo,
      warning: model.postLow,
    },
    beforeBed: {
      actualHyper: model.noctHyper,
      hyper: model.noctHigh,
      hypo: model.noctHipo,
      warning: model.noctLow,
    },
    beforeEating: {
      actualHyper: model.preHyper,
      hyper: model.preHigh,
      hypo: model.preHipo,
      warning: model.preLow,
    },
    hyper: {
      postIdealInterval: model.postHigh,
      noctIdealInterval: model.noctHigh,
      preIdealInterval: model.preHigh,
    },
    hypo: {
      postIdealInterval: model.postHipo,
      noctIdealInterval: model.noctHipo,
      preIdealInterval: model.preHipo,
    },
    warning: {
      postIdealInterval: model.postLow,
      noctIdealInterval: model.noctLow,
      preIdealInterval: model.preLow,
    },
  };
};

export const ThresholdServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);

import {
  CLEAR_IS_WHATS_NEW_VIEWED,
  IS_WHATS_NEW_VIEWED,
} from './whats-new.constants';

type WhatsNewState = boolean;

type WhatsNewAction =
  | { type: typeof IS_WHATS_NEW_VIEWED }
  | { type: typeof CLEAR_IS_WHATS_NEW_VIEWED };

const actionHandlers = {
  [IS_WHATS_NEW_VIEWED]: () => true,
  [CLEAR_IS_WHATS_NEW_VIEWED]: () => false,
};

export const whatsNewReducer = (
  state: WhatsNewState = false,
  action: WhatsNewAction,
): WhatsNewState => {
  const handler = actionHandlers[action.type];
  return handler ? handler() : state;
};

import { GRAPHS as G } from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { EDIT_PATIENT } from 'src/domains/patient/scenes/edit-patient-jello/edit_patient.constants';

export const ID = 'additionalInfo';
export const BTN = '-btn';
export const PREFIX = 'modals.additionalInfo.';
export const TITLE = 'title';
export const CONTENT = 'content';
export const CONTENT_ACC = 'content_ACC';
export const SECTIONS = 'sections';
export const ITEM = 'item';
export const CARD = 'card';

export const GRAPHS = { ...G };

export const INFOS = {
  hba1c: 'hba1c',
  graphReports: 'graphReports',
  glucoseLvlDistrib: 'glucoseLvlDistrib',
  bgStatistics: 'bgStatistics',
  dailyGlucoseProfile: 'dailyGlucoseProfile',
  dailyGlucoseProfileGraphs: 'dailyGlucoseProfileGraphs',
  status: 'status2',
  hypoglycaemiaBgCard: 'hypoglycaemiaBgCard',
  variability: 'variability',
  cgmStatistics: 'cgmStatistics',
  timeInRanges: 'timeInRanges',
  agp: 'agp',
  homeDelivery: 'homeDelivery',
  patientStrip: 'patientStrip',
  stockDetails: 'stockDetails',
  patterns: 'patterns',
  targetRanges: 'targetRanges',
  rpmSettings: 'rpmSettings',
  timeBlocks: 'timeBlocks',
  defaultTargetRanges: 'defaultTargetRanges',
  unitsMeasurement: 'unitsMeasurement',
  gettingStarted: 'gettingStarted',
  editPatient: 'editPatient',
  deviceSettings: 'deviceSettings',
  createPatient: 'createPatient',
  cgmSensor: 'cgmSensor',
};

export const USERS = {
  HCP_MASTER: 'HCP_MASTER',
  GENERAL_PRACTITIONER: 'GENERAL_PRACTITIONER',
  ADMINISTRATIVE: 'ADMINISTRATIVE',
  PAYER: 'PAYER',
};

export const CARD_TYPES = {
  note: 'note',
  warning: 'warning',
  precaution: 'precaution',
};

export const EXPANDED_GLUCOSE_PROFILE = {
  overview: 'overview',
  dailyGlucoseProfile: 'dailyGlucoseProfile',
  contextualData: 'contextualData',
};

export const HOME_DELIVERY = {
  status: 'status',
  configuration: 'configuration',
};

export const PATIENT_STRIP = {
  overview: 'overview',
  delivery: 'delivery',
  prescription: 'prescription',
  alerts: 'alerts',
};

export const PATTERNS = {
  overview: 'overview',
  initialSetup: 'initialSetup',
  configurePatterns: 'configurePatterns',
  viewPatterns: 'viewPatterns',
};

export const RPM_SETTINGS = {
  overview: 'overview',
  settings: 'settings',
  enroll: 'enroll',
};

export const GETTING_STARTED = {
  aboutPlatform: 'aboutPlatform',
  navigatePlatform: 'navigatePlatform',
  homeScreen: 'homeScreen',
  viewPatient: 'viewPatient',
  uploadData: 'uploadData',
  visualizeHealthData: 'visualizeHealthData',
  printDownloadReports: 'printDownloadReports',
  remotePatientMonitoring: 'remotePatientMonitoring',
  electronicMedicalRecord: 'electronicMedicalRecord',
  patientCareTracking: 'patientCareTracking',
};

export const CONTENTS = {
  [INFOS.hba1c]: { cards: [CARD_TYPES.note] },
  [INFOS.graphReports]: {
    [USERS.HCP_MASTER]: {
      sections: [
        { info: GRAPHS.TREND },
        { info: GRAPHS.STANDARD_DAY },
        { info: GRAPHS.STANDARD_WEEK },
        { info: GRAPHS.LOGBOOK, cards: [CARD_TYPES.note, CARD_TYPES.note] },
        { info: GRAPHS.METABOLIC_RATE },
        { info: GRAPHS.INSULIN },
        { info: GRAPHS.INSULIN_PUMP },
      ],
    },
    sections: [
      { info: GRAPHS.TREND },
      { info: GRAPHS.STANDARD_DAY },
      { info: GRAPHS.LOGBOOK, cards: [CARD_TYPES.note, CARD_TYPES.note] },
    ],
  },
  [INFOS.glucoseLvlDistrib]: { cards: [CARD_TYPES.note] },
  [INFOS.bgStatistics]: { cards: [CARD_TYPES.note, CARD_TYPES.note] },
  [INFOS.dailyGlucoseProfile]: { cards: [CARD_TYPES.note] },
  [INFOS.dailyGlucoseProfileGraphs]: { cards: [CARD_TYPES.note] },
  [INFOS.status]: {},
  [INFOS.hypoglycaemiaBgCard]: { cards: [CARD_TYPES.note] },
  [INFOS.variability]: {},
  [INFOS.cgmStatistics]: { cards: [CARD_TYPES.note] },
  [INFOS.timeInRanges]: { cards: [CARD_TYPES.note] },
  [INFOS.agp]: { cards: [CARD_TYPES.note] },
  [INFOS.homeDelivery]: {
    sections: [
      { info: HOME_DELIVERY.status },
      {
        info: HOME_DELIVERY.configuration,
        cards: [CARD_TYPES.note, CARD_TYPES.note],
      },
    ],
  },
  [INFOS.patientStrip]: {
    [USERS.ADMINISTRATIVE]: {
      sections: [{ info: PATIENT_STRIP.delivery }],
    },
    sections: [
      { info: PATIENT_STRIP.delivery },
      { info: PATIENT_STRIP.prescription, cards: [CARD_TYPES.note] },
      { info: PATIENT_STRIP.alerts },
    ],
  },
  [INFOS.stockDetails]: {},
  [INFOS.patterns]: {
    sections: [
      {
        info: PATTERNS.overview,
        cards: [CARD_TYPES.note, CARD_TYPES.note],
      },
      {
        info: PATTERNS.initialSetup,
        cards: [CARD_TYPES.note],
      },
      {
        info: PATTERNS.configurePatterns,
      },
      {
        info: PATTERNS.viewPatterns,
        cards: [CARD_TYPES.note],
      },
    ],
  },
  [INFOS.targetRanges]: {},
  [INFOS.rpmSettings]: {
    sections: [
      {
        info: RPM_SETTINGS.settings,
        cards: [CARD_TYPES.note],
      },
      { info: RPM_SETTINGS.enroll },
    ],
  },
  [INFOS.timeBlocks]: {},
  [INFOS.defaultTargetRanges]: { cards: [CARD_TYPES.note] },
  [INFOS.unitsMeasurement]: {
    cards: [CARD_TYPES.note, CARD_TYPES.note],
  },
  [INFOS.gettingStarted]: {
    sections: [
      { info: GETTING_STARTED.aboutPlatform, hasACC: true },
      { info: GETTING_STARTED.navigatePlatform, cards: [CARD_TYPES.note] },
      { info: GETTING_STARTED.homeScreen },
      {
        info: GETTING_STARTED.viewPatient,
        hasACC: true,
        cards: [CARD_TYPES.note],
      },
      { info: GETTING_STARTED.visualizeHealthData, cards: [CARD_TYPES.note] },
      { info: GETTING_STARTED.printDownloadReports, cards: [CARD_TYPES.note] },
      {
        info: GETTING_STARTED.remotePatientMonitoring,
        cards: [CARD_TYPES.note],
      },
      {
        info: GETTING_STARTED.electronicMedicalRecord,
        hasACC: true,
        cards: [CARD_TYPES.note],
      },
      { info: GETTING_STARTED.patientCareTracking, cards: [CARD_TYPES.note] },
    ],
  },
  [INFOS.editPatient]: {
    [USERS.ADMINISTRATIVE]: {
      sections: [
        {
          info: EDIT_PATIENT.PROFILE_AND_SHARING_STATUS,
          cards: [CARD_TYPES.note],
        },
        { info: EDIT_PATIENT.DEVICES },
        {
          info: EDIT_PATIENT.CLINICAL_STUDY,
          conditionalSection: true,
          cards: [CARD_TYPES.note],
        },
      ],
    },
    [USERS.GENERAL_PRACTITIONER]: {
      sections: [
        {
          info: EDIT_PATIENT.PROFILE_AND_SHARING_STATUS,
          cards: [CARD_TYPES.note],
        },
        { info: EDIT_PATIENT.TIME_BLOCKS },
        { info: EDIT_PATIENT.GLUCOSE_TARGET_RANGES },
        { info: EDIT_PATIENT.DEVICES },
        {
          info: EDIT_PATIENT.CLINICAL_STUDY,
          conditionalSection: true,
          cards: [CARD_TYPES.note],
        },
      ],
    },
    sections: [
      {
        info: EDIT_PATIENT.PROFILE_AND_SHARING_STATUS,
        cards: [CARD_TYPES.note],
      },
      { info: EDIT_PATIENT.TIME_BLOCKS },
      { info: EDIT_PATIENT.GLUCOSE_TARGET_RANGES },
      { info: EDIT_PATIENT.DEVICES },
      {
        info: EDIT_PATIENT.CLINICAL_STUDY,
        conditionalSection: true,
        cards: [CARD_TYPES.note],
      },
      { info: EDIT_PATIENT.DEACTIVATE_PATIENT },
    ],
  },
  [INFOS.deviceSettings]: {},
  [INFOS.createPatient]: {},
  [INFOS.cgmSensor]: {},
};

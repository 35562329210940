// function that checks if there's more than x hours gap between dates
import { diffHours } from 'src/shared/utils/date';
import { HOURS_IN_DAY } from 'src/domains/diagnostics/scenes/graphs/template/standard-day-detail/standard-day-detail.constant';

export const hourGapNotGreaterThanXHours = (a, b, numHours) => {
  const diff = diffHours(b, a);
  const diffValue = diff.toObject().hours || /* istanbul ignore next */ 0;
  const hourGapNotGreaterThanX = Math.abs(diffValue) <= numHours;

  return hourGapNotGreaterThanX;
};

export const adjustValueByDay = (value) =>
  value < 0 ? value + HOURS_IN_DAY : value;

export const filterMainPoints = (points) => {
  const newPoints = points.filter(
    (x) =>
      x?.data?.value !== 0 &&
      /* istanbul ignore next */ x?.data?.value !== null,
  );
  return newPoints ? newPoints : [];
};

import { createRequestActions } from '../../../app/store/request/request.actions';
import {
  CLOSE_WHATS_NEW_MODAL,
  FETCH_USER_ANNOUNCEMENTS_REQUEST,
  MARK_ANNOUNCEMENT_VISITED_REQUEST,
  OPEN_WHATS_NEW_MODAL,
} from './announcements.constants';
import { UserAnnouncementNotificationState } from './announcements.types';

export const fetchUserAnnouncements = createRequestActions(
  FETCH_USER_ANNOUNCEMENTS_REQUEST,
);

export const markAnnouncementVisited = createRequestActions(
  MARK_ANNOUNCEMENT_VISITED_REQUEST,
);

export const openWhatsNewModal = (
  payload: UserAnnouncementNotificationState,
) => ({
  payload,
  type: OPEN_WHATS_NEW_MODAL,
});

export const closeModal = () => ({
  type: CLOSE_WHATS_NEW_MODAL,
});

import React from 'react';
import { StickyContainer } from 'react-sticky';
import { PatientDataBar } from 'src/widgets/top-patient-bar/patient-data-bar.component';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { CardMinimizer } from 'src/shared/design-system/card/card-minimizer/card-minimizer.component';
import { GridItem } from 'src/domains/diagnostics/components/grid-layout/grid-layout';
import { BG_UNIT_KEYS } from 'src/domains/diagnostics/store/constants';
import { colors } from 'src/app/styles/colors';
import { fontSize } from 'src/app/styles/font-sizes';
import { Pager } from 'src/domains/diagnostics/components/pager/pager.component';
import { CardWrapper } from 'src/domains/diagnostics/scenes/graphs/graph.style';
import { convertPxToRem } from 'src/domains/diagnostics/utils/rem-calc';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import { formatBGMeasurement } from 'src/domains/diagnostics/utils/measurements';

import { makeDescIntervals } from 'src/domains/diagnostics/utils/time.util';
import {
  formatMonthlyInterval,
  formatQuarterlyInterval,
  formatWeeklyInterval,
} from './store/blood-glucose-overview.utils';

import { PERMISSIONS } from 'src/domains/permissions/store/permissions.constants';

import { BloodGlucoseOverviewTable } from './components/blood-glucose-overview-table/blood-glucose-overview-table.component';
import { BloodGlucoseOverviewTableRow } from './components/blood-glucose-overview-table-row/blood-glucose-overview-table-row.component';
import { IntervalsHeader } from './components/intervals-header/intervals-header.component';
import {
  BloodGlucoseOverviewFlexibleHeightCard,
  BloodGlucoseOverviewMainCardHeader,
  BloodGlucoseOverviewSubCard,
  ButtonLayer,
  ButtonsWrapper,
  CardHeader,
} from './blood-glucose-overview.style';
import { FilterBar } from './components/filter-bar/filter-bar.component';
import { TrafficLight } from './components/traffic-light/traffic-light.component';
import {
  NUMBER_BLOOD_GLUCOSE_OVERVIEW_COLUMNS,
  TRAFFIC_LIGHT_LABELS,
} from './store/blood-glucose-overview.constants';
import { InsufficientDataTooltip } from './components/insufficient-data-tooltip/insufficient-data-tooltip.component';
import { AdditionalInfo } from 'src/domains/diagnostics/components/additional-info/additional-info.container';
import { INFOS } from 'src/domains/diagnostics/components/additional-info/additional-info.constants';
import { TIME_INTERVAL } from 'src/domains/diagnostics/constants/diagnostics.constants';

import { additionalInfoActivated } from 'src/domains/diagnostics/components/additional-info/additional.info.utils';
import { AdditionalInformationButtonContainer as AdditionalInformationButton } from '../../components/additional-information/additional-information.container';
import { logbookDisclaimersLastUpdated } from '../../components/additional-information/additional-information.constant';
import { disclaimers } from './blood-glucose-overview.container';
import { INFO_MISSING_PERIODS } from './blood-glucose-overview.constants';
import { selectJelloPermission } from 'src/domains/permissions/store/permissions.selectors';
import { withJelloWrapper } from 'src/domains/patient/utils/with-jello-wrapper';
import { testId } from '@roche/roche-common';

import {
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
} from 'src/app/app.jello.constants';
import { RenderIfElse } from 'src/shared/utils/render-if';

const renderBGStatusCell = (data) => {
  if (!data) {
    return data;
  }
  const { color, label } = data;
  return (
    <TrafficLight
      color={color}
      emptyInnerCircle={label === TRAFFIC_LIGHT_LABELS.INSUFFICIENT_INFO}
      label={label}
    >
      <RenderIf validate={label === TRAFFIC_LIGHT_LABELS.INSUFFICIENT_INFO}>
        <InsufficientDataTooltip />
      </RenderIf>
    </TrafficLight>
  );
};

const renderBGStatisticsCell = (data) => data;
const renderBGMeasurementStatisticsCell =
  (translate) => (bloodGlucoseUnit) => (data) =>
    translate(formatBGMeasurement(bloodGlucoseUnit)(data));

const isValidDateRangeItem = (item) => Array.isArray(item) && item.length === 2;

const emptyListObject1 = ['-', '-', '-', '-', '-', '-'];
let translatedDateRanges = [];

const fillEmptyDataList = () => {
  return Array.from({ length: NUMBER_BLOOD_GLUCOSE_OVERVIEW_COLUMNS }).fill({
    color: 'GREY',
    label: '-',
  });
};

const noBgDataCheck = (interval, endDate) => {
  if (!endDate.isValid) {
    return [];
  }

  const intervals = makeDescIntervals(
    endDate,
    interval,
    NUMBER_BLOOD_GLUCOSE_OVERVIEW_COLUMNS,
  );

  const intervalTransformers = {
    [TIME_INTERVAL.QUARTERLY_INTERVALS]: formatQuarterlyInterval,
    [TIME_INTERVAL.MONTHLY_INTERVALS]: formatMonthlyInterval,
    [TIME_INTERVAL.WEEKLY_INTERVALS]: formatWeeklyInterval,
  };

  const infoMissingIntervals = {
    [TIME_INTERVAL.QUARTERLY_INTERVALS]: INFO_MISSING_PERIODS.quarterly,
    [TIME_INTERVAL.MONTHLY_INTERVALS]: INFO_MISSING_PERIODS.oneMonth,
    [TIME_INTERVAL.WEEKLY_INTERVALS]: INFO_MISSING_PERIODS.sevenDays,
  };

  const groupedIntervals = intervals.map((currentInterval) =>
    intervalTransformers[interval](currentInterval),
  );
  const noDateObj = groupedIntervals.map((currentInterval) => {
    return {
      info: infoMissingIntervals[interval],
      label: {
        top: currentInterval.top,
        bottom: currentInterval.bottom,
      },
    };
  });

  return noDateObj.reverse();
};

let hadData = false;
let hasNoData = false;
let hasVisitManagement = false;
let patientHasEtapes = false;
let patientHasRPM = false;
let patientIsEnrolled = false;
let visitManagementPresent = false;

const hasNoDataValueCheck = (
  hypoRisk,
  statusMeanBloodGlucose,
  variability,
  endDate,
) =>
  isEmpty(hypoRisk) &&
  isEmpty(statusMeanBloodGlucose) &&
  isEmpty(variability) &&
  isNaN(endDate);

const authorizationCheck = (
  hypoRisk,
  statusMeanBloodGlucose,
  variability,
  endDate,
  hcpPermissions,
  patientPermissions,
) => {
  hasNoData = hasNoDataValueCheck(
    hypoRisk,
    statusMeanBloodGlucose,
    variability,
    endDate,
  );
  hasVisitManagement =
    hcpPermissions && hcpPermissions.includes(PERMISSIONS.VISIT_MANAGEMENT);
  patientHasEtapes =
    patientPermissions &&
    patientPermissions.includes(PERMISSIONS.ETAPES_PROGRAM);
  patientHasRPM = patientPermissions.includes(PERMISSIONS.RPM_PROGRAM);
  patientIsEnrolled = patientHasEtapes || patientHasRPM;
  visitManagementPresent = hasVisitManagement && patientIsEnrolled;
};

const hypoRiskValueCheck = (hypoRisk) =>
  isEmpty(hypoRisk) ? fillEmptyDataList() : hypoRisk;

const statusMeanBloodGlucoseValueCheck = (statusMeanBloodGlucose) =>
  isEmpty(statusMeanBloodGlucose)
    ? fillEmptyDataList()
    : statusMeanBloodGlucose;

const variabilityValueCheck = (variability) =>
  isEmpty(variability) ? fillEmptyDataList() : variability;

const statisticsMeanBloodGlucoseValueCheck = (
  statisticsMeanBloodGlucose,
  dateRanges,
) =>
  hasNoData || dateRanges.length === 0
    ? emptyListObject1
    : statisticsMeanBloodGlucose;

const testsPerDayValueCheck = (testsPerDay, dateRanges) =>
  hasNoData || dateRanges.length === 0 ? emptyListObject1 : testsPerDay;

const hypoglycaemiaValueCheck = (hypoglycaemia, dateRanges) =>
  hasNoData || dateRanges.length === 0 ? emptyListObject1 : hypoglycaemia;

const translateDateRanges = (dateRanges, t) => {
  return dateRanges.map((item) => ({
    ...item,
    info: isValidDateRangeItem(item.info)
      ? `${item.info[0]} ${t(item.info[1])}.`
      : '',
  }));
};

const updateHadDataStatus = (hasNoData, dateRangesLength) => {
  if (hasNoData) {
    hadData = false;
  }

  if (dateRangesLength > 0) {
    hadData = true;
  }
};

export const BloodGlucoseOverviewComponent = ({
  patientPermissions,
  hcpPermissions,
  bloodGlucoseUnit,
  bloodGlucoseOverview: {
    bgStatus: {
      hypoRisk = [],
      meanBloodGlucose: statusMeanBloodGlucose,
      variability,
    },
    bgStatistics: {
      meanBloodGlucose: statisticsMeanBloodGlucose,
      testsPerDay,
      hypoglycaemia = [],
    },
    dateRanges = [],
  },
  endDate,
  match,
  onChangeInterval,
  onClickPager,
  isThereNextInterval,
  isTherePrevInterval,
  t,
  thresholds: { hypoglycemiaThreshold },
  interval = TIME_INTERVAL.WEEKLY_INTERVALS,
}) => {
  translatedDateRanges = translateDateRanges(dateRanges, t);
  updateHadDataStatus(hasNoData, translatedDateRanges.length);
  if (translatedDateRanges.length === 0 && !hadData) {
    isThereNextInterval = true;
    isTherePrevInterval = true;
  }
  const margin = '0 1rem';
  authorizationCheck(
    hypoRisk,
    statusMeanBloodGlucose,
    variability,
    endDate,
    hcpPermissions,
    patientPermissions,
  );
  hypoRisk = hypoRiskValueCheck(hypoRisk);
  statusMeanBloodGlucose = statusMeanBloodGlucoseValueCheck(
    statusMeanBloodGlucose,
  );
  variability = variabilityValueCheck(variability);
  statisticsMeanBloodGlucose = statisticsMeanBloodGlucoseValueCheck(
    statisticsMeanBloodGlucose,
    translatedDateRanges,
  );
  testsPerDay = testsPerDayValueCheck(testsPerDay, translatedDateRanges);
  hypoglycaemia = hypoglycaemiaValueCheck(hypoglycaemia, translatedDateRanges);
  if (translatedDateRanges.length === 0) {
    translatedDateRanges = noBgDataCheck(interval, endDate);
  }
  return (
    <StickyContainer {...testId('blood-glucose', 'main-container')}>
      <GridItem marginBottom>
        <GridItem marginTop={'0'} span="12" style={{ margin }}>
          <CardWrapper>
            <BloodGlucoseOverviewFlexibleHeightCard>
              <BloodGlucoseOverviewMainCardHeader>
                <CardHeader>
                  <jello-text
                    weight={JELLO_TEXT_WEIGHTS.BOLD}
                    size={JELLO_TEXT_SIZES.M}
                    style={{ padding: '1rem 2rem' }}
                  >
                    {t('dashboard.statusCardTitle')}
                  </jello-text>
                  <ButtonsWrapper>
                    <ButtonLayer>
                      {additionalInfoActivated() ? (
                        <AdditionalInfo info={INFOS.status} />
                      ) : (
                        <AdditionalInformationButton
                          content={disclaimers}
                          updated={logbookDisclaimersLastUpdated}
                        />
                      )}
                    </ButtonLayer>
                    <CardMinimizer link={`/patients/${match.params.id}`} />
                  </ButtonsWrapper>
                </CardHeader>
                <Pager
                  onClickPager={onClickPager}
                  isDisabledPrev={isTherePrevInterval}
                  isDisabledSuperPrev={isTherePrevInterval}
                  isDisabledNext={isThereNextInterval}
                  isDisabledSuperNext={isThereNextInterval}
                >
                  <IntervalsHeader intervals={translatedDateRanges} />
                </Pager>
              </BloodGlucoseOverviewMainCardHeader>
              <BloodGlucoseOverviewSubCard>
                <BloodGlucoseOverviewTable
                  title={t('bloodGlucoseOverview.bgStatus')}
                >
                  <BloodGlucoseOverviewTableRow
                    borderBottom
                    renderCell={renderBGStatusCell}
                    data={hypoRisk}
                    height={convertPxToRem(125)}
                    width={convertPxToRem(145)}
                    bodyFontSize={fontSize.caption}
                    rowNumber={1}
                    titleLines={[t('bloodGlucoseOverview.hypoRisk')]}
                  />
                  <BloodGlucoseOverviewTableRow
                    borderBottom
                    renderCell={renderBGStatusCell}
                    data={statusMeanBloodGlucose}
                    height={convertPxToRem(125)}
                    width={convertPxToRem(145)}
                    bodyFontSize={fontSize.caption}
                    rowNumber={2}
                    titleLines={[t('bloodGlucoseOverview.meanBloodGlucose')]}
                  />
                  <BloodGlucoseOverviewTableRow
                    borderBottom
                    renderCell={renderBGStatusCell}
                    data={variability}
                    height={convertPxToRem(125)}
                    width={convertPxToRem(145)}
                    bodyFontSize={fontSize.caption}
                    rowNumber={3}
                    titleLines={[t('bloodGlucoseOverview.variability')]}
                  />
                </BloodGlucoseOverviewTable>
              </BloodGlucoseOverviewSubCard>
              <BloodGlucoseOverviewSubCard>
                <BloodGlucoseOverviewTable
                  title={t('bloodGlucoseOverview.bgStatistics')}
                >
                  <BloodGlucoseOverviewTableRow
                    borderBottom
                    renderCell={renderBGMeasurementStatisticsCell(t)(
                      bloodGlucoseUnit,
                    )}
                    data={statisticsMeanBloodGlucose}
                    height={convertPxToRem(75)}
                    width={convertPxToRem(145)}
                    bodyFontSize={fontSize.subheading}
                    rowNumber={4}
                    titleLines={[
                      t('bloodGlucoseOverview.meanBloodGlucose'),
                      `(${t(BG_UNIT_KEYS[bloodGlucoseUnit])})`,
                    ]}
                  />
                  <BloodGlucoseOverviewTableRow
                    borderBottom
                    renderCell={renderBGStatisticsCell}
                    data={testsPerDay}
                    height={convertPxToRem(75)}
                    width={convertPxToRem(145)}
                    bodyFontSize={fontSize.subheading}
                    rowNumber={5}
                    titleLines={[t('bloodGlucoseOverview.testsPerDay')]}
                  />
                  <BloodGlucoseOverviewTableRow
                    borderBottom={{ size: '0.125rem', thick: true }}
                    renderCell={renderBGStatisticsCell}
                    data={hypoglycaemia}
                    height={convertPxToRem(75)}
                    width={convertPxToRem(145)}
                    bodyFontSize={fontSize.subheading}
                    rowNumber={6}
                    titleLines={[
                      `${t('bloodGlucoseOverview.hypoglycaemia')}`,
                      `(<${hypoglycemiaThreshold} ${t(
                        BG_UNIT_KEYS[bloodGlucoseUnit],
                      )})`,
                    ]}
                    textColor={colors.red}
                  />
                </BloodGlucoseOverviewTable>
              </BloodGlucoseOverviewSubCard>
            </BloodGlucoseOverviewFlexibleHeightCard>
          </CardWrapper>
        </GridItem>
      </GridItem>
    </StickyContainer>
  );
};

const mapStateToProps = (state, ownProps) => ({
  hcpPermissions: state.permissions.permissions,
  patientPermissions: state.patientPermissions.patientPermissions,
  ...ownProps,
});

export const BloodGlucoseOverview = connect(mapStateToProps)(
  withTranslation(
    withJelloWrapper
      ? withJelloWrapper(BloodGlucoseOverviewComponent)
      : BloodGlucoseOverviewComponent,
  ),
);

export const mockThresholds = {
  resultDescription: 'getThresholdOK',
  model: {
    noctLow: '80',
    preLow: '80',
    postHyper: '240',
    noctHyper: '240',
    preHipo: '70',
    unit: 'mg/dL',
    preHigh: '125',
    postHipo: '70',
    postLow: '80',
    preHyper: '240',
    postHigh: '95',
    noctHipo: '70',
    noctHigh: '95',
  },
};

import { match } from 'ramda';
import {
  createAuthHeader,
  putJSON,
  stringifyBody,
} from 'src/shared/utils/service/service.utils';
import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

import {
  EditPatientParams,
  EditPatientResponse,
  TransformedEditPatientSuccessResponse,
} from './edit-patient.types';

export const EditPatientLoaderImpl = (
  { patientId, patientDto }: EditPatientParams,
  accessToken: string,
) =>
  putJSON(endpointWithParams(ENDPOINTS.patientV2, { patientId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
    body: stringifyBody(patientDto),
  });

export const EditPatientTransformImpl = ({
  model,
}: EditPatientResponse): TransformedEditPatientSuccessResponse => ({
  patientId: Number(match(/patients\/(.*)/, model)[1]),
});

export const EditPatientServiceImpl =
  (load, transform) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);

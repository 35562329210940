import React, { useEffect, useState } from 'react';
import { pick } from 'ramda';
import { connect } from 'react-redux';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import {
  JELLO_ICON_BUTTON_SIZES,
  JELLO_ICON_BUTTON_VARIANTS,
  JELLO_ICON_NAMES,
} from 'src/app/app.jello.constants';
import styles from './styles.module.css';
import { VisitTimeTrackingComponent } from './visit-time-tracking-jello.component';
import { createSelector } from 'reselect';
import {
  selectPatient,
  selectPatientId,
} from 'src/domains/patient/store/patient/patient.selector';
import {
  selectIsPatientSessionRunning,
  selectPatientSessionEndStatus,
  selectPatientSessionTime,
  selectPatientVisitId,
} from 'src/app/navigation/store/patient-session/patient-session.selectors';
import {
  resetIsRunningAction,
  resetPatientSessionAction,
  resetSessionStatusAction,
  savePatientSessionStartAction,
} from 'src/app/navigation/store/patient-session/patient-session.actions';
import {
  selectHasVideocall,
  selectIsRPMEnrolled,
  selectIsTimeTrackerEnrolled,
  selectPatientPermissions,
  selectPermissions,
} from 'src/domains/permissions/store/permissions.selectors';
import { VisitModuleActiveSection } from 'src/domains/patient/utils/visit-module-active-section';
import { t } from 'i18next';
import { hasVisitModulePermission } from 'src/app/modules/visit-module/visit-module-permissions';
import { DocumentsExportSectionComponent } from 'src/domains/patient-data-bar/patient-actions-jello/documents-export-section.component';

const selectPatientInfo: any = createSelector(
  [selectPatient],
  pick([
    'firstName',
    'surName',
    'dateOfBirth',
    'diabetesTypeEC6',
    'healthCareSystemId',
    'diagnosticDate',
  ]),
);
const mapStateToProps = (state, props) => ({
  isRPMEnrolled: selectIsRPMEnrolled(state),
  isTimeTrackerEnrolled: selectIsTimeTrackerEnrolled(state),
  isPatientSessionRunning: selectIsPatientSessionRunning(state),
  patientSessionTime: selectPatientSessionTime(state),
  patient: selectPatientInfo(state),
  sessionEndStatus: selectPatientSessionEndStatus(state),
  patientId: selectPatientId(state),
  visitId: selectPatientVisitId(state),
  hcpPermissions: selectPermissions(state),
  patientPermissions: selectPatientPermissions(state),
  showVideocallButton: selectHasVideocall(state),
});

const mapDispatchToProps = mapDispatchers({
  resetPatientSession: resetPatientSessionAction,
  resetSessionEndStatus: resetSessionStatusAction,
  endSessionStart: savePatientSessionStartAction,
  resetIsRunning: resetIsRunningAction,
});

export const PatientActionsComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  interface UnreadEvent extends Event {
    detail: {
      unread: boolean;
    };
  }
  const [isUnread, setIsUnread] = useState(false);

  useEffect(() => {
    window.addEventListener(
      'vc-module-unread-messages-event',
      (data: UnreadEvent) => {
        setIsUnread(data.detail.unread);
      },
    );
  });

  const onClickNotesButton = () =>
    props.visitModuleActiveSection !== VisitModuleActiveSection.NOTES
      ? props.setVisitModuleActiveSection(VisitModuleActiveSection.NOTES)
      : props.setVisitModuleActiveSection(VisitModuleActiveSection.CLOSE);

  const onClickVideocallButton = () =>
    props.visitModuleActiveSection !== VisitModuleActiveSection.VIDEOCALL
      ? props.setVisitModuleActiveSection(VisitModuleActiveSection.VIDEOCALL)
      : props.setVisitModuleActiveSection(VisitModuleActiveSection.CLOSE);

  return (
    <>
      <VisitTimeTrackingComponent
        isRPMEnrolled={props.isRPMEnrolled}
        isTimeTrackerEnrolled={props.isTimeTrackerEnrolled}
        isPatientSessionRunning={props.isPatientSessionRunning}
        resetPatientSession={props.resetPatientSession}
        resetSessionEndStatus={props.resetSessionEndStatus}
        patientSessionTime={props.patientSessionTime}
        patient={props.patient}
        sessionEndStatus={props.sessionEndStatus}
        endSessionStart={props.endSessionStart}
        patientId={props.patientId}
        visitId={props.visitId}
        resetIsRunning={props.resetIsRunning}
      />
      <DocumentsExportSectionComponent />
      {hasVisitModulePermission(props.hcpPermissions) && (
        <section
          className={styles.buttonsSection}
          data-testid="patient-actions-buttons-section"
        >
          <jello-tooltip
            role="tooltip"
            title="Notes & reports button"
            label={t('patientBar.notesTooltipText')}
          >
            <jello-icon-button
              data-testid="visit-notes-button"
              size={JELLO_ICON_BUTTON_SIZES.L}
              variant={JELLO_ICON_BUTTON_VARIANTS.TERTIARY}
              icon-name={JELLO_ICON_NAMES.NOTE}
              onClick={onClickNotesButton}
              accessibility-text="Notes & reports button"
              {...(isUnread && { notification: ' ' })}
            />
          </jello-tooltip>
          <jello-tooltip
            role="tooltip"
            title="Video consultation button"
            label={t('patientBar.videocallTooltipText')}
          >
            {props.showVideocallButton && (
              <jello-icon-button
                data-testid="visit-videocall-button"
                size={JELLO_ICON_BUTTON_SIZES.L}
                variant={JELLO_ICON_BUTTON_VARIANTS.TERTIARY}
                icon-name={JELLO_ICON_NAMES.VIDEO}
                onClick={onClickVideocallButton}
                accessibility-text="Video consultation button"
              />
            )}
          </jello-tooltip>
        </section>
      )}
    </>
  );
});

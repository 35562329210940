import {
  UnblindPatientActions,
  UnblindPatientState,
} from 'src/domains/patient/scenes/clinical-studies/store/interventional-clinical-studies/unblind-clinical-study-patient.types';
import { UnblindPatientActionType } from './unblind-clinical-study-patient.types';

export const unblindClinicalStudyPatientInitialState: UnblindPatientState = {
  isUnblindingPatient: false,
  unblindPatientSuccessful: false,
  unblindPatientError: false,
};

export const unblindClinicalStudyPatientReducer = (
  state = unblindClinicalStudyPatientInitialState,
  action: UnblindPatientActions,
): UnblindPatientState => {
  switch (action.type) {
    case UnblindPatientActionType.UNBLIND_PATIENT_START:
      return {
        ...state,
        isUnblindingPatient: true,
        unblindPatientSuccessful: false,
        unblindPatientError: false,
      };
    case UnblindPatientActionType.UNBLIND_PATIENT_SUCCESS:
      return {
        ...state,
        isUnblindingPatient: false,
        unblindPatientSuccessful: true,
        unblindPatientError: false,
      };
    case UnblindPatientActionType.UNBLIND_PATIENT_ERROR:
      return {
        ...state,
        isUnblindingPatient: false,
        unblindPatientSuccessful: false,
        unblindPatientError: true,
      };
    case UnblindPatientActionType.CLEAR_UNBLIND_PATIENT_STATE:
      return unblindClinicalStudyPatientInitialState;

    default:
      return state;
  }
};

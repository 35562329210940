import { createAction, createPayloadAction } from 'src/app/store/app.actions';

import {
  ClearCreatePatientWithFhirStateAction,
  CreatePatientWithFhirActionType,
  CreatePatientWithFhirErrorAction,
  CreatePatientWithFhirParams,
  CreatePatientWithFhirStartAction,
  CreatePatientWithFhirSuccessAction,
} from './create-patient-with-fhir.types';

export const createPatientWithFhirStart = (
  payload: CreatePatientWithFhirParams,
): CreatePatientWithFhirStartAction =>
  createPayloadAction(
    CreatePatientWithFhirActionType.CREATE_PATIENT_WITH_FHIR_START,
    payload,
  );

export const createPatientWithFhirSuccess =
  (): CreatePatientWithFhirSuccessAction =>
    createAction(
      CreatePatientWithFhirActionType.CREATE_PATIENT_WITH_FHIR_SUCCESS,
    );

export const createPatientWithFhirError =
  (): CreatePatientWithFhirErrorAction =>
    createAction(
      CreatePatientWithFhirActionType.CREATE_PATIENT_WITH_FHIR_ERROR,
    );

export const clearCreatePatientWithFhirState =
  (): ClearCreatePatientWithFhirStateAction =>
    createAction(
      CreatePatientWithFhirActionType.CLEAR_CREATE_PATIENT_WITH_FHIR_STATE,
    );

import React from 'react';
import { getConfig, testId } from '@roche/roche-common';

import styles from './styles.module.css';
import { IconTextLinkComponent } from 'src/widgets/top-navigation-jello/components/icon-text-link/icon-text-link.component';
import { InstructionsForUseIcon } from 'src/shared/design-system/icons';

import {
  JELLO_BUTTON_VARIANTS,
  JELLO_ICON_BUTTON_SIZES,
  JELLO_ICON_NAMES,
  JELLO_POPOVER_ALIGNMENTS,
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
  JELLO_TOOLTIP_POSITIONS,
} from 'src/app/app.jello.constants';
import { JelloIconButton } from 'src/shared/design-system/jello-icon-button/jello-icon-button.component';
import { JelloPopoverComponent } from 'src/app/navigation/scenes/jello-popover/jello-popover.component';
import { INFOS } from 'src/domains/diagnostics/components/additional-info/additional-info.constants';
import { AdditionalInfo } from 'src/domains/diagnostics/components/additional-info/additional-info.container';
import {
  countlyHandleListItemLink,
  getVersionIFUForLinkPDF,
} from 'src/widgets/top-navigation-jello/top-navigation-jello.utils';
import {
  HELP_ELEMENT_LIST,
  HELP_MENU_ID,
  HELP_MENU_LABEL,
  HELP_MENU_MODAL_BASE_ID,
} from 'src/widgets/top-navigation-jello/top-navigation-jello.constants';
import { t } from 'i18next';
import { HelpCenterProps } from 'src/widgets/top-navigation-jello/top-navigation-jello.types';
import { WhatsNewHelpMenu } from '../../../../domains/announcements/whats-new/components/whats-new-help-menu/whats-new-help-menu.container';

const {
  REACT_APP_PUBLIC_ASSETS_BASE_URL,
  REACT_APP_VERSION,
  REACT_APP_ACC_VERSION,
} = getConfig();

export const HelpCenterComponent: React.FunctionComponent<HelpCenterProps> = (
  props,
) => {
  const appVersion = getVersionIFUForLinkPDF({
    rebranding: props.rebranding,
    appVersion: REACT_APP_VERSION,
    appAccVersion: REACT_APP_ACC_VERSION,
  });

  const helpCenterElementList = HELP_ELEMENT_LIST.filter((item) => {
    return props.showWhatsNewMenu || !item.isWhatsNew;
  });

  const productVersion = props.rebranding
    ? `v${REACT_APP_ACC_VERSION}`
    : `v${REACT_APP_VERSION}`;

  const HEADER_TITLE_KEY = 'manufacturerInfo.product';

  return (
    <JelloPopoverComponent
      mainTestId={HELP_MENU_ID}
      accessibilityText={t(HELP_MENU_LABEL)}
      popoverAlignment={JELLO_POPOVER_ALIGNMENTS.CENTER}
      tooltipProps={{
        whitTooltip: true,
        tooltipPosition: JELLO_TOOLTIP_POSITIONS.BOTTOM,
        tooltipLabel: t(HELP_MENU_LABEL) || undefined,
      }}
      clickableComponent={{
        Component: JelloIconButton,
        iconName: JELLO_ICON_NAMES.HELP,
        size: JELLO_ICON_BUTTON_SIZES.L,
        variant: JELLO_BUTTON_VARIANTS.TERTIARY,
        accessibilityText: t(HELP_MENU_LABEL),
      }}
      popoverStyle={styles.popoverIndex}
    >
      <ul className={styles.list}>
        {helpCenterElementList.map((item) => (
          <li
            key={item.key}
            className={styles.liElement}
            onClick={() => countlyHandleListItemLink(item.countlySegmentValue)}
          >
            {item.isAdditionalInfo ? (
              <AdditionalInfo info={INFOS.gettingStarted}>
                <IconTextLinkComponent
                  iconName={item.iconName}
                  linkText={t(item.linkText)}
                />
              </AdditionalInfo>
            ) : item.onClickModal ? (
              <div
                onClick={() =>
                  props.createModal({
                    key: item.modalKey,
                    data: {
                      id: 'productInfo',
                      headerTitle: `${t(HEADER_TITLE_KEY)} ${productVersion}`,
                      showCloseButton: true,
                      closeOnAction: props.destroyModal,
                    },
                  })
                }
                {...testId(item.modalKey, HELP_MENU_MODAL_BASE_ID)}
              >
                <IconTextLinkComponent
                  iconName={item.iconName}
                  linkText={t(item.linkText)}
                />
              </div>
            ) : item?.isWhatsNew ? (
              <WhatsNewHelpMenu>
                <IconTextLinkComponent
                  iconName={item.iconName}
                  linkText={t(item.linkText)}
                />
              </WhatsNewHelpMenu>
            ) : (
              <jello-link
                class={styles.externalLink}
                href={`${item.link}`
                  .replace('{{locale}}', `${props.locale}`)
                  .replace('{{version}}', `${appVersion}`)
                  .replace(
                    '{{REACT_APP_PUBLIC_ASSETS_BASE_URL}}',
                    REACT_APP_PUBLIC_ASSETS_BASE_URL,
                  )}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.isIFU && (
                  <div className={styles.listItem}>
                    <InstructionsForUseIcon width={16} height={16} />
                    <jello-text
                      class={styles.listItemText}
                      weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
                      size={JELLO_TEXT_SIZES.S}
                    >
                      {t(item.linkText)}
                    </jello-text>
                  </div>
                )}
                {!item.isIFU && (
                  <IconTextLinkComponent
                    iconName={item.iconName}
                    linkText={t(item.linkText)}
                  />
                )}
              </jello-link>
            )}
          </li>
        ))}
      </ul>
    </JelloPopoverComponent>
  );
};

import { path, pipe } from 'ramda';
import { DEVICE_TYPE } from 'src/domains/device-assignment/services/device-assignment/get-device-association/get-device-association.types';
import { getCombinedRoutes } from 'src/app/navigation/store/navigation.selectors';

import {
  selectDeviceAssignmentDeviceInfo,
  selectDeviceAssignmentSelectedPatientId,
} from './device-assignment.selectors';

type RoutesMap = Map<string, any>;

export const constructDashboardUrlByResourceType = (state: any): string => {
  const { resourceType } = selectDeviceAssignmentDeviceInfo(state);
  const patientId = selectDeviceAssignmentSelectedPatientId(state);
  const routes: any = getCombinedRoutes(state);

  switch (resourceType) {
    case DEVICE_TYPE.Meter:
      return constructTransitionUrlToBG(patientId, routes);
    case DEVICE_TYPE.Cgm:
      return constructTransitionUrlToCGM(patientId, routes);
    default:
      return constructTransitionUrlToBG(patientId, routes);
  }
};

export const constructTransitionUrlToBG = (
  patientId: number,
  routes: RoutesMap,
): string =>
  pipe(
    selectPatientDashboardUrl,
    createPatientDashboardUrlByPatient(patientId),
  )(routes);

export const constructTransitionUrlToCGM = (
  patientId: number,
  routes: RoutesMap,
): string =>
  pipe(selectCGMUrl, createPatientDashboardUrlByPatient(patientId))(routes);

/*
 * route selectors
 */
export const selectPatientDashboardUrl = (routes: RoutesMap): string =>
  path(['dashboard', 'bgId'])(routes);

export const selectCGMUrl = (routes: RoutesMap): string =>
  path(['dashboard', 'cgmId'])(routes);

/*
 * final url builders
 */
export const createPatientDashboardUrlByPatient =
  (patientId: number) =>
  (url: string): string =>
    url.replace(':id', patientId.toString());

import { ANNOUNCEMENT } from '../whats-new.constants';

export const generateAnnouncementUrl = (
  baseUrl: string,
  productName: string,
  announcementType: string,
  version: string,
  announcementUuid: string,
  fileName: string,
): string => {
  const AnnouncementType = ANNOUNCEMENT;
  return `${baseUrl}/${productName}/${AnnouncementType}/${announcementType}/${version}/${announcementUuid}/${fileName}`;
};

export const getRebrandedSubHeader = (version: string, isRebranded: boolean) =>
  `${isRebranded ? '_ACC' : ''} ${version}`;

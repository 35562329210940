import { isNil } from 'ramda';
import React from 'react';

import { formatBGMeasurement } from 'src/domains/diagnostics/utils/measurements';

import {
  HandPointIconWrapper,
  LogbookCellBlockContentsBase,
  LogbookCellBlockContentsBG,
  LogbookCellBlockContentsHyper,
  LogbookCellBlockContentsHypo,
} from './logbook-cell-block-contents.style';

import { LOGBOOK_UNITS } from '../../../constants/logbook.constants';
import { HI_VALUE, LO_VALUE } from 'src/domains/diagnostics/store/constants';
import { getJelloIcon } from 'src/widgets/modal/components/manufacturer-info-modal/components/icons-view/icons-view-templates';
import { JELLO_ICON_NAMES } from 'src/app/setup-icons';
import { JELLO_ICON_SIZES } from 'src/app/app.jello.constants';

const renderBlockCellContent = ({
  aboveTargetRange,
  belowTargetOrHypoSymptom,
  formattedBG,
  handPointIcon,
  hypoSymptoms,
}) => {
  if (aboveTargetRange || formattedBG === HI_VALUE) {
    return (
      <LogbookCellBlockContentsHyper>
        {formattedBG} {handPointIcon}
      </LogbookCellBlockContentsHyper>
    );
  } else if (belowTargetOrHypoSymptom || formattedBG === LO_VALUE) {
    return (
      <LogbookCellBlockContentsHypo hypoSymptoms={hypoSymptoms}>
        {formattedBG} {handPointIcon}
      </LogbookCellBlockContentsHypo>
    );
  } else {
    return (
      <LogbookCellBlockContentsBG>
        {formattedBG} {handPointIcon}
      </LogbookCellBlockContentsBG>
    );
  }
};

export const LogbookCellBlockContents = ({
  unit,
  bloodGlucoseUnit,
  value,
  belowTargetRange,
  aboveTargetRange,
  hypoSymptoms,
  manuallyEntered,
  children,
}) => {
  if (unit !== LOGBOOK_UNITS.GLUCOSE || isNil(value)) {
    return (
      <LogbookCellBlockContentsBase>{children}</LogbookCellBlockContentsBase>
    );
  }
  const formattedBG = formatBGMeasurement(bloodGlucoseUnit)(value);
  const belowTargetOrHypoSymptom = belowTargetRange || hypoSymptoms;

  const handPointIcon = manuallyEntered && (
    <HandPointIconWrapper>
      {getJelloIcon(JELLO_ICON_NAMES.EDIT, JELLO_ICON_SIZES.XS)}
    </HandPointIconWrapper>
  );

  return renderBlockCellContent({
    aboveTargetRange,
    belowTargetOrHypoSymptom,
    formattedBG,
    handPointIcon,
    hypoSymptoms,
  });
};

import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';

import {
  Ec6UpdateAssociationResultDescription,
  UpdateDeviceAssociationServiceType,
  UpdateDeviceAssociationTransform,
} from './update-device-association.types';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const UpdateDeviceAssociationLoaderImpl: UpdateDeviceAssociationServiceType =
  ({ patientId, associationId }, accessToken: string) => {
    return getJSON(
      endpointWithParams(ENDPOINTS.updateDeviceAssociation, {
        associationId,
        patientId,
      }),
      {
        headers: {
          Authorization: createAuthHeader(accessToken),
        },
      },
    );
  };

export const UpdateDeviceAssociationTransformImpl: UpdateDeviceAssociationTransform =
  (result) => {
    if (
      result.resultDescription ===
      Ec6UpdateAssociationResultDescription.assingDeviceToPatientOk
    ) {
      return { success: true };
    }
    switch (result.resultDescription) {
      case Ec6UpdateAssociationResultDescription.assingDeviceToPatientOk:
      case Ec6UpdateAssociationResultDescription.ASSOCIATION_NOT_FOUND:
        return { success: false, errorReason: 'invalid-association-id' };
      case Ec6UpdateAssociationResultDescription.ASSOCIATION_ALREADY_ASSIGNED:
        return { success: false, errorReason: 'already-assigned' };
      default:
        return { success: false, errorReason: 'unexpected-error' };
    }
  };

export const UpdateDeviceAssociationServiceFactoryImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);

import React from 'react';

import { colors } from 'src/app/styles/colors';

import {
  PopoverContainerDiv,
  PopoverRectangleContainer,
} from './popover.style';

export const Popover = ({
  backgroundColor = colors.white,
  children,
  pushLeft,
  show,
  width = 0,
  overwriteStyle = false,
}) => (
  <PopoverContainerDiv show={show}>
    <PopoverRectangleContainer
      backgroundColor={backgroundColor}
      pushLeft={pushLeft}
      width={width}
      overwriteStyle={overwriteStyle}
    >
      {children}
    </PopoverRectangleContainer>
  </PopoverContainerDiv>
);

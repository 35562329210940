import { EditDemoPatientProfileResponse } from '../../store/demo-profile.types';

export const mockEditDemoPatientProfile: EditDemoPatientProfileResponse = {
  patientRecordId: '28f42df7-c5b0-4a54-b9fa-c347d73c82ae',
  assignedProfiles: [
    {
      demoProfile: 'Demo_profile1',
      ingestionOperationId: '36f8e0f2-55e0-41eb-8e31-e2f16131885c',
    },
  ],
};

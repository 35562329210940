import { toFormat } from 'src/shared/utils/date';

const DATE_WITHOUT_TIME_FORMAT = 'MMM d, yyyy';

export const formatGraphsDate = (date) =>
  date ? toFormat('dd LLL yyyy')(date) : '';

export const areDatesTheSameDay = (dateA, dateB) => {
  const format = toFormat(DATE_WITHOUT_TIME_FORMAT);
  return dateB == null || dateA == null
    ? false
    : format(dateA) === format(dateB);
};

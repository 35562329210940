import {
  EditPatientPlatformActions,
  EditPatientPlatformActionType,
  EditPatientPlatformState,
  FetchEditPatientDataActions,
  FetchEditPatientDataActionType,
  FetchEditPatientDataState,
} from './edit-patient.actions.types';

export const FetchEditPatientPlatformInitialState: FetchEditPatientDataState = {
  isFetchingPatientData: false,
  fetchDataSuccess: false,
  fetchDataError: false,
  patientData: null,
};

export const EditPatientPlatformInitialState: EditPatientPlatformState = {
  isEditingPatient: false,
  editionSuccess: false,
  editionError: false,
};

export const fetchEditPatientDataReducer = (
  state = FetchEditPatientPlatformInitialState,
  action: FetchEditPatientDataActions,
) => {
  switch (action.type) {
    case FetchEditPatientDataActionType.FETCH_EDIT_PATIENT_DATA_START:
      return {
        ...state,
        isFetchingPatientData: true,
      };
    case FetchEditPatientDataActionType.FETCH_EDIT_PATIENT_DATA_SUCCESS:
      return {
        ...state,
        isFetchingPatientData: false,
        fetchDataSuccess: true,
        patientData: action.payload,
      };
    case FetchEditPatientDataActionType.FETCH_EDIT_PATIENT_DATA_ERROR:
      return {
        ...state,
        isFetchingPatientData: false,
        fetchDataError: true,
      };
    case FetchEditPatientDataActionType.CLEAR_EDIT_PATIENT_DATA:
      return FetchEditPatientPlatformInitialState;
    default:
      return state;
  }
};

export const editPatientPlatformReducer = (
  state = EditPatientPlatformInitialState,
  action: EditPatientPlatformActions,
) => {
  switch (action.type) {
    case EditPatientPlatformActionType.EDIT_PATIENT_PLATFORM_START:
      return {
        ...state,
        isEditingPatient: true,
      };
    case EditPatientPlatformActionType.EDIT_PATIENT_PLATFORM_SUCCESS:
      return {
        ...state,
        isEditingPatient: false,
        editionSuccess: true,
      };
    case EditPatientPlatformActionType.EDIT_PATIENT_PLATFORM_ERROR:
      return {
        ...state,
        isEditingPatient: false,
        editionError: true,
      };
    case EditPatientPlatformActionType.CLEAR_EDIT_PATIENT_PLATFORM:
      return EditPatientPlatformInitialState;
    default:
      return state;
  }
};

import * as React from 'react';
import { push } from 'react-router-redux';
import { matchPath, Route } from 'react-router-dom';
import {
  appendOneTrustScript,
  appendDataDogScript,
} from 'src/app/inject-script';
import {
  BGM,
  CGM,
  HOME,
  HOME_CGM,
  RESET_PWD_PATH,
  SHORT_CUT,
  STATUS,
} from './app.constants';
import { GlobalStyle } from 'src/app/app.style';

import { Navigation } from 'src/app/navigation/navigation';

import { AlertWidget as Alert } from 'src/widgets/alert/alert-widget.component';
import { Block } from 'src/shared/design-system/block/block.component';
import { Column } from 'src/shared/design-system/column/column.component';
import { LoadingRing } from 'src/shared/design-system/loading-ring/loading-ring.component';

import { getCombinedRoutes } from 'src/app/navigation/store/navigation.selectors';
import { Consent } from './consent/consent.component';
import { doesUserAcceptedCookies, isConsentActivated } from './app.utils';
import {
  clearCriteria,
  filterPatients,
  filterPatientsNc,
  filterPatientsSet,
  filterPatientsSetNc,
  search,
  updateDashboardLocationIsolated,
} from 'src/domains/general/widgets/store/hcp-dashboard.actions';

import { DEFAULT_FILTERS } from 'src/domains/general/widgets/components/advanced-search/filters/filters.utils';
import {
  selectCurrentDashboardLocation,
  selectSearchTerm,
  selectUnitGlucoseForService,
} from 'src/domains/general/widgets/store/hcp-dashboard.selectors';

import { EventSystem, initCustomIds } from '@roche/roche-common';
import { storageListener } from './storage';
import { UserActivity } from 'src/app/session/components/user-activity/user-activity.component';
import {
  selectFhirPermission,
  selectHasRebranding,
  selectJelloPermission,
} from 'src/domains/permissions/store/permissions.selectors';
import { FiltersType } from 'src/domains/general/widgets/components/advanced-search/filters/filters.constants';
import { Modal } from 'src/widgets/modal/modal.component';
import { getConfig } from '@roche/roche-common';
import { AnnouncementsContainer } from 'src/domains/announcements/announcements.container';
import { RenderIf } from 'src/shared/utils/render-if';
import { QualtricsDataInjectorContainer } from 'src/app/navigation/qualtrics/qualtrics-data-injector.container';

const { REACT_APP_DATA_DOG_IS_ENABLED, REACT_APP_QUALTRICS_IS_ENABLED } =
  getConfig();

const isQualtricsEnabled =
  REACT_APP_QUALTRICS_IS_ENABLED && REACT_APP_QUALTRICS_IS_ENABLED === 'true';

export const AppContainer = ({ store }) => {
  const [status, setStatus] = React.useState(STATUS.IS_LOADING);

  const { dispatch } = store;

  const goTo = (path: string) => dispatch(push(path));

  const redirect = (id: string, isJelloDsEnabled = false) => {
    const routes = getCombinedRoutes(store.getState());
    const homeKey = isJelloDsEnabled
      ? HOME
      : id === SHORT_CUT.CGM
      ? HOME_CGM
      : HOME;
    const home = routes.general[homeKey];
    const noAtHome = !matchPath(window.location.pathname, {
      path: homeKey,
      exact: true,
    });

    if (noAtHome) goTo(home);
  };

  const checkSearch = (term: any, location = '', isJelloDsEnabled = false) => {
    if (term) {
      const unitGlucoseForService = selectUnitGlucoseForService(
        store.getState(),
      );
      const isFhir = isJelloDsEnabled
        ? selectFhirPermission(store.getState())
        : null;
      const locationParam =
        location || selectCurrentDashboardLocation(store.getState());

      dispatch(clearCriteria());
      dispatch(search(unitGlucoseForService, locationParam, '', isFhir));
    }
  };

  const filterData = (
    data: FiltersType,
    location = '',
    isJelloDsEnabled = false,
  ) => {
    if (isJelloDsEnabled) {
      dispatch(updateDashboardLocationIsolated(location));
      dispatch(filterPatients(data));
    } else {
      dispatch(filterPatientsSet(DEFAULT_FILTERS));
      dispatch(filterPatientsNc(data));
      dispatch(filterPatientsSetNc(data));
    }
  };

  const shortcutsFlow = (
    data: FiltersType,
    shortcutDash: string,
    searchTerm: string,
    isJelloFlow = false,
  ) => {
    const location =
      (isJelloFlow && (shortcutDash === SHORT_CUT.CGM ? CGM : BGM)) || '';
    redirect(shortcutDash, isJelloFlow);
    checkSearch(searchTerm, location, isJelloFlow);
    filterData(data, location, isJelloFlow);
  };

  const subscribeShortcuts = (eventSystem: any, shortcutDash: string) => {
    eventSystem.subscribe(shortcutDash, (name: unknown, data: any) => {
      const state = store.getState();
      const searchTerm = selectSearchTerm(state) as string;
      const isJelloDsEnabled = selectJelloPermission(state);
      shortcutsFlow(data, shortcutDash, searchTerm, isJelloDsEnabled);
    });
  };

  const handlesSubscribes = () => {
    const eventSystem = new EventSystem();
    eventSystem.initializePubSub();
    subscribeShortcuts(eventSystem, SHORT_CUT.BG);
    subscribeShortcuts(eventSystem, SHORT_CUT.CGM);
  };

  const handlesMultitab = () => {
    window.addEventListener('storage', storageListener(store));
  };

  const waitForElm = (selector: string) => {
    return new Promise((resolve) => {
      const el = document.querySelector(selector);

      if (el) return resolve(el);
      const observerCB = (mutationList) => {
        for (const mutation of mutationList) {
          const htmlElm = mutation.addedNodes[0] as HTMLElement;

          if (
            htmlElm &&
            (htmlElm.id === 'onetrust-banner-sdk' ||
              htmlElm.className ===
                'gigya-customize-ChangePasswordSuccessButton')
          ) {
            observer.disconnect();
            return resolve(htmlElm);
          }
        }

        observer.disconnect();
      };

      const observer = new MutationObserver(observerCB);

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  };

  const addButtonListener = (id: string) => {
    const doc: any = document;

    const el = doc.getElementById(id) || doc.getElementsByClassName(id)[0];

    if (el) {
      el.onclick = () => setStatus(STATUS.IS_READY);
    }
  };

  const handlesScriptConsentEls = () => {
    const wait = (id: string) => {
      waitForElm(id).then((elm) => {
        if (elm) {
          addButtonListener('gigya-customize-ChangePasswordSuccessButton');
        }
      });
    };

    wait('#onetrust-banner-sdk');
    wait('.gigya-customize-ChangePasswordSuccessButton');
  };

  const rebranding = selectHasRebranding(store.getState());

  const appendsScripts = () => {
    const consent = isConsentActivated();
    const cookies = doesUserAcceptedCookies();
    if (
      REACT_APP_DATA_DOG_IS_ENABLED &&
      REACT_APP_DATA_DOG_IS_ENABLED === 'true'
    ) {
      appendDataDogScript();
    }

    if (consent && !cookies) {
      setStatus(STATUS.IS_CONSENT);
      appendOneTrustScript({
        onError: () => setStatus(STATUS.IS_ERROR),
        onLoad: () => handlesScriptConsentEls(),
      });
    } else {
      setStatus(STATUS.IS_ACCEPTED);
    }
  };

  const handlesCookiesOnclick = () => {
    window.onclick = (e) => {
      const confirmMyChoices = document.getElementsByClassName(
        'save-preference-btn-handler',
      )[0];

      confirmMyChoices
        ? confirmMyChoices.setAttribute('id', 'save-preference-btn-handler')
        : null;

      if (
        [
          'onetrust-accept-btn-handler',
          'accept-recommended-btn-handler',
          'save-preference-btn-handler',
        ].indexOf(e.target.id) > -1
      ) {
        setStatus(STATUS.IS_ACCEPTED);
      }
    };
  };

  React.useEffect(() => {
    handlesCookiesOnclick();
    handlesSubscribes();
    handlesMultitab();
    initCustomIds('hcp-portal');
    appendsScripts();
  }, []);

  React.useEffect(() => {
    if (
      status === STATUS.IS_LOADING &&
      window.location.pathname === RESET_PWD_PATH
    ) {
      appendsScripts();
    }
  });

  if (status === STATUS.IS_ERROR) {
    return <div>Something went wrong</div>;
  }

  if (status === STATUS.IS_CONSENT) {
    return <Consent rebranding={rebranding} />;
  }

  if (status === STATUS.IS_LOADING) {
    return (
      <Column align="center" height="100vh" justifyContent="center">
        <LoadingRing infinite />
      </Column>
    );
  }

  return (
    <UserActivity>
      <section id="app-container">
        <GlobalStyle />
        <Route path="/" component={Navigation} />
        <Modal />
        <Alert />
        <AnnouncementsContainer />
        <Block id="version" display="none">
          {process.env.REACT_APP_VERSION}
        </Block>
        <RenderIf validate={isQualtricsEnabled}>
          <QualtricsDataInjectorContainer />
        </RenderIf>
      </section>
    </UserActivity>
  );
};

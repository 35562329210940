import { createPayloadAction } from '../../../../../app/store/app.actions';

import {
  CreateProfessionalActionType,
  CreateProfessionalErrorAction,
  CreateProfessionalErrorPayload,
  CreateProfessionalParams,
  CreateProfessionalStartAction,
  CreateProfessionalSuccessAction,
  CreateProfessionalSuccessPayload,
} from './create-professional.types';

export const createProfessionalStart = (
  payload: CreateProfessionalParams,
): CreateProfessionalStartAction =>
  createPayloadAction(
    CreateProfessionalActionType.CREATE_PROFESSIONAL_START,
    payload,
  );

export const createProfessionalSuccess = (
  payload: CreateProfessionalSuccessPayload,
): CreateProfessionalSuccessAction =>
  createPayloadAction(
    CreateProfessionalActionType.CREATE_PROFESSIONAL_SUCCESS,
    payload,
  );

export const createProfessionalError = (
  payload: CreateProfessionalErrorPayload,
): CreateProfessionalErrorAction =>
  createPayloadAction(
    CreateProfessionalActionType.CREATE_PROFESSIONAL_ERROR,
    payload,
  );

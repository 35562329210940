import { countlyEventTrigger } from 'src/app/navigation/countly';
import {
  BG_GRAPH_LEGEND_SEGMENT_VALUES,
  BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS,
} from 'src/domains/diagnostics/components/graph-controls/graph-controls.countly.constans';

import { graphTypeSelected } from 'src/domains/diagnostics/components/graph-controls/graph-controls.utils';

export const CHECKBOX_LEGEND_KEYS = {
  CHECKBOX_LEGEND_CHANGED: 'BG Graph Legend Changed',
};
export const checkboxChangedCountly = (graph, expandedGraph) => {
  countlyEventTrigger(CHECKBOX_LEGEND_KEYS.CHECKBOX_LEGEND_CHANGED, {
    [BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS.BG_GRAPH_TYPE]:
      graphTypeSelected(graph),
    [BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS.BG_LOCATION]: expandedGraph
      ? BG_GRAPH_LEGEND_SEGMENT_VALUES.GRAPH_PAGE
      : BG_GRAPH_LEGEND_SEGMENT_VALUES.BG_DASHBOARD,
  });
};

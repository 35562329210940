import {
  any,
  equals,
  filter,
  find,
  isNil,
  path,
  pipe,
  prop,
  propEq,
  range,
  reject,
} from 'ramda';

export const clinicGuideToQuantities = (clinicGuide) => {
  const min = prop('minimumStrips', clinicGuide);
  const max = prop('maximumStrips', clinicGuide);
  return any(isNil, [min, max]) ? [] : range(min, max + 1);
};

export const clinicGuideToPeriods = (clinicGuide) =>
  pipe(path(['period']), (period) => [period], reject(isNil))(clinicGuide);

export const findClinicGuideWithClinicGuideId =
  (clinicGuideId) => (clinicGuides) =>
    find(propEq('id', clinicGuideId), clinicGuides) || {};

const isCustomClinicGuide = pipe(prop('isCustom'), equals(true));
export const pickCustomClinicGuides = filter(isCustomClinicGuide);

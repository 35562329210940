import {
  EDIT_DEMO_PATIENT_PROFILES_REQUEST,
  FETCH_ASSIGNED_DEMO_PATIENT_PROFILES_REQUEST,
  FETCH_DEMO_PROFILES_REQUEST,
} from '../constants/demo-profile.constants';
import { DEMO_PROFILE } from '../demo-profile.constants';
import {
  CLEAR_EDIT_DEMO_PATIENT_PROFILE,
  DemoPatientProfileState,
} from './demo-profile.types';
import { map, prop } from 'ramda';

export const DemoPatientProfileInitialState: DemoPatientProfileState = {
  isFetchingDemoProfiles: false,
  demoPatientProfiles: [],
  isFetchingDemoProfilesError: false,
  isEditingDemoPatientProfiles: false,
  isEditingDemoPatientProfilesError: false,
  editedDemoPatientProfiles: null,
  isFetchingAssignedDemoPatientProfiles: false,
  assignedDemoPatientProfiles: [],
  isFetchingAssignedDemoPatientProfilesError: false,
};

const handleFetchDemoProfileStart = (state: DemoPatientProfileState) => ({
  ...state,
  isFetchingDemoProfiles: true,
  isFetchingDemoProfilesError: false,
});

const handleFetchDemoProfileSuccess = (
  state: DemoPatientProfileState,
  action,
) => ({
  ...state,
  demoPatientProfiles: action.payload,
  isFetchingDemoProfiles: false,
  isFetchingDemoProfilesError: false,
});

const handleFetchDemoProfileError = (state: DemoPatientProfileState) => ({
  ...state,
  isFetchingDemoProfiles: false,
  isFetchingDemoProfilesError: true,
});

const handleEditDemoPatientProfilesStart = (
  state: DemoPatientProfileState,
) => ({
  ...state,
  isEditingDemoPatientProfiles: true,
  isEditingDemoPatientProfilesError: false,
});

const handleEditDemoPatientProfilesSuccess = (
  state: DemoPatientProfileState,
  action,
) => ({
  ...state,
  editedDemoPatientProfiles: action.payload,
  isEditingDemoPatientProfiles: false,
  isEditingDemoPatientProfilesError: false,
  assignedDemoPatientProfiles: map(
    prop(DEMO_PROFILE),
    action.payload.assignedProfiles,
  ),
});

const handleEditDemoPatientProfilesError = (
  state: DemoPatientProfileState,
) => ({
  ...state,
  isEditingDemoPatientProfiles: false,
  isEditingDemoPatientProfilesError: true,
});

const clearEditDemoPatientProfile = (state: DemoPatientProfileState) => ({
  ...state,
  isEditingDemoPatientProfiles: false,
  isEditingDemoPatientProfilesError: false,
  editedDemoPatientProfiles: null,
});

const handleFetchAssignedDemoPatientProfilesStart = (
  state: DemoPatientProfileState,
) => ({
  ...state,
  isFetchingAssignedDemoPatientProfiles: true,
  isFetchingAssignedDemoPatientProfilesError: false,
});

const handleFetchAssignedDemoPatientProfilesSuccess = (
  state: DemoPatientProfileState,
  action,
) => ({
  ...state,
  assignedDemoPatientProfiles: action.payload,
  isFetchingAssignedDemoPatientProfiles: false,
  isFetchingAssignedDemoPatientProfilesError: false,
});

const handleFetchAssignedDemoPatientProfilesError = (
  state: DemoPatientProfileState,
) => ({
  ...state,
  isFetchingAssignedDemoPatientProfiles: false,
  isFetchingAssignedDemoPatientProfilesError: true,
});

const actionHandlers = {
  [FETCH_DEMO_PROFILES_REQUEST.START]: handleFetchDemoProfileStart,
  [FETCH_DEMO_PROFILES_REQUEST.SUCCESS]: handleFetchDemoProfileSuccess,
  [FETCH_DEMO_PROFILES_REQUEST.ERROR]: handleFetchDemoProfileError,
  [EDIT_DEMO_PATIENT_PROFILES_REQUEST.START]:
    handleEditDemoPatientProfilesStart,
  [EDIT_DEMO_PATIENT_PROFILES_REQUEST.SUCCESS]:
    handleEditDemoPatientProfilesSuccess,
  [EDIT_DEMO_PATIENT_PROFILES_REQUEST.ERROR]:
    handleEditDemoPatientProfilesError,
  [CLEAR_EDIT_DEMO_PATIENT_PROFILE]: clearEditDemoPatientProfile,
  [FETCH_ASSIGNED_DEMO_PATIENT_PROFILES_REQUEST.START]:
    handleFetchAssignedDemoPatientProfilesStart,
  [FETCH_ASSIGNED_DEMO_PATIENT_PROFILES_REQUEST.SUCCESS]:
    handleFetchAssignedDemoPatientProfilesSuccess,
  [FETCH_ASSIGNED_DEMO_PATIENT_PROFILES_REQUEST.ERROR]:
    handleFetchAssignedDemoPatientProfilesError,
};

export const patientDemoProfileReducer = (
  state = DemoPatientProfileInitialState,
  action,
): DemoPatientProfileState => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};

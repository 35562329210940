import { Action, PayloadAction } from '../../../../app/store/app.types';

export enum LatestPatientsActionType {
  RESET_LATEST_PATIENTS_STATE = 'RESET_LATEST_PATIENTS_STATE',
  GET_LATEST_PATIENTS_START = 'GET_LATEST_PATIENTS_START',
  GET_LATEST_PATIENTS_ERROR = 'GET_LATEST_PATIENTS_ERROR',
  GET_LATEST_PATIENTS_SUCCESS = 'GET_LATEST_PATIENTS_SUCCESS',
}

export type LatestPatientsResult = {
  id: number;
};

export type LatestPatientsState = {
  readonly results?: LatestPatientsResult[];
  readonly isFetching: boolean;
  readonly error?: GetLatestPatientsErrorPayloadType;
};

export type GetLatestPatientsErrorPayloadType = any;
export type GetLatestPatientsSuccessPayloadType = LatestPatientsResult[];

export type GetLatestPatientsStartAction =
  Action<LatestPatientsActionType.GET_LATEST_PATIENTS_START>;

export type GetLatestPatientsSuccessAction = PayloadAction<
  LatestPatientsActionType.GET_LATEST_PATIENTS_SUCCESS,
  GetLatestPatientsSuccessPayloadType
>;
export type GetLatestPatientsErrorAction = PayloadAction<
  LatestPatientsActionType.GET_LATEST_PATIENTS_ERROR,
  GetLatestPatientsErrorPayloadType
>;

export type ResetLatestPatientsStateAction =
  Action<LatestPatientsActionType.RESET_LATEST_PATIENTS_STATE>;

export type LatestPatientsReducerActions =
  | GetLatestPatientsStartAction
  | GetLatestPatientsSuccessAction
  | GetLatestPatientsErrorAction
  | ResetLatestPatientsStateAction;

export type LatestPatientsActions = LatestPatientsReducerActions;

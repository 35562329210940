import { createSelector } from 'reselect';
import { isEmpty, path } from 'ramda';

export const selectIsFetching = path(['listDevices', 'isFetchingDevices']);
export const selectHasError = path(['listDevices', 'isFetchingDevicesError']);
export const selectDevices = path(['listDevices', 'devices']);
export const selectDevicesStatus = path(['listDevices', 'status']);
export const selectHasNoDevices = createSelector([selectDevices], (val) =>
  isEmpty(val),
);

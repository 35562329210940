import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';
import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const TimeIntervalLoaderImpl = ({ patientId }, accessToken: string) => {
  return getJSON(endpointWithParams(ENDPOINTS.timeBlocks, { patientId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  });
};

export const TimeIntervalTransformImpl: any = (results) =>
  results.model.timeIntervals.map(
    ({ id, description, startTime, endTime }) => ({
      id,
      description,
      startTime,
      endTime,
    }),
  );

export const TimeIntervalServiceImpl =
  (load, transform) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);

import { createSelector, createStructuredSelector } from 'reselect';

import { toDayOfWeekNumFormat } from '../../../../../../shared/utils/date';
import { getFormattedStandardDeviation } from 'src/domains/diagnostics/utils/stat.util';
import {
  selectGraphDetails,
  selectShowGridLines,
  selectTargetRange,
  selectThreshold,
  selectVerticalAxesCeiling,
  selectVerticalTicks,
} from 'src/domains/diagnostics/scenes/graphs/graph.selector';
import {
  DAYS_OF_WEEK,
  GRAPH_Y_MIN,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { EMPTY_VALUE_PLACEHOLDER } from 'src/domains/diagnostics/store/constants';
import {
  selectBloodGlucoseUnit,
  selectGlucoseMeasurementsInDateSliderRange,
  selectGraphLoading,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';

const getMeasurementDetails = (measurements) => {
  const glucoseValues = measurements.map((measurement) => measurement.value);
  const newGlucoseValues = glucoseValues.filter((item) => item !== 0);

  const max = Math.max(...newGlucoseValues);
  const min = Math.min(...newGlucoseValues);

  const sum = newGlucoseValues.reduce(
    (accumulator, value) => accumulator + value,
    0,
  );

  const mean = sum / newGlucoseValues.length;

  return {
    max,
    min,
    mean,
    count: newGlucoseValues.length,
    stdDev: getFormattedStandardDeviation(newGlucoseValues),
  };
};

const buildGlucoseDataSet = (measurements) => {
  const newMeasurements = measurements.filter((item) => item.value !== null);
  return DAYS_OF_WEEK.map((_day, index) =>
    getMeasurementDetails(
      newMeasurements.filter((measurement) => {
        const dayOfWeek = toDayOfWeekNumFormat(measurement.date);
        return (
          dayOfWeek === `${index + 1}` || (dayOfWeek === '0' && index === 6)
        );
      }), // toDayOfWeekNumFormat returns days of the week as strings 0 -> 6 starting with Sunday
    ),
  );
};

const normalizeGlucoseData = (data, _floor, ceiling) => {
  const newData = data.filter((item) => item.min !== 0);
  return newData.map((datum, index) => {
    const { max, min, stdDev, mean } = datum;
    return {
      max: max / ceiling,
      min: min / ceiling,
      deviation: stdDev === EMPTY_VALUE_PLACEHOLDER ? 0 : stdDev / ceiling,
      x: index / newData.length,
      y: mean / ceiling,
      data: datum,
    };
  });
};

const normalizeGraphData = (measurements, graphYMax) =>
  normalizeGlucoseData(
    buildGlucoseDataSet(measurements),
    GRAPH_Y_MIN,
    graphYMax,
  );

export const selectGraphData = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectVerticalAxesCeiling,
  normalizeGraphData,
);

const normalizeHorizontalTicks = (graphData, index) =>
  graphData.map((datum, index) => ({
    value: datum.x,
    label: `general.days.${DAYS_OF_WEEK[index]}`,
  }));

export const selectHorizontalTicks = createSelector(
  selectGraphData,
  normalizeHorizontalTicks,
);

export const standardWeekTrendConnector = createStructuredSelector({
  bloodGlucoseUnit: selectBloodGlucoseUnit,
  measurements: selectGlucoseMeasurementsInDateSliderRange,
  graphData: selectGraphData,
  targetRange: selectTargetRange,
  threshold: selectThreshold,
  verticalTicks: selectVerticalTicks,
  horizontalTicks: selectHorizontalTicks,
  graphYMax: selectVerticalAxesCeiling,
  graphDetails: selectGraphDetails,
  showGridLines: selectShowGridLines,
  isLoading: selectGraphLoading,
});

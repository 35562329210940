import {
  createAction,
  createPayloadAction,
} from '../../../../../../app/store/app.actions';
import {
  CheckDuplicatedEmailActionType,
  CheckDuplicatedEmailErrorAction,
  CheckDuplicatedEmailErrorPayload,
  CheckDuplicatedEmailStartAction,
  CheckDuplicatedEmailStartPayload,
  CheckDuplicatedEmailSuccessAction,
  CheckDuplicatedEmailSuccessPayload,
  ResetDuplicatedEmailAction,
} from './check-duplicated-email.actions.types';

export const checkDuplicatedEmailStart = (
  payload: CheckDuplicatedEmailStartPayload,
): CheckDuplicatedEmailStartAction =>
  createPayloadAction(
    CheckDuplicatedEmailActionType.CHECK_DUPLICATED_EMAIL_START,
    payload,
  );

export const checkDuplicatedEmailSuccess = (
  payload: CheckDuplicatedEmailSuccessPayload,
): CheckDuplicatedEmailSuccessAction =>
  createPayloadAction(
    CheckDuplicatedEmailActionType.CHECK_DUPLICATED_EMAIL_SUCCESS,
    payload,
  );

export const checkDuplicatedEmailError = (
  payload: CheckDuplicatedEmailErrorPayload,
): CheckDuplicatedEmailErrorAction =>
  createPayloadAction(
    CheckDuplicatedEmailActionType.CHECK_DUPLICATED_EMAIL_ERROR,
    payload,
  );

export const resetDuplicatedEmailAction = (): ResetDuplicatedEmailAction =>
  createAction(CheckDuplicatedEmailActionType.RESET_DUPLICATED_EMAIL);

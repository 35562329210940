import styled from 'styled-components';
import { Div } from 'src/shared/design-system/div/div.component';

import { colors } from 'src/app/styles/colors';
import { transitions } from 'src/app/styles/transitions';
import { spacing } from 'src/app/styles/spacing';

export const IconContainer = styled(Div)`
  height: 32px;
  display: flex;
  .icon {
    width: 32px;
    height: 32px;
    display: inline-block;
    position: relative;
    margin-right: ${spacing.four};
    &:after {
      content: '';
      height: 28px;
      width: 1px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: -18px;
      background: ${colors.gray3};
    }
    &:not(.menu-icon, .help-icon) {
      border: 2px solid ${colors.brandBlueDark};
      border-radius: 50%;
      transition: ${transitions.default};
      cursor: pointer;
      &.at-home {
        border-color: ${colors.brandBlue};
      }
      &:hover {
        border-color: ${colors.brandBlue};
      }
      span:first-of-type {
        float: left;
      }
      svg {
        width: 32px;
        position: absolute;
        right: 0;
        left: -2px;
        margin: auto;
        top: 0;
        bottom: 0;
      }
    }
    &:last-of-type {
      margin-right: none;
    }
  }
`;
IconContainer.displayName = 'IconContainer';
export const IconWrapper = styled(Div)``;
IconWrapper.displayName = 'IconWrapper';

export const NotificationModuleWrapper = styled(Div)`
  height: 100%;
  z-index: 2;
  position: relative;
`;

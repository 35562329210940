import { find, path, pathOr, pipe, propEq } from 'ramda';

import { STATE_ACTIONS } from 'src/app/store/state/state.constants';
import { isNotNil } from 'src/shared/utils/validation-helpers';

import {
  FETCH_TEMPORARY_REASONS_REQUEST,
  GET_CURRENT_PRESCRIPTION_REQUEST,
  GUIDE_HISTORY_FILTERS,
  PRESCRIPTION_ACTIONS,
  SAVE_PRESCRIPTION_REQUEST,
} from '../prescription.constants';
import {
  CLINIC_GUIDE_TYPES,
  GET_CLINIC_GUIDES_REQUEST,
} from '../clinic-guide/clinic-guide.constants';
import { GET_FREQUENCIES_REQUEST } from '../frequencies/frequencies.constants';
import { GET_THERAPIES_REQUEST } from '../therapy/therapy.constants';
import {
  flattenPrescriptions,
  removeActiveForm,
  removePrescription,
  sendGuidelinesCountlyEvent,
  setAllFieldsOptions,
  setFieldOptions,
  setFieldOptionsForTemporaryForm,
  setPrescriptionByType,
} from '../prescription.utils';
import {
  clinicGuideToPeriods,
  clinicGuideToQuantities,
  findClinicGuideWithClinicGuideId,
} from '../clinic-guide/clinic-guide.utils';
import { GET_STRIP_MODELS_REQUEST } from '../strip-model/strip-model.constants';

// .prescriptions: saved prescriptions fetched from middleware
// .activeForms: the working copies modified by the user
// .activeFormId: the current form being edited by user
export const INITIAL_PRESCRIPTION_STATE = {
  activeFormId: '',
  activeForms: {},
  permanent: null,
  temporary: null,
  activePrescription: null,
  stripModels: [],
  frequencies: [],
  therapies: [],
  clinicGuides: [],
  isCreatingCustomClinicGuides: false,
  guideHistoryFilter: GUIDE_HISTORY_FILTERS.ALL,
  guideHistoryPage: 1,
};

// Arrays represent dropdown options fetched from middleware
// Their respective single values represent user's choice from the list
export const INITIAL_PRESCRIPTION_FORM_STATE = {
  clinicGuide: '',
  quantity: null,
  stripModel: '',
  therapy: '',
  frequency: '',
  period: '',
  reason: null,
  startDate: null,
  endDate: null,
  clinicGuideFilter: null,
};

export const INITIAL_TEMPORARY_PRESCRIPTION_FORM_STATE = {
  ...INITIAL_PRESCRIPTION_FORM_STATE,
  reason: '',
};

// Check if this prescription already exists in the store
const isNewPrescription = (id, prescriptions) =>
  !find(propEq('id', id))(prescriptions);

const clearPrescriptions = () => INITIAL_PRESCRIPTION_STATE;

const resetPrescription = (state) => ({
  ...INITIAL_PRESCRIPTION_STATE,
  stripModels: state.stripModels,
});

const setCurrentPrescription = (state, action) => ({
  ...state,
  activePrescription: pathOr(null, ['payload', 'active'])(action),
});

const addPrescriptionEntry = (state, action) => {
  const nextPrescriptionId = action.payload.id;
  const prescriptions = flattenPrescriptions({
    permanent: state.permanent,
    temporary: state.temporary,
  });

  if (isNewPrescription(nextPrescriptionId, prescriptions)) {
    // Create a new prescription object and insert it into the store      // (in prescriptions array and empty object entry in activeForms)
    // This will render a new form on the front end
    // Temporary prescription will not persist unless saved
    const nextPrescription = action.payload;
    return pipe(
      setPrescriptionByType(nextPrescription),
      setAllFieldsOptions(nextPrescriptionId, {
        id: nextPrescriptionId,
        clinicGuideFilter: CLINIC_GUIDE_TYPES.PRESET,
      }),
    )(state);
  }
  return state;
};

const setActivePrescriptionForm = (state, action) => ({
  ...state,
  activeFormId: action.payload,
});

const getStripModelsRequestSucces = (state, action) => ({
  ...state,
  stripModels: action.payload,
});

const getTherapiesRequestSucces = (state, action) => ({
  ...state,
  therapies: action.payload,
});

const getClinicGuidesRequestSucces = (state, action) => {
  const { clinicGuides, id } = action.payload;

  if (isNotNil(id)) {
    const prescriptions = flattenPrescriptions({
      permanent: state.permanent,
      temporary: state.temporary,
    });
    const clinicGuideFilter =
      pipe(
        find(propEq('id', id)),
        path(['clinicGuide']),
        (clinicGuideId) => find(propEq('id', clinicGuideId), clinicGuides),
        path(['type']),
      )(prescriptions) || CLINIC_GUIDE_TYPES.PRESET;

    return {
      ...setAllFieldsOptions(id, {
        clinicGuides: clinicGuides,
        clinicGuideFilter,
      })(state),
      clinicGuides,
    };
  }

  return {
    ...state,
    clinicGuides,
  };
};

const getFrequenciesRequestSucces = (state, action) => ({
  ...state,
  frequencies: action.payload,
});

const fetchTemporaryReasonsRequestSucces = (state, action) =>
  setFieldOptionsForTemporaryForm('reasons', action.payload)(state);

const savePrescriptionRequestSucces = (state, action) => {
  // After save, remove the working prescription object
  // Latest valid prescriptions will be fetched from API and injected
  const previousId = action.payload.previousPrescriptionId;
  sendGuidelinesCountlyEvent(action.payload, state);
  return removeActiveForm(previousId)(state);
};

const updateQuantitiesAndPeriods = (state, action) => {
  const { clinicGuideId, formId } = action.payload;
  const clinicGuide = pipe(
    pathOr([], ['activeForms', formId, 'clinicGuides']),
    findClinicGuideWithClinicGuideId(clinicGuideId),
  )(state);
  const quantities = clinicGuideToQuantities(clinicGuide);
  const periods = clinicGuideToPeriods(clinicGuide);
  return pipe(
    setFieldOptions('quantities', quantities, formId),
    setFieldOptions('periods', periods, formId),
  )(state);
};

const setPrescriptionClinicGuideType = (state, action) => {
  const { id, isCustom } = action.payload;
  return pipe(setFieldOptions('hasCustomClinicGuide', isCustom, id))(state);
};

const removeUnsavedPrescription = (state, action) => {
  const formId = action.payload;
  return pipe(removePrescription(formId), removeActiveForm(formId))(state);
};

const showCustomClinicGuidesForm = (state) => ({
  ...state,
  isCreatingCustomClinicGuides: true,
});

const hideCustomClinicGuidesForm = (state) => ({
  ...state,
  isCreatingCustomClinicGuides: false,
});

const setClinicGuideFilter = (state, action) => {
  const { filter, formId } = action.payload;
  return setFieldOptions('clinicGuideFilter', filter, formId)(state);
};

const setGuideHistoryFilter = (state, action) => {
  const { filter } = action.payload;
  return {
    ...state,
    guideHistoryFilter: filter,
    guideHistoryPage: INITIAL_PRESCRIPTION_STATE.guideHistoryPage,
  };
};

const setGuideHistoryPage = (state, action) => {
  const { page } = action.payload;
  return {
    ...state,
    guideHistoryPage: page,
  };
};

export const actionHandlers = {
  [STATE_ACTIONS.CLEAR_PRESCRIPTIONS]: clearPrescriptions,
  [PRESCRIPTION_ACTIONS.RESET_PRESCRIPTION]: resetPrescription,
  [GET_CURRENT_PRESCRIPTION_REQUEST.SUCCESS]: setCurrentPrescription,
  [PRESCRIPTION_ACTIONS.ADD_PRESCRIPTION_ENTRY]: addPrescriptionEntry,
  [PRESCRIPTION_ACTIONS.SET_ACTIVE_PRESCRIPTION_FORM]:
    setActivePrescriptionForm,
  [GET_STRIP_MODELS_REQUEST.SUCCESS]: getStripModelsRequestSucces,
  [GET_THERAPIES_REQUEST.SUCCESS]: getTherapiesRequestSucces,
  [GET_CLINIC_GUIDES_REQUEST.SUCCESS]: getClinicGuidesRequestSucces,
  [GET_FREQUENCIES_REQUEST.SUCCESS]: getFrequenciesRequestSucces,
  [FETCH_TEMPORARY_REASONS_REQUEST.SUCCESS]: fetchTemporaryReasonsRequestSucces,
  [SAVE_PRESCRIPTION_REQUEST.SUCCESS]: savePrescriptionRequestSucces,
  [PRESCRIPTION_ACTIONS.UPDATE_QUANTITIES_AND_PERIODS]:
    updateQuantitiesAndPeriods,
  [PRESCRIPTION_ACTIONS.SET_PRESCRIPTION_CLINIC_GUIDE_TYPE]:
    setPrescriptionClinicGuideType,
  [PRESCRIPTION_ACTIONS.REMOVE_UNSAVED_PRESCRIPTION]: removeUnsavedPrescription,
  [PRESCRIPTION_ACTIONS.SHOW_CUSTOM_CLINIC_GUIDES_FORM]:
    showCustomClinicGuidesForm,
  [PRESCRIPTION_ACTIONS.HIDE_CUSTOM_CLINIC_GUIDES_FORM]:
    hideCustomClinicGuidesForm,
  [PRESCRIPTION_ACTIONS.SET_CLINIC_GUIDE_FILTER]: setClinicGuideFilter,
  [PRESCRIPTION_ACTIONS.SET_GUIDE_HISTORY_FILTER]: setGuideHistoryFilter,
  [PRESCRIPTION_ACTIONS.SET_GUIDE_HISTORY_PAGE]: setGuideHistoryPage,
};

export const prescriptionReducer = (
  state = INITIAL_PRESCRIPTION_STATE,
  action = {},
) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};

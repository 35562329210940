import styled from 'styled-components';
import { color, fontSize, space, textAlign, width } from 'styled-system';

import { colors } from 'src/app/styles/colors';

export const TableRow = styled.tr`
  ${width};
  ${color};
  ${space};
  ${fontSize};
  ${textAlign};
`;

export const TableAltRowEven = styled(TableRow)`
  :nth-child(even) {
    background: ${colors.silverLight};
  }
`;

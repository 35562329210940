import { BooleanAsString } from '../../app/store/app.types';

// Booleans in string format should only be used when interfacing with libraries
// that do not support boolean input.
// The most common use case in this code base is React Redux Form controls

export const convertStringToBoolean = (value: BooleanAsString): boolean =>
  value === BooleanAsString.TRUE;

export const convertBooleanToString = (value: boolean): BooleanAsString =>
  value ? BooleanAsString.TRUE : BooleanAsString.FALSE;

import {
  createAction,
  createPayloadAction,
} from '../../../../../../app/store/app.actions';
import {
  ClearEditPatientDataAction,
  ClearEditPatientPlatformAction,
  EditPatientPlatformActionType,
  EditPatientPlatformErrorAction,
  EditPatientPlatformStartAction,
  EditPatientPlatformStartPayloadType,
  EditPatientPlatformSuccessAction,
  FetchEditPatientDataActionType,
  FetchEditPatientDataErrorAction,
  FetchEditPatientDataErrorActionPayload,
  FetchEditPatientDataStartAction,
  FetchEditPatientDataStartActionPayload,
  FetchEditPatientDataSuccessAction,
  FetchEditPatientDataSuccessActionPayload,
} from './edit-patient.actions.types';

export const fetchEditPatientDataStartAction = (
  payload: FetchEditPatientDataStartActionPayload,
): FetchEditPatientDataStartAction =>
  createPayloadAction(
    FetchEditPatientDataActionType.FETCH_EDIT_PATIENT_DATA_START,
    payload,
  );

export const fetchEditPatientDataSuccessAction = (
  payload: FetchEditPatientDataSuccessActionPayload,
): FetchEditPatientDataSuccessAction =>
  createPayloadAction(
    FetchEditPatientDataActionType.FETCH_EDIT_PATIENT_DATA_SUCCESS,
    payload,
  );

export const fetchEditPatientDataErrorAction = (
  payload: FetchEditPatientDataErrorActionPayload,
): FetchEditPatientDataErrorAction =>
  createPayloadAction(
    FetchEditPatientDataActionType.FETCH_EDIT_PATIENT_DATA_ERROR,
    payload,
  );

export const clearEditPatientDataAction = (): ClearEditPatientDataAction =>
  createAction(FetchEditPatientDataActionType.CLEAR_EDIT_PATIENT_DATA);

export const editPatientPlatformStartAction = (
  payload: EditPatientPlatformStartPayloadType,
): EditPatientPlatformStartAction =>
  createPayloadAction(
    EditPatientPlatformActionType.EDIT_PATIENT_PLATFORM_START,
    payload,
  );

export const editPatientPlatformSuccessAction = (
  payload: FixMe,
): EditPatientPlatformSuccessAction =>
  createPayloadAction(
    EditPatientPlatformActionType.EDIT_PATIENT_PLATFORM_SUCCESS,
    payload,
  );

export const editPatientPlatformErrorAction =
  (): EditPatientPlatformErrorAction =>
    createAction(EditPatientPlatformActionType.EDIT_PATIENT_PLATFORM_ERROR);

export const clearEditPatientPlatformAction =
  (): ClearEditPatientPlatformAction =>
    createAction(EditPatientPlatformActionType.CLEAR_EDIT_PATIENT_PLATFORM);

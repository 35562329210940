import { Store as ReduxStore } from 'redux';
import { Persistor as RPPersistor } from 'redux-persist/lib/types';

import { ConfigurablePermissionsState } from 'src/domains/patient/store/configurables/configurables.types';
import { CountriesState } from 'src/domains/patient/store/countries/countries.types';
import { CurrentFormsState } from 'src/domains/patient/store/current-forms/current-forms.types';
import { DepartmentState } from 'src/domains/patient/store/department/department.types';
import { EditPatientState } from 'src/domains/patient/scenes/edit-patient/store/edit-patient.types';
import { LanguagesState } from 'src/domains/patient/store/languages/languages.types';
import { DeliveryConfigurationState } from 'src/domains/patient/scenes/delivery-configuration/store/delivery-configuration.types';
import { CreatePatientState } from 'src/domains/patient/scenes/create-patient/store/create-patient.types';
import { CreateProfessionalState } from 'src/domains/professional/scenes/create-professional/store/create-professional.types';
import { RpmSettingsState } from 'src/domains/rpm/rpm-settings/store/rpm-settings.types';

export type Persistor = {
  persist: () => void;
} & RPPersistor;

export type Store<S> = ReduxStore<S> & {
  persistor: Persistor;
};

export type Action<Type, Meta = void> = {
  readonly type: Type;
  readonly meta?: Meta;
};

export type PayloadAction<Type, Payload, Meta = void> = Action<Type, Meta> & {
  readonly payload: Payload;
};

export type State = {
  readonly createPatient: CreatePatientState;
  readonly createProfessional: CreateProfessionalState;
  readonly department: DepartmentState;
  readonly countries: CountriesState;
  readonly languages: LanguagesState;
  readonly editPatient: EditPatientState;
  readonly currentForms: CurrentFormsState;
  readonly configurablePermissions: ConfigurablePermissionsState;
  readonly deliveryConfiguration: DeliveryConfigurationState;
  readonly rpmSettings: RpmSettingsState;
};

declare global {
  type FixMe = any;
  interface Window {
    QSI: FixMe;
  }
}

export enum BooleanAsString {
  TRUE = 'true',
  FALSE = 'false',
}

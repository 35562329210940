import * as React from 'react';

import { GenerateIconsSection } from '../icon-components.component';
import { JELLO_ICON_NAMES } from 'src/app/app.jello.constants';
import {
  ADDITIONAL_INFORMATION_ALT,
  CE_CODE_ICON_ALT,
  DATE_OF_MANUFACTURE_ICON_ALT,
  MANUFACTURER_ICON_ALT,
  MEDICAL_DEVICE_ICON_ALT,
  MEDICAL_DEVICE_ID_ICON_ALT,
} from '../../../general-info-view/constants';

import {
  generateIfusRegulatoryAssetURL,
  getImage,
  getJelloIcon,
} from '../../icons-view-templates';
import { ISO_SYMBOLS } from '../../../../manufacturer-info-modal.icons.keys';

const MANUFACTURER_ICON_URL = generateIfusRegulatoryAssetURL('manufacture.svg');
const MANUFACTURER_ID_ICON_URL = generateIfusRegulatoryAssetURL(
  'manufacturer_date.svg',
);
const REPRESENTATIVE_ICON_URL =
  generateIfusRegulatoryAssetURL('sym_mdr_rep.svg');
const MEDICAL_DEVICE_ICON_URL =
  generateIfusRegulatoryAssetURL('sym_mdr_md.svg');
const MEDICAL_DEVICE_ID_ICON_URL =
  generateIfusRegulatoryAssetURL('sym_mdr_udi.svg');
const CE_CODE_ICON_URL = generateIfusRegulatoryAssetURL('ce_marking_mdr.svg');
const ADDITIONAL_INFO_ICON_URL =
  generateIfusRegulatoryAssetURL('user_manual.svg');

const sectionTitle = 'manufacturerInfo.isoSymbols.isoSymbolsTitle';

const iconListItems = [
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.WARNING_ALT),
    label: ISO_SYMBOLS.CAUTION_ICON_TEXT_KEY,
  },
  {
    icon: getImage(MANUFACTURER_ID_ICON_URL, DATE_OF_MANUFACTURE_ICON_ALT),
    label: ISO_SYMBOLS.DATE_MANUFACTURE_ICON_TEXT_KEY,
  },
  {
    icon: getImage(MEDICAL_DEVICE_ICON_URL, MEDICAL_DEVICE_ICON_ALT),
    label: ISO_SYMBOLS.MEDICAL_DEVICE_ICON_TEXT_KEY,
  },
  {
    icon: getImage(REPRESENTATIVE_ICON_URL, MEDICAL_DEVICE_ICON_ALT),
    label: ISO_SYMBOLS.AUTH_REP_ICON_TEXT_KEY,
  },
  {
    icon: getImage(MEDICAL_DEVICE_ID_ICON_URL, MEDICAL_DEVICE_ID_ICON_ALT),
    label: ISO_SYMBOLS.UNIQUE_DEVICE_ID_ICON_TEXT_KEY,
  },
  {
    icon: getImage(CE_CODE_ICON_URL, CE_CODE_ICON_ALT),
    label: ISO_SYMBOLS.CE_INFO_ICON_TEXT_KEY,
  },
  {
    icon: getImage(MANUFACTURER_ICON_URL, MANUFACTURER_ICON_ALT),
    label: ISO_SYMBOLS.MANUFACTURER_ICON_TEXT_KEY,
  },
  {
    icon: getImage(ADDITIONAL_INFO_ICON_URL, ADDITIONAL_INFORMATION_ALT),
    label: ISO_SYMBOLS.CONSULT_ELECTRONIC_ICON_TEXT_KEY,
  },
];

export const ISOSymbolsIconsComponent = () => (
  <GenerateIconsSection
    iconListItems={iconListItems}
    sectionTitle={sectionTitle}
  />
);

import React from 'react';
import {
  JELLO_ICON_BUTTON_SIZES,
  JELLO_ICON_BUTTON_VARIANTS,
  JELLO_ICON_NAMES,
} from '../../../../app/app.jello.constants';
import { PRINT_AND_DOWNLOAD_BUTTON_TEST_ID } from '../patient.constants';

export interface PrintAndDownloadButtonProps {
  onClickPrintAndDownloadButton: () => void;
  disableProps: { disabled: boolean } | {};
  tooltipText: string;
}

export const PrintAndDownloadButton = ({
  onClickPrintAndDownloadButton,
  disableProps,
  tooltipText,
}: PrintAndDownloadButtonProps) => (
  <jello-tooltip label={tooltipText}>
    <jello-icon-button
      data-testid={PRINT_AND_DOWNLOAD_BUTTON_TEST_ID}
      size={JELLO_ICON_BUTTON_SIZES.L}
      variant={JELLO_ICON_BUTTON_VARIANTS.TERTIARY}
      icon-name={JELLO_ICON_NAMES.PRINTER}
      onClick={onClickPrintAndDownloadButton}
      {...disableProps}
    />
  </jello-tooltip>
);

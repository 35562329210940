import React from 'react';

import { ProtectedRoute } from 'src/shared/design-system/protected-route/protected-route.container';

import { DeviceAssignmentBundle } from './bundles';

export const deviceAssignmentLinks = {
  associateDevice: '/associate-device/:associationId',
};

export const DeviceAssignmentRoutes = (props) => (
  <ProtectedRoute
    key="device-assignment"
    exact
    path={deviceAssignmentLinks.associateDevice}
    component={(props) => (
      <DeviceAssignmentBundle
        path={deviceAssignmentLinks.associateDevice}
        {...props}
      />
    )}
    returnAfterLogin
  />
);

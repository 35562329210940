// @ts-nocheck
import { path, prop } from 'ramda';
import { createSelector } from 'reselect';

export const getDomainRoutes = path(['navigation', 'routes']);
export const getRouterPathname = path(['router', 'location', 'pathname']);

export const getDomainRoutesDiagnostics = createSelector(
  getDomainRoutes,
  prop('diagnostics'),
);

export const getDomainRoutesPatient = createSelector(
  getDomainRoutes,
  prop('patient'),
);

export const getCombinedRoutes = createSelector(
  [getDomainRoutes],
  (domainRoutes) => ({
    ...domainRoutes,
  }),
);

import * as React from 'react';

import { colors } from '../../../app/styles/colors';

import {
  CircleWithBorder,
  makeCircleBoxshadow,
} from './concentric-circles.style';

type ConcentricCirclesProps = {
  height?: number;
  borderThickness?: number;
  innerCircleColor?: string;
  borderColor?: string;
  borderOpacity?: number;
  children?: React.ReactNode;
};

const DEFAULT_INNER_CIRCLE_COLOR = colors.grayMedium;

export const ConcentricCircles: React.FunctionComponent<
  ConcentricCirclesProps
> = ({
  height = 50,
  borderThickness = 4,
  innerCircleColor = DEFAULT_INNER_CIRCLE_COLOR,
  borderColor = innerCircleColor,
  borderOpacity = 0.3,
  children,
}) => (
  <CircleWithBorder
    bg={innerCircleColor}
    boxShadow={makeCircleBoxshadow(borderColor, borderOpacity, borderThickness)}
    height={height}
    width={height}
  >
    {children}
  </CircleWithBorder>
);

import * as React from 'react';

import { ManufacturerLeftItemLink } from './manufacturer-info-modal.style';

import styles from './styles.module.css';

type ManufacturerLeftItemProps = {
  item: string;
  id: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  active: boolean;
  key: number;
};

export const ManufacturerLeftItemComponent = ({
  item,
  id,
  onClick,
  active,
}: ManufacturerLeftItemProps) => (
  <li className={styles.menuColumnItem}>
    <ManufacturerLeftItemLink
      id={id}
      href="#"
      onClick={onClick}
      active={active}
    >
      {item}
    </ManufacturerLeftItemLink>
  </li>
);

import styled from 'styled-components';
import {
  alignItems,
  alignSelf,
  color,
  flex,
  flexWrap,
  height,
  justifyContent,
  space,
  width,
} from 'styled-system';

import { Div } from 'src/shared/design-system/div/div.component';

export const Column = styled(Div)`
  flex: 1;
  flex-direction: column;
  display: flex;
  ${color};
  ${space};
  ${width};
  ${height};
  ${flex};
  ${flexWrap};
  ${justifyContent};
  ${alignItems};
  ${alignSelf};
`;

export default Column;

import React from 'react';
import { withTheme } from 'styled-components';
import { testId } from '@roche/roche-common';
import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';
import {
  BasalToggleIcon,
  CarbohydratesToggleIcon,
  ConnectingLinesIcon,
  CrossMarkIcon,
  GridIcon,
  InsulinToggleIcon,
  MeanBloodGlucoseIcon,
  RectangleMarkIcon,
} from 'src/shared/design-system/icons';
import { colors } from 'src/app/styles/colors';

import { legendLists } from './detail-graph-legend.constant';
import { LegendItem } from './legend-item.component';
import { CheckboxContainerComponent } from './checkbox-container.component';

import {
  LegendList,
  LegendListsContainer,
  LegendListSectionHeader,
} from '../graph-legend.style';
import { CheckboxesForm } from '../../graph-options/graph-options.style';

const DetailGraphLegendComponent = ({
  graphType,
  theme,
  t,
  expandedGraph,
  graph,
}) => (
  <LegendListsContainer {...testId('graph-legend', '')}>
    <CheckboxesForm model="ui.patientDashboard">
      {legendLists(theme).map(({ textKey, noBorder, items }) => (
        <LegendList {...{ noBorder }} key={`legend-list-${textKey}`}>
          <LegendListSectionHeader>
            <LocalizedText {...{ textKey }} />
          </LegendListSectionHeader>
          {items.reduce((acc, { icon, label, graphs }) => {
            if (graphs.includes(graphType)) {
              /* istanbul ignore next */
              if (label === 'graphDetails.legend.bloodGlucose') {
                return [
                  ...acc,
                  <CheckboxContainerComponent
                    t={t}
                    key={`legend-item-${label}`}
                    id={'graphs.detailGraph.bloodGlucose'}
                    modelPath={'.showBloodGlucosePoints'}
                    icon={<CrossMarkIcon />}
                    graphType={graphType}
                    graph={graph}
                    expandedGraph={expandedGraph}
                  />,
                ];
              }
              /* istanbul ignore next */
              if (label === 'graphs.detailGraph.bgBeforeMeal') {
                return [
                  ...acc,
                  <CheckboxContainerComponent
                    t={t}
                    key={`legend-item-${label}`}
                    id={'graphs.detailGraph.bgBeforeMeal'}
                    modelPath={'.showBloodGlucoseBeforeMealPoints'}
                    icon={<RectangleMarkIcon fillColor={colors.white} />}
                    graphType={graphType}
                    graph={graph}
                    expandedGraph={expandedGraph}
                  />,
                ];
              }
              /* istanbul ignore next */
              if (label === 'graphs.detailGraph.bgAfterMeal') {
                return [
                  ...acc,
                  <CheckboxContainerComponent
                    t={t}
                    key={`legend-item-${label}`}
                    id={'graphs.detailGraph.bgAfterMeal'}
                    modelPath={'.showBloodGlucoseAfterMealPoints'}
                    icon={<RectangleMarkIcon />}
                    graphType={graphType}
                    graph={graph}
                    expandedGraph={expandedGraph}
                  />,
                ];
              }
              /* istanbul ignore next */
              if (label === 'graphDetails.legend.meanBloodGlucose') {
                return [
                  ...acc,
                  <CheckboxContainerComponent
                    t={t}
                    key={`legend-item-${label}`}
                    id={'graphs.detailGraph.meanBloodGlucose'}
                    modelPath={'.showMeanBloodGlucose'}
                    icon={<MeanBloodGlucoseIcon />}
                    graphType={graphType}
                    graph={graph}
                    expandedGraph={expandedGraph}
                  />,
                ];
              }
              /* istanbul ignore next */
              if (label === 'graphDetails.legend.carbohydrates') {
                return [
                  ...acc,
                  <CheckboxContainerComponent
                    t={t}
                    key={`legend-item-${label}`}
                    id={'graphs.detailGraph.carbohydrates'}
                    modelPath={'.showCarbohydrates'}
                    icon={<CarbohydratesToggleIcon />}
                    graphType={graphType}
                    graph={graph}
                    expandedGraph={expandedGraph}
                  />,
                ];
              }
              /* istanbul ignore next */
              if (label === 'graphDetails.legend.insulinAll') {
                return [
                  ...acc,
                  <CheckboxContainerComponent
                    t={t}
                    key={`legend-item-${label}`}
                    id={'graphDetails.legend.insulinAll'}
                    modelPath={'.showInsulin'}
                    icon={<InsulinToggleIcon />}
                    graphType={graphType}
                    graph={graph}
                    expandedGraph={expandedGraph}
                  />,
                ];
              }
              /* istanbul ignore next */
              if (label === 'graphDetails.legend.basalRate') {
                return [
                  ...acc,
                  <CheckboxContainerComponent
                    t={t}
                    key={`legend-item-${label}`}
                    id={'graphs.detailGraph.basalRate'}
                    modelPath={'.showBasalRate'}
                    icon={<BasalToggleIcon />}
                    graphType={graphType}
                    graph={graph}
                    expandedGraph={expandedGraph}
                  />,
                ];
              }
              /* istanbul ignore next */
              if (label === 'graphDetails.legend.connectingLines') {
                return [
                  ...acc,
                  <CheckboxContainerComponent
                    t={t}
                    key={`legend-item-${label}`}
                    id={'graphs.detailGraph.connectingLines'}
                    modelPath={'.showBloodGlucoseLines'}
                    icon={<ConnectingLinesIcon />}
                    graphType={graphType}
                    graph={graph}
                    expandedGraph={expandedGraph}
                  />,
                ];
              }
              /* istanbul ignore next */
              if (label === 'graphs.detailGraph.gridLines') {
                return [
                  ...acc,
                  <CheckboxContainerComponent
                    t={t}
                    key={`legend-item-${label}`}
                    id={'graphs.detailGraph.gridLines'}
                    modelPath={'.showGridLines'}
                    icon={<GridIcon />}
                    graphType={graphType}
                    graph={graph}
                    expandedGraph={expandedGraph}
                  />,
                ];
              }
              /* istanbul ignore next */
              return [
                ...acc,
                <LegendItem
                  {...{ icon, label }}
                  key={`legend-item-${label}`}
                />,
              ];
            } else {
              return acc;
            }
          }, [])}
        </LegendList>
      ))}
    </CheckboxesForm>
  </LegendListsContainer>
);

export const DetailGraphLegend = withTranslation(
  withTheme(DetailGraphLegendComponent),
);

import { getConfig } from '@roche/roche-common';

const { REACT_APP_PUBLIC_ASSETS_BASE_URL } = getConfig();

// MANUFACTURER INFO KEYS
export const MANUFACTURER_INFO_MANUFACTURER_KEY =
  'manufacturerInfo.manufacturer';
export const MANUFACTURER_INFO_ADDRESS_LINE1_KEY =
  'manufacturerInfo.addressLine1';
export const MANUFACTURER_INFO_ADDRESS_LINE2_KEY =
  'manufacturerInfo.addressLine2';
export const MANUFACTURER_INFO_ADDRESS_LINE3_KEY =
  'manufacturerInfo.addressLine3';
export const MANUFACTURER_INFO_ADDRESS_LINE4_KEY =
  'manufacturerInfo.addressLine4';
export const MANUFACTURER_INFO_MANUFACTURE_DATE_KEY =
  'manufacturerInfo.manufactureDate';
export const MANUFACTURER_INFO_GEN_INFO_RDCP_DATE_KEY =
  'manufacturerInfo.genInfoRdcpDate';
export const MANUFACTURER_SWITZ_NAME_KEY = 'manufacturerSwitz.name';
export const MANUFACTURER_SWITZ_ADDRESS_LINE1_KEY =
  'manufacturerSwitz.addressLine1';
export const MANUFACTURER_SWITZ_ADDRESS_LINE2_KEY =
  'manufacturerSwitz.addressLine2';
export const MANUFACTURER_SWITZ_ADDRESS_LINE3_KEY =
  'manufacturerSwitz.addressLine3';
export const MANUFACTURER_SWITZ_ADDRESS_LINE4_KEY =
  'manufacturerSwitz.addressLine4';
export const MANUFACTURER_SWITZ_PHONE_NUMBER_KEY =
  'manufacturerSwitz.phoneNumber';
export const MANUFACTURER_SWITZ_EMAIL_KEY = 'manufacturerSwitz.email';
export const MANUFACTURER_BRAZIL_NAME_KEY = 'manufacturerBrazil.name';
export const MANUFACTURER_BRAZIL_ADDRESS_LINE1_KEY =
  'manufacturerBrazil.addressLine1';
export const MANUFACTURER_BRAZIL_ADDRESS_LINE2_KEY =
  'manufacturerBrazil.addressLine2';
export const MANUFACTURER_BRAZIL_ADDRESS_LINE3_KEY =
  'manufacturerBrazil.addressLine3';
export const MANUFACTURER_BRAZIL_ADDRESS_LINE4_KEY =
  'manufacturerBrazil.addressLine4';
export const MANUFACTURER_BRAZIL_ADDRESS_LINE5_KEY =
  'manufacturerBrazil.addressLine5';
export const MANUFACTURER_BRAZIL_ADDRESS_LINE6_KEY =
  'manufacturerBrazil.addressLine6';
export const MANUFACTURER_AUSTRALIA_NAME_KEY =
  'manufacturerAustralia.addressLine1';
export const MANUFACTURER_AUSTRALIA_ADDRESS_LINE1_KEY =
  'manufacturerAustralia.addressLine2';
export const MANUFACTURER_AUSTRALIA_ADDRESS_LINE2_KEY =
  'manufacturerAustralia.addressLine3';
export const MANUFACTURER_AUSTRALIA_ADDRESS_LINE3_KEY =
  'manufacturerAustralia.addressLine4';
export const MANUFACTURER_MALAYSIA_NAME_KEY =
  'manufacturerMalaysia.addressLine1';
export const MANUFACTURER_MALAYSIA_ADDRESS_LINE1_KEY =
  'manufacturerMalaysia.addressLine2';
export const MANUFACTURER_MALAYSIA_ADDRESS_LINE2_KEY =
  'manufacturerMalaysia.addressLine3';
export const MANUFACTURER_MALAYSIA_ADDRESS_LINE3_KEY =
  'manufacturerMalaysia.addressLine4';
export const MANUFACTURER_MALAYSIA_ADDRESS_LINE4_KEY =
  'manufacturerMalaysia.addressLine5';
export const MANUFACTURER_MALAYSIA_ADDRESS_LINE5_KEY =
  'manufacturerMalaysia.addressLine6';

export const MANUFACTURER_INFO_EXPLANATION_SYMBOLS_DEVICE_ID_KEY =
  'manufacturerInfo.explanationSymbols.deviceId';
export const MANUFACTURER_INFO_GENERAL_KEY = 'manufacturerInfo.general';
export const MANUFACTURER_INFO_VALID_INFO_FOR_KEY =
  'manufacturerInfo.validInfoFor';
export const MANUFACTURER_INFO_VERSION_KEY = 'manufacturerInfo.version';
export const MANUFACTURER_INFO_CAUTION_KEY =
  'manufacturerInfo.explanationSymbols.caution';
export const MANUFACTURER_INFO_MED_DEVICE_KEY =
  'manufacturerInfo.explanationSymbols.medDevice';
export const MANUFACTURER_INFO_CE_INFO_KEY = 'manufacturerInfo.ceInfo';
export const MANUFACTURER_INFO_CONSULT_ELECTRONIC_KEY =
  'manufacturerInfo.consultElectronic';
export const MANUFACTURER_INFO_EXPLANATION_SYMBOLS_TITLE_KEY =
  'manufacturerInfo.explanationSymbols.title';
export const MANUFACTURER_INFO_EXPLANATION_SYMBOLS_DESCRIPTION_KEY =
  'manufacturerInfo.explanationSymbols.description';
export const MANUFACTURER_ICON_ALT = 'manufacturer icon';
export const DATE_OF_MANUFACTURE_ICON_ALT = 'date of manufacture icon';
export const IMPORTER_CH_ICON_ALT = 'importer CH icon';
export const MEDICAL_DEVICE_ICON_ALT = 'medical device icon';
export const MEDICAL_DEVICE_ID_ICON_ALT = 'medical device id icon';
export const CE_CODE_ICON_ALT = 'CE code icon';
export const ADDITIONAL_INFORMATION_ALT = 'additional information';
export const IMPORTER_BR_ICON_ALT = 'importer BR icon';
export const IMPORTER_AU_ICON_ALT = 'importer AU icon';
export const IMPORTER_MY_ICON_ALT = 'importer MY icon';
export const REGULATORY_PATH = '/images/regulatory/';
export const ICON_TYPE = 'svg';
export const ICON_WIDTH = 40;
export const MANUFACTURER_INFO = 'manufacturerInfo';

// ICONS
export const generateAssetURL = (host, assetFolder, iconType, fileName) => {
  return `${host}${assetFolder}${iconType}/${fileName}`;
};

export const generateIfusRegulatoryAssetURL = (fileName) => {
  return generateAssetURL(
    REACT_APP_PUBLIC_ASSETS_BASE_URL,
    REGULATORY_PATH,
    ICON_TYPE,
    fileName,
  );
};

export const IMPORTER_CH_ICON_URL =
  generateIfusRegulatoryAssetURL('sym_mdr_ch_rep.svg');
export const IMPORTER_BR_ICON_URL =
  generateIfusRegulatoryAssetURL('sym_mdr_br_rep.svg');
export const MEDICAL_DEVICE_ICON_URL =
  generateIfusRegulatoryAssetURL('sym_mdr_md.svg');
export const MEDICAL_DEVICE_ID_ICON_URL =
  generateIfusRegulatoryAssetURL('sym_mdr_udi.svg');
export const CE_CODE_ICON_URL =
  generateIfusRegulatoryAssetURL('ce_marking_mdr.svg');
export const ADDITIONAL_INFO_ICON_URL =
  generateIfusRegulatoryAssetURL('user_manual.svg');
export const MANUFACTURER_ICON_URL =
  generateIfusRegulatoryAssetURL('manufacture.svg');
export const MANUFACTURER_ID_ICON_URL = generateIfusRegulatoryAssetURL(
  'manufacturer_date.svg',
);
export const IMPORTER_AU_ICON_URL =
  generateIfusRegulatoryAssetURL('sym_mdr_au_rep.svg');
export const IMPORTER_MY_ICON_URL =
  generateIfusRegulatoryAssetURL('sym_mdr_my_rep.svg');

// COUNTRIES DATA
export const COUNTRIES_DATA = [
  {
    iconAlt: IMPORTER_CH_ICON_ALT,
    iconUrl: IMPORTER_CH_ICON_URL,
    addressKeys: [
      MANUFACTURER_SWITZ_NAME_KEY,
      MANUFACTURER_SWITZ_ADDRESS_LINE1_KEY,
      MANUFACTURER_SWITZ_ADDRESS_LINE2_KEY,
      MANUFACTURER_SWITZ_ADDRESS_LINE3_KEY,
      MANUFACTURER_SWITZ_ADDRESS_LINE4_KEY,
      MANUFACTURER_SWITZ_PHONE_NUMBER_KEY,
      MANUFACTURER_SWITZ_EMAIL_KEY,
    ],
  },
  {
    iconAlt: IMPORTER_BR_ICON_ALT,
    iconUrl: IMPORTER_BR_ICON_URL,
    addressKeys: [
      MANUFACTURER_BRAZIL_NAME_KEY,
      MANUFACTURER_BRAZIL_ADDRESS_LINE1_KEY,
      MANUFACTURER_BRAZIL_ADDRESS_LINE2_KEY,
      MANUFACTURER_BRAZIL_ADDRESS_LINE3_KEY,
      MANUFACTURER_BRAZIL_ADDRESS_LINE4_KEY,
      MANUFACTURER_BRAZIL_ADDRESS_LINE5_KEY,
      MANUFACTURER_BRAZIL_ADDRESS_LINE6_KEY,
    ],
  },
  {
    iconAlt: IMPORTER_AU_ICON_ALT,
    iconUrl: IMPORTER_AU_ICON_URL,
    addressKeys: [
      MANUFACTURER_AUSTRALIA_NAME_KEY,
      MANUFACTURER_AUSTRALIA_ADDRESS_LINE1_KEY,
      MANUFACTURER_AUSTRALIA_ADDRESS_LINE2_KEY,
      MANUFACTURER_AUSTRALIA_ADDRESS_LINE3_KEY,
    ],
  },
  {
    iconAlt: IMPORTER_MY_ICON_ALT,
    iconUrl: IMPORTER_MY_ICON_URL,
    addressKeys: [
      MANUFACTURER_MALAYSIA_NAME_KEY,
      MANUFACTURER_MALAYSIA_ADDRESS_LINE1_KEY,
      MANUFACTURER_MALAYSIA_ADDRESS_LINE2_KEY,
      MANUFACTURER_MALAYSIA_ADDRESS_LINE3_KEY,
      MANUFACTURER_MALAYSIA_ADDRESS_LINE4_KEY,
      MANUFACTURER_MALAYSIA_ADDRESS_LINE5_KEY,
    ],
  },
];

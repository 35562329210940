import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { withRouter } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { selectFhirPermission } from 'src/domains/permissions/store/permissions.selectors';
import { TimeIntervalsFilterComponent } from './time-intervals-filter.component';
import { calculateBloodGlucoseStartDate } from 'src/domains/diagnostics/scenes/blood-glucose-overview/store/blood-glucose-overview.utils';
import { NUMBER_BLOOD_GLUCOSE_OVERVIEW_COLUMNS } from 'src/domains/diagnostics/scenes/blood-glucose-overview/store/blood-glucose-overview.constants';
import {
  selectBGOverviewEndDate,
  selectBGOverviewTimeInterval,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import { selectPatientLastMeasurementDate } from 'src/domains/diagnostics/store/selectors/patient-date-range.selector';
import { selectPatientFhirId } from 'src/domains/patient/store/patient/patient.selector';
import { selectUnitMeasurementForService } from 'src/domains/patient-dashboard/store/patient-date-range/patient-date-range.selector';
import {
  fetchBGOverviewClinicalData,
  fetchBGOverviewClinicalDataFromFhir,
  onBloodGlucoseOverviewEndDateChange,
} from 'src/domains/patient-dashboards/bg/store/bg.actions';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import { convertISO } from 'src/shared/utils/date';
import { DateTime } from 'luxon';

type TimeIntervalsFilterProps = {
  match: { params: { id: string } };
  endDate: DateTime;
  lastMeasurementDate: DateTime;
  interval: string;
  onFetchBGOverviewClinicalData: ({
    patientId,
    startDate,
    endDate,
  }: {
    patientId: string;
    startDate: string;
    endDate: string;
  }) => void;
  onFetchBGOverviewClinicalDataFromFhir: ({
    patientFhirId,
    startDate,
    endDate,
    bgUnit,
  }: {
    patientFhirId: string;
    startDate: DateTime;
    endDate: DateTime;
    bgUnit: string;
  }) => void;
  onBloodGlucoseOverviewEndDateChange: (endDate: Date) => void;
  hasUserFhirPermission: boolean;
  patientFhirId: string;
  bgUnit: string;
};

const mapDispatchToProps = mapDispatchers({
  onFetchBGOverviewClinicalData: fetchBGOverviewClinicalData.start,
  onFetchBGOverviewClinicalDataFromFhir:
    fetchBGOverviewClinicalDataFromFhir.start,
  onBloodGlucoseOverviewEndDateChange,
  updateTimeIntervalsFilter: actions.change,
});

export const mapStateToProps = createStructuredSelector({
  endDate: selectBGOverviewEndDate,
  interval: selectBGOverviewTimeInterval,
  lastMeasurementDate: selectPatientLastMeasurementDate,
  hasUserFhirPermission: selectFhirPermission,
  patientFhirId: selectPatientFhirId,
  bgUnit: selectUnitMeasurementForService,
});

const getBloodGlucoseMeasurementsByProps = (
  props: TimeIntervalsFilterProps,
) => {
  const {
    match: { params },
    endDate,
    interval,
    onFetchBGOverviewClinicalData,
    onFetchBGOverviewClinicalDataFromFhir,
    hasUserFhirPermission,
    patientFhirId,
    bgUnit,
  } = props;

  const patientId = params.id;

  const startDate = calculateBloodGlucoseStartDate(
    endDate,
    interval,
    NUMBER_BLOOD_GLUCOSE_OVERVIEW_COLUMNS,
  );
  hasUserFhirPermission
    ? onFetchBGOverviewClinicalDataFromFhir({
        patientFhirId,
        startDate,
        endDate,
        bgUnit,
      })
    : onFetchBGOverviewClinicalData({
        patientId,
        startDate: startDate.toString(),
        endDate: endDate.toString(),
      });
};

const addHandlers = withHandlers({
  onChangeInterval: (props: TimeIntervalsFilterProps) => (interval: string) => {
    const { onBloodGlucoseOverviewEndDateChange, lastMeasurementDate } = props;
    const endDate = isNaN(lastMeasurementDate as unknown as number)
      ? convertISO('1970-01-01T23:59:59')
      : lastMeasurementDate;

    getBloodGlucoseMeasurementsByProps({ ...props, interval, endDate });
    onBloodGlucoseOverviewEndDateChange(endDate.toJSDate());
  },
});

export const TimeIntervalsFilter = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  addHandlers,
)(TimeIntervalsFilterComponent);

import { divide, equals, includes, isNil, pipe, prop } from 'ramda';

import {
  addDays,
  convertJSDate,
  convertStringToJSDate,
  formatDateString,
  isDateStringValid,
  jsDateToISOString,
  now,
  setTimezone,
  toJSDate,
} from 'src/shared/utils/date';
import { hasValue } from 'src/shared/utils/validation-helpers';

import {
  DELIVERY_STATUS_COMMENT_STATES,
  STRIPS_PER_TUBE,
  TRAFFIC_COLOR_MAP,
  TRAFFIC_LIGHT_STATES,
} from './strip-delivery.constants';

const AVERAGE_WEEKS_PER_MONTH = 4.348214;

export const convertStripConsumptionToTrafficLightState = (percentConsumed) => {
  const percentConsumedInt = percentConsumed
    ? Math.floor(percentConsumed)
    : null;
  switch (true) {
    case isNil(percentConsumedInt):
      return TRAFFIC_LIGHT_STATES.DISABLED;
    case percentConsumedInt < 70:
      return TRAFFIC_LIGHT_STATES.DO_NOT_DELIVER;
    case percentConsumedInt > 100:
      return TRAFFIC_LIGHT_STATES.DO_NOT_DELIVER;
    case percentConsumedInt < 80:
      return TRAFFIC_LIGHT_STATES.DELIVER_WITH_ALERT;
    default:
      return TRAFFIC_LIGHT_STATES.DELIVER;
  }
};

const convertStatusDescriptionToTrafficLightState = (
  status,
  stripsConsumedPercentage,
) => {
  const percentConsumedInt = stripsConsumedPercentage
    ? Math.floor(stripsConsumedPercentage)
    : null;
  switch (status) {
    case isNil(percentConsumedInt):
      return TRAFFIC_LIGHT_STATES.DISABLED;
    case 'DISABLED':
      return TRAFFIC_LIGHT_STATES.DISABLED;
    case 'DO_NOT_DELIVER':
      return TRAFFIC_LIGHT_STATES.DO_NOT_DELIVER;
    case 'DELIVER_WITH_ALERT':
      return TRAFFIC_LIGHT_STATES.DELIVER_WITH_ALERT;
    default:
      return TRAFFIC_LIGHT_STATES.DELIVER;
  }
};

export const convertStatusDescriptionToTrafficLightColor = (
  status,
  stripsConsumedPercentage,
) =>
  prop(
    convertStatusDescriptionToTrafficLightState(
      status,
      stripsConsumedPercentage,
    ),
    TRAFFIC_COLOR_MAP,
  );

export const convertStripConsumptionToTrafficLightColor = (percentConsumed) =>
  prop(
    convertStripConsumptionToTrafficLightState(percentConsumed),
    TRAFFIC_COLOR_MAP,
  );

export const getTrafficLightColorFromStatus = (trafficLightStatus) =>
  TRAFFIC_COLOR_MAP[trafficLightStatus || TRAFFIC_LIGHT_STATES.DISABLED];

export const convertDateStringToDateTime = pipe(
  convertStringToJSDate,
  convertJSDate,
);

export const daysInWeeks = (numOfWeeks) => numOfWeeks * 7;

export const daysInMonths = (numOfMonths) =>
  Math.round(daysInWeeks(numOfMonths * AVERAGE_WEEKS_PER_MONTH));

export const addTime = (duration, unit) => (date) => {
  switch (unit.toLowerCase()) {
    case 'months':
      return addDays(daysInMonths(duration))(date);
    case 'weeks':
      return addDays(daysInWeeks(duration))(date);
    case 'days':
      return addDays(duration)(date);
    default:
      return date;
  }
};

export const numberOfStripsToTubes = (strips) =>
  strips && divide(strips, STRIPS_PER_TUBE);

export const calculateNextDeliveryDate = (
  lastCollectedDate,
  { duration, unit },
) =>
  isDateStringValid(lastCollectedDate) && hasValue(duration) && hasValue(unit)
    ? pipe(
        convertDateStringToDateTime,
        addTime(duration, unit),
        toJSDate,
        jsDateToISOString,
      )(lastCollectedDate)
    : null;

export const calculateNewPatientDeliveryDate = (frequency) => {
  const currentTimeIso = pipe(
    setTimezone('Etc/GMT+0'),
    toJSDate,
    jsDateToISOString,
  )(now());
  return calculateNextDeliveryDate(currentTimeIso, frequency);
};

export const formatStripDeliveryDateString = (dateString, errorString) =>
  formatDateString({
    dateString,
    errorString,
    format: 'd LLLL yyyy',
  });

export const shouldTriggerNotificationModal = ({
  hasModal,
  hasForceStatus,
  lastTrafficLightStatusCommentState,
  lastTrafficLightStatus,
}) =>
  hasModal &&
  hasForceStatus &&
  equals(
    lastTrafficLightStatusCommentState,
    DELIVERY_STATUS_COMMENT_STATES.IMPORTANT,
  ) &&
  includes(lastTrafficLightStatus, [
    TRAFFIC_LIGHT_STATES.DO_NOT_DELIVER,
    TRAFFIC_LIGHT_STATES.DELIVER_WITH_ALERT,
  ]);

export const shouldTriggerSendPatientStatusModal = ({
  hasModal,
  trafficLightStatus,
  hasForceStatus,
  trafficLightStatusForced,
}) =>
  hasModal &&
  !hasForceStatus &&
  !trafficLightStatusForced &&
  includes(trafficLightStatus, [
    TRAFFIC_LIGHT_STATES.DO_NOT_DELIVER,
    TRAFFIC_LIGHT_STATES.DELIVER_WITH_ALERT,
  ]);

import { addDomainNamespace } from 'src/domains/strip-management/domain-namespace';

import { createRequestActionTypes } from '../../../../../app/store/request/request.actions';

const DOMAIN_NAMESPACE = 'PATIENT_THERAPY';
const addNamespacing = (actionName) =>
  addDomainNamespace(actionName, DOMAIN_NAMESPACE);

export const GET_THERAPIES = addNamespacing('GET_THERAPIES');
export const GET_THERAPIES_REQUEST = createRequestActionTypes(GET_THERAPIES);

import { SubmitModalProps } from '../../../../widgets/top-navigation-jello/components/time-tracker-export-modal/time-tracker-export-modal.types';
import {
  getJSON,
  createAuthHeader,
} from '../../../../shared/utils/service/service.utils';

import { ENDPOINTS } from '../service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const PatientSessionExportLoaderImpl = (
  { startDate, endDate }: SubmitModalProps,
  accessToken: string,
) =>
  getJSON(
    endpointWithParams(ENDPOINTS.patientsSessionExport, { startDate, endDate }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );

export const PatientsSessionExportTransformImpl: any = ({ report }) => report;

export const PatientsSessionExportServiceImpl: any =
  (load, transform) => (query, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);

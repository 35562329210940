import React, { useState } from 'react';

import { JelloTooltip } from '../../../../shared/design-system/jello-tooltip/jello-tooltip.component';
import { RenderIfElse } from '../../../../shared/utils/render-if';
import { JelloPopoverBaseComponent } from './jello-popover-base.component';
import { JelloPopoverTooltipProps } from './jello-popover.types';

export const JelloPopoverComponent: React.FunctionComponent<
  JelloPopoverTooltipProps
> = ({
  mainTestId,
  accessibilityText,
  popoverAlignment,
  popoverPosition,
  popoverStyle,
  popoverContentStyle,
  tooltipProps,
  clickableComponent,
  children,
}) => {
  const [currentVisibility, setCurrentVisibility] = useState(true);

  const popoverProps = {
    mainTestId,
    currentVisibility,
    setCurrentVisibility: (val: boolean) => setCurrentVisibility(val),
    accessibilityText,
    popoverAlignment,
    popoverPosition,
    popoverStyle,
    popoverContentStyle,
    clickableComponent,
    children,
  };

  return (
    <RenderIfElse validate={tooltipProps?.whitTooltip && currentVisibility}>
      <JelloTooltip
        id={mainTestId}
        label={tooltipProps?.tooltipLabel || ''}
        position={tooltipProps?.tooltipPosition}
        style={tooltipProps?.tooltipStyle}
      >
        <JelloPopoverBaseComponent {...popoverProps} />
      </JelloTooltip>
      <JelloPopoverBaseComponent {...popoverProps} />
    </RenderIfElse>
  );
};

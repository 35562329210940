import {
  PatientSessionEndLoaderImpl,
  PatientSessionEndServiceImpl,
  PatientSessionEndTransformImpl,
} from './patient-session-end.service';
// mockLoaderError,
import { mockLoaderSuccess } from './patient-session-end.mock';

export const PatientSessionEndFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoaderSuccess : PatientSessionEndLoaderImpl;
  return PatientSessionEndServiceImpl(loader, PatientSessionEndTransformImpl);
};

import { combineEpics, Epic } from 'redux-observable';
import { State } from 'src/app/store/app.types';
import { createModal } from 'src/shared/design-system/modal/store/modal/modal.actions';
import { MODAL_TYPES } from 'src/shared/design-system/modal/store/modal/modal.constants';

import {
  ExpiringModalActions,
  ExpiringModalActionTypes,
} from './expiring-modal.types';

/* istanbul ignore next */ export const showExpiringModalEpic =
  (): Epic<ExpiringModalActions, State> => (action$) =>
    action$
      .ofType(ExpiringModalActionTypes.SHOW_EXPIRING_MODAL)
      .switchMap((action) => {
        return [
          createModal({
            key: MODAL_TYPES.TTL_EXPIRING,
            data: { ...action.payload },
          }),
        ];
      });

export const expiringModalEpics = combineEpics(showExpiringModalEpic());

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import { withToolTip } from 'src/shared/utils/with-tool-tip';

import { LogbookStats } from './logbook-stats.component';
import { logbookStatsConnector } from './logbook-stats.selector';

const dispatchers = mapDispatchers({});

export const LogbookStatsContainer = compose(
  withRouter,
  connect(logbookStatsConnector, dispatchers),
  withToolTip,
  withTranslation,
)(LogbookStats);

import { countlyEventTrigger } from 'src/app/navigation/countly';

import {
  GRAPH_TYPE_DETAILS,
  GRAPH_TYPE_TREND,
  GRAPHS,
  LOGBOOK_TYPE_24HOUR,
  LOGBOOK_TYPE_DETAILS,
  LOGBOOK_TYPE_DETAILS_COUNTLY,
  LOGBOOK_TYPE_DIARY,
  LOGBOOK_TYPE_STATS,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { graphTypeSelected } from 'src/domains/diagnostics/components/graph-controls/graph-controls.utils';

import {
  GRAPH_LOGBOOK_TYPE_KEYS,
  GRAPH_LOGBOOK_TYPE_SEGMENT_KEYS,
  GRAPH_SEGMENT_VALUES,
  GRAPH_TYPE_KEYS,
  GRAPH_TYPE_LOGBOOK_SEGMENT_VALUES,
  GRAPH_TYPE_SEGMENT_KEYS,
  GRAPH_TYPE_SEGMENT_VALUES,
} from './graph-tab-item.countly.constans';

import { BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS } from '../graph-controls/graph-controls.countly.constans';

export const graphTypeButtonSelected = (graphTypeButton) => {
  switch (graphTypeButton) {
    case GRAPH_TYPE_DETAILS:
      return GRAPH_TYPE_SEGMENT_VALUES.SELECTED_GRAPH_TYPE_LINE;
    case GRAPH_TYPE_TREND:
      return GRAPH_TYPE_SEGMENT_VALUES.SELECTED_GRAPH_TYPE_BOX;
    case LOGBOOK_TYPE_DIARY:
      return GRAPH_TYPE_LOGBOOK_SEGMENT_VALUES.SELECTED_LOGBOOK_TYPE_DAILY;
    case LOGBOOK_TYPE_STATS:
      return GRAPH_TYPE_LOGBOOK_SEGMENT_VALUES.SELECTED_LOGBOOK_TYPE_STATISTICS;
    case LOGBOOK_TYPE_DETAILS_COUNTLY:
      return GRAPH_TYPE_LOGBOOK_SEGMENT_VALUES.SELECTED_LOGBOOK_TYPE_LOGBOOK;
    case LOGBOOK_TYPE_24HOUR:
      return GRAPH_TYPE_LOGBOOK_SEGMENT_VALUES.SELECTED_LOGBOOK_TYPE_24H;
    default:
      return GRAPH_SEGMENT_VALUES.SELECTED_TYPE_NONAME;
  }
};

export const graphTypeCountly = (graph, graphTypeButton) => {
  if (
    graph === GRAPHS.TREND ||
    graph === GRAPHS.STANDARD_DAY ||
    graph === GRAPHS.STANDARD_WEEK
  ) {
    countlyEventTrigger(GRAPH_TYPE_KEYS.GRAPH_TYPE_CHANGED, {
      [GRAPH_TYPE_SEGMENT_KEYS.GRAPH_TYPE_SELECTED]:
        graphTypeButtonSelected(graphTypeButton),
      [BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS.BG_GRAPH_TYPE]:
        graphTypeSelected(graph),
    });
  } else if (graph === GRAPHS.LOGBOOK) {
    countlyEventTrigger(GRAPH_LOGBOOK_TYPE_KEYS.GRAPH_LOGBOOK_TYPE_CHANGED, {
      [GRAPH_LOGBOOK_TYPE_SEGMENT_KEYS.GRAPH_LOGBOOK_TYPE_SELECTED]:
        graphTypeButtonSelected(
          graphTypeButton === LOGBOOK_TYPE_DETAILS
            ? LOGBOOK_TYPE_DETAILS_COUNTLY
            : graphTypeButton,
        ),
    });
  }
};

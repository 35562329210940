import styled from 'styled-components';

import { spacing } from 'src/app/styles/spacing';

import {
  COLLAPSED_STD_GRAPH_HEIGHT_IN_REM,
  MIN_EXPANDED_STD_GRAPH_HEIGHT_IN_REM,
} from '../../graph.constants';

export const MetabolicWrapperDiv = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  min-height: ${(props) =>
    props.collapsed
      ? COLLAPSED_STD_GRAPH_HEIGHT_IN_REM
      : MIN_EXPANDED_STD_GRAPH_HEIGHT_IN_REM};
  height: ${(props) =>
    props.collapsed ? COLLAPSED_STD_GRAPH_HEIGHT_IN_REM : '100%'};
  margin: 0 ${spacing.two} 0;
  flex-direction: column;
`;

MetabolicWrapperDiv.displayName = 'MetabolicWrapperDiv';

import styled from 'styled-components';

import { spacing } from 'src/app/styles/spacing';
import { colors } from 'src/app/styles/colors';
import { convertPxToRem } from 'src/shared/utils/rem-calc';

export const Container = styled.span`
  margin-top: -15px;
  margin-left: ${spacing.three};
  padding-right: ${spacing.three};
  border-right: 1px solid ${colors.silver};
  display: inline;
  vertical-align: inherit;
`;
Container.displayName = 'Container';

type ChangeTypeButtonType = {
  active: boolean;
};

export const ChangeTypeButton = styled.button`
  cursor: pointer;
  outline: none;
  display: inline-flex;

  padding: ${convertPxToRem(11)} ${spacing.three};
  border: 1px solid ${colors.quartzBlue};
  border-right: none;
  background: ${(props: ChangeTypeButtonType) =>
    props.active ? colors.blueMarineAlpha : colors.white};

  &:first-child {
    border-radius: 3px 0 0 3px;
  }
  &:last-child {
    border-radius: 0 3px 3px 0;
    border-right: 1px solid ${colors.quartzBlue};
  }
  &:only-child {
    border-radius: 3px;
    border-right: 1px solid ${colors.quartzBlue};
  }
`;
ChangeTypeButton.displayName = 'ChangeTypeButton';

import * as React from 'react';
import { isEmpty } from 'ramda';

import { TablePrimary } from 'src/domains/diagnostics/components/table-primary/table-primary.component';
import {
  GridContainer,
  GridItem,
} from 'src/shared/design-system/grid-layout/index';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';

import { BasalBolusGrid } from './tab-basal-bolus.style';
import {
  convertBasalProfilesToTable,
  convertBolusTimeBlocksToTable,
  generateBasalHeader,
  generateBolusHeader,
  normalizeTime,
  renderTBRtable,
} from './tab-basal-bolus.utils';
import {
  getLabelFromAttribute,
  getUnitFromAttribute,
} from './tab-basal-bolus.constants';

import {
  getCellStyledProps,
  getRowStyledProps,
} from '../../device-settings.utils';
import { BasalIPProfile } from '../../device-settings.types';
import { GenerateTable } from '../tab-general/tab-general.utils';
import { Disclaimer } from '../disclaimer/disclaimer.component';

export interface Props {
  ipBasalProfiles: BasalIPProfile;
  selectActiveIpProfile: number;
  disabledBasalProfileIds: number[];
}

export const TabBasalBolusComponent: React.FunctionComponent<any> = ({
  isPreviousSettingsSelected,
  ipBasalProfiles,
  activeIpProfile,
  bolusTimeBlocks,
  bolusAdvice,
  prevBolusAdvice,
  bolusHealthEvents,
  prevBolusHealthEvents,
  meterSettings,
  disabledBasalProfileIds,
  customTBR,
  previousCustomTBR,
  prevTimeBlocks,
  previousIpBasalProfiles,
  t,
  isCollapsedView,
  is12HourFormat,
}) => {
  const showPreviousValue = isPreviousSettingsSelected;

  const basalProfileTableData = convertBasalProfilesToTable(
    ipBasalProfiles,
    previousIpBasalProfiles,
    activeIpProfile,
    disabledBasalProfileIds,
    isPreviousSettingsSelected,
    t,
    isCollapsedView,
    is12HourFormat,
  );

  const bolusTimeBlocksTableData = convertBolusTimeBlocksToTable(
    bolusTimeBlocks,
    prevTimeBlocks,
    meterSettings,
    isPreviousSettingsSelected,
    isCollapsedView,
    is12HourFormat,
  );

  const TableGenerator = new GenerateTable({
    getLabelFromAttribute,
    getUnitFromAttribute,
    units: meterSettings,
    showPreviousValue,
    comparePreviousValue: !isCollapsedView,
    t,
  });

  const bolusAdviceHeader: any = TableGenerator.getHeaderTable([
    [{ colSpan: 2, value: t('deviceSettings.tabBasalBolus.bolusAdvice') }],
  ]);

  const bolusAdviceBody: any = TableGenerator.getBodyTable(
    normalizeTime(bolusAdvice),
    normalizeTime(prevBolusAdvice),
  );

  const customTbrHeader = [
    {
      colSpan: 2,
      value: t('deviceSettings.tabBasalBolus.customTempBasalRate'),
    },
    {
      colSpan: 2,
      value: t('deviceSettings.tabBasalBolus.percentageAdjustment'),
    },
    { colSpan: 2, value: t('deviceSettings.tabBasalBolus.duration') },
  ];

  const bolusHealthEventsBody: any = TableGenerator.getBodyTable(
    bolusHealthEvents,
    prevBolusHealthEvents,
  );

  const bolusHealthEventsHeader: any = TableGenerator.getHeaderTable([
    [{ colSpan: 2, value: t('deviceSettings.tabBasalBolus.healthEvents') }],
  ]);
  const basalTBRTable = renderTBRtable(
    customTBR,
    previousCustomTBR,
    customTbrHeader,
    isPreviousSettingsSelected,
    isCollapsedView,
  );

  return (
    <GridContainer>
      <BasalBolusGrid span={6}>
        <TablePrimary
          bodyData={basalProfileTableData}
          headerData={generateBasalHeader(ipBasalProfiles, activeIpProfile, t)}
          rowStyles={getRowStyledProps()}
          cellStyles={getCellStyledProps()}
        />
        <RenderIf validate={isEmpty(basalProfileTableData)}>
          <Disclaimer />
        </RenderIf>
        <TablePrimary
          bodyData={basalTBRTable.body}
          headerData={basalTBRTable.header}
          rowStyles={getRowStyledProps()}
          cellStyles={getCellStyledProps()}
        />
        <RenderIf validate={isEmpty(basalTBRTable.body)}>
          <Disclaimer />
        </RenderIf>
      </BasalBolusGrid>
      <BasalBolusGrid span={6}>
        <TablePrimary
          bodyData={bolusTimeBlocksTableData}
          headerData={generateBolusHeader(bolusTimeBlocks, t)}
          rowStyles={getRowStyledProps()}
          cellStyles={getCellStyledProps()}
        />
        <RenderIf validate={isEmpty(bolusTimeBlocksTableData)}>
          <Disclaimer />
        </RenderIf>
        <GridContainer>
          <GridItem span={6}>
            <TablePrimary
              bodyData={bolusAdviceBody}
              headerData={bolusAdviceHeader}
              rowStyles={getRowStyledProps()}
              cellStyles={getCellStyledProps()}
            />
            <RenderIf validate={isEmpty(bolusAdviceBody)}>
              <Disclaimer />
            </RenderIf>
          </GridItem>
          <GridItem span={6}>
            <TablePrimary
              bodyData={bolusHealthEventsBody}
              headerData={bolusHealthEventsHeader}
              rowStyles={getRowStyledProps()}
              cellStyles={getCellStyledProps()}
            />
            <RenderIf validate={isEmpty(bolusHealthEventsBody)}>
              <Disclaimer />
            </RenderIf>
          </GridItem>
        </GridContainer>
      </BasalBolusGrid>
    </GridContainer>
  );
};

export default TabBasalBolusComponent;

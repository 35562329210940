import { AnnouncementsState } from './announcements.types';
import {
  CLOSE_WHATS_NEW_MODAL,
  FETCH_USER_ANNOUNCEMENTS_REQUEST,
  MARK_ANNOUNCEMENT_VISITED_REQUEST,
  OPEN_WHATS_NEW_MODAL,
} from './announcements.constants';

export const INITIAL_ANNOUNCEMENTS_STATE: AnnouncementsState = {
  userAnnouncements: null,
  markVisitedResponse: null,
  isFetchingAnnouncements: false,
  isMarkingAnnouncementVisited: false,
  userAnnouncementNotification: null,
};

export const announcementsReducer = (
  state = INITIAL_ANNOUNCEMENTS_STATE,
  action,
) => {
  switch (action.type) {
    case FETCH_USER_ANNOUNCEMENTS_REQUEST.START:
      return {
        ...state,
        isFetchingAnnouncements: true,
      };
    case FETCH_USER_ANNOUNCEMENTS_REQUEST.ERROR:
      return {
        ...state,
        isFetchingAnnouncements: false,
      };
    case FETCH_USER_ANNOUNCEMENTS_REQUEST.SUCCESS:
      return {
        ...state,
        isFetchingAnnouncements: false,
        userAnnouncements: action.payload,
      };
    case MARK_ANNOUNCEMENT_VISITED_REQUEST.START:
      return {
        ...state,
        isMarkingAnnouncementVisited: true,
      };
    case MARK_ANNOUNCEMENT_VISITED_REQUEST.ERROR:
      return {
        ...state,
        isMarkingAnnouncementVisited: false,
      };
    case MARK_ANNOUNCEMENT_VISITED_REQUEST.SUCCESS:
      return {
        ...state,
        isMarkingAnnouncementVisited: false,
        markVisitedResponse: action.payload,
        userAnnouncements: { ...state.userAnnouncements, isVisited: true },
      };
    case OPEN_WHATS_NEW_MODAL:
      return {
        ...state,
        userAnnouncementNotification: { ...action.payload },
      };

    case CLOSE_WHATS_NEW_MODAL:
      return {
        ...state,
        userAnnouncementNotification: null,
      };

    default:
      return {
        ...state,
      };
  }
};

// @ts-nocheck
import { formatDateString } from 'src/shared/utils/date';

import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';
import {
  GetClinicalDataFromFhirLoaderImplType,
  GetClinicalDataFromFhirServiceImplType,
} from './clinical-data-from-fhir.types';

export const GetClinicalDataFromFhirLoaderImpl: GetClinicalDataFromFhirLoaderImplType =
  (
    { patientFhirId, startDate, endDate, bgUnit, carbUnit },
    accessToken,
    gigyaToken,
  ) =>
    getJSON(
      endpointWithParams(ENDPOINTS.clinicalDataFromFhir, { patientFhirId }),
      {
        headers: {
          rangeStartDate: formatDateString({
            dateString: startDate,
            format: 'yyyy-LL-dd',
            timeZone: 'Etc/GMT+0',
          }),
          rangeEndDate: formatDateString({
            dateString: endDate,
            format: 'yyyy-LL-dd',
            timeZone: 'Etc/GMT+0',
          }),
          bgUnit,
          carbUnit,
          Authorization: createAuthHeader(accessToken),
        },
      },
    );

export const GetClinicalDataFromFhirServiceImpl: GetClinicalDataFromFhirServiceImplType =
  (load, transform) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);

import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { propEq, propOr } from 'ramda';
import {
  actions as reactReduxFormActions,
  ModelAction,
} from 'react-redux-form';
import { push } from 'react-router-redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { State } from 'src/app/store/app.types';
import {
  CountriesActions,
  CountriesActionType,
} from 'src/domains/patient/store/countries/countries.types';
import {
  DepartmentActions,
  DepartmentActionType,
  ProfileTypes,
} from 'src/domains/patient/store/department/department.types';
import {
  LanguagesActions,
  LanguagesActionType,
} from 'src/domains/patient/store/languages/languages.types';
import { clearPatientState } from 'src/domains/patient/store/patient/patient.action';

import { GET_CURRENT_USER } from 'src/app/store/user/user.constants';
import { patientLinks } from 'src/domains/patient/routes';
import { getRouterPathname } from 'src/app/navigation/store/navigation.selectors';
import { CreatePatientServiceImplType } from 'src/domains/patient/services/patient/create-patient/create-patient.types';
import { CreatePatientWithFhirLoaderImplType } from 'src/domains/patient/services/patient/create-patient-with-fhir/create-patient-with-fhir.types';
import { hasValue } from 'src/shared/utils/validation-helpers';
import { PROFESSIONAL_PROFILES } from 'src/widgets/forms/form.constants';

import { PatientFormModel } from 'src/domains/patient/components/forms/forms.types';
import {
  selectCurrentUserByEC6UserId,
  selectCurrentUserLanguageByLocale,
  selectDepartmentCountryId,
} from '../create-patient.selectors';

import {
  createPatientError,
  createPatientSuccess,
  getCreatedPatientError,
  getCreatedPatientStart,
  getCreatedPatientSuccess,
} from './create-patient.actions';
import {
  CreatedPatient,
  CreatePatientActions,
  CreatePatientActionType,
  CreatePatientStartAction,
  GetCreatedPatientStartAction,
} from './create-patient.types';

import {
  clearCreatePatientWithFhirState,
  createPatientWithFhirError,
  createPatientWithFhirSuccess,
} from '../create-patient-with-platform/store/create-patient-with-fhir/create-patient-with-fhir.actions';

import {
  CreatePatientWithFhirActionType,
  CreatePatientWithFhirStartAction,
} from '../create-patient-with-platform/store/create-patient-with-fhir/create-patient-with-fhir.types';
import { addAlertAction } from 'src/shared/design-system/alerts/store/alert.actions';
import { AlertType } from 'src/shared/design-system/alerts/store/alert.types';
import { ALERT_POSITION } from 'src/shared/design-system/alerts/store/alert.constans';

export const populateCreatePatientProfessionalsEpic =
  () => (action$: any, store: any) =>
    action$
      .ofType(DepartmentActionType.GET_PROFESSIONALS_SUCCESS)
      .flatMap((action) => {
        const currentUser = selectCurrentUserByEC6UserId(store.getState());
        const isPractitioner =
          currentUser &&
          (currentUser.user.profile === PROFESSIONAL_PROFILES.HCP_MASTER ||
            currentUser.user.profile ===
              PROFESSIONAL_PROFILES.GENERAL_PRACTITIONER);
        if (!currentUser || !isPractitioner) {
          return [];
        }
        return [
          reactReduxFormActions.change(
            `${PatientFormModel.healthInfo.full}${PatientFormModel.healthInfo.associatedProfessional}`,
            currentUser.id.toString(),
          ),
        ];
      });

export const populateCreatePatientLanguageEpic =
  () => (action$: any, store: any) =>
    action$
      .ofType(LanguagesActionType.FETCH_LANGUAGES_SUCCESS)
      .flatMap((action) => {
        const currentUserLanguage = selectCurrentUserLanguageByLocale(
          store.getState(),
        );
        const currentUserLanguageISOCode = propOr(
          '',
          'id',
          currentUserLanguage,
        );
        return [
          reactReduxFormActions.change(
            `${PatientFormModel.patientInfo.full}${PatientFormModel.patientInfo.language}`,
            currentUserLanguageISOCode,
            { silent: true },
          ),
        ];
      });

export const createPatientEpic: (
  createPatientService: CreatePatientServiceImplType,
) => Epic<FixMe, any> = (createPatientService) => (action$, store) =>
  action$
    .ofType(CreatePatientActionType.CREATE_PATIENT_START)
    .switchMap(({ payload }: CreatePatientStartAction) =>
      Observable.fromPromise(
        createPatientService(
          payload,
          selectAccessToken(store.getState()),
          selectGigyaToken(store.getState()),
        ),
      )
        .flatMap((data) => [
          createPatientSuccess(data),
          clearPatientState({
            clearRelatedPatientData: false,
          }),
          getCreatedPatientStart(data),
        ])
        .pipe(catchError((err) => Observable.of(createPatientError(err)))),
    );

export const createPatientWithFhirEpic: (
  createPatientWithFhirService: CreatePatientWithFhirLoaderImplType,
) => Epic<FixMe, any> = (createPatientWithFhirService) => (action$, store) =>
  action$
    .ofType(CreatePatientWithFhirActionType.CREATE_PATIENT_WITH_FHIR_START)
    .switchMap(({ payload }: CreatePatientWithFhirStartAction) =>
      Observable.fromPromise(
        createPatientWithFhirService(
          payload,
          selectAccessToken(store.getState()),
          selectGigyaToken(store.getState()),
        ),
      )
        .flatMap(({ patientProfileUrl }) => [
          clearPatientState(),
          createPatientWithFhirSuccess(),
          push(patientProfileUrl),
          addAlertAction({
            type: AlertType.SUCCESS,
            text: {
              [AlertType.SUCCESS]:
                'createPatientWithPlatform.alert.creationSuccess',
            },
            position: ALERT_POSITION.BOTTOM_RIGHT,
          }),
          clearCreatePatientWithFhirState(),
        ])
        .pipe(catchError((err) => Observable.of(createPatientWithFhirError()))),
    );

export const getCreatedPatientEpic: (
  getPatientService,
) => Epic<CreatePatientActions, any> =
  (getPatientService) => (action$, store) =>
    action$
      .ofType(CreatePatientActionType.GET_CREATED_PATIENT_START)
      .switchMap(({ payload }: GetCreatedPatientStartAction) =>
        Observable.fromPromise(
          getPatientService(
            payload,
            selectAccessToken(store.getState()),
            selectGigyaToken(store.getState()),
          ),
        )
          .map((response: CreatedPatient) => getCreatedPatientSuccess(response))
          .pipe(catchError((err) => Observable.of(getCreatedPatientError()))),
      );

export const updateAllowPatientAccessOnProfileChangeEpic: () => Epic<
  ModelAction,
  ModelAction
> = () => (action$) =>
  action$
    .ofType('rrf/change')
    .filter(propEq('model', PatientFormModel.profileTypeFull))
    .filter(propEq('value', ProfileTypes.homeDelivery))
    .mapTo(
      reactReduxFormActions.change(
        `${PatientFormModel.patientInfo.full}${PatientFormModel.patientInfo.allowPatientAccess}`,
        true,
      ),
    );

export const updateCreatePatient: () => Epic<any, any> = () => (action$) =>
  action$
    .ofType('rrf/change')
    .filter(propEq('model', PatientFormModel.patientInfo.full))
    .filter((action: any) => action.value === '[object Object]')
    .switchMap((action: any) => {
      return [
        reactReduxFormActions.load(
          `${PatientFormModel.patientInfo.full}`,
          action.currentValue,
        ),
      ];
    });
export const updateCreatePatientHealthInfo: () => Epic<any, any> =
  () => (action$) =>
    action$
      .ofType('rrf/change')
      .filter(propEq('model', PatientFormModel.healthInfo.full))
      .filter((action: any) => action.value === '[object Object]')
      .switchMap((action: any) => {
        return [
          reactReduxFormActions.load(
            `${PatientFormModel.healthInfo.full}`,
            action.currentValue,
          ),
        ];
      });

export const setPatientCountryFromDepartmentEpic: () => Epic<
  CountriesActions | ModelAction,
  State
> = () => (action$, store) =>
  action$.ofType(CountriesActionType.FETCH_COUNTRIES_SUCCESS).switchMap(() => {
    const departmentCountryId = selectDepartmentCountryId(store.getState());
    return hasValue(departmentCountryId)
      ? [
          reactReduxFormActions.load(
            `${PatientFormModel.patientInfo.full}${PatientFormModel.patientInfo.country}`,
            departmentCountryId,
          ),
        ]
      : [];
  });

export const setDefaultLanguageWhenCreatingPatientEpic: () => Epic<
  LanguagesActions | any,
  State
> = () => (action$, store) =>
  action$.ofType(GET_CURRENT_USER.SUCCESS as any).flatMap(() =>
    action$.ofType(LanguagesActionType.FETCH_LANGUAGES_SUCCESS).flatMap(() => {
      const currentRouterPath = getRouterPathname(store.getState());
      const isCreatePatientRoute =
        currentRouterPath === patientLinks.createPatient;
      const currentUserLanguage = selectCurrentUserLanguageByLocale(
        store.getState(),
      );
      const currentUserLanguageId = propOr('', 'id', currentUserLanguage);
      const languagePath =
        PatientFormModel.patientInfo.full +
        PatientFormModel.patientInfo.language;

      return currentUserLanguageId && isCreatePatientRoute
        ? [reactReduxFormActions.change(languagePath, currentUserLanguageId)]
        : [];
    }),
  );

export const setDefaultHCPWhenCreatingPatientEpic: () => Epic<
  DepartmentActions | any,
  State
> = () => (action$, store) =>
  action$.ofType(GET_CURRENT_USER.SUCCESS as any).flatMap(() =>
    action$
      .ofType(DepartmentActionType.GET_PROFESSIONALS_SUCCESS)
      .flatMap(() => {
        const currentRouterPath = getRouterPathname(store.getState());
        const currentUserFull = selectCurrentUserByEC6UserId(store.getState());
        const isCreatePatientRoute =
          currentRouterPath === patientLinks.createPatient;
        const isHCPPractitioner =
          currentUserFull &&
          (currentUserFull.user.profile === PROFESSIONAL_PROFILES.HCP_MASTER ||
            PROFESSIONAL_PROFILES.GENERAL_PRACTITIONER);
        const associatedProfessionalPath =
          PatientFormModel.healthInfo.full +
          PatientFormModel.healthInfo.associatedProfessional;

        return currentUserFull && isCreatePatientRoute && isHCPPractitioner
          ? [
              reactReduxFormActions.change(
                associatedProfessionalPath,
                currentUserFull.id.toString(),
              ),
            ]
          : [];
      }),
  );

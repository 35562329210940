import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { State } from 'src/app/store/app.types';
import { DeactivatePatientErrorModal } from 'src/domains/patient/scenes/deactivate-patient/components/deactivate-patient-error-modal/deactivate-patient-error.modal';
import { DeactivatePatientSuccessModal } from 'src/domains/patient/scenes/deactivate-patient/components/deactivate-patient-success-modal/deactivate-patient-success.modal';

import { createModal } from 'src/shared/design-system/modal/store/modal/modal.actions';
import { MODAL_TYPES } from 'src/shared/design-system/modal/store/modal/modal.constants';
import { DeactivatePatientLoaderImplType } from 'src/domains/patient/services/patient/deactivate-patient/deactivate-patient.types';

import { DeactivatePatientActionType } from './deactivate-patient.types';
import { deactivationKeysCountly } from './../deactivate-patient.utils';

export const deactivatePatientEpic: (
  service: DeactivatePatientLoaderImplType,
) => Epic<any, State> = (service) => (action$, store$) =>
  action$
    .ofType(DeactivatePatientActionType.DEACTIVATE_PATIENT_START)
    .debounceTime(500)
    .switchMap((action) => {
      const openId = {
        accessToken: selectAccessToken(store$.getState()),
        gigyaToken: selectGigyaToken(store$.getState()),
      };
      const { patientId, motive, comment } = action.payload;
      return Observable.fromPromise(
        service(openId, patientId, {
          comment,
          motive,
        }),
      )
        .map(() => {
          deactivationKeysCountly(motive, comment);
          return createModal({
            key: MODAL_TYPES.CUSTOM,
            data: {
              customComponent: DeactivatePatientSuccessModal,
            },
          });
        })

        .pipe(
          catchError(() =>
            Observable.of(
              createModal({
                key: MODAL_TYPES.CUSTOM,
                data: {
                  customComponent: DeactivatePatientErrorModal,
                },
              }),
            ),
          ),
        );
    });

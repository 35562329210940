import { requestSequence } from '../../../app/store/request/request.epics';
import {
  FETCH_USER_ANNOUNCEMENTS_REQUEST,
  MARK_ANNOUNCEMENT_VISITED_REQUEST,
} from './announcements.constants';

export const fetchUserAnnouncementsEpic = (userAnnouncementsService) =>
  requestSequence({
    service: userAnnouncementsService,
    actionTypes: FETCH_USER_ANNOUNCEMENTS_REQUEST,
  });

export const markAnnouncementVisitedEpic = (markAnnouncementVisitedService) =>
  requestSequence({
    service: markAnnouncementVisitedService,
    actionTypes: MARK_ANNOUNCEMENT_VISITED_REQUEST,
  });

// @ts-nocheck
import React from 'react';
import { testId } from '@roche/roche-common';

import { useCustomEventListener } from '../../../../shared/utils/hooks';
import { JelloPopoverBaseProps } from './jello-popover.types';

export const JelloPopoverBaseComponent: React.FunctionComponent<
  JelloPopoverBaseProps
> = ({
  mainTestId,
  popoverAlignment,
  popoverPosition,
  popoverStyle,
  popoverContentStyle,
  currentVisibility,
  setCurrentVisibility,
  clickableComponent,
  children,
}) => {
  const { Component, ...props } = clickableComponent;
  const slot = 'trigger';
  const popoverRef = useCustomEventListener('jello-popover-hidden', () =>
    setCurrentVisibility(true),
  );

  return (
    <jello-popover
      ref={popoverRef}
      onClick={() => setCurrentVisibility(!currentVisibility)}
      alignment={popoverAlignment}
      position={popoverPosition}
      class={popoverStyle}
      visible={!currentVisibility ? true : undefined}
      {...testId(mainTestId, 'jello-popover-dropdown')}
    >
      <Component slot={slot} {...props} />
      <div slot="content" className={popoverContentStyle}>
        {children}
      </div>
    </jello-popover>
  );
};

import { useEffect, useState } from 'react';
import {
  ONE_COLUMN,
  THREE_COLUMNS,
  WAFFLE_COLUMN_STYLE,
} from 'src/widgets/top-navigation-jello/top-navigation-jello.constants';
import { waffleElementListElement } from 'src/widgets/top-navigation-jello/top-navigation-jello.types';

export function useGetDynamicStyles(
  permissions: string[],
  waffleElementList: waffleElementListElement[],
) {
  const [numColumnState, setNumColumnState] = useState(ONE_COLUMN);
  const [newList, setNewList] = useState([]);

  useEffect(() => {
    calcNumberOfColumns(permissions, waffleElementList);
  }, [permissions]);

  const calcNumberOfColumns = (permissionsList, waffleList) => {
    const list: object[] = [];
    waffleList?.forEach((waffleElement) => {
      if (
        waffleElement.permissions.every((item) =>
          permissionsList.includes(item),
        ) &&
        waffleElement.incompatibilityPermissions.every(
          /* istanbul ignore next */
          (item) => !permissionsList.includes(item),
        )
      )
        list.push(waffleElement);
    });
    setNumColumnState(Math.ceil(list.length / THREE_COLUMNS));
    // @ts-ignore
    setNewList(list);
  };

  return {
    getColumnsStyle:
      WAFFLE_COLUMN_STYLE[numColumnState]?.liElement ||
      WAFFLE_COLUMN_STYLE.default.liElement,
    getContentColumnsStyle:
      WAFFLE_COLUMN_STYLE[numColumnState]?.contentWrapper ||
      WAFFLE_COLUMN_STYLE.default.contentWrapper,
    numColumns: numColumnState,
    newList,
  };
}

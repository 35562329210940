import React from 'react';
import { Bundle } from 'src/app/navigation/bundle';
/* istanbul ignore next */
export const LoginSuccessBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { LoginSuccessComponent } = await import(
        /* webpackChunkName: "loginSuccess" */ 'src/app/session/scenes/success/success.container'
      );
      return LoginSuccessComponent;
    }}
    bundleDidLoad={(LoginSuccessComponent) => (
      <LoginSuccessComponent {...props} />
    )}
  />
);
/* istanbul ignore next */
export const LoginBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { LoginComponent } = await import(
        /* webpackChunkName: "loginComponent" */ 'src/app/session/scenes/login/login.container'
      );
      return LoginComponent;
    }}
    bundleDidLoad={(LoginComponent) => <LoginComponent {...props} />}
  />
);
/* istanbul ignore next */
export const RedirectBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { RedirectComponent } = await import(
        /* webpackChunkName: "redirectComponent" */ 'src/app/session/components/redirect.component'
      );
      return RedirectComponent;
    }}
    bundleDidLoad={(RedirectComponent) => <RedirectComponent {...props} />}
  />
);

import React from 'react';
import { withTheme } from 'styled-components';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';

import { SvgIcon } from 'src/shared/design-system/icon/icon.component';

const BasalProfileChangeIconComponent = ({
  x = 0,
  y = 0,
  width = 15,
  height = 15,
  theme,
  t,
}) => {
  const originalWidth = 15;
  const originalHeight = 15;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  return (
    <SvgIcon
      title={t('graphDetails.legend.basalRateSwitch')}
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <rect
        x={x}
        y={y}
        fill={theme.colors.tbrBlue}
        width={originalWidth}
        height={originalHeight}
      />
      <path
        x={x + 2}
        y={y}
        transform={`translate(${x + 1}, ${y + 3}) scale(${0.35})`}
        fill={theme.colors.white}
        stroke={theme.colors.white}
        d="M16.7,22.7l9-9c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7l-9-9C16.5,3.1,16.3,3,16,3s-0.5,0.1-0.7,0.3l-1.4,1.4  c-0.4,0.4-0.4,1,0,1.4l4,4c0.3,0.3,0.1,0.9-0.4,0.9H1c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h16.6c0.4,0,0.7,0.5,0.4,0.9l-4,4  c-0.4,0.4-0.4,1,0,1.4l1.4,1.4c0.2,0.2,0.4,0.3,0.7,0.3C16.3,23,16.5,22.9,16.7,22.7z"
      />
    </SvgIcon>
  );
};

export const BasalProfileChangeIcon = withTranslation(
  withTheme(BasalProfileChangeIconComponent),
);

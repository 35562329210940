import * as React from 'react';
import moment from 'moment';
import { withTranslation as translateHOC } from 'react-i18next';
import { t } from 'i18next';
import * as C from './calendar.constants';
import { throttle } from 'lodash';
import { countlyEventTrigger } from 'src/app/navigation/countly';
import {
  EVENTS_KEYS,
  SEGMENT_KEYS,
  SEGMENT_VALUES,
} from './period-selectors.countly.constants';
import { CalendarProps, CalendarState } from './calendar-component.types';

const THROTTLE_INTERVAL = 300;
const CUSTOM_RANGE_VALUE = 4;

const triggerCountlyEvent = (segmentValue, isPatternActive, isCgmActive) => {
  countlyEventTrigger(EVENTS_KEYS.DATE_RANGE_CHANGED, {
    [SEGMENT_KEYS.DATE_CHANGE_METHOD]: segmentValue,
    [SEGMENT_KEYS.DATE_CHANGE_LOCATION]: isPatternActive
      ? SEGMENT_VALUES.PATTERNS_VIEW
      : isCgmActive
      ? SEGMENT_VALUES.CGM_VIEW
      : SEGMENT_VALUES.BG_VIEW,
  });
};

const safeDate = (d) => (typeof d === 'string' ? moment(d.split('T')[0]) : d);

const outOfMaxRange = (startDate, endDate, maxDateRange) => {
  const hasMaxDateRange = Boolean(maxDateRange);

  if (!hasMaxDateRange) {
    return false;
  }

  const TWO = 2;
  const DATE_RANGE =
    startDate.startOf(C.DAY).diff(endDate.endOf(C.DAY), C.DAYS) - TWO;
  const OUT_OF_RANGE = Math.abs(DATE_RANGE) > maxDateRange;

  return OUT_OF_RANGE;
};

const calculateDateRange = ({
  stateRange,
  propsRange,
  maxDateRange,
  dates,
  stateStartDate,
  stateEndDate,
}) => {
  const isCustom = stateRange === CUSTOM_RANGE_VALUE;
  const sDate = dates.startDate;
  const eDate = dates.endDate || sDate.clone();
  let range = propsRange - 1;
  let letCustom = isCustom;

  if (isCustom && outOfMaxRange(sDate, eDate, maxDateRange)) {
    range = maxDateRange - 1;
    letCustom = false;
  }

  if (!sDate.isSame(stateStartDate)) {
    return {
      startDate: sDate,
      endDate: letCustom
        ? eDate.endOf(C.DAY)
        : sDate.clone().add(range, C.DAYS),
    };
  }

  if (!eDate.isSame(stateEndDate)) {
    return {
      startDate: letCustom
        ? sDate.startOf(C.DAY)
        : eDate.clone().subtract(range, C.DAYS),
      endDate: eDate,
    };
  }

  return null;
};

const createInitialState = () => ({
  range: 14,
  originalStartDate: moment(),
  originalEndDate: moment(),
  wrapWidth: window.outerWidth,
  startDate: moment(),
  endDate: moment(),
  minDate: moment(),
  maxDate: moment(),
  focusedInput: null,
  isCustom: false,
});

const calculateNextState = (nextProps, propsRange, state) => {
  const {
    dateInfo,
    range,
    isCgmActive,
    isPatternActive,
    onDatesChange,
    displayCal,
    disabled,
  } = nextProps;

  const { firstMeasurementDate, lastMeasurementDate, startDate, endDate } =
    dateInfo;

  if (disabled && !isCgmActive) {
    return { ...state, startDate: null, endDate: null };
  }

  const sDate = safeDate(startDate);
  const eDate = safeDate(endDate);
  const minDate = moment(firstMeasurementDate);
  const maxDate = moment(lastMeasurementDate);
  const pRange = parseInt(range, 10);
  const isCustom = pRange === CUSTOM_RANGE_VALUE;
  const hasRangeChanged = propsRange !== range;

  if (isCgmActive && !isCustom && !disabled) {
    onDatesChange(sDate.endOf(C.DAY), eDate.endOf(C.DAY), pRange);
  }

  let focusedInput: null | string = null;
  if (displayCal && isCustom && hasRangeChanged) {
    focusedInput = isPatternActive ? C.END_DATE_ID : C.START_DATE_ID;
  }

  return {
    ...state,
    range: pRange,
    originalStartDate: sDate,
    originalEndDate: eDate,
    startDate: sDate,
    endDate: eDate,
    minDate,
    maxDate,
    isCustom,
    focusedInput,
  };
};

export const calendarClassBuilder = (CalendarRenderComponent) => {
  class CalendarClass extends React.Component<CalendarProps, CalendarState> {
    public state = createInitialState();

    private readonly onResize = throttle(() => {
      this.handleResize();
    }, THROTTLE_INTERVAL);

    public constructor(props) {
      super(props);
      moment.updateLocale(moment.locale(props.locale), {
        invalidDate: C.NO_DATA,
        months: [
          t('dates.months.full.jan'),
          t('dates.months.full.feb'),
          t('dates.months.full.mar'),
          t('dates.months.full.apr'),
          t('dates.months.full.may'),
          t('dates.months.full.jun'),
          t('dates.months.full.jul'),
          t('dates.months.full.aug'),
          t('dates.months.full.sep'),
          t('dates.months.full.oct'),
          t('dates.months.full.nov'),
          t('dates.months.full.dec'),
        ],
      });
    }

    public componentWillReceiveProps(nextProps) {
      const {
        dateInfo: oldDateInfo,
        disabled: oldDisabled,
        range: propsRange,
      } = this.props;
      const { dateInfo: newDateInfo, disabled: newDisabled } = nextProps;

      const hasChangedDateInfo =
        JSON.stringify(newDateInfo) !== JSON.stringify(oldDateInfo);
      const hasChangedDisabled = newDisabled !== oldDisabled;

      if (hasChangedDateInfo || hasChangedDisabled) {
        const newState = calculateNextState(nextProps, propsRange, this.state);
        this.setState(newState);
      }
    }

    public componentDidMount() {
      window.addEventListener('resize', this.onResize);
    }

    public componentWillUnmount() {
      window.removeEventListener('resize', this.onResize);
    }

    public render() {
      const { startDate, endDate, focusedInput } = this.state;
      const {
        wrapWidth,
        dateInfo,
        disabled,
        showArrows = true,
        className,
        id,
        isCgmActive,
        cgmSelectedDate,
      } = this.props;

      const calenderRenderProps = {
        wrapWidth,
        startDate,
        endDate,
        focusedInput,
        dateInfo,
        disabled,
        showArrows,
        className,
        id,
        isCgmActive,
        cgmSelectedDate,
        onChangePeriod: this.onChangePeriod.bind(this),
        shoWCalendar: this.shoWCalendar.bind(this),
        getDate: this.getDate.bind(this),
        handleDatesChange: this.handleDatesChange.bind(this),
        handleFocusChange: this.handleFocusChange.bind(this),
        onChangeDates: this.onChangeDates.bind(this),
        onHideCalendar: this.onHideCalendar.bind(this),
      };

      return <CalendarRenderComponent {...calenderRenderProps} />;
    }

    public getDate(disabled, date, isCgmActive, cgmSelectedDate) {
      return disabled && !(isCgmActive && cgmSelectedDate)
        ? moment('--')
        : date;
    }

    private shoWCalendar() {
      const { disabled, isPatternActive } = this.props;
      if (!disabled) {
        const focusedInput = isPatternActive ? C.END_DATE_ID : C.START_DATE_ID;
        this.setState({ focusedInput });
      }
    }

    private handleFocusChange(focusedInput) {
      if ([C.START_DATE_ID, C.END_DATE_ID].indexOf(focusedInput) > -1) {
        this.setState({ focusedInput });
      }
    }

    private handleResize() {
      const { focusedInput } = this.state;
      if (focusedInput) {
        this.setState({ focusedInput: null });
        this.setState({ focusedInput });
      }
      this.setState({ wrapWidth: window.outerWidth });
    }

    private onHideCalendar() {
      setTimeout(() => {
        this.setState({ focusedInput: null });
      }, 0);
    }

    private handleDatesChange(dates) {
      const { range: propsRange, maxDateRange } = this.props;
      const {
        range: stateRange,
        startDate: stateStartDate,
        endDate: stateEndDate,
      } = this.state;

      const newState = calculateDateRange({
        stateRange,
        propsRange,
        maxDateRange,
        dates,
        stateStartDate,
        stateEndDate,
      });

      if (newState !== null) {
        this.setState(newState);
      }
    }

    private onChangeDates() {
      const { startDate, endDate, originalStartDate, originalEndDate, range } =
        this.state;
      const { isPatternActive, isCgmActive, onDatesChange } = this.props;

      if (
        !startDate
          .clone()
          .startOf(C.DAY)
          .isSame(originalStartDate.clone().startOf(C.DAY), C.DAY) ||
        !endDate
          .clone()
          .startOf(C.DAY)
          .isSame(originalEndDate.clone().startOf(C.DAY), C.DAY)
      ) {
        onDatesChange(startDate, endDate, range);
      }

      triggerCountlyEvent(SEGMENT_VALUES.MANUAL, isPatternActive, isCgmActive);

      this.onHideCalendar();
    }

    private onChangePeriod(way) {
      const { isPatternActive, isCgmActive, onDatesChange, range } = this.props;

      triggerCountlyEvent(SEGMENT_VALUES.ARROWS, isPatternActive, isCgmActive);

      let startDate = this.state.startDate.clone();
      let endDate = this.state.endDate.clone();
      const days = Math.abs(endDate.diff(startDate, C.DAYS)) + 1;

      if (way === C.NEXT) {
        startDate = startDate.startOf(C.DAY).add(days, C.DAYS);
        endDate = endDate.endOf(C.DAY).add(days, C.DAYS);
      }
      if (way === C.PREV) {
        startDate = startDate.startOf(C.DAY).subtract(days, C.DAYS);
        endDate = endDate.endOf(C.DAY).subtract(days, C.DAYS);
      }

      this.setState(
        {
          endDate,
          startDate,
        },
        () => {
          this.onHideCalendar();
          onDatesChange(startDate, endDate, range);
        },
      );
    }
  }

  const Calendar = translateHOC('translations')(CalendarClass);

  return Calendar;
};

import React from 'react';

import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import { hasValue } from 'src/shared/utils/validation-helpers';
import { Column } from 'src/domains/diagnostics/components/column/column.component';
import {
  NO_DATA_AVAILABLE_OBJECT,
  NO_ENOUGH_DATA_OBJECT,
} from 'src/shared/design-system/no-data-tooltip/no-data-tooltip.constants';
import { addNoDataType } from 'src/domains/diagnostics/widgets/status-card/components/status-card-row/status-card-row.component';

import {
  CircleDiv,
  DetailBlockContainerDiv,
  PrimaryTitleHeader,
  SecondaryTitleHeader,
  ValueContainerDiv,
  ValueDisplay,
} from './detail-block.style';

export const DetailBlock = ({
  flex,
  primaryTitle,
  secondaryTitle = '',
  value,
  circleFill = '',
  hasData,
  hasSufficiencyData,
}) => {
  const valueDiv = (circleFillColor, valueToFill) => (
    <React.Fragment>
      <RenderIf validate={hasValue(circleFillColor)}>
        <CircleDiv color={circleFillColor} />
      </RenderIf>
      <ValueDisplay>{valueToFill}</ValueDisplay>
    </React.Fragment>
  );
  const secondaryTitleDiv = (title) => (
    <React.Fragment>
      <RenderIf validate={hasValue(title)}>
        <SecondaryTitleHeader>{title}</SecondaryTitleHeader>
      </RenderIf>
    </React.Fragment>
  );

  return (
    <DetailBlockContainerDiv flex={flex}>
      <Column>
        <PrimaryTitleHeader>{primaryTitle}</PrimaryTitleHeader>
        <ValueContainerDiv>
          {!hasData
            ? addNoDataType(NO_DATA_AVAILABLE_OBJECT)
            : hasSufficiencyData
            ? valueDiv(circleFill, value)
            : addNoDataType(NO_ENOUGH_DATA_OBJECT)}
        </ValueContainerDiv>
        {!hasData
          ? addNoDataType(NO_DATA_AVAILABLE_OBJECT)
          : secondaryTitleDiv(secondaryTitle)}
      </Column>
    </DetailBlockContainerDiv>
  );
};

import { FetchDeviceSettingsListSuccessAction } from 'src/domains/diagnostics/scenes/device-settings-jello/store/device-settings-list/device-settings-list.types';
import { FetchDevicesStartAction } from 'src/domains/patient/scenes/list-devices/store/list-devices.types';

export enum FetchStatus {
  LOADING = 'loading',
  FAILURE = 'failure',
  SUCCESS = 'success',
  NONE = 'none',
}

export interface PatientDevices {
  value: string;
  label: string;
}

export interface Devices {
  name: string;
  serial: string;
  addedDateJsDate?: Date;
}

export interface DeviceSettingsProps {
  fetchDevices: (patientId: number) => FetchDevicesStartAction;
  fetchDeviceSettingsList: (
    settingsParams: FetchDeviceSettingsListParams,
  ) => FetchDeviceSettingsListSuccessAction;
  fetchDeviceSettings: any;
  deviceSettingsList: DeviceSettingListItem[];
  deviceSettingsListStatus: FetchStatus;
  deviceSettingsStatusAll: FetchStatus;
  deviceSettingsStatus: FetchStatus;
  patientDevices: PatientDevices[];
  patientDevicesStatus: FetchStatus;
  match: any;
  collapsed: string;
  fhirId: string;
  fetchAllData: any;
}

export interface DeviceSettingListItem {
  version: number;
  timestamp: string;
}

export interface FetchDeviceSettingsListParams {
  patientId: number;
  deviceSerialNumber: string;
  patientType: string;
}

export interface FetchDeviceSettingsParams {
  patientId: number;
  deviceSerialNumber: string;
  patientType: string;
  includePrevious: boolean;
  version: string;
}

export interface DeviceSettingsState {
  id: number;
  tabSelected: string;
  selectedDeviceId: any;
  selectedReportId: any;
  isLowResolution: boolean;
  isPreviousSettingsSelected: boolean;
}

/*
 * Types generated by API endpoint
/*/

export interface DeviceInformation {
  DeviceType: string;
  ModelNumber: string;
  SerialNumber: string;
  ModelName: string;
}

export interface PumpPairingStatus {
  Paired: boolean;
  Connected: boolean;
  SerialNumberConnectedPump: string;
}

export interface UnitsSettings {
  TimeFormat: string;
  Carbohydrates: string;
  BloodGlucose: string;
  Insuline: string;
}

export interface TargetRanges {
  Low: number;
  High: number;
}

export interface CarbRatio {
  Insulin: number;
  Carbohydrates: number;
}

export interface InsulinSensitivity {
  Insulin: number;
  BloodGlucose: number;
}

export interface TimeBlock {
  Name: string;
  StartTime: string;
  EndTime: string;
  TargetRanges: TargetRanges;
  CarbRatio: CarbRatio;
  InsulinSensitivity: InsulinSensitivity;
}

export interface HealthEvents {
  Exercise1: number;
  Exercise2: number;
  Stress: number;
  Illness: number;
  Premenstrual: number;
}

export interface Device {
  DeviceInformation: DeviceInformation;
  PumpPairingStatus: PumpPairingStatus;
  UnitsSettings: UnitsSettings;
  TimeBlocks: TimeBlock[];
  HealthEvents: HealthEvents;
}

export interface AfterHighBG {
  Enabled: boolean;
  Duration: number;
  EventThreshold: number;
  CarbThreshold: number;
}

export interface AfterLowBG {
  Enabled: boolean;
  Duration: number;
  EventThreshold: number;
  CarbThreshold: number;
}

export interface AfterMeal {
  Enabled: boolean;
  Duration: number;
  EventThreshold: number;
  CarbThreshold: number;
}

export interface BgTestReminder {
  AfterHighBG: AfterHighBG;
  AfterLowBG: AfterLowBG;
  AfterMeal: AfterMeal;
}

export interface AppointmentReminder {
  Type: string;
  Status: string;
  Date: string;
  Time: string;
  Name: string;
}

export interface AlarmClockReminder {
  Type: string;
  Name: string;
  Status: string;
  Recurrence: string;
  Time: string;
}

export interface PumpReminder {
  Type: string;
  Name: string;
  Status: string;
  Recurrence: string;
  Time: string;
}

export interface Reminders {
  BgTestReminder: BgTestReminder;
  AppointmentReminders: AppointmentReminder[];
  AlarmClockReminders: AlarmClockReminder[];
  PumpReminders: PumpReminder[];
}

export interface BolusAdvice {
  Enabled: boolean;
  ActiveTime: number;
  Offset: number;
  MealRize: number;
  SnackLimit: number;
}

export interface BolusSettings {
  DeliverySpeed: string;
  BolusIncrement: number;
  MaxBolusQuantity: number;
  LagStatus: string;
  MaxBolusDuration: number;
  BolusAdvice: BolusAdvice;
}

export interface IPTimeBlock {
  StartTime: string;
  EndTime: string;
  InsulinUnits: number;
}

export interface BasalIPProfile {
  Name: string;
  IdProfile: number;
  InsulinUnitsPerDay: number;
  IPTimeBlocks: IPTimeBlock[];
}

export interface CustomTBR {
  Name: string;
  Adjustment: number;
  Duration: number;
}

export interface BasalSettings {
  ActiveProfile: number;
  MaximunHourlyBasalRate: number;
  TemporaryBasalRateMaximumAdjustment: number;
  TemporaryBasalRateMaximunDuration: number;
  BasalIPProfiles: BasalIPProfile[];
  CustomTBR: CustomTBR[];
}

export interface PumpSettings {
  InsulinIncrement: number;
  CartridgeLowAlarmQuantity: number;
  InfusionSetCannulaFillAmount: number;
  InfusionSetTubingFillAmount: number;
  BolusIncrement: number;
  PrimeQuantity: number;
}
export interface DeviceSetting {
  TimeStamp: Date;
  Device: Device;
  Reminders: Reminders;
  BolusSettings: BolusSettings;
  BasalSettings: BasalSettings;
  PumpSettings: PumpSettings;
}

import styled from 'styled-components';

import { boxShadows } from 'src/app/styles/box-shadow';
import { borderRadius } from 'src/app/styles/border-radius';
import { colors } from 'src/app/styles/colors';
import { spacing } from 'src/app/styles/spacing';

export const CardContainer = styled.div`
  padding: ${spacing.one} 0;
`;

CardContainer.displayName = 'CardContainer';

export const RowCard = styled.div`
  background-color: ${colors.white};
  border: 0.0625rem solid ${colors.grayLight};
  border-radius: ${borderRadius.three};
  box-shadow: ${boxShadows.two};
  display: flex;
`;

RowCard.displayName = 'RowCard';

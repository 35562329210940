import { createAction } from 'src/app/store/app.actions';

import {
  ClearUnitsRequestAction,
  UserUnitsActionTypes,
} from './user-units-selector.types';

import {
  createRequestActions,
  createRequestActionTypes,
} from '../../../app/store/request/request.actions';

export const USER_UNITS_GET_REQUEST = createRequestActionTypes(
  UserUnitsActionTypes.USER_UNITS_GET,
);

export const getUserUnits = createRequestActions(USER_UNITS_GET_REQUEST);

export const USER_UNITS_SAVE_REQUEST = createRequestActionTypes(
  UserUnitsActionTypes.USER_UNITS_SAVE,
);

export const saveUserUnits = createRequestActions(USER_UNITS_SAVE_REQUEST);

export const clearUserUnitRequest = (): ClearUnitsRequestAction =>
  createAction(UserUnitsActionTypes.USER_UNITS_CLEAR);

import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';

import {
  selectCurrentPatientPermissions,
  selectCurrentPermissions,
  selectIsFetchingPermissions,
} from 'src/domains/permissions/store/permissions.selectors';
import {
  getPermissions,
  hasPermissions,
} from 'src/domains/permissions/store/permissions.utils';

import { WithPermissionsComponent } from './with-permissions.component';

const permissionConnector = (state) => ({
  currentPermissions: selectCurrentPermissions(state),
  isFetchingPermissions: selectIsFetchingPermissions(state),
});

const patientPermissionsConnector = (state) => ({
  currentPermissions: selectCurrentPatientPermissions(state),
});

const mapPermissions = () =>
  mapProps((props) => ({
    ...props,
    hasAccess: hasPermissions({
      toValidate: props.hasPermissions,
      current: props.currentPermissions,
    }),
    permissions: getPermissions({
      permissions: props.hasPermissions,
      current: props.currentPermissions,
    }),
  }));

export const WithPermissions = compose(
  connect(permissionConnector),
  mapPermissions(),
)(WithPermissionsComponent);

export const WithPatientPermissions = compose(
  connect(patientPermissionsConnector),
  mapPermissions(),
)(WithPermissionsComponent);

export const ID = 'whatsNew';
export const CHECKBOX = 'checkbox';
export const OK_BTN = 'okbtn';
export const TITLE = 'title';
export const CONTENT = 'content';
export const CONTENT_ACC = 'content_ACC';
export const SECTIONS = 'sections';
export const MENU = 'menu';
export const ITEM = 'item';
export const CONTAINER = 'container';
export const MODAL = 'modal';
export const IMAGE = 'image';
export const VIDEO = 'video';
export const GIF = 'gif';
export const RDCP = 'rdcp';
export const ANNOUNCEMENT = 'announcement';
export const MP4 = 'mp4';
export const TEXT = 'text';
export const BREAKLINE = 'breakline';
export const SUB_HEADER = 'sub_header';
export const ITALIC = 'italic';
export const BOLD = 'bold';
export const CUSTOM = 'custom';
export const BULLET = 'bullet';
export const CALLOUT = 'callout';
export const HELP = 'help';
export const PAGE_CONTENT = 'page_content';
export const UNORDERED_LIST = 'unordered_list';
export const MEDIA = 'media';
export const BLOCK = 'block';
export const WHAT_NEW_MEDIA = `${ID}-${PAGE_CONTENT}-${MEDIA}`;
export const WHAT_NEW_TEXT = `${ID}-${PAGE_CONTENT}-${TEXT}`;
export const WHAT_NEW_CONTENT = `${ID}-${CONTENT}`;
export const FALLBACK_TEXT = 'media_fallback_text';

/* Translation Keys */
export const ANNOUNCEMENT_WHATS_NEW = 'announcements.whatsNew';
export const WHATS_NEW_TITLE = `${ANNOUNCEMENT_WHATS_NEW}.titleText`;
export const WHATS_NEW_CHECKBOX = `${ANNOUNCEMENT_WHATS_NEW}.checkboxText`;
export const WHATS_NEW_MEDIA_FALLBACK_TEXT = `${ANNOUNCEMENT_WHATS_NEW}.media.fallbackText`;
export const WHATS_NEW_CLOSE_MODAL_BUTTON_TEXT = `${ANNOUNCEMENT_WHATS_NEW}.closeModal`;

import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import {
  selectAccessToken,
  selectIsOidcSessionValid,
} from '../../../app/session/core/oidc/oidc.selectors';
import { customDatadogLog } from '../../../app/logger/utils';
import { LOGS } from '../../../app/logger/constants';

// Validation triggered against epic
/* istanbul ignore next */ export class SessionGuardComponent extends React.Component<
  any,
  { isVerifying: boolean }
> {
  // tslint:disable-next-line
  static defaultProps: Partial<any> = {
    onSuccess: (props) => props.children,
    onError: (props) => props.children,
    onVerifying: () => <div />,
  };

  public state = {
    isVerifying: true,
  };

  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    const { token, isOidcSessionValid, validateSession } = this.props;
    validateSession();
    this.setState({
      isVerifying: !(token && isOidcSessionValid),
    });
  }

  public componentWillReceiveProps(nextProps) {
    const { nToken, nError } = nextProps;
    const { token, error, isOidcSessionValid } = this.props;
    if (nToken !== token || nError !== error) {
      this.setState({
        isVerifying: !(token && isOidcSessionValid),
      });
    }
  }

  public render() {
    const { isVerifying } = this.state;
    const { onSuccess, onError, onVerifying, error, isOidcSessionValid } =
      this.props;
    if (isVerifying) {
      return onVerifying(this.props);
    }
    if (!isOidcSessionValid || error) {
      customDatadogLog('session', LOGS.sessionGuardError, {
        isOidcSessionValid,
        error,
      });
      return onError(this.props);
    }
    return onSuccess(this.props);
  }
}

export const SessionGuard = compose<any, Partial<any>>(
  connect((state) => ({
    token: selectAccessToken(state),
    isOidcSessionValid: selectIsOidcSessionValid(state),
  })),
)(SessionGuardComponent);

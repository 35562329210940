import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { State } from 'src/app/store/app.types';
import {
  fetchRpmSettingsError,
  fetchRpmSettingsSuccess,
  updateRpmSettingsError,
  updateRpmSettingsSuccess,
} from 'src/domains/rpm/rpm-settings/store/rpm-settings.actions';
import { createModal } from 'src/shared/design-system/modal/store/modal/modal.actions';
import { MODAL_TYPES } from 'src/shared/design-system/modal/store/modal/modal.constants';
import { RpmSettingsService } from 'src/domains/rpm/services/rpm/rpm-settings/rpm-settings.types';

import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';

import {
  RpmSettingsActions,
  RpmSettingsActionType,
} from './rpm-settings.types';

import { RpmSettingsSuccessModal } from '../components/rpm-settings-success-modal/rpm-settings-success.modal';
import { RpmSettingsErrorModal } from '../components/rpm-settings-error-modal/rpm-settings-error.modal';

export const fetchRpmSettingsEpic =
  (service: RpmSettingsService): Epic<RpmSettingsActions, State> =>
  (action$, store$) =>
    action$
      .ofType(RpmSettingsActionType.FETCH_RPMSETTINGS_START)
      .debounceTime(1000)
      .switchMap(() => {
        const openId = {
          accessToken: selectAccessToken(store$.getState()),
          gigyaToken: selectGigyaToken(store$.getState()),
        };
        return Observable.fromPromise(service(openId))
          .map((data) => fetchRpmSettingsSuccess(data))
          .pipe(catchError((err) => Observable.of(fetchRpmSettingsError(err))));
      });

export const updateRpmSettingsEpic =
  (updateRpmSettingsService) => (action$, state$) =>
    action$
      .ofType(RpmSettingsActionType.UPDATE_RPMSETTINGS_START)
      .flatMap(({ payload }) => {
        const openId = {
          accessToken: selectAccessToken(state$.getState()),
          gigyaToken: selectGigyaToken(state$.getState()),
        };
        return Observable.fromPromise(
          updateRpmSettingsService(openId, payload.rpmSettings),
        )
          .switchMap((data) => {
            return [
              updateRpmSettingsSuccess(data),
              createModal({
                key: MODAL_TYPES.CUSTOM,
                data: {
                  customComponent: RpmSettingsSuccessModal,
                },
              }),
            ];
          })
          .pipe(
            catchError((err) => {
              return Observable.concat(
                Observable.of(updateRpmSettingsError(err)),
                Observable.of(
                  createModal({
                    key: MODAL_TYPES.CUSTOM,
                    data: {
                      customComponent: RpmSettingsErrorModal,
                    },
                  }),
                ),
              );
            }),
          );
      });

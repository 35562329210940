import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { ProtectedRoute } from 'src/shared/design-system/protected-route/protected-route.container';
import { StripManagementDashboardInnerRoutes as StripRoutes } from 'src/domains/strip-management/routes';
import { hasValue } from 'src/shared/utils/validation-helpers';
import { Column } from 'src/shared/design-system/column/column.component';
import { LoadingRing } from 'src/shared/design-system/loading-ring/loading-ring.component';
import {
  ADMINISTRATIVE,
  GENERAL_PRACTITIONER,
} from 'src/app/store/user/user.constants';

import {
  connectWithDiagnosticsPermissionsAndCheckExternalIdLifecycle,
  hasPatientDiagnostics,
} from './utils';
import {
  BgDashboardBundle,
  BgDashboardGpBundle,
  CgmDashboardBundle,
} from './bundles';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { connectToPermissions } from 'src/domains/permissions/store/permissions.selectors';
import { connectToProfile } from 'src/app/store/user/user.selectors';

import {
  fetchCheckExternalPatient,
  setIsExternalPatient,
} from './bg/store/bg.actions';
import { patientExternalConnector } from './bg/store/bg.selectors';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import {
  PATIENT_PERMISSIONS,
  PERMISSIONS,
} from 'src/domains/permissions/store/permissions.constants';

export const patientDashboardsLinks = {
  byId: '/patients/:id',
  bgId: '/patients/bg/:id',
  cgmId: '/patients/cgm/:id',
};

const loadingRing = (
  <Column align="center" height="100vh" justifyContent="center">
    <LoadingRing infinite />
  </Column>
);

const CheckExternal = (props) => {
  const {
    setIsExternalPatient,
    fetchCheckExternalPatient,
    location,
    match: { params },
    currentPermissions,
    isExternalPatient,
    externalPatient,
    profile,
  } = props;

  const patientId = params.id;
  const urlSearchParams = new URLSearchParams(location.search);
  const externalType = urlSearchParams.get('type') === 'external';

  const [isBusy, setIsBusy] = useState(true);
  const [errorExternalFetch, setErrorExternalFetch] = useState(false);

  useEffect(() => {
    if (externalType) {
      fetchCheckExternalPatient({ externalPatientId: patientId });
    }
  }, []);

  useEffect(() => {
    if (externalPatient.patientId || !externalType) {
      setIsBusy(false);
    }
  }, [externalPatient]);

  useEffect(() => {
    if (isBusy && !isExternalPatient) {
      setErrorExternalFetch(true);
    }
  }, [isExternalPatient]);

  if (!hasValue(currentPermissions) || (isBusy && !errorExternalFetch)) {
    return loadingRing;
  }

  if (isExternalPatient) {
    const id = externalPatient.patientId;
    return (
      <Redirect
        to={id ? patientDashboardsLinks.byId.replace(':id', id) : '/home'}
      />
    );
  }

  if (profile === ADMINISTRATIVE) {
    return (
      <Redirect to={patientDashboardsLinks.byId.replace(':id', patientId)} />
    );
  }

  if (profile === GENERAL_PRACTITIONER) {
    return (
      <Route
        render={(routerProps) => <BgDashboardGpBundle {...routerProps} />}
      />
    );
  }

  return (
    <Route render={(routerProps) => <BgDashboardBundle {...routerProps} />} />
  );
};

const CheckExternalConnected = compose(
  withRouter,
  connectToPermissions,
  connectToProfile,
  connect(
    patientExternalConnector,
    mapDispatchers({
      setIsExternalPatient,
      fetchCheckExternalPatient: fetchCheckExternalPatient.start,
    }),
  ),
)(CheckExternal);

export const DashboardRoutes = () => {
  const isNotBlindedPatient = (patientPermissions) => {
    return patientPermissions?.includes(PATIENT_PERMISSIONS.BLINDED_STUDY)
      ? false
      : true;
  };

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={patientDashboardsLinks.byId}
        component={connectWithDiagnosticsPermissionsAndCheckExternalIdLifecycle(
          ({ currentPermissions, ...routerProps }) => {
            const { bgId } = patientDashboardsLinks;
            if (!hasValue(currentPermissions)) return loadingRing;
            if (!hasPatientDiagnostics(currentPermissions))
              return <StripRoutes {...routerProps} />;
            return (
              <Redirect to={bgId.replace(':id', routerProps.match.params.id)} />
            );
          },
        )}
      />

      <ProtectedRoute
        exact
        path={patientDashboardsLinks.bgId}
        component={() => <CheckExternalConnected />}
      />

      <ProtectedRoute
        exact
        path={patientDashboardsLinks.cgmId}
        accessConditions={[
          ({ patientPermissions }) => isNotBlindedPatient(patientPermissions),
        ]}
        validationRedirectPath={'/patients/:id'}
        hasPermissions={[PERMISSIONS.PROFESSIONAL]}
        component={connectWithDiagnosticsPermissionsAndCheckExternalIdLifecycle(
          ({ currentPermissions, profile, ...routerProps }) => {
            const { bgId } = patientDashboardsLinks;
            if (!hasValue(currentPermissions)) return loadingRing;
            if (profile === ADMINISTRATIVE)
              return (
                <Redirect
                  to={patientDashboardsLinks.bgId.replace(
                    ':id',
                    routerProps.match.params.id,
                  )}
                />
              );

            return <CgmDashboardBundle {...routerProps} />;
          },
        )}
      />
    </Switch>
  );
};

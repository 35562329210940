import {
  NextShipmentFetchSuccessAction,
  NextShipmentState,
} from './next-shipment.types';
import {
  NEXT_SHIPMENT_INITIAL_STATE,
  NEXT_SHIPMENT_KO,
  NO_DELIVERY_PROGRAM,
} from '../next-shipment.constants';
import {
  CLEAR_NEXT_SHIPMENT_REQUEST,
  NEXT_SHIPMENT_FETCH_REQUEST,
} from './next-shipment.actions';
import { formatNextShipmentDate } from './../next-shipment.utils';

const nextShipmentState: NextShipmentState = NEXT_SHIPMENT_INITIAL_STATE;

export const nextShipmentReducer = (
  state = nextShipmentState,
  action: NextShipmentFetchSuccessAction,
): NextShipmentState => {
  switch (action.type) {
    case NEXT_SHIPMENT_FETCH_REQUEST.SUCCESS:
      const { id, shipDate, shipStatus, shipQtty, resultDescription } =
        action.payload;
      return {
        id,
        shipDate,
        shipDateFormatted: formatNextShipmentDate(shipDate),
        shipStatus,
        shipQtty,
        resultDescription,
      };
    case NEXT_SHIPMENT_FETCH_REQUEST.ERROR:
      const error = action.payload.status;
      return {
        ...NEXT_SHIPMENT_INITIAL_STATE,
        resultDescription:
          error === 404 ? NO_DELIVERY_PROGRAM : NEXT_SHIPMENT_KO,
      };
    case CLEAR_NEXT_SHIPMENT_REQUEST.SUCCESS:
      return NEXT_SHIPMENT_INITIAL_STATE;
    default:
      return state;
  }
};

import { Action, PayloadAction } from '../../../../../../app/store/app.types';
import { GetPatientInfoErrorResponse } from '../../../../services/patient/patient-info/patient-info.types';
import { EC6Patient } from '../../../../services/patient/patient.types';
import { EditPatientWithFhirParams } from '../../../../services/patient/edit-patient-with-fhir/edit-patient-with-fhir.types';

export enum FetchEditPatientDataActionType {
  FETCH_EDIT_PATIENT_DATA_START = 'FETCH_EDIT_PATIENT_DATA_START',
  FETCH_EDIT_PATIENT_DATA_SUCCESS = 'FETCH_EDIT_PATIENT_DATA_SUCCESS',
  FETCH_EDIT_PATIENT_DATA_ERROR = 'FETCH_EDIT_PATIENT_DATA_ERROR',
  CLEAR_EDIT_PATIENT_DATA = 'CLEAR_EDIT_PATIENT_DATA',
}

export type FetchEditPatientDataStartActionPayload = { patientId: number };
export type FetchEditPatientDataSuccessActionPayload = EC6Patient;
export type FetchEditPatientDataErrorActionPayload =
  GetPatientInfoErrorResponse;

export type FetchEditPatientDataStartAction = PayloadAction<
  FetchEditPatientDataActionType.FETCH_EDIT_PATIENT_DATA_START,
  FetchEditPatientDataStartActionPayload
>;

export type FetchEditPatientDataSuccessAction = PayloadAction<
  FetchEditPatientDataActionType.FETCH_EDIT_PATIENT_DATA_SUCCESS,
  FetchEditPatientDataSuccessActionPayload
>;

export type FetchEditPatientDataErrorAction = PayloadAction<
  FetchEditPatientDataActionType.FETCH_EDIT_PATIENT_DATA_ERROR,
  FetchEditPatientDataErrorActionPayload
>;

export type ClearEditPatientDataAction =
  Action<FetchEditPatientDataActionType.CLEAR_EDIT_PATIENT_DATA>;

export type FetchEditPatientDataActions =
  | FetchEditPatientDataStartAction
  | FetchEditPatientDataSuccessAction
  | FetchEditPatientDataErrorAction
  | ClearEditPatientDataAction;

export type FetchEditPatientDataState = {
  isFetchingPatientData: boolean;
  fetchDataSuccess: boolean;
  fetchDataError: boolean;
  patientData: EC6Patient | null;
};

export enum EditPatientPlatformActionType {
  EDIT_PATIENT_PLATFORM_START = 'EDIT_PATIENT_PLATFORM_START',
  EDIT_PATIENT_PLATFORM_SUCCESS = 'EDIT_PATIENT_PLATFORM_SUCCESS',
  EDIT_PATIENT_PLATFORM_ERROR = 'EDIT_PATIENT_PLATFORM_ERROR',
  CLEAR_EDIT_PATIENT_PLATFORM = 'CLEAR_EDIT_PATIENT_PLATFORM',
}

export type EditPatientPlatformStartPayloadType = EditPatientWithFhirParams;

export type EditPatientPlatformStartAction = PayloadAction<
  EditPatientPlatformActionType.EDIT_PATIENT_PLATFORM_START,
  EditPatientPlatformStartPayloadType
>;

export type EditPatientPlatformSuccessAction = PayloadAction<
  EditPatientPlatformActionType.EDIT_PATIENT_PLATFORM_SUCCESS,
  FixMe
>;

export type EditPatientPlatformErrorAction =
  Action<EditPatientPlatformActionType.EDIT_PATIENT_PLATFORM_ERROR>;

export type ClearEditPatientPlatformAction =
  Action<EditPatientPlatformActionType.CLEAR_EDIT_PATIENT_PLATFORM>;

export type EditPatientPlatformActions =
  | EditPatientPlatformStartAction
  | EditPatientPlatformSuccessAction
  | EditPatientPlatformErrorAction
  | ClearEditPatientPlatformAction;

export type EditPatientPlatformState = {
  isEditingPatient: boolean;
  editionSuccess: boolean;
  editionError: boolean;
};

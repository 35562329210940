import {
  createAuthHeader,
  postJSON,
} from 'src/shared/utils/service/service.utils';

import {
  RemoveDeviceLoaderImplType,
  RemoveDeviceServiceImplType,
} from './remove-device.types';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const RemoveDeviceLoaderImpl: RemoveDeviceLoaderImplType = (
  accessToken: string,
  gigyaToken: string,
  patientId: string,
  deviceId: number,
) => {
  return postJSON(
    endpointWithParams(ENDPOINTS.removeDevice, { patientId, deviceId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );
};

export const RemoveDeviceServiceImpl: RemoveDeviceServiceImplType =
  (load: RemoveDeviceLoaderImplType) =>
  (accessToken, gigyaToken, patientId, deviceId) =>
    load(accessToken, gigyaToken, patientId, deviceId);

import { createPayloadAction } from 'src/app/store/app.actions';
import {
  UnblindBodyData,
  UnblindPatientErrorResponse,
} from '../../../../services/patient/clinical-studies/interventional-clinical-studies/unblind-clinical-study-patient/unblind-clinical-study-patient.types';
import { UnblindPatientStartAction } from './unblind-clinical-study-patient.types';
import {
  UnblindPatientActionType,
  UnblindPatientErrorAction,
  UnblindPatientSuccessAction,
} from './unblind-clinical-study-patient.types';

export const unblindClinicalStudyPatientStart = (
  payload: string,
): UnblindPatientStartAction =>
  createPayloadAction(UnblindPatientActionType.UNBLIND_PATIENT_START, payload);

export const unblindClinicalStudyPatientSuccess = (
  payload: UnblindBodyData,
): UnblindPatientSuccessAction =>
  createPayloadAction(
    UnblindPatientActionType.UNBLIND_PATIENT_SUCCESS,
    payload,
  );

export const unblindClinicalStudyPatientError = (
  payload: UnblindPatientErrorResponse,
): UnblindPatientErrorAction =>
  createPayloadAction(UnblindPatientActionType.UNBLIND_PATIENT_ERROR, payload);

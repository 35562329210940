import React, { useRef, useEffect, useCallback } from 'react';
import { FunctionComponent } from 'react';
import styles from './styles.module.css';
import { Page } from '../../../store/announcements.types';
import { WhatsNewUiComposer } from '../whats-new-ui-composer/whats-new-ui-composer.component';
import { t } from 'i18next';
import { testId } from '@roche/roche-common';
import {
  BLOCK,
  CONTAINER,
  TITLE,
  WHAT_NEW_CONTENT,
} from '../../whats-new.constants';
import {
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
} from 'src/app/app.jello.constants';

type WhatsNewContentProps = {
  page: Page;
};

export const WhatsNewContent: FunctionComponent<WhatsNewContentProps> = ({
  page,
}) => {
  const rightSectionRef = useRef<HTMLDivElement | null>(null);

  const scrollToTop = useCallback(() => {
    if (
      rightSectionRef?.current &&
      typeof rightSectionRef?.current?.scrollTo === 'function'
    ) {
      rightSectionRef.current.scrollTo({ top: 0 });
    }
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [page, scrollToTop]);

  return (
    <React.Fragment>
      <div
        ref={rightSectionRef}
        className={styles.contentContainer}
        {...testId(WHAT_NEW_CONTENT, CONTAINER)}
      >
        <jello-text
          size={JELLO_TEXT_SIZES.XL}
          weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
          {...testId(WHAT_NEW_CONTENT, TITLE)}
        >
          {t(page?.pageHeader)}
        </jello-text>
        <br />
        <div {...testId(WHAT_NEW_CONTENT, BLOCK)}>
          {page ? <WhatsNewUiComposer content={page} /> : <></>}
        </div>
      </div>
    </React.Fragment>
  );
};

/* tslint:disable */

import * as React from 'react';
import {
  BodyRowType,
  HeaderRowType,
} from 'src/domains/diagnostics/components/table-primary/table-primary.types';
import {
  bgReminderHeaderKeys,
  getLabelFromAttribute,
  getUnitFromAttribute,
  TabRemaindersStrings,
  TRANSLATE_PREFIX,
} from './tab-reminders.constants';
import { keys } from 'ramda';
import { UnitValue } from '../../mock-data/tables/tables.components.style';
import { StyledBox } from 'src/domains/diagnostics/components/table-primary/table-primary-cell/table-primary-cell.style';
import { convertToHourMinuteFormat } from '../../device-settings.utils';

const moment = require('moment');

export class GenerateTable {
  private units;
  private showPreviousValue;
  private comparePreviousValue;
  private t;
  private is12HourFormat;
  constructor({
    units = {},
    comparePreviousValue = true,
    showPreviousValue = false,
    t = null,
    is12HourFormat = false,
  }) {
    this.units = units;
    this.showPreviousValue = showPreviousValue;
    this.comparePreviousValue = comparePreviousValue;
    this.t = t;
    this.is12HourFormat = is12HourFormat;
  }

  private getRowTable = (
    value,
    previousData: any = [],
    keyPreviousData = '',
  ) => {
    const keysList = keys(value);
    const sizeCols = 100 / keysList.length;
    const values = keysList.map((key) => {
      const unit = getUnitFromAttribute(key, this.units);
      const valuePrev =
        previousData.find(
          (val) => val[keyPreviousData] === value[keyPreviousData],
        ) || {};
      let previousValue = getLabelFromAttribute(valuePrev[key]);
      let currentValue = getLabelFromAttribute(value[key]);

      if (isValidTime(currentValue)) {
        currentValue = convertToHourMinuteFormat(
          currentValue,
          this.is12HourFormat,
        );
      } else if (this.t) {
        currentValue = this.t(value[key]);
      }

      if (isValidTime(previousValue)) {
        previousValue = convertToHourMinuteFormat(
          previousValue,
          this.is12HourFormat,
        );
      } else if (this.t) {
        previousValue = this.t(valuePrev[key]);
      }

      const unitElm = unit ? <UnitValue>{unit}</UnitValue> : '';
      const valChecked =
        currentValue || currentValue === 0 ? <>{currentValue}</> : '-';
      const res = {
        value: valChecked,
        unit: unitElm,
        disabled:
          value[TabRemaindersStrings.STATUS] === TabRemaindersStrings.OFF ||
          value[TabRemaindersStrings.ENABLED] === TabRemaindersStrings.OFF,
        colSpan: 2,
        cellWidth: sizeCols,
      };
      if (!this.comparePreviousValue || keyPreviousData === key) {
        return res;
      }

      if (previousValue !== currentValue && previousValue) {
        res[TabRemaindersStrings.VALUE] = <StyledBox>{valChecked}</StyledBox>;
        res[TabRemaindersStrings.DISABLED] = false;
        if (this.showPreviousValue) {
          res[TabRemaindersStrings.VALUE_HL] =
            previousValue || previousValue === 0 ? <>{previousValue}</> : '-';
        }
      }
      return res;
    });
    return {
      type: BodyRowType.Basic,
      data: [...values],
    };
  };

  public getHeaderTable = (header, type = HeaderRowType.Extended) => {
    return header.map((row) => ({
      type,
      data: row,
    }));
  };

  public getTable = ({
    data = [],
    headerTitle,
    previousData = [],
    keyPreviousData = '',
  }) => {
    const title = this.t ? this.t(headerTitle) : headerTitle;
    return {
      body: data.map((row) =>
        this.getRowTable(row, previousData, keyPreviousData),
      ),
      header: this.getHeaderTable([
        [{ colSpan: 2, value: title }, ...this.getHeader(data[0])],
      ]),
    };
  };

  private getHeader = (obj) => {
    const ObjList = keys(obj);
    const res = ObjList.map((key) => {
      const val = this.t
        ? this.t(`${TRANSLATE_PREFIX}.${key.toLowerCase()}`)
        : key.toLowerCase();
      return { colSpan: 2, value: val };
    });
    res.shift();
    return res;
  };
}

export const splitArrayToObjectsByType = (arr = []) => {
  const res = {};
  arr.sort(compareByStatus).forEach((val: any) => {
    const aux = { ...val };
    aux.Type in res ? res[aux.Type].push(aux) : (res[aux.Type] = [aux]);
    delete aux.Type;
  });
  return res;
};

export const transformAppointmentData = (arr = []) => {
  return arr.sort(compareByStatus).map((val: any) => {
    const date = moment(new Date(val.Date)).format('DD MMM YYYY');
    const name = val.Name || val.Type;
    const aux = { Type: name, ...val, Date: date };
    if ('Name' in val) {
      delete aux.Name;
    }
    return aux;
  });
};

export const transformBGReminderData2 = (obj = {}, t) => {
  const keysObjectList = keys(obj);
  const keyStructure = bgReminderHeaderKeys;
  const header = keyStructure.map((struct) => ({
    colSpan: 2,
    value: t(struct.header),
  }));
  const list = keysObjectList.map((key) => {
    const aux = {
      Name: t ? t(getLabelFromAttribute(key)) : getLabelFromAttribute(key),
      ...obj[key],
    };
    aux.Enabled =
      aux.Enabled === true ? TabRemaindersStrings.ON : TabRemaindersStrings.OFF;
    return aux;
  });
  const listSorted = list.sort((a, b) =>
    a.Enabled > b.Enabled ? -1 : a.Enabled < b.Enabled ? 1 : 0,
  );
  const listChecked = listSorted.map((val) => {
    keyStructure.forEach((struct: any) => {
      if (!(struct.key in val)) {
        val[struct.key] = '-';
      }
    });
    return val;
  });

  return {
    header,
    body: listChecked,
  };
};

export const isValidTime = (value) =>
  moment(value, ['hh:mm:ss', 'hh:mm', 'HH:mm:ss', 'HH:mm'], true).isValid();

export const compareByStatus = (a, b) =>
  a.Status > b.Status ? -1 : a.Status < b.Status ? 1 : 0;

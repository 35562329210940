import * as React from 'react';

import { SvgIcon } from '../../icon/icon.component';

export const NoPatientsResults = () => {
  // This is a hack to avoid the following error:
  // "Error: InvalidStateError: Failed to execute 'createSVGMatrix' on 'SVGGraphicsElement': The object is in an invalid state."
  // This is a known issue with React and SVGs:
  const randomUUID = performance.now();
  return (
    <SvgIcon
      width={120}
      height={120}
      originalHeight={120}
      originalWidth={120}
      fill="none"
      title="No patients results"
    >
      <g clipPath={`${'url(#a' + randomUUID + ')'}`}>
        <g clipPath={`${'url(#b' + randomUUID + ')'}`}>
          <path
            d="M59.7 112.7c29.105 0 52.7-23.594 52.7-52.7 0-29.105-23.595-52.7-52.7-52.7C30.595 7.3 7 30.895 7 60c0 29.106 23.595 52.7 52.7 52.7Z"
            fill="#E4E9ED"
          />
          <path
            d="M59.7 112.7c22.3 0 41.4-13.9 49.1-33.5h-79c-3.5 0-6.9 1.5-9.3 4.1l-4.7 5.2c1.7 2.9 3.7 5.6 5.9 8.1 9.6 9.9 23.1 16.1 38 16.1Z"
            fill="#CED3D6"
          />
          <path
            opacity={0.45}
            d="M77.4 91.3c6.02 0 10.9-4.88 10.9-10.9 0-6.02-4.88-10.9-10.9-10.9-6.02 0-10.9 4.88-10.9 10.9 0 6.02 4.88 10.9 10.9 10.9Z"
            fill="#fff"
          />
          <path
            d="M74.2 88.7c-13.2 0-23.8 1.6-23.8 3.5s10.7 3.5 23.8 3.5c2.9 0 21.8-.1 24.4-.2 1.9-1.9 3.6-3.9 5.1-6.1-3.8-.5-24.5-.7-29.5-.7Z"
            fill="#B7BFC5"
          />
          <path
            d="M66.5 79.8H55c-1.5 0-3 .6-4.1 1.7l-.5.4c-1.4 1.4-2 3.5-1.5 5.5.4 1.7 1.6 3.2 3.3 3.9"
            stroke="#203266"
            strokeWidth={2.4}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M93.8 80s-2.6-1.8-5.3 0"
            stroke="#203266"
            strokeWidth={2.6}
            strokeMiterlimit={10}
          />
          <path
            d="M77.4 90.9c6.02 0 10.9-4.88 10.9-10.9 0-6.02-4.88-10.9-10.9-10.9-6.02 0-10.9 4.88-10.9 10.9 0 6.02 4.88 10.9 10.9 10.9Z"
            stroke="#203266"
            strokeWidth={2.4}
            strokeMiterlimit={10}
          />
          <path
            opacity={0.45}
            d="M104.9 90.9c6.02 0 10.9-4.88 10.9-10.9 0-6.02-4.88-10.9-10.9-10.9C98.88 69.1 94 73.98 94 80c0 6.02 4.88 10.9 10.9 10.9Z"
            fill="#fff"
          />
          <path
            d="M104.9 90.9c6.02 0 10.9-4.88 10.9-10.9 0-6.02-4.88-10.9-10.9-10.9C98.88 69.1 94 73.98 94 80c0 6.02 4.88 10.9 10.9 10.9Z"
            stroke="#203266"
            strokeWidth={2.4}
            strokeMiterlimit={10}
          />
          <path
            opacity={0.8}
            d="M120 76.1v33.3l-104.3 1.1 30.1-68.1c0-.2.3-1.5 3.8-3.7 3.8-2.3 7.8-4.1 12-5.5 9.9-3.4 16.2-3.4 17.8-2.6.2.1.3.2.4.3L120 76.1Z"
            fill={`${'url(#c' + randomUUID + ')'}`}
          />
          <path
            d="M30.3 100.6 4.2 58.4l41.7-45.3"
            stroke="#5D6A75"
            strokeWidth={3}
            strokeMiterlimit={10}
          />
          <path
            d="m8.5 65.5-4.3-7.1 5.2-5.7M30.3 100.6l-4.1-6.7"
            stroke="#000"
            strokeWidth={3}
            strokeMiterlimit={10}
          />
          <path
            d="M4.2 62.6a4.2 4.2 0 1 0 0-8.4 4.2 4.2 0 0 0 0 8.4ZM50.9 9.4a4.7 4.7 0 1 0 0-9.4 4.7 4.7 0 0 0 0 9.4ZM31.2 105.7a4.2 4.2 0 1 0 0-8.4 4.2 4.2 0 0 0 0 8.4Z"
            fill="#00CE62"
          />
          <path
            d="M22.4 120c0-12.1 9.8-21.9 21.9-21.9 12.1 0 21.9 9.8 21.9 21.9H22.4Z"
            fill={`${'url(#d' + randomUUID + ')'}`}
          />
          <path
            opacity={0.35}
            d="M65.2 113.7h-42c-.2.6-.3 1.2-.5 1.8h42.9c0-.6-.2-1.2-.4-1.8Z"
            fill="#F9F9F9"
          />
          <path
            d="M66.7 3.3C66 1.4 59.2 2 51.6 4.7S38.2 11.1 38.9 13l5.7 30.9 36.8-12.8L66.7 3.3Z"
            fill={`${'url(#e' + randomUUID + ')'}`}
          />
          <path
            d="M64.577 42.025c10.17-3.542 17.709-8.443 16.837-10.947-.872-2.503-9.824-1.662-19.995 1.88-10.17 3.543-17.709 8.444-16.837 10.947.872 2.504 9.824 1.662 19.995-1.88Z"
            fill="#23242D"
            stroke="#fff"
            strokeWidth={1.39}
            strokeMiterlimit={10}
          />
          <path
            opacity={0.6}
            d="M61.4 32.9c10.2-3.5 19.1-4.4 20-1.8.9 2.6-6.6 7.4-16.8 11-10.2 3.5-19.1 4.4-20 1.8-.9-2.5 6.6-7.4 16.8-11Z"
            fill={`${'url(#f' + randomUUID + ')'}`}
          />
          <path
            d="M63.2 38.6c2.7-.9 4.1-3.8 3.3-6.5-1.6.4-3.3.9-5.1 1.5-1.8.6-3.4 1.3-4.9 2 1.1 2.6 4 4 6.7 3Z"
            fill="#F9FF3D"
          />
          <path
            opacity={0.5}
            d="M65.1 43c2.4 1.1 5.1 0 6.2-2.3 1.1-2.3 0-5.1-2.3-6.2-2.3-1.1-5.1 0-6.2 2.3-1.1 2.3-.1 5.1 2.3 6.2Z"
            fill="#fff"
          />
          <path
            opacity={0.35}
            d="M74.9 18.9c-14.7 1.2-27 7.1-33 10.5l.3 1.9C48 28 60.7 21.7 75.8 20.7c0-.1-.9-1.8-.9-1.8Z"
            fill="#F9F9F9"
          />
          <path
            d="M72.2 72c-.6.4-1.1.8-1.6 1.3-.9.9-1.5 1.9-2 2.9l12.6 12.6c1.1-.5 2.1-1.1 2.9-2 .5-.5.9-1 1.3-1.6L72.2 72ZM77.1 70.5l9.9 9.9c.1-2.6-.8-5.2-2.8-7.1-2-1.9-4.5-2.9-7.1-2.8ZM99.7 72c-.6.4-1.1.8-1.6 1.3-.9.9-1.5 1.9-2 2.9l12.6 12.6c1.1-.5 2.1-1.1 2.9-2 .5-.5.9-1 1.3-1.6L99.7 72ZM104.5 70.5l9.9 9.9c.1-2.6-.8-5.2-2.8-7.1-2-1.9-4.5-2.9-7.1-2.8Z"
            fill="#fff"
          />
          <path
            opacity={0.46}
            d="M44.3 72.4a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"
            fill="#CED3D6"
          />
          <path
            d="M81.6 63.3c5.1.9 9.5-3.5 8.5-8.6-.6-2.9-2.9-5.2-5.8-5.7-5.1-.9-9.5 3.5-8.5 8.6.6 2.8 2.9 5.1 5.8 5.7Z"
            fill="#fff"
          />
          <path
            opacity={0.3}
            d="M77.5 50.2c1.1 2.5 4.1 2.1 4.7 0 .2-.8 0-1.7-.7-2.3-1.6-1.6-4.4-.4-4 2.3Z"
            fill="#CED3D6"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id={`${'c' + randomUUID}`}
          x1={57.467}
          y1={39.225}
          x2={67.94}
          y2={96.55}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.18} stopColor="#fff" />
          <stop offset={0.88} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id={`${'d' + randomUUID}`}
          x1={22.36}
          y1={109.045}
          x2={66.18}
          y2={109.045}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.03} stopColor="#224CFF" />
          <stop offset={0.06} stopColor="#2250FF" />
          <stop offset={0.26} stopColor="#2268FF" />
          <stop offset={0.44} stopColor="#2276FF" />
          <stop offset={0.59} stopColor="#227CFF" />
        </linearGradient>
        <linearGradient
          id={`${'e' + randomUUID}`}
          x1={38.797}
          y1={23.066}
          x2={81.38}
          y2={23.066}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.03} stopColor="#224CFF" />
          <stop offset={0.06} stopColor="#2250FF" />
          <stop offset={0.26} stopColor="#2268FF" />
          <stop offset={0.44} stopColor="#2276FF" />
          <stop offset={0.59} stopColor="#227CFF" />
        </linearGradient>
        <linearGradient
          id={`${'f' + randomUUID}`}
          x1={50.177}
          y1={31.333}
          x2={75.755}
          y2={43.697}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.24} stopColor="#fff" />
          <stop offset={0.32} stopColor="#fff" stopOpacity={0.96} />
          <stop offset={0.45} stopColor="#fff" stopOpacity={0.85} />
          <stop offset={0.62} stopColor="#fff" stopOpacity={0.67} />
          <stop offset={0.83} stopColor="#fff" stopOpacity={0.43} />
          <stop offset={1} stopColor="#fff" stopOpacity={0.2} />
        </linearGradient>
        <clipPath id={`${'a' + randomUUID}`}>
          <path fill="#fff" d="M0 0h120v120H0z" />
        </clipPath>
        <clipPath id={`${'b' + randomUUID}`}>
          <path fill="#fff" d="M0 0h120v120H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

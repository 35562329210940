import * as React from 'react';

import { colors } from 'src/app/styles/colors';
import { DisclaimerIcon } from 'src/shared/design-system/icons';
import { NoDataP } from 'src/domains/diagnostics/components/no-data-disclaimer/no-data-disclaimer.style';
import {
  GridContainer,
  GridItem,
} from 'src/shared/design-system/grid-layout/index';

export function Disclaimer() {
  return (
    <GridContainer style={{ textAlign: 'center', paddingBottom: '1.5rem' }}>
      <GridItem span={12}>
        <DisclaimerIcon
          width={60}
          height={60}
          withBorder
          iconColor={colors.charcoal}
          borderFillColor={colors.white}
          borderColor={colors.charcoal}
        />
        <NoDataP>{'No data available.'}</NoDataP>
      </GridItem>
    </GridContainer>
  );
}

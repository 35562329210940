import * as React from 'react';

import { withTranslation } from '../../../utils/i18n/with-translation';
import { SvgIcon } from '../../icon/icon.component';
import { colors } from '../../../../app/styles/colors';

const originalWidth = 18;
const originalHeight = 18;
// tslint:disable-next-line: no-identical-expressions
const aspectRatio = originalWidth / originalWidth;

export const SquareHashComponent = ({
  height = originalHeight,
  fillColor = colors.blueMarineAlpha,
  strokeColor = colors.blueMarineBorder,
  textColor = colors.brandBlue,
}) => (
  <SvgIcon
    width={height * aspectRatio}
    height={height}
    originalWidth={originalWidth}
    originalHeight={originalHeight}
  >
    <g
      id="Device-Settings"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      transform="translate(1, 1)"
    >
      <rect
        id="Rectangle-5"
        stroke={strokeColor}
        strokeWidth="1"
        fill={fillColor}
        fillRule="evenodd"
        x="0.5"
        y="0.5"
        width="15"
        height="15"
      />
      <text
        id="#"
        fontFamily="Nunito-SemiBold, Nunito SemiBold"
        fontSize="12"
        fontWeight="500"
        fill={textColor}
      >
        <tspan x="5" y="12" fontWeight="bold">
          #
        </tspan>
      </text>
    </g>
  </SvgIcon>
);

export const SquareHashIcon = withTranslation(SquareHashComponent);

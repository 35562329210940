import { createRequestActionTypes } from 'src/app/store/request/request.actions';

export const FETCH_PATIENTS = createRequestActionTypes('FETCH_PATIENTS');

export const search = (
  unitGlucose: string,
  location?: string,
  term?: string,
  role?: false,
) => ({
  type: FETCH_PATIENTS.START,
  payload: {
    inputText: term,
    location,
    unitGlucose,
    role,
  },
});

export const UPDATE_SEARCH_BY_TAB = 'UPDATE_SEARCH_BY_TAB';
export const updateSearchByTab = (searchByTab: boolean) => ({
  type: UPDATE_SEARCH_BY_TAB,
  payload: searchByTab,
});

export const UPDATE_DASHBOARD_LOCATION_ISOLATED =
  'UPDATE_DASHBOARD_LOCATION_ISOLATED';
export const updateDashboardLocationIsolated = (location: string) => ({
  type: UPDATE_DASHBOARD_LOCATION_ISOLATED,
  payload: location,
});

export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM';
export const updateSearchTerm = (term) => ({
  type: UPDATE_SEARCH_TERM,
  payload: term,
});

export const FILTER_PATIENTS = 'FILTER_PATIENTS';
export const filterPatients = (filters) => ({
  type: FILTER_PATIENTS,
  filters,
});

export const FILTER_PATIENTS_NC = 'FILTER_PATIENTS_NC';
export const filterPatientsNc = (filters) => ({
  type: FILTER_PATIENTS_NC,
  filters,
});

export const CLEAR_CRITERIA = 'CLEAR_CRITERIA';
export const clearCriteria = () => ({ type: CLEAR_CRITERIA });

export const SORT_PATIENTS = 'SORT_PATIENTS';
export const sortPatients = (value) => ({
  type: SORT_PATIENTS,
  payload: value,
});

export const FILTER_PATIENTS_SET = 'FILTER_PATIENTS_SET';
export const filterPatientsSet = (filters) => ({
  type: FILTER_PATIENTS_SET,
  filters,
});

export const FILTER_PATIENTS_SET_NC = 'FILTER_PATIENTS_SET_NC';
export const filterPatientsSetNc = (filtersNc) => ({
  type: FILTER_PATIENTS_SET_NC,
  filtersNc,
});

export const SET_PAGE = 'SET_PAGE';
export const setPage = (value) => ({
  type: SET_PAGE,
  payload: value,
});

export const SET_PER_PAGE = 'SET_PER_PAGE';
export const setPerPage = (value) => ({
  type: SET_PER_PAGE,
  payload: value,
});

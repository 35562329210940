import React from 'react';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';

import {
  GraphSvgIcon,
  SvgIconSpan,
} from 'src/shared/design-system/icon/icon.component';

const PumpStopIconComponent = ({
  x,
  y,
  height,
  width,
  opacity,
  title,
  isGraphIcon = true,
  t,
  cursor,
  onMouseMove,
  onMouseOut,
  onClick,
}) => {
  const originalWidth = 17;
  const originalHeight = 17;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  const iconTitle = title || t('graphs.iconTitles.pumpStop');

  const coreIcon = (
    <GraphSvgIcon
      x={x}
      y={y}
      title={iconTitle}
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
      titleNotToBeRendered={onMouseMove && true}
    >
      <rect
        fill="#F56E23"
        width={originalWidth}
        height={originalHeight}
        rx="1"
        fillRule="evenodd"
        opacity={opacity}
        cursor={cursor}
        onMouseMove={onMouseMove}
        onMouseOut={onMouseOut}
        onClick={onClick}
      />
    </GraphSvgIcon>
  );

  return !isGraphIcon ? SvgIconSpan(iconTitle, coreIcon) : coreIcon;
};

export const PumpStopIcon = withTranslation(PumpStopIconComponent);

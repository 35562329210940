import * as React from 'react';
import {
  ToolTip,
  TooltipPreventRenderProps,
} from './tooltip-prevent-render.types';

class TooltipPreventRender extends React.Component<
  TooltipPreventRenderProps,
  any
> {
  public shouldComponentUpdate(nextProps) {
    if (!this.props.toolTip || !nextProps.toolTip) {
      return true;
    }
    return !hasTooltipChangedState(this.props.toolTip, nextProps.toolTip);
  }

  public render() {
    return <>{this.props.children}</>;
  }
}

const hasTooltipChangedState = (
  currentToolTip: ToolTip,
  nextTooltip: ToolTip,
) => {
  return (
    hasCoordinateChanged(currentToolTip.x, nextTooltip.x) ||
    hasCoordinateChanged(currentToolTip.y, nextTooltip.y)
  );
};

const hasCoordinateChanged = (coordinate: number, nextCoordinate: number) => {
  return coordinate !== nextCoordinate;
};

export default TooltipPreventRender;

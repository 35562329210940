import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { retryLogin } from 'src/shared/design-system/modal/store/login-system-error/login-system-error.action';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';

import { LoginSystemErrorModalComponent } from './login-system-error.modal';

const loginSystemErrorConnector = createStructuredSelector({});

export const loginSystemErrorModal = compose(
  connect(
    loginSystemErrorConnector,
    mapDispatchers({
      retryLogin,
    }),
  ),
  withTranslation,
)(LoginSystemErrorModalComponent);

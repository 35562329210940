import React from 'react';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { SvgIcon } from 'src/shared/design-system/icon/icon.component';
import { colors } from 'src/app/styles/colors';

const originalWidth = 12;
const originalHeight = 6;
const aspectRatio = originalWidth / originalHeight;

export const Logbook24hourBgIconComponent = ({
  height = originalHeight,
  fillColor = colors.white,
  t,
}) => (
  <SvgIcon
    title={t('graphs.iconTitles.logbook24hours.bgAvg')}
    width={height * aspectRatio}
    height={height}
    originalWidth={originalWidth}
    originalHeight={originalHeight}
  >
    <g
      id="Logbook-24-Hour-Chart"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="24-Hour-Logbook-XL-(V001)"
        transform="translate(-1238.000000, -558.000000)"
        fill="#3A3A3A"
        fillRule="nonzero"
      >
        <g id="Group-18" transform="translate(1230.000000, 507.000000)">
          <g id="Group-16" transform="translate(0.000000, 34.000000)">
            <g id="Group-21" transform="translate(8.378378, 17.000000)">
              <polygon
                id="Bg"
                points="10.9958506 0 12.6216216 0.688619962 7.61818072 4 4.40929819 1.73489901 1.09381991 3.4898669 -0.378378378 2.71040203 4.63435664 0.0563771678 7.67179656 2.20063335"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export const Logbook24hourBgIcon = withTranslation(
  Logbook24hourBgIconComponent,
);

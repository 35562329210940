import { createRequestActionTypes } from 'src/domains/diagnostics/core/request/request.actions';

export const FETCH_PATIENT_DATE_RANGE = 'PATIENT/FETCH_DATE_RANGE';
export const FETCH_PATIENT_DATE_RANGE_REQUEST = createRequestActionTypes(
  FETCH_PATIENT_DATE_RANGE,
);
export const FETCH_PATIENT_DATE_RANGE_FROM_FHIR =
  'PATIENT/FETCH_DATE_RANGE_FROM_FHIR';
export const PATIENT_DATE_ACTIONS = {
  SET_DATES: 'PATIENT/SET_DATES',
  SET_FIRST_LAST_DATES: 'PATIENT/SET_FIRST_LAST_DATES',
  CLEAR: 'PATIENT/CLEAR_DATES',
};

import {
  GetClinicalStudiesActionType,
  GetClinicalStudiesState,
  GetClinicalStudiesActions,
} from './get-clinical-studies.types';

export const getClinicalStudiesInitialState: GetClinicalStudiesState = {
  isGettingClinicalStudies: false,
  isClinicalStudiesSuccessful: false,
  isClinicalStudiesError: false,
  observationalClinicalStudies: [],
};

export const getClinicalStudiesReducer = (
  state = getClinicalStudiesInitialState,
  action: GetClinicalStudiesActions,
): GetClinicalStudiesState => {
  switch (action.type) {
    case GetClinicalStudiesActionType.GET_CLINICAL_STUDIES_START:
      return {
        ...state,
        isGettingClinicalStudies: true,
        isClinicalStudiesSuccessful: false,
        isClinicalStudiesError: false,
        observationalClinicalStudies: [],
      };
    case GetClinicalStudiesActionType.GET_CLINICAL_STUDIES_SUCCESS:
      return {
        isGettingClinicalStudies: false,
        isClinicalStudiesSuccessful: true,
        isClinicalStudiesError: false,
        observationalClinicalStudies: action.payload,
      };
    case GetClinicalStudiesActionType.GET_CLINICAL_STUDIES_ERROR:
      return {
        isGettingClinicalStudies: false,
        isClinicalStudiesSuccessful: false,
        isClinicalStudiesError: true,
        observationalClinicalStudies: [],
      };
    default:
      return state;
  }
};

import { ICON_SIZES } from '@rdcs/jello-ds/components/icon/constants.js';
import {
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from '@rdcs/jello-ds/components/button/constants.js';
import {
  CHIP_COLORS,
  CHIP_SIZES,
} from '@rdcs/jello-ds/components/chip/constants.js';
import {
  TEXT_SIZES,
  TEXT_WEIGHTS,
} from '@rdcs/jello-ds/components/text/constants.js';
import {
  HEADING_LEVELS,
  HEADING_SIZES,
  HEADING_WEIGHTS,
} from '@rdcs/jello-ds/components/heading/constants.js';
import {
  BOTTOM,
  LEFT,
  RIGHT,
  TOP,
} from '@rdcs/jello-ds/lib/constants/positions';
import { CENTER, END, START } from '@rdcs/jello-ds/lib/constants/alignments';
import {
  ICON_BUTTON_SIZES,
  ICON_BUTTON_VARIANTS,
} from '@rdcs/jello-ds/components/icon-button/constants.js';
import {
  BOX_BACKGROUND_COLORS,
  BOX_BORDER_RADIUS_SIZES,
} from '@rdcs/jello-ds/components/box/constants.js';
import {
  TOOLBAR_BORDER_POSITIONS,
  TOOLBAR_ORIENTATIONS,
  TOOLBAR_SIZES,
} from '@rdcs/jello-ds/components/toolbar/constants';

import { ALERT_VARIANTS } from '@rdcs/jello-ds/components/alert/constants';

import { TABS_POSITIONS } from '@rdcs/jello-ds/components/tabs/constants';

import { TOOLTIP_POSITIONS } from '@rdcs/jello-ds/components/tooltip/constants';

import { OPTION_PICKER_BACKGROUND_COLORS } from '@rdcs/jello-ds/components/option-picker/constants';

import { LOADING_SIZES } from '@rdcs/jello-ds/components/loading/constants';

import { TOGGLE_LABEL_POSITIONS } from '@rdcs/jello-ds/components/toggle/constants';

import { SEARCH_BOX_SIZES } from '@rdcs/jello-ds/components/search-box/constants';

import { POPOVER_ALIGNMENTS } from '@rdcs/jello-ds/components/popover/constants';

import {
  AVATAR_COLORS,
  AVATAR_SIZES,
} from '@rdcs/jello-ds/components/avatar/constants';

import { DATA_UNIT_SIZES } from '@rdcs/jello-ds/components/data-unit/constants';

import { JELLO_ICON_NAMES as JELLO_ICON_NAMES_CONSTANTS } from './setup-icons';

import {
  AGP_PERCENTILE_NAMES,
  AGP_RANGE_NAMES,
} from '@rdcs/jello-graphs-cgm/esm/agp.js';

import {
  DAILY_VIEW_VARIANTS,
  DAILY_VIEW_TIME_NOTATIONS,
  DAILY_VIEW_EVENTS,
} from '@rdcs/jello-graphs-cgm/esm/daily-view.js';

import { TIME_IN_RANGES_VALUE_TYPES } from '@rdcs/jello-graphs-cgm/esm/time-in-ranges.js';

import { CARBS_EVENTS } from '@rdcs/jello-graphs-cgm/esm/carbs';

import { INSULIN_EVENTS } from '@rdcs/jello-graphs-cgm/esm/insulin';

import { INSULIN_TYPES } from '@rdcs/jello-graphs-cgm/esm/insulin';

import { PUMP_EVENTS } from '@rdcs/jello-graphs-cgm/esm/pump';

import { MILESTONES_EVENTS } from '@rdcs/jello-graphs-cgm/esm/milestones';

import { PUMP_TIME_NOTATIONS } from '@rdcs/jello-graphs-cgm/esm/pump.js';

import {
  BGM_TREND_SCATTER_PLOT_EVENTS,
  GLUCOSE_VALUE_TYPES,
} from '@rdcs/jello-graphs-cgm/bgm-trend-scatter-plot.js';

export const JELLO_GENERAL_ALIGNMENTS = {
  START,
  CENTER,
  END,
};

export const JELLO_GENERAL_POSITIONS = {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT,
};

export const JELLO_ICON_SIZES = ICON_SIZES;

export const JELLO_ICON_BUTTON_SIZES = ICON_BUTTON_SIZES;

export const JELLO_ICON_BUTTON_VARIANTS = ICON_BUTTON_VARIANTS;

export const JELLO_BUTTON_SIZES = BUTTON_SIZES;

export const JELLO_BUTTON_VARIANTS = BUTTON_VARIANTS;

export const JELLO_CHIP_SIZES = CHIP_SIZES;

export const JELLO_CHIP_COLOR = CHIP_COLORS;

export const JELLO_TEXT_SIZES = TEXT_SIZES;

export const JELLO_TEXT_WEIGHTS = TEXT_WEIGHTS;

export const JELLO_HEADINGS_LEVELS = HEADING_LEVELS;

export const JELLO_HEADINGS_SIZES = HEADING_SIZES;

export const JELLO_HEADINGS_WEIGHTS = HEADING_WEIGHTS;

export const JELLO_ICON_NAMES = JELLO_ICON_NAMES_CONSTANTS;

export const JELLO_BOX_BACKGROUND = BOX_BACKGROUND_COLORS;

export const JELLO_BOX_BORDER_RADIUS = BOX_BORDER_RADIUS_SIZES;

export const JELLO_TOOLBAR_BORDER_POSITIONS = TOOLBAR_BORDER_POSITIONS;

export const JELLO_TOOLBAR_SIZES = TOOLBAR_SIZES;

export const JELLO_TOOLBAR_ORIENTATIONS = TOOLBAR_ORIENTATIONS;

export const JELLO_OPTION_PICKER_BACKGROUND = OPTION_PICKER_BACKGROUND_COLORS;

export const JELLO_ALERT_VARIANTS = ALERT_VARIANTS;

export const JELLO_LOADING_SIZES = LOADING_SIZES;

export const JELLO_TOGGLE_LABEL_POSITIONS = TOGGLE_LABEL_POSITIONS;

export const JELLO_TABS_POSITIONS = TABS_POSITIONS;

export const JELLO_SEARCHBOX_SIZES = SEARCH_BOX_SIZES;

export const JELLO_TOOLTIP_POSITIONS = TOOLTIP_POSITIONS;

export const JELLO_POPOVER_ALIGNMENTS = POPOVER_ALIGNMENTS;

export const JELLO_AVATAR_SIZES = AVATAR_SIZES;

export const JELLO_AVATAR_COLORS = AVATAR_COLORS;

export const JELLO_DATA_UNIT_SIZES = DATA_UNIT_SIZES;

export const JELLO_AGP_RANGE_NAMES = AGP_RANGE_NAMES;

export const JELLO_AGP_PERCENTILE_NAMES = AGP_PERCENTILE_NAMES;

export const JELLO_DAILY_VIEW_VARIANTS = DAILY_VIEW_VARIANTS;

export const JELLO_DAILY_VIEW_TIME_NOTATIONS = DAILY_VIEW_TIME_NOTATIONS;

export const JELLO_DAILY_VIEW_EVENTS = DAILY_VIEW_EVENTS;

export const JELLO_TIME_IN_RANGES_VALUE_TYPES = TIME_IN_RANGES_VALUE_TYPES;

export const JELLO_CARBS_EVENTS = CARBS_EVENTS;

export const JELLO_INSULIN_EVENTS = INSULIN_EVENTS;

export const JELLO_INSULIN_INSULIN_TYPES = INSULIN_TYPES;

export const JELLO_PUMP_TIME_NOTATIONS = PUMP_TIME_NOTATIONS;

export const JELLO_PUMP_EVENTS = PUMP_EVENTS;

export const JELLO_PUMP_INDICATORS_EVENTS = MILESTONES_EVENTS;

export const TREND_SCATTER_PLOT_EVENTS = BGM_TREND_SCATTER_PLOT_EVENTS;

export const TREND_SCATTER_PLOT_VALUE_TYPES = GLUCOSE_VALUE_TYPES;

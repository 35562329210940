// tslint:disable
import { testId } from '@roche/roche-common';
import * as React from 'react';
import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { removeAlertAction } from 'src/shared/design-system/alerts/store/alert.actions';
import {
  AlertItem,
  AlertType,
} from 'src/shared/design-system/alerts/store/alert.types';
import { alertConnector } from 'src/shared/design-system/alerts/store/alert.selectors';
import { StyledFloatingAlertWrapper } from 'src/widgets/alert/alert-widget.styles';

import { RenderIf } from 'src/shared/utils/render-if';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import { Portal } from 'src/domains/diagnostics/components/portal/portal.component';
import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { JELLO_ALERT_VARIANTS } from '../../app/app.jello.constants';

export type AlertWidgetComponentProps = {
  activeAlerts: AlertItem[];
  removeAlert: () => void;
  t: (param) => string;
};
const JELLO_ALERT_TOAST_ID = 'jello-alert-toast';
const JELLO_ALERT_TOAST_CLOSE_ACTION = 'jello-alert-close-action';

export const AlertWidgetComponent: React.FunctionComponent<
  AlertWidgetComponentProps
> = (props: AlertWidgetComponentProps) => {
  const { activeAlerts, t } = props;
  return (
    // @ts-ignore
    <Portal rootId="alert-root">
      <RenderIf validate={activeAlerts.length > 0}>
        {activeAlerts.map((alert: AlertItem, key) => {
          if (alert.type === AlertType.SUCCESS) {
            return (
              <StyledFloatingAlertWrapper
                key={key}
                position={alert.position ? alert.position : null}
              >
                <jello-alert
                  id={JELLO_ALERT_TOAST_ID}
                  variant="success"
                  title-text={t(alert.text[AlertType.SUCCESS])}
                  show-close-button
                  {...testId('jello-alert-toast', 'success')}
                />
              </StyledFloatingAlertWrapper>
            );
          } else if (alert.type === AlertType.ERROR) {
            return (
              <StyledFloatingAlertWrapper
                key={key}
                position={alert.position ? alert.position : null}
              >
                <jello-alert
                  id={JELLO_ALERT_TOAST_ID}
                  variant={JELLO_ALERT_VARIANTS.DANGER}
                  title-text={t(alert.text[AlertType.ERROR])}
                  show-close-button
                  {...testId('jello-alert-toast', 'error')}
                >
                  {alert.content ? t(alert.content[AlertType.ERROR]) : null}
                </jello-alert>
              </StyledFloatingAlertWrapper>
            );
          }
          return <></>;
        })}
      </RenderIf>
    </Portal>
  );
};

export const AlertWidget = compose(
  connect(alertConnector, mapDispatchers({ removeAlert: removeAlertAction })),
  withTranslation,
  lifecycle<any, {}>({
    componentDidUpdate(prevProps) {
      const toast = document.getElementById(JELLO_ALERT_TOAST_ID);
      if (toast) {
        toast.addEventListener(JELLO_ALERT_TOAST_CLOSE_ACTION, () =>
          this.props.removeAlert(),
        );
      }
      if (this.props.activeAlerts.length !== prevProps.activeAlerts.length) {
        setTimeout(() => {
          this.props.removeAlert();
        }, 2000);
      }
    },
  }),
)(AlertWidgetComponent);

import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { StickyContainer } from 'react-sticky';
import { isNil } from 'ramda';
import { connect } from 'react-redux';
import { withPatientSummary } from 'src/domains/patient/utils/with-patient-summary';
import { TabsHeader } from 'src/domains/patient-dashboards/components/tabs-header/tabs-header.component';
import { RenderIf } from 'src/shared/utils/render-if';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { Tabs } from 'src/domains/diagnostics/components/tabs/tabs.component';
import { AdditionalInfo } from 'src/domains/diagnostics/components/additional-info/additional-info.container';
import { INFOS } from 'src/domains/diagnostics/components/additional-info/additional-info.constants';
import { CardMinimizer } from 'src/shared/design-system/card/card-minimizer/card-minimizer.component';
import { GRAPH_DEVICE_SETTINGS } from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { selectJelloPermission } from 'src/domains/permissions/store/permissions.selectors.js';
import { selectShouldShowHILOBanner } from 'src/domains/patient-dashboards/bg/store/bg.selectors';
import { selectRangeTypeInAllRangeDate } from 'src/domains/diagnostics/store/selectors/diagnostics.selector';

import { gpTabs, tabs } from './graph-wrapper.constants';
import {
  GraphTabsContainer,
  GraphTabsContent,
  GraphWrapperGridContainer,
  GridItemGraph,
  NavContainerOptions,
} from './graph-wrapper.style';
import { getGraphNameFromUrl } from './graph-wrapper.util';

import { HiAndLoValuesAlertComponent } from 'src/domains/diagnostics/widgets/hi-and-lo-alert/hi-and-lo-alert.component';
import { FlexibleHeightCard } from '../scenes/graphs/graph.style';
import { connectGraphs } from '../scenes/graphs/graph.container';
import { withJelloWrapper } from 'src/domains/patient/utils/with-jello-wrapper';
import { hideBannerForDateRange } from 'src/domains/patient-dashboards/bg/store/bg.actions';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import { push } from 'react-router-redux';
import {
  JELLO_ALERT_HI_AND_LO_VALUES_ID,
  JELLO_ALERT_HI_AND_LO_VALUES_ACTION,
  JELLO_ALERT_HI_AND_LO_VALUES_LINK_ACTION,
} from 'src/domains/diagnostics/widgets/hi-and-lo-alert/hi-and-lo-alert.constants';
import { HIandLOAlertTypes } from '../widgets/hi-and-lo-alert/hi-and-lo-alert.types';

export class GraphPage extends React.Component {
  componentDidMount() {
    const {
      changeGraph,
      location: { pathname: path },
      match: { url },
    } = this.props;
    changeGraph(getGraphNameFromUrl(path, url));
  }

  componentDidUpdate() {
    this.handleHiAndLoAlert();
  }

  /* istanbul ignore next */
  handleHiAndLoAlert() {
    const deleteAlert = () => {
      this.props.hideBannerForDateRange(
        this.props.startDate,
        this.props.endDate,
      );
    };

    const hiAndLoAlertLink = () => {
      this.props.goTo('logbook');
    };

    const hiAndLoAlert = document.getElementById(
      JELLO_ALERT_HI_AND_LO_VALUES_ID,
    );

    if (hiAndLoAlert) {
      hiAndLoAlert.removeEventListener(
        JELLO_ALERT_HI_AND_LO_VALUES_ACTION,
        deleteAlert,
      );

      hiAndLoAlert.addEventListener(
        JELLO_ALERT_HI_AND_LO_VALUES_ACTION,
        deleteAlert,
      );

      hiAndLoAlert.removeEventListener(
        JELLO_ALERT_HI_AND_LO_VALUES_LINK_ACTION,
        hiAndLoAlertLink,
      );

      hiAndLoAlert.addEventListener(
        JELLO_ALERT_HI_AND_LO_VALUES_LINK_ACTION,
        hiAndLoAlertLink,
      );
    }
  }

  render() {
    const {
      children,
      changeGraph,
      graph,
      match,
      isHCPProfessional,
      hcpPermissions,
      patientPermissions,
      t,
      infoHILOBanner,
    } = this.props;
    let filteredTabs = (_g) => [];
    if (!isNil(isHCPProfessional)) {
      filteredTabs = isHCPProfessional ? tabs : gpTabs;
    }
    const translatedTabNames = filteredTabs(changeGraph).map((item) => ({
      activeTitle: '',
      name: t(item.name),
      ...item,
    }));

    const tabsWithJelloActivated = translatedTabNames.filter(
      (item) => item.link !== GRAPH_DEVICE_SETTINGS,
    );

    const hiAndLoValues = {
      numberOfHiValues: infoHILOBanner.numberOfHiValues,
      numberOfLoValues: infoHILOBanner.numberOfLoValues,
    };

    const shouldRenderAlert = infoHILOBanner.shouldShowBanner;

    if (shouldRenderAlert) {
      this.handleHiAndLoAlert();
    }

    const extraMarginBottom = [
      'trend',
      'standard-week',
      'standard-day',
    ].includes(graph);

    return (
      <StickyContainer>
        <RenderIf validate={shouldRenderAlert}>
          <HiAndLoValuesAlertComponent
            type={HIandLOAlertTypes.BG}
            hiAndLoValues={hiAndLoValues}
          />
        </RenderIf>
        <GraphWrapperGridContainer
          addExtraMarginBottom={extraMarginBottom}
          marginBottom
          gridTemplateRows={'98%'}
        >
          <GridItemGraph
            span="12"
            style={{ margin: '0 1rem', minHeight: '52rem' }}
          >
            <FlexibleHeightCard>
              <Tabs
                render={(children) => (
                  <GraphTabsContainer>{children}</GraphTabsContainer>
                )}
              >
                <NavContainerOptions>
                  <TabsHeader
                    items={tabsWithJelloActivated}
                    breakpoint={isHCPProfessional ? 1600 : 2000}
                    active={graph}
                    expanded={true}
                  ></TabsHeader>
                  <AdditionalInfo
                    info={INFOS.graphReports}
                    active={graph}
                    style={{ position: 'absolute', right: '40px', top: '8px' }}
                  />
                  <CardMinimizer link={`/patients/${match.params.id}`} />
                </NavContainerOptions>

                <GraphTabsContent>{children}</GraphTabsContent>
              </Tabs>
            </FlexibleHeightCard>
          </GridItemGraph>
        </GraphWrapperGridContainer>
      </StickyContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  hcpPermissions: state.permissions.permissions,
  patientPermissions: state.patientPermissions.patientPermissions,
  infoHILOBanner: selectShouldShowHILOBanner(state),
  rangeTypeInRangeDate: selectRangeTypeInAllRangeDate(state),
  ...ownProps,
});

/* istanbul ignore next */
const dispatchers = mapDispatchers({
  hideBannerForDateRange,
  goTo: (path) => push(path),
});

export const GraphPageWrapper = compose(
  withPatientSummary,
  connectGraphs,
  withRouter,
  withTranslation,
  connect(mapStateToProps, dispatchers),
)(withJelloWrapper ? withJelloWrapper(GraphPage) : GraphPage);

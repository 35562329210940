import { GetBorderColorFn } from './next-shipment.types';
import { STATUS } from './next-shipment.constants';
import { colors } from 'src/app/styles/colors';
import { DateTime } from 'luxon';

export const getBorderColor: GetBorderColorFn = (status) => {
  switch (status) {
    case STATUS.ESTIMATED:
      return colors.trafficGreen;
    case STATUS.PAUSED:
      return colors.trafficOrange;
    case STATUS.CUT:
      return colors.trafficRed;
    default:
      return colors.blue;
  }
};

export const formatNextShipmentDate = (date: string) =>
  DateTime.fromISO(date).toFormat('dd LLL yyyy');

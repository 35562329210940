import * as React from 'react';
import {
  UserAnnouncements,
  UserAnnouncementsTypes,
} from './store/announcements.types';
import { Portal } from 'src/shared/design-system/portal/portal.component';
import { RenderIf } from 'src/shared/utils/render-if';
import { WhatsNew } from './whats-new/whats-new.container';

export interface AnnouncementsProps {
  fetchUserAnnouncementsAction: (payload: {
    type: string;
    countryCode: string;
  }) => void;
  userAnnouncements: UserAnnouncements | null;
  countryCode: string;
}

export const AnnouncementsComponent: React.FunctionComponent<
  AnnouncementsProps
> = ({ fetchUserAnnouncementsAction, userAnnouncements, countryCode }) => {
  const isWhatsNew =
    userAnnouncements?.type === UserAnnouncementsTypes.WHATS_NEW;

  React.useEffect(() => {
    if (countryCode) {
      fetchUserAnnouncementsAction({
        type: UserAnnouncementsTypes.WHATS_NEW,
        countryCode,
      });
    }
  }, [countryCode]);

  return (
    <Portal rootId="announcement-root">
      <RenderIf validate={isWhatsNew}>
        <WhatsNew />
      </RenderIf>
    </Portal>
  );
};

import * as React from 'react';
import { TFunction } from 'i18next';
import {
  BasalValue,
  CarboMeasurementLabel,
  CarboMeasurementValueWrapper,
  CarboMeasurementWrapper,
  ContainerDiv,
  DateLabel,
  DateWrapper,
  HandPointIconWrapper,
  IconWrapper,
  InsulinMeasurementWrapper,
  ManualLabelWrapper,
  MeasurementLabel,
  MeasurementRemark,
  MeasurementUnit,
  MeasurementUnitRemarkWrapper,
  MeasurementValue,
  MeasurementValueWrapper,
  MeasurementWrapper,
  MultipleCarboMeasurementWrapper,
  SingleCarboMeasurementWrapper,
  SingleInsulinMeasurementWrapper,
  TimeLabel,
  TimeLabelValue,
  TimeLabelValueDiv,
  TopBar,
} from 'src/domains/diagnostics/components/tool-tip/detail-tool-tip/detail-tool-tip.style';
import {
  formatLuxonTime,
  getHCPTimeFormat,
  is12HourFormat,
} from 'src/domains/diagnostics/utils/time-format';
import { testId } from '@roche/roche-common';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { LOGBOOK_UNITS_UNIT } from 'src/domains/diagnostics/constants/logbook.constants';
import { formatBGMeasurement } from 'src/domains/diagnostics/utils/measurements';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import { dataTypeToTranslationMap } from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { LocalizedText } from 'src/shared/design-system/localized-text/localized-text.component';
import { getJelloIcon } from 'src/widgets/modal/components/manufacturer-info-modal/components/icons-view/icons-view-templates';
import {
  JELLO_ICON_BUTTON_SIZES,
  JELLO_ICON_NAMES,
} from 'src/app/app.jello.constants';

export type Props = {
  icon?: React.ReactElement<any> | null;
  topBarColor: string | null;
  date: Date | string;
  value: number;
  unit: string;
  t: TFunction;
  carbLabel?: string;
};

export type DetailGlucoseToolTipComponentType = {
  type: string;
  bloodGlucoseUnit: string;
  manuallyEntered: boolean;
};

export type DetailGlucoseToolTipComponentProps = Props &
  DetailGlucoseToolTipComponentType;

const GRAPHS_LOGBOOK_STATS_CARBOHYDRATES = 'graphs.logbookStats.carbohydrates';

export const DetailToolTip = ({
  children,
  date,
  icon,
  topBarColor,
  timeFormat,
  toolTipWidth,
}: any) => {
  const formattedTime =
    date && formatLuxonTime(date, is12HourFormat(timeFormat));

  const formattedDate = date ? date.toFormat('dd LLL yyyy') : null;

  return (
    <ContainerDiv toolTipWidth={toolTipWidth}>
      <TopBar color={topBarColor} />
      <DateWrapper>
        <RenderIf validate={icon}>
          <IconWrapper>{icon}</IconWrapper>
        </RenderIf>
        <RenderIf validate={date}>
          <div {...testId('tooltip', 'time')}>{formattedTime}</div>
          <DateLabel {...testId('tooltip', 'date')}>{formattedDate}</DateLabel>
        </RenderIf>
      </DateWrapper>
      {children}
    </ContainerDiv>
  );
};

export const DetailGlucoseToolTipComponent = ({
  type,
  bloodGlucoseUnit,
  value,
  manuallyEntered,
  t,
  ...props
}: DetailGlucoseToolTipComponentProps) => (
  <DetailToolTip {...props}>
    <MeasurementWrapper>
      <MeasurementLabel>
        <LocalizedText textKey={dataTypeToTranslationMap[type]} />
      </MeasurementLabel>
      <RenderIf validate={manuallyEntered}>
        <ManualLabelWrapper manuallyEntered={manuallyEntered}>
          <LocalizedText
            textKey={'graphs.detailGraph.toolTip.manuallyEntered'}
          />
        </ManualLabelWrapper>
      </RenderIf>
      <MeasurementValueWrapper>
        <RenderIf validate={manuallyEntered}>
          <HandPointIconWrapper>
            {getJelloIcon(JELLO_ICON_NAMES.EDIT, JELLO_ICON_BUTTON_SIZES.XS)}
          </HandPointIconWrapper>
        </RenderIf>
        <MeasurementValue {...testId('tooltip', 'value')}>
          {formatBGMeasurement(bloodGlucoseUnit)(value)}
        </MeasurementValue>
        <MeasurementUnitRemarkWrapper>
          <MeasurementUnit {...testId('tooltip', 'unit')}>
            {t(LOGBOOK_UNITS_UNIT[bloodGlucoseUnit])}
          </MeasurementUnit>
        </MeasurementUnitRemarkWrapper>
      </MeasurementValueWrapper>
    </MeasurementWrapper>
  </DetailToolTip>
);

export const DetailGlucoseToolTip = withTranslation(
  DetailGlucoseToolTipComponent,
);

export const DetailPumpEventToolTipComponent = ({
  value,
  duration,
  t,
  ...props
}) => (
  <DetailToolTip {...props}>
    <MeasurementWrapper>
      <MeasurementValueWrapper>
        <MeasurementValue {...testId('tooltip', 'value')}>
          {value &&
            t(`graphs.detailGraph.toolTip.pumpEvents.${value.toLowerCase()}`)}
        </MeasurementValue>
        <MeasurementUnitRemarkWrapper>
          <MeasurementUnit {...testId('tooltip', 'unit')}>
            {duration}
          </MeasurementUnit>
        </MeasurementUnitRemarkWrapper>
      </MeasurementValueWrapper>
    </MeasurementWrapper>
  </DetailToolTip>
);

export const DetailPumpEventToolTip = withTranslation(
  DetailPumpEventToolTipComponent,
);

export const DetailCarbohydratesToolTipComponent = ({
  value,
  t,
  carbLabel,
  ...props
}) => (
  <DetailToolTip {...props}>
    <MeasurementWrapper>
      <MeasurementLabel>
        {t(GRAPHS_LOGBOOK_STATS_CARBOHYDRATES)}
      </MeasurementLabel>
      <MeasurementValueWrapper>
        <MeasurementValue {...testId('tooltip', 'value')}>
          {value}
        </MeasurementValue>
        <MeasurementUnitRemarkWrapper>
          <MeasurementUnit {...testId('tooltip', 'unit')}>
            {t(carbLabel)}
          </MeasurementUnit>
        </MeasurementUnitRemarkWrapper>
      </MeasurementValueWrapper>
    </MeasurementWrapper>
  </DetailToolTip>
);

export const DetailCarbohydratesToolTip = withTranslation(
  DetailCarbohydratesToolTipComponent,
);

export const formatDate = (date, timeFormat) => {
  const moment = require('moment');
  let formattedTime;
  let formattedDate;
  if (date) {
    const d = moment(date.toISOString().substring(0, 16));
    formattedTime = date ? d.format(getHCPTimeFormat(timeFormat)) : null;
    formattedDate = date ? d.format('DD MMM yyyy') : null;
  }
  return { formattedTime, formattedDate };
};

export const DetailCarbohydratesCgmToolTipComponent = ({
  value,
  t,
  topBarColor,
  timeFormat,
  date,
  carbLabel,
}) => {
  const { formattedTime, formattedDate } = formatDate(date, timeFormat);
  return (
    <ContainerDiv>
      <TopBar color={topBarColor} />
      {value.map((carbo, index) => (
        <React.Fragment key={index}>
          <RenderIf validate={(value || []).length === 1}>
            <DateWrapper>
              <RenderIf validate={carbo.date}>
                <TimeLabelValueDiv {...testId('tooltip', 'time')}>
                  {formattedTime}
                </TimeLabelValueDiv>
                <DateLabel {...testId('tooltip', 'date')}>
                  {formattedDate}
                </DateLabel>
              </RenderIf>
            </DateWrapper>
            <CarboMeasurementWrapper>
              <SingleCarboMeasurementWrapper>
                <CarboMeasurementLabel>
                  {t(GRAPHS_LOGBOOK_STATS_CARBOHYDRATES).toLowerCase()}
                </CarboMeasurementLabel>
                <MeasurementValueWrapper>
                  <MeasurementValue {...testId('tooltip', 'value')} key={index}>
                    {carbo.carbohydrates}
                  </MeasurementValue>
                  <MeasurementUnitRemarkWrapper>
                    <MeasurementUnit {...testId('tooltip', 'unit')}>
                      {t(carbLabel)}
                    </MeasurementUnit>
                  </MeasurementUnitRemarkWrapper>
                </MeasurementValueWrapper>
              </SingleCarboMeasurementWrapper>
            </CarboMeasurementWrapper>
          </RenderIf>
        </React.Fragment>
      ))}
      <RenderIf validate={(value || []).length > 1}>
        <DateWrapper>
          <RenderIf validate={value[0].date}>
            <DateLabel {...testId('tooltip', 'date')}>
              {formattedDate}
            </DateLabel>
          </RenderIf>
        </DateWrapper>
        <CarboMeasurementWrapper>
          <SingleCarboMeasurementWrapper>
            <CarboMeasurementLabel>
              {t(GRAPHS_LOGBOOK_STATS_CARBOHYDRATES).toLowerCase()}
            </CarboMeasurementLabel>
            <MultipleCarboMeasurementWrapper>
              {value.map((carbo, index) => {
                const { formattedTime } = formatDate(carbo.date, timeFormat);
                return (
                  <CarboMeasurementValueWrapper key={index}>
                    <TimeLabel {...testId('tooltip', 'time')}>
                      <TimeLabelValue>{formattedTime}</TimeLabelValue>
                    </TimeLabel>
                    <MeasurementValue {...testId('tooltip', 'value')}>
                      {carbo.carbohydrates}
                    </MeasurementValue>
                    <MeasurementUnitRemarkWrapper>
                      <MeasurementUnit {...testId('tooltip', 'unit')}>
                        {t(carbLabel)}
                      </MeasurementUnit>
                    </MeasurementUnitRemarkWrapper>
                  </CarboMeasurementValueWrapper>
                );
              })}
            </MultipleCarboMeasurementWrapper>
          </SingleCarboMeasurementWrapper>
        </CarboMeasurementWrapper>
      </RenderIf>
    </ContainerDiv>
  );
};

export const DetailCarbohydratesCgmToolTip = withTranslation(
  DetailCarbohydratesCgmToolTipComponent,
);

export const DetailInsulinToolTipComponent = ({
  values,
  remark,
  t,
  ...props
}) => (
  <DetailToolTip {...props}>
    <InsulinMeasurementWrapper>
      <RenderIf validate={(values || []).length > 0}>
        <SingleInsulinMeasurementWrapper>
          <MeasurementLabel>{values[0] && values[0].label}</MeasurementLabel>
          <MeasurementValueWrapper>
            <MeasurementValue {...testId('tooltip', 'value')}>
              {values[0] && values[0].value}
            </MeasurementValue>
            <MeasurementUnitRemarkWrapper>
              <MeasurementUnit {...testId('tooltip', 'unit')}>
                {t('graphs.insulinPump.u')}
              </MeasurementUnit>
              <RenderIf validate={remark}>
                <MeasurementRemark>
                  ({remark && remark.trim()})
                </MeasurementRemark>
              </RenderIf>
            </MeasurementUnitRemarkWrapper>
          </MeasurementValueWrapper>
        </SingleInsulinMeasurementWrapper>
      </RenderIf>
      <RenderIf validate={(values || []).length > 1}>
        <SingleInsulinMeasurementWrapper>
          <MeasurementLabel>{values[1] && values[1].label}</MeasurementLabel>
          <MeasurementValueWrapper>
            <MeasurementValue {...testId('tooltip', 'value')}>
              {values[1] && values[1].value}
            </MeasurementValue>
            <MeasurementUnitRemarkWrapper>
              <MeasurementUnit {...testId('tooltip', 'unit')}>
                {t('graphs.insulinPump.u')}
              </MeasurementUnit>
              <RenderIf validate={remark}>
                <MeasurementRemark>
                  ({remark && remark.trim()})
                </MeasurementRemark>
              </RenderIf>
            </MeasurementUnitRemarkWrapper>
          </MeasurementValueWrapper>
        </SingleInsulinMeasurementWrapper>
      </RenderIf>
    </InsulinMeasurementWrapper>
  </DetailToolTip>
);

export const DetailInsulinToolTip = withTranslation(
  DetailInsulinToolTipComponent,
);

export const DetailBasalEventTooltipComponent = ({ type, t, ...props }) => (
  <DetailToolTip {...props}>
    <MeasurementWrapper>
      <BasalValue {...testId('tooltip', 'value')}>{t(type)}</BasalValue>
    </MeasurementWrapper>
  </DetailToolTip>
);

export const DetailBasalEventTooltip = withTranslation(
  DetailBasalEventTooltipComponent,
);

export const DetailBasalSetbackTooltipComponent = ({
  duration,
  t,
  ...props
}) => (
  <DetailToolTip {...props}>
    <MeasurementWrapper>
      <MeasurementLabel>
        {t('graphs.iconTitles.timeSetbackBy')}
      </MeasurementLabel>
      <MeasurementValueWrapper>
        <MeasurementValue {...testId('tooltip', 'value')}>
          {duration}
        </MeasurementValue>
        <MeasurementUnitRemarkWrapper>
          <MeasurementUnit {...testId('tooltip', 'unit')}>
            {t('graphs.iconTitles.hours')}
          </MeasurementUnit>
        </MeasurementUnitRemarkWrapper>
      </MeasurementValueWrapper>
    </MeasurementWrapper>
  </DetailToolTip>
);

export const DetailBasalSetbackTooltip = withTranslation(
  DetailBasalSetbackTooltipComponent,
);

const getBasalRateTranslationKey = (tbr) =>
  tbr
    ? 'graphs.detailGraph.temporaryBasalRate'
    : 'graphs.detailGraph.basalRate';

export const DetailBasalTooltipComponent = ({
  date,
  endDate,
  topBarColor,
  icon,
  value,
  tbr,
  t,
  timeFormat,
  toolTipWidth,
}) => {
  const formattedTime = date
    ? date.toFormat(getHCPTimeFormat(timeFormat), { locale: 'us' })
    : null;
  const formattedDate = date ? date.toFormat('dd LLL yyyy') : null;
  const formattedEndTime = endDate
    ? endDate.toFormat(getHCPTimeFormat(timeFormat), { locale: 'us' })
    : null;
  return (
    <ContainerDiv toolTipWidth={toolTipWidth}>
      <TopBar color={topBarColor} />
      <DateWrapper>
        <RenderIf validate={icon}>
          <IconWrapper>{icon}</IconWrapper>
        </RenderIf>
        <RenderIf validate={date}>
          <div>{formattedTime + ' - ' + formattedEndTime}</div>
          <DateLabel>{formattedDate}</DateLabel>
        </RenderIf>
      </DateWrapper>
      <MeasurementWrapper>
        <MeasurementLabel>
          {t(getBasalRateTranslationKey(tbr))}
        </MeasurementLabel>
        <MeasurementValueWrapper>
          <MeasurementValue>{value}</MeasurementValue>
          <MeasurementUnitRemarkWrapper>
            <MeasurementUnit {...testId('tooltip', 'unit')}>
              {t('graphs.logbook.u')} / h
            </MeasurementUnit>
          </MeasurementUnitRemarkWrapper>
        </MeasurementValueWrapper>
      </MeasurementWrapper>
    </ContainerDiv>
  );
};

export const DetailBasalTooltip = withTranslation(DetailBasalTooltipComponent);

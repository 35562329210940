import styled from 'styled-components';
import { minWidth } from 'styled-system';
import { Column } from 'src/shared/design-system/column/column.component';

import { Button } from 'src/shared/design-system/button/button.component';
import { combineRems } from 'src/shared/utils/rem-calc';
import { colors } from 'src/app/styles/colors';
import { transitionEasing, transitionSpeed } from 'src/app/styles/transitions';
import { boxShadow } from 'src/app/styles/box-shadow';
import { spacing } from 'src/app/styles/spacing';
import { fadeIn } from 'src/app/styles/animations';

const { fast } = transitionSpeed;
const { enter } = transitionEasing;

type OverlayProps = {
  origin?: string;
};

export const ModalOverlay = styled.div<OverlayProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: ${fadeIn} ${fast} ${enter} 0s backwards;
  background-color: ${(props) =>
    props.origin === 'pairing-code' ? 'none' : colors.transparentBlack};
  z-index: 1000;
  overflow: auto;
`;

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalCard = styled.div`
  border-radius: 0.1875rem;
  box-shadow: ${boxShadow({
    color: colors.black,
    depth: 'modal',
  })};
  display: inline-block;
  position: relative;
  z-index: 200;
  min-height: 11.25rem;
  background: #fff;
  max-width: 60rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

export const ModalBody = styled(Column)`
  ${minWidth};
`;
export const CommonHeader = styled.div`
  display: flex;
  color: ${colors.white};
  background-color: ${colors.brandBlue};
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.three} ${spacing.four};
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
`;
export const WhiteHeader = styled.div`
  display: flex;
  color: ${colors.brandBlue};
  justify-content: space-between;
  align-items: center;
  padding-top: ${spacing.two};
  padding-bottom: ${spacing.three};
  margin: ${spacing.four};
  border-bottom: 1px solid ${colors.grayMedium};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  button {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

export const ButtonContainerDiv = styled.div`
  display: flex;
  justify-content: space-around;
`;

ButtonContainerDiv.displayName = 'ButtonContainerDiv';

export const ModalOptionButton = styled(Button)`
  margin: 0 ${({ theme }) => theme.spacing.three};
`;
ModalOptionButton.displayName = 'ModalOptionButton';

export const ModalColumn = styled(Column)`
  flex-basis: auto;
  padding: ${combineRems(spacing.four, spacing.two)} ${spacing.four}
    ${spacing.four} ${spacing.four};
`;
ModalColumn.displayName = 'ModalColumn';

export const RangeDialogErrorWrapper = styled.div`
  text-align: center;
  padding: 26px 0 24px 0;
  & jello-heading {
    margin: 18px 0;
  }
`;

import React from 'react';
import { Bundle } from 'src/app/navigation';

export const ProfileBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { ProfileContainer } = await import(
        /* webpackChunkName: "profile" */ './scenes/profile/profile.container'
      );
      return ProfileContainer;
    }}
    bundleDidLoad={(ProfileContainer) => <ProfileContainer {...props} />}
  />
);

import { createSelector } from 'reselect';

import {
  selectPatientEndDate,
  selectPatientStartDate,
} from 'src/domains/diagnostics/store/selectors/patient-date-range.selector';

import {
  convertDaysToDayTicks,
  convertDaysToMonthYearTicks,
  generateDayRange,
} from './trend.util';

const selectDayRange = createSelector(
  selectPatientStartDate,
  selectPatientEndDate,
  (startDate, endDate) => generateDayRange(startDate, endDate),
);

export const selectHorizontalDayTicks = createSelector(selectDayRange, (days) =>
  convertDaysToDayTicks(days),
);

export const selectHorizontalMonthYearTicks = createSelector(
  selectDayRange,
  (days) => convertDaysToMonthYearTicks(days),
);

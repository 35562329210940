import { DateTime } from 'luxon';
import {
  DiabetesType,
  TherapyType,
} from 'src/domains/patient/services/patient/patient.types';
import {
  DEFAULT_NULL_VALUE_STRING,
  DIABETES_TYPE_FROM_SERVER_TO_TRANSLATION,
  DIABETES_TYPE_FROM_SERVER_TO_TRANSLATION_SHORT,
  THERAPY_TYPE_FROM_SERVER_TO_TRANSLATION,
  THERAPY_TYPE_FROM_SERVER_TO_TRANSLATION_SHORT,
} from './patient-data-bar.constants';

export const formatDoB = (date) => {
  if (!date) return '-';
  const luxon = DateTime.fromISO(date);
  const format = luxon.toFormat('dd LLL yyyy');
  const age = Math.floor(Math.abs(luxon.diffNow('years').years));
  return `${format} (${age})`;
};

export const formatDiagnosticsDate = (date) => {
  if (!date) return '-';
  const luxon = DateTime.fromISO(date);
  const format = luxon.toFormat('dd LLL yyyy');
  return `${format}`;
};

const getTranslatedValue = (
  type: TherapyType | DiabetesType | null,
  translationMap: { [key: string]: string },
) =>
  translationMap[type as TherapyType | DiabetesType] ||
  DEFAULT_NULL_VALUE_STRING;

export const getShortTranslatedTherapyType = (
  therapyType: TherapyType | null,
) =>
  getTranslatedValue(
    therapyType,
    THERAPY_TYPE_FROM_SERVER_TO_TRANSLATION_SHORT,
  );

export const getTranslatedTherapyType = (therapyType: TherapyType | null) =>
  getTranslatedValue(therapyType, THERAPY_TYPE_FROM_SERVER_TO_TRANSLATION);

export const getTranslatedDiabetesType = (diabetesType: DiabetesType | null) =>
  getTranslatedValue(diabetesType, DIABETES_TYPE_FROM_SERVER_TO_TRANSLATION);

export const getShortTranslatedDiabetesType = (
  diabetesType: DiabetesType | null,
) =>
  getTranslatedValue(
    diabetesType,
    DIABETES_TYPE_FROM_SERVER_TO_TRANSLATION_SHORT,
  );

import React from 'react';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';

import { SvgIcon } from 'src/shared/design-system/icon/icon.component';

const BicycleIconComponent = ({ height, width, t }) => {
  const originalWidth = 27;
  const originalHeight = 16;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  return (
    <SvgIcon
      title={t('graphs.iconTitles.bicycle')}
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g
        id="_icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="NEW-ICONS"
          transform="translate(-994.000000, -528.000000)"
          fillRule="nonzero"
        >
          <g id="Group-15" transform="translate(994.000000, 528.000000)">
            <path
              d="M5.72632793,4.25 C5.63683603,4.25 5.54739657,4.21017259 5.45790467,4.17029849 L4.92105815,3.81171175 C4.51842326,3.53282651 4.25,3.09453825 4.25,2.65625 C4.25,2.21796175 4.47370353,1.77972018 4.87633842,1.50078825 L5.41318494,1.14220151 C5.54739657,1.1023741 5.63688846,1.0625 5.72632793,1.0625 L8.05264538,1.0625 C8.32106864,1.0625 8.5,1.22185632 8.5,1.46091415 C8.5,1.69997199 8.32106864,1.85932831 8.05264538,1.85932831 L5.90525929,1.85932831 L5.45790467,2.13821355 C5.27897331,2.25774246 5.14476168,2.45697289 5.14476168,2.65615662 C5.14476168,2.85534035 5.23425357,3.05457077 5.41318494,3.17409969 L5.95003146,3.53268643 C6.12896282,3.65221535 6.17373498,3.93110059 6.03952335,4.0905036 C5.99475119,4.21017259 5.86053956,4.25 5.72632793,4.25 Z"
              id="Shape"
              fill="#787D82"
            />
            <path
              d="M21.8657424,15.9879453 C19.2135629,15.9879453 17.083076,13.856736 17.083076,11.2035427 C17.083076,8.55034938 19.2135119,6.41914004 21.8657424,6.41914004 C24.5179729,6.41914004 26.6484088,8.55040035 26.6484088,11.2035427 C26.6484088,13.856685 24.5179729,15.9879453 21.8657424,15.9879453 Z M21.8657424,7.28904533 C19.6917939,7.28904533 17.9526656,9.02880494 17.9526656,11.2035427 C17.9526656,13.3782804 19.6917939,15.11804 21.8657424,15.11804 C24.039691,15.11804 25.7788192,13.3782804 25.7788192,11.2035427 C25.7788192,9.02880494 24.039691,7.28904533 21.8657424,7.28904533 Z"
              id="Shape"
              fill="#4A4A4A"
            />
            <path
              d="M4.86574242,15.9879453 C2.21356289,15.9879453 0.0830759896,13.856736 0.0830759896,11.2035427 C0.0830759896,8.55034938 2.21351194,6.41914004 4.86574242,6.41914004 C7.5179729,6.41914004 9.64840885,8.55040035 9.64840885,11.2035427 C9.64840885,13.856685 7.5179729,15.9879453 4.86574242,15.9879453 Z M4.86574242,7.28904533 C2.69179387,7.28904533 0.9526656,9.02880494 0.9526656,11.2035427 C0.9526656,13.3782804 2.69179387,15.11804 4.86574242,15.11804 C7.03969097,15.11804 8.77881924,13.3782804 8.77881924,11.2035427 C8.77881924,9.02880494 7.03969097,7.28904533 4.86574242,7.28904533 Z"
              id="Shape"
              fill="#4A4A4A"
            />
            <path
              d="M5.12729672,11.6875 L4.25,11.2788482 L8.58776394,3.69606929 L8.58776394,1.97063285 L6.68695438,1.97063285 L6.68695438,1.0625 L9.07513197,1.0625 C9.36756421,1.0625 9.5625,1.24410529 9.5625,1.51653982 L9.5625,3.78684533 C9.5625,3.87767458 9.5625,3.9230626 9.51378033,4.01389184 L5.12729672,11.6875 Z"
              id="Shape"
              fill="#0066CC"
            />
            <path
              d="M12.8468476,12.9051099 C12.6208013,12.8176413 12.5304252,12.5552867 12.6660424,12.336692 L17.1865965,1.32485461 C17.2770255,1.10620869 17.5482598,1.01879131 17.7742531,1.14996862 C18.0002994,1.23743723 18.0906755,1.49979184 17.9550583,1.71838652 C15.0658409,8.84176655 13.5538662,12.5210923 13.4191342,12.7563639 C13.2844022,12.9916355 13.09364,13.0412175 12.8468476,12.9051099 Z"
              id="Shape"
              fill="#0066CC"
            />
            <path
              d="M21.5262923,10.4197973 L16.4180816,3.6860461 L9.8180292,3.6860461 L14.11259,12.2055147 L13.3440752,12.6865152 L8.59747483,3.51116011 C8.50704573,3.3799828 8.50704573,3.20509681 8.59747483,3.0739195 C8.64266289,2.89903351 8.77828004,2.81156489 8.95913823,2.81156489 L16.6441278,2.81156489 C16.779745,2.81156489 16.9153621,2.89903351 17.0057912,2.98645089 L22.2496721,9.89513936 L21.5262923,10.4197973 Z"
              id="Shape"
              fill="#0066CC"
            />
            <ellipse
              id="Oval"
              fill="#4A4A4A"
              cx="22.248784"
              cy="10.9391346"
              rx="1"
              ry="1"
            />
            <ellipse
              id="Oval"
              fill="#4A4A4A"
              cx="5.18993936"
              cy="10.9391346"
              rx="1"
              ry="1"
            />
            <path
              d="M17.3537701,3.1875 C17.2827615,3.1875 17.247278,3.1875 17.1762693,3.13003734 C16.9987686,2.957582 16.9632851,2.61267131 17.0342937,2.32522329 L17.3892536,1.34795389 C17.4957457,1.06050586 17.7087299,1.00304321 17.8862307,1.11803589 C18.0637314,1.29049123 18.0992149,1.63540192 18.0282063,1.92284994 L17.6732464,2.90005197 C17.6022378,3.07250732 17.4602622,3.1875 17.3537701,3.1875 Z"
              id="Shape"
              fill="#289F87"
            />
            <path
              d="M19.33749,2.125 L16.78751,2.125 C16.319997,2.125 15.9375,1.64688434 15.9375,1.0625 C15.9375,0.478115662 16.319997,0 16.78751,0 L19.33749,0 C19.805003,0 20.1875,0.478115662 20.1875,1.0625 C20.1875,1.64688434 19.805003,2.125 19.33749,2.125 Z"
              id="Shape"
              fill="#DC4D41"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const BicycleIcon = withTranslation(BicycleIconComponent);

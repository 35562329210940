import React from 'react';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { ArrowIcon } from 'src/shared/design-system/icons';
import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';
import { colors } from 'src/app/styles/colors';

import { getTableHeaderColumnConfig } from './logbook-stats-table-header.util';
import {
  CaretWrapper,
  DateWrapper,
  HeaderCell,
  HeaderColumn,
  HeaderColumnInner,
  HeaderDayColumn,
  LogbookStatsTableHeaderWrapper,
  Row,
} from './logbook-stats-table-header.style';

import { LOGBOOK_LOGBOOK_MIN_WIDTH } from '../../scenes/graphs/graph.constants';
import { getCarbohydratesUnitsLabel } from 'src/domains/diagnostics/scenes/graphs/graph-statistics.util';

const columnHeaderRowSizes = [1.5, 2, null];

const LogbookStatsTableHeaderColumn = ({ colSpan, headerRows, carbLabel }) => (
  <HeaderColumn flex={colSpan}>
    {headerRows.map((subColumns, index) => (
      <Row key={index} flex={columnHeaderRowSizes[index]}>
        {subColumns.map((cell, subIndex) => (
          <LogbookStatsTableHeaderColumnCell
            key={`${index}-${subIndex}`}
            row={cell}
            index={index}
          />
        ))}
      </Row>
    ))}
  </HeaderColumn>
);

const LogbookStatsTableHeaderColumnCell = ({
  row: { colSpan, textKeys },
  index,
}) => (
  <HeaderColumnInner flex={colSpan}>
    <HeaderCell
      bold={index < 2}
      borderBottom={{
        color: index < 2 ? colors.blueMarine : 'none',
        size: index === 1 ? '0.125rem' : null,
      }}
      textAlign={index < 2 ? 'left' : 'center'}
    >
      {textKeys.map((textKey, index) => (
        <LocalizedText key={`${textKey}-${index}`} textKey={textKey} />
      ))}
    </HeaderCell>
  </HeaderColumnInner>
);

export const LogbookStatsTableHeaderComponent = ({
  bloodGlucoseUnit,
  t,
  carbUnit,
}) => {
  const carbLabel = getCarbohydratesUnitsLabel[carbUnit];
  return (
    <LogbookStatsTableHeaderWrapper
      minWidth={LOGBOOK_LOGBOOK_MIN_WIDTH}
      height={7.35}
      paddingBottom={0.2}
    >
      <Row>
        <HeaderDayColumn flex={1}>
          <Row>
            <HeaderColumn>
              <HeaderCell bold>
                <DateWrapper>
                  <span>{t('graphs.logbookStats.date')}</span>
                  <CaretWrapper>
                    <ArrowIcon fillColor={colors.blueMarine} height={8} />
                  </CaretWrapper>
                </DateWrapper>
              </HeaderCell>
            </HeaderColumn>
          </Row>
        </HeaderDayColumn>
        {getTableHeaderColumnConfig(bloodGlucoseUnit, carbLabel).map(
          ({ colSpan, headerRows }, index) => (
            <LogbookStatsTableHeaderColumn
              key={index}
              colSpan={colSpan}
              headerRows={headerRows}
            />
          ),
        )}
      </Row>
    </LogbookStatsTableHeaderWrapper>
  );
};

export const LogbookStatsTableHeader = withTranslation(
  LogbookStatsTableHeaderComponent,
);

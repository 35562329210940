import React from 'react';
import {
  ExportToEMRModal,
  FetchStatus,
} from 'src/shared/design-system/pdf-report/export-to-EMR-modal.component';
import {
  JELLO_ICON_BUTTON_SIZES,
  JELLO_ICON_BUTTON_VARIANTS,
} from '../../../../app/app.jello.constants';
import { JELLO_ICON_NAMES } from '../../../../app/setup-icons';
import { EXPORT_TO_EMR_BUTTON_TEST_ID } from '../patient.constants';

export interface ExportEMRSectionProps {
  onClickExportToEMR: () => void;
  isExportEMREnabled: { disabled: boolean } | {};
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  patientDetails: {
    patient: {
      firstName: string;
      surName: string;
      healthCareSystemId: string;
    };
    patientEndDate: string;
    patientStartDate: string;
    patientReportStatus: FetchStatus;
  };
  onRetry: () => void;
  tooltipText: string;
}

export const ExportEMRSection = ({
  onClickExportToEMR,
  isExportEMREnabled,
  isModalOpen,
  setModalOpen,
  patientDetails,
  onRetry,
  tooltipText,
}: ExportEMRSectionProps) => (
  <>
    <jello-tooltip label={tooltipText}>
      <jello-icon-button
        data-testid={EXPORT_TO_EMR_BUTTON_TEST_ID}
        size={JELLO_ICON_BUTTON_SIZES.L}
        variant={JELLO_ICON_BUTTON_VARIANTS.TERTIARY}
        icon-name={JELLO_ICON_NAMES.DOCUMENT_EXPORT}
        onClick={onClickExportToEMR}
        {...isExportEMREnabled}
      />
    </jello-tooltip>
    <ExportToEMRModal
      isOpen={isModalOpen}
      setIsOpen={setModalOpen}
      patient={patientDetails.patient}
      patientEndDate={patientDetails.patientEndDate}
      patientStartDate={patientDetails.patientStartDate}
      patientReportStatus={patientDetails.patientReportStatus}
      onRetry={onRetry}
    />
  </>
);

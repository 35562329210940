import {
  createAuthHeader,
  postJSON,
} from 'src/shared/utils/service/service.utils';
import { PatientReportsLoaderImplType } from './patient-reports.types';
import { OpenId } from '../../patient/services/patient/list-devices/list-devices.types';
import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const PatientReportsLoaderImpl: PatientReportsLoaderImplType = (
  { patientId, reportType, startDate, endDate },
  openId: OpenId,
) =>
  postJSON(
    endpointWithParams(ENDPOINTS.patientReports, {
      patientId,
      reportType,
      startDate,
      endDate,
    }),
    {
      headers: {
        Authorization: createAuthHeader(openId.accessToken),
        Accept: 'application/json',
      },
    },
  );

export const PatientReportsTransformImpl: any = (data) => data;

export const PatientReportsServiceImpl: any =
  (load, transform) => (params, accessToken) =>
    load(params, accessToken).then(transform);

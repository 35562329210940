import styled from 'styled-components';

import { Column } from 'src/shared/design-system/column/column.component';
import { Span } from 'src/shared/design-system/span/span.component';
import { spacing } from 'src/app/styles/spacing';
import { combineRems } from 'src/shared/utils/rem-calc';

export const LoginSystemErrorContentDiv = styled(Column)`
  margin: ${spacing.three} 0 ${spacing.four} 0;
  flex: 1 0 auto;
  font-size: ${({ theme }) => theme.fontSize.p};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;
LoginSystemErrorContentDiv.displayName = 'LoginSystemErrorContentDiv';

export const LoginSystemErrorSubheading = styled(Span)`
  color: ${({ theme }) => theme.colors.grayDark};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: ${spacing.four} 0 ${spacing.three};
`;
LoginSystemErrorSubheading.displayName = 'LoginSystemErrorSubheading';

export const LoginSystemErrorHighlight = styled(Span)`
  color: ${({ theme }) => theme.colors.grayDark};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: ${spacing.three} 0;
`;
LoginSystemErrorHighlight.displayName = 'LoginSystemErrorHighlight';

export const LoginSystemErrorMessage = styled(Span)`
  color: ${({ theme }) => theme.colors.grayMedium};
`;
LoginSystemErrorMessage.displayName = 'LoginSystemErrorMessage';

export const ContainerDiv = styled.div`
  max-width: 100%;
  padding: 0 ${combineRems(spacing.three, spacing.four)} ${spacing.four};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

ContainerDiv.displayName = 'ContainerDiv';

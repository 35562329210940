import React from 'react';
import { appendQualtricsScript } from 'src/app/inject-script';

const injectQualtricsUserMetadata = (
  locale: string,
  email: string,
  countryCode: string,
) => {
  window.QSI = {
    config: {
      language: locale,
      metadata: {
        user: {
          email,
          countryCode,
        },
      },
    },
  };
};

interface QualtricsDataInjectorProps {
  locale: string;
  userEmail: string;
  countryCode: string;
}

export const QualtricsDataInjectorComponent: React.FunctionComponent<
  QualtricsDataInjectorProps
> = ({ locale, userEmail, countryCode }) => {
  React.useEffect(() => {
    if (locale && userEmail && countryCode) {
      /* Injecting the user details in the QSI config. */
      injectQualtricsUserMetadata(locale, userEmail, countryCode);
      /* Append the script only when user details are injected. */
      appendQualtricsScript();
    }
  }, [locale, userEmail, countryCode]);

  return null;
};

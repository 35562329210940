import { Action, PayloadAction } from '../../../../../../app/store/app.types';
import {
  InvitePatientParams,
  InvitePatientTransformedResponseType,
} from '../../../../services/rdac/invite-patient/invite-patient.types';

export enum SendInvitationActionType {
  SEND_INVITATION_START = 'SEND_INVITATION_START',
  SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS',
  SEND_INVITATION_ERROR = 'SEND_INVITATION_ERROR',
  RESET_INVITATION = 'RESET_RDAC_INVITATION',
}

export type SendInvitationPayloadType = InvitePatientParams;

export type SendInvitationSuccessPayload = InvitePatientTransformedResponseType;

export type SendInvitationErrorPayload = InvitePatientTransformedResponseType;

export type SendInvitationStartAction = PayloadAction<
  SendInvitationActionType.SEND_INVITATION_START,
  InvitePatientParams
>;

export type SendInvitationSuccessAction = PayloadAction<
  SendInvitationActionType.SEND_INVITATION_SUCCESS,
  SendInvitationSuccessPayload
>;

export type SendInvitationErrorAction = PayloadAction<
  SendInvitationActionType.SEND_INVITATION_ERROR,
  SendInvitationErrorPayload
>;

export type ResetInviteAction =
  Action<SendInvitationActionType.RESET_INVITATION>;

export type SendInvitationActions =
  | SendInvitationStartAction
  | SendInvitationSuccessAction
  | SendInvitationErrorAction
  | ResetInviteAction;

export type SendInvitationState = {
  isSendingInvitation: boolean;
  invitationWasSuccessful: boolean;
  invitationHasError: boolean;
};

import { Action, PayloadAction } from '../../../../../../app/store/app.types';
import {
  CreatePatientWithFhirErrorResponse,
  CreatePatientWithFhirParams,
} from '../../../../services/patient/create-patient-with-fhir/create-patient-with-fhir.types';

export enum CreateFhirPatientActionType {
  CREATE_FHIR_PATIENT_START = 'CREATE_FHIR_PATIENT_START',
  CREATE_FHIR_PATIENT_SUCCESS = 'CREATE_FHIR_PATIENT_SUCCESS',
  CREATE_FHIR_PATIENT_ERROR = 'CREATE_FHIR_PATIENT_ERROR',
  CLEAR_CREATE_FHIR_PATIENT_STATE = 'CLEAR_CREATE_FHIR_PATIENT_STATE',
  CREATE_PAIRING_CODE_PATIENT_START = 'CREATE_PAIRING_CODE_PATIENT_START',
  CREATE_PAIRING_CODE_PATIENT_SUCCESS = 'CREATE_PAIRING_CODE_PATIENT_SUCCESS',
  CREATE_WITHOUT_PAIRING_CODE_PATIENT_START = 'CREATE_WITHOUT_PAIRING_CODE_PATIENT_START',
}

export type CreateFhirPatientStartPayload = {
  fhirData: CreatePatientWithFhirParams;
  isAdmin: boolean;
  createPatientCountlyEventData: {
    patient: Patient;
    dataSharing: boolean;
    isStriChecked: boolean;
    hasOpenConnectivity: boolean;
    isRPMProgramEnrolled: boolean;
    isStripChecked: boolean;
    languages: string;
    profileType: string;
  };
};
type Patient = {
  user: {
    email: string;
    phone: string;
    gender: string;
    languageId: number;
  };
  healthCareSystemId: string;
  diabetesType: string;
  therapyType: string;
  diagnosticDate: string;
  pregnant: boolean;
};

export type CreateFhirPatientWithPairingCodeSuccessPayload =
  CreateFhirPatientStartPayload & {
    patientId: string | number;
    patientProfileUrl: string;
  };

export type CreateFhirPatientErrorPayload = CreatePatientWithFhirErrorResponse;

export type CreateFhirPatientStartAction = PayloadAction<
  CreateFhirPatientActionType.CREATE_FHIR_PATIENT_START,
  CreateFhirPatientStartPayload
>;

export type CreateFhirPatientSuccessAction =
  Action<CreateFhirPatientActionType.CREATE_FHIR_PATIENT_SUCCESS>;

export type CreateFhirPatientErrorAction = PayloadAction<
  CreateFhirPatientActionType.CREATE_FHIR_PATIENT_ERROR,
  CreateFhirPatientErrorPayload
>;

export type CreatePairingCodePatientAction = PayloadAction<
  CreateFhirPatientActionType.CREATE_PAIRING_CODE_PATIENT_START,
  CreateFhirPatientStartPayload
>;

export type CreateWithoutPairingCodePatientAction = PayloadAction<
  CreateFhirPatientActionType.CREATE_WITHOUT_PAIRING_CODE_PATIENT_START,
  CreateFhirPatientStartPayload
>;

export type CreateFhirPatientWithPairingCodeSuccess = PayloadAction<
  CreateFhirPatientActionType.CREATE_PAIRING_CODE_PATIENT_SUCCESS,
  CreateFhirPatientWithPairingCodeSuccessPayload
>;

export type ClearCreateFhirPatientStateAction =
  Action<CreateFhirPatientActionType.CLEAR_CREATE_FHIR_PATIENT_STATE>;

export type CreateFhirPatientActions =
  | CreateFhirPatientStartAction
  | CreateFhirPatientSuccessAction
  | CreateFhirPatientErrorAction
  | ClearCreateFhirPatientStateAction
  | CreatePairingCodePatientAction
  | CreateWithoutPairingCodePatientAction
  | CreateFhirPatientWithPairingCodeSuccess;

export type CreateFhirPatientState = {
  isCreatingPatient: boolean;
  createPatientWasSuccessful: boolean;
  createPatientHasError: boolean;
};

import { DateTime } from 'luxon';
import {
  createAuthHeader,
  endpointTransformer,
  getJSON,
} from 'src/shared/utils/service/service.utils';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const DateRangeLoaderImpl = (
  { patientId, hasUserFhirPermission, patientFhirId }: any,
  accessToken: string,
) =>
  getJSON(
    endpointTransformer(
      endpointWithParams(ENDPOINTS.patientDateRange, { patientId }),
      ['v1', 'v2', patientId, patientFhirId, hasUserFhirPermission],
    ),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );

export const DateRangeServiceImpl: any =
  (load) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(({ model }) => ({
      patientId: query.patientId,
      firstMeasurement: DateTime.fromISO(model.firstMeasurement).toISO(),
      latestMeasurement: DateTime.fromISO(model.latestMeasurement).toISO(),
    }));

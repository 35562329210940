import React from 'react';
import { Bundle } from 'src/app/navigation/bundle';

export const EditPatientBundleJello = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { EditPatient } = await import(
        /* webpackChunkName: "editPatientJello" */ './scenes/edit-patient-jello/edit-patient.container'
      );
      return EditPatient;
    }}
    bundleDidLoad={(EditPatient) => <EditPatient {...props} />}
  />
);

export const EditPatientBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { EditPatient } = await import(
        /* webpackChunkName: "editPatient" */ './scenes/edit-patient/edit-patient.container'
      );
      return EditPatient;
    }}
    bundleDidLoad={(EditPatient) => <EditPatient {...props} />}
  />
);

export const EditPatientWithPlatformBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { EditPatientWithPlatform } = await import(
        /* webpackChunkName: "editPatientWithPlatform" */ './scenes/edit-patient/edit-patient-with-platform/edit-patient-with-platform.container'
      );
      return EditPatientWithPlatform;
    }}
    bundleDidLoad={(EditPatientWithPlatform) => (
      <EditPatientWithPlatform {...props} />
    )}
  />
);

export const DeactivatePatientBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { DeactivatePatient } = await import(
        /* webpackChunkName: "deactivatePatient" */ './scenes/deactivate-patient/deactivate-patient.container'
      );
      return DeactivatePatient;
    }}
    bundleDidLoad={(DeactivatePatient) => <DeactivatePatient {...props} />}
  />
);

export const CreatePatientBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { CreatePatient } = await import(
        /* webpackChunkName: "createPatient" */ 'src/domains/patient/scenes/create-patient-wrapper/create-patient-wrapper.container'
      );
      return CreatePatient;
    }}
    bundleDidLoad={(CreatePatient) => <CreatePatient {...props} />}
  />
);

export const TimePeriodsBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { TimePeriods } = await import(
        /* webpackChunkName: "timePeriods" */ './scenes/time-periods/time-periods.container'
      );
      return TimePeriods;
    }}
    bundleDidLoad={(TimePeriods) => <TimePeriods {...props} />}
  />
);

export const ListDevicesBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { ListDevices } = await import(
        /* webpackChunkName: "listDevices" */ './scenes/list-devices/list-devices.container'
      );
      return ListDevices;
    }}
    bundleDidLoad={(ListDevices) => <ListDevices {...props} />}
  />
);

export const GraphicSettingsBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { GraphicSettings } = await import(
        /* webpackChunkName: "graphicSettings" */ './scenes/graph-settings/graph-settings.container'
      );
      return GraphicSettings;
    }}
    bundleDidLoad={(GraphicSettings) => <GraphicSettings {...props} />}
  />
);

export const HomeDeliveryJelloBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { HomeDeliveryJello } = await import(
        /* webpackChunkName: "HomeDeliveryJello" */ './scenes/home-delivery-jello/home-delivery-jello.container'
      );
      return HomeDeliveryJello;
    }}
    bundleDidLoad={(HomeDeliveryJello) => <HomeDeliveryJello {...props} />}
  />
);

export const DeliveryConfigurationBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { DeliveryConfiguration } = await import(
        /* webpackChunkName: "deliveryConfiguration" */ './scenes/delivery-configuration/delivery-configuration.container'
      );
      return DeliveryConfiguration;
    }}
    bundleDidLoad={(DeliveryConfiguration) => (
      <DeliveryConfiguration {...props} />
    )}
  />
);

export const NextShipmentBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { NextShipment } = await import(
        /* webpackChunkName: "nextShipment" */ './scenes/next-shipment/next-shipment.container'
      );
      return NextShipment;
    }}
    bundleDidLoad={(NextShipment) => <NextShipment {...props} />}
  />
);

export const DeviceSettingsJelloBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { DeviceSettingsJelloContainer } = await import(
        /* webpackChunkName: "JelloDeviceSettings" */ 'src/domains/diagnostics/scenes/device-settings-jello/device-settings-jello.container'
      );
      return DeviceSettingsJelloContainer;
    }}
    bundleDidLoad={(DeviceSettingsJelloContainer) => (
      <DeviceSettingsJelloContainer {...props} />
    )}
  />
);

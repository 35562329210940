import 'rxjs/add/operator/switchMap';
import { CGMSummaryFactoryImpl } from 'src/domains/patient-dashboards/cgm/services/cgm/cgm-summary/cgm-summary.factory';
import { DateRangeFactoryImpl } from 'src/domains/patient-dashboards/bg/services/date-range/patient-date-range.factory';
import { PatientFactoryImpl } from 'src/domains/patient/services/patient/patient.factory';
import { selectAccessToken } from '../../../../../../app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from '../../../../../../app/session/core/config/config.selectors';
import { addAlertAction } from '../../../../../../shared/design-system/alerts/store/alert.actions';
import { AlertType } from '../../../../../../shared/design-system/alerts/store/alert.types';
import { ALERT_POSITION } from '../../../../../../shared/design-system/alerts/store/alert.constans';
import { clearPatientState } from '../../../../store/patient/patient.action';
import {
  clearCreateFhirPatientStateAction,
  createFhirPatientSuccess,
} from './create-patient.actions';
import { triggerConnectionCompletedWithPairingCodeCountlyEvent } from 'src/domains/patient/scenes/create-edit-form-jello/create-edit-countly-events/create-edit-countly-events';

export const getTokens = (store) => {
  const accessToken = selectAccessToken(store.getState());
  const gigyaToken = selectGigyaToken(store.getState());
  return { accessToken, gigyaToken };
};

export const dispatchCreatePatientWithoutPairingCodeActions = (payload) => {
  return [
    clearPatientState(),
    createFhirPatientSuccess(),
    triggerConnectionCompletedWithPairingCodeCountlyEvent(
      payload.fhirData.pairingCode,
    ),
    addAlertAction({
      type: AlertType.SUCCESS,
      text: {
        [AlertType.SUCCESS]: 'createPatientWithPlatform.alert.creationSuccess',
      },
      position: ALERT_POSITION.BOTTOM_RIGHT,
    }),
    clearCreateFhirPatientStateAction(),
  ];
};

export const getRangesFromBGAndCGM = async (patientId, accessToken, apiKey) => {
  const getFhirId = PatientFactoryImpl({ devMode: false });
  const checkCgmDate = CGMSummaryFactoryImpl({ devMode: false });
  const checkBgmDate = DateRangeFactoryImpl({ devMode: false });

  const model = await getFhirId({ patientId }, accessToken, apiKey);
  const { fhirId } = model;

  const cgmData = await checkCgmDate(
    { fhirId, hasRoleFhir: true },
    accessToken,
    apiKey,
  );

  const { lastMeasurementDate: lmCGM } = cgmData;

  const bgmData = await checkBgmDate(
    { patientId, hasUserFhirPermission: true, patientFhirId: fhirId },
    accessToken,
  );
  const { latestMeasurement: lmBGM } = bgmData;

  return { lmCGM, lmBGM };
};

export const checkLastMeasurementDateFromBGandCGM = async (
  accessToken,
  patientId,
  store,
  patientProfileUrl,
) => {
  const apiKey = selectGigyaToken(store.getState());
  const { lmCGM, lmBGM } = await getRangesFromBGAndCGM(
    patientId,
    accessToken,
    apiKey,
  );

  if (
    !!lmCGM &&
    (new Date(lmCGM) > new Date(lmBGM) ||
      new Date(lmCGM).getTime() === new Date(lmBGM).getTime())
  ) {
    return { patientProfileUrl: patientProfileUrl.replace(/\/bg\//, '/cgm/') };
  } else {
    return { patientProfileUrl };
  }
};

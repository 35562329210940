import React from 'react';
import { isEmpty } from 'ramda';
import { AutoSizer } from 'react-virtualized';
import { BloodGlucoseAxis } from 'src/domains/diagnostics/components/graph/graph-axes/blood-glucose-axis.component';
import { DayRangeAxis } from 'src/domains/diagnostics/components/graph/graph-axes/day-range-axis.component';
import { GraphPlot } from 'src/domains/diagnostics/components/graph/graph-plot/graph-plot.component';
import { ToolTip } from 'src/domains/diagnostics/components/tool-tip/tool-tip.component';
import { TrendToolTip } from 'src/domains/diagnostics/components/tool-tip/trend-tool-tip/trend-tool-tip.component';
import { GraphControls } from 'src/domains/diagnostics/components/graph-controls/graph-controls.component';
import { Graph } from 'src/domains/diagnostics/components/graph-lib/graph.component';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import { withGraphLoader } from 'src/domains/diagnostics/utils/with-graph-loader';
import { filterVerticalTicksForViewAndVisibilityTolerance } from 'src/domains/diagnostics/utils/graphs.util';
import { EMPTY_VALUE_PLACEHOLDER } from 'src/domains/diagnostics/store/constants';
import { withToolTip } from 'src/shared/utils/with-tool-tip';
import { ResizeWrapper } from 'src/domains/diagnostics/resize-wrapper/resize-wrapper.component';

import { TrendGraphPlot } from '../components/trend-graph-plot/trend-graph-plot.component';
import { TrendPlotWrapperDiv } from '../trend.style';
import {
  getToolTipValueColor,
  getYAxisTickVisibilityTolerance,
} from '../../../graph-shared/graph.util';
import {
  COLLAPSED_STD_GRAPH_HEIGHT,
  GRAPH_TYPE_TREND,
  MIN_EXPANDED_STD_GRAPH_HEIGHT,
} from '../../../graph.constants';

const TrendPlotWrapperDivWithLoader = withGraphLoader(TrendPlotWrapperDiv);

const X_AXIS_HEIGHT = 0.13;
const Y_AXIS_WIDTH = 0.05;

const PLOT_WIDTH = 1 - Y_AXIS_WIDTH * 2;
const PLOT_HEIGHT = 1 - X_AXIS_HEIGHT * 2;

export const TrendTrend = withToolTip(
  ({
    bloodGlucoseUnit,
    measurements,
    graphData,
    graphDetails,
    targetRange,
    threshold,
    verticalTicks,
    horizontalDayTicks,
    horizontalMonthYearTicks,
    showChangeGraphToggle,
    showGridLines,
    yDirection = -1,
    toolTip,
    showToolTip,
    hideToolTip,
    graphYMax,
    flexibleHeight,
    isLoading,
    graph,
    collapsed,
  }) => (
    <React.Fragment>
      <ResizeWrapper
        minHeight={
          collapsed ? COLLAPSED_STD_GRAPH_HEIGHT : MIN_EXPANDED_STD_GRAPH_HEIGHT
        }
        render={(height) => {
          const yAxisTickVisibilityTolerance = getYAxisTickVisibilityTolerance({
            bloodGlucoseUnit,
          });

          const filteredVerticalTicks =
            filterVerticalTicksForViewAndVisibilityTolerance(
              verticalTicks,
              flexibleHeight,
              threshold,
              targetRange,
              yAxisTickVisibilityTolerance,
              graphYMax,
            );

          return (
            <TrendPlotWrapperDivWithLoader
              hasError={isEmpty(measurements) && !isLoading}
              isLoading={isLoading}
            >
              <AutoSizer>
                {({ width }) => (
                  <React.Fragment>
                    <RenderIf validate={!isLoading && !isEmpty(measurements)}>
                      <GraphControls
                        showChangeGraphToggle={showChangeGraphToggle}
                        graphStatistics={graphDetails}
                        graphType={GRAPH_TYPE_TREND}
                        graph={graph}
                        width={width}
                      />
                    </RenderIf>
                    <RenderIf validate={width && height}>
                      <Graph
                        viewportRight={width}
                        viewportBottom={height}
                        height={height}
                        anchor="xMidYMid"
                      >
                        <GraphPlot
                          width={width}
                          height={height}
                          xAxisHeight={X_AXIS_HEIGHT}
                          yAxisWidth={Y_AXIS_WIDTH}
                          plotHeight={PLOT_HEIGHT}
                          plotWidth={PLOT_WIDTH}
                          mainGraph={(dimensions) => (
                            <TrendGraphPlot
                              {...dimensions}
                              yDirection={yDirection}
                              targetRange={targetRange}
                              threshold={threshold.value}
                              points={graphData}
                              horizontalDayTicks={horizontalDayTicks}
                              showGridLines={showGridLines}
                              onCandleStickMouseOver={showToolTip}
                              onCandleStickMouseOut={hideToolTip}
                              graphYMax={graphYMax}
                            />
                          )}
                          yAxisLeft={(dimensions) => (
                            <BloodGlucoseAxis
                              {...dimensions}
                              ticks={filteredVerticalTicks}
                              unit={bloodGlucoseUnit}
                            />
                          )}
                          xAxisBottom={(dimensions) => (
                            <DayRangeAxis
                              {...dimensions}
                              dayTicks={horizontalDayTicks}
                              monthYearTicks={horizontalMonthYearTicks}
                            />
                          )}
                        />
                      </Graph>
                    </RenderIf>
                  </React.Fragment>
                )}
              </AutoSizer>
            </TrendPlotWrapperDivWithLoader>
          );
        }}
      />
      <RenderIf validate={toolTip.x && toolTip.y}>
        {renderToolTip(toolTip, threshold, targetRange, bloodGlucoseUnit)}
      </RenderIf>
    </React.Fragment>
  ),
);

const renderToolTip = (
  { x, y, data },
  { data: thresholdData },
  { data: targetRangeData },
  bloodGlucoseUnit,
) => {
  const { stdDev, max, min, mean, count } = data;

  return (
    <ToolTip x={x} y={y}>
      <TrendToolTip
        bloodGlucoseUnit={bloodGlucoseUnit}
        max={max}
        min={min}
        mean={mean && mean.toFixed(1)}
        numMeasurements={count}
        stDev={
          !stdDev || stdDev === EMPTY_VALUE_PLACEHOLDER
            ? stdDev
            : stdDev.toFixed(1)
        }
        meanColor={getToolTipValueColor(
          mean,
          thresholdData.value,
          targetRangeData,
        )}
        maxColor={getToolTipValueColor(
          max,
          thresholdData.value,
          targetRangeData,
        )}
        minColor={getToolTipValueColor(
          min,
          thresholdData.value,
          targetRangeData,
        )}
      />
    </ToolTip>
  );
};

import { enrollPatientToObservationalStudyMockResponse } from './enroll-patient-to-study.mock';
import {
  EnrollPatientToStudyLoaderImpl,
  EnrollPatientToStudyServiceImpl,
  EnrollPatientToStudyTransformImp,
} from './enroll-patient-to-study.service';
import { EnrollPatientToStudyResponseType } from './enroll-patient-to-study.types';

export const mockLoaderEnrollPatientToStudy =
  (): Promise<EnrollPatientToStudyResponseType> =>
    Promise.resolve(enrollPatientToObservationalStudyMockResponse);

export const EnrollPatientToStudyFactoryImp = ({ devMode }) => {
  const loader = devMode
    ? mockLoaderEnrollPatientToStudy
    : EnrollPatientToStudyLoaderImpl;
  return EnrollPatientToStudyServiceImpl(
    loader,
    EnrollPatientToStudyTransformImp,
  );
};

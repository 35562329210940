import React from 'react';

import { CrossMarkIcon } from 'src/shared/design-system/icons';
import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';
import {
  BG_UNIT_KEYS,
  EMPTY_VALUE_PLACEHOLDER,
} from 'src/domains/diagnostics/store/constants';
import { formatBGMeasurement } from 'src/domains/diagnostics/utils/measurements';

import {
  IconContainer,
  List,
  ListElement,
  ListElementSectionHeader,
  ListsContainer,
  ListSection,
} from '../../graph-statistics.style';

export const GraphDetailBloodGlucose = ({
  bloodGlucoseMean,
  bloodGlucoseStandardDeviation,
  bloodGlucoseUnit,
  testsPerDay,
  hasSufficiencyData,
  hasData,
}) => (
  <ListSection borderRight>
    <ListElementSectionHeader>
      <IconContainer>
        <CrossMarkIcon height={10} />{' '}
      </IconContainer>
      <LocalizedText textKey="graphDetails.statistics.bloodGlucose.meanBloodGlucoseTitle" />
    </ListElementSectionHeader>
    <ListsContainer>
      <List iconPadding>
        <ListElement>
          <LocalizedText textKey="graphDetails.statistics.bloodGlucose.glucoseLevel" />
        </ListElement>
        <ListElement>
          <LocalizedText textKey="graphDetails.statistics.bloodGlucose.standardDeviation" />
        </ListElement>
        <ListElement>
          <LocalizedText textKey="graphDetails.statistics.bloodGlucose.testsPerDay" />
        </ListElement>
      </List>
      <List>
        <ListElement bold>
          {hasData ? (
            hasSufficiencyData ? (
              <React.Fragment>
                {formatBGMeasurement(bloodGlucoseUnit)(
                  bloodGlucoseMean || EMPTY_VALUE_PLACEHOLDER,
                )}{' '}
                <LocalizedText textKey={BG_UNIT_KEYS[bloodGlucoseUnit]} />
              </React.Fragment>
            ) : (
              <LocalizedText textKey="graphDetails.statistics.bloodGlucose.notEnoughData" />
            )
          ) : (
            <LocalizedText textKey="graphDetails.statistics.bloodGlucose.noDataAvailable" />
          )}
        </ListElement>
        <ListElement bold>
          {hasData ? (
            hasSufficiencyData ? (
              <React.Fragment>
                {formatBGMeasurement(bloodGlucoseUnit)(
                  bloodGlucoseStandardDeviation,
                )}{' '}
                <LocalizedText textKey={BG_UNIT_KEYS[bloodGlucoseUnit]} />
              </React.Fragment>
            ) : (
              <LocalizedText textKey="graphDetails.statistics.bloodGlucose.notEnoughData" />
            )
          ) : (
            <LocalizedText textKey="graphDetails.statistics.bloodGlucose.noDataAvailable" />
          )}
        </ListElement>
        <ListElement bold>{testsPerDay}</ListElement>
      </List>
    </ListsContainer>
  </ListSection>
);

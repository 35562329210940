import { compose } from 'recompose';
import { connect } from 'react-redux';
import { TabBasalBolusComponent } from './tab-basal-bolus.component';
import { deviceSettingsBasalBolusConnector } from './tab-basal-bolus.selector';
import { withTranslation } from 'src/shared/utils/i18n/with-translation';

export const TabBasalBolusContainer = compose<any, any>(
  withTranslation,
  connect(deviceSettingsBasalBolusConnector, null),
)(TabBasalBolusComponent);

import React from 'react';
import { withTheme } from 'styled-components';

import { HorizontalAxis } from 'src/domains/diagnostics/components/graph-lib/graph-horizontal-axis.component';

const getTickTextAnchor = ({ isTickAtEndOfXAxis }) =>
  isTickAtEndOfXAxis ? 'end' : 'middle';

const TICK_LINE_LENGTH_MODIFIER = 0.2;
const TEXT_POSITION_MODIFIER = 0.4;

const HoursAxisComponent = ({
  x = 0,
  width,
  height,
  timeHorizontalTicks,
  theme,
}) => (
  <HorizontalAxis
    x={x}
    y={height}
    width={width}
    height={height}
    Tick={(tick, index) => (
      <React.Fragment key={`${tick.value}-${index}`}>
        <line
          x1={tick.value}
          y1={0}
          x2={tick.value}
          y2={height * TICK_LINE_LENGTH_MODIFIER}
          strokeWidth={theme.strokeWidth.one}
          stroke={theme.colors.grayLight}
        />
        <text
          textAnchor={getTickTextAnchor(tick)}
          x={tick.value}
          y={height * TEXT_POSITION_MODIFIER}
          fontSize={theme.fontSize.label}
        >
          {tick.label}
        </text>
      </React.Fragment>
    )}
    ticks={timeHorizontalTicks}
    axisColor={theme.colors.grayLight}
  />
);

export const HoursAxis = withTheme(HoursAxisComponent);

import styled from 'styled-components';
import { color, fontSize, space, width } from 'styled-system';

export const TableHeader = styled.thead`
  ${width};
  ${color};
  ${space};
  ${fontSize};
`;

export const FixedTableHeader = styled(TableHeader)`
  display: block;
  overflow: hidden;
`;

export const FixedTableHeaderWithSpacing = styled(FixedTableHeader)`
  display: block;
  overflow: hidden;
  margin-left: ${(props) => props.marginLeft};
  margin-top: ${(props) => props.marginTop};
`;

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';

import { LogbookDiary } from './logbook-diary.component';
import { logbookDiaryConnector } from './logbook-diary.selector';

const dispatchers = mapDispatchers({});

export const LogbookDiaryContainer = compose(
  withRouter,
  connect(logbookDiaryConnector, dispatchers),
  withTranslation,
)(LogbookDiary);

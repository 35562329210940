import { isNil } from 'ramda';
import { createSelector } from 'reselect';

import { EMPTY_VALUE_PLACEHOLDER } from 'src/domains/diagnostics/store/constants';
import {
  selectBloodGlucoseUnit,
  selectFormattedGlobalDatesAverageTestsPerDay,
  selectGraphDetailTargetRanges,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import {
  selectBGIndexes,
  selectHighestBG,
  selectLowestBG,
  selectMeanBG,
  selectMeanBGBeforeBreakfast,
  selectMeanBGBeforeDinner,
  selectMeanBGBeforeLunch,
  selectMeanBGBeforeMeal,
  selectNumberOfTests,
  selectStandardDeviation,
  selectTestsPerMeasuredDay,
} from 'src/domains/diagnostics/store/selectors/statistics.selector';
import { roundBGMeasurement } from 'src/domains/diagnostics/utils/measurements';

import {
  mealsZeroCheck,
  zeroCheck,
} from './blood-glucose-general-stats.util.js';

import { selectHasSufficiencyToStatisticsCalculation } from '../../status-card/status-card.selector.js';

export const selectTests = createSelector(
  selectNumberOfTests,
  selectFormattedGlobalDatesAverageTestsPerDay,
  selectTestsPerMeasuredDay,
  (numberOfTests, testsPerDay, testsPerMeasuredDay) => ({
    numberOfTests,
    testsPerDay: isNil(testsPerDay)
      ? EMPTY_VALUE_PLACEHOLDER
      : testsPerDay < 0.1
      ? 0
      : testsPerDay,
    testsPerMeasuredDay:
      testsPerMeasuredDay === '0.0' || isNaN(testsPerMeasuredDay)
        ? EMPTY_VALUE_PLACEHOLDER
        : testsPerMeasuredDay,
  }),
);

export const selectBGStats = createSelector(
  selectMeanBG,
  selectStandardDeviation,
  selectLowestBG,
  selectHighestBG,
  selectBloodGlucoseUnit,
  selectHasSufficiencyToStatisticsCalculation,
  (mean, stdDev, lowestBG, highestBG, bgUnit, hasSDSufficiency) => ({
    mean: zeroCheck(mean),
    stdDev: roundBGMeasurement(bgUnit)(stdDev),
    lowestBG: lowestBG === Infinity ? EMPTY_VALUE_PLACEHOLDER : lowestBG,
    highestBG: highestBG === -Infinity ? EMPTY_VALUE_PLACEHOLDER : highestBG,
    hasSDSufficiency,
  }),
);

export const selectMeanBGBeforeMeals = createSelector(
  selectMeanBGBeforeMeal,
  selectMeanBGBeforeBreakfast,
  selectMeanBGBeforeLunch,
  selectMeanBGBeforeDinner,
  (
    meanBeforeMeals,
    meanBeforeBreakfast,
    meanBeforeLunch,
    meanBeforeDinner,
  ) => ({
    meanBeforeMeals: mealsZeroCheck(meanBeforeMeals),
    meanBeforeBreakfast: mealsZeroCheck(meanBeforeBreakfast),
    meanBeforeLunch: mealsZeroCheck(meanBeforeLunch),
    meanBeforeDinner: mealsZeroCheck(meanBeforeDinner),
  }),
);

export const selectAllBGStats = createSelector(
  selectTests,
  selectBGStats,
  selectGraphDetailTargetRanges,
  selectBGIndexes,
  selectMeanBGBeforeMeals,
  (
    tests,
    meanBG,
    {
      targetBloodGlucoseMinimum,
      targetBloodGlucoseMaximum,
      abovePercentage,
      aboveCount,
      belowAndHypoCount,
      belowAndHypoPercentage,
      withinPercentage,
      withinCount,
      hypoglycemiaThreshold,
      hypoglycaemiaNumber,
    },
    indexes,
    meanBeforeMeals,
  ) => ({
    tests,
    meanBG,
    targetRange: {
      targetBloodGlucoseMinimum,
      targetBloodGlucoseMaximum,
      abovePercentage,
      aboveCount,
      belowAndHypoCount,
      belowAndHypoPercentage,
      withinPercentage,
      withinCount,
    },
    hypos: {
      hypoglycemiaThreshold,
      hypoglycaemiaNumber,
    },
    indexes: {
      lbgi: indexes.lbgi,
      hbgi: indexes.hbgi,
    },
    meanBeforeMeals,
  }),
);

import { SUCCESS_CODE } from '../../../clinical-studies.constants';
import {
  GetObservationalStatusActionType,
  GetObservationalStatusActions,
  GetObservationalStatusState,
} from './get-observational-studies-status.types';

export const getObservationalStudiesStatusInitialState: GetObservationalStatusState =
  {
    isGettingObservationalStatus: false,
    getObservationalStatusSuccessful: false,
    getObservationalStatusError: false,
    status: 0,
    studyId: '',
    subjectId: '',
  };

export const getObservationalStudiesStatusReducer = (
  state = getObservationalStudiesStatusInitialState,
  action: GetObservationalStatusActions,
): GetObservationalStatusState => {
  switch (action.type) {
    case GetObservationalStatusActionType.GET_OBSERVATIONAL_STATUS_START:
      return {
        ...state,
        isGettingObservationalStatus: true,
        getObservationalStatusSuccessful: false,
        getObservationalStatusError: false,
      };
    case GetObservationalStatusActionType.GET_OBSERVATIONAL_STATUS_SUCCESS:
      return {
        isGettingObservationalStatus: false,
        getObservationalStatusSuccessful: true,
        getObservationalStatusError: false,
        status: SUCCESS_CODE,
        studyId: action.payload?.studyId,
        subjectId: action.payload?.subjectId,
      };
    case GetObservationalStatusActionType.GET_OBSERVATIONAL_STATUS_ERROR:
      return {
        isGettingObservationalStatus: false,
        getObservationalStatusSuccessful: false,
        getObservationalStatusError: true,
        status: action.payload?.status,
        studyId: '',
        subjectId: '',
      };
    case GetObservationalStatusActionType.CLEAR_OBSERVATIONAL_STATUS:
      return getObservationalStudiesStatusInitialState;

    default:
      return state;
  }
};

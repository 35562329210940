import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';

import { State } from 'src/app/store/app.types';
import { GetTimeBlocksServiceFnType } from 'src/domains/patient/services/patient/time-blocks/get-time-blocks/time-blocks.types';
import {
  FetchPatientTimeBlocksStartAction,
  PatientTimeBlocksActions,
  PatientTimeBlocksActionType,
} from './time-periods.types';
import {
  fetchPatientTimeBlocksError,
  fetchPatientTimeBlocksSuccess,
  patientTimeBlocksStatusReset,
  savePatientTimeBlocksError,
  savePatientTimeBlocksSuccess,
} from './time-periods.actions';
import { TimeInterval } from '../time-periods.types';

import { createModal } from 'src/shared/design-system/modal/store/modal/modal.actions';
import { MODAL_TYPES } from 'src/shared/design-system/modal/store/modal/modal.constants';

import { TimePeriodsErrorModal } from 'src/domains/patient/scenes/time-periods/components/time-periods-error-modal/time-periods-error.modal';

import { TimePeriodsSuccessModal } from 'src/domains/patient/scenes/time-periods/components/time-periods-success-modal/time-periods-success.modal';

export const fetchPatientTimeBlocksEpic: (
  service: GetTimeBlocksServiceFnType,
) => Epic<PatientTimeBlocksActions, State> = (service) => (action$, store) =>
  action$
    .ofType(PatientTimeBlocksActionType.FETCH_PATIENT_TIMEBLOCKS_START)
    .switchMap(({ payload }: FetchPatientTimeBlocksStartAction) => {
      const accessToken = selectAccessToken(store.getState());
      const apiKey = selectGigyaToken(store.getState());
      return Observable.fromPromise(service(accessToken, apiKey, payload))
        .map((periods) => fetchPatientTimeBlocksSuccess(periods))
        .pipe(catchError(() => Observable.of(fetchPatientTimeBlocksError())));
    });

export const savePatientTimeBlocksEpic: (
  service: FixMe,
) => Epic<FixMe, State> = (service) => {
  return (action$, store) => {
    return action$
      .ofType(PatientTimeBlocksActionType.SAVE_PATIENT_TIMEBLOCKS_START)
      .switchMap(({ payload }: FixMe) => {
        const accessToken = selectAccessToken(store.getState());
        const apiKey = selectGigyaToken(store.getState());
        return Observable.fromPromise(
          service(accessToken, apiKey, payload.patientId, payload.blocks),
        )
          .mergeMap((periods) => [
            savePatientTimeBlocksSuccess(periods as TimeInterval[]),
            createModal({
              key: MODAL_TYPES.CUSTOM,
              data: {
                customComponent: TimePeriodsSuccessModal,
              },
            }),
            patientTimeBlocksStatusReset(),
          ])
          .pipe(
            // tslint:disable
            catchError(() => {
              return Observable.concat(
                Observable.of(savePatientTimeBlocksError()),
                Observable.of(
                  createModal({
                    key: MODAL_TYPES.CUSTOM,
                    data: {
                      customComponent: TimePeriodsErrorModal,
                    },
                  }),
                ),
              );
            }),
          );
      });
  };
};

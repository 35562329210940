const isoSymbolsPrefix = 'manufacturerInfo.isoSymbols.';
const generalIconsPrefix = 'manufacturerInfo.generalIcons.';
const dataIconsPrefix = 'manufacturerInfo.dataIcons.';

export const ISO_SYMBOLS = {
  CAUTION_ICON_TEXT_KEY: `${isoSymbolsPrefix}caution`,
  DATE_MANUFACTURE_ICON_TEXT_KEY: 'manufacturerInfo.manufactureDate',
  MEDICAL_DEVICE_ICON_TEXT_KEY: 'manufacturerInfo.explanationSymbols.medDevice',
  AUTH_REP_ICON_TEXT_KEY: `${isoSymbolsPrefix}authRepresentative`,
  UNIQUE_DEVICE_ID_ICON_TEXT_KEY: `${isoSymbolsPrefix}uniqueDeviceId`,
  CE_INFO_ICON_TEXT_KEY: `${isoSymbolsPrefix}ceInfo`,
  MANUFACTURER_ICON_TEXT_KEY: 'manufacturerInfo.manufacturer',
  CONSULT_ELECTRONIC_ICON_TEXT_KEY: 'manufacturerInfo.consultElectronic',
};

export const GENERAL_ICONS = {
  HOME_ICON_TEXT_KEY: `${generalIconsPrefix}home`,
  PATTERNS_ICON_TEXT_KEY: `${generalIconsPrefix}patterns`,
  CREATE_PATIENT_ICON_TEXT_KEY: `${generalIconsPrefix}createNewPatient`,
  EDIT_PATIENT_ICON_TEXT_KEY: `${generalIconsPrefix}editPatient`,
  SIGN_OUT_ICON_TEXT_KEY: `${generalIconsPrefix}signOut`,
  TIME_TRACKER_ICON_TEXT_KEY: `${generalIconsPrefix}timeTracker`,
  GENERAL_FUNCTIONS_ICON_TEXT_KEY: `${generalIconsPrefix}generalFunctions`,
  STRIP_MANAGEMENT_ICON_TEXT_KEY: `${generalIconsPrefix}stripManagement`,
  ADD_HCP_ICON_TEXT_KEY: `${generalIconsPrefix}addHcpProfile`,
  WARNING_ICON_TEXT_KEY: `${generalIconsPrefix}disclaimer`,
  DEVICE_LINK_ICON_TEXT_KEY: `${generalIconsPrefix}deviceLink`,
  GLUCOSE_DEVICES_ICON_TEXT_KEY: `${generalIconsPrefix}devices`,
  HCP_PROFILE_ICON_TEXT_KEY: `${generalIconsPrefix}hcpProfile`,
  EXPAND_ICON_TEXT_KEY: `${generalIconsPrefix}expand`,
  HELP_ICON_TEXT_KEY: `${generalIconsPrefix}help`,
  PRINT_ICON_TEXT_KEY: `${generalIconsPrefix}printAndDownload`,
  STOCK_MANAGEMENT_ICON_TEXT_KEY: `${generalIconsPrefix}stock`,
  COLLAPSE_ICON_TEXT_KEY: `${generalIconsPrefix}collapse`,
  ADDITIONAL_INFO_ICON_TEXT_KEY: `${generalIconsPrefix}additionalInformation`,
  REPORT_ICON_TEXT_KEY: `${generalIconsPrefix}report`,
  FILTERS_ICON_TEXT_KEY: `${generalIconsPrefix}filters`,
  CLOSE_ICON_TEXT_KEY: `${generalIconsPrefix}close`,
  NOTIFICATION_ICON_TEXT_KEY: `${generalIconsPrefix}notificationCenter`,
  NOTES_ICON_TEXT_KEY: `${generalIconsPrefix}notesAndReports`,
  RPM_SETTINGS_ICON_TEXT_KEY: `${generalIconsPrefix}RPMSettings`,
  BACK_ICON_TEXT_KEY: `${generalIconsPrefix}back`,
  SEARCH_ICON_TEXT_KEY: `${generalIconsPrefix}search`,
  VIDEO_CONSULTATION_ICON_TEXT_KEY: `${generalIconsPrefix}videoConsultation`,
  GETTING_STARTED_ICON_TEXT_KEY: `${generalIconsPrefix}gettingStarted`,
  SHOW_ICON_TEXT_KEY: `${generalIconsPrefix}show`,
  BGM_ICON_TEXT_KEY: `${generalIconsPrefix}bgm`,
  CALENDAR_ICON_TEXT_KEY: `${generalIconsPrefix}calendarPicker`,
  PRODUCT_INFO_ICON_TEXT_KEY: `${generalIconsPrefix}productInformation`,
  HIDE_ICON_TEXT_KEY: `${generalIconsPrefix}hide`,
  CGM_ICON_TEXT_KEY: `${generalIconsPrefix}cgm`,
  SETTINGS_ICON_TEXT_KEY: `${generalIconsPrefix}settings`,
  INSTRUCTIONS_USE_ICON_TEXT_KEY: `${generalIconsPrefix}instructionsForUse`,
  EXPAND_SIDEBAR_ICON_TEXT_KEY: `${generalIconsPrefix}expandSidebar`,
  PUMP_ICON_TEXT_KEY: `${generalIconsPrefix}pump`,
  WHATS_NEW_ICON_TEXT_KEY: `${generalIconsPrefix}whatsNew`,
  LAUNCH_ICON_TEXT_KEY: `${generalIconsPrefix}openInBrowser`,
  COLLAPSE_SIDEBAR_ICON_TEXT_KEY: `${generalIconsPrefix}collapseSidebar`,
  PASSWORD_ICON_TEXT_KEY: `${generalIconsPrefix}password`,
};

export const DATA_ICONS = {
  NO_DATA_ICON_TEXT_KEY: `${dataIconsPrefix}noData`,
  LOW_ICON_TEXT_KEY: `${dataIconsPrefix}low`,
  ACTIVE_BASAL_RATE_ICON_TEXT_KEY: `${dataIconsPrefix}activeBasalProfile`,
  DAY_ICON_TEXT_KEY: `${dataIconsPrefix}day`,
  NOT_ENOUGH_DATA_ICON_TEXT_KEY: `${dataIconsPrefix}noDataAvailable`,
  STANDARD_BOLUS_ICON_TEXT_KEY: `${dataIconsPrefix}standardBolus`,
  BASAL_RATE_ICON_TEXT_KEY: `${dataIconsPrefix}tempBasalRate`,
  NIGHT_ICON_TEXT_KEY: `${dataIconsPrefix}night`,
  BEFORE_MEAL_ICON_TEXT_KEY: `${dataIconsPrefix}beforeMeal`,
  QUICK_BOLUS_ICON_TEXT_KEY: `${dataIconsPrefix}quickBolus`,
  CARTRIDGE_CHANGE_ICON_TEXT_KEY: `${dataIconsPrefix}cartridgeChange`,
  BEDTIME_ICON_TEXT_KEY: `${dataIconsPrefix}bedtime`,
  AFTER_MEAL_ICON_TEXT_KEY: `${dataIconsPrefix}afterMeal`,
  EXTENDED_BOLUS_ICON_TEXT_KEY: `${dataIconsPrefix}extendedBolus`,
  PRIME_INFUSION_SET_ICON_TEXT_KEY: `${dataIconsPrefix}primeInfusionSet`,
  EDIT_ICON_TEXT_KEY: `${dataIconsPrefix}manualEntry`,
  FASTING_ICON_TEXT_KEY: `${dataIconsPrefix}fasting`,
  MULTIWAVE_BOLUS_ICON_TEXT_KEY: `${dataIconsPrefix}multiWaveBolus`,
  TWENTY_FOUR_LOGBOOK_ICON_TEXT_KEY: `${dataIconsPrefix}24HRLogbook`,
  START_SHARING_ICON_TEXT_KEY: `${dataIconsPrefix}startSharing`,
  VERY_LOW_ICON_TEXT_KEY: `${dataIconsPrefix}veryLow`,
  STOP_BASAL_RATE_ICON_TEXT_KEY: `${dataIconsPrefix}stop`,
  LOGBOOK_ICON_TEXT_KEY: `${dataIconsPrefix}logbook`,
  SCATTER_PLOT_VIEW_ICON_TEXT_KEY: `${dataIconsPrefix}scatterPlotView`,
  VERY_HIGH_ICON_TEXT_KEY: `${dataIconsPrefix}veryHigh`,
  PAUSE_BASAL_RATE_ICON_TEXT_KEY: `${dataIconsPrefix}pause`,
  DIARY_ICON_TEXT_KEY: `${dataIconsPrefix}diary`,
  BOX_PLOT_VIEW_ICON_TEXT_KEY: `${dataIconsPrefix}boxPlotView`,
  HIGH_ICON_TEXT_KEY: `${dataIconsPrefix}high`,
  RUN_BASAL_RATE_ICON_TEXT_KEY: `${dataIconsPrefix}run`,
  STATISTICS_ICON_TEXT_KEY: `${dataIconsPrefix}statistics`,
};

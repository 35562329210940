import { addDomainNamespace } from 'src/domains/strip-management/domain-namespace';

import { createRequestActionTypes } from '../../../../../app/store/request/request.actions';

const DOMAIN_NAMESPACE = 'CLINICAL_GUIDANCE';
const addNamespacing = (actionName) =>
  addDomainNamespace(actionName, DOMAIN_NAMESPACE);

export const GET_CLINIC_GUIDES = addNamespacing('GET_CLINIC_GUIDES');
export const GET_CLINIC_GUIDES_REQUEST =
  createRequestActionTypes(GET_CLINIC_GUIDES);

export const SAVE_CLINIC_GUIDE = addNamespacing('SAVE_CLINIC_GUIDE');
export const SAVE_CLINIC_GUIDE_REQUEST =
  createRequestActionTypes(SAVE_CLINIC_GUIDE);

export const DELETE_CLINIC_GUIDE = addNamespacing('DELETE_CLINIC_GUIDE');
export const DELETE_CLINIC_GUIDE_REQUEST =
  createRequestActionTypes(DELETE_CLINIC_GUIDE);

export const CLINIC_GUIDE_TYPES = {
  CUSTOM: 'CUSTOM',
  PRESET: 'PRESET',
};

import {
  Action,
  PayloadAction,
} from '../../../../../../../app/store/app.types';

export enum EnrollPatientToStudyActionType {
  ENROLL_PATIENT_TO_STUDY_START = 'ENROLL_PATIENT_TO_STUDY_START',
  ENROLL_PATIENT_TO_STUDY_SUCCESS = 'ENROLL_PATIENT_TO_STUDY_SUCCESS',
  ENROLL_PATIENT_TO_STUDY_ERROR = 'ENROLL_PATIENT_TO_STUDY_ERROR',
  CLEAR_ENROLL_PATIENT_TO_STUDY = 'CLEAR_ENROLL_PATIENT_TO_STUDY',
}

export type EnrollPatientToStudyStartPayload = {
  fhirId: string;
  studyId: string;
  siteId: string;
  participantId: string;
  status: string;
};

export type EnrollPatientToStudyStartAction = PayloadAction<
  EnrollPatientToStudyActionType.ENROLL_PATIENT_TO_STUDY_START,
  EnrollPatientToStudyStartPayload
>;

export type EnrollPatientToStudySuccessAction =
  Action<EnrollPatientToStudyActionType.ENROLL_PATIENT_TO_STUDY_SUCCESS>;

export type EnrollPatientToStudyErrorAction =
  Action<EnrollPatientToStudyActionType.ENROLL_PATIENT_TO_STUDY_ERROR>;

export type ClearEnrollPatientToStudyAction =
  Action<EnrollPatientToStudyActionType.CLEAR_ENROLL_PATIENT_TO_STUDY>;

export type EnrollPatientToStudyActions =
  | EnrollPatientToStudyStartAction
  | EnrollPatientToStudySuccessAction
  | EnrollPatientToStudyErrorAction
  | ClearEnrollPatientToStudyAction;

export type EnrollPatientToStudyState = {
  isEnrollingPatientToStudy: boolean;
  enrollPatientToStudySuccessful: boolean;
  enrollPatientToStudyError: boolean;
};

/* eslint-disable no-unused-vars */
import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';

import { CheckExternalPatientParams } from './check-external-patient.types';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const CheckExternalPatientLoaderImpl = (
  { externalPatientId }: CheckExternalPatientParams,
  accessToken: string,
  gigyaToken: string,
) =>
  getJSON(
    endpointWithParams(ENDPOINTS.checkExternalPatient, { externalPatientId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );

export const CheckExternalPatientTransformImpl = (data) => data;
export const CheckExternalPatientServiceImpl =
  (load, transform) => (query, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);

import {
  PrescriptionReasonsLoaderImpl,
  PrescriptionReasonsServiceImpl,
  PrescriptionReasonsTransform,
} from './prescription-reasons.service';
import { PrescriptionReasonsMockData } from './prescription-reasons.mock';

const mockLoader = (q) => Promise.resolve(PrescriptionReasonsMockData);

export const PrescriptionReasonsFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : PrescriptionReasonsLoaderImpl;
  return PrescriptionReasonsServiceImpl(loader, PrescriptionReasonsTransform);
};

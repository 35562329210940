import * as React from 'react';
import { GenerateIconsSection } from '../icon-components.component';

import { getJelloIcon } from '../../icons-view-templates';

import { JELLO_ICON_NAMES } from 'src/app/app.jello.constants';
import { DATA_ICONS } from '../../../../manufacturer-info-modal.icons.keys';

const sectionTitle = 'manufacturerInfo.dataIcons.dataIconsTitle';

const iconListItems = [
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.NO_DATA),
    label: DATA_ICONS.NO_DATA_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.ARROW_DOWN),
    label: DATA_ICONS.LOW_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.ARROW_UP),
    label: DATA_ICONS.HIGH_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.DEPRECATED_SUNNY),
    label: DATA_ICONS.DAY_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.WARNING),
    label: DATA_ICONS.NOT_ENOUGH_DATA_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.STANDARD_BOLUS),
    label: DATA_ICONS.STANDARD_BOLUS_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.BASAL_TEMP),
    label: DATA_ICONS.BASAL_RATE_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.NIGHT),
    label: DATA_ICONS.NIGHT_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.BEFORE_MEAL),
    label: DATA_ICONS.BEFORE_MEAL_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.QUICK_BOLUS),
    label: DATA_ICONS.QUICK_BOLUS_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.CARTRIDGE_CHANGE),
    label: DATA_ICONS.CARTRIDGE_CHANGE_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.BEDTIME),
    label: DATA_ICONS.BEDTIME_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.AFTER_MEAL),
    label: DATA_ICONS.AFTER_MEAL_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.EXTENDED_BOLUS),
    label: DATA_ICONS.EXTENDED_BOLUS_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.PRIME_INFUSION_SET),
    label: DATA_ICONS.PRIME_INFUSION_SET_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.EDIT),
    label: DATA_ICONS.EDIT_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.FASTING),
    label: DATA_ICONS.FASTING_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.MULTIWAVE_BOLUS),
    label: DATA_ICONS.MULTIWAVE_BOLUS_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.LOGBOOK),
    label: DATA_ICONS.TWENTY_FOUR_LOGBOOK_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.LINKED),
    label: DATA_ICONS.START_SHARING_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.STOP_OUTLINE),
    label: DATA_ICONS.STOP_BASAL_RATE_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.PERCENTAGE),
    label: DATA_ICONS.STATISTICS_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.EVENT),
    label: DATA_ICONS.LOGBOOK_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.LIST_BULLET),
    label: DATA_ICONS.DIARY_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.PAUSE_OUTLINE),
    label: DATA_ICONS.PAUSE_BASAL_RATE_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.USER),
    label: DATA_ICONS.ACTIVE_BASAL_RATE_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.DEPRECATED_CHART_SCATTER),
    label: DATA_ICONS.SCATTER_PLOT_VIEW_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.CHART_BOXPLOT),
    label: DATA_ICONS.BOX_PLOT_VIEW_ICON_TEXT_KEY,
  },
  {
    icon: getJelloIcon(JELLO_ICON_NAMES.PLAY_OUTLINE),
    label: DATA_ICONS.RUN_BASAL_RATE_ICON_TEXT_KEY,
  },
];

export const DataIconsComponent = () => (
  <GenerateIconsSection
    iconListItems={iconListItems}
    sectionTitle={sectionTitle}
  />
);

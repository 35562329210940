import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';
import { equals, reject } from 'ramda';

import {
  GetUserUnitsDataTransformImplType,
  GetUserUnitsLoaderImplType,
  GetUserUnitsServiceImplType,
} from './get-user-unit.types';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';

export const GetUserUnitsLoaderImpl: GetUserUnitsLoaderImplType = (openId) => {
  const headers = {
    Authorization: createAuthHeader(openId.accessToken),
    ApiKey: openId.apiKey,
  };
  return getJSON(ENDPOINTS.getUserUnits, {
    headers: reject(equals({}))(headers),
  });
};

export const GetUserUnitsTransformImpl: GetUserUnitsDataTransformImplType = (
  results,
) =>
  results.model.map((el) => ({
    name: el.name,
    unitMeasurementModels: el.unitMeasurementModels.map((element) => ({
      text: element.text,
      unit: element.unit,
      family: element.family,
    })),
  }));

export const GetUserUnitsServiceImpl: GetUserUnitsServiceImplType =
  (
    load: GetUserUnitsLoaderImplType,
    transform: GetUserUnitsDataTransformImplType,
  ) =>
  (token) =>
    load(token).then(transform);

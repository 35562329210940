import {
  createCustomLogs,
  filterSensitiveData,
  getConfig,
} from '@roche/roche-common';
import { LOGS, SESSION_ID } from './constants';

import { selectSession } from 'src/app/session/core/session/session.selectors';
import {
  selectIsOidcSessionValid,
  selectNotExpired,
} from 'src/app/session/core/oidc/oidc.selectors';
import {
  selectConfig,
  selectUID,
} from 'src/app/session/core/config/config.selectors';
import { store } from 'src/app/app';
import { State } from 'src/app/store/app.types';

import sha256 from 'crypto-js/sha256';
import moment from 'moment';
import { FORMAT } from 'src/app/session/constants';

/* istanbul ignore next */ export const createSessionId = (code) => {
  const max = 12;
  localStorage.setItem(SESSION_ID, `${sha256(code)}`.substring(0, max));
};

/* istanbul ignore next */ const getInfoData = (_obj, _store) => {
  return {
    time: moment().format(FORMAT),
    session_id: localStorage.getItem(SESSION_ID),
    action: _obj,
    selectGigyaUid: selectUID(_store),
    selectSession: selectSession(_store),
    selectConfig: selectConfig(_store),
    selectNotExpired: selectNotExpired(_store),
    selectIsOidcSessionValid: selectIsOidcSessionValid(_store),
  };
};

/* istanbul ignore next */ export type DatadogType =
  | 'session'
  | 'errors'
  | 'others'
  | 'pass';

/* istanbul ignore next */ export const customDatadogLog = (
  // @ts-ignore
  type: DatadogType,
  _log: string,
  obj?: object,
) => {
  const isDevus = getConfig().REACT_APP_DATA_DOG_ENV === 'devus';
  const isTestus = getConfig().REACT_APP_DATA_DOG_ENV === 'testus';
  const label = LOGS[type] + _log;
  const info = { ...getInfoData({ ...obj }, store?.getState() as State) };

  if (isDevus || isTestus) {
    const { log } = console;
    log(label, filterSensitiveData(info, false));
  }

  createCustomLogs(label, 'Info', filterSensitiveData(info));
};

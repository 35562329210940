import React, { useEffect } from 'react';
import { RenderIf } from 'src/shared/utils/render-if';
import { NavLink } from 'react-router-dom';

import styles from './styles.module.css';
import {
  JELLO_BUTTON_VARIANTS,
  JELLO_ICON_BUTTON_SIZES,
  JELLO_ICON_NAMES,
  JELLO_POPOVER_ALIGNMENTS,
  JELLO_TOOLTIP_POSITIONS,
} from 'src/app/app.jello.constants';
import { JelloIconButton } from 'src/shared/design-system/jello-icon-button/jello-icon-button.component';
import { JelloPopoverComponent } from 'src/app/navigation/scenes/jello-popover/jello-popover.component';

import {
  MORE_OPTIONS,
  ONE_COLUMN,
  WAFFLE_ELEMENT_LIST,
  WAFFLE_MENU_ID,
  WAFFLE_MENU_LABEL,
  WAFFLE_MENU_MODAL_BASE_ID,
} from 'src/widgets/top-navigation-jello/top-navigation-jello.constants';
import { t } from 'i18next';
import { testId } from '@roche/roche-common';
import { WaffleMenuItemComponent } from '../waffle-menu-item/waffle-menu-item.component';
import { WaffleMenuProps } from '../../top-navigation-jello.types';
import { useGetDynamicStyles } from 'src/widgets/top-navigation-jello/components/waffle-menu/hooks/waffle-custom-hook';
import { ExportExcelComponent } from 'src/widgets/top-navigation-jello/components/excel-export/excel-export.component';
import { countlyEventTrigger } from 'src/app/navigation/countly';
import {
  CREATE_PATIENT_EVENTS_KEYS,
  CREATE_PATIENT_SEGMENT_KEYS,
} from 'src/domains/patient/scenes/create-edit-form-jello/create-edit-form-jello.constants';

export const WaffleMenuComponent: React.FunctionComponent<WaffleMenuProps> = (
  props,
) => {
  const {
    showTimeTrackerExportModal,
    exportPatientsSessionStart,
    locale,
    waffleElementList,
    permissions,
    routes,
    isPatientExportData,
    patientExportData,
    is12HourFormat,
    clearPatientExportData,
    destroyModal,
  } = props;

  useEffect(() => {
    if (isPatientExportData) {
      clearPatientExportData();
      destroyModal();
    }
  }, [isPatientExportData]);

  const { getColumnsStyle, getContentColumnsStyle, numColumns, newList } =
    useGetDynamicStyles(permissions, waffleElementList);

  const renderListElement = (list) => {
    return list.map((item) => (
      <li
        key={item.key}
        className={`${styles.liElement} ${styles[getColumnsStyle]}`}
      >
        {item.onClickModal ? (
          <div
            onClick={() =>
              showTimeTrackerExportModal(locale, t, exportPatientsSessionStart)
            }
            {...testId(item.modalKey, WAFFLE_MENU_MODAL_BASE_ID)}
          >
            <WaffleMenuItemComponent
              id={item.key}
              iconName={item.iconName}
              linkText={t(item.linkText)}
              description={t(item.descriptionText)}
            />
          </div>
        ) : (
          <>
            <NavLink
              className={styles.externalLink}
              to={routes[item.baseRoute][item.specificRoute]}
              onClick={() =>
                item.key === WAFFLE_ELEMENT_LIST[0].key
                  ? countlyEventTrigger(
                      CREATE_PATIENT_EVENTS_KEYS.CREATE_PATIENT_INITIATED,
                      {
                        [CREATE_PATIENT_SEGMENT_KEYS.ACCESS]: MORE_OPTIONS,
                      },
                    )
                  : null
              }
            >
              <WaffleMenuItemComponent
                id={item.key}
                iconName={item.iconName}
                linkText={t(item.linkText)}
                description={t(item.descriptionText)}
              />
            </NavLink>
          </>
        )}
      </li>
    ));
  };

  const isValidExportData = () =>
    isPatientExportData &&
    Array.isArray(patientExportData) &&
    patientExportData.length > 0;

  return (
    <RenderIf validate={newList && newList.length > 0}>
      <JelloPopoverComponent
        mainTestId={WAFFLE_MENU_ID}
        accessibilityText={t(WAFFLE_MENU_LABEL)}
        popoverAlignment={JELLO_POPOVER_ALIGNMENTS.END}
        tooltipProps={{
          whitTooltip: true,
          tooltipPosition: JELLO_TOOLTIP_POSITIONS.BOTTOM,
          tooltipLabel: t(WAFFLE_MENU_LABEL) || undefined,
        }}
        clickableComponent={{
          Component: JelloIconButton,
          iconName: JELLO_ICON_NAMES.SWITCHER,
          size: JELLO_ICON_BUTTON_SIZES.L,
          variant: JELLO_BUTTON_VARIANTS.TERTIARY,
          accessibilityText: t(WAFFLE_MENU_LABEL),
        }}
        popoverContentStyle={styles[getContentColumnsStyle]}
      >
        <ul
          className={
            numColumns === ONE_COLUMN
              ? `${styles.list} ${styles.list3El}`
              : styles.list
          }
        >
          <>{renderListElement(newList)}</>
        </ul>
      </JelloPopoverComponent>
      <RenderIf validate={isValidExportData()}>
        <ExportExcelComponent
          dataExcel={patientExportData}
          is12HourFormat={is12HourFormat}
        />
      </RenderIf>
    </RenderIf>
  );
};

import {
  CreateFhirPatientActions,
  CreateFhirPatientActionType,
  CreateFhirPatientState,
} from './create-patient.actions.types';

export const createFhirPatientInitialState: CreateFhirPatientState = {
  isCreatingPatient: false,
  createPatientWasSuccessful: false,
  createPatientHasError: false,
};

export const createFhirPatientReducer = (
  state = createFhirPatientInitialState,
  action: CreateFhirPatientActions,
) => {
  switch (action.type) {
    case CreateFhirPatientActionType.CREATE_FHIR_PATIENT_START:
      return {
        isCreatingPatient: true,
        createPatientWasSuccessful: false,
        createPatientHasError: false,
      };
    case CreateFhirPatientActionType.CREATE_FHIR_PATIENT_SUCCESS:
      return {
        isCreatingPatient: false,
        createPatientWasSuccessful: true,
        createPatientHasError: false,
      };
    case CreateFhirPatientActionType.CREATE_FHIR_PATIENT_ERROR:
      return {
        isCreatingPatient: false,
        createPatientWasSuccessful: false,
        createPatientHasError: true,
      };
    case CreateFhirPatientActionType.CREATE_PAIRING_CODE_PATIENT_START:
    case CreateFhirPatientActionType.CREATE_WITHOUT_PAIRING_CODE_PATIENT_START:
      return {
        isCreatingPatient: true,
        createPatientWasSuccessful: true,
        createPatientHasError: false,
      };
    case CreateFhirPatientActionType.CLEAR_CREATE_FHIR_PATIENT_STATE:
      return createFhirPatientInitialState;
    default:
      return state;
  }
};

import * as React from 'react';
import { JelloIcon } from 'src/shared/design-system/jello-icon/jello-icon.component';
import { JELLO_ICON_SIZES } from 'src/app/app.jello.constants';
import { getConfig } from '@roche/roche-common';

const { REACT_APP_PUBLIC_ASSETS_BASE_URL } = getConfig();

export const getJelloIcon = (iconName, size = JELLO_ICON_SIZES.S) => (
  <JelloIcon iconName={iconName} size={size} />
);

const REGULATORY_PATH = '/images/regulatory/';
const ICON_TYPE = 'svg';
const ICON_WIDTH = 24;

const generateAssetURL = (host, assetFolder, iconType, fileName) => {
  return `${host}${assetFolder}${iconType}/${fileName}`;
};

export const generateIfusRegulatoryAssetURL = (fileName) => {
  return generateAssetURL(
    REACT_APP_PUBLIC_ASSETS_BASE_URL,
    REGULATORY_PATH,
    ICON_TYPE,
    fileName,
  );
};

export const getImage = (url, alt) => (
  <img width={ICON_WIDTH} alt={alt} src={url} />
);

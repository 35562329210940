import { connect } from 'react-redux';
import { AnnouncementsComponent } from './announcements.component';
import { fetchUserAnnouncements } from './store/announcements.actions';
import { selectUserAnnouncements } from './store/announcements.selectors';
import { mapDispatchers } from '../../shared/utils/map-dispatchers';
import { selectHomeCountry } from 'src/app/store/user/user.selectors';

const dispatchers = mapDispatchers({
  fetchUserAnnouncementsAction: fetchUserAnnouncements.start,
});

const mapStateToProps = (state) => ({
  userAnnouncements: selectUserAnnouncements(state),
  countryCode: selectHomeCountry(state),
});

export const AnnouncementsContainer = connect(
  mapStateToProps,
  dispatchers,
)(AnnouncementsComponent);

import { createPayloadAction } from 'src/app/store/app.actions';

import {
  FetchRpmSettingsError,
  FetchRpmSettingsStart,
  FetchRpmSettingsSuccess,
  RpmSettingsActionType,
  RpmSettingsPayload,
  UpdateRpmSettingsError,
  UpdateRpmSettingsStart,
  UpdateRpmSettingsSuccess,
} from './rpm-settings.types';

import {
  TransformedUpdateSettingsErrorResponse,
  TransformedUpdateSettingsSuccessResponse,
  UpdateRpmSettingsParams,
} from 'src/domains/rpm/services/rpm/rpm-settings/rpm-settings.types';

export const fetchRpmSettingsStart = (
  payload: RpmSettingsPayload,
): FetchRpmSettingsStart =>
  createPayloadAction(RpmSettingsActionType.FETCH_RPMSETTINGS_START, payload);

export const fetchRpmSettingsSuccess = (
  payload: RpmSettingsPayload,
): FetchRpmSettingsSuccess =>
  createPayloadAction(RpmSettingsActionType.FETCH_RPMSETTINGS_SUCCESS, payload);

export const fetchRpmSettingsError = (error: any): FetchRpmSettingsError =>
  createPayloadAction(RpmSettingsActionType.FETCH_RPMSETTINGS_ERROR, error);

export const updateRpmSettingsStart = (
  payload: UpdateRpmSettingsParams,
): UpdateRpmSettingsStart =>
  createPayloadAction(RpmSettingsActionType.UPDATE_RPMSETTINGS_START, payload);

export const updateRpmSettingsSuccess = (
  payload: TransformedUpdateSettingsSuccessResponse,
): UpdateRpmSettingsSuccess =>
  createPayloadAction(
    RpmSettingsActionType.UPDATE_RPMSETTINGS_SUCCESS,
    payload,
  );

export const updateRpmSettingsError = (
  error: TransformedUpdateSettingsErrorResponse,
): UpdateRpmSettingsError =>
  createPayloadAction(RpmSettingsActionType.UPDATE_RPMSETTINGS_ERROR, error);

import styled from 'styled-components';

const TimeDiv = styled.span;
export const Time = TimeDiv`
    color: #FFFFFF;
    font-family: Nunito;
    font-size: 12px;
    font-weight: bold;
    padding-top: 1px;
    margin-left: 4px;
`;

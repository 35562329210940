import { createSelector } from 'reselect';
import { compose, filter, map, path, pathOr, pipe, propOr } from 'ramda';
import { graphThresholdsTransformer } from 'src/domains/diagnostics/scenes/graphs/logbook/logbook.core.utils';

import {
  convertJSDateGMT,
  convertStringToJSDate,
  isDateStringBetweenTwoDates,
} from '../../../../../shared/utils/date';
import {
  selectPatientEndDate,
  selectPatientStartDate,
} from '../../../../patient-dashboard/store/patient-date-range/patient-date-range.selector';

import { filterForHighestDailyTotalInsulinValues } from './logbook.core.util';
import { createBolusObject } from '../bolus/bolus.util';

const selectAllGlucoseMeasurementsIncludingNullValues = (store) =>
  store.ui.patientDashboard.glucoseMeasurements.filter((bg) => !bg.control);

export const selectAllInsulin = path(['ui', 'patientDashboard', 'insulin']);

const selectIsFetchingClinicalData = path([
  'ui',
  'patientDashboard',
  'isFetchingClinicalData',
]);

const selectIsFetchingThreshold = path([
  'ui',
  'patientDashboard',
  'isFetchingThreshold',
]);

const selectIsFetchingTimeIntervals = path([
  'ui',
  'patientDashboard',
  'isFetchingTimeIntervals',
]);

export const selectGlucoseMeasurementsIncludingNullValues = createSelector(
  selectPatientStartDate,
  selectPatientEndDate,
  selectAllGlucoseMeasurementsIncludingNullValues,
  (startDate, endDate, glucoseMeasurements) =>
    glucoseMeasurements.filter(({ date }) =>
      isDateStringBetweenTwoDates(date, startDate, endDate),
    ),
);

const selectAllBolusesData = path([
  'ui',
  'patientDashboard',
  'insulin',
  'bolus',
]);

const objectDateToGMTDate = (object) => {
  const date = compose(convertJSDateGMT, convertStringToJSDate)(object.date);
  return { ...object, date };
};

const selectBolusesDataFilterByDateRange = createSelector(
  selectPatientStartDate,
  selectPatientEndDate,
  selectAllBolusesData,
  (startDate, endDate, boluses = []) =>
    boluses
      .filter(({ date }) =>
        isDateStringBetweenTwoDates(date, startDate, endDate),
      )
      .map(objectDateToGMTDate),
);

export const selectBoluses = createSelector(
  selectBolusesDataFilterByDateRange,
  createBolusObject('Bolus'),
);

export const selectInsulin = createSelector(
  selectPatientStartDate,
  selectPatientEndDate,
  selectAllInsulin,
  (startDate, endDate, insulin) => {
    const basals = insulin.basals
      .filter(({ date }) =>
        isDateStringBetweenTwoDates(date, startDate, endDate),
      )
      .map(({ date, cbrf, profile, remark, tbrdec, tbrinc }) => ({
        date: new Date(date),
        basalCbrf: cbrf,
        basalRateProfile: profile,
        basalRemark: remark,
        basalTbrdec: tbrdec,
        basalTbrinc: tbrinc,
      }));

    const createBolusObject = (registerTypeString) =>
      pipe(
        filter(keepBolusOfRegisterType(registerTypeString)),
        filter(keepBolusInDateRange),
        map(toBolusShape),
      );

    const keepBolusOfRegisterType =
      (registerTypeString) =>
      ({ registerType }) =>
        registerType === registerTypeString;

    const keepBolusInDateRange = ({ date }) =>
      isDateStringBetweenTwoDates(date, startDate, endDate);

    const toBolusShape = ({
      date,
      value,
      remark,
      registerType,
      bolusType,
    }) => ({
      date: new Date(date),
      bolusValue: value,
      bolusRemark: remark,
      bolusRegisterType: registerType,
      bolusType: bolusType,
    });

    const bolus = createBolusObject('Bolus')(insulin.bolus);

    const totalBolus = filterForHighestDailyTotalInsulinValues(
      createBolusObject('BolusTotal')(insulin.bolus),
    );

    const totalBolusPlusBasal = filterForHighestDailyTotalInsulinValues(
      createBolusObject('BolusPlusBasalTotal')(insulin.bolus),
    );

    return { basals, bolus, totalBolus, totalBolusPlusBasal };
  },
);

const renameGraphsIntervalKeys = ({
  upperLimit,
  lowerLimit,
  thresholdHypo,
  thresholdHyper,
}) => ({
  upperHyperThreshold: thresholdHyper,
  glucoseIdealIntervalMax: upperLimit,
  glucoseIdealIntervalMin: lowerLimit,
  hypoglycemiaThreshold: thresholdHypo,
});

const selectStripDeliveryThresholds = pathOr({}, [
  'stripDelivery',
  'thresholds',
]);

const getPostIdealIntervalFromThreshold = propOr(null, 'postIdealInterval');

const selectPostIdealIntervalsFromThresholds = createSelector(
  selectStripDeliveryThresholds,
  ({ actualHyper, hyper, hypo, warning }) => ({
    thresholdHyper: getPostIdealIntervalFromThreshold(actualHyper),
    upperLimit: getPostIdealIntervalFromThreshold(hyper),
    lowerLimit: getPostIdealIntervalFromThreshold(warning),
    thresholdHypo: getPostIdealIntervalFromThreshold(hypo),
  }),
);

export const selectGraphThreshold = createSelector(
  selectPostIdealIntervalsFromThresholds,
  renameGraphsIntervalKeys,
);

export const selectAllGraphThresholds = createSelector(
  selectStripDeliveryThresholds,
  ({ actualHyper = {}, hyper = {}, hypo = {}, warning = {} }) =>
    graphThresholdsTransformer({ actualHyper, hyper, hypo, warning }),
);

export const selectTimeIntervals = pathOr(
  [],
  ['stripDelivery', 'timeIntervals'],
);

export const selectGraphLoading = createSelector(
  selectIsFetchingClinicalData,
  selectIsFetchingThreshold,
  selectIsFetchingTimeIntervals,
  (isFetchingMeasurements, isFetchingThreshold, isFetchingTimeIntervals) =>
    isFetchingMeasurements || isFetchingThreshold || isFetchingTimeIntervals,
);

import {
  CLEAR_PATIENT_EXPORT_DATA,
  EXPORT_PATIENT_SESSION_REQUEST,
  FetchStatus,
  PATIENT_ACTIONS,
  SAVE_PATIENT_SESSION_REQUEST,
  START_PATIENT_SESSION_REQUEST,
} from './patient-session.constants';
import { Action, IExportPatientsData } from './patient-session.types';

export type PatientSessionReducerState = {
  sessionTime: number;
  patientSessionId: string | null;
  sessionEndStatus: FetchStatus;
  exportStatus: FetchStatus;
  exportPatientsData: IExportPatientsData[] | null;
  isRunning: boolean;
  visitId: number | null;
};

export const INITIAL: PatientSessionReducerState = {
  sessionTime: 0,
  patientSessionId: null,
  sessionEndStatus: FetchStatus.NONE,
  exportStatus: FetchStatus.NONE,
  exportPatientsData: null,
  isRunning: false,
  visitId: null,
};

const handleStartPatientSession = (
  state: PatientSessionReducerState,
  action: Action,
) => ({
  ...state,
  ...action.payload,
  isRunning: true,
});

const handleResetPatientSession = (state: PatientSessionReducerState) => ({
  ...state,
  sessionTime: 0,
  patientSessionId: null,
  isRunning: false,
});

const handleClearSessionStatus = (state: PatientSessionReducerState) => ({
  ...state,
  sessionEndStatus: FetchStatus.NONE,
});

const handleSessionErrorOrReset = (state: PatientSessionReducerState) => ({
  ...state,
  isRunning: false,
  sessionEndStatus: FetchStatus.NONE,
});

const handleStartPatientSessionRequestSuccess = (
  state: PatientSessionReducerState,
  action: Action,
) => ({
  ...state,
  visitId: action.payload ? action.payload.id : null,
});

const handleSavePatientSessionRequestSuccess = (
  state: PatientSessionReducerState,
) => ({
  ...state,
  sessionEndStatus: FetchStatus.SUCCESS,
});

const handleSavePatientSessionRequestError = (
  state: PatientSessionReducerState,
) => ({
  ...state,
  sessionEndStatus: FetchStatus.FAILURE,
});

const handleExportPatientSessionRequest = (
  state: PatientSessionReducerState,
  action: Action,
  status: FetchStatus,
) => ({
  ...state,
  exportStatus: status,
  exportPatientsData: action.payload || state.exportPatientsData,
});

const handleClearPatientExportData = (state: PatientSessionReducerState) => ({
  ...state,
  exportPatientsData: null,
});

const actionHandlers = {
  [PATIENT_ACTIONS.START_PATIENT_SESSION]: handleStartPatientSession,
  [PATIENT_ACTIONS.RESET_PATIENT_SESSION]: handleResetPatientSession,
  [PATIENT_ACTIONS.CLEAR_SESSION_STATUS]: handleClearSessionStatus,
  [START_PATIENT_SESSION_REQUEST.ERROR]: handleSessionErrorOrReset,
  [PATIENT_ACTIONS.RESET_IS_RUNNING]: handleSessionErrorOrReset,
  [START_PATIENT_SESSION_REQUEST.SUCCESS]:
    handleStartPatientSessionRequestSuccess,
  [SAVE_PATIENT_SESSION_REQUEST.SUCCESS]:
    handleSavePatientSessionRequestSuccess,
  [SAVE_PATIENT_SESSION_REQUEST.ERROR]: handleSavePatientSessionRequestError,
  [EXPORT_PATIENT_SESSION_REQUEST.START]: (
    state: PatientSessionReducerState,
    action: Action,
  ) => handleExportPatientSessionRequest(state, action, FetchStatus.LOADING),
  [EXPORT_PATIENT_SESSION_REQUEST.SUCCESS]: (
    state: PatientSessionReducerState,
    action: Action,
  ) => handleExportPatientSessionRequest(state, action, FetchStatus.SUCCESS),
  [EXPORT_PATIENT_SESSION_REQUEST.ERROR]: (
    state: PatientSessionReducerState,
    action: Action,
  ) => handleExportPatientSessionRequest(state, action, FetchStatus.FAILURE),
  [CLEAR_PATIENT_EXPORT_DATA]: handleClearPatientExportData,
};

export const patientSessionReducer = (state = INITIAL, action: Action) => {
  const handler = actionHandlers[action.type];
  if (handler) {
    return handler(state, action);
  }

  return state;
};

import React, { FC } from 'react';
import { t } from 'i18next';
import {
  HIandLOAlertTypes,
  HiAndLoValuesAlertProps,
} from './hi-and-lo-alert.types';
import { JELLO_ALERT_VARIANTS } from 'src/app/app.jello.constants';
import styles from './styles.module.css';
import { JELLO_ALERT_HI_AND_LO_VALUES_ID } from './hi-and-lo-alert.constants';

export const HiAndLoValuesAlertComponent: FC<HiAndLoValuesAlertProps> = ({
  hiAndLoValues,
  type,
}) => {
  const hiAndLoValuesContent = (values) => {
    const bothValues = values?.numberOfHiValues && values?.numberOfLoValues > 0;
    const onlyHiValue = values?.numberOfHiValues > 0;
    const onlyLoValue = values?.numberOfLoValues > 0;

    let result = '';

    if (bothValues) {
      result = `${values?.numberOfHiValues} ${t(
        'bgDashboard.hiAndLoAlert.HI',
      )} -
         ${values?.numberOfLoValues} ${t('bgDashboard.hiAndLoAlert.LO')}`;
    } else if (onlyLoValue) {
      result = `${values?.numberOfLoValues} ${t(
        'bgDashboard.hiAndLoAlert.LO',
      )}`;
    } else if (onlyHiValue) {
      result = `${values?.numberOfHiValues} ${t(
        'bgDashboard.hiAndLoAlert.HI',
      )}`;
    }
    return result;
  };

  const alertDataMap = {
    [HIandLOAlertTypes.CGM]: {
      title: t('cgmDashboard.hiAndLoAlert.title'),
      linkText: '',
      content: '',
      testId: 'hi-and-lo-alert-cgm',
    },
    [HIandLOAlertTypes.BG]: {
      title: t('bgDashboard.hiAndLoAlert.title'),
      linkText: t('bgDashboard.hiAndLoAlert.linkText'),
      content: hiAndLoValuesContent(hiAndLoValues),
      testId: 'hi-and-lo-alert-bg',
    },
  };

  const alertData = alertDataMap[type];

  return (
    <React.Fragment>
      <jello-alert
        id={JELLO_ALERT_HI_AND_LO_VALUES_ID}
        data-testid={alertData.testId}
        class={styles.hiAndLoValues}
        variant={JELLO_ALERT_VARIANTS.WARNING}
        title-text={alertData.title}
        link-target="_blank"
        show-close-button
        link-text={alertData.linkText}
        close-button-accessibility-text={t(
          'bgDashboard.hiAndLoAlert.accessibilityText',
        )}
      >
        {alertData.content}
      </jello-alert>
    </React.Fragment>
  );
};

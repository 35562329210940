import * as React from 'react';
import { testId } from '@roche/roche-common';
import { ErrorCrossIcon } from 'src/shared/design-system/icons';
import styles from './styles.module.css';
import {
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
} from 'src/app/app.jello.constants';
import {
  INTERVENTIONAL_CLINICAL_STUDY_TAB,
  INTERVENTIONAL_PATH,
} from 'src/domains/patient/scenes/clinical-studies/clinical-studies.constants';
import { t } from 'i18next';

export type UnblindStudyErrorModalProps = {
  closeModal: () => void;
};

export const UnblindStudyErrorModalComponent: React.FC<
  UnblindStudyErrorModalProps
> = () => {
  return (
    <jello-dialog
      visible
      primary-button-text={t(INTERVENTIONAL_PATH.UNBLIND_ERROR_MODAL_BUTTON)}
      close-on-action
      {...testId(
        INTERVENTIONAL_CLINICAL_STUDY_TAB,
        'unblind-study-error-modal',
      )}
    >
      <div className={styles.contentWrapper}>
        <ErrorCrossIcon width={120} height={120} />
        <jello-text size={JELLO_TEXT_SIZES.L} weight={JELLO_TEXT_WEIGHTS.BOLD}>
          {t(INTERVENTIONAL_PATH.UNBLIND_ERROR_MODAL_TITLE)}
        </jello-text>
        <jello-text
          size={JELLO_TEXT_SIZES.M}
          weight={JELLO_TEXT_WEIGHTS.REGULAR}
        >
          {t(INTERVENTIONAL_PATH.UNBLIND_ERROR_MODAL_CONTENT)}
        </jello-text>
      </div>
    </jello-dialog>
  );
};

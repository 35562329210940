import {
  createAuthHeader,
  postJSON,
  stringifyBody,
} from 'src/shared/utils/service/service.utils';

import {
  ENDPOINTS,
  endpointWithParams,
} from 'src/app/navigation/services/service.constants';

export const ManualDeliveryLoaderImpl = (
  { patientId, stripModelReference, numberOfStripsDelivered, comment },
  accessToken,
) =>
  postJSON(endpointWithParams(ENDPOINTS.manualDelivery, { patientId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
    body: stringifyBody({
      stripModelReference,
      numberOfStripsDelivered,
      comment,
    }),
  });

export const ManualDeliveryTransformImpl = (results) => results;

export const ManualDeliveryServiceImpl =
  (post, transform) => (params, accessToken, gigyaToken) =>
    post(params, accessToken, gigyaToken).then(transform);

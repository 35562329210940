import { GetClinicalStudiesResponseType } from './get-clinical-studies.types';

export const getClinicalStudiesMockResponse: GetClinicalStudiesResponseType = {
  interventionalStudies: [
    {
      studyId: 'DC123456',
      siteId: '10',
    },
  ],
  observationalStudies: [
    {
      studyId: 'DC123457',
      siteId: '10',
    },
  ],
};

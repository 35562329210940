import React from 'react';
import { createRoot } from 'react-dom/client';
import { loadTheme } from '@rdcs/jello-themes/blue/helpers';
import { getConfig } from '@roche/roche-common';
const { REACT_APP_PUBLIC_ASSETS_BASE_URL } = getConfig();

import { App } from './app/app';

const container = document.getElementById('root');
const root = createRoot(container!);
import { initIconLibrary } from './app/setup-icons';

loadTheme(REACT_APP_PUBLIC_ASSETS_BASE_URL);
initIconLibrary();
import(/* webpackChunkName: "jello-ds" */ '@rdcs/jello-ds/esm/main').then(() =>
  root.render(<App />),
);

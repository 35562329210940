import React, { Component, Fragment } from 'react';
import { compose, isEmpty, isNil } from 'ramda';

import { ResizeWrapper } from 'src/domains/diagnostics/resize-wrapper/resize-wrapper.component';
import { Table } from 'src/shared/design-system/table/table.component';
import { ToolTip } from 'src/shared/design-system/tool-tip/tool-tip.component';
import { RenderIf } from 'src/shared/utils/render-if';
import { toMealTimeModalDate } from 'src/shared/utils/date';
import { spacing } from 'src/app/styles/spacing';
import { LogbookTable } from 'src/domains/diagnostics/components/table/logbook-table/logbook-table.component';
import { MealTimesTableHeader } from 'src/domains/diagnostics/components/table/meal-times-table-header/meal-times-table-header.component';
import { AdditionalInformationButtonContainer as AdditionalInformationButton } from 'src/domains/diagnostics/components/additional-information/additional-information.container';
import {
  logbookDisclaimers,
  logbookDisclaimersLastUpdated,
} from 'src/domains/diagnostics/components/additional-information/additional-information.constant';
import { withGraphLoader } from 'src/domains/diagnostics/utils/with-graph-loader';
import { GraphControls } from 'src/domains/diagnostics/components/graph-controls/graph-controls.component';
import { GRAPHS } from 'src/domains/diagnostics/scenes/graphs/graph.constants';

import { LogbookHint } from '../../logbook-hint/logbook-hint.component';
import { LogbookWrapper } from '../wrapper/logbook-wrapper.component';
import {
  LogbookGraphWrapper,
  LogbookGraphWrapperDiv,
} from '../style/logbook.style';
import {
  getCombinedMealTimeDate,
  getCombinedMealTimeName,
  getMealTimeModalMeasurements,
} from '../../logbook.util';

import { GraphWrapperDiv } from '../../../../scenes/graphs/graph.style';
import {
  COLLAPSED_STD_GRAPH_HEIGHT,
  COLLAPSED_STD_GRAPH_HEIGHT_GP,
  COLLAPSED_STD_GRAPH_HEIGHT_GP_IN_REM,
  COLLAPSED_STD_GRAPH_HEIGHT_IN_REM,
  LOGBOOK_LOGBOOK_MIN_WIDTH,
  MIN_EXPANDED_STD_GRAPH_HEIGHT,
} from '../../../../scenes/graphs/graph.constants';
import TooltipPreventRender from '../../../../components/tooltip-prevent-render/tooltip-prevent-render.component';
import { additionalInfoActivated } from 'src/domains/diagnostics/components/additional-info/additional.info.utils';
import { getSelectedRowId } from '../../graph/logbook.graph-utils';
import { getCarbohydratesUnitsLabel } from 'src/domains/diagnostics/scenes/graphs/graph-statistics.util';

const LogbookGraphWrapperDivWithLoader = withGraphLoader(
  LogbookGraphWrapperDiv,
  'graphs.logbook.loading',
);

export class Logbook extends Component {
  ref = React.createRef();
  constructor() {
    super();
    this.header = { clientHeight: 0 };
    this.tableRef = null;
  }

  state = {
    hint: null,
  };

  componentDidMount() {
    if (this.ref.current) {
      this.tableRef = this.ref.current;
    }
  }

  render() {
    const {
      bloodGlucoseUnit,
      logbookData,
      collapsed,
      collapsedGP,
      match,
      toolTip,
      showToolTip,
      hideToolTip,
      isLoading,
      timeFormat,
      graph,
      hasData,
      numbermeasurementsbasalData,
      carbUnit,
    } = this.props;
    const TABLE_WRAPPER_MARGIN = 16;
    const carbLabel = getCarbohydratesUnitsLabel[carbUnit];

    if (this.tableRef && this.tableRef.clientHeight) {
      this.header.clientHeight =
        this.tableRef.clientHeight + TABLE_WRAPPER_MARGIN;
    } else {
      this.header.clientHeight = 0;
    }

    return (
      <React.Fragment>
        <TooltipPreventRender toolTip={toolTip}>
          <RenderIf validate={hasData || numbermeasurementsbasalData !== 0}>
            <GraphControls
              showChangeGraphToggle={collapsedGP ? !collapsedGP : !collapsed}
              graphType={GRAPHS.LOGBOOK}
              collapsed={collapsed}
              collapsedGP={collapsedGP}
              graph={graph}
            >
              {!additionalInfoActivated() && (
                <RenderIf validate={collapsedGP ? !collapsedGP : !collapsed}>
                  <AdditionalInformationButton
                    content={logbookDisclaimers}
                    updated={logbookDisclaimersLastUpdated}
                    onlyIcon={true}
                  />
                </RenderIf>
              )}
            </GraphControls>
          </RenderIf>
          <LogbookWrapper
            collapsed={collapsedGP ? collapsedGP : collapsed}
            blueBackground={!isEmpty(logbookData) && !isLoading}
            onClick={this.onLogbookTableClickHandler}
          >
            <LogbookGraphWrapperDivWithLoader
              collapsed={collapsed}
              collapsedGP={collapsedGP}
              hasError={isEmpty(logbookData) && !isLoading}
              isLoading={isLoading}
            >
              <GraphWrapperDiv
                p={0}
                minHeight={
                  collapsed
                    ? COLLAPSED_STD_GRAPH_HEIGHT_IN_REM
                    : collapsedGP
                    ? COLLAPSED_STD_GRAPH_HEIGHT_GP_IN_REM
                    : 0
                }
                collapsed={collapsedGP ? collapsedGP : collapsed}
                minWidth={LOGBOOK_LOGBOOK_MIN_WIDTH}
              >
                <RenderIf validate={!!logbookData.length}>
                  <ResizeWrapper
                    firstUpdate={true}
                    minHeight={
                      collapsed || collapsedGP ? COLLAPSED_STD_GRAPH_HEIGHT : 0
                    }
                    render={(height) => (
                      <Fragment>
                        <RenderIf validate={!!logbookData.length}>
                          <Table m={`0 0 ${spacing.one}`} ref={this.ref}>
                            <MealTimesTableHeader
                              bloodGlucoseUnit={bloodGlucoseUnit}
                              carbLabel={carbLabel}
                            />
                          </Table>
                        </RenderIf>
                        <LogbookGraphWrapper
                          tableHeight={
                            collapsed
                              ? COLLAPSED_STD_GRAPH_HEIGHT -
                                this.header.clientHeight
                              : collapsedGP
                              ? COLLAPSED_STD_GRAPH_HEIGHT_GP -
                                this.header.clientHeight
                              : height - this.header.clientHeight
                          }
                        >
                          <LogbookTable
                            match={match}
                            bloodGlucoseUnit={bloodGlucoseUnit}
                            logbookData={
                              (collapsed || collapsedGP) &&
                              logbookData.length > 2
                                ? logbookData.slice(
                                    logbookData.length - 2,
                                    logbookData.length,
                                  )
                                : logbookData
                            }
                            selectedRowId={
                              match.params.selectedDate &&
                              getSelectedRowId(match, logbookData)
                            }
                            onScrollHandler={this.onTableScroll}
                            onPointMouseOver={showToolTip}
                            onPointMouseOut={hideToolTip}
                          />
                        </LogbookGraphWrapper>
                      </Fragment>
                    )}
                    resizeFunction={(clientHeight) => {
                      const tableHeight = clientHeight;
                      return tableHeight > MIN_EXPANDED_STD_GRAPH_HEIGHT
                        ? tableHeight
                        : MIN_EXPANDED_STD_GRAPH_HEIGHT;
                    }}
                  />
                </RenderIf>
              </GraphWrapperDiv>
            </LogbookGraphWrapperDivWithLoader>
          </LogbookWrapper>
        </TooltipPreventRender>
        <RenderIf validate={toolTip.x && toolTip.y}>
          {this.renderToolTip(toolTip, timeFormat)}
        </RenderIf>
      </React.Fragment>
    );
  }

  renderToolTip = (toolTip, timeFormat) => {
    const {
      x,
      y,
      data: { mealTimeData, mealTime },
    } = toolTip;

    const { bloodGlucoseUnit } = this.props;

    if (isNil(x) || isNil(y) || isEmpty(mealTimeData.measurements)) return;

    const measurements = getMealTimeModalMeasurements(
      mealTimeData,
      bloodGlucoseUnit,
    );

    const hintProps = {
      mealTimeName: getCombinedMealTimeName(mealTime),
      date: compose(toMealTimeModalDate, getCombinedMealTimeDate)(mealTimeData),
      measurements,
      timeFormat,
    };

    return (
      <ToolTip x={x} y={y}>
        <LogbookHint {...hintProps} />
      </ToolTip>
    );
  };

  onTableScroll = (e) => this.props.hideToolTip();

  onLogbookTableClickHandler = (event) => {
    const { toolTip, hideToolTip } = this.props;
    if (!isNil(toolTip.x) && !isNil(toolTip.y)) hideToolTip();
  };
}

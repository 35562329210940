import * as React from 'react';
import { TFunction } from 'i18next';

import {
  GeneralUseText,
  ManufacturerInformationText,
  ManufacturerInfoTitle,
  TextBlock,
} from '../manufacturer-info-modal.style';
import { appendSuffixIfRebranded } from '../manufacturer-info-modal.utils';

type Props = {
  t: TFunction;
  rebranding: boolean;
};

export const SysReqView = ({ t, rebranding }: Props) => (
  <div>
    <ManufacturerInfoTitle>
      {t('manufacturerInfo.sysReq')}
    </ManufacturerInfoTitle>
    <TextBlock>
      <GeneralUseText>
        {t(appendSuffixIfRebranded('manufacturerInfo.sysReq2', rebranding))}
      </GeneralUseText>
      {browserList.map((index, i) => (
        <ManufacturerInformationText key={i}>
          {t(`${index.browser}`)}
          <span>{t(`${index.version}`)}</span>
        </ManufacturerInformationText>
      ))}
      <GeneralUseText>{t('manufacturerInfo.sysReq3')}</GeneralUseText>
    </TextBlock>
  </div>
);

const browserList = [
  {
    browser: 'manufacturerInfo.sysReqChromeWin',
    version: 'manufacturerInfo.sysReqChromeWinVs',
  },
  {
    browser: 'manufacturerInfo.sysReqChromeMac',
    version: 'manufacturerInfo.sysReqChromeMacVs',
  },
  {
    browser: 'manufacturerInfo.sysReqFirefoxWin',
    version: 'manufacturerInfo.sysReqFirefoxWinVs',
  },
  {
    browser: 'manufacturerInfo.sysReqFirefoxMac',
    version: 'manufacturerInfo.sysReqFirefoxMacVs',
  },
  {
    browser: 'manufacturerInfo.sysReqEdgeWin10',
    version: 'manufacturerInfo.sysReqEdgeWin10Vs',
  },
  {
    browser: 'manufacturerInfo.sysReqSafariMojave',
    version: 'manufacturerInfo.sysReqSafariMojaveVs',
  },
];

import { createSelector } from 'reselect';
import { isEmpty, path, propOr } from 'ramda';
import {
  selectGlucoseMeasurementsInDateSliderRange,
  selectIsLoading,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import { selectPatient } from 'src/domains/patient/store/patient/patient.selector';

export const selectPatientHasData = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectIsLoading,
  (measurements, isLoading) => isLoading || !isEmpty(measurements),
);

export const selectPatientIsEMRCompleted = createSelector(
  [selectPatient],
  (state) => propOr(false, 'isEMRCompleted', state),
);

export const selectPatientReportStatus = path(['patientReports', 'status']);

import React from 'react';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';
import { weight } from 'src/domains/diagnostics/components/fonts/weights/weight.style';
import { LogbookTableRow } from 'src/domains/diagnostics/components/table/logbook-table-row/logbook-table-row.component';
import { FixedTableHeaderWithSpacing } from 'src/domains/diagnostics/components/table-header/table-header.component';
import { TableHeaderCell } from 'src/domains/diagnostics/components/table/table-header-cell/table-header-cell.component';
import { LogbookDiaryTableSubheader } from 'src/domains/diagnostics/components/table/logbook-diary-table-subheader/logbook-diary-table-subheader.component';
import { colors } from 'src/app/styles/colors';
import { TABLE_HEADER_KEYS } from 'src/domains/diagnostics/constants/logbook-diary.constants';

const borderSpacing = 0.75;

const tableHeaders = (is12hourTimeFormat) => [
  { key: TABLE_HEADER_KEYS.DATE, width: `${6.875 - borderSpacing * 1.5}rem` },
  {
    key: TABLE_HEADER_KEYS.TIME,
    width: is12hourTimeFormat
      ? `${4.8 - borderSpacing}rem`
      : `${3.8 - borderSpacing}rem`,
  },
  { key: TABLE_HEADER_KEYS.BLOOD_GLUCOSE, width: `${16 - borderSpacing}rem` },
  { key: TABLE_HEADER_KEYS.CARBOHYDRATES, width: `${9 - borderSpacing}rem` },
  {
    key: TABLE_HEADER_KEYS.INSULIN,
    width: `${12 - borderSpacing}rem`,
    colSpan: 3,
  },
  {
    key: TABLE_HEADER_KEYS.BASAL_RATE_PROFILE,
    width: `${8 - borderSpacing}rem`,
  },
  { key: TABLE_HEADER_KEYS.PUMP, width: `${8 - borderSpacing}rem`, colspan: 3 },
  {
    key: TABLE_HEADER_KEYS.ADVICED_BOLUS,
    width: `${18 - borderSpacing}rem`,
  },
];

export const LogbookDiaryTableHeaderComponent = ({
  bloodGlucoseUnit,
  is12hourTimeFormat,
  t,
  carbLabel,
}) => (
  <FixedTableHeaderWithSpacing
    bg={colors.clear}
    marginLeft="-0.625rem"
    marginTop="-1.063rem"
  >
    <LogbookTableRow>
      {tableHeaders(is12hourTimeFormat).map((header, i) => {
        const borderBottom =
          header.key === TABLE_HEADER_KEYS.DATE ||
          header.key === TABLE_HEADER_KEYS.TIME ||
          header.key === TABLE_HEADER_KEYS.ADVICED_BOLUS
            ? null
            : { color: colors.blueMarine };

        return (
          <TableHeaderCell
            key={`logbook diary label header - ${header.key}-${i}`}
            keyText={i}
            headerKey={header.key}
            height="auto"
            width={header.width}
            colSpan={header.colSpan ? header.colSpan : 1}
            noFill
            noTopBorder
            noRightBorder
            borderBottom={borderBottom}
            color={colors.blueMarine}
            textTransform="uppercase"
            fontWeight={weight.bold}
            verticalAlign="top"
            overflow="visible"
            padding="0"
            whiteSpace="normal"
            textAlign="left"
          />
        );
      })}
    </LogbookTableRow>
    <LogbookTableRow>
      <LogbookDiaryTableSubheader
        bloodGlucoseUnit={bloodGlucoseUnit}
        t={t}
        is12hourTimeFormat={is12hourTimeFormat}
        carbLabel={carbLabel}
      />
    </LogbookTableRow>
  </FixedTableHeaderWithSpacing>
);

export const LogbookDiaryTableHeader = withTranslation(
  LogbookDiaryTableHeaderComponent,
);

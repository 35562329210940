import {
  DateRangeLoaderImpl,
  DateRangeServiceImpl,
} from './patient-date-range.service';
import { mockDateRangeData } from './patient-date-range.mock';

export const mockLoader = () => Promise.resolve(mockDateRangeData);

export const DateRangeFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : DateRangeLoaderImpl;
  return DateRangeServiceImpl(loader);
};

// @ts-nocheck
import * as React from 'react';
import { withTranslation } from '../../../../utils/i18n/with-translation';
import { TextModal } from '../../pdf-report.style';
import { ButtonWrapper, IconWrapper } from './pdf-report-fail.style';
import { Button } from '../../../button/button.component';
import { DisclaimerIcon } from '../../../icons';
import { colors } from '../../../../../app/styles/colors';

const PdfReportsFailComponentModal = ({ onRetry, t, text }) => (
  <>
    <IconWrapper>
      <DisclaimerIcon
        withBorder
        width={75}
        height={75}
        borderFillColor={colors.red}
        iconColor={colors.white}
        borderColor={colors.white}
      />{' '}
    </IconWrapper>
    <TextModal textAlign="center">{t('pdfReport.error')}</TextModal>
    <TextModal textAlign="center">
      {t('pdfReport.pdfNotGenerated', { patientInfo: text })}
    </TextModal>
    <ButtonWrapper>
      <Button onClick={onRetry} label={t('pdfReport.retry')} />
    </ButtonWrapper>
  </>
);

export const PdfReportsFailModal = withTranslation(
  PdfReportsFailComponentModal,
);

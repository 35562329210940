import * as platform from 'platform';

const MAC_OSX_REGEX = /(mac)|(OS\sX)/gi;

export const transformPlatformToOSMap = ({ os }) => {
  const isMacOS = os && os.toString().match(MAC_OSX_REGEX);
  return {
    name: isMacOS ? 'mac' : 'win',
    extension: isMacOS ? 'dmg' : 'exe',
  };
};

export const generateDTCDownloadURL = () => {
  const os = transformPlatformToOSMap(platform);
  return `buckets/${os.name}/Setup.${os.extension}`;
};

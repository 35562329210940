import { pipe } from 'ramda';
import {
  createAuthHeader,
  endpointTransformer,
  getJSON,
} from 'src/shared/utils/service/service.utils';

import {
  ENDPOINTS,
  endpointWithParams,
} from 'src/app/navigation/services/service.constants';

import { transformServerToClientDeliveryStatus } from '../delivery-status.util';
import { withQueryPatientId } from '../query';

export const GetDeliveryStatusLoaderImpl = (
  { patientId, patientFhirId, hasUserFhirPermission },
  accessToken,
  gigyaToken,
) =>
  getJSON(
    endpointTransformer(
      endpointWithParams(ENDPOINTS.deliveryStatus, { patientId }),
      ['v1', 'v2', patientId, patientFhirId, hasUserFhirPermission],
    ),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );

export const GetDeliveryStatusTransformImpl =
  transformServerToClientDeliveryStatus;

export const GetDeliveryStatusServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(
      pipe(transform, withQueryPatientId(query)),
    );

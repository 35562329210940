import React, { useEffect, useState } from 'react';
import { matchPath } from 'react-router-dom';
import { testId } from '@roche/roche-common';
import { t } from 'i18next';
import { routes } from 'src/domains/routes';
import {
  EDIT_PATIENT,
  getTitles,
  PATIENT_DASBOARD_TITLE_CHIP,
  SIDEBAR_STRIP_TITLE,
} from './constants';
import styles from './styles.module.css';
import {
  JELLO_CHIP_SIZES,
  JELLO_HEADINGS_LEVELS,
  JELLO_HEADINGS_WEIGHTS,
} from 'src/app/app.jello.constants';
import { RenderIf } from 'src/shared/utils/render-if';
import { ADMINISTRATIVE } from 'src/app/store/user/user.constants';
import { PATIENT_PERMISSIONS as PP } from 'src/domains/permissions/store/permissions.constants';
import { ProfileTypes } from 'src/domains/patient/store/department/department.types';

type DashboardTitleProps = {
  location?: any;
  patientPermissions?: any;
  profile?: any;
  patientProfileType: string;
};

export const DashboardTitleComponent = (props: DashboardTitleProps) => {
  const {
    location = window.location,
    patientPermissions,
    profile,
    patientProfileType,
  } = props;

  const showTooltip = () => window.innerWidth < 1200;
  const TITLES = getTitles(routes);
  const [tooltip, setTooltip] = useState(showTooltip());

  const exactMatch = {
    ...TITLES.filter(({ path }) =>
      matchPath(location?.pathname, { path, exact: true }),
    )[0],
  };

  const looseMatch = {
    ...TITLES.filter(({ path }) => matchPath(location?.pathname, { path }))[0],
  };

  const { title = '', id = '' } =
    exactMatch.title && exactMatch.id ? exactMatch : looseMatch;

  const isAdminWithoutStrip =
    title !== EDIT_PATIENT &&
    !patientPermissions.includes(PP.STRIP_MNG_PATIENT) &&
    profile === ADMINISTRATIVE;

  const hasChip =
    title === SIDEBAR_STRIP_TITLE &&
    (patientProfileType === ProfileTypes.pickup ||
      patientProfileType === ProfileTypes.homeDelivery);

  useEffect(() => {
    /* istanbul ignore next */
    window.addEventListener('resize', () => setTooltip(showTooltip()), false);
  }, [window.innerWidth]);

  const renderChipTitle = () => (
    <jello-chip
      size={JELLO_CHIP_SIZES.S}
      color={PATIENT_DASBOARD_TITLE_CHIP.chipColor}
      left-icon-name={PATIENT_DASBOARD_TITLE_CHIP[patientProfileType].icon}
      {...testId(
        'jello-chip',
        'strip-management-' +
          `${PATIENT_DASBOARD_TITLE_CHIP[patientProfileType].text}`,
      )}
    >
      {t(PATIENT_DASBOARD_TITLE_CHIP[patientProfileType].text)}
    </jello-chip>
  );

  return (
    <RenderIf validate={!isAdminWithoutStrip}>
      <div className={styles.wrapper}>
        <jello-heading
          id={id}
          class={styles.title}
          ellipsis={true}
          level={JELLO_HEADINGS_LEVELS.H1}
          weight={JELLO_HEADINGS_WEIGHTS.SEMIBOLD}
          title={tooltip ? t(title) : null}
          {...testId('jello-heading-', id)}
        >
          {t(title)}
        </jello-heading>
        {hasChip ? renderChipTitle() : <></>}
      </div>
    </RenderIf>
  );
};

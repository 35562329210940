import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PERMISSIONS } from 'src/domains/permissions/store/permissions.constants';
import { ProtectedRoute } from 'src/domains/diagnostics/components/protected-route/protected-route.container';
import { RouterOutlet } from 'src/domains/diagnostics/components/navigation/router-outlet';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import {
  GRAPH_TYPE_DETAILS,
  LOGBOOK_TYPE_DIARY,
  LOGBOOK_TYPE_STATS,
  LOGBOOK_TYPE_DETAILS,
  LOGBOOK_TYPE_24HOUR,
  GRAPHS,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { InsulinContainerMaximizeWrapper } from 'src/domains/diagnostics/scenes/insulin/insulin.style';
import { DeviceSettingsContainer } from './scenes/device-settings/device-settings.container';
import { LogbookContainer } from 'src/domains/diagnostics/widgets/logbook/ui/component/logbook.container';
import { LogbookDiaryContainer } from 'src/domains/diagnostics/widgets/logbook-diary/logbook-diary.container';
import { LogbookStatsContainer } from 'src/domains/diagnostics/widgets/logbook-stats/logbook-stats.container';
import { InsulinContainer } from 'src/domains/diagnostics/scenes/insulin/insulin.container';
import { Logbook24HoursContainer } from 'src/domains/diagnostics/widgets/logbook-24hrs/logbook-24hrs.container';

import { Column } from 'src/shared/design-system/column/column.component';
import { LoadingRing } from 'src/shared/design-system/loading-ring/loading-ring.component';
import {
  DeviceDetailsBundle,
  GraphBundle,
  BloodGlucoseOverviewBundle,
  DeviceSettingsBundle,
  StandardWeekBundle,
  StandardWeekTrendBundle,
  StandardDayDetailBundle,
  StandardDayTrendBundle,
  TrendDetailBundle,
  TrendTrendBundle,
  InsulinPumpBundle,
  MetabolicBundle,
  Logbook24HoursBundle,
  LogbookDiaryBundle,
  LogbookStatsBundle,
  LogbookBundle,
  InsulinBundle,
} from './bundles';
import { GraphPageWrapper } from './components/graph-wrapper.component';
import { GraphSettingsWrapper } from './components/graph-settings-wrapper.component';
import { connectToGraphs } from './scenes/graphs/graph.container';
import { connectToBloodGlucoseStats } from './widgets/blood-glucose-general-stats/blood-glucose-general-stats.container';
import { InsulinPumpContainer } from './scenes/insulin-pump/insulin-pump.container';

import { BloodGlucoseOverviewContainer } from './scenes/blood-glucose-overview/blood-glucose-overview.container';
import { TrendDetailContainer } from './scenes/graphs/template/trend/trend-detail/trend-detail.container';
import { TrendTrendContainer } from './scenes/graphs/template/trend/trend-trend/trend-trend.container';
import { StandardDayDetailContainer } from './scenes/graphs/template/standard-day-detail/standard-day-detail.container';
import { StandardDayTrendContainer } from './scenes/graphs/template/standard-day-trend/standard-day-trend.container';
import { StandardWeekDetailContainer } from './scenes/graphs/template/standard-week-detail/standard-week-detail.container';
import { StandardWeekTrendContainer } from './scenes/graphs/template/standard-week-trend/standard-week-trend.container';
import { MetabolicContainer } from './scenes/graphs/template/metabolic/metabolic.container';
import { routes } from 'src/domains/routes';
import { patientDashboardsLinks } from 'src/domains/patient-dashboards/routes';

export const diagnosticsLinks = {
  bloodGlucoseOverview: '/patients/bg/:id/blood-glucose-overview',
  insulin: '/patients/bg/:id/graph/insulin',
  graph: '/patients/bg/:id/graph',
  trend: '/patients/bg/:id/graph/trend',
  logbook: '/patients/bg/:id/graph/logbook/:selectedDate?',
  logbookStats: '/patients/bg/:id/graph/logbook-stats/:selectedDate?',
  logbookDiary: '/patients/bg/:id/graph/logbook-diary/:selectedDate?',
  metabolicRate: '/patients/bg/:id/graph/metabolic-rate',
  standardWeek: '/patients/bg/:id/graph/standard-week',
  standardDay: '/patients/bg/:id/graph/standard-day',
  bloodGlucoseGeneralStats:
    '/patients/bg/:id/graph/blood-glucose-general-stats',
  bloodGlucoseGeneralStatsAll:
    '/patients/bg/:id/graph/blood-glucose-general-stats/all',
  bloodGlucoseGeneralStatsById:
    '/patients/bg/:id/graph/blood-glucose-general-stats/:id',
  insulinPump: '/patients/bg/:id/graph/insulin-pump',
  deviceSettings: '/patients/bg/:id/graph/device-settings',
};

export const DiagnosticsRoutes = (props) => {
  const { patientId, goTo } = props;
  const { bgId } = patientDashboardsLinks;
  const matchPatient = !!patientId && window.location.href.includes(patientId);

  const renderGraph = (Component) =>
    matchPatient ? (
      Component
    ) : (
      <Column align="center" height="100vh" justifyContent="center">
        <LoadingRing infinite />
      </Column>
    );

  return (
    <Switch>
      <ProtectedRoute
        hasPermissions={[PERMISSIONS.USER_SHOW_GRAPHIC_CONFIG]}
        exact
        path={diagnosticsLinks.bloodGlucoseOverview}
        component={connectToGraphs(() =>
          renderGraph(<BloodGlucoseOverviewContainer />),
        )}
      />
      <ProtectedRoute
        hasPermissions={[PERMISSIONS.USER_SHOW_GRAPHIC_CONFIG]}
        exact
        path={diagnosticsLinks.graph}
        component={GraphBundle}
      />
      <RouterOutlet path={diagnosticsLinks.graph}>
        <GraphPageWrapper {...props}>
          <ProtectedRoute
            hasPermissions={[PERMISSIONS.USER_SHOW_GRAPHIC_CONFIG]}
            exact
            path={diagnosticsLinks.insulin}
            component={connectToGraphs(() =>
              renderGraph(
                <GraphSettingsWrapper showChangeGraphToggle={false}>
                  <InsulinContainerMaximizeWrapper>
                    <InsulinContainer graph={GRAPHS.INSULIN} expanded={true} />
                  </InsulinContainerMaximizeWrapper>
                </GraphSettingsWrapper>,
              ),
            )}
          />
          <ProtectedRoute
            hasPermissions={[PERMISSIONS.USER_SHOW_GRAPHIC_CONFIG]}
            exact
            path={diagnosticsLinks.logbook}
            component={connectToGraphs(({ logbookType }) =>
              renderGraph(
                <GraphSettingsWrapper>
                  <RenderIf validate={logbookType === LOGBOOK_TYPE_DETAILS}>
                    <LogbookContainer graph={GRAPHS.LOGBOOK} />
                  </RenderIf>
                  <RenderIf validate={logbookType === LOGBOOK_TYPE_STATS}>
                    <LogbookStatsContainer graph={GRAPHS.LOGBOOK} />
                  </RenderIf>
                  <RenderIf validate={logbookType === LOGBOOK_TYPE_DIARY}>
                    <LogbookDiaryContainer graph={GRAPHS.LOGBOOK} />
                  </RenderIf>
                  <RenderIf validate={logbookType === LOGBOOK_TYPE_24HOUR}>
                    <Logbook24HoursContainer graph={GRAPHS.LOGBOOK} />
                  </RenderIf>
                </GraphSettingsWrapper>,
              ),
            )}
          />
          <ProtectedRoute
            hasPermissions={[PERMISSIONS.USER_SHOW_GRAPHIC_CONFIG]}
            exact
            path={diagnosticsLinks.metabolicRate}
            component={connectToGraphs(() =>
              renderGraph(
                <MetabolicContainer
                  graph={GRAPHS.METABOLIC_RATE}
                  flexibleHeight
                  showDetails
                />,
              ),
            )}
          />
          <ProtectedRoute
            hasPermissions={[PERMISSIONS.USER_SHOW_GRAPHIC_CONFIG]}
            exact
            path={diagnosticsLinks.insulinPump}
            component={() =>
              renderGraph(
                <InsulinPumpContainer
                  graph={GRAPHS.INSULIN_PUMP}
                  showDetails
                />,
              )
            }
          />
          <ProtectedRoute
            hasPermissions={[PERMISSIONS.USER_SHOW_GRAPHIC_CONFIG]}
            exact
            path={diagnosticsLinks.trend}
            component={connectToGraphs(({ graphType }) =>
              renderGraph(
                <GraphSettingsWrapper>
                  <RenderIf validate={graphType === GRAPH_TYPE_DETAILS}>
                    <TrendDetailContainer
                      graph={GRAPHS.TREND}
                      flexibleHeight
                      showChangeGraphToggle
                    />
                  </RenderIf>
                  <RenderIf validate={graphType !== GRAPH_TYPE_DETAILS}>
                    <TrendTrendContainer
                      graph={GRAPHS.TREND}
                      flexibleHeight
                      showChangeGraphToggle
                    />
                  </RenderIf>
                </GraphSettingsWrapper>,
              ),
            )}
          />
          <ProtectedRoute
            hasPermissions={[PERMISSIONS.USER_SHOW_GRAPHIC_CONFIG]}
            exact
            path={diagnosticsLinks.standardDay}
            component={connectToGraphs(({ graphType }) =>
              renderGraph(
                <GraphSettingsWrapper>
                  <RenderIf validate={graphType === GRAPH_TYPE_DETAILS}>
                    <StandardDayDetailContainer
                      graph={GRAPHS.STANDARD_DAY}
                      flexibleHeight
                      linesClickable
                      showChangeGraphToggle
                    />
                  </RenderIf>
                  <RenderIf validate={graphType !== GRAPH_TYPE_DETAILS}>
                    <StandardDayTrendContainer
                      graph={GRAPHS.STANDARD_DAY}
                      flexibleHeight
                      showChangeGraphToggle
                    />
                  </RenderIf>
                </GraphSettingsWrapper>,
              ),
            )}
          />
          <ProtectedRoute
            hasPermissions={[PERMISSIONS.USER_SHOW_GRAPHIC_CONFIG]}
            exact
            path={diagnosticsLinks.standardWeek}
            component={connectToGraphs(({ graphType }) =>
              renderGraph(
                <GraphSettingsWrapper>
                  <RenderIf validate={graphType === GRAPH_TYPE_DETAILS}>
                    <StandardWeekDetailContainer
                      graph={GRAPHS.STANDARD_WEEK}
                      flexibleHeight
                      linesClickable
                      showChangeGraphToggle
                    />
                  </RenderIf>
                  <RenderIf validate={graphType !== GRAPH_TYPE_DETAILS}>
                    <StandardWeekTrendContainer
                      graph={GRAPHS.STANDARD_WEEK}
                      flexibleHeight
                      showChangeGraphToggle
                    />
                  </RenderIf>
                </GraphSettingsWrapper>,
              ),
            )}
          />
          <Route
            path={diagnosticsLinks.bloodGlucoseGeneralStats}
            render={(routerProps) => {
              return goTo(bgId.replace(':id', routerProps.match.params.id));
            }}
          ></Route>
          <ProtectedRoute
            hasPermissions={[PERMISSIONS.USER_SHOW_GRAPHIC_CONFIG]}
            path={diagnosticsLinks.deviceSettings}
            component={() =>
              renderGraph(
                <DeviceSettingsContainer graph={GRAPHS.DEVICE_SETTINGS} />,
              )
            }
          />
        </GraphPageWrapper>
      </RouterOutlet>
    </Switch>
  );
};

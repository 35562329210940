import React from 'react';
import styles from './styles.module.css';

import { JelloIcon } from 'src/shared/design-system/jello-icon/jello-icon.component';
import { WaffleMenuItemProps } from 'src/widgets/top-navigation-jello/top-navigation-jello.types';
import {
  JELLO_ICON_SIZES,
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
} from 'src/app/app.jello.constants';
import { WAFFLE_MENU_ITEM_ID } from '../../top-navigation-jello.constants';
import { testId } from '@roche/roche-common';

export const WaffleMenuItemComponent: React.FunctionComponent<
  WaffleMenuItemProps
> = ({ id, iconName, linkText, description }) => (
  <div className={styles.wrapper} {...testId(WAFFLE_MENU_ITEM_ID, id)}>
    <JelloIcon
      iconName={iconName}
      size={JELLO_ICON_SIZES.XS}
      color={'var(--jello-color-foreground-highlight-base)'}
    />
    <div className={styles.wrapperText}>
      <jello-text
        class={styles.listItemText}
        weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
        size={JELLO_TEXT_SIZES.S}
      >
        {linkText}
      </jello-text>
      <jello-text
        class={styles.listItemDescription}
        weight={JELLO_TEXT_WEIGHTS.REGULAR}
        size={JELLO_TEXT_SIZES.S}
      >
        {description}
      </jello-text>
    </div>
  </div>
);

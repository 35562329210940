import {
  createAuthHeader,
  getJSON,
} from '../../../../../shared/utils/service/service.utils';
import { getConfig } from '@roche/roche-common';
import { equals, reject } from 'ramda';

import {
  GetINSIdentityLoaderImplType,
  GetINSIdentityServiceImplType,
  GetINSIdentityTransformImplType,
} from './get-INS-identity.types';

const { REACT_APP_API_MULE } = getConfig();

export const GetINSIdentityLoaderImpl: GetINSIdentityLoaderImplType = (openId: {
  accessToken: string;
  apiKey: string;
}) => {
  const headers = {
    Authorization: createAuthHeader(openId.accessToken),
    ApiKey: openId.apiKey,
  };
  return getJSON(`${REACT_APP_API_MULE}/PUT_THE_CORRECT_ENDPOINT_HERE`, {
    headers: reject(equals({}))(headers),
  });
};

export const GetINSIdentityTransformImpl: GetINSIdentityTransformImplType = (
  result,
) => {
  const { user } = result;
  return {
    code: result.code,
    OID: user.OID,
    insNumber: user.insNumber,
    firstNameAtBirth: user.name,
    lastNameAtBirth: user.surname,
    sexAtBirth: user.gender,
    birthDate: user.birthday,
    birthPlaceCode: user.birthPlaceCode,
    auditId: result.auditId,
  };
};

export const GetINSIdentityServiceImpl: GetINSIdentityServiceImplType =
  (load: GetINSIdentityLoaderImplType, transform) => (token, query) =>
    load(token, query).then(transform);

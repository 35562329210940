import * as React from 'react';
import { XIcon } from '../../../../../shared/design-system/icons';
import { Block } from '../../../../../shared/design-system/block/block.component';
import { Button } from '../../../../../shared/design-system/button/button.component';
import { LocalizedText } from '../../../../../shared/design-system/localized-text/localized-text.component';
import { SuccessBadge } from '../../../../../shared/design-system/badge/success-badge/success-badge.component';
import { ButtonReset } from '../../../../../app/styles/resets';
import { colors } from '../../../../../app/styles/colors';
import { translate } from '../../../../../shared/utils/i18n';
import {
  CommonHeader,
  ModalBody,
} from '../../../../../widgets/modal/modal.style';

export const EditProfileSuccessModal = ({ destroyModal }) => (
  <ModalBody>
    <CommonHeader>
      <strong>
        <LocalizedText fontSize={4} textKey="profile.successModal.title" />
      </strong>
      <ButtonReset onClick={() => destroyModal()}>
        <XIcon height={14} fillColor={colors.white} />
      </ButtonReset>
    </CommonHeader>
    <Block py={2} px={6}>
      <Block p={4}>
        <Block pb={2} mb={3} display="flex" justifyContent="center">
          <SuccessBadge size={75} fillColor={colors.brandBlue} />
        </Block>
        <Block pb={3} mb={4} display="flex" justifyContent="center">
          <strong>
            <LocalizedText textKey="profile.successModal.description" />
          </strong>
        </Block>
        <Block display="flex" justifyContent="center">
          <Button
            autoFocus
            label={translate('profile.successModal.confirm')}
            onClick={() => {
              destroyModal();
              window.location.reload();
            }}
          />
        </Block>
      </Block>
    </Block>
  </ModalBody>
);

import { createAuthHeader } from 'src/shared/utils/auth-token';
import { getJSON } from 'src/shared/utils/loaders';

import { frequencyToString } from './frequencies.utils';

import { ENDPOINTS } from 'src/app/navigation/services/service.constants';

export const FrequenciesLoaderImpl = ({ centerId }, token) =>
  getJSON(
    ENDPOINTS.frequencies,
    { centerId },
    {
      Authorization: createAuthHeader(token),
    },
  );

export const FrequenciesTransformImpl = (results) =>
  results.map(({ id, name, frequency }) => ({
    id: frequencyToString(frequency),
    name,
  }));

export const FrequenciesServiceImpl = (load, transform) => (query, token) =>
  load(query, token).then(transform);

import React from 'react';

import { ToolTipContainerDiv } from './tool-tip.style';

import { Portal } from 'src/shared/design-system/portal/portal.component';

export const ToolTip = ({ x, y, children }) => (
  <Portal rootId="tool-tip-root">
    <ToolTipContainerDiv x={x} y={y} origin={children.props.origin}>
      {children}
    </ToolTipContainerDiv>
  </Portal>
);

import { ProfessionalProfileType } from 'src/domains/professional/services/professionals/get-professionals/get-professionals.types';
import {
  constructBirthDayDropdownOptions,
  constructBirthYearDropdownOptions,
  constructGestationalYearDropdownOptions,
} from 'src/widgets/forms/utils/form-data.utils';

const CURRENT_YEAR = new Date().getFullYear();
const DAYS_TO_DISPLAY = 31;
const YEARS_TO_DISPLAY = 120;

export const FORM_OPTION_DAYS =
  constructBirthDayDropdownOptions(DAYS_TO_DISPLAY);

export const FORM_OPTION_YEARS = constructBirthYearDropdownOptions(
  YEARS_TO_DISPLAY,
  CURRENT_YEAR,
);

export const PROFESSIONAL_ROLES = [
  {
    label: 'professional.administrative',
    value: 'ADMINISTRATIVE',
  },
  {
    label: 'professional.generalPractitioner',
    value: 'GENERAL_PRACTITIONER',
  },
  {
    label: 'professional.healthcareProfessional',
    value: 'HEALTHCARE_PROFESSIONAL',
  },
];

export const FORM_OPTION_GESTATIONAL_YEARS =
  constructGestationalYearDropdownOptions(CURRENT_YEAR);

export const FORM_OPTION_MONTHS = [
  { label: 'general.date.jan', value: '1' },
  { label: 'general.date.feb', value: '2' },
  { label: 'general.date.mar', value: '3' },
  { label: 'general.date.apr', value: '4' },
  { label: 'general.date.may', value: '5' },
  { label: 'general.date.jun', value: '6' },
  { label: 'general.date.jul', value: '7' },
  { label: 'general.date.aug', value: '8' },
  { label: 'general.date.sep', value: '9' },
  { label: 'general.date.oct', value: '10' },
  { label: 'general.date.nov', value: '11' },
  { label: 'general.date.dec', value: '12' },
];

export const PROFESSIONAL_PROFILES = {
  HCP_MASTER: 'HCP_MASTER' as ProfessionalProfileType,
  GENERAL_PRACTITIONER: 'GENERAL_PRACTITIONER' as ProfessionalProfileType,
  ADMINISTRATIVE: 'ADMINISTRATIVE' as ProfessionalProfileType,
  PAYER: 'PAYER' as ProfessionalProfileType,
  HEALTHCARE_PROFESSIONAL: 'HEALTHCARE_PROFESSIONAL' as ProfessionalProfileType,
};

export const EDIT_MODAL_TITLES = {
  WARN: 'connectionError',
  ERR: 'title',
};

export const RDAC_INVITATION = {
  promptText: 'prompt',
  detailText: 'detail',
  actionText: 'action',
  inviteSuccess: 'inviteToastSuccessTitle',
};

import React from 'react';

import { withTranslation } from 'src/shared/utils/i18n/with-translation';

import { Item, SVGContainer } from './legend-item.style';

export const LegendItemComponent = ({ color, icon, label, t }) => (
  <Item color={color}>
    <SVGContainer>{icon}</SVGContainer>
    {t(label)}
  </Item>
);

export const LegendItem = withTranslation(LegendItemComponent);

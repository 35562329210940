import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';

import { transformPrescriptionReasons } from './prescription-reasons.util';

import {
  ENDPOINTS,
  endpointWithParams,
} from 'src/app/navigation/services/service.constants';

export const PrescriptionReasonsLoaderImpl = ({ patientId }, accessToken) =>
  getJSON(endpointWithParams(ENDPOINTS.prescriptionReasons, { patientId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  });

export const PrescriptionReasonsTransform = (data) =>
  transformPrescriptionReasons(data);

export const PrescriptionReasonsServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);

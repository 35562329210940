import styled, { StyledComponentClass } from 'styled-components';
import { ThemeInterface } from '../../../../shared/theme';

const textDisabled = ({ theme }) => theme.colors.grayLight;

interface DropdownCalendarContainerProps {
  width?: string;
  flex?: string;
}

export const DropdownButtonContainer: StyledComponentClass<
  DropdownCalendarContainerProps,
  ThemeInterface
> = styled<DropdownCalendarContainerProps, 'div'>('div')`
  display: flex;
  flex-direction: row;
  position: relative;
  flex: ${({ flex }) => (flex ? flex : '')};
  width: ${({ width }) => (width ? width : '')};
`;
DropdownButtonContainer.displayName = 'DropdownCalendarContainer';

interface DropdownCalendarButtonProps {
  onClick?: (e: MouseEvent) => void;
  className?: string;
}

export const DropdownBtn: StyledComponentClass<
  DropdownCalendarButtonProps,
  ThemeInterface
> = styled<DropdownCalendarButtonProps, 'div'>('div')`
  box-sizing: border-box;
  height: 40px;
  width: 30px;
  border: 1px solid ${(props) => props.theme.colors.grayLighter};
  background-color: ${(props) => props.theme.colors.blueMarineAlpha};
  color: ${(props) => props.theme.colors.brandBlue};
  cursor: pointer;

  &.is-disabled {
    pointer-events: none;
    background-color: ${(props) => props.theme.colors.silverLight};
    stroke: ${textDisabled};
  }

  &.is-left {
    border-radius: 3px 0 0 3px;
    border-right: 0;
  }

  &.is-right {
    border-radius: 0 3px 3px 0;
    border-left: 0;
  }
`;
DropdownBtn.displayName = 'DropdownCalendarButton';

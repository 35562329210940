import { SessionActionType } from 'src/app/session/core/session/session.types';
import { OidcActionTypes } from 'src/app/session/core/oidc/oidc.types';
import { customDatadogLog, DatadogType } from 'src/app/logger/utils';
import { LOGS } from './constants';

export const SESSION_TYPES = [
  SessionActionType.VALIDATE_SESSION_START,
  SessionActionType.VALIDATE_SESSION_SUCCESS,
  SessionActionType.VALIDATE_SESSION_ERROR,
  SessionActionType.SIGN_OUT_START,
  SessionActionType.SIGN_OUT_SUCCESS,
  SessionActionType.SIGN_OUT_ERROR,
  SessionActionType.LOGIN_REDIRECT,
  SessionActionType.GET_CURRENT_USER_START,
  SessionActionType.GET_CURRENT_USER_SUCCESS,
  SessionActionType.GET_CURRENT_USER_ERROR,
  SessionActionType.SHOW_EXPIRING_MODAL,
  OidcActionTypes.OIDC_FETCH_TOKENS_START,
  OidcActionTypes.OIDC_FETCH_TOKENS_SUCCESS,
  OidcActionTypes.OIDC_FETCH_TOKENS_ERROR,
  OidcActionTypes.REFRESH_OIDC_TOKENS_START,
  OidcActionTypes.REFRESH_OIDC_TOKENS_SUCCESS,
  OidcActionTypes.REFRESH_OIDC_TOKENS_ERROR,
];

export const dataDogEvents = (action = { type: '' }) => {
  const t = action.type;
  const sessionType = SESSION_TYPES.some((e) => e === t);
  const errorType = t.toUpperCase().includes('ERROR');
  const _t = t.replace(/_/g, ' ').toLowerCase();

  const logIt = (type: DatadogType) => {
    try {
      customDatadogLog(type, _t[0].toUpperCase() + _t.slice(1) + '.', {
        ...action,
      });
    } catch (error) {
      /* istanbul ignore next */
      /* eslint-disable no-console */
      const { log } = console;
      log(LOGS.session + LOGS.errorLogging);
    }
  };

  if (sessionType) {
    logIt('session');
  } else if (errorType) {
    logIt('errors');
  }
};

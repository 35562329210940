import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { mapDispatchers } from 'src/domains/diagnostics/utils/map-dispatchers';
import {
  selectDeviceSettingsType,
  selectGraph,
  selectGraphType,
  selectLogbookType,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import {
  changeDeviceSettingsType,
  changeGraphType,
  changeLogbookType,
} from 'src/domains/patient-dashboards/bg/store/bg.actions';

import { ChangeGraphTypeButtons } from './graph-type-buttons.component';

export const graphTypeConnector = createStructuredSelector({
  graph: selectGraph,
  graphType: selectGraphType,
  logbookType: selectLogbookType,
  deviceSettingsType: selectDeviceSettingsType,
});

const graphTypeDispatchers = mapDispatchers({
  // @ts-ignore
  changeGraphType,
  changeLogbookType,
  changeDeviceSettingsType,
});

export const ChangeGraphTypeButtonsContainer = connect(
  graphTypeConnector,
  graphTypeDispatchers,
)(ChangeGraphTypeButtons);

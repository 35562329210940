import {
  createAuthHeader,
  getJSON,
} from 'src/shared/utils/service/service.utils';
import { ENDPOINTS } from 'src/app/navigation/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const FetchAssignedDemoPatientProfilesLoaderImpl = (
  { patientId },
  accessToken: string,
) => {
  return getJSON(
    endpointWithParams(ENDPOINTS.fetchAssignedDemoPatientProfiles, {
      patientId,
    }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );
};

export const FetchAssignedDemoPatientProfilesServiceImpl =
  (load) => (query: any, accessToken: string, gigyaToken: string) => {
    return load(query, accessToken, gigyaToken).then((data) => data);
  };

import { createAction, createPayloadAction } from 'src/app/store/app.actions';
import {
  ClearPairingCodeCorrelationState,
  CorrelatePairingCodeActionType,
  CorrelatePairingCodeErrorAction,
  CorrelatePairingCodeErrorConflictAction,
  CorrelatePairingCodeErrorConflictPayload,
  CorrelatePairingCodeErrorPayload,
  CorrelatePairingCodePayloadType,
  CorrelatePairingCodeStartAction,
  CorrelatePairingCodeSuccessAction,
  CorrelatePairingCodeSuccessPayload,
} from './correlate-pairing-code.types';

export const correlatePairingCodeStart = (
  payload: CorrelatePairingCodePayloadType,
): CorrelatePairingCodeStartAction =>
  createPayloadAction(
    CorrelatePairingCodeActionType.CORRELATE_PAIRING_CODE_START,
    payload,
  );

export const correlatePairingCodeSuccess = (
  payload: CorrelatePairingCodeSuccessPayload,
): CorrelatePairingCodeSuccessAction =>
  createPayloadAction(
    CorrelatePairingCodeActionType.CORRELATE_PAIRING_CODE_SUCCESS,
    payload,
  );

export const correlatePairingCodeError = (
  payload: CorrelatePairingCodeErrorPayload,
): CorrelatePairingCodeErrorAction =>
  createPayloadAction(
    CorrelatePairingCodeActionType.CORRELATE_PAIRING_CODE_ERROR,
    payload,
  );

export const correlatePairingCodeErrorConflict = (
  payload: CorrelatePairingCodeErrorConflictPayload,
): CorrelatePairingCodeErrorConflictAction =>
  createPayloadAction(
    CorrelatePairingCodeActionType.CORRELATE_PAIRING_CODE_ERROR_CONFLICT,
    payload,
  );

export const clearCorrelationState = (): ClearPairingCodeCorrelationState =>
  createAction(
    CorrelatePairingCodeActionType.CLEAR_PAIRING_CODE_CORRELATION_STATE,
  );

import { combineEpics } from 'redux-observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { oidcEpics } from 'src/app/session/core/oidc/oidc.epics';
import { sessionEpics } from 'src/app/session/core/session/session.epics';
import { generatePatientReportsEpic } from 'src/domains/patient-dashboard/store/patient-reports/patient-reports.epic';
import { continuousMonitoringEpics } from 'src/domains/patient-dashboards/cgm/store/cgm.epics';
import {
  exportPatientsSessionEpic,
  savePatientSessionEndEpic,
  startPatientSessionEpic,
} from 'src/app/navigation/store/patient-session/patient-session.epics';
import {
  logoutEpic,
  oidcLoginErrorEpic,
  reloadAfterLogoutEpic,
  retryLoginEpic,
} from 'src/shared/design-system/modal/store/login-system-error/login-system-error.epics';
import { pairingCodeIdentityStatusEpic } from 'src/domains/patient/scenes/create-patient/create-patient-with-platform/store/pairing-code-identity-status/pairing-code-identity-status.epic';
import { checkDataSharingConsentEpic } from 'src/domains/patient-dashboards/bg/store/check-data-sharing-consent/check-data-sharing-consent.epic';

import {
  createPatientStatusNotificationModalEpic,
  createSendPatientStatusModalEpic,
  getDeliveryEpic,
  getDeliveryHistoryEpic,
  getDeliveryStatusEpic,
  getLastDeliveryStatusEpic,
  getThresholdsEpic,
  getTimeIntervalsEpic,
  onDeliveryStatusChangeFetchStripStockEpic,
  saveDeliveryEpic,
  saveDeliveryStatusCommentEpic,
  setDeliveryStatusEpic,
  updateLastDeliveryStatusEpic,
} from 'src/domains/strip-management/store/strip-delivery/strip-delivery.epics';
import {
  fetchCurrentPatientErrorEpic,
  fetchPatientEpic,
  patientChangeEpic,
} from 'src/domains/patient/store/patient/patient.epic';
import {
  fetchPatientDateRangeEpic,
  getClinicalDataOnDatesChangeEpic,
} from 'src/domains/patient-dashboard/store/patient-date-range/patient-date-range.epic';
import {
  getPatientStockEpic,
  submitLostStripsEpic,
  submitManualDeliveryEpic,
} from 'src/domains/strip-management/store/patient-stock/patient-stock.epics';
import { getPatientSearchEpic } from 'src/domains/device-assignment/components/patient-search/store/patient-search.epics';
import {
  addPrescriptionEntryEpic,
  fetchTemporaryPrescriptionReasonsEpic,
  getCurrentPrescriptionEpic,
  initializePrescriptionEpic,
  onClinicGuidesUpdateEpic,
  onPatientChangeResetPrescriptionEpic,
  onPrescriptionSaveFetchLatestEpic,
  savePrescriptionEpic,
  setCurrentPrescriptionsEpic,
} from 'src/domains/strip-management/store/prescription/prescription.epics';
import {
  createUnsavedPrescriptionEntryEpic,
  getStripModelsEpic,
} from 'src/domains/strip-management/store/prescription/strip-model/strip-model.epic';
import {
  deleteClinicGuideEpic,
  getClinicGuidesEpic,
  onSaveClinicGuideEpic,
  refreshClinicGuidesEpic,
  saveClinicGuideEpic,
} from 'src/domains/strip-management/store/prescription/clinic-guide/clinic-guide.epics';
import { getFrequenciesEpic } from 'src/domains/strip-management/store/prescription/frequencies/frequencies.epics';
import { getTherapiesEpic } from 'src/domains/strip-management/store/prescription/therapy/therapy.epics';
import {
  clearOrganizationStateEpic,
  clearPatientStateEpic,
} from 'src/app/store/state/state.epics';
import {
  getAlertsEpic,
  saveAlertsEpic,
} from 'src/domains/strip-management/store/alerts/alerts.epics';
import {
  addOrgStockEpic,
  fetchOrgStockEpic,
} from 'src/domains/strip-management/scenes/org-stock/store/org-stock/org-stock.epic';
import { changeLanguageEpic, userEpic } from 'src/app/store/user/user.epics';
import {
  createLoadingModalEpic,
  errorLoadingModalEpic,
  modalDomSideEffectsEpics,
  removeLoadingModalEpic,
  successLoadingModalEpic,
  successRequiresConfirmationModalEpic,
} from 'src/shared/design-system/modal/store/modal/modal.epics';
import {
  getAlreadyAssignedPatientEpic,
  getDeviceAssociationEpic,
  redirectAfterDeviceAssignmentCancelEpic,
  redirectAfterDeviceAssignmentDoneEpic,
  resetFeatureStateAfterCancelEpic,
  updateAssociationOnConfirmEpic,
  updateDeviceAssociationEpic,
} from 'src/domains/device-assignment/store/device-assignment/device-assignment.epics';
import {
  checkAssociatedProfessionalsExistOnEditPatientEpic,
  editEMRPatientEpic,
  editPatientEpic,
  editPatientWithFhirEpic,
  fetchPatientForEditEpic,
  fetchPatientPermissionsEpic,
  updateAllowPatientAccessOnProfileEditEpic,
  updateProfileTypeOnFetchPatientSuccessEpic,
} from 'src/domains/patient/scenes/edit-patient/store/edit-patient.epics';
import { getProfessionalsEpic } from 'src/domains/patient/store/department/department.epics';
import { fetchConfigurablePermissionsEpic } from 'src/domains/patient/store/configurables/configurables.epics';
import {
  fetchPatientDevicesEpic,
  removePatientDeviceEpic,
} from 'src/domains/patient/scenes/list-devices/store/list-devices.epics';
import {
  mysugrResetInviteEpic,
  mysugrSendInviteEpic,
  mysugrStatusEpic,
} from 'src/shared/design-system/forms/components/mysugr-status/store/mysugr.epics';
import { fetchDeviceSettingsListEpic } from 'src/domains/diagnostics/scenes/device-settings-jello/store/device-settings-list/device-settings-list.epic';
import {
  fetchAllDeviceSettingsDataEpic,
  fetchDeviceSettingsEpic,
} from 'src/domains/diagnostics/scenes/device-settings-jello/store/device-settings/device-settings.epic';

import { PatientStockFactoryImpl } from '../domains/strip-management/services/patient-stock/get-patient-stock/get-patient-stock.factory';
import { PatientListFactoryImpl } from '../domains/patient/services/patient-list/patient-list.factory';
import { PatientFactoryImpl } from '../domains/patient/services/patient/patient.factory';
import { DateRangeFactoryImpl } from '../domains/patient-dashboards/bg/services/date-range/patient-date-range.factory';
import { ClinicalDataFactoryImpl } from '../domains/patient-dashboards/bg/services/clinical-data/clinical-data.factory';
import { ClinicalDataFromFhirFactoryImpl } from '../domains/patient-dashboards/bg/services/clinical-data-from-fhir/clinical-data-from-fhir.factory';
import { TimeIntervalFactoryImpl } from './services/time-interval/time-interval.factory';
import { ThresholdFactoryImpl } from '../domains/strip-management/services/threshold/threshold.factory';
import { GetDeliveryStatusFactoryImpl } from '../domains/strip-management/services/delivery-status/get-delivery-status/get-delivery-status.factory';
import { GetAlertsFactoryImpl } from '../domains/strip-management/services/alerts/get-alerts/get-alerts.factory';
import { SaveAlertsFactoryImpl } from '../domains/strip-management/services/alerts/save-alerts/save-alerts.factory';
import { TherapiesFactoryImpl } from '../domains/strip-management/services/therapies/therapies.factory';
import { FrequenciesFactoryImpl } from '../domains/strip-management/frequencies.factory';
import { PatientSearchFactoryImpl } from '../domains/device-assignment/services/patient-search/patient-search.factory';
import { GetPrescriptionFactoryImpl } from '../domains/strip-management/services/prescription/get-prescription/get-prescription.factory';
import { SavePrescriptionFactoryImpl } from '../domains/strip-management/services/prescription/save-prescription/save-prescription.factory';
import { SaveDeliveryFactoryImpl } from '../domains/strip-management/services/delivery/save-delivery/save-delivery.factory';
import { SetDeliveryStatusFactoryImpl } from '../domains/strip-management/services/delivery-status/set-delivery-status/set-delivery-status.factory';
import { GetDeliveryFactoryImpl } from '../domains/strip-management/services/delivery/get-delivery/get-delivery.factory';
import { CurrentUserFactoryImpl } from './services/current-user/current-user.factory';
import { GetClinicGuidesFactoryImpl } from '../domains/strip-management/services/clinic-guides/get-clinic-guides/get-clinic-guides.factory';
import { SaveClinicGuideFactoryImpl } from '../domains/strip-management/services/clinic-guides/save-clinic-guide/save-clinic-guide.factory';
import { DeleteClinicGuideFactoryImpl } from '../domains/strip-management/services/clinic-guides/delete-clinic-guide/delete-clinic-guide.factory';
import { StripModelsFactoryImpl } from '../domains/strip-management/strip-models/strip-models.factory';
import { LostStripsFactoryImpl } from '../domains/strip-management/services/patient-stock/lost-strips/lost-strips.factory';
import { ManualDeliveryFactoryImpl } from '../domains/strip-management/services/patient-stock/manual-delivery/manual-delivery.factory';
import { PrescriptionReasonsFactoryImpl } from '../domains/strip-management/services/prescription-reasons/prescription-reasons.factory';
import { GetOrgStockFactoryImpl } from '../domains/strip-management/services/org-stock/get-org-stock/get-org-stock.factory';
import { PermissionsFactory } from '../domains/permissions/services/permissions/permissions.factory';
import { AddOrgStockFactoryImpl } from '../domains/strip-management/services/org-stock/add-org-stock/add-org-stock.factory';
import { SaveDeliveryStatusCommentFactoryImpl } from '../domains/strip-management/services/delivery-status/save-delivery-status-comment/save-delivery-status-comment.factory';
import { GetDeviceAssociationFactoryImpl } from '../domains/device-assignment/services/device-assignment/get-device-association/get-device-association.factory';
import { UpdateDeviceAssociationFactoryImpl } from '../domains/device-assignment/services/device-assignment/update-device-association/update-device-association.factory';
import { ProfileTypesFactory } from '../domains/patient/services/department/profile-types/profile-types.factory';
import { GetActiveSystemLanguagesFactoryImpl } from './services/languages/active-system-languages/active-system-languages.factory';
import { GetPatientInfoFactoryImpl } from '../domains/patient/services/patient/patient-info/patient-info.factory';
import { FetchProfessionalFactoryImpl } from '../domains/professional/services/professionals/get-professionals/get-professionals.factory';
import { GetLatestPatientsFactoryImpl } from '../domains/device-assignment/services/get-latest-patients/get-latest-patients.factory';
import { GetLatestPatientsSimpleTransformImpl } from '../domains/device-assignment/services/get-latest-patients/get-latest-patients.service';
import { EditPatientFactoryImpl } from '../domains/patient/services/patient/edit-patient/edit-patient.factory';
import { EditEMRPatientFactoryImpl } from '../domains/patient/services/patient/edit-emr-patient/edit-emr-patient.factory';
import { FetchConfigurablesFactoryImpl } from '../domains/patient/services/configurables/configurables.factory';
import { ListDevicesFactoryImpl } from '../domains/patient/services/patient/list-devices/list-devices.factory';
import { RemoveDeviceFactoryImpl } from '../domains/patient/services/patient/remove-device/remove-device.factory';
import { DeactivatePatientFactoryImpl } from '../domains/patient/services/patient/deactivate-patient/deactivate-patient.factory';
import { NextShipmentFetchFactoryImpl } from '../domains/patient/services/next-shipment/next-shipment-fetch/next-shipment-fetch.factory';
import { NextShipmentChangeStatusFactoryImpl } from '../domains/patient/services/next-shipment/next-shipment-change-status/next-shipment-change-status.factory';
import { NextShipmentChangeDateFactoryImpl } from '../domains/patient/services/next-shipment/next-shipment-change-date/next-shipment-change-date.factory';
import { GetTimeBlocksFactoryImpl } from '../domains/patient/services/patient/time-blocks/get-time-blocks/time-blocks.factory';
import {
  GetDeliveryConfigurationFactoryImpl,
  GetPatientDeliveryConfigurationFactoryImpl,
} from '../domains/patient/services/patient/delivery-configuration/get-delivery-configuration/delivery-configuration.factory';
import { PostDeliveryConfigurationFactoryImpl } from '../domains/patient/services/patient/delivery-configuration/post-delivery-configuration/post-delivery-configuration.factory';
import { PostTimeBlocksFactoryImpl } from '../domains/patient/services/patient/time-blocks/post-time-blocks/post-time-blocks.factory';
import { MysugrStatusFactoryImpl } from './services/mysugr-status/mysugr-status.factory';
import { MysugrSendInviteFactoryImpl } from './services/mysugr-send-invite/mysugr-send-invite.factory';
import { StockAdjustmentsFactoryImpl } from '../domains/strip-management/services/patient-stock/stock-adjustments/stock-adjustments.factory';
import { GetTargetRangesFetchFactoryImpl } from '../domains/patient/services/target-ranges/get-target-ranges/get-target-ranges.factory';
import { SaveTargetRangesFetchFactoryImpl } from '../domains/patient/services/target-ranges/save-target-ranges/save-target-ranges.factory';
import { GetHba1cCardFactoryImpl } from '../domains/diagnostics/services/hba1c-card/hba1c-card.factory';
import { GetHba1cListFactoryImpl } from '../domains/diagnostics/services/hba1c-list-values/hba1c-list.factory';
import { PostNewEntryHba1cFactoryImpl } from '../domains/diagnostics/services/new-entry/hba1c/new-entry-hba1c.factory';
import { ClinicalStatisticsFactoryImpl } from '../domains/general/services/clinical-statistics/clinical-statistics.factory';
import { DeviceSettingsListFactoryImpl } from '../domains/diagnostics/services/device-settings-list/device-settings-list.factory';
import { DeviceSettingsFactoryImpl } from '../domains/diagnostics/scenes/device-settings-jello/services/device-settings/device-settings.factory';
import { PatientReportsFactoryImpl } from '../domains/patient-dashboard/patient-reports/patient-reports.factory';
import {
  GetRpmSettingsFactoryImpl,
  UpdateRpmSettingsFactoryImpl,
} from '../domains/rpm/services/rpm/rpm-settings/rpm-settings.factory';
import { PatientSessionEndFactoryImpl } from './navigation/services/patient-session/patient-session-end/patient-session-end.factory';
import { PatientsSessionExportFactoryImpl } from './navigation/services/patients-session-export/patients-session-export.factory';
import { PatientSessionStartFactoryImpl } from './navigation/services/patient-session/patient-session-start/patient-session-start.factory';
import { CheckExternalPatientFactoryImpl } from '../domains/patient/services/patient/check-external-patient/check-external-patient.factory';
import { SaveUserUnitsFactoryImpl } from '../domains/patient/services/user-units/save-user-units/save-user-units.factory';
import { GetUserUnitsFactoryImpl } from '../domains/patient/services/user-units/get-user-units/get-user-units.factory';
import { GetINSIdentityFetchFactoryImpl } from '../domains/patient/services/INS-identity/get-INS-identity/get-INS-identity.factory';
import { CheckPairingCodeFactoryImp } from '../domains/patient/services/pairing-code/pairing-code-check/pairing-code-check.factory';
import { CorrelatePairingCodeFactoryImp } from '../domains/patient/services/pairing-code/pairing-code-correlation/pairing-code-correlation.factory';
import { EditPatientWithFhirFactoryImpl } from '../domains/patient/services/patient/edit-patient-with-fhir/edit-patient-with-fhir.factory';
import { InvitePatientFactoryImp } from '../domains/patient/services/rdac/invite-patient/invite-patient.factory';
import { CheckPairingCodeAvailabilityFactoryImpl } from '../domains/patient/services/pairing-code/pairing-code-availability/pairing-code-availability.factory';
import { CheckEmailFactoryImpl } from '../domains/patient/services/check-email/check-email.factory';
import { CheckHCIDFactoryImpl } from '../domains/patient/services/check-hcid/check-hcid.factory';
import { correlatePairingCodeEpic } from 'src/domains/patient/scenes/create-patient/create-patient-with-platform/store/correlate-pairing-code/correlate-pairing-code.epics';
import { invitePatientEpic } from 'src/domains/patient/scenes/create-patient/create-patient-with-platform/store/invite-patient/invite-patient.epics.ts';
import { getPatientsListEpic } from '../domains/patient/scenes/patients/store/patients.epic';
import {
  fetchBGOverviewClinicalDataEpic,
  fetchBGOverviewClinicalDataFromFhirEpic,
  fetchBGOverviewEndDateEpic,
  fetchCheckExternalPatientEpic,
  getClinicalDataEpic,
  getClinicalDataFromFhirEpic,
  getClinicalDataOnBGOverviewEndDateEpic,
  getClinicalDataOnDateRangeEpic,
  setClinicalDataEpic,
} from '../domains/patient-dashboards/bg/store/bg.epics';
import {
  patientPermissionsEpic,
  permissionsEpic,
} from '../domains/permissions/store/permissions.epics';
import { GetLastDeliveryStatusFactoryImpl } from '../domains/strip-management/services/delivery-status/get-last-delivery-status/get-last-delivery-status.factory';
import { CreatePatientFactoryImpl } from '../domains/patient/services/patient/create-patient/create-patient.factory';
import { CreatePatientWithFhirFactoryImpl } from '../domains/patient/services/patient/create-patient-with-fhir/create-patient-with-fhir.factory';
import { CreateProfessionalFactoryImpl } from '../domains/professional/services/professionals/create-professional/create-professional.factory';
import { EditProfileFactoryImpl } from '../domains/profile/services/profile/edit-profile.factory';
import { EditEMRProfileFactoryImpl } from '../domains/profile/services/profile-emr/edit-emr-profile.factory';
import {
  createPatientEpic,
  createPatientWithFhirEpic,
  getCreatedPatientEpic,
  populateCreatePatientLanguageEpic,
  populateCreatePatientProfessionalsEpic,
  setDefaultHCPWhenCreatingPatientEpic,
  setDefaultLanguageWhenCreatingPatientEpic,
  setPatientCountryFromDepartmentEpic,
  updateAllowPatientAccessOnProfileChangeEpic,
  updateCreatePatient,
  updateCreatePatientHealthInfo,
} from '../domains/patient/scenes/create-patient/store/create-patient.epics';
import {
  createProfessionalEpic,
  populateCreateProfessionalLanguageEpic,
  setDefaultLanguageWhenCreatingProfessionalEpic,
  setOpenConnectivityWhenCreatingProfessionalEpic,
} from 'src/domains/professional/scenes/create-professional/store/create-professional.epics';
import {
  editEMRProfileEpic,
  editProfileEpic,
} from 'src/domains/profile/scenes/edit-profile/store/edit-profile.epics';
import { generalLinks } from '../domains/general/routes';
import { getDepartmentProfileTypesEpic } from '../domains/patient/store/department/department.epics';
import { countryService } from '../domains/patient/services/countries/country.service';
import { countriesEpic } from '../domains/patient/store/countries/countries.epics';
import { fetchLanguagesEpic } from '../domains/patient/store/languages/languages.epics';
import {
  getLatestPatientsAfterPatientCreatedEpic,
  getLatestPatientsEpic,
} from '../domains/device-assignment/store/latest-patients/latest-patients.epics';
import { deactivatePatientEpic } from '../domains/patient/scenes/deactivate-patient/store/deactivate-patient.epics';
import {
  changeDateNextShipmentEpic,
  changeStatusNextShipmentEpic,
  fetchNextShipmentEpic,
} from '../domains/patient/scenes/next-shipment/store/next-shipment.epics';
import {
  fetchPatientTimeBlocksEpic,
  savePatientTimeBlocksEpic,
} from '../domains/patient/scenes/time-periods/store/time-periods.epics';
import {
  fetchDeliveryConfigurationEpic,
  saveDeliveryConfigurationEpic,
} from '../domains/patient/scenes/delivery-configuration/store/delivery-configuration.epics';
import { fetchHba1cCardEpics } from '../domains/diagnostics/widgets/hba1c-card/store/hba1c-card.epics';
import { fetchHba1cListEpics } from '../domains/diagnostics/widgets/hba1c-modal/components/list-values-hba1c/store/list-values-hba1c.epics';
import { expiringModalEpics } from './session/core/expiring-modal/expiring-modal.epics';
import { saveNewEntryHba1cEpic } from '../domains/diagnostics/widgets/hba1c-modal/components/new-entry-hba1c/store/new-entry-hba1c.epics';
import { fetchPatientsEpic } from '../domains/general/widgets/store/hcp-dashboard.epics';
import {
  fetchRpmSettingsEpic,
  updateRpmSettingsEpic,
} from '../domains/rpm/rpm-settings/store/rpm-settings.epics';
import {
  getUserUnitsEpic,
  saveUserUnitsEpic,
} from '../widgets/user-units-selector/store/user-units-selector.epics';
import { getINSIdentityEpic } from '../domains/patient/store/INS-patient/INS-identity.epic';
import {
  getTargetRangesEpic,
  saveTargetRangesEpic,
} from '../widgets/target-ranges/store/target-ranges.epics';
import { checkPairingCodeEpic } from '../domains/patient/scenes/create-patient/create-patient-with-platform/store/check-pairing-code/check-pairing-code.epics';
import { pairingCodeIdentityStatusFactoryImp } from 'src/domains/patient/services/pairing-code/pairing-code-identity-status/pairing-code-identity-status.factory';
import { CheckDataSharingConsentFactoryImp } from 'src/domains/patient/services/patient/check-data-sharing-consent/check-data-sharing-consent.factory';
import {
  checkIsSharingCodeAvailableEpic,
  connectSharingCodeEpic,
} from 'src/domains/patient/scenes/create-edit-form-jello/store/connect-sharing-code/connect-sharing-code.epic';
import { sendInvitationtEpic } from 'src/domains/patient/scenes/create-edit-form-jello/store/send-invitation/send-invitation.epic';
import { checkDuplicatedEmailEpic } from 'src/domains/patient/scenes/create-edit-form-jello/store/check-duplicated-email/check-duplicated-email.epic';
import { checkDuplicatedHealthcareIdEpic } from 'src/domains/patient/scenes/create-edit-form-jello/store/check-duplicated-healthcare-id/check-duplicated-healthcare-id.epic';
import {
  createFhirPatientEpic,
  createPatientWithPairingCodeEpic,
  createPatientWithPairingCodeSuccessEpic,
  createPatientWithoutPairingCodeEpic,
} from 'src/domains/patient/scenes/create-edit-form-jello/store/create-patient/create-patient.epic';
import {
  editPatientPlatformEpic,
  fetchEditPatientDataEpic,
} from 'src/domains/patient/scenes/create-edit-form-jello/store/edit-patient/edit-patient.epic';
import { correlateSharingCodeEpic } from 'src/domains/patient/scenes/create-edit-form-jello/store/correlate-sharing-code/correlate-sharing-code.epic';

import { UnblindClinicalStudyPatientFactoryImp } from '../domains/patient/services/patient/clinical-studies/interventional-clinical-studies/unblind-clinical-study-patient/unblind-clinical-study-patient.factory';
import { unblindClinicalStudyPatientEpic } from 'src/domains/patient/scenes/clinical-studies/store/interventional-clinical-studies/unblind-clinical-study-patient.epic';
import { GetClinicalStudiesFactoryImp } from '../domains/patient/services/patient/clinical-studies/observational-clinical-studies/get-clinical-studies/get-clinical-studies.factory';
import { getClinicalStudiesEpic } from 'src/domains/patient/scenes/clinical-studies/store/observational-clinical-studies/get-clinical-studies/get-clinical-studies.epic';
import { getParticipantIdsEpic } from 'src/domains/patient/scenes/clinical-studies/store/observational-clinical-studies/get-participant-ids/get-participant-ids.epic';
import { GetParticipantIdsFactoryImp } from 'src/domains/patient/services/patient/clinical-studies/observational-clinical-studies/get-participant-ids/get-participant-ids.factory';
import { getObservationalStudiesStatusEpic } from 'src/domains/patient/scenes/clinical-studies/store/observational-clinical-studies/get-observational-studies-status/get-observational-studies-status.epic';
import { GetObservationalStatusFactoryImp } from 'src/domains/patient/services/patient/clinical-studies/observational-clinical-studies/get-observational-studies-status/get-observational-studies-status.factory';

import { UserAnnouncementsFactoryImpl } from 'src/domains/announcements/services/user-announcements/user-announcements.factory';
import { MarkAnnouncementVisitedFactoryImpl } from 'src/domains/announcements/services/mark-announcement-visited/mark-announcement-visited.factory';
import {
  fetchUserAnnouncementsEpic,
  markAnnouncementVisitedEpic,
} from 'src/domains/announcements/store/announcements.epics';
import { ListDemoProfilesFactoryImpl } from 'src/domains/patient/scenes/demo-profile/services/list-demo-profiles/list-demo-profiles.factory';
import { editDemoPatientProfilesFactoryImpl } from 'src/domains/patient/scenes/demo-profile/services/edit-demo-patient-profiles/edit-demo-patient-profiles.factory';
import { fetchAssignedDemoPatientProfilesFactoryImpl } from 'src/domains/patient/scenes/demo-profile/services/list-assigned-demo-patient-profiles/list-assigned-demo-patient-profiles.factory';
import {
  fetchDemoProfilesEpic,
  editDemoPatientProfilesEpic,
  fetchAssignedDemoPatientProfilesEpic,
} from 'src/domains/patient/scenes/demo-profile/store/demo-profile.epics';
import { EnrollPatientToStudyFactoryImp } from 'src/domains/patient/services/patient/clinical-studies/observational-clinical-studies/enroll-patient-to-study/enroll-patient-to-study.factory';
import { enrollPatientToStudyEpic } from 'src/domains/patient/scenes/clinical-studies/store/observational-clinical-studies/enroll-patient-to-study/enroll-patient-to-study.epic';

export const rootEpic = combineEpics(
  getPatientStockEpic(PatientStockFactoryImpl({ devMode: false })),
  getPatientsListEpic(PatientListFactoryImpl({ devMode: false })),
  fetchPatientEpic(PatientFactoryImpl({ devMode: false })),
  fetchCurrentPatientErrorEpic(),
  oidcLoginErrorEpic(),
  retryLoginEpic(),
  fetchPatientDateRangeEpic(DateRangeFactoryImpl({ devMode: false })),
  fetchBGOverviewEndDateEpic(DateRangeFactoryImpl({ devMode: false })),
  getClinicalDataEpic(ClinicalDataFactoryImpl({ devMode: false })),
  getClinicalDataFromFhirEpic(
    ClinicalDataFromFhirFactoryImpl({ devMode: false }),
  ),
  fetchCheckExternalPatientEpic(
    CheckExternalPatientFactoryImpl({ devMode: false }),
  ),
  fetchBGOverviewClinicalDataEpic(ClinicalDataFactoryImpl({ devMode: false })),
  fetchBGOverviewClinicalDataFromFhirEpic(
    ClinicalDataFromFhirFactoryImpl({ devMode: false }),
  ),
  getClinicalDataOnDateRangeEpic(),
  getClinicalDataOnBGOverviewEndDateEpic(),
  setClinicalDataEpic(),
  getClinicalDataOnDatesChangeEpic(),
  getTimeIntervalsEpic(TimeIntervalFactoryImpl({ devMode: false })),
  getThresholdsEpic(ThresholdFactoryImpl({ devMode: false })),
  getDeliveryStatusEpic(GetDeliveryStatusFactoryImpl({ devMode: false })),
  getAlertsEpic(GetAlertsFactoryImpl({ devMode: false })),
  getCurrentPrescriptionEpic(GetPrescriptionFactoryImpl({ devMode: false })),
  saveAlertsEpic(SaveAlertsFactoryImpl({ devMode: false })),
  getTherapiesEpic(TherapiesFactoryImpl({ devMode: false })),
  getClinicGuidesEpic(GetClinicGuidesFactoryImpl({ devMode: false })),
  saveClinicGuideEpic(SaveClinicGuideFactoryImpl({ devMode: false })),
  deleteClinicGuideEpic(DeleteClinicGuideFactoryImpl({ devMode: false })),
  getFrequenciesEpic(FrequenciesFactoryImpl({ devMode: true })),
  getPatientSearchEpic(PatientSearchFactoryImpl({ devMode: false })),
  getStripModelsEpic(StripModelsFactoryImpl({ devMode: false })),
  savePrescriptionEpic(SavePrescriptionFactoryImpl({ devMode: false })),
  getDeliveryEpic(GetDeliveryFactoryImpl({ devMode: false })),
  saveDeliveryEpic(SaveDeliveryFactoryImpl({ devMode: false })),
  setDeliveryStatusEpic(SetDeliveryStatusFactoryImpl({ devMode: false })),
  getDeliveryHistoryEpic(StockAdjustmentsFactoryImpl({ devMode: false })),
  getTargetRangesEpic(GetTargetRangesFetchFactoryImpl({ devMode: false })),
  saveTargetRangesEpic(SaveTargetRangesFetchFactoryImpl({ devMode: false })),
  addPrescriptionEntryEpic(),
  createUnsavedPrescriptionEntryEpic(),
  patientChangeEpic(),
  setCurrentPrescriptionsEpic(),
  onPatientChangeResetPrescriptionEpic(),
  submitLostStripsEpic(LostStripsFactoryImpl({ devMode: false })),
  submitManualDeliveryEpic(ManualDeliveryFactoryImpl({ devMode: false })),
  initializePrescriptionEpic(),
  fetchTemporaryPrescriptionReasonsEpic(
    PrescriptionReasonsFactoryImpl({ devMode: false }),
  ),
  checkAssociatedProfessionalsExistOnEditPatientEpic(),
  fetchPatientPermissionsEpic(),
  onClinicGuidesUpdateEpic(),
  onSaveClinicGuideEpic(),
  clearPatientStateEpic,
  onPrescriptionSaveFetchLatestEpic(),
  onDeliveryStatusChangeFetchStripStockEpic(),
  fetchOrgStockEpic(GetOrgStockFactoryImpl({ devMode: false })),
  addOrgStockEpic(AddOrgStockFactoryImpl({ devMode: false })),
  permissionsEpic(PermissionsFactory({ devMode: false })),
  changeLanguageEpic(),
  clearOrganizationStateEpic,
  modalDomSideEffectsEpics(),
  createPatientStatusNotificationModalEpic(),
  updateLastDeliveryStatusEpic(),
  patientPermissionsEpic(
    PermissionsFactory({ devMode: false, type: 'patient' }),
  ),
  createSendPatientStatusModalEpic(),
  getLastDeliveryStatusEpic(
    GetLastDeliveryStatusFactoryImpl({ devMode: false }),
  ),
  userEpic(CurrentUserFactoryImpl({ devMode: false })),
  saveDeliveryStatusCommentEpic(
    SaveDeliveryStatusCommentFactoryImpl({ devMode: false }),
  ),
  createLoadingModalEpic(),
  successLoadingModalEpic(),
  removeLoadingModalEpic(),
  errorLoadingModalEpic(),
  successRequiresConfirmationModalEpic(),
  refreshClinicGuidesEpic(),
  getDeviceAssociationEpic(GetDeviceAssociationFactoryImpl({ devMode: false })),
  updateDeviceAssociationEpic(
    UpdateDeviceAssociationFactoryImpl({ devMode: false }),
  ),
  updateAssociationOnConfirmEpic(),
  redirectAfterDeviceAssignmentCancelEpic(generalLinks.home),
  resetFeatureStateAfterCancelEpic(),
  getAlreadyAssignedPatientEpic(PatientFactoryImpl({ devMode: false })),
  createPatientEpic(CreatePatientFactoryImpl({ devMode: false })),
  createProfessionalEpic(CreateProfessionalFactoryImpl({ devMode: false })),
  editProfileEpic(EditProfileFactoryImpl({ devMode: false })),
  editEMRProfileEpic(EditEMRProfileFactoryImpl({ devMode: false })),
  getProfessionalsEpic(FetchProfessionalFactoryImpl({ devMode: false })),
  getDepartmentProfileTypesEpic(ProfileTypesFactory({ devMode: false })),
  redirectAfterDeviceAssignmentDoneEpic(),
  countriesEpic(countryService({ devMode: false })),
  getCreatedPatientEpic(PatientFactoryImpl({ devMode: false })),
  fetchLanguagesEpic(
    GetActiveSystemLanguagesFactoryImpl({
      devMode: false,
    }),
  ),
  fetchPatientForEditEpic(
    GetPatientInfoFactoryImpl({ devMode: false }),
    FetchConfigurablesFactoryImpl({ devMode: false }),
  ),
  updateProfileTypeOnFetchPatientSuccessEpic(),
  updateAllowPatientAccessOnProfileChangeEpic(),
  updateCreatePatient(),
  updateCreatePatientHealthInfo(),
  updateAllowPatientAccessOnProfileEditEpic(),
  getLatestPatientsEpic(
    GetLatestPatientsFactoryImpl(
      { devMode: false },
      GetLatestPatientsSimpleTransformImpl,
    ),
  ),
  getLatestPatientsAfterPatientCreatedEpic(),
  editPatientEpic(EditPatientFactoryImpl({ devMode: false })),
  editEMRPatientEpic(EditEMRPatientFactoryImpl({ devMode: false })),
  populateCreatePatientLanguageEpic(),
  populateCreateProfessionalLanguageEpic(),
  populateCreatePatientProfessionalsEpic(),
  setPatientCountryFromDepartmentEpic(),
  setDefaultHCPWhenCreatingPatientEpic(),
  setDefaultLanguageWhenCreatingPatientEpic(),
  setDefaultLanguageWhenCreatingProfessionalEpic(),
  setOpenConnectivityWhenCreatingProfessionalEpic(),
  expiringModalEpics,
  fetchConfigurablePermissionsEpic(
    FetchConfigurablesFactoryImpl({ devMode: false }),
  ),
  fetchPatientDevicesEpic(ListDevicesFactoryImpl({ devMode: false })),
  fetchAllDeviceSettingsDataEpic(ListDevicesFactoryImpl({ devMode: false })),
  removePatientDeviceEpic(RemoveDeviceFactoryImpl({ devMode: false })),
  deactivatePatientEpic(DeactivatePatientFactoryImpl({ devMode: false })),
  fetchNextShipmentEpic(NextShipmentFetchFactoryImpl({ devMode: false })),
  changeStatusNextShipmentEpic(
    NextShipmentChangeStatusFactoryImpl({ devMode: false }),
  ),
  changeDateNextShipmentEpic(
    NextShipmentChangeDateFactoryImpl({ devMode: false }),
  ),
  fetchPatientTimeBlocksEpic(GetTimeBlocksFactoryImpl({ devMode: false })),
  fetchDeliveryConfigurationEpic(
    GetDeliveryConfigurationFactoryImpl({ devMode: false }),
    GetPatientDeliveryConfigurationFactoryImpl({ devMode: false }),
  ),
  fetchHba1cCardEpics(GetHba1cCardFactoryImpl({ devMode: false })),
  fetchHba1cListEpics(GetHba1cListFactoryImpl({ devMode: false })),
  saveNewEntryHba1cEpic(PostNewEntryHba1cFactoryImpl({ devMode: false })),
  savePatientTimeBlocksEpic(PostTimeBlocksFactoryImpl({ devMode: false })),
  saveDeliveryConfigurationEpic(
    PostDeliveryConfigurationFactoryImpl({ devMode: false }),
  ),
  fetchPatientsEpic(ClinicalStatisticsFactoryImpl({ devMode: false })),
  mysugrStatusEpic(MysugrStatusFactoryImpl({ devMode: false })),
  mysugrSendInviteEpic(MysugrSendInviteFactoryImpl({ devMode: false })),
  mysugrResetInviteEpic,
  oidcEpics,
  sessionEpics,
  fetchDeviceSettingsListEpic(
    DeviceSettingsListFactoryImpl({ devMode: false }),
  ),
  fetchDeviceSettingsEpic(DeviceSettingsFactoryImpl({ devMode: false })),
  generatePatientReportsEpic(PatientReportsFactoryImpl({ devMode: false })),
  continuousMonitoringEpics,
  fetchRpmSettingsEpic(GetRpmSettingsFactoryImpl({ devMode: false })),
  updateRpmSettingsEpic(UpdateRpmSettingsFactoryImpl({ devMode: false })),
  savePatientSessionEndEpic(PatientSessionEndFactoryImpl({ devMode: false })),
  exportPatientsSessionEpic(
    PatientsSessionExportFactoryImpl({ devMode: false }),
  ),
  startPatientSessionEpic(PatientSessionStartFactoryImpl({ devMode: false })),
  saveUserUnitsEpic(SaveUserUnitsFactoryImpl({ devMode: false })),
  getUserUnitsEpic(GetUserUnitsFactoryImpl({ devMode: false })),
  // getTargetRangesEpic(GetTargetRangesFetchFactoryImpl({ devMode: false })),
  // saveTargetRangesEpic(SaveTargetRangesFetchFactoryImpl({ devMode: false })),
  getINSIdentityEpic(GetINSIdentityFetchFactoryImpl({ devMode: true })),
  // OLD FORM EPIC
  checkPairingCodeEpic(CheckPairingCodeFactoryImp({ devMode: false })),
  correlatePairingCodeEpic(CorrelatePairingCodeFactoryImp({ devMode: false })),
  invitePatientEpic(InvitePatientFactoryImp({ devMode: false })),
  createPatientWithFhirEpic(
    CreatePatientWithFhirFactoryImpl({ devMode: false }),
  ),
  editPatientWithFhirEpic(EditPatientWithFhirFactoryImpl({ devMode: false })),
  // NEW FORM EPIC
  connectSharingCodeEpic(CheckPairingCodeFactoryImp({ devMode: false })),
  sendInvitationtEpic(InvitePatientFactoryImp({ devMode: false })),
  checkIsSharingCodeAvailableEpic(
    CheckPairingCodeAvailabilityFactoryImpl({ devMode: false }),
  ),
  checkDuplicatedEmailEpic(CheckEmailFactoryImpl({ devMode: false })),
  checkDuplicatedHealthcareIdEpic(CheckHCIDFactoryImpl({ devMode: false })),
  createFhirPatientEpic(),
  createPatientWithoutPairingCodeEpic(
    CreatePatientWithFhirFactoryImpl({ devMode: false }),
  ),
  createPatientWithPairingCodeEpic(
    CreatePatientWithFhirFactoryImpl({ devMode: false }),
  ),
  fetchEditPatientDataEpic(
    GetPatientInfoFactoryImpl({ devMode: false }),
    FetchConfigurablesFactoryImpl({ devMode: false }),
  ),
  editPatientPlatformEpic(EditPatientWithFhirFactoryImpl({ devMode: false })),
  correlateSharingCodeEpic(CorrelatePairingCodeFactoryImp({ devMode: false })),
  createPatientWithPairingCodeSuccessEpic(),
  //END FORM EPICS
  logoutEpic(),
  reloadAfterLogoutEpic(),
  pairingCodeIdentityStatusEpic(
    pairingCodeIdentityStatusFactoryImp({ devMode: false }),
  ),
  checkDataSharingConsentEpic(
    CheckDataSharingConsentFactoryImp({ devMode: false }),
  ),
  unblindClinicalStudyPatientEpic(
    UnblindClinicalStudyPatientFactoryImp({ devMode: false }),
  ),
  getClinicalStudiesEpic(GetClinicalStudiesFactoryImp({ devMode: false })),
  getParticipantIdsEpic(GetParticipantIdsFactoryImp({ devMode: false })),
  getObservationalStudiesStatusEpic(
    GetObservationalStatusFactoryImp({ devMode: false }),
  ),
  fetchUserAnnouncementsEpic(UserAnnouncementsFactoryImpl({ devMode: false })),
  markAnnouncementVisitedEpic(
    MarkAnnouncementVisitedFactoryImpl({ devMode: false }),
  ),
  enrollPatientToStudyEpic(EnrollPatientToStudyFactoryImp({ devMode: false })),
  fetchDemoProfilesEpic(ListDemoProfilesFactoryImpl({ devMode: false })),
  editDemoPatientProfilesEpic(
    editDemoPatientProfilesFactoryImpl({ devMode: false }),
  ),
  fetchAssignedDemoPatientProfilesEpic(
    fetchAssignedDemoPatientProfilesFactoryImpl({ devMode: false }),
  ),
);
const epic$ = new BehaviorSubject(rootEpic);

export const appEpic = (action$, store) =>
  epic$.mergeMap((epic) => epic(action$, store, null));

export const injectEpic = (asyncEpic) => epic$.next(asyncEpic);
export const injectEpicFactory =
  (injectedEpicNamespaces) => (namespace, asyncEpic) => {
    if (injectedEpicNamespaces.indexOf(namespace) >= 0) {
      return epic$;
    }
    injectedEpicNamespaces = [...injectedEpicNamespaces, namespace];
    return epic$.next(asyncEpic);
  };
